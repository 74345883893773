/*eslint eqeqeq: "off"*/
import React, {useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';

import logo from '../logo.png';
import swal from 'sweetalert';
import $ from "jquery";
import info from '../info.png';
const Jointtwentytwo = ({ setForm, formData, navigation, saveAndExist, infodetls }) => {
  const { previous, next } = navigation;

  
   useEffect(() => {
    // $(document).ready(function () {
  let getVal=[];
{if(formData.investmentCriteria){
  if(typeof formData.investmentCriteria != "boolean"){
    // alert( typeof formData.investmentCriteria);
     getVal =(formData.investmentCriteria).split('##')
    //  alert("getval length" + getVal.length)
    $('input[name="investmentCriteria"]').each(function (i) {
    // console.log("inside for each" + i)
    for(let j= 0 ; j < getVal.length; j++){
      //  console.log("inside for JJ" + j)
      //  console.log("$(this).val()" + $(this).val()   +" &&   getVal[j]"+ getVal[j] )
      if( $(this).val() === getVal[j]){
        // alert();
        $(this).prop('checked', true);
         if(getVal[j] !== "None"){
            $(".box").show();
        }
      } ;
    }
    });


  }
}
}
})

 useEffect(() => {
    // $(document).ready(function () {
  let getVal=[];
{if(formData.otherInvestCriteria){
  if(typeof formData.otherInvestCriteria != "boolean"){
    // alert( typeof formData.otherInvestCriteria);
     getVal =(formData.otherInvestCriteria).split('##')
    //  alert("getval length" + getVal.length)
    $('input[name="otherInvestCriteria"]').each(function (i) {
    // console.log("inside for each" + i)
    for(let j= 0 ; j < getVal.length; j++){
      //  console.log("inside for JJ" + j)
      //  console.log("$(this).val()" + $(this).val()   +" &&   getVal[j]"+ getVal[j] )
      if( $(this).val() === getVal[j]){
        // alert();
        $(this).prop('checked', true);
       
      } ;
    }
    });


  }
}
}
})


  useEffect(() => {
    $(document).ready(function () {
      // $('.years input[type="checkbox"]').click(function () {
      //   $(this).prop('checked', true);
      //   var inputValue = $(this).attr("value");
      //   var targetBox = $("." + inputValue);
      //   $(".box").not(targetBox).show();
      //   $(targetBox).show();
      // });
    });

    $('.set-check  input[type="checkbox"]').click(function () {
      if ($("#input-test").is(':checked')) {
        $(".box").hide();
        $('.years :checkbox:checked').each(function (i) {
          $(this).prop('checked', false);
        });
      } else if (!$("#input-test").is(':checked')) {
        if ($(this).is(':checked')) {
          $(".box").show();
        }
      }
    })

    // $('.containers').click(function () {
    //   // alert($("#input-test").is(":checked"))
    //   if ($("#input-test").is(":checked")) {
    //     $(':checkbox:checked').each(function (i) {
    //       $(this).prop('checked', false);

    //     });
    //     $("#input-test").prop('checked', true);
    //   }
    // })
  });


  function handleClick(e) {
    localStorage.setItem("step15", "step15")
    localStorage.removeItem("step13");
    var val = [];
    var val2 = [];
    $('input[name="investmentCriteria"]:checked').each(function (i) {
      val[i] = $(this).val();
    });
    $('input[name="otherInvestCriteria"]:checked').each(function (i) {
      val2[i] = $(this).val();
    });
    let xValue = val.join("##");
    let xValue2 = val2.join("##");
    formData.investmentCriteria = xValue;
    formData.otherInvestCriteria = xValue2; 

    if (typeof formData.investmentCriteria == 'undefined' || formData.investmentCriteria === "") {
      $('.investmentCriteria').html('Please select an answer');
    }
    else if (formData.investmentCriteria !== "None") {
      go("Jointtwentyfour")
    }
    else {

      next();
    }
  }
  function removeError() {
    $('.error').html('');
  }


  saveAndExist = () => {
    formData.status = "PENDING"
    formData.step = "15"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  const { go } = navigation;
  useEffect(() => {

    $(".past").click(function () {
      const myValues = localStorage.getItem("myValues")
      if (myValues === "None") {
        go("Thirteen");
      }
    })

  })
  return (
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />

      <div className="save-exit">
        <p className="save" onClick={saveAndExist} > Save Data </p><img src={info} onClick={infodetls} className="info" />
      </div>
      {/* <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit <img src={info} className="info" /></p>
      </div> */}
      <h3>Investor certificate</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
  

      </div>

      <label>Do you meet the following investment criteria?</label>
      <br />
      <p>Select at least one of the below options</p>




      <div className="checkbox-my">

        <label className="containers years set-check">Own, or at any time during the 2-year period before the relevant time has owned, a portfolio of specified financial products of a value of at least $1 million (in aggregate)
  <input type="checkbox" name="investmentCriteria" onClick={removeError} value="Own or at any time during the 2 year period before the relevant time has owned a portfolio of specified financial products of a value of at least 1 million (in aggregate)" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers years set-check">During the 2-year period before the relevant time, carried out 1 or more transactions to acquire specified financial products where the amount payable under those transactions (in aggregate) is at least $1 million and the other parties to the transactions are not associated persons of A:
  <input type="checkbox" name="investmentCriteria" onClick={removeError} value="During the 2-year period before the relevant time, carried out 1 or more transactions to acquire specified financial products where the amount payable under those transactions (in aggregate) is at least $1 million and the other parties to the transactions are not associated persons of A" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers years set-check">Greater than 10 years in investment business & with more than 2 years making investment decisions
  <input type="checkbox" className="" name="investmentCriteria" onClick={removeError} value="Greater than 10 years in investment business with more than 2 years making investment decisions" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers other-check set-check">None of the above
  <input type="checkbox" name="investmentCriteria" id="input-test" onClick={removeError} value="None" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark" ></span>
        </label>
        <span className="error investmentCriteria"></span>
        </div>




    
        
        <div className="fullspace pandingfinsh None box ">
        <label>Please define the specific financial products of at least $1mil. </label>
        
        <div className="checkbox-my left-more">
        <p>Select all that apply</p>
          <div>
         

            <label className="containers">Equity securities (includes listed shares)
  <input type="checkbox" name="otherInvestCriteria" value="Equity securities (includes listed shares)" onChange={e=>{setForm(e) ; removeError()}} />
              <span className="checkmark"></span>
            </label>

            <label className="containers">Managed funds
  <input type="checkbox" name="otherInvestCriteria" value="Managed funds" onChange={e=>{setForm(e) ; removeError()}} />
              <span className="checkmark"></span>
            </label>

            <label className="containers">Derivative instruments
  <input type="checkbox" name="otherInvestCriteria" value="Derivative instruments" onChange={e=>{setForm(e) ; removeError()}} />
              <span className="checkmark"></span>
            </label>

            <label className="containers">Debt securities
  <input type="checkbox" name="otherInvestCriteria" value="Debt securities" onChange={e=>{setForm(e) ; removeError()}} />
              <span className="checkmark"></span>
            </label>


          </div>
          </div>
        </div>


   




      <br />

      <br />
      <div className="fotter-btn">
        <button className="preview past" onClick={previous}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={handleClick}  >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
  );
};

export default Jointtwentytwo;
