import React from 'react';

const states = [
  ['Select', 'Select'],
  ['Director', 'Director'],
  ['CEO', 'CEO'],
  ['Chairperson', 'Chairperson'],
  ['Other','Other']
];


const Option2 = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others}>
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default Option2;
