import React, { useState, useEffect,useRef } from 'react';
import axios from "axios";
import AxiosInstance from "../axios"
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import $ from "jquery";
import info from '../info.png';
import swal from 'sweetalert';
import logo from '../logo.png';
import SignaturePad from 'react-signature-pad-wrapper'
// var SignaturePad = require('react-signature-pad');
const Jointtwentyfour = ({ setForm, formData, navigation, saveAndExist, jQuery, signaturePad, infodetls }) => {


  const [userapplication, setUserapplication] = useState([]);
  const mySignatureRef = useRef(null);
  useEffect(() => {
    application();
  }, []);
  const application = async () => {
    const applicationdata = await AxiosInstance.get("/admin/syndicate/" + Syndication);
    setUserapplication(applicationdata.data);
    console.log(applicationdata.data);
  };


  // function handleClick() {
  //   alert();
  //   this.signaturePad.isEmpty();
  //   alert(this.signaturePad.isEmpty())
  //   alert(signaturePad.isEmpty())
  //   if (signaturePad.isEmpty()) {
  //     console.log("Empty!");
  //   }
  //   else {
  //     next()
  //   }
  // }

  const { Syndication, fullName, twoYearInvestEntity, investmentBusiness, investmentCriteria } = formData;
  const { previous, next } = navigation;
  useEffect(() => {
    $(document).ready(function () {
      var date = new Date();

      var val = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      $("#printDate").text(val);
      formData.certificateA_Date = val;


    });

    //selector.click( over, out )
    $('.signupForm').hide();
    $(".signupButton").click(function () {
      $('.signupForm').show();
    }, function () {
      $('.signupForm').show();
    });
    $(".signupXButton").click(function () {
      $('.signupForm').hide();
      $('.signupForm').hide();
    });
    $(".signupXButton").click(function () {
      $('.signupForm').hide();
    });
    $(".set-con").click(function () {
      const setValues = localStorage.getItem("setValues")
        let signatureA = mySignatureRef.current.toDataURL();
    let isEmpty = mySignatureRef.current.isEmpty();
    // alert(isEmpty);
    // alert(signatureA);
    if(isEmpty){
      // alert("inside erorr ")
 $('.signError').html('Please sign ..');
    }else{
      formData.signatureA = signatureA;
//       console.log("sig natureA + " +signatureA);
//  alert("inside next " + formData.signatureA )

      if (setValues === "Yes") {
        go("Twenty");
      }
      //  else if (myValues !== "None") {
      //   go("Twelve")
      // }
      else {
        localStorage.setItem("Step20", "Step20")
        go("Twenty");
      }
    }
    });
    
    console.log('render 1st time');
  },[]);

  useEffect(() => {

    $(".past").click(function () {
      const myValues = localStorage.getItem("step10")
      const myValuesone = localStorage.getItem("step13")
      const myValuestwo = localStorage.getItem("step15")


      if (myValues === "step10" && myValuesone === "step13") {
        go("Jointtwentyone");
        // localStorage.removeItem("step13");
      }
      if (myValues === "step10" && myValuestwo === "step15") {
        go("Jointtwentytwo");
        //  localStorage.removeItem("step15");

      }

      if (!myValuesone && !myValuestwo) {
        go("Jointtwenty");
        localStorage.removeItem("step10");

      }

    })

  },[])

  const { go } = navigation;
  saveAndExist = () => {
    formData.status = "PENDING"
    formData.step = "11"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
//   async function uploadDataWithSignature() {
//     let signatureA = mySignatureRef.current.toDataURL();
//     let isEmpty = mySignatureRef.current.isEmpty();
//     alert(isEmpty);
//     alert(signatureA);
//     if(isEmpty){
//  $('.signError').html('Please sign ..');
//     }else{
//       formData.signatureA = signatureA;
//       console.log("sig natureA + " +signatureA);
//       next();
//     }
  
//     alert(mySignatureRef);
    
//   }
    function removeError() {
    $('.error').html('');
  }
  return (
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls} className="info" />
      </div>
      <h3>Certificate A - Wholesale Investor Certificate</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
      

      </div>

      <p>This certificate is required to verify that the investor meets the respective eligibility requirements as a "Wholesale Investor" under schedule 1, clause 3(2)(a) - (c) of the FMCA and accordingly confirm that neither MyFarm (as Offeror), {userapplication.syndicateName}  (as Issuer) are required to make disclosure in respect of this offer under Part 3 of the FMCA.</p>

      <div className="cantant">
        <p>In relation to the offer from MyFarm Limited of units in {userapplication.syndicateName} ("the financial product" on offer and "the transaction"), that:</p>
       {formData.entity==="Trust" ?  <p> 1)  I/WE {formData.trustName} ("the investor") hereby certify that I am/We are a Wholesale Investor under Schedule 1, clause 3(2) of the FMCA of the following kind:</p> :  <p> 1)  I/WE {formData.fullName} and {formData.secondholder} ("the investor") hereby certify that I am/We are a Wholesale Investor under Schedule 1, clause 3(2) of the FMCA of the following kind:</p>}

        {(investmentCriteria !== "None" && investmentBusiness === "None")
          ?
          <label>I/We meet the investment criteria specified in Schedule 1, clause 38 of the FMCA </label>
          :
          null
        }
        {(investmentBusiness !== "None" && twoYearInvestEntity === "None")
          ?
          <label>An “investment business” as defined in Schedule 1, Cl37 of the FMCA </label>
          :
          null
        }

        {(twoYearInvestEntity !== "None")
          ?
          <label>A “large” investor as defined in Schedule 1, clause 39 of the FMCA  </label>
          :
          null
        }


        {/* <label>{investmentCriteria  === "None" ? "" : "An “investment business” as defined in Schedule 1, cl37 of the FMCA "}</label>
        <label>{investmentBusiness  === "None" ? "" : "I/We meet the investment criteria specified in Schedule 1, clause 38 of the FMCA"}</label>
        <label>{twoYearInvestEntity === "None" ? "" : "A “large” investor as defined in Schedule 1, clause 39 of the FMCA" }</label> */}
        <p>2) I/We do understand the consequences of certifying myselfor ourselves to be a Wholesale Investor.</p>
        <div className="d-flex">
          <p> Dated:</p>  <p id="printDate"></p>

        </div>



        <div className="signupButton">Please click to read this warning</div>
        <div className="signupForm"><div className="signupFormPaw"></div><b>Warning<br />
The law normally requires people who offer financial products to give information to investors before they invest. This information is designed to help investors make an informed decision.
If you are a wholesale investor, the usual rules do not apply to offers of financial products made to you. As a result, you may not receive a complete and balanced set of information. You will also have fewer other legal protection for these investments. Ask questions, read all documents carefully, and seek independent financial advice before committing yourself.
<br /><br />Offence<br />
It is an offence to give a certificate knowing that it is false or misleading in a material particular. The offence has a penalty of a fine not exceeding $50,000
</b><div className="signupXButton"  >X</div>
</div>

 <div onClick={removeError}>
        <label>Signature:</label>
        
        <SignaturePad  className="signature_h" clearButton="true" id="signature-pad-canvas" ref={mySignatureRef}   />
          <br></br>
         <span className="error signError"></span>
         </div>
         </div>


      <br />
      <div className="fotter-btn">
        <button className="preview past" onClick={previous}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button  className="next set-con"   >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
        
      </div>
    </div>
    </div>
  );
};

export default Jointtwentyfour;
