import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, useStep } from 'react-hooks-helper';
import ItemForm from '../ItemForm';
import { Link } from 'react-router-dom';
import leftarrow from '../leftarrow.png';
import info from '../info.png';
import swal from 'sweetalert';
import rightarrow from '../rightarrow.png';
import PhoneInput from 'react-phone-input-2';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { DatePicker } from 'antd';
import logo from '../logo.png';
import $, { now } from 'jquery';
import ReactInputMask from 'react-input-mask';
import InputMask from 'react-input-mask';
import MenuSidebar from '../Individual/MenuSidebar';
import BiometricOption from '../BiometricOption';
import Option2 from '../Individual/Option2';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';


// import Googleaddress from "./Googleaddress";
const EntityThird = ({
  func,
  setForm,
  formData,
  navigation,
  saveAndExist,
  infodetlss,
}) => {
  const {
    fullName,
    shareholdingPercentage,
    isTwoYearInvest,
    secondholder,
    DirectorRole,
    otherDirectorDetailRole,
  } = formData;
  const query = useQuery();

  let queryValue = query.get("update");
  const saveandexit = () => {
    formData.secondholder =
      secondholder != 'undefined' && secondholder != '' && secondholder != null
        ? secondholder.trim()
        : '';
    formData.fullName =
      fullName != 'undefined' && fullName != '' && fullName != null ? fullName.trim() : '';
    func();
  };
  const [namefull1, setNamefull1] = useState(
    formData.secondholder ? formData.secondholder.trim().split(' ') : ''
  );
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];
  if (
    formData.secondholder != null &&
    formData.secondholder != '' &&
    formData.secondholder != 'undefined'
  ) {
    formData.firstName12 = namefull1[0];
    if (namefull1.length > 1) {
      formData.lastName12 = namefull1[namefull1.length - 1];
    }

    if (namefull1 && namefull1.length > 2) {
      formData.middleName12 = namefull1
        .slice(1, namefull1.length - 1)
        .join()
        .replace(/,/g, ' ');
    }
  }

  // formData.fullName = fullName.trim()
  const { previous, next } = navigation;
  useEffect(() => {
    $('.toogles').click(function () {
      $('.toogles').removeClass('active');
      $(this).addClass('active');
    });
    $('.step-change').click(function () {
      $('.step-change').removeClass('active');
      $(this).addClass('active');
    });
    $('document').ready(function () {
      $("input[type='tel']").attr('readonly', true);
    });
    $('.flagsave').click(function () {
      let phoneCode = $('.form-control').val();
      localStorage.setItem('phoneCode', phoneCode);
    });
    $('#SHAREvALUE').keyup(function () {
      let input = $(this).val();
      if (input < 100) {
        return true;
      } else {
        return false;
      }
    });
  });
  function splitFullName(fn) {
    fn = fn.replace(/(^[\s]*|[\s]*$)/g, '');
    fn = fn.replace(/\s+/g, ' ');
    var fnarr = fn.split(' ');
    formData.firstNames = fnarr[0];
    formData.firstNameone = formData.firstNames;
    if (fnarr.length >= 3) {
      formData.lastNames = fnarr[fnarr.length - 1];
      formData.lastNameone = formData.lastNames;
      fnarr.shift();
      fnarr.pop();
      fnarr = fnarr.toString().replace(/,/g, ' ');
      formData.middleNames = fnarr;
      formData.middleNameone = formData.middleNames;
    } else if (fnarr.length === 3) {
      formData.middleNames = fnarr[1];
      formData.middleNameone = formData.middleNames;
      formData.lastNames = fnarr[2];
      formData.lastNameone = formData.lastNames;
    } else if (fnarr.length === 2) {
      formData.lastNames = fnarr[1];
      formData.lastNameone = formData.lastNames;
    }
  }

  function Onprevious(e) {
    // formData.class = "Second"
    formData.class = 'Jointhird';
    go('EntityTwo');
  }

  const handleChange = (e) => {
    e.preventDefault();
  };

  const [errorResults, setErrorResults] = useState();

  // function handleClick(e) {
  //   formData.class = 'Jointhird';
  //   go('EntityThird1');
  // }
  function handleClick(e) {
    const total_index = inputData.length - 1;
    let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (total_index == 0) {
      if (inputData[0].fullLegalName == "" || inputData[0].fullLegalName == null || inputData[0].fullLegalName == undefined) {
        $('.fullLegalNames0').html('Please enter full legal name');

      } else if (inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Select") {
        $('.directRole0').html('Please select role');
      } else if ((inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Other" || inputData[0].DirectorRole == "Select") && (inputData[0].otherDirectorDetailRole == "" ||
        inputData[0].otherDirectorDetailRole == null || inputData[0].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole0').html('Please select other role');
      } else if (!emailExpression.test(inputData[0].userEmail) && inputData[0].isCompanyDirectorYou === 0) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      } else {
        formData.class = 'Jointhird';
        go('EntityThird1');
      }
    }

    else if (total_index == 1) {
      if (inputData[0].fullLegalName == "" || inputData[0].fullLegalName == null || inputData[0].fullLegalName == undefined) {
        $('.fullLegalNames0').html('Please enter full legal name');

      }
      else if (inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Select") {
        $('.directRole0').html('Please select role');
      }
      else if ((inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Other" || inputData[0].DirectorRole == "Select") && (inputData[0].otherDirectorDetailRole == "" ||
        inputData[0].otherDirectorDetailRole == null || inputData[0].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole0').html('Please select other role');
      } else if (!emailExpression.test(inputData[0].userEmail) && inputData[0].isCompanyDirectorYou === 0) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }


      else if (inputData[1].fullLegalName == "" || inputData[1].fullLegalName == null || inputData[1].fullLegalName == undefined) {
        $('.fullLegalNames1').html('Please enter full legal name');

      }
      else if (inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Select") {
        $('.directRole1').html('Please select role');
      }
      else if ((inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Other" || inputData[1].DirectorRole == "Select") && (inputData[1].otherDirectorDetailRole == "" ||
        inputData[1].otherDirectorDetailRole == null || inputData[1].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole1').html('Please select other role');
      } else if (!emailExpression.test(inputData[1].userEmail) && inputData[1].isCompanyDirectorYou === 0) {
        $(".email1").html(
          "Please enter a valid email address"
        );
      }
      else {
        formData.class = 'Jointhird';
        go('EntityThird1');
      }
    }
    else if (total_index == 2) {
      if (inputData[0].fullLegalName == "" || inputData[0].fullLegalName == null || inputData[0].fullLegalName == undefined) {
        $('.fullLegalNames0').html('Please enter full legal name');

      }
      else if (inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Select") {
        $('.directRole0').html('Please select role');
      }
      else if ((inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Other" || inputData[0].DirectorRole == "Select") && (inputData[0].otherDirectorDetailRole == "" ||
        inputData[0].otherDirectorDetailRole == null || inputData[0].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole0').html('Please select other role');
      } else if (!emailExpression.test(inputData[0].userEmail) && inputData[0].isCompanyDirectorYou === 0) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }
      else if (inputData[1].fullLegalName == "" || inputData[1].fullLegalName == null || inputData[1].fullLegalName == undefined) {
        $('.fullLegalNames1').html('Please enter full legal name');

      }
      else if (inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Select") {
        $('.directRole1').html('Please select role');
      }
      else if ((inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Other" || inputData[1].DirectorRole == "Select") && (inputData[1].otherDirectorDetailRole == "" ||
        inputData[1].otherDirectorDetailRole == null || inputData[1].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole1').html('Please select other role');
      } else if (!emailExpression.test(inputData[1].userEmail) && inputData[1].isCompanyDirectorYou === 0) {
        $(".email1").html(
          "Please enter a valid email address"
        );
      }
      else if (inputData[2].fullLegalName == "" || inputData[2].fullLegalName == null || inputData[2].fullLegalName == undefined) {
        $('.fullLegalNames2').html('Please enter full legal name');

      }
      else if (inputData[2].DirectorRole == "" || inputData[2].DirectorRole == null || inputData[2].DirectorRole == undefined || inputData[2].DirectorRole == "Select") {
        $('.directRole2').html('Please select role');
      }
      else if ((inputData[2].DirectorRole == "" || inputData[2].DirectorRole == null || inputData[2].DirectorRole == undefined || inputData[2].DirectorRole == "Other" || inputData[2].DirectorRole == "Select") && (inputData[2].otherDirectorDetailRole == "" ||
        inputData[2].otherDirectorDetailRole == null || inputData[2].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole2').html('Please select other role');
      } else if (!emailExpression.test(inputData[2].userEmail) && inputData[2].isCompanyDirectorYou === 0) {
        $(".email2").html(
          "Please enter a valid email address"
        );
      }
      else {
        formData.class = 'Jointhird';
        go('EntityThird1');
      }
    }
    // fourth director
    else if (total_index == 3) {
      if (inputData[0].fullLegalName == "" || inputData[0].fullLegalName == null || inputData[0].fullLegalName == undefined) {
        $('.fullLegalNames0').html('Please enter full legal name');

      }
      else if (inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Select") {
        $('.directRole0').html('Please select role');
      }
      else if ((inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Other" || inputData[0].DirectorRole == "Select") && (inputData[0].otherDirectorDetailRole == "" ||
        inputData[0].otherDirectorDetailRole == null || inputData[0].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole0').html('Please select other role');
      } else if (!emailExpression.test(inputData[0].userEmail) && inputData[0].isCompanyDirectorYou === 0) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }
      else if (inputData[1].fullLegalName == "" || inputData[1].fullLegalName == null || inputData[1].fullLegalName == undefined) {
        $('.fullLegalNames1').html('Please enter full legal name');

      }
      else if (inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Select") {
        $('.directRole1').html('Please select role');
      }
      else if ((inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Other" || inputData[1].DirectorRole == "Select") && (inputData[1].otherDirectorDetailRole == "" ||
        inputData[1].otherDirectorDetailRole == null || inputData[1].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole1').html('Please select other role');
      } else if (!emailExpression.test(inputData[1].userEmail) && inputData[1].isCompanyDirectorYou === 0) {
        $(".email1").html(
          "Please enter a valid email address"
        );
      }
      else if (inputData[2].fullLegalName == "" || inputData[2].fullLegalName == null || inputData[2].fullLegalName == undefined) {
        $('.fullLegalNames2').html('Please enter full legal name');

      }
      else if (inputData[2].DirectorRole == "" || inputData[2].DirectorRole == null || inputData[2].DirectorRole == undefined || inputData[2].DirectorRole == "Select") {
        $('.directRole2').html('Please select role');
      }
      else if ((inputData[2].DirectorRole == "" || inputData[2].DirectorRole == null || inputData[2].DirectorRole == undefined || inputData[2].DirectorRole == "Other" || inputData[2].DirectorRole == "Select") && (inputData[2].otherDirectorDetailRole == "" ||
        inputData[2].otherDirectorDetailRole == null || inputData[2].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole2').html('Please select other role');
      } else if (!emailExpression.test(inputData[2].userEmail) && inputData[2].isCompanyDirectorYou === 0) {
        $(".email2").html(
          "Please enter a valid email address"
        );
      }

      //fourth
      else if (inputData[3].fullLegalName == "" || inputData[3].fullLegalName == null || inputData[3].fullLegalName == undefined) {
        $('.fullLegalNames3').html('Please enter full legal name');

      }
      else if (inputData[3].DirectorRole == "" || inputData[3].DirectorRole == null || inputData[3].DirectorRole == undefined || inputData[3].DirectorRole == "Select") {
        $('.directRole3').html('Please select role');
      }
      else if ((inputData[3].DirectorRole == "" || inputData[3].DirectorRole == null || inputData[3].DirectorRole == undefined || inputData[3].DirectorRole == "Other" || inputData[3].DirectorRole == "Select") && (inputData[3].otherDirectorDetailRole == "" ||
        inputData[3].otherDirectorDetailRole == null || inputData[3].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole3').html('Please select other role');
      } else if (!emailExpression.test(inputData[3].userEmail) && inputData[3].isCompanyDirectorYou === 0) {
        $(".email3").html(
          "Please enter a valid email address"
        );
      }
      else {
        formData.class = 'Jointhird';
        go('EntityThird1');
      }
    }

    // fifth
    else if (total_index == 4) {
      if (inputData[0].fullLegalName == "" || inputData[0].fullLegalName == null || inputData[0].fullLegalName == undefined) {
        $('.fullLegalNames0').html('Please enter full legal name');

      }
      else if (inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Select") {
        $('.directRole0').html('Please select role');
      }
      else if ((inputData[0].DirectorRole == "" || inputData[0].DirectorRole == null || inputData[0].DirectorRole == undefined || inputData[0].DirectorRole == "Other" || inputData[0].DirectorRole == "Select") && (inputData[0].otherDirectorDetailRole == "" ||
        inputData[0].otherDirectorDetailRole == null || inputData[0].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole0').html('Please select other role');
      } else if (!emailExpression.test(inputData[0].userEmail) && inputData[0].isCompanyDirectorYou === 0) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }
      else if (inputData[1].fullLegalName == "" || inputData[1].fullLegalName == null || inputData[1].fullLegalName == undefined) {
        $('.fullLegalNames1').html('Please enter full legal name');

      }
      else if (inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Select") {
        $('.directRole1').html('Please select role');
      }
      else if ((inputData[1].DirectorRole == "" || inputData[1].DirectorRole == null || inputData[1].DirectorRole == undefined || inputData[1].DirectorRole == "Other" || inputData[1].DirectorRole == "Select") && (inputData[1].otherDirectorDetailRole == "" ||
        inputData[1].otherDirectorDetailRole == null || inputData[1].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole1').html('Please select other role');
      } else if (!emailExpression.test(inputData[1].userEmail) && inputData[1].isCompanyDirectorYou === 0) {
        $(".email1").html(
          "Please enter a valid email address"
        );
      }
      else if (inputData[2].fullLegalName == "" || inputData[2].fullLegalName == null || inputData[2].fullLegalName == undefined) {
        $('.fullLegalNames2').html('Please enter full legal name');

      }
      else if (inputData[2].DirectorRole == "" || inputData[2].DirectorRole == null || inputData[2].DirectorRole == undefined || inputData[2].DirectorRole == "Select") {
        $('.directRole2').html('Please select role');
      }
      else if ((inputData[2].DirectorRole == "" || inputData[2].DirectorRole == null || inputData[2].DirectorRole == undefined || inputData[2].DirectorRole == "Other" || inputData[2].DirectorRole == "Select") && (inputData[2].otherDirectorDetailRole == "" ||
        inputData[2].otherDirectorDetailRole == null || inputData[2].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole2').html('Please select other role');
      } else if (!emailExpression.test(inputData[2].userEmail) && inputData[2].isCompanyDirectorYou === 0) {
        $(".email2").html(
          "Please enter a valid email address"
        );
      }

      //fourth
      else if (inputData[3].fullLegalName == "" || inputData[3].fullLegalName == null || inputData[3].fullLegalName == undefined) {
        $('.fullLegalNames3').html('Please enter full legal name');

      }
      else if (inputData[3].DirectorRole == "" || inputData[3].DirectorRole == null || inputData[3].DirectorRole == undefined || inputData[3].DirectorRole == "Select") {
        $('.directRole3').html('Please select role');
      }
      else if ((inputData[3].DirectorRole == "" || inputData[3].DirectorRole == null || inputData[3].DirectorRole == undefined || inputData[3].DirectorRole == "Other" || inputData[3].DirectorRole == "Select") && (inputData[3].otherDirectorDetailRole == "" ||
        inputData[3].otherDirectorDetailRole == null || inputData[3].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole3').html('Please select other role');
      } else if (!emailExpression.test(inputData[3].userEmail) && inputData[3].isCompanyDirectorYou === 0) {
        $(".email3").html(
          "Please enter a valid email address"
        );
      }
      else if (inputData[4].fullLegalName == "" || inputData[4].fullLegalName == null || inputData[4].fullLegalName == undefined) {
        $('.fullLegalNames4').html('Please enter full legal name');

      }
      else if (inputData[4].DirectorRole == "" || inputData[4].DirectorRole == null || inputData[4].DirectorRole == undefined || inputData[4].DirectorRole == "Select") {
        $('.directRole4').html('Please select role');
      }
      else if ((inputData[4].DirectorRole == "" || inputData[4].DirectorRole == null || inputData[4].DirectorRole == undefined || inputData[4].DirectorRole == "Other" || inputData[4].DirectorRole == "Select") && (inputData[4].otherDirectorDetailRole == "" ||
        inputData[4].otherDirectorDetailRole == null || inputData[4].otherDirectorDetailRole == undefined)) {
        $('.OtherdirectRole4').html('Please select other role');
      } else if (!emailExpression.test(inputData[4].userEmail) && inputData[4].isCompanyDirectorYou === 0) {
        $(".email4").html(
          "Please enter a valid email address"
        );
      }
      else {
        formData.class = 'Jointhird';
        go('EntityThird1');
      }

    }
    else {
      formData.class = 'Jointhird';
      go('EntityThird1');
    }
  }
  function removeError() {
    $('.error').html('');
  }
  function numbertest() {
    let input = $(this).val();
    if (input < 100) {
      return true;
    } else {
      return false;
    }
  }
  $('input').on('paste', function (e) {
    $('.error').html('');
  });
  const [value, setValue] = useState();
  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, data) => setNewDate(data.value);
  const { go } = navigation;
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '3';
    go('submit');
  };



  infodetlss = () => {
    swal({
      text: 'This email address can either be the same email address used by the first joint account holder, or it can be a different email address.'
    });
  };

  const getInitialData = {
    fullLegalName: "",
    isCompanyDirectorYou: 0,
    DirectorRole: "",
    otherDirectorDetailRole: "",
    // userEmail:"",
  };

  const [inputData, setInputData] = useState(
    formData.inputData != null && formData.inputData != '' && formData.inputData != 'undefined'
      ? formData.inputData
      : [getInitialData]
  );

  const handleAddClick = () => {
    setInputData([...inputData, getInitialData]);
  };

  const handleRemoveClick = (index, e) => {
    const newList = [...inputData];
    const result = axios.delete(`https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev/delete/shareholder/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${formData.token}`

      }
    }).then((res) => {
      if (res.status == 404) {
        // alert("deleted")
      }
    }
    ).catch((error) => {
      // alert("not Deleted")
    })

    newList.splice(index, 1);
    setInputData(newList);

    setInputData(newList);
    formData.inputData = newList
    // }

  };

  formData.inputData = inputData;
  console.log("formData.inputDataformData.inputData", formData.inputData)

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          <h2>Step 2: Company Details</h2>
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          { queryValue  ?<p></p> :
          <div className="save-exit se_invff">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>
          }
          <Row>
            <Col>
              <p>Director Details</p>
              <p>
                List below anyone who has effective control over the company. This should include
                but is not limited to ALL Directors. Please also tick the box if one of these individuals is you.
              </p>
            </Col>
          </Row>
          {inputData && inputData.map((x, index) => {
            return (
              <>
                <div className={'bdetail entity_third ' + index + 1 + '' + 'box'} id="jjjj">

                  <Row className="mb-2">

                    <div className='col-md-6'>
                      <label>Full name </label>
                      <ItemForm
                        disabled={formData.isPrimary == 1 ? false : true}
                        type="text"
                        name="fullLegalName"
                        className="quantity"
                        defaultValue={
                          inputData[index].fullLegalName
                        }
                        onChange={(e) => {
                          // setForm(e);
                          removeError();
                          const secArray = inputData;
                          secArray[index].fullLegalName = e.target.value;
                          setInputData([...secArray]);
                        }}

                        placeholder="Name of director "
                      />
                      <span className={`error fullLegalNames${index}`}></span>
                    </div>
                    <div className="Select_role col-md-6">
                      <Row className='align-items-end role11'>

                        <div className='col-lg-12'>
                          <p className="paddingNone Select_role">
                            <label className='RoleSelect'>Select role</label>
                            <Option2
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="text"
                              autoComplete="off"
                              className="optionchange quantity"
                              onClick={removeError}
                              onChange={(e) => {
                                // setForm(e);
                                removeError();
                                const secArray = inputData;
                                secArray[index].DirectorRole = e.target.value;
                                setInputData([...secArray]);
                              }}
                              name="DirectorRole"
                              value={inputData[index].DirectorRole}
                            // value={DirectorRole}
                            /> </p>
                          <span className={`error directRole${index}`}></span>

                        </div>
                        <div className='col-lg-12'>
                          {inputData[index].DirectorRole == "Other" ?
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              className='Other_input other-input-2'
                              name='otherDirectorDetailRole'
                              value={inputData[index].otherDirectorDetailRole}
                              onChange={(e) => {
                                removeError();
                                const secArray = inputData;
                                secArray[index].otherDirectorDetailRole = e.target.value;
                                setInputData([...secArray]);
                              }}
                              placeholder='Provide details of role'

                            />
                            : null}
                        </div>



                      </Row>
                      <span className={`error erorrRole OtherdirectRole${index}`}></span>
                    </div>
                  </Row>



                  <Row>
                    <div className='col-md-8'>
                      <label>Is this you?</label>
                    </div>
                    <div className='col-md-4'>
                      <div className="button_group space-bottom">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank swtich11">
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id={`radio-one${index + 1}`}
                              className="yesno"
                              name="isCompanyDirectorYou"
                              value={[inputData[index].isCompanyDirectorYou]}
                              onChange={(e) => {
                                // setForm(e);
                                removeError();
                                const secArray = inputData;
                                secArray[index].isCompanyDirectorYou = 0;
                                setInputData([...secArray]);
                              }}
                            />
                            <label
                              for={`radio-one${index + 1}`}
                              className={`toogles postalAddressButton ${inputData[index].isCompanyDirectorYou === 0 ? `actives` : null
                                }`}
                              id={`show${index + 1}`}
                            >
                              No
                            </label>
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id={`radio-two${index + 1}`}
                              name="isCompanyDirectorYou"
                              value={[inputData[index].isCompanyDirectorYou]}
                              onChange={(e) => {
                                // setForm(e);
                                removeError();
                                const secArray = inputData;
                                secArray[index].isCompanyDirectorYou = 1;
                                setInputData([...secArray]);
                                inputData[index].userEmail = "";
                              }}
                            />
                            <label
                              for={`radio-two${index + 1}`}
                              className={`toogles postalAddressButtons ${inputData[index].isCompanyDirectorYou === 1 ? `actives` : null
                                }`}
                              id={`hide${index + 1}`}
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  {
                    inputData[index].isCompanyDirectorYou == false ?
                      <Row>
                        <Col md={6}>
                          <label>Email address</label>
                          <InputMask
                            disabled={formData.isPrimary == 1 ? false : true}
                            type="text"
                            name="userEmail"
                            autoComplete="off"
                            placeholder="Please provide email address for this person"
                            className="postal_address"
                            onKeyPress={removeError}
                            value={inputData[index].userEmail}
                            onChange={(e) => {
                              removeError();
                              const secArray = inputData;
                              secArray[index].userEmail = e.target.value;
                              setInputData([...secArray]);
                            }}
                          />
                          {/* <span className="error emailerror"></span> */}

                          <span className={`error email${index}`}></span>
                        </Col>
                      </Row>
                      : ""
                  }


                  <div className={`revove_btn removebt${index}`}>
                    {inputData.length > 1 && (
                      <button
                        disabled={formData.isPrimary == 1 ? false : true}
                        onClick={(e) => handleRemoveClick(index, e)}
                        name={x.fullLegalName}
                        value={x.userEmail}
                      >
                        Remove
                      </button>
                    )}
                  </div>

                </div>
              </>

            );
          })}
          <Row className="fullinput">
            <Col sm={12} className="Shareholder">
              <div className="Trust_ind_btn">
                {inputData.length <= 2 ? (
                  <button
                    disabled={formData.isPrimary == 1 ? false : true}
                    onClick={handleAddClick}>+Individual</button>
                ) : null}
              </div>
            </Col>
          </Row>



          <div className="spceimg ml-50">
            <span id="name_tag" class="preview2"></span>
            <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
          </div>
          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={Onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>

            <button type="submit" className="next flagsave" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div >
    </>
  );
};
export default EntityThird;
