// import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { LoginUrl } from '../../auth/Configuration';

export const infodetls =()=>{
   

    Swal.fire({
        html: `Save your application by clicking on the button “Save Data”. You can then logout and resume your application later. To resume your application, simply visit ${LoginUrl} and enter your username and password.` 
      });
}
