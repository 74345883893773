import React, { useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import info from '../info.png';
import MenuSidebar from './MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';

const Sixteen = ({ func, setForm, formData, navigation, saveAndExist, setValues, }) => {
  const {
    acquiringDisposingFinancialProductsOther
  } = formData;
  const { previous } = navigation;
  const saveandexit = () => {
    func();
  };


  function removeError() {
    $('.error').html('');
  }

  function handleClick(e) {

    formData.investorPrincipalBusiness = false
    formData.investingFinancialProducts = false
    formData.actingUnderwriter = false
    formData.financialAdviceService = false
    formData.clientMoneyPropertyService = false
    formData.tradingFinancialProducts = false
    formData.registeredBankRBNZ = false
    formData.NBDTInvestor = false
    formData.licensedIssuerInsurance = false
    formData.registeredSchemeManager = false
    formData.derivativesIssuer = false
    formData.FinancialAdvisor = false
    formData.ownedPortfolio2yrs = false
    formData.carriedTransactions2yrs = false
    formData.investmentBusiness10yrs = false
    formData.equitySecurities = false
    formData.managedFunds = false
    formData.derivativeInstruments = false
    formData.debtSecurities = false
    formData.twoYearInvestEntity1 = false
    formData.twoYearInvestEntity2 = false
    formData.twoYearInvestEntity = formData.twoYearInvestEntity == true ? true : false

    if ((formData.startupOrBusiness == false || formData.startupOrBusiness === undefined) &&
      (formData.investorPresentation == false || formData.investorPresentation === undefined) &&
      (formData.myOwnInvestmentDecisions == false || formData.myOwnInvestmentDecisions === undefined) &&
      (formData.valueAndRisksOfInvesting == false || formData.valueAndRisksOfInvesting === undefined) &&
      (formData.capitalRaisingDirector == false || formData.capitalRaisingDirector === undefined) &&
      (formData.investingSharesSecurities == false || formData.investingSharesSecurities === undefined) &&
      (formData.acquiringDisposingFinancialProducts == false || formData.acquiringDisposingFinancialProducts === undefined)

      // &&(acquiringDisposingFinancialProductsOther == null || acquiringDisposingFinancialProductsOther == "" ||
      // acquiringDisposingFinancialProductsOther == "undefined" || acquiringDisposingFinancialProductsOther == undefined)
    ) {
      $('.investmentBusiness').html('Please select an answer');
    }
    else {
      go('Seventeen');
    }


  }
  const { go } = navigation;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">

          {/* <h2>Wholesale Certification: Wholesale Certificate 3</h2> */}
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Wholesale Certificate B: Eligible Investor Certificate</h2> :
              <h2>Step 5: Wholesale Certificate B: Eligible Investor Certificate</h2>

          }
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>
          {/* <h3>Investor certificate</h3> */}
          <div className="grid-container">
            <div className="grid-item "></div>
            <div className="grid-item"></div>
            <div className="grid-item "></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item active"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
          </div>
          <p><b>Eligible Investor</b></p>
          {/* <p>
            To determine whether you are an eligible investor you will need to confirm your previous experience in acquiring or disposing of financial products that allows you to assess:
          </p> */}
          <p>
            1. I/WE have previous experience in acquiring or disposing of financial products* that allows me/us to assess:
          </p>
          <p className="tt">
            A). The merits of this offer and any financial advice service to be provided (including
            assessing the value and the risks of the financial products involved);
          </p>
          <p className="tt">
            B). My own information needs in relation to this offer and any financial advice
            service to be provided; and {' '}
          </p>
          <p className="tt">
            C). The adequacy of the information provided by any person involved in this offer and
            any financial advice service to be provided.
          </p>
          <br />
          <label className='font-fami'>
            The grounds for this certification are (select the ground(s) that apply to you from the following list):
          </label>
          {/* <br /> */}
          {/* <p>Select at least one of the below options</p> */}

          <div className="checkbox-my">
            <label className="containers restContainer font-fami">{
              formData.startupOrBusiness == true ?
                <b>I/We previously invested in early stage, start up, or established businesses.</b> :
                <p>I/We previously invested in early stage, start up, or established businesses.</p>
            }
              <input
                type="checkbox"
                disabled={formData.isPrimary == 0 ? true : false}

                name="startupOrBusiness"
                checked={formData.startupOrBusiness == true ? true : false}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers restContainer font-fami">{
              formData.investorPresentation == true ?
                <b>In the course of it’s investment activities, I/We have been involved in investor
                  presentations and/or due diligence meetings and through this have gained an
                  appreciation for the risks and opportunities associated with investing in businesses.</b> :
                <p>In the course of it’s investment activities, I/We have been involved in investor
                  presentations and/or due diligence meetings and through this have gained an
                  appreciation for the risks and opportunities associated with investing in businesses.</p>
            }
              <input
                disabled={formData.isPrimary == 0 ? true : false}

                type="checkbox"
                name="investorPresentation"
                checked={formData.investorPresentation == true ? true : false}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
              />
              <span className="checkmark"></span>
            </label>
            <label className="containers restContainer font-fami">{
              formData.capitalRaisingDirector == true ?
                <b>I/We have served as a Director of a business that was involved in capital raising.</b> :
                <p>I/We have served as a Director of a business that was involved in capital raising.</p>
            }
              <input
                type="checkbox"
                disabled={formData.isPrimary == 0 ? true : false}

                name="capitalRaisingDirector"
                checked={formData.capitalRaisingDirector == true ? true : false}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers restContainer font-fami">{
              formData.investingSharesSecurities == true ?
                <b>I/We have experience in investing in shares and other securities (listed and unlisted)
                  and understand the risks with investing in different types of financial products.</b> :
                <p>I/We have experience in investing in shares and other securities (listed and unlisted)
                  and understand the risks with investing in different types of financial products.</p>
            }
              <input
                type="checkbox"
                disabled={formData.isPrimary == 0 ? true : false}

                name="investingSharesSecurities"
                checked={formData.investingSharesSecurities == true ? true : false}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers restContainer font-fami">{
              formData.myOwnInvestmentDecisions == true ?
                <b>
                  I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                  benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                </b> :
                <p>I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                  benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                </p>
            }
              <input
                type="checkbox"
                disabled={formData.isPrimary == 0 ? true : false}

                name="myOwnInvestmentDecisions"
                checked={formData.myOwnInvestmentDecisions == true ? true : false}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers restContainer font-fami">{
              formData.valueAndRisksOfInvesting == true ?
                <b> In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                  this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                </b> :
                <p> In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                  this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                </p>
            }
              <input
                type="checkbox"
                disabled={formData.isPrimary == 0 ? true : false}

                name="valueAndRisksOfInvesting"
                checked={formData.valueAndRisksOfInvesting == true ? true : false}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers my font-fami">{
              formData.acquiringDisposingFinancialProducts == true ?
                <b>I/We have other relevant experience of acquiring or disposing of financial products,
                  details being the following:</b> :
                <p>I/We have other relevant experience of acquiring or disposing of financial products,
                  details being the following:</p>
            }
              <input
                type="checkbox"
                disabled={formData.isPrimary == 0 ? true : false}

                name="acquiringDisposingFinancialProducts"
                checked={formData.acquiringDisposingFinancialProducts == true ? true : false}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}

              />
              <span className="checkmark"></span>
            </label>
            <span className="error investmentProductExp"></span>
            {formData.acquiringDisposingFinancialProducts == true ?
              <div className="fullspace pandingfinsh Other">
                <textarea
                  className="mouse otherInput"
                  name="acquiringDisposingFinancialProductsOther"
                  value={acquiringDisposingFinancialProductsOther}
                  type="text"
                  placeholder="Please provide details"
                  onChange={(e) => {
                    setForm(e);
                    removeError();
                  }}
                ></textarea>
                <span className="error investmentProductExpOther"></span>
              </div> : null}

          </div>

          <span className="error investmentBusiness"></span>
          <div className="six"></div>
          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={previous}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            <button type="submit" className="next" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sixteen;
