import React, { useState, useEffect } from "react";
import leftarrow from "../leftarrow.png";
import rightarrow from "../rightarrow.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../logo.png";
import info from "../info.png";
import swal from "sweetalert";
import $ from "jquery";
import MenuSidebar from "../Individual/MenuSidebar";
// const { useState } = React;
const Jointninteen = ({
	setForm,
	formData,
	navigation,
	saveAndExist,
	infodetls,
}) => {
	const { isMinimumInvest } = formData;

	const { previous, next } = navigation;
	let setValues = formData.isMinimumInvest;
	useEffect(() => {
		localStorage.removeItem("step10");
		localStorage.removeItem("step15");
		localStorage.removeItem("step16");

		$(".toogles").click(function() {
			$(".toogles").removeClass("actives");
			$(this).addClass("actives");
		});
		$(".step-change").click(function() {
			setValues = $(this).html();

			if (setValues === "Yes") {
				$("#hidemyy").hide();
				$("#showmy").show();
			} else {
				$("#showmy").hide();
				$("#hidemyy").show();
			}
		});
		$(document).ready(function() {
			// $("#select_flag_button").click(function () {
			// let isMinimumInvest = true;
			localStorage.setItem("isMinimumInvest", isMinimumInvest);
			$(".yesno").click(function() {
				isMinimumInvest = $(this).val();
				localStorage.setItem("isMinimumInvest", isMinimumInvest);
			});
		});
	});
	function handleClick(e) {
		localStorage.setItem("setValues", setValues);
		formData.isMinimumInvest = setValues;
		if (setValues === "Yes") {
			go("Twenty");
		} else {
			next();
		}
	}
	const { go } = navigation;
	saveAndExist = () => {
		formData.status = "PENDING";
		formData.step = "17";
		// swal({
		//   text: "Your application saved",
		// });
		go("submit");
	};
	infodetls = () => {
		swal({
			text:
				"By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
		});
	};
	// let placeOfBirth;
	// formData.placeOfBirth = placeOfBirth;
	return (
		<>
		<MenuSidebar navigation={navigation} formData={formData}/>
		<div className="main_container">
		<div className="form">
			<img src={logo} alt="img" className="imageCover" />
			<div className="save-exit">
				<p className="save" onClick={saveAndExist}>
				SAVE DATA{" "}
				</p>
				<img src={info} onClick={infodetls} className="info" />
			</div>
			<h3>Investor certificate</h3>

			<div className="grid-container">
				<div className="grid-item "></div>
				<div className="grid-item"></div>
				<div className="grid-item "></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item active"></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
			</div>
			<p>Please answer the below information</p>
			<Row>
				<Col md={12}>
					<div className="button_group pos">
						<div class="switch-field nospace">
							<input
								type="radio"
								id="radio-two"
								className="yesno"
								name="isMinimumInvest"
								value="false"
							/>
							<label
								for="radio-two"
								className="toogles step-change actives"
								id="show"
							>
								No
							</label>
							<input
								type="radio"
								id="radio-one"
								className="yesno"
								name="isMinimumInvest"
								value="true"
							/>
							<label for="radio-one" className="toogles step-change " id="hide">
								Yes
							</label>
						</div>
					</div>
				</Col>
				<Col md={12}>
					<div className="fullspace pandingfinsh">
						<div id="hidemyy">
							<Col lg={12}>
								<label>
									Is the investing entity investing a minimum of
									<br />
									$750,000 into this offer?
								</label>
							</Col>
						</div>

						<div id="showmy">
							<Col lg={12}>
								<label>
									Is the investing entity investing a minimum of
									<br />
									$750,000 into this offer?
								</label>
								<p>
									If investing a minimum of $750,000 upon acceptance in
									accordance with Schedule 1, clause 3(3)(b)(i) or (ii) of the
									FMCA.
									<br />
									I/We confirm that:
								</p>
								<ul className="usual">
									<li>
										The usual legal rules that require information to be given
										to investors for offers of financial products do not apply
										if the amount invested upon acceptance by me/us (plus any
										other investments I/we have already made in those financial
										products) is $750,000 or more; and
									</li>
									<li>
										I/We may not receive a complete and balanced set of
										information about this investment; and
									</li>
									<li>
										I/We have fewer legal protections for this investment; and
									</li>
									<li>
										This investment is not suitable for retail investors; and
									</li>
									<li>
										I/We have been advised to ask questions, read all documents
										carefully, and seek independent financial advice.
									</li>
								</ul>
							</Col>
						</div>
					</div>
				</Col>
			</Row>

			<br />
			<div className="fotter-btn">
				<button className="preview" onClick={previous}>
					<img src={leftarrow} className="leftarrow" alt=""/>
					Previous
				</button>
				<button type="submit" className="next" onClick={handleClick}>
					Continue
					<img src={rightarrow} className="rightarrow"  alt=""/>{" "}
				</button>
			</div>
		</div>
		</div>
		</>
	);
};

export default Jointninteen;
