import React, { useState, useEffect } from "react";
import leftarrow from "../leftarrow.png";
import rightarrow from "../rightarrow.png";
import attch from "../attch.png";
import Container from "react-bootstrap/Container";
import ItemForm from "../ItemForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import logo from "../logo.png";
import InputMask from "react-input-mask";
import info from "../info.png";
import $ from "jquery";
import swal from "sweetalert";
import MenuSidebar from "../Individual/MenuSidebar";
// const { useState } = React;
const Eight = ({
	bankowner,
	setForm,
	formData,
	navigation,
	saveAndExist,
	func,
	infodetls,
}) => {
	const {
		firstNames,
		lastNames,
		isNZBankone,
		accountNameone,
		accountNumberone,
		swiftCodeone,
	} = formData;
	const { previous, next } = navigation;
	const [state, setState] = useState({ shown: true, hide: false });
	const [file, setFile] = useState(null);
	let JointAcount = sessionStorage.getItem("Jointacoount");
	async function uploadWithJSON() {
		const toBase64 = (file) =>
			new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		const data = {
			img64: await toBase64(file),
		};
		next();
		localStorage.setItem("bankFileone", data.img64);
	}
	let applicationType = sessionStorage.getItem("applicationType");
	useEffect(() => {
		applicationType === "Trust" ? next() : console.log("error");
	}, []);

	useEffect(() => {
		let multipleBankAccount = sessionStorage.getItem("multipleBankAccount");
		{
			multipleBankAccount == "0"
				? go("Jointseventeen")
				: console.log("Accoount2");
		}
		// let dataStr = sessionStorage.getItem("allData");
		// let dataObj = JSON.parse(dataStr);
		// $(".againFile").text(dataObj.bankFile)
		$(document).ready(function() {
			$("#hide").click(function() {
				$("#showmy").hide();
				$("#hidemyy").show();
			});

			$("#show").click(function() {
				$("#hidemyy").hide();
				$("#showmy").show();
			});
		});
		$(".toogles").click(function() {
			$(".toogles").removeClass("actives");
			$(this).addClass("actives");
		});
		function readURL(input, imgControlName) {
			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.onload = function(e) {
					$(imgControlName).attr("src", e.target.result);
				};
				reader.readAsDataURL(input.files[0]);
				$("#name_tag").text(input.files[0].name);
			}
		}

		$("#imag2").change(function() {
			var imgControlName = "#ImgPreview2";
			readURL(this, imgControlName);
			$(".preview2").addClass("it");
			$(".btn-rmv2").addClass("rmv");
		});

		$("#removeImage2").click(function(e) {
			e.preventDefault();
			$("#imag2").val("");
			$("#ImgPreview2").attr("src", "");
			$(".preview2").text("");
			$(".btn-rmv2").removeClass("rmv");
		});

		$(document).ready(function() {
			// $("#select_flag_button").click(function () {
			let isNZBank = isNZBankone;
			localStorage.setItem("isNZBank", isNZBank);
			$(".yesno").click(function() {
				isNZBank = $(this).val();
				formData.isNZBankone = isNZBank;

				localStorage.setItem("isNZCitizen", isNZBank);
			});
		});
	});

	function handleClick(e) {
		var btnVal = $(".postalAddressButton").hasClass("actives");
		var btnVals = $(".postalAddressButtons").hasClass("actives");
		let name = $("#name_tag").text();
		let emailExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let nameExpression = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;

		if (
			btnVal &&
			(typeof formData.accountNameone == "undefined" ||
				formData.accountNameone === "" ||
				formData.accountNameone === null)
		) {
			$(".accountName").html("Please enter bank account name");
		} else if (
			btnVal &&
			(typeof formData.accountNumberone == "undefined" ||
				formData.accountNumberone === "" ||
				formData.accountNumberone === null)
		) {
			$(".accountNumber").html(
				"Please enter bank account number in format xx-xxxx-xxxxxxx-xx"
			);
		} else if (btnVal && formData.accountNumberone.length < 18) {
			$(".accountNumber").html(
				"Please enter bank account number in the format xx-xxxx-xxxxxxx-xx"
			);
		} else if (
			btnVals &&
			(typeof formData.accountNameone == "undefined" ||
				formData.accountNameone === "" ||
				formData.accountNameone === null)
		) {
			$(".accountName").html("Please enter bank account name");
		} else if (
			btnVals &&
			(typeof formData.swiftCodeone == "undefined" ||
				formData.swiftCodeone === "" ||
				formData.swiftCodeone === null)
		) {
			$(".swiftCode").html("Please enter bank swift code");
		} else if (
			btnVals &&
			(typeof formData.accountNumberone == "undefined" ||
				formData.accountNumberone === "" ||
				formData.accountNumberone === null)
		) {
			$(".accountNumber").html("Please enter  Account/IBAN number");
		} else if (name === "") {
			$(".file").html("Please upload proof of account");
		} else if (!nameExpression.test(name)) {
			$(".file").html("Please only upload png, jpg and pdf");
		} else {
			uploadWithJSON();
		}
	}
	function removeError() {
		$(".error").html("");
	}
	const { go } = navigation;
	saveAndExist = () => {
		formData.status = "PENDING";
		formData.step = "17";
		// swal({
		//   text: "Your application saved",
		// });
		func();
	};
	infodetls = () => {
		swal({
			text:
				"By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
		});
	};
	bankowner = () => {
		const wrapper = document.createElement("div");
		wrapper.innerHTML =
			"<ul class='error-list'>Bank account details are required as investment returns will not be issued by cheque. Please ensure that the bank account you provide is in the same name as the investment owner/entity in this application.</ul>";

		swal({
			text: "",
			content: wrapper,
		});
	};
	return (
		<>
		
		<MenuSidebar navigation={navigation} formData={formData}/>
		<div className="main_container">
		<div className="form">
			<img src={logo} alt="img" className="imageCover" />
			{!JointAcount ? (
				<>
					<div className="save-exit joint-holder">
        <p className="save" onClick={saveAndExist}>
		SAVE DATA{" "}
						</p>
						<img src={info} onClick={infodetls} className="info" />
					</div>
				</>
			) : null}
			<h3>
				Bank account details{" "}
				<img src={info} onClick={bankowner} className="infos" />
			</h3>
			<div className="grid-container">
				<div className="grid-item "></div>
				<div className="grid-item "></div>
				<div className="grid-item "></div>
				<div className="grid-item"></div>
				<div className="grid-item "></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
			</div>
			<p>
				Please provide details for the bank account in the name of {firstNames}{" "}
				{lastNames != "undefined" && lastNames != null ? lastNames : ""}
			</p>

			<Row className="fullinput">
				<Col xs={8}>
					<label>Is this a New Zealand Bank account</label>
				</Col>
				<Col xs={4}>
					<div className="button_group space-bottom">
						<div class="switch-field nospace">
							<input
								type="radio"
								id="radio-two"
								name="isNZBankone"
								value="false"
								onChange={(e) => {
									setForm(e);
									removeError();
								}}
							/>
							<label
								for="radio-two"
								className={`toogles postalAddressButtons ${
									isNZBankone === "false" ? "actives" : null
								}`}
								id="show"
							>
								No
							</label>
							<input
								type="radio"
								id="radio-one"
								className="yesno"
								name="isNZBankone"
								value="true"
								onChange={(e) => {
									setForm(e);
									removeError();
								}}
							/>
							<label
								for="radio-one"
								className={`toogles postalAddressButton ${
									typeof isNZBankone === "undefined" || isNZBankone === "true"
										? "actives"
										: null
								}`}
								id="hide"
							>
								Yes
							</label>
						</div>
					</div>
				</Col>

				<div className="fullspace">
					{isNZBankone === "true" ||
					isNZBankone === null ||
					typeof isNZBankone === "undefined" ? (
						<div id="">
							<Col xs={12}>
								<ItemForm
									label="Bank account name "
									className="quantity"
									name="accountNameone"
									value={accountNameone}
									onChange={(e) => {
										setForm(e);
										removeError();
									}}
									type="text"
									placeholder="Enter account name"
									onKeyPress={removeError}
								/>
								<span className="error accountName"></span>
								<label>Bank account number</label>
								<InputMask
									mask="99-9999-9999999-99"
									maskPlaceholder={null}
									className="numbers"
									name="accountNumberone"
									type=""
									value={accountNumberone}
									onChange={(e) => {
										setForm(e);
										removeError();
									}}
									placeholder="XX-XXXX-XXXXXXX-XX"
									onKeyPress={removeError}
								/>
								<span className="error accountNumber"></span>
							</Col>
						</div>
					) : (
						<div id="">
							<Col xs={12}>
								<label>Bank account name</label>
								<ItemForm
									className="quantity"
									name="accountNameone"
									type="text"
									value={accountNameone}
									onChange={(e) => {
										setForm(e);
										removeError();
									}}
									onKeyPress={removeError}
									placeholder="Enter account name"
								/>
								<span className="error accountName"></span>
								<label>Bank swift code</label>
								<ItemForm
									className="digitsOnly"
									type=""
									name="swiftCodeone"
									value={swiftCodeone}
									onChange={(e) => {
										setForm(e);
										removeError();
									}}
									placeholder="Enter swift code"
									onKeyPress={removeError}
								/>{" "}
								<span className="error swiftCode"></span>
								<label>Account/IBAN Number</label>
								<ItemForm
									className="numbers"
									type="text"
									name="accountNumberone"
									value={accountNumberone}
									onChange={(e) => {
										setForm(e);
										removeError();
									}}
									placeholder="Enter account number"
									onKeyPress={removeError}
								/>
								<span className="error accountNumber"></span>
							</Col>
						</div>
					)}
				</div>

				{/* <Col xs={12}>
  
      <Search   placeholder="Enter account name"/>
  
      </Col> */}
			</Row>

			<Row className="fullinput">
				<Col xs={1}>
					<div class="yes">
						<span class="btn_upload">
							<input
								type="file"
								id="imag2"
								title=""
								onClick={removeError}
								onChange={(e) => setFile(e.target.files[0])}
							/>
							<img src={attch} />
						</span>
					</div>
				</Col>
				<Col xs={11} className="pl-0">
					<label>Upload proof of account</label>
					<span className="error file ml-0"></span>
				</Col>
			</Row>
			<div className="spceimg ml-50">
				<span id="name_tag" class="preview2"></span>
				<input type="button" id="removeImage2" value="x" class="btn-rmv2" />
			</div>

			<br />

			<div className="fotter-btn">
				<button className="preview" onClick={previous}>
					<img src={leftarrow} className="leftarrow" alt=""/>
					Previous
				</button>
				<button type="submit" className="next flagsave" onClick={handleClick}>
					Continue
					<img src={rightarrow} className="rightarrow"  alt=""/>{" "}
				</button>
			</div>
		</div>
		</div>
		</>
	);
};

export default Eight;
