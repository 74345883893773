import React from "react";
import $ from "jquery";
function removeError(id) {
	$(".name_" + (id + 1)).html("");
}
function removeError2(id) {
	$(".benDateOfBirth_" + (id + 1)).html("");
}

const ItemForm = ({
	label,
	children,
	type,
	maxLength,
	deleteValue,

	...otherProps
}) => (
	<div>
		{type === "text" ? (
			<>
				<label>{label}</label>
				<input
					type={type}
					onKeyPress={() => removeError(deleteValue)}
					{...otherProps}
					maxLength={maxLength}
				/>
			</>
		) : (
			<>
				<label />

				<input
					type={type}
					// onkeyPress={() => removeError2(deleteValue)}
					onPaste={() => removeError2(deleteValue)}
					{...otherProps}
					maxLength={maxLength}
				/>
				{label}
			</>
		)}
	</div>
);

export default ItemForm;
