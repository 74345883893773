import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
// material
// import { styled } from '@mui/material/styles';
// import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
//import AuthLayout from '../layouts/AuthLayout';
// components
// import Page from '../components/Page';
import LoginButton from '../auth/LoginButton';
// import ReactFlagsSelect from 'react-flags-select';
// import { MHidden } from '../components/@material-extend';
// import { RegisterForm } from '../components/authentication/register';
// import AuthSocial from '../components/authentication/AuthSocial';
// import Register2 from './Register2';
import logo from '../pages/logo.png';
import rightarrow from '../pages/rightarrow.png';
import useQuery from './useQuery';
// import { useAuth0 } from '@auth0/auth0-react';

// ----------------------------------------------------------------------

// const RootStyle = (({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex'
//   }
// }));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2)
// }));

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   display: 'flex',
//   minHeight: '100vh',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(12, 0)
// }));

// ----------------------------------------------------------------------

export default function Register() {
  const [ss, vsj] = useState();

  const query = useQuery();

  let queryValue = query.get("update") || "";
  const queryCheck = query.get("name") || "";
  let rbtn = query.get("rbtn") || "";
  // console.log('query',query)

  useMemo(() => {
    // alert(rbtn)
    if (queryCheck) {
      sessionStorage.setItem("queryValue", queryValue);
      sessionStorage.setItem("queryCheck", queryCheck);
      // sessionStorage.setItem("rbtn", rbtn);
     
      // console.log('rbtn',rbtn)
      // alert(queryCheck)


    }
  }, [queryCheck == true])
  useMemo(() => {
    // alert(rbtn)
    if (rbtn) {
    
      sessionStorage.setItem("rbtn", rbtn);
     
      // console.log('rbtn',rbtn)
      // alert(queryCheck)


    }
  }, [rbtn == true])


  return (
    <div className='main_reg_page bg_bacv'>
      <div className="welcome__page">
        <div className="welcome-header">
          <a href="#">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="wel-come_content">
          <h3>Investment Application Form</h3>
          <div className="grid-container">
            <div className="grid-item active"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
          </div>
          <p>Welcome to the MyFarm online application.</p>
          <p>
            You'll need to complete this online application and provide information about
            yourself, and any joint account investment holders.
          </p>
          <p>To complete your application, you will need the following information on hand:</p>
          <ul className="welcome-space">

            <li>Photo ID (e.g. Passport)</li>
            <li>Bank Account Details (e.g. Bank statement)</li>
            <li>IRD Number</li>
          </ul>

          <div className="fotter-btn   registercss regiiister">

            {(rbtn == true || rbtn == "true" ) ? (
              <div className='loginbuttton'>
                <LoginButton />
              </div>
            ) : (
              <>
                <RouterLink to="/register2">
                  START NEW APPLICATION <img src={rightarrow} className="rightarrow" alt="" />
                </RouterLink>
                <div className='loginbuttton'>
                  <LoginButton />
                </div>
              </>
            )}

            {/* <RouterLink to="/register2">
              START NEW APPLICATION <img src={rightarrow} className="rightarrow" alt="" />
            </RouterLink>
            <div className='loginbuttton'>
              <LoginButton />
            </div> */}

          </div>
        </div>
      </div>
    </div>
  );
}
