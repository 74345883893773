import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import axios from 'axios';
import logo from '../logo.png';
import $, { now } from 'jquery';
import info from '../info.png';
import swal from 'sweetalert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
// import 'antd/dist/antd.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
// import { Col } from 'react-bootstrap';

const TrustFour = ({
  setForm,
  formData,
  navigation,
  saveAndExist,
  infodetls,
  idinfo,
  func }) => {
  const {
    optionsd,
    // setOptionsd,
    secondholder,
    idType2,
    firstlast_name,
    emailadd,
    isCompanyDirectorYou,
    userEmail,
    TrustDirectorYou,
    professionalTrust,
    isPrimaryContact,
    fullLegalName
  } = formData;

  const { previous, next } = navigation;
  const [searchTerm, setSearchTerm] = useState("")
  const [options, setOptions] = useState()
  // const [optionsd, setOptionsd] = useState()

  const [optionsnumber, setOptionsnumber] = useState()

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const saveandexit = () => {
    func();
  };

  console.log("options", options)
  const [running, setRunning] = useState()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm != null && searchTerm != "" && searchTerm != "undefined") {
        setRunning(true)
      }

      console.log(searchTerm)
      axios.get(`https://xl4q10bqil.execute-api.ap-southeast-2.amazonaws.com/dev/company/search/${searchTerm}`)
        .then((result) => {
          setRunning(false)
          setOptions(result.data.items)

        })
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const [directorRoles, setDirectorRoles] = useState([])
  const Companysearch = (value) => {
    axios.get(`https://xl4q10bqil.execute-api.ap-southeast-2.amazonaws.com/dev/company/director/${value}`,
      {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((result) => {
        console.log("result.data1111", result)

        setDirectorRoles(result.data.directorName)
        // formData.directorRoles = result.data.directorName;
      })


  }

  const [namefull1, setNamefull1] = useState(secondholder ? secondholder.split(' ') : '');

  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];

  formData.firstName1 = namefull1[0];

  if (namefull1.length > 1) {
    formData.lastName1 = namefull1[namefull1.length - 1];
  }

  if (namefull1.length > 2) {
    formData.middleName1 = namefull1
      .slice(1, namefull1.length - 1)
      .join()
      .replace(/,/g, ' ');
  }
  function handleClick(e) {
    if (optionsd == "" || optionsd == null || optionsd == undefined || optionsd == 'undefined') {
      $('.optionsd').html('Please enter name')
    } else {
      formData.class = 'Third';
      go('ThirdTrust');
    }

  }
  function removeError() {
    $('.error').html('');
  }
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '5';

    go('submit');
  };

  idinfo = () => {
    swal({
      text: 'MyFarm is required to comply with the New Zealand Government regulations to fight the funding of terrorism and money laundering activities. We are required to obtain, verify, and record information about your identity.'
    });
  };
  const { go } = navigation;
  const [nextt, setNextt] = useState(false);
  const [active, setActive] = useState('first');
  const chenging = () => {
    setNextt(true);
    setActive('second');
  };

  const chenging2 = () => {
    setNextt(false);
    setActive('first');
  };

  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });
  const [showing, setShowing] = useState(true)
  const showed = () => {
    if (showing == true) {
      setShowing(false)
    }
    else {
      setShowing(true)
    }

  }
  const shooowed = () => {
    if (showing == false) {
      setShowing(true)
    }
    else {
      setShowing(true)
    }
  }

  formData.optionsd = optionsd;
  // formData.setOptionsd() = setOptionsd();
  // formData.fullLegalName = fullLegalName;
  formData.directorRoles = directorRoles;
  const [primaryYes, setPrimary] = useState(true)
  // alert(optionsd)
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2>Step 2: Trust Details </h2>
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff joint-holder">
            <p className="save next" onClick={saveandexit}>SAVE DATA </p>
            <img src={info} className="info" onClick={idinfo} />
          </div>

          <div className="div_start spinnnknsd">
            <p className="please">Name of Corporate Trustee Company</p>

            <div className='spiner_loa'>
              <input
                                    disabled={formData.isPrimary == 1 ? false : true}

                name="professionalTrust"
                value={optionsd}
                className="start"
                type="text"
                onClick={() => shooowed()}
                placeholder="Start typing the name of the Company and then select"
                onChange={(e) => {
                  // setOptionsd()
                  // setOptionsd(entityName);
                  formData.optionsd = e.target.value;
                  setForm(e);
                  setSearchTerm(e.target.value)
                  removeError()

                }}
              />
              <span className='error optionsd'></span>
              {
                running == true ? <Spin indicator={antIcon} className="spinnner cust_loader" /> : null
              }
            </div>


            {showing == true ?
              options && options.map((ttt) => {
                // console.log("kkkk", ttt)
                return (
                  <button
                    className="trustSix"
                    onClick={() => {
                      // setOptionsd(ttt.entityName);
                      formData.optionsd = (ttt.entityName);
                      Companysearch(ttt.nzbn);
                      showed();


                    }

                    } >{ttt.entityName}</button>
                )
              }) : ""}

          </div>

          <div>
            <p className="please">
              Please provide the email address for each director below, and select who is the primary contact for this trust.
            </p>
          </div>

          {directorRoles && directorRoles.map((ppp, index) => {
            return (

              <div className="trust_form trust-form-2">
                <div className="abcd abcd2 abcd3 ">
                  <div className='prmry_cont w-50'>
                    <input
                                    disabled={formData.isPrimary == 1 ? false : true}

                      className="abc def"
                      type="text"
                      placeholder="First name & last name of Director"
                      name="firstlast_name"
                      // value={ppp.fullLegalName }
                      defaultValue={
                        directorRoles[index].fullLegalName
                      }
                      onChange={(e) => {
                        removeError();
                        const secArray = directorRoles;
                        secArray[index].fullLegalName = e.target.value;
                        setDirectorRoles([...secArray]);
                      }}
                    />

                    <span className='error firstlast_name'></span>
                  </div>
                  <div className="select_pri_cont d-flex align-items-center flex-wrap w-50">
                    <div className='check-mb w-100 d-flex align-items-center justify-content-end flex-wrap'>
                      <div className='s-holder'>
                        <label>Is this you?</label>
                      </div>
                      <div className='y-n-btn'>
                        <div className="button_group space-bottom mb-0">
                          <div class="switch-field nospace">
                            <div className="check_YN_Bank  bank-2">
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="radio"
                                id={`radio-one${index + 1}`}
                                className="yesno"
                                name="isCompanyDirectorYou"
                                //   onClick={()=>handler(index)}
                                value={directorRoles[index].isCompanyDirectorYou}
                                onChange={(e) => {
                                  // setForm(e);
                                  // removeError();
                                  const secArray = directorRoles;
                                  secArray[index].isCompanyDirectorYou = 0;
                                  setDirectorRoles([...secArray]);
                                }}
                              />
                              <label
                                for={`radio-one${index + 1}`}
                                className={`toogles postalAddressButton ${typeof directorRoles[index].isCompanyDirectorYou === 'undefined' ||
                                  directorRoles[index].isCompanyDirectorYou === 0
                                  ? 'actives'
                                  : null
                                  }`}
                                id="show"
                              >
                                No
                              </label>
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="radio"
                                id={`radio-two${index + 1}`}
                                name="isCompanyDirectorYou"
                                value={directorRoles[index].isCompanyDirectorYou}
                                onChange={(e) => {
                                  // setForm(e);
                                  // removeError();
                                  const secArray = directorRoles;
                                  secArray[index].isCompanyDirectorYou = 1;
                                  setDirectorRoles([...secArray]);
                                }}
                              />
                              <label
                                for={`radio-two${index + 1}`}
                                className={`toogles postalAddressButtons ${directorRoles[index].isCompanyDirectorYou === 1 ? `actives` : null
                                  }`}
                                id="hide"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <di v className=" check-mb w-100 d-flex align-items-center justify-content-end">
                      <div className='s-holder'>
                        <label>Primary Contact ?</label>
                      </div>
                      <div className='y-n-btn'>
                        <div className="button_group space-bottom mb-0">
                          <div class="switch-field nospace">
                            <div className="check_YN_Bank bank-2">
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="radio"
                                id={`radio-yyy${index + 1}`}
                                className="yesno"
                                name="isPrimaryContact"
                                //   onClick={()=>handler(index)}
                                defaultValue={directorRoles[index].isPrimaryContact == undefined || directorRoles[index].isPrimaryContact == 0 ? directorRoles[index].isPrimaryContact = 0 : directorRoles[index].isPrimaryContact = 1}
                                onChange={(e) => {
                                  setForm(e);
                                  // removeError();
                                  const secArray = directorRoles;
                                  secArray[index].isPrimaryContact = 0;


                                  setDirectorRoles([...secArray]);
                                }}
                              />

                              <label
                                for={`radio-yyy${index + 1}`}
                                className={`toogles postalAddressButton ${typeof directorRoles[index].isPrimaryContact === 'undefined' ||
                                  directorRoles[index].isPrimaryContact === 0
                                  ? 'actives'
                                  : null
                                  }`}
                                id={`show${index}`}
                              >
                                No
                              </label>
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="radio"
                                id={`radio-uuu${index + 1}`}
                                name="isPrimaryContact"
                                className="yesno"
                                value={directorRoles[index].isPrimaryContact}
                                onChange={(e) => {
                                  setForm(e);
                                  // removeError();
                                  // setPrimary(true);
                                  const secArray = directorRoles;
                                  secArray[index].isPrimaryContact = 1;
                                  for (let i = 0; i < secArray.length; i++) {
                                    if (i != index) {
                                      secArray[i].isPrimaryContact = 0;
                                    }
                                  }
                                  setDirectorRoles([...secArray]);
                                }}
                              />
                              <label
                                for={`radio-uuu${index + 1}`}
                                className={`toogles postalAddressButtons ${directorRoles[index].isPrimaryContact === 1 ? `actives` : null
                                  }`}
                                id={`hide${index}`}
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </di>
                  </div>
                  {
                    directorRoles[index].isCompanyDirectorYou == 0 ?
                      <div className='d-email w-50'>
                        <div className='d-email-adrs w-100'>
                          {/* <label>Email address of shareholder</label> */}
                          <input
                            disabled={formData.isPrimary == 1 ? false : true}
                            name="userEmail"
                            autoComplete="off"
                            className="capitaluserEmail w-100"
                            placeholder="Enter the Director's email address"
                            // className="postal_address quantity"
                            onKeyPress={removeError}
                            onChange={(e) => {
                              removeError();
                              const secArray = directorRoles;
                              secArray[index].userEmail = e.target.value;
                              setDirectorRoles([...secArray]);
                            }}
                            value={directorRoles[index].userEmail}
                          />
                          <span className={`error email${index}`}></span>
                          {/* <span className="error emailerror"></span>  issue Number 10 */}
                        </div>
                      </div>
                      : null
                  }

                  {/* <input
                    className="abc"
                    type="text"
                    placeholder="Enter the Director's email address"
                    name="emailadd"
                    value={directorRoles[index].emailadd}
                    onChange={(e) => {
                      removeError();
                      const secArray = directorRoles;
                      secArray[index].emailadd = e.target.value;
                      setDirectorRoles([...secArray]);
                    }}
                   />
                   <span className={`error email`}></span> */}
                  {/* </div> */}
                </div>



                <div class="switch-field nospace nospace-2">
                  {/* <p className="please ">Primary Contact?</p> */}
                  {/* <div className="check_YN_Bank bank-2">
                    <input
                      //disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id={`radio-one${index + 1}`}
                      className="yesno"
                      name="TrustDirectorYou"
                      value={false}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                        const secArray = directorRoles;
                        secArray[index].TrustDirectorYou = 0;
                        setDirectorRoles([...secArray]);
                      }}
                    />
                    <label
                      for={`radio-one${index + 1}`}
                      className={`toogles postalAddressButton ${directorRoles[index].TrustDirectorYou === false ? `actives` : null
                        }`}
                      id={`show${index + 1}`}
                    >
                      No
                    </label>
                    <input
                      //disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id={`radio-two${index + 1}`}
                      name="TrustDirectorYou"
                      value={true}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                        const secArray = directorRoles;
                        secArray[index].TrustDirectorYou = 1;
                        setDirectorRoles([...secArray]);

                      }}
                    />
                    <label
                      for={`radio-one${index + 1}`}
                      className={`toogles postalAddressButtons ${directorRoles[index].TrustDirectorYou === true ? `actives` : null
                        }`}
                      id={`hide${index + 1}`}
                    >
                      Yes
                    </label>
                  </div> */}
                  {/*  */}
                  {/* <div className="check_YN_Bank ">
                    <input
                      //disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id={`radio-two${index + 1}`}
                      className="yesno" 
                      name="isPrimaryContact"
                      //   onClick={()=>handler(index)}
                      value={directorRoles[index].isPrimaryContact}
                      onChange={(e) => {
                        // setForm(e);
                        // removeError();
                        const secArray = directorRoles;
                        secArray[index].isPrimaryContact = 0;
                        setDirectorRoles([...secArray]);
                      }}
                    />
                    <label
                      for={`radio-one${index + 1}`}
                      className={`toogles postalAddressButton ${typeof directorRoles[index].isPrimaryContact === 'undefined' ||
                        directorRoles[index].isPrimaryContact === 0
                        ? 'actives'
                        : null
                        }`}
                      id="show"
                    >
                      No
                    </label>
                    <input
                      //disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id={`radio-two${index + 1}`}
                      name="isPrimaryContact"
                      value={directorRoles[index].isPrimaryContact}
                      onChange={(e) => {
                        // setForm(e);
                        // removeError();
                        const secArray = directorRoles;
                        secArray[index].isPrimaryContact = 1;
                        setDirectorRoles([...secArray]);
                      }}
                    />
                    <label
                      for={`radio-two${index + 1}`}
                      className={`toogles postalAddressButtons ${directorRoles[index].isPrimaryContact === 1 ? `actives` : null
                        }`}
                      id="hide"
                    >
                      Yes
                    </label>
                  </div> */}
                  {/*  */}

                  {/* <div className="check_YN_Bank">
                    <input
                      type="radio"
                      id={`radio-one ${index + 1}`}
                      className="yesno"
                      name="isUserAddressSame2"
                      value="true"
                    />
                    <label
                      for={`radio-one ${index + 1}`}
                      className={`  ${active === 'first' ? 'activeButton' : ''}`}
                      onClick={() => {
                        chenging2();
                      }}
                      id="hide"
                    >
                      No
                    </label>
                    <input
                      autoComplete="off"
                      type="radio"
                      id={`radio-two${index + 1}`}
                      name="isUserAddressSame2"
                      value="false"
                    />
                    <label
                      for={`radio-two${index + 1}`}                  
                      className={` ${active === 'second' ? 'activeButton' : ''}`}
                      onClick={() => {
                        chenging();
                      }}
                      id="show"
                    >
                      Yes
                    </label>
                  </div> */}
                </div>
              </div>

            )
          })}


          {directorRoles == "" || directorRoles == null || directorRoles == "undefined" || directorRoles == undefined ?

            <div className="trust_form  tust-form-2">
              <div className="abcd abcd2">
                <input
                                    disabled={formData.isPrimary == 1 ? false : true}

                  className="abc"
                  type="text"
                  placeholder="First name & last name of Director"
                  name="fullLegalName"
                  value={fullLegalName}
                  onChange={(e) => {
                    // setDirectorRoles([...secArray]);
                    setForm(e);

                  }}
                />
                <span className='error firstlast_name'></span>


                <input
                                    disabled={formData.isPrimary == 1 ? false : true}

                  className="abc"
                  type="text"
                  placeholder="Enter the Director's email address"
                  name="userEmail"
                  value={userEmail}
                  onChange={(e) => {
                    setForm(e);

                  }}
                />
                <span className='error emailvalue'></span>
              </div>

              <div class="switch-field nospace nospace-2">
                <p className="please">Primary Contact?</p>
                <div className="check_YN_Bank bank-2">
                  <input
                                    disabled={formData.isPrimary == 1 ? false : true}

                    type="radio"
                    id="radio-one"
                    className="yesno"
                    name="isUserAddressSame2"
                    value="true"
                  />
                  <label
                    for="radio-one"
                    className={`  ${active === 'first' ? 'activeButton' : ''}`}
                    onClick={() => {
                      chenging2();
                    }}
                    id="hide"
                  >
                    No
                  </label>
                  <input
                    autoComplete="off"
                    type="radio"
                    id="radio-two"
                    name="isUserAddressSame2"
                    value="false"
                  />
                  <label
                    for="radio-two"
                    className={` ${active === 'second' ? 'activeButton' : ''}`}
                    onClick={() => {
                      chenging();
                    }}
                    id="show"
                  >
                    Yes
                  </label>
                </div>
              </div>
            </div>
            : null}

          <div className="fotter-btn">
            <button className="preview" onClick={previous}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            <button type="submit" className="next flagsave" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrustFour;