import React, { useState, useEffect, useRef } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import info from '../info.png';
import swal from 'sweetalert';
import $ from 'jquery';
import MenuSidebar from './MenuSidebar';
import logoBlack from '../logoBlack.png';
import SignaturePad from 'react-signature-pad-wrapper';
import axios from 'axios';
import { multisavedata } from '../../auth/Configuration';
import Option from './Option';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';




const Ten = ({ setForm, formData, navigation, func }) => {
  const { isMinimumInvest, secondholder, fullName, OptionFund3, OptionFund4 } = formData;
  let setValues = localStorage.getItem('setValues');
  {
    setValues === 'Yes' ? (setValues = 'Yes') : (setValues = 'No');
  }
  function removeError() {
    $('.error').html('');
  }
  const query = useQuery();

  let queryValue = query.get("update");
  const saveandexit = () => {

    if (formData.isMinimumInvest == "No" || formData.isMinimumInvest == "No") {
      formData.usualLegalRules = false
      formData.notCompleteInformation = false
      // formData.materialExtent10yrs = false
      formData.fewerLegalProtection = false
      formData.notSuitableInvestment = false
      formData.seekFinancialAdvice = false
      func();

    }
    else if (formData.isMinimumInvest == "Yes") {

      if (formData.usualLegalRules === true
        && formData.notCompleteInformation === true
        && formData.fewerLegalProtection === true
        && formData.notSuitableInvestment === true
        && formData.seekFinancialAdvice === true) {

        formData.nineTick = true
        formData.class = 'Nine'
        formData.CertificationValue = true

        formData.certificateNumber = 2
        formData.investorPrincipalBusiness = false
        formData.investingFinancialProducts = false
        formData.actingUnderwriter = false
        formData.financialAdviceService = false
        formData.clientMoneyPropertyService = false
        formData.tradingFinancialProducts = false
        formData.registeredBankRBNZ = false
        formData.NBDTInvestor = false
        formData.licensedIssuerInsurance = false
        formData.registeredSchemeManager = false
        formData.derivativesIssuer = false
        formData.FinancialAdvisor = false
        formData.investmentBusinessNone = false
        formData.ownedPortfolio2yrs = false
        formData.carriedTransactions2yrs = false
        formData.investmentBusiness10yrs = false
        formData.equitySecurities = false
        formData.managedFunds = false
        formData.derivativeInstruments = false
        formData.debtSecurities = false
        formData.investmentCriteriaNone = false
        formData.startupOrBusiness = false
        formData.investorPresentation = false
        formData.myOwnInvestmentDecisions = false
        formData.valueAndRisksOfInvesting = false
        formData.capitalRaisingDirector = false
        formData.investingSharesSecurities = false
        formData.acquiringDisposingFinancialProducts = false
        formData.acquiringDisposingFinancialProductsOther = ""
        // formData.rwt12 = null
        // formData.userEmail1 = null
        // formData.fullLegalNamesign = null

        func();

      }
      else {
        $('.Certificate2').html('Please review each of the statements here and confirm your understanding by ticking each box');
      }
    }
  }
  const [namefull1, setNamefull1] = useState(secondholder ? secondholder.split(' ') : '');
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];

  if (secondholder !== null && secondholder !== "" && secondholder !== "undefined") {
    formData.firstNameJoint = ""
    formData.middleNamejoint = ""
    formData.lastNamejoint = ""
    formData.firstName1 = namefull1[0];

    if (namefull1.length > 1) {
      formData.lastName1 = namefull1[namefull1.length - 1];
    }

    if (namefull1.length > 2) {
      formData.middleName1 = namefull1
        .slice(1, namefull1.length - 1)
        .join()
        .replace(/,/g, ' ');
    }
  } else {
    formData.firstName1 = ""
    formData.lastName1 = ""
    formData.middleName1 = ""
  }

  const [namefull, setNamefull] = useState(fullName ? fullName.split(' ') : '');
  if (fullName !== null && fullName !== "" && fullName !== "undefined") {
    formData.firstNameindi = ""
    formData.middleNameindi = ""
    formData.lastNameindi = ""
    formData.firstName = namefull[0];

    if (namefull.length > 1) {
      formData.lastName = namefull[namefull.length - 1];
    }

    if (namefull.length > 2) {
      formData.middleName = namefull
        .slice(1, namefull.length - 1)
        .join()
        .replace(/,/g, ' ');

    }
  } else {
    formData.firstName = ""
    formData.lastName = ""
    formData.middleName = ""

  }


  useEffect(() => {
    localStorage.removeItem('step10');
    localStorage.removeItem('step15');
    localStorage.removeItem('step16');
    if (formData.isMinimumInvest == 'Yes') {
      $('#hidemyy').hide();
      $('#showmy').show();
    } else {
      $('#showmy').hide();
      $('#hidemyy').show();
    }
    $('.toogles').click(function () {
      $('.toogles').removeClass('actives');
      $(this).addClass('actives');
    });
    $('.step-change').click(function () {
      setValues = $(this).html();
      formData.isMinimumInvest = $(this).html();
      if (setValues === 'Yes') {
        $('#hidemyy').hide();
        $('#showmy').show();
      } else {
        $('#showmy').hide();
        $('#hidemyy').show();
      }
    });
    $(document).ready(function () {
      if (formData.isMinimumInvest == 'Yes') {
        $('.toogles').removeClass('actives');
        $('.label-one').addClass('actives');
        $('#showmy').show();
      } else {
        $('.toogles').removeClass('actives');
        $('.label-two').addClass('actives');
      }
      localStorage.setItem('isMinimumInvest', isMinimumInvest);

    });
  });
  function handleClick2() {
    go('Nine');
  }
  const [imageLoader, setImageLoader] = useState();
  const mySignatureRef = useRef(null);
  const mySignatureRef1 = useRef(null);

  useEffect(() => {

    formData.signatureUrlB = '';
  }, [formData]);

  // function signaturejoint1() {
  //   var date = new Date();
  //   var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  //   $('#printDate').text(val);
  //   formData.certificateA_Date1 = val;
  //   const setValues = localStorage.getItem('setValues');
  //   // let signatureB1 = mySignatureRef1.current.toDataURL();
  //   // formData.signatureB1 = signatureB1;
  //   // formData.signatureA1Url = signatureB1;
  //   // let datajoint = {
  //   //   columnName: 'secSignatureB',
  //   //   fileName: formData.investmentType === 'Joint' ? 'secSignatureB' : 'signatureA',
  //   //   document: signatureB1
  //   // };

  //   setImageLoader(true);
  //   if (setValues === 'Yes') {
  //     axios
  //       .post(`${multisavedata}/genImageUrl`, datajoint, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.signatureA1Url = res.data.url;
  //         formData.signatureB1 = res.data.url;
  //         formData.result = res.status;
  //         if (res.status === 200) {
  //           setImageLoader(false);
  //           formData.class = 'Twentyone';
  //           go('Twentyone');
  //         } else {
  //           setImageLoader(false);
  //         }
  //       });
  //   } else {
  //     localStorage.setItem('Step20', 'Step20');
  //     setImageLoader(true);
  //     axios
  //       .post(`${multisavedata}/genImageUrl`, datajoint, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.signatureA1Url = res.data.url;
  //         formData.signatureB1 = res.data.url;
  //         if (res.status === 200) {
  //           setImageLoader(false);
  //           formData.CertificationValue = true;
  //           formData.class = 'Twentyone';
  //           go('Twentyone');
  //         }
  //       });
  //   }
  // }

  function handleClick(e) {
    formData.certificateNumber = 2;
    // formData.rwt12 = null
    // formData.userEmail1 = null
    // formData.fullLegalNamesign = null
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    formData.certificateA_Date = val;
    const setValues = localStorage.getItem('setValues');
    // let signatureB = mySignatureRef.current.toDataURL();
    // let isEmpty = mySignatureRef.current.isEmpty();
    // let isEmpty1;
    // if (formData.investmentType === 'Joint') {
    //   isEmpty1 = mySignatureRef1.current.isEmpty();
    // }
    // formData.signatureB = signatureB;/
    // formData.signatureUrl = signatureB;

    // let data = {
    //   columnName: 'signatureA',
    //   fileName: formData.investmentType === 'Joint' ? 'priSignatureB' :
    //     formData.investmentType === 'Company' ? 'signatureA' : 'signatureA',
    //   document: signatureB
    // };
    if (formData.usualLegalRules === false || formData.usualLegalRules === null || formData.usualLegalRules === undefined
      || formData.notCompleteInformation === false || formData.notCompleteInformation === null || formData.notCompleteInformation === undefined
      // || formData.materialExtent10yrs === false || formData.materialExtent10yrs === null || formData.materialExtent10yrs === undefined 
      || formData.fewerLegalProtection === false || formData.fewerLegalProtection === null || formData.fewerLegalProtection === undefined
      || formData.notSuitableInvestment === false || formData.notSuitableInvestment === null || formData.notSuitableInvestment === undefined ||
      formData.seekFinancialAdvice === false || formData.seekFinancialAdvice === null || formData.seekFinancialAdvice === undefined) {
      $('.Certificate2').html('Please review each of the statements here and confirm your understanding by ticking each box');
    } else {
      formData.class = 'Twentyone';
      go('Twentyone');
    }

    // else if (isEmpty) {
    //   $('.signError').html('Please sign');
    // }
    // else if (formData.investmentType === 'Joint' && isEmpty1) {
    //   $('.signError1').html('Please sign');
    // }
    // else {
    //   setImageLoader(true);
    //   if (setValues === 'Yes') {
    //     axios
    //       .post(`${multisavedata}/genImageUrl`, data, {
    //         headers: { Authorization: `Bearer ${formData.token}` }
    //       })
    //       .then((res) => {
    //         formData.signatureUrl = res.data.url;
    //         formData.signatureB = res.data.url;
    //         formData.result = res.status;
    //         if (res.status === 200) {
    //           setImageLoader(false);
    //           formData.CertificationValue = true;
    //           if (formData.investmentType === 'Joint') {
    //             signaturejoint1();
    //           } else {
    //             formData.class = 'Twentyone';
    //             go('Twentyone');
    //           }
    //         } else {
    //           setImageLoader(false);
    //           formData.CertificationValue = true;
    //         }
    //       });
    //   } else {
    //     localStorage.setItem('Step20', 'Step20');
    //     setImageLoader(true);
    //     axios
    //       .post(`${multisavedata}/genImageUrl`, data, {
    //         headers: { Authorization: `Bearer ${formData.token}` }
    //       })
    //       .then((res) => {
    //         formData.signatureUrl = res.data.url;
    //         formData.signatureB = res.data.url;
    //         if (res.status === 200) {
    //           setImageLoader(false);
    //           formData.CertificationValue = true;
    //           if (formData.investmentType === 'Joint') {
    //             signaturejoint1();
    //           } else {
    //             formData.class = 'Twentyone';
    //             go('Twentyone');
    //           }
    //         } else {
    //           formData.CertificationValue = true;
    //         }
    //       });
    //   }
    // }
    // hyh
    if (formData.isMinimumInvest == 'No' || formData.isMinimumInvest == '' ||
      formData.isMinimumInvest == 'undefined' || formData.isMinimumInvest == undefined ||
      formData.isMinimumInvest == true ||
      formData.isMinimumInvest == null
    ) {

      formData.usualLegalRules = false;
      formData.notCompleteInformation = false;
      // formData.materialExtent10yrs = false;
      formData.fewerLegalProtection = false;
      formData.notSuitableInvestment = false;
      formData.seekFinancialAdvice = false;
      go('Twelve');
    }

    // alert(formData.isMinimumInvest)
    // }else{
    //   go('Twelve');
    // }

  }
  // const handleClick = () => {

  //   if (formData.usualLegalRules === false || formData.notCompleteInformation === false
  //     || formData.materialExtent10yrs === false || formData.fewerLegalProtection === false
  //     || formData.notSuitableInvestment === false ||
  //     formData.seekFinancialAdvice === false) {
  //     $('.Certificate2').html('Please review each of the statements here and confirm your understanding by ticking each box');
  //   }else {
  //     formData.class = 'Twentyone';
  //     go('Twentyone');
  //   }
  // }
  // const handleClick = () => {
  //   if (formData.investmentType === 'Joint') {
  //     if (OptionFund3 === 'Select' ||
  //       OptionFund3 === undefined ||
  //       OptionFund3 === '' ||
  //       OptionFund3 === null) {
  //       $('.OptionFund3').html('Please select your role');
  //     } else if (OptionFund4 === 'Select' ||
  //       OptionFund4 === undefined ||
  //       OptionFund4 === '' ||
  //       OptionFund4 === null) {
  //       $('.OptionFund4').html('Please select your role')
  //     } else {
  //       signature();
  //     }
  //   } else if (OptionFund3 === 'Select' ||
  //     OptionFund3 === undefined ||
  //     OptionFund3 === '' ||
  //     OptionFund3 === null) {
  //     $('.OptionFund3').html('Please select your role');
  //   } else {
  //     signature();
  //   }
  // }
  function removeError() {
    $('.error').html('');
  }


  const { go } = navigation;


  useEffect(() => {
    if (formData.isMinimumInvest == "Yes") {
      formData.largePersonValue = false;
      formData.twoYearInvestEntity2 = false;
      formData.largePersonNone = false;
      formData.investorPrincipalBusiness = false
      formData.investingFinancialProducts = false
      formData.actingUnderwriter = false
      formData.financialAdviceService = false
      formData.clientMoneyPropertyService = false
      formData.tradingFinancialProducts = false
      formData.registeredBankRBNZ = false
      formData.NBDTInvestor = false
      formData.licensedIssuerInsurance = false
      formData.registeredSchemeManager = false
      formData.derivativesIssuer = false
      formData.FinancialAdvisor = false
      formData.investmentBusinessNone = false
      formData.ownedPortfolio2yrs = false
      formData.carriedTransactions2yrs = false
      formData.investmentBusiness10yrs = false
      formData.equitySecurities = false
      formData.managedFunds = false
      formData.derivativeInstruments = false
      formData.debtSecurities = false
      formData.investmentCriteriaNone = false
    }
    else if (formData.isMinimumInvest == "No" || formData.isMinimumInvest == false) {
      formData.usualLegalRules = false
      formData.notCompleteInformation = false
      // formData.materialExtent10yrs = false
      formData.fewerLegalProtection = false
      formData.notSuitableInvestment = false
      formData.seekFinancialAdvice = false
      formData.certificateNumber = 2;
    }
  })

  // const Clear = () => {
  //   var date = new Date();
  //   var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  //   $('#printDate').text(val);
  //   formData.certificateA_Date = val;
  //   let signatureB = mySignatureRef.current.clear();
  //   formData.signatureB = signatureB;
  // };
  // const Clear1 = () => {
  //   var date = new Date();
  //   var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  //   $('#printDate').text(val);
  //   formData.certificateA_Date1 = val;
  //   let signatureB1 = mySignatureRef1.current.clear();
  //   formData.signatureB1 = signatureB1;
  // };
  // useEffect(() => {
  //   if (formData.signatureB != "" && formData.signatureB != null && formData.signatureB != undefined) {
  //     Clear();
  //   }

  // }, []);
  // useEffect(() => {
  //   if (formData.signatureB1 != "" && formData.signatureB1 != null && formData.signatureB1 != undefined) {
  //     Clear1();
  //   }

  // }, []);


  const [show, setShow] = useState(null);
  const shown = () => {
    setShow(true)
  }
  const hide = () => {
    setShow(false)
  }

  const handleClick1 = () => {
    go("Twelve")
  }


  var today = new Date();
  var date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [hides, setHides] = useState()
  console.log('hides', hides)
  useEffect(() => {
    if (formData.isMinimumInvest == 'Yes') {
      setHides(false)
    } else {
      setHides(true)
    }
  })

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Investor Certificate</h2> :
              <h2>Step 5: Investor Certificate</h2>
          }

        </div>
        <div className="form tenjs">
          <img src={logo} alt="img" className="imageCover" />
          {queryValue ? <p></p> :
            <div
              disabled={formData.isPrimary == 1 ? false : true}>
              <div className="save-exit se_invff">
                <p className="save next" onClick={saveandexit}>
                  SAVE DATA{' '}
                </p>
                <img src={info} onClick={infodetls} className="info" alt="info" />
              </div>
            </div>
          }

          <p className="no-space">Please answer the below information</p>
          <Row>
            <Col lg={12}>
              <div className="fullspace pandingfinsh ">
                <div id={"hidemyy"}>
                  <div>
                    <label className="font-fami">
                      Is the investing entity investing a minimum of $750,000 into this offer?
                    </label>
                    <div className="button_group pos pos_for_switch">
                      <div class="switch-field nospace">
                        {/* <div className="check_YN_Bank swtich11">
                          <input
                            // disabled={formData.isPrimary == 1 ? false : true}
                            type="radio"
                            id="radio-two"
                            className="yesno"
                            name="isMinimumInvest"
                            value="No"
                          // onChange={(e) => {
                          //   setForm(e);
                          //   // removeError();
                          // }}
                          />
                          <label
                            for="radio-two"
                            className="toogles step-change label-two actives"
                            id="show"
                            onClick={(e) => hide(e)}
                          >
                            No
                          </label>
                          <>
                            <input
                              // disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id="radio-one"
                              className="yesno"
                              name="isMinimumInvest"
                              value="Yes"
                            // onChange={(e) => {
                            //   setForm(e);
                            //   // removeError();
                            // }}
                            />
                            <label for="radio-one" className="toogles step-change label-one"
                              onClick={(e) => shown(e)}
                              id="hide">
                              Yes
                            </label>
                          </>
                        </div> */}
                        {formData.isPrimary == 1 ?
                          <div className="check_YN_Bank swtich11">
                            <input
                              // disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id="radio-two"
                              className="yesno"
                              name="isMinimumInvest"
                              value="No"
                            // onChange={(e) => {
                            //   setForm(e);
                            //   // removeError();
                            // }}
                            />
                            <label
                              for="radio-two"
                              className="toogles step-change label-two actives"
                              id="show"
                              onClick={(e) => hide(e)}
                            >
                              No
                            </label>
                            <>
                              <input
                                // disabled={formData.isPrimary == 1 ? false : true}
                                type="radio"
                                id="radio-one"
                                className="yesno"
                                name="isMinimumInvest"
                                value="Yes"
                              // onChange={(e) => {
                              //   setForm(e);
                              //   // removeError();
                              // }}
                              />
                              <label for="radio-one" className="toogles step-change label-one"
                                onClick={(e) => shown(e)}
                                id="hide">
                                Yes
                              </label>
                            </>
                          </div> :
                          <div className="check_YN_Bank swtich11">
                            <input
                              disabled={true}
                              type="radio"
                              id="radiorr"
                              className={`yesNobtn ${formData.isMinimumInvest == "No" || formData.isMinimumInvest == false ? "actives" : null}`}
                              name="isMinimumInvest"
                              value="No"
                            // onChange={(e) => {
                            //   setForm(e);
                            //   // removeError();
                            // }}
                            />
                            <label
                              for="radiorr"
                              className={`yesNobtn ${formData.isMinimumInvest == "No" || formData.isMinimumInvest == false ? "actives" : null}`}
                              id="show"
                              onClick={(e) => hide(e)}
                            >
                              No
                            </label>
                            <>
                              <input
                                disabled={true}
                                type="radio"
                                id="radiothree"
                                className={`yesNobtn ${formData.isMinimumInvest == "Yes" || formData.isMinimumInvest == "true" ? "actives" : null}`}
                                name="isMinimumInvest"
                                value="Yes"
                              // onChange={(e) => {
                              //   setForm(e);
                              //   // removeError();
                              // }}
                              />
                              <label for="radiothree"
                                className={`yesNobtn ${formData.isMinimumInvest == "Yes" || formData.isMinimumInvest == "true" ? "actives" : null}`}
                                // onClick={(e) => shown(e)}
                                id="hide">
                                Yes
                              </label>
                            </>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>


                <div id={"showmy"} >
                  <div>
                    <label className="font-fami">
                      Is the investing entity investing a minimum of $750,000 into this offer?
                    </label>
                    <div className="button_group pos pos_for_switch">
                      <div class="switch-field nospace">
                        {/* <div className="check_YN_Bank swtich11">
                          <input
                            // disabled={formData.isPrimary == 1 ? false : true}
                            type="radio"
                            id="radio-two"
                            className="yesno"
                            name="isMinimumInvest"
                            value="No"
                          //   onChange={(e) => {
                          //     setForm(e);
                          //     // removeError();
                          // }}
                          />
                          <label
                            for="radio-two"
                            className="toogles step-change label-two actives"
                            id="show"
                            onClick={(e) => hide(e)}
                          >
                            No
                          </label>
                          <>
                            <input
                              // disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id="radio-one"
                              className="yesno"
                              name="isMinimumInvest"
                              value="Yes"
                              onChange={(e) => {
                                setForm(e);
                                // removeError();
                              }}
                            />
                            <label for="radio-one" className="toogles step-change label-one"
                              onClick={(e) => shown(e)}
                              id="hide">
                              Yes
                            </label>
                          </>
                        </div> */}
                        {formData.isPrimary == 1 ?
                          <div className="check_YN_Bank swtich11">
                            <input
                              // disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id="radio-two"
                              className="yesno"
                              name="isMinimumInvest"
                              value="No"
                            //   onChange={(e) => {
                            //     setForm(e);
                            //     // removeError();
                            // }}
                            />
                            <label
                              for="radio-two"
                              className="toogles step-change label-two actives"
                              id="show"
                              onClick={(e) => hide(e)}
                            >
                              No
                            </label>
                            <>
                              <input
                                // disabled={formData.isPrimary == 1 ? false : true}
                                type="radio"
                                id="radio-one"
                                className="yesno"
                                name="isMinimumInvest"
                                value="Yes"
                                onChange={(e) => {
                                  setForm(e);
                                  // removeError();
                                }}
                              />
                              <label for="radio-one" className="toogles step-change label-one"
                                onClick={(e) => shown(e)}
                                id="hide">
                                Yes
                              </label>
                            </>
                          </div>
                          :
                          <div className="check_YN_Bank swtich11">
                            <input
                              disabled={true}
                              type="radio"
                              id="radiofour"
                              className={`yesNobtn ${formData.isMinimumInvest == "No" || formData.isMinimumInvest == false ? "actives" : null}`}
                              name="isMinimumInvest"
                              value="No"
                            //   onChange={(e) => {
                            //     setForm(e);
                            //     // removeError();
                            // }}
                            />
                            <label
                              for="radiofour"
                              className={`yesNobtn ${formData.isMinimumInvest == "No" || formData.isMinimumInvest == false ? "actives" : null}`}
                              id="show"
                              onClick={(e) => hide(e)}
                            >
                              No
                            </label>
                            <>
                              <input
                                disabled={true}
                                type="radio"
                                id="radiofour"
                                className={`yesNobtn ${formData.isMinimumInvest == "Yes" || formData.isMinimumInvest == true ? "actives" : null}`}
                                name="isMinimumInvest"
                                value="Yes"
                                onChange={(e) => {
                                  setForm(e);
                                  // removeError();
                                }}
                              />
                              <label for="radiofour"
                                className={`yesNobtn ${formData.isMinimumInvest == "Yes" || formData.isMinimumInvest == true ? "actives" : null}`}
                                onClick={(e) => shown(e)}
                                id="hide">
                                Yes
                              </label>
                            </>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="certificate_main certificate_main_for_switch">
                      <div className="acknowledgement mt-3">
                        <h3>Acknowledgement</h3>
                        <div className="for_input">
                          <label>I/We</label>
                          <div className='Divinvestor'>
                            <p className="certifies__Input newdivadd">
                              {
                                formData.investmentType == "Company" ?
                                  <div>
                                    {formData.companyName}
                                  </div>
                                  :
                                  <>
                                    {formData.investmentType === 'Joint'
                                      ? (formData.firstNameindi !== null &&
                                        formData.firstNameindi !== '' &&
                                        formData.firstNameindi !== 'undefined' &&
                                        formData.firstNameindi !== undefined
                                        ? formData.firstNameindi
                                        : formData.firstName !== null &&
                                          formData.firstName !== '' &&
                                          formData.firstName !== 'undefined' &&
                                          formData.firstName !== undefined
                                          ? formData.firstName
                                          : '') +
                                      ' ' +
                                      (formData.middleNameindi !== null &&
                                        formData.middleNameindi !== '' &&
                                        formData.middleNameindi !== 'undefined' &&
                                        formData.middleNameindi !== undefined
                                        ? formData.middleNameindi
                                        : formData.middleName !== null &&
                                          formData.middleName !== '' &&
                                          formData.middleName !== 'undefined' &&
                                          formData.middleName !== undefined
                                          ? formData.middleName
                                          : '') +
                                      '' +
                                      (formData.lastNameindi !== null &&
                                        formData.lastNameindi !== '' &&
                                        formData.lastNameindi !== 'undefined' &&
                                        formData.lastNameindi !== undefined
                                        ? ' ' + formData.lastNameindi
                                        : formData.lastName !== null &&
                                          formData.lastName !== '' &&
                                          formData.lastName !== 'undefined' &&
                                          formData.lastName !== undefined
                                          ? ' ' + formData.lastName
                                          : '') +
                                      (formData.firstNameJoint !== null &&
                                        formData.firstNameJoint !== '' &&
                                        formData.firstNameJoint !== 'undefined' &&
                                        formData.firstNameJoint !== undefined
                                        ? ' & ' + formData.firstNameJoint
                                        : formData.firstName1 !== null &&
                                          formData.firstName1 !== '' &&
                                          formData.firstName1 !== 'undefined' &&
                                          formData.firstName1 !== undefined
                                          ? ' & ' + formData.firstName1
                                          : '') +
                                      ' ' +
                                      (formData.middleNamejoint !== null &&
                                        formData.middleNamejoint !== '' &&
                                        formData.middleNamejoint !== 'undefined' &&
                                        formData.middleNamejoint !== undefined
                                        ? formData.middleNamejoint
                                        : formData.middleName1 !== null &&
                                          formData.middleName1 !== '' &&
                                          formData.middleName1 !== 'undefined' &&
                                          formData.middleName1 !== undefined
                                          ? formData.middleName1 + ' '
                                          : '') +
                                      '' +
                                      (formData.lastNamejoint !== null &&
                                        formData.lastNamejoint !== '' &&
                                        formData.lastNamejoint !== 'undefined' &&
                                        formData.lastNamejoint !== undefined
                                        ? ' ' + formData.lastNamejoint
                                        : formData.lastName1 !== null &&
                                          formData.lastName1 !== '' &&
                                          formData.lastName1 !== 'undefined' &&
                                          formData.lastName1 !== undefined
                                          ? ' ' + formData.lastName1
                                          : '')
                                      : formData.investmentType === 'Individual'
                                        ? (formData.firstNameindi !== null &&
                                          formData.firstNameindi !== '' &&
                                          formData.firstNameindi !== 'undefined' &&
                                          formData.firstNameindi !== undefined
                                          ? formData.firstNameindi
                                          : formData.firstName !== null &&
                                            formData.firstName !== '' &&
                                            formData.firstName !== 'undefined' &&
                                            formData.firstName !== undefined
                                            ? formData.firstName
                                            : '') +
                                        ' ' +
                                        (formData.middleNameindi !== null &&
                                          formData.middleNameindi !== '' &&
                                          formData.middleNameindi !== 'undefined' &&
                                          formData.middleNameindi !== undefined
                                          ? formData.middleNameindi
                                          : formData.middleName !== null &&
                                            formData.middleName !== '' &&
                                            formData.middleName !== 'undefined' &&
                                            formData.middleName !== undefined
                                            ? formData.middleName
                                            : '') +
                                        '' +
                                        (formData.lastNameindi !== null &&
                                          formData.lastNameindi !== '' &&
                                          formData.lastNameindi !== 'undefined' &&
                                          formData.lastNameindi !== undefined
                                          ? ' ' + formData.lastNameindi
                                          : formData.lastName !== null &&
                                            formData.lastName !== '' &&
                                            formData.lastName !== 'undefined' &&
                                            formData.lastName !== undefined
                                            ? ' ' + formData.lastName
                                            : '')
                                        : ''}
                                  </>
                              }
                            </p>

                            <label className='Investor'>(Investor)</label>
                          </div>
                        </div>

                        <div className="list">
                          <p>Confirm that I/We understand that :-</p>
                          <div className="checkbox-my">
                            <label className="containers years set-check font-fami">{
                              formData.usualLegalRules == true ?
                                <b> The usual legal rules that require information to be given to
                                  investors for offers of financial products do not apply if the amount
                                  invested upfront by me (plus any other investments I/We have already
                                  made in those financial products) is $750,000 or more; and</b> :
                                <p> The usual legal rules that require information to be given to
                                  investors for offers of financial products do not apply if the amount
                                  invested upfront by me (plus any other investments I/We have already
                                  made in those financial products) is $750,000 or more; and</p>
                            }
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="checkbox"
                                name="usualLegalRules"
                                onChange={(e) => {
                                  setForm(e);
                                }}
                                onClick={(e) => {
                                  removeError();
                                }}
                                checked={formData.usualLegalRules == true ? true : false}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="containers years set-check font-fami">{
                              formData.notCompleteInformation == true ?
                                <b>I/We may not receive a complete and balanced set of information about
                                  this investment; and</b> :
                                <p>I/We may not receive a complete and balanced set of information about
                                  this investment; and</p>
                            }
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="checkbox"
                                className=""
                                name="notCompleteInformation"
                                onChange={(e) => {
                                  setForm(e);
                                }}
                                onClick={(e) => {
                                  removeError();
                                }}
                                checked={formData.notCompleteInformation == true ? true : false}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="containers years set-check font-fami">{
                              formData.fewerLegalProtection == true ?
                                <b>I/We have fewer legal protections for this investment; and</b> :
                                <p>I/We have fewer legal protections for this investment; and</p>
                            }
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="checkbox"
                                name="fewerLegalProtection"
                                onChange={(e) => {
                                  setForm(e);
                                }}
                                onClick={(e) => {
                                  removeError();
                                }}
                                checked={formData.fewerLegalProtection == true ? true : false}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="containers years set-check font-fami">{
                              formData.notSuitableInvestment == true ?
                                <b> This investment is not suitable for retail investors; and</b> :
                                <p> This investment is not suitable for retail investors; and</p>
                            }
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="checkbox"
                                name="notSuitableInvestment"
                                onChange={(e) => {
                                  setForm(e);
                                }}
                                onClick={(e) => {
                                  removeError();
                                }}
                                checked={formData.notSuitableInvestment == true ? true : false}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="containers years set-check font-fami">{
                              formData.seekFinancialAdvice == true ?
                                <b>I/We have been advised to ask questions, read all documents carefully,
                                  and seek independent financial advice.</b> :
                                <p>I/We have been advised to ask questions, read all documents carefully,
                                  and seek independent financial advice.</p>
                            }
                              <input
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="checkbox"
                                name="seekFinancialAdvice"
                                onChange={(e) => {
                                  setForm(e);
                                }}
                                onClick={(e) => {
                                  removeError();
                                }}
                                checked={formData.seekFinancialAdvice == true ? true : false}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <span className="error Certificate2"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={handleClick2}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {/* {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) :
              formData.isMinimumInvest === "Yes" ? (
                <button type="submit" className="next flagsave" onClick={handleClick}>
                  Confirm & Continue
                  <img src={rightarrow} className="rightarrow" alt="" />{' '}
                </button>
              ) : (
                <button type="submit" className="next flagsave" onClick={handleClick1}>
                  Continue
                  <img src={rightarrow} className="rightarrow" alt="" />{' '}
                </button>
              )} */}
            <button type="submit" className="next" onClick={handleClick}>
              Confirm & Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ten;
