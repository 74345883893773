
// Stage

// export const Domain = 'wholesale.au.auth0.com';
// export const LoginUrl = 'https://wholesale-user.invsta.io/userlogin';
// export const clientId = 'P2K5iM5Sy6ASPitWzvMsO5INWrCj0ltY';
// export const audience = 'https://wholesale.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'stage';
// export const connection = 'wholesale-db';
// export const singup_auth = 'https://wholesale.au.auth0.com/dbconnections/signup';
// export const duvalapi = 'https://d1u0pb8ql4kshw.cloudfront.net';
// export const multisavedata = 'https://d1u0pb8ql4kshw.cloudfront.net';
// export const Biometricverify = 'https://d1u0pb8ql4kshw.cloudfront.net';
// export const twilio = 'https://d1u0pb8ql4kshw.cloudfront.net';

//new myfarm replica dev

export const Domain = 'myfarmreplica.au.auth0.com';
export const LoginUrl = 'https://myfarmnewuser-dev.invsta.io/userlogin';
export const clientId = 'HMr8nV41ugaunQYWORAOxyEGi6aWK9fJ';
export const audience = 'https://myfarmreplica.au.auth0.com/api/v2/';
export const CHECK_ENV = 'dev';
export const connection = 'my-farm-replica';
export const singup_auth = 'http://myfarmreplica.au.auth0.com/dbconnections/signup';
export const duvalapi = 'https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev';
export const multisavedata = 'https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev';
export const Biometricverify = 'https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev';
export const twilio = 'https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev';
