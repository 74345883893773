import React, { useState, useEffect, useRef } from 'react';
import ItemForm from '../ItemForm';
import { Link } from 'react-router-dom';
import leftarrow from '../leftarrow.png';
import info from '../info.png';
import swal from 'sweetalert';
import rightarrow from '../rightarrow.png';
import PhoneInput from 'react-phone-input-2';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { DatePicker } from 'antd';
import logo from '../logo.png';
import $, { now } from 'jquery';
import ReactInputMask from 'react-input-mask';
import InputMask from 'react-input-mask';
import MenuSidebar from '../Individual/MenuSidebar';
import axios from 'axios';
import { infodetls } from '../Individual/SaveInfo';
import { BlockInvalidChar } from "./BlockInvalidChar";
import useQuery from '../useQuery';


const EntityTwo = ({
  func,
  setForm,
  formData,
  navigation,
  saveAndExist,
  infodetlss,
  google,
  test
}) => {
  const {
    fullName,
    secondholder,
    isNoShareCompanys,
    companySharePercents,
  } = formData;
  const query = useQuery();
  let queryValue = query.get("update");
  const saveandexit = () => {
    const total_index = inputList.length - 1;
    let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (total_index == 0) {
      if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      }

      else {
        func();
      }
    }
    else if (total_index == 1) {

      if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      } else if ((!emailExpression.test(inputList[1].userEmail)) && (inputList[1].isCompanyShareholderYou === 0) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      } else {
        func();
      }
    }

    else if (total_index == 2) {

      if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      }

      else if ((!emailExpression.test(inputList[1].userEmail)) && (inputList[1].isCompanyShareholderYou === 0) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });

      }
      else if ((!emailExpression.test(inputList[2].userEmail)) && (inputList[2].isCompanyShareholderYou === 0) && (inputList[2].fullLegalName !== '' && inputList[2].fullLegalName !== null && inputList[2].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      } else {
        func();
      }
    }

    else if (total_index == 3) {
      if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      }

      else if ((!emailExpression.test(inputList[1].userEmail)) && (inputList[1].isCompanyShareholderYou === 0) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });

      }

      else if ((!emailExpression.test(inputList[2].userEmail)) && (inputList[2].isCompanyShareholderYou === 0) && (inputList[2].fullLegalName !== '' && inputList[2].fullLegalName !== null && inputList[2].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      }
      else if ((!emailExpression.test(inputList[3].userEmail)) && (inputList[3].isCompanyShareholderYou === 0) && (inputList[3].fullLegalName !== '' && inputList[3].fullLegalName !== null && inputList[3].fullLegalName !== undefined)) {
        swal({
          text: 'Please add an email for shareholder to save the data.'
        });
      } else {
        func();
      }
    }

  };

  const [namefull1, setNamefull1] = useState(
    formData.secondholder ? formData.secondholder.trim().split(' ') : ''
  );
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];
  if (
    formData.secondholder != null &&
    formData.secondholder != '' &&
    formData.secondholder != 'undefined'
  ) {
    formData.firstName12 = namefull1[0];
    if (namefull1.length > 1) {
      formData.lastName12 = namefull1[namefull1.length - 1];
    }

    if (namefull1 && namefull1.length > 2) {
      formData.middleName12 = namefull1
        .slice(1, namefull1.length - 1)
        .join()
        .replace(/,/g, ' ');
    }
  }

  // formData.fullName = fullName.trim()
  const { previous, next } = navigation;
  useEffect(() => {
    $('.toogles').click(function () {
      $('.toogles').removeClass('active');
      $(this).addClass('active');
    });
    $('.step-change').click(function () {
      $('.step-change').removeClass('active');
      $(this).addClass('active');
    });
    $('document').ready(function () {
      $("input[type='tel']").attr('readonly', true);
    });
    $('.flagsave').click(function () {
      let phoneCode = $('.form-control').val();
      localStorage.setItem('phoneCode', phoneCode);
    });
    $('#SHAREvALUE').keyup(function () {
      let input = $(this).val();
      if (input < 100) {
        return true;
      } else {
        return false;
      }
    });
  });

  function splitFullName(fn) {
    fn = fn.replace(/(^[\s]*|[\s]*$)/g, '');
    fn = fn.replace(/\s+/g, ' ');
    var fnarr = fn.split(' ');
    formData.firstNames = fnarr[0];
    formData.firstNameone = formData.firstNames;
    if (fnarr.length >= 3) {
      formData.lastNames = fnarr[fnarr.length - 1];
      formData.lastNameone = formData.lastNames;
      fnarr.shift();
      fnarr.pop();
      fnarr = fnarr.toString().replace(/,/g, ' ');
      formData.middleNames = fnarr;
      formData.middleNameone = formData.middleNames;
    } else if (fnarr.length === 3) {
      formData.middleNames = fnarr[1];
      formData.middleNameone = formData.middleNames;
      formData.lastNames = fnarr[2];
      formData.lastNameone = formData.lastNames;
    } else if (fnarr.length === 2) {
      formData.lastNames = fnarr[1];
      formData.lastNameone = formData.lastNames;
    }
  }

  function Onprevious(e) {
    // formData.class = "Second"
    formData.country = true
    formData.class = 'Jointhird';
    go('EntityOne');
  }

  const handleChange = (e) => {
    e.preventDefault();
  };

  const [errorResults, setErrorResults] = useState();

  function handleClick(e) {
    const total_index = inputList.length - 1;

    let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (total_index == 0) {
      if ((inputList[0].companySharePercent == '' || inputList[0].companySharePercent == null || inputList[0].companySharePercent == undefined) && (inputList[0].fullLegalName != '' && inputList[0].fullLegalName != null && inputList[0].fullLegalName != undefined)) {
        $('.SharePercent0').html('Please enter % of shareholder');
      } else if ((inputList[0].companySharePercent <= 0 || inputList[0].companySharePercent <= 24) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $('.SharePercent0').html('minimum 25% of shareholder');
      } else if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }
      else {
        go('EntityThird');
      }
    }

    else if (total_index == 1) {
      if ((inputList[0].companySharePercent == '' || inputList[0].companySharePercent == null || inputList[0].companySharePercent == undefined) && (inputList[0].fullLegalName != '' && inputList[0].fullLegalName != null && inputList[0].fullLegalName != undefined)) {
        $('.SharePercent0').html('Please enter % of shareholder');
      }
      else if ((inputList[0].companySharePercent !== "" && inputList[0].companySharePercent !== null && inputList[0].companySharePercent <= 24) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $('.SharePercent0').html('minimum 25% of shareholder');
      } else if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }

      else if ((inputList[1].companySharePercent == '' || inputList[1].companySharePercent == null || inputList[1].companySharePercent == undefined) && (inputList[1].fullLegalName != '' && inputList[1].fullLegalName != null && inputList[1].fullLegalName != undefined)) {
        $('.SharePercent1').html('Please enter % of shareholder');
      }
      else if ((inputList[1].companySharePercent !== "" && inputList[1].companySharePercent !== null && inputList[1].companySharePercent <= 24) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        $('.SharePercent1').html('minimum 25% of shareholder');
      }
      else if ((!emailExpression.test(inputList[1].userEmail)) && (inputList[1].isCompanyShareholderYou === 0) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        $(".email1").html(
          "Please enter a valid email address"
        );
      } else {
        go('EntityThird');
      }
    }

    else if (total_index == 2) {
      if ((inputList[0].companySharePercent == '' || inputList[0].companySharePercent == null || inputList[0].companySharePercent == undefined) && (inputList[0].fullLegalName != '' && inputList[0].fullLegalName != null && inputList[0].fullLegalName != undefined)) {
        $('.SharePercent0').html('Please enter % of shareholder');
      }
      else if ((inputList[0].companySharePercent !== "" && inputList[0].companySharePercent !== null && inputList[0].companySharePercent <= 24) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $('.SharePercent0').html('minimum 25% of shareholder');
      } else if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }

      else if ((inputList[1].companySharePercent == '' || inputList[1].companySharePercent == null || inputList[1].companySharePercent == undefined) && (inputList[1].fullLegalName != '' && inputList[1].fullLegalName != null && inputList[1].fullLegalName != undefined)) {
        $('.SharePercent1').html('Please enter % of shareholder');
      }
      else if ((inputList[1].companySharePercent !== "" && inputList[1].companySharePercent !== null && inputList[1].companySharePercent <= 24) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        $('.SharePercent1').html('minimum 25% of shareholder');
      }
      else if ((!emailExpression.test(inputList[1].userEmail)) && (inputList[1].isCompanyShareholderYou === 0) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        $(".email1").html(
          "Please enter a valid email address"
        );

      }

      else if ((inputList[2].companySharePercent == '' || inputList[2].companySharePercent == null || inputList[2].companySharePercent == undefined) && (inputList[2].fullLegalName != '' && inputList[2].fullLegalName != null && inputList[2].fullLegalName != undefined)) {
        $('.SharePercent2').html('Please enter % of shareholder');
      }
      else if ((inputList[2].companySharePercent !== "" && inputList[2].companySharePercent !== null && inputList[2].companySharePercent <= 24) && (inputList[2].fullLegalName !== '' && inputList[2].fullLegalName !== null && inputList[2].fullLegalName !== undefined)) {
        $('.SharePercent2').html('minimum 25% of shareholder');
      }
      else if ((!emailExpression.test(inputList[2].userEmail)) && (inputList[2].isCompanyShareholderYou === 0) && (inputList[2].fullLegalName !== '' && inputList[2].fullLegalName !== null && inputList[2].fullLegalName !== undefined)) {
        $(".email2").html(
          "Please enter a valid email address"
        );
      } else {
        go('EntityThird');
      }
    }

    else if (total_index == 3) {

      if ((inputList[0].companySharePercent == '' || inputList[0].companySharePercent == null || inputList[0].companySharePercent == undefined) && (inputList[0].fullLegalName != '' && inputList[0].fullLegalName != null && inputList[0].fullLegalName != undefined)) {
        $('.SharePercent0').html('Please enter % of shareholder');
      }
      else if ((inputList[0].companySharePercent !== "" && inputList[0].companySharePercent !== null && inputList[0].companySharePercent <= 24) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $('.SharePercent0').html('minimum 25% of shareholder');
      } else if ((!emailExpression.test(inputList[0].userEmail)) && (inputList[0].isCompanyShareholderYou === 0) && (inputList[0].fullLegalName !== '' && inputList[0].fullLegalName !== null && inputList[0].fullLegalName !== undefined)) {
        $(".email0").html(
          "Please enter a valid email address"
        );
      }

      else if ((inputList[1].companySharePercent == '' || inputList[1].companySharePercent == null || inputList[1].companySharePercent == undefined) && (inputList[1].fullLegalName != '' && inputList[1].fullLegalName != null && inputList[1].fullLegalName != undefined)) {
        $('.SharePercent1').html('Please enter % of shareholder');
      }
      else if ((inputList[1].companySharePercent !== "" && inputList[1].companySharePercent !== null && inputList[1].companySharePercent <= 24) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        $('.SharePercent1').html('minimum 25% of shareholder');
      }
      else if ((!emailExpression.test(inputList[1].userEmail)) && (inputList[1].isCompanyShareholderYou === 0) && (inputList[1].fullLegalName !== '' && inputList[1].fullLegalName !== null && inputList[1].fullLegalName !== undefined)) {
        $(".email1").html(
          "Please enter a valid email address"
        );

      }

      else if ((inputList[2].companySharePercent == '' || inputList[2].companySharePercent == null || inputList[2].companySharePercent == undefined) && (inputList[2].fullLegalName != '' && inputList[2].fullLegalName != null && inputList[2].fullLegalName != undefined)) {
        $('.SharePercent2').html('Please enter % of shareholder');
      }
      else if ((inputList[2].companySharePercent !== "" && inputList[2].companySharePercent !== null && inputList[2].companySharePercent <= 24) && (inputList[2].fullLegalName !== '' && inputList[2].fullLegalName !== null && inputList[2].fullLegalName !== undefined)) {
        $('.SharePercent2').html('minimum 25% of shareholder');
      }
      else if ((!emailExpression.test(inputList[2].userEmail)) && (inputList[2].isCompanyShareholderYou === 0) && (inputList[2].fullLegalName !== '' && inputList[2].fullLegalName !== null && inputList[2].fullLegalName !== undefined)) {
        $(".email2").html(
          "Please enter a valid email address"
        );
      }

      else if ((inputList[3].companySharePercent == '' || inputList[3].companySharePercent == null || inputList[3].companySharePercent == undefined) && (inputList[3].fullLegalName != '' && inputList[3].fullLegalName != null && inputList[3].fullLegalName != undefined)) {
        $('.SharePercent3').html('Please enter % of shareholder');
      }
      else if ((inputList[3].companySharePercent !== "" && inputList[3].companySharePercent !== null && inputList[3].companySharePercent <= 24) && (inputList[3].fullLegalName !== '' && inputList[3].fullLegalName !== null && inputList[3].fullLegalName !== undefined)) {
        $('.SharePercent3').html('minimum 25% of shareholder');
      } else if ((!emailExpression.test(inputList[3].userEmail)) && (inputList[3].isCompanyShareholderYou === 0) && (inputList[3].fullLegalName !== '' && inputList[3].fullLegalName !== null && inputList[3].fullLegalName !== undefined)) {
        $(".email3").html(
          "Please enter a valid email address"
        );
      } else {
        go('EntityThird');
      }
    }
  }



  function removeError(index) {
    $('.error').html('');
    console.log('index', index)
  }
  function numbertest() {
    let input = $(this).val();
    if (input < 100) {
      return true;
    } else {
      return false;
    }
  }
  $('input').on('paste', function (e) {
    $('.error').html('');
  });
  const [value, setValue] = useState();
  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, data) => setNewDate(data.value);
  const { go } = navigation;
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '3';
    go('submit');
  };

  // infodetls = () => {
  //   swal({
  //     text: 'You can save your application data by clicking on the button Save Data. You can then logout, and resume your application at a later stage by clicking on the link we have previously sent in an email to you asking you to verify your email address.'
  //   });
  // };

  infodetlss = () => {
    swal({
      text: 'This email address can either be the same email address used by the first joint account holder, or it can be a different email address.'
    });
  };

  const getInitialData = {
    fullLegalName: '',
    companySharePercent: '',
    isCompanyShareholderYou: 0,
    isNoShareCompany: "NO",
    // userEmail: "",
  };

  const [inputList, setInputList] = useState(
    formData.inputList != null && formData.inputList != '' && formData.inputList != 'undefined'
      ? formData.inputList
      : [getInitialData !== "" && getInitialData !== undefined ? getInitialData : null]
  );

  const handleAddClick = () => {
    setInputList([...inputList, getInitialData]);
  };

  const handleRemoveClick = (index, e) => {
    const newList = [...inputList];
    const deleteData = e.target.value
    console.log("ksdfushuif :", e.target)

    // console.log("e.target.value",e.target.value)
    // if (formData.is_trust_individual == true || formData.is_trust_individual == 1) {

    const result = axios.delete(`https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev/delete/shareholder/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${formData.token}`
      }
    }).then((res) => {
      if (res.status == 404) {
        // alert("deleted")
      }
    }
    ).catch((error) => {
      // alert("not Deleted")
    })

    setInputList(newList);
    formData.inputList = newList
    // }
    newList.splice(index, 1);
    setInputList(newList);

  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onchange = (index) => {
    console.log('indexindex', index)
  }

  const NO = () => {

    inputList.isCompanyTrustShareholder = false
    inputList.isCompanyShareholder = false
  }


  formData.inputList = inputList

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          <h2>Step 2: Company Details</h2>

        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          { queryValue  ?<p></p> :
          <div className="save-exit se_invff">
            <p className="save next"
              disabled={formData.isPrimary == 1 ? false : true}
              onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>
         }
          <p>Shareholder Details</p>
          <p>
            List the names of all shareholders within the company that own over 25% shareholding,
            and the percentage of shares they each hold. Please also tick the box if you are a
            shareholder.
          </p>

          {inputList && inputList.map((x, index) => {
            return (
              <div className='ggg company_page'>
                <div className={'bdetail' + index + 1 + '' + 'box'}>
                  <Row className='align-items-center mb-2 mb-md-3'>
                    <div className='col-md-6'>
                      {/* <label>Company Name</label> */}
                      <input
                        disabled={formData.isPrimary == 1 ? false : true}
                        type="text"
                        name="fullLegalName"
                        className="quantity quantitys"
                        onKeyPress={removeError}
                        value={inputList[index].fullLegalName
                          // inputList[index].isCompanyShareholderYou == 1 ? fullName : inputList[index].fullLegalName
                        }
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                          // formData.fullName = e.target.value;
                          const secArray = inputList;
                          secArray[index].fullLegalName = e.target.value;
                          setInputList([...secArray]);
                        }}
                        placeholder="Name of shareholder"
                      />
                      <span className={`error fullname${index}`}></span>
                    </div>
                    <div className='companySharePercent col-md-6'>
                      {/* <label>Company number</label> */}
                      <input
                        disabled={formData.isPrimary == 1 ? false : true}
                        maxlength={2}
                        onKeyDown={BlockInvalidChar}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }}
                        onwheel="return false;"
                        type="number"
                        className="numberss"
                        name="companySharePercent"
                        placeholder="% of shares held"
                        value={inputList[index].companySharePercent
                          // inputList[index].isCompanyShareholderYou == 1 ? companySharePercents : inputList[index].companySharePercent
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                          formData.companySharePercents = e.target.value;
                          const secArray = inputList;
                          secArray[index].companySharePercent = e.target.value;
                          setInputList([...secArray]);
                        }}
                      />
                      <span className={`error share-held SharePercent${index}`}></span>

                    </div>

                  </Row>
                  <Row className=" check-mb">
                    <Col md={8}>
                      <label>Is this shareholder you?</label>
                    </Col>
                    <Col md={4}>
                      <div className="button_group space-bottom">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank swtich11">
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id={`radio-one${index + 1}`}
                              className="yesno"
                              name="isCompanyShareholderYou"
                              //   onClick={()=>handler(index)}
                              value={inputList[index].isCompanyShareholderYou}
                              onChange={(e) => {
                                // setForm(e);
                                // removeError();
                                const secArray = inputList;
                                secArray[index].isCompanyShareholderYou = 0;
                                setInputList([...secArray]);
                              }}
                            />
                            <label
                              for={`radio-one${index + 1}`}
                              className={`toogles postalAddressButton ${typeof inputList[index].isCompanyShareholderYou === 'undefined' ||
                                inputList[index].isCompanyShareholderYou === 0
                                ? 'actives'
                                : null
                                }`}
                              id="show"
                            >
                              No
                            </label>
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id={`radio-two${index + 1}`}
                              name="isCompanyShareholderYou"
                              value={inputList[index].isCompanyShareholderYou}
                              onChange={(e) => {
                                // setForm(e);
                                // removeError();
                                const secArray = inputList;
                                secArray[index].isCompanyShareholderYou = 1;
                                setInputList([...secArray]);
                              }}
                            />
                            <label
                              for={`radio-two${index + 1}`}
                              className={`toogles postalAddressButtons ${inputList[index].isCompanyShareholderYou === 1 ? `actives` : null
                                }`}
                              id="hide"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {
                    inputList[index].isCompanyShareholderYou === 0 ?
                      <Row>
                        <Col md={6}>
                          <label>Email address of shareholder</label>
                          <input
                            disabled={formData.isPrimary == 1 ? false : true}
                            name="userEmail"
                            autoComplete="off"
                            className="capitaluserEmail"
                            placeholder="Please provide email address for shareholder"
                            // className="postal_address quantity"
                            onKeyPress={removeError}
                            onChange={(e) => {
                              // setForm(e);
                              removeError();
                              const secArray = inputList;
                              secArray[index].userEmail = e.target.value;
                              setInputList([...secArray]);
                            }}
                            value={inputList[index].userEmail}
                          />
                          <span className={`error email${index}`}></span>
                          {/* <span className="error emailerror"></span>  issue Number 10 */}
                        </Col>
                      </Row>
                      : null
                  }


                  <Row className=" check-mb">
                    <Col md={6} className='shareholder'>
                      <label>Is this shareholder a Trust or a Company?</label>
                    </Col>
                    <Col md={6} className="btn_shareholder">
                      <div className="button_group space-bottom">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank swtich11 swtich233">
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id={`radio-ones${index + 1}`}
                              className="yesno"
                              name="isNoShareCompany"
                              value={inputList[index].isNoShareCompany
                                //inputList[index].isCompanyShareholderYou == 1 ? isNoShareCompanys : inputList[index].isNoShareCompany
                              }
                              onChange={(e) => {
                                // setForm(e);
                                // removeError();
                                const secArray = inputList;
                                secArray[index].isNoShareCompany = "NO";
                                setInputList([...secArray]);
                              }}
                            />
                            <label
                              for={`radio-ones${index + 1}`}
                              className={
                                inputList[index].isNoShareCompany == 'NO'
                                  ? 'actives actives-2'
                                  : null
                              }
                              id="show"
                            >
                              No
                            </label>
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id={`radio-twos${index + 1}`}
                              name="isNoShareCompany"
                              value={inputList[index].isNoShareCompany
                                //inputList[index].isCompanyShareholderYou == 1 ? isNoShareCompanys : inputList[index].isNoShareCompany
                              }
                              onChange={(e) => {
                                // setForm(e);
                                // removeError();
                                const secArray = inputList;
                                secArray[index].isNoShareCompany = "TRUST";
                                setInputList([...secArray]);

                              }}
                            />
                            <label
                              for={`radio-twos${index + 1}`}
                              className={
                                inputList[index].isNoShareCompany == 'TRUST' ? 'actives' : null

                              }
                              id="hide"
                            >
                              Trust
                            </label>
                            <input
                              disabled={formData.isPrimary == 1 ? false : true}
                              type="radio"
                              id={`radio-third${index + 1}`}
                              name="isNoShareCompany"
                              value={inputList[index].isNoShareCompany
                                // inputList[index].isCompanyShareholderYou == 1 ? isNoShareCompanys : inputList[index].isNoShareCompany
                              }
                              onChange={(e) => {
                                // setForm(e);
                                // removeError();
                                const secArray = inputList;
                                secArray[index].isNoShareCompany = "COMPANY";
                                setInputList([...secArray]);
                              }}
                            />
                            <label
                              for={`radio-third${index + 1}`}
                              className={
                                inputList[index].isNoShareCompany == 'COMPANY' ? 'actives' : null
                              }
                              id="hide"
                            >
                              Company
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className={`revove_btn removebt${index}`}>
                    {inputList.length > 1 && (
                      <button
                        disabled={formData.isPrimary == 1 ? false : true}
                        onClick={(e) => handleRemoveClick(index, e)}
                        name={x.fullLegalName}
                        value={x.userEmail}
                      >
                        Remove
                      </button>
                    )}
                  </div>

                </div>
              </div>
            );
          })}

          <Row className="fullinput">
            <Col sm={12} className="Shareholder">
              <div className="Trust_ind_btn">
                {
                  inputList.length <= 3 ? (
                    <button
                      disabled={formData.isPrimary == 1 ? false : true}
                      onClick={handleAddClick}>+Shareholder</button>
                  ) : null
                  // <button >+Shareholder</button>
                }
              </div>
            </Col>
          </Row>

          <div className="fotter-btn">
            <button className="preview" onClick={Onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>

            <button type="submit" className="next flagsave" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default EntityTwo;
