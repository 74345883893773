import React, { useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import MenuSidebar from './MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';



const Twelve = ({ func, setForm, formData, navigation, }) => {
  const { previous, next } = navigation;
  useEffect(() => {
    localStorage.removeItem('step13');
  }, []);
  const saveandexit = () => {
    func();
  };
  const query = useQuery();

  let queryValue = query.get("update");
  formData.certificateDetails = {};


  function ONchange2() {

  }
  useEffect(() => {
    formData.Certificate2 = ""

  }, [formData])


  const allNone = (e) => {
    formData.largePersonValue = false
    formData.twoYearInvestEntity2 = false

    // if(formData.largePersonNone === "None" || formData.largePersonNone === true)
    // {
    //   formData.largePersonNone = false
    // }

    // else{
    //   formData.largePersonNone = true
    //   formData.largePersonValue = false
    //   formData.twoYearInvestEntity2 = false
    // }

  }
  console.log("formData---twoYearInvestEntity2", formData.twoYearInvestEntity2)
  console.log("formData---largePersonValue", formData.largePersonValue)
  console.log("formData.largePersonNone", formData.largePersonNone)

  const Nonefalse = () => {
    formData.largePersonNone = false
  }
  // alert(formData.largePersonNone)
  function handleClick(e) {
    formData.Certificate2 = ""
    localStorage.setItem('step10', 'step10');
    localStorage.removeItem('step15');
    if (
      (formData.largePersonNone == false || formData.largePersonNone == undefined) && (formData.largePersonValue == false && formData.twoYearInvestEntity2 == false)
    ) {
      $('.largePersonNone').html('Please select an answer');
    } else if (
      (formData.largePersonNone == false || formData.largePersonNone == undefined) && (formData.largePersonValue == null && formData.twoYearInvestEntity2 == null)
    ) {
      $('.largePersonNone').html('Please select an answer');
    }
    else if (formData.largePersonNone == true) {
      go("Thirteen")

      // $('.largePersonNone').html('Please select an answer');

    } else if (formData.largePersonNone === 'None') {

      if (formData.largePersonNone !== "None") {
        formData.investorPrincipalBusiness = false
        formData.investingFinancialProducts = false
        formData.actingUnderwriter = false
        formData.financialAdviceService = false
        formData.clientMoneyPropertyService = false
        formData.tradingFinancialProducts = false
        formData.registeredBankRBNZ = false
        formData.NBDTInvestor = false
        formData.licensedIssuerInsurance = false
        formData.registeredSchemeManager = false
        formData.derivativesIssuer = false
        formData.FinancialAdvisor = false
        formData.ownedPortfolio2yrs = false
        formData.carriedTransactions2yrs = false
        formData.investmentBusiness10yrs = false
        formData.equitySecurities = false
        formData.managedFunds = false
        formData.derivativeInstruments = false
        formData.debtSecurities = false
        formData.investmentCriteriaNone = false
      }

      next();
      localStorage.setItem('step10', 'step10');
    } else {
      if (formData.largePersonNone !== "None") {
        formData.investorPrincipalBusiness = false
        formData.investingFinancialProducts = false
        formData.actingUnderwriter = false
        formData.financialAdviceService = false
        formData.clientMoneyPropertyService = false
        formData.tradingFinancialProducts = false
        formData.registeredBankRBNZ = false
        formData.NBDTInvestor = false
        formData.licensedIssuerInsurance = false
        formData.registeredSchemeManager = false
        formData.derivativesIssuer = false
        formData.FinancialAdvisor = false
        formData.ownedPortfolio2yrs = false
        formData.carriedTransactions2yrs = false
        formData.investmentBusiness10yrs = false
        formData.equitySecurities = false
        formData.managedFunds = false
        formData.derivativeInstruments = false
        formData.debtSecurities = false
        formData.investmentCriteriaNone = false
      }

      formData.acquiringDisposingFinancialProductsOther = null
      formData.startupOrBusiness = false
      formData.investorPresentation = false
      formData.myOwnInvestmentDecisions = false
      formData.valueAndRisksOfInvesting = false
      formData.capitalRaisingDirector = false
      formData.investingSharesSecurities = false
      formData.acquiringDisposingFinancialProducts = false
      formData.acquiringDisposingFinancialProducts = false

      go('Eleven');
      localStorage.setItem('step10', 'step10');
    }
  }
  function removeError() {
    $('.error').html('');
  }


  const { go } = navigation;
  console.log("formData.largePersonNone", formData.largePersonNone)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // alert(formData.largePersonValue)
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {/* <h2>Wholesale Certification: Wholesale Certification 1</h2> */}
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Wholesale Certification A</h2> :
              <h2>Step 5: Wholesale Certification A</h2>

          }
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          {queryValue ? <p></p> :
            <div
              disabled={formData.isPrimary == 1 ? false : true}>
              <div className="save-exit se_invff">
                <p className="save next" onClick={saveandexit}>
                  SAVE DATA{' '}
                </p>
                <img src={info} onClick={infodetls} alt="info1" className="info" />
              </div>
            </div>
          }
          {/* <h3>Investor certificate</h3> */}
          <div className="grid-container">
            <div className="grid-item "></div>
            <div className="grid-item "></div>
            <div className="grid-item "></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item active"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
          </div>
          <label className="person">Large persons</label>
          {/* <h1>Select one of the below options</h1> */}

          <p>The Investor is ‘large’ on the basis that at least one of the following paragraphs applies immediately before this certificate is given:</p>

          <div className="checkbox-my">
            <label className="containers restContainer font-fami">
              {
                formData.largePersonValue === true ?
                  <b>As at the last day of each of the Investor's two most recently completed financial
                    years, the net assets of the Investor and the entities controlled by the Investor
                    exceeded $5 million.</b> :
                  <p>As at the last day of each of the Investor's two most recently completed financial
                    years, the net assets of the Investor and the entities controlled by the Investor
                    exceeded $5 million.</p>
              }

              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="largePersonValue"
                checked={
                  formData.largePersonValue == true ? true : false
                }
                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }
                }
                onChange={(e) => setForm(e)}

              />
              <span className="checkmark"></span>
            </label>
            <label className="containers restContainer font-fami">
              {
                formData.twoYearInvestEntity2 === true ?
                  <b> In each of the Investor's two most recently completed financial years, the total
                    consolidated turnover of the Investor and the entities controlled by the Investor
                    exceeded $5 million.</b> :
                  <p> In each of the Investor's two most recently completed financial years, the total
                    consolidated turnover of the Investor and the entities controlled by the Investor
                    exceeded $5 million.</p>
              }

              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="twoYearInvestEntity2"
                checked={
                  formData.twoYearInvestEntity2 == true ? true : false
                }
                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }
                }
                onChange={(e) => setForm(e)}

              />
              <span className="checkmark"></span>
            </label>

            <label className="containers font-fami">
              {
                formData.largePersonNone == true ? <b>None of the above.</b> : <p>None of the above.</p>
              }

              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                value="None"
                name="largePersonNone"
                checked={
                  formData.largePersonNone == true ? true : false
                }

                onClick={(e) => {
                  allNone(e)
                  removeError()
                }

                }
                onChange={(e) => { setForm(e) }}
              />
              <span className="checkmark"></span>
            </label>

            <span className="error largePersonNone"></span>
            <br />
          </div>


          {/* <br /> */}
          <div className="fotter-btn whlsm-certi-footer">
            <button className="preview" onClick={previous}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            <button type="submit" className="next" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Twelve;
