import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import axios from 'axios';
import { multisavedata } from '../../auth/Configuration';
import info from '../info.png';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

const UploadImage2 = ({ formData, columnName, fileName, labeltxt, inform, showIcon, buttonName, valuedefine, bankcls }) => {
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  let { document } = documentFile64;

  const navigate = useNavigate();
  const [imgSize, setimgSize] = useState();
  async function uploadWithJSON(file) {
    if (formData.trustDeedProof.length > 2) {
      swal({
        text: 'You can attach maximum 3 files.'
      });
    } else {
      if (valuedefine == "trustDeedProof") {
        // formData.trustDeedProof = '';
      } else if (valuedefine == "trustIrdNumberProof") {
        formData.trustIrdNumberProof = '';
      }


      setdocumentFile64(file.name);
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);

          reader.onerror = (error) => reject(error);
        });

      const data = {
        img64: await toBase64(file)
      };

      setdocumentFile64(data.img64);
      formData.arryImg.push(data.img64)
      console.log("formData.arryImg", formData.arryImg)
      // gg.push(data.img64);

      const imgs = file.type;
      if (file.size > 2202009) {
        if (valuedefine == "bank2" || valuedefine == "bank") {
          swal({
            text: 'The nominated Bank Account must be in the same name as the investment entity/owner. Maximum file size is 2MB.'
          });
        }

        else if (valuedefine == "trustDeedProof") {
          swal({
            text: 'Please attach proof of deed (maximum file size is 2MB).'

          });
        }

      } else {

        console.log("8888888", file.type)

        if (
          data.img64 !== null &&
          data.img64 !== '' &&
          data.img64 !== 'undefined' &&
          data.img64 !== undefined
        ) {
          let dataupdate = {
            columnName: columnName,
            fileName: file.name.split('.')[0],
            document: data.img64,
            // document: formData.arryImg,
            investmentType: formData.investmentType
          };

          setImageLoader(true);
          axios
            .post(`${multisavedata}/genImageUrl`, dataupdate, {
              headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
              console.log("ttttt", res);


              formData.trustDeedProof.push(res.data.url);
              // formData.trustDeedProof = formData.listTrust;
              // if (valuedefine == "bank2") {
              //   formData.primaryBankProof2 = res.data.url;
              // } else if (valuedefine == 'document') {
              //   formData.documentProof = res.data.url;
              // } else if (valuedefine == 'document2') {
              //   formData.documentProof2 = res.data.url;
              // } else if (columnName == 'companyAddressProof') {
              //   formData.companyAddressProof = res.data.url;
              // } else if (columnName == 'companyFundsDocument') {
              //   formData.companyFundsDocument = res.data.url;
              // } else if (valuedefine == "bank") {
              //   formData.primaryBankProof = res.data.url;
              // } else if (valuedefine == "exampt") {
              //   formData.examptProof = res.data.url;
              // } else if (valuedefine == "companyExamptProof") {
              //   formData.companyExamptProof = res.data.url;
              // } else if (valuedefine == "exampt2") {
              //   formData.examptProof2 = res.data.url;
              // } else if (valuedefine == "trustAddressProof") {
              //   formData.trustAddressProof = res.data.url;
              // } else if (valuedefine == "trustDeedProof") {
              //   formData.trustDeedProof = res.data.url;
              // } else if (valuedefine == "trustExamptProof") {
              //   formData.trustExamptProof = res.data.url;
              // } else if (valuedefine == "trustSourceOfFundsProof") {
              //   formData.trustSourceOfFundsProof = res.data.url;
              // } else if (valuedefine == "userIrdNumberProof") {
              //   formData.userIrdNumberProof = res.data.url;
              // } else if (valuedefine == "userIrdNumberProof2") {
              //   formData.userIrdNumberProof2 = res.data.url;
              // } else if (valuedefine == "trustIrdNumberProof") {
              //   formData.trustIrdNumberProof = res.data.url;
              // }

              if (res.status === 200) {
                setImageLoader(false);
              } else {
                setImageLoader(false);
              }
            })
            .catch((error) => {
              setImageLoader(false);
            });
        }
      }
    }

  }

  function removeError() {
    $('.error').html('');
  }


  if (valuedefine == "trustAddressProof") {
    formData.ProofValue = formData.listTrust;
    var ddd = formData.listTrus;
  } else if (valuedefine == "trustDeedProof") {
    formData.ProofValue = formData.listTrust;
  }
  formData.ProofValue = formData.listTrust;

  const downloadImage = (imageUrl) => {
    console.log("iiiii", imageUrl)
    let postadv;
    postadv = {
      downloadUrl: imageUrl.trustDeedProof ? imageUrl.trustDeedProof : imageUrl,
      investmentType: formData.investmentType,
      // investmentType:formData.investmentType
    }


    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
    console.log("yyyy", formData.listTrust)
  };
  const [imgType, setimgType] = useState();
  console.log("imgTypeimgType", formData.ProofValue);
  console.log("imgSize", formData.ProofValue);

  useEffect(() => {
  }, [formData.trustDeedProof]);


  const DeleteImage = async (ind) => {
    // alert(ind);
    setImageLoader(true);
    console.log("00000", ind)
    formData.trustDeedProof.indexOf(ind) !== -1 && formData.trustDeedProof.splice(formData.trustDeedProof.indexOf(ind), 1)
    console.log("00000dd", formData.trustDeedProof)
    await axios.put(`${multisavedata}/delete/image`, {
      "investmentType": formData.investmentType,
      "downloadUrl": ind.trustDeedProof ? ind.trustDeedProof : ind
    }, {
      headers: { Authorization: `Bearer ${formData.token}` }
    },).then((res) => {
      if (res.status == 200) {
        setImageLoader(false);

        // formData.trustDeedProof.splice(indexs, indexs)

        // alert("deleted")


        // navigate("/dashboard");
        // window.location.reload();
        //  formData.applications()


      }
    }
    ).catch((error) => {


      // alert("not Deleted")
    })
  }
  return (
    <>
      <Row className="fullinput uploaded_btn ">

        <div className='btn_custom'>
          <div class="yes1">
            <button
              // disabled={formData.isPrimary == 1 ? false : true}

              className="click-hover" >
              <span class="btn_upload">
                <input
                  disabled={formData.isPrimary == 1 ? false : true}
                  type="file"
                  id="imag2"
                  title=""
                  value={document}
                  onChange={(e) => {
                    uploadWithJSON(e.target.files[0]);
                    setimgType(e.target.files[0].type);
                    setimgSize(e.target.files[0].size);
                    removeError();
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                    removeError();
                  }}
                />
              </span>
              {buttonName}

              {/* {BankInfo=="BankInfo"?<img src={info} onClick={infobanks} className="info backi" alt="" /> :""} */}

            </button>
          </div>
        </div>

        <div className=" ">
          <label className="bankClick">
            {labeltxt}
            {showIcon == true ? (
              <img src={info} onClick={inform} className="inform_identity_informbutton" />
            ) : (
              ''
            )}
          </label>
          <span className="error file ml-0"></span>
        </div>
      </Row>
      {
        <>
          <div className="spceimg mult_file_upload ">

            <>
              {formData.trustDeedProof && formData.trustDeedProof.map((val, index) => {

                return <>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className="licedocument-downloade w-auto" id={`${index}`} onClick={() => downloadImage(val)}>

                      <div className="main-downloade-wrapper">
                        <div className="downloads">
                          <i className="fa fa-download downloadeStyle"></i>
                        </div>
                        <p className="downloadeMsg mb-0">View file already provided  { } </p>
                      </div>


                    </a>
                    <span onClick={() => DeleteImage(val)}></span>
                  </div>
                </>

              })

              }
            </>


            {/* <span id="name_tag" class="preview2"></span>
        <input type="button" id="removeImage2" value="x" class="btn-rmv2" /> */}
            {imageLoader === true ? (
              <div class="progress">
                <span class="progress-bar" style={{ width: '100%' }}></span>
              </div>
            ) : null}
          </div>
        </>


      }

    </>
  );
};
export default UploadImage2;
