
import MultiStepForm from "../pages/MultiStepForm"
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from 'src/auth/LoginButton';
// import ScrollToTop from 'src/components/ScrollToTop';

export default function DashboardApp() {

  const { user, isAuthenticated, isLoading } = useAuth0();
 

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
    
    <LoginButton/>
 
    {isAuthenticated  && user.email_verified === true && (
    <>  
  
    {/* <ScrollToTop/> */}
    <MultiStepForm/>
   
    {/* <MenuSidebar/> */}
    {/* <MenuSidebar/> */}
   
     
      

    </>
         
     
         )}
            
      
       

          
    </>  
   

  );

}
