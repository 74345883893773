import React, { useState, useEffect } from "react";
import leftarrow from "../leftarrow.png";
import rightarrow from "../rightarrow.png";
import logo from "../logo.png";
import info from "../info.png";
import swal from "sweetalert";
import MenuSidebar from "./MenuSidebar";
import image_wholsale from "../image_wholsale.png"
import { infodetls } from './SaveInfo';
import useQuery from '../useQuery';


const Nine = ({ setForm, formData, navigation, saveAndExist, eligibilityinfo, func }) => {
    const [userapplication, setUserapplication] = useState([]);
    const saveandexit = () => {
        func();
    }
const queryValue=sessionStorage.getItem("queryValue");

    function HandlePrevious() {
        

        if (formData.investmentType === "Joint") {
            if(queryValue ==null || queryValue ==undefined || queryValue ==""){

            
                formData.class = "Eight"
            go("Eight")
              }
                else{
                  formData.class = 'Five';
                go('Five');
              }
        }
        else {
            if(queryValue ==null || queryValue ==undefined || queryValue ==""){

            
                formData.class = "Eight"
            go("Eight")
              }
                else{
                  formData.class = 'Five';
                go('Five');
              }
            
        }



    }
    function Next1(e) {
        formData.nominatedProfessional1 === "later" && formData.nominatedProfessional1 !== null ? go("Ten") : next();
    }
    const { Syndication } = formData;
    const { previous, next } = navigation;
    saveAndExist = () => {
        formData.status = "PENDING";
        formData.step = "9";
        go("submit");
    };

    eligibilityinfo = () => {
        swal({
            content: {
                element: "div",
                attributes: {
                    innerHTML: 'Full details of the requirements for a "Wholesale Investor" and an "Eligible Investor" are more fully explained in the Information Memorandum ' + `<a href=${userapplication.hyperLink1} target="_blank">Click here </a> `
                }
            }
        });
    }
    const { go } = navigation;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // const query = useQuery();

    // let queryValue = query.get("update");
    
    return (
        <>

            <MenuSidebar navigation={navigation} formData={formData} />
            <div className="main_container">
                <div class="heading__top">
                    {
                        formData.investmentType == "Company" || formData.investmentType == "Joint" ?
                            <h2>Step 6: Eligibility to Invest</h2> :
                            <h2>Step 5: Eligibility to Invest</h2>

                    }
                </div>
                <div className="form">
                    <img src={logo} alt="img" className="imageCover" />
                    {queryValue ? <p></p> :
                    <div
                    disabled={formData.isPrimary == 1 ? false : true}>
                        <div className="save-exit se_invff">
                            <p className="save next" onClick={saveandexit}>
                                SAVE DATA{" "}
                            </p>
                            <img src={info} onClick={infodetls} className="info" />
                        </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-12">
                            <p>You will need to certify yourself as a Wholesale or Eligible Investor.</p>
                        </div>
                    </div>
                    <div className="image_wholsale">
                        <img src={image_wholsale} />
                    </div>
                    {/* <div className="wholesal_main row">
                        <div className="wholesal col-md-3">
                            <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.dreamstime.com%2Fphotos-images%2Fnature.html&psig=AOvVaw2jo8Wop2zhIgQQL2-88QbS&ust=1668574242893000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCOCnj8mxr_sCFQAAAAAdAAAAABAE" target="_blank">
                                <div className="wholesel_inner">
                                    <i class="fa-solid fa-pen-to-square edite_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                                    </i>
                                    <h3 className="box_title">Trust Onbording</h3>
                                </div>
                            </a>

                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-12 mt-3">
                            <p>More information is provided in the following pages to help with your selection of the appropriate Investor Category and Certificate.</p>
                        </div>
                    </div>
                    {/* <p className="pera">
                        This investment offer in the Du Val Mortage Fund Limited Partnership is only available to wholesale investors as defined in the Financial Markets Conduct Act 2013.



                    </p>
                    <p><b>To view and download copies of the Wholesale Certificates please  </b><a className="duvalpartners_Link" href="https://duvalpartners.com/download/WholesaleInvestorCertificates(Combined)18.11.21.pdf" target="_blank">click here.</a> </p>
                    <p className="text-justify">
                        If you have not already reviewed the Information Memorandum and Limited Partnership Agreement you must request this information, which will also tell you about the FIXED RETURN AND THE RISKS in this investment, from your Investor Relations Associate.

                    </p>
                    <p className="darktext">Warning</p>
                    <p>The law normally requires people who offer financial products 
                        to give information to investors before they invest. This 
                        information is designed to help investors make an informed decision.</p>
                    <p>If you are a wholesale investor, the usual rules do not apply 
                        to offers of financial products made to you. As a result, you may not receive a complete and balanced set of information. You will also have fewer other legal protections for these investments. Ask questions, read all documents carefully, and seek independent financial advice before committing yourself.</p>
                    <p className="darktext">Offence</p>
                    <p>It is an offence to give a certificate knowing that it is false
                         or misleading in a material particular. The offence has a penalty of a
                          fine not exceeding $50,000.</p> */}
                    <br />
                    <div className="fotter-btn">
                        <button
                            className="preview"
                            onClick={() =>
                                HandlePrevious()
                            }
                        >
                            <img src={leftarrow} className="leftarrow" alt="" />
                            Previous
                        </button>
                        <button type="submit" className="next" onClick={Next1}>
                            Continue
                            <img src={rightarrow} className="rightarrow" alt="" />{" "}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Nine;




