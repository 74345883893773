import React, { useState, useEffect, useRef } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import download from '../download.png';
import ItemForm from '../ItemForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import swal from 'sweetalert';
import info from '../info.png';
import $ from 'jquery';
import axios from 'axios';
import MenuSidebar from './MenuSidebar';
import logo from '../logo.png';
import SignaturePad from 'react-signature-pad-wrapper';
import { multisavedata } from '../../auth/Configuration';
import Option from './Option';

const Twentyone1 = ({ func, setForm, formData, navigation, saveAndExist, infodetls }) => {
  const { OptionFund12, jointHolderDetails, OptionFund121 } = formData;
  const { previous, next } = navigation;
  const [imageLoader, setImageLoader] = useState();
  let JointAcount = sessionStorage.getItem('Jointacoount');
  const [namefull, setNamefull] = useState(
    formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.fullName !== undefined &&
      formData.fullName !== 'undefined'
      ? formData.fullName.split(' ')
      : ''
  );
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];

  formData.firstName = namefull[0];

  if (namefull.length > 1) {
    formData.lastName = namefull[namefull.length - 1];
  }

  if (namefull.length > 2) {
    formData.middleName = namefull
      .slice(1, namefull.length - 1)
      .join()
      .replace(/,/g, ' ');
  }
  var dates = new Date();
  var valss = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
  console.log("datssss", valss)

  const [namefull1, setNamefull1] = useState(
    formData.secondholder !== '' &&
      formData.secondholder !== null &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined
      ? formData.secondholder.split(' ')
      : ''
  );
  let text3 = ['world!', 'ny', 'jjij'];
  let result1 = text3[-1];

  formData.firstName1 = namefull1[0];

  if (namefull1.length > 1) {
    formData.lastName1 = namefull1[namefull1.length - 1];
  }

  if (namefull1.length > 2) {
    formData.middleName1 = namefull1
      .slice(1, namefull1.length - 1)
      .join()
      .replace(/,/g, ' ');
  }

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '17';
    // swal({
    //   text: "Your application saved",
    // });
    go('submit');
  };
  const mySignatureRefAderence = useRef(null);
  const mySignaturejointHolderDetails = useRef(null);

  infodetls = () => {
    swal({
      text: 'You can save your application data by clicking on the button Save Data. You can then logout, and resume your application at a later stage by clicking on the link we have previously sent in an email to you asking you to verify your email address.'
    });
  };
  let applicationType = sessionStorage.getItem('applicationType');

  const saveandexit = () => {
    func();
  };

  let previousHandler = () => {
    if (applicationType === 'Trust') {
      go('Jointfivteen');
    } else {
      go('Twentyone');
      // previous();
    }
  };
  useEffect(() => {
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate').text(val);
      formData.certificateB_Date = val;
    });
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate2').text(val);
      formData.certificateB_Date = val;
    });
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate21').text(val);
      formData.certificateB_Date = val;
    });
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate1').text(val);
      formData.certificateB_Date = val;
    });
    $('#terms').scroll(function () {
      if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
        $('#payment').removeAttr('disabled');
        $('#term_conditionTest1').val('true');
      }
    });
    $('#termss').scroll(function () {
      if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
        $('#payments').removeAttr('disabled');
        $('#term_conditionTest2').val('true');
      }
    });
    // $(document).ready(function () {
    //   alert(payment)
    //   let payment = "term";
    //   $(".yesno").click(function () {
    //     payment = $(this).val();
    //     alert(payment)
    //   });
    // });

    //   $(document).ready(function(){
    //     $('input[type="checkbox"]').click(function(){
    //         if($(this).prop("checked") == true){
    //             // $(".error").html("");
    //         }
    //         else if($(this).prop("checked") == false){
    //             // $("#result").html("Checkbox is unchecked.");
    //         }
    //     });
    // });

    $('#payment').click(function () {
      let test = $('#term_conditionTest1').val();
      if (
        $('#terms').scrollTop() + $('#terms').innerHeight() >= $('#terms')[0].scrollHeight ||
        test === 'true'
      ) {
        return true;
      } else {
        // $(".result").html(
        // 	"Please read and review terms and conditions to proceed. You will need to scroll to the bottom before you can accept."
        // );
        // return false;
        return true;
      }
    });
    $('#payments').click(function () {
      let test = $('#term_conditionTest2').val();
      if (
        $('#termss').scrollTop() + $('#termss').innerHeight() >= $('#termss')[0].scrollHeight ||
        test === 'true'
      ) {
        return true;
      } else {
        // $(".results").html(
        // 	"Please read and review privacy policy to proceed. You will need to scroll to the bottom before you can accept."
        // );
        // return false;
        return true;
      }
    });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function removeError() {
    $('.error').html('');
  }

  const Clear = () => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    const setValues = localStorage.getItem('setValues');
    let adherenceSign = mySignatureRefAderence.current.clear();
    formData.adherenceSign = adherenceSign;
  };
  const Clear12 = () => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    // formData.certificateA_Date = val;
    const setValues = localStorage.getItem('setValues');
    let mySignaturejointHolderDetails1 = mySignaturejointHolderDetails.current.clear();
    formData.mySignaturejointHolderDetails = mySignaturejointHolderDetails1;
  };

  function secondaryjointsign() {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    formData.certificateAD_Date = val;

    const setValues = localStorage.getItem('setValues');
    let mySignaturejointHolderDetails1 = mySignaturejointHolderDetails.current.toDataURL();

    let isEmpty = mySignaturejointHolderDetails.current.isEmpty();

    let data = {
      columnName: 'secAdherenceSign',
      fileName: 'secAdherenceSign',
      document: mySignaturejointHolderDetails1,
      investmentType:formData.investmentType
    };

    setImageLoader(true);

    if (setValues === 'Yes') {
      axios
        .post(`${multisavedata}/genImageUrl`, data, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          formData.mySignaturejointHolderDetailsUrl = res.data.url;
          formData.result = res.status;
          if (res.status === 200) {
            setImageLoader(false);
            formData.class = 'Twentyone';
            formData.termss = false;
            formData.termss1 = false;
            formData.terms = false;
            formData.termsss = false;
            formData.termssss = false;
            go('Twentyone2');
          } else {
            setImageLoader(false);
          }
        });
    } else {
      localStorage.setItem('Step20', 'Step20');
      setImageLoader(true);

      axios
        .post(`${multisavedata}/genImageUrl`, data, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          formData.mySignaturejointHolderDetailsUrl = res.data.url;

          if (res.status === 200) {
            setImageLoader(false);
            formData.class = 'Twentyone';
            formData.termss = false;
            formData.termss1 = false;
            formData.terms = false;
            formData.termsss = false;
            formData.termssss = false;
            go('Twentyone2');
            setImageLoader(false);
          }
        });
    }
  }

  function handleClick(e) {
    if (formData.investmentType === 'Joint') {
      // if (
      //   OptionFund12 === 'Select' ||
      //   OptionFund12 === null ||
      //   OptionFund12 === 'undefined' ||
      //   OptionFund12 === '' ||
      //   OptionFund12 === undefined
      // ) {
      //   //
      //   // $('.Role').html('Please select your role');
      // } else if (
      //   OptionFund121 === 'Select' ||
      //   OptionFund121 === null ||
      //   OptionFund121 === 'undefined' ||
      //   OptionFund121 === '' ||
      //   OptionFund121 === undefined
      // ) {
      //   // $('.Role1').html('Please select your role');
      // }
      // else 
      {
        formData.terms = '';
        formData.termss = '';
        var date = new Date();
        var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        $('#printDate').text(val);
        formData.certificateAD_Date = val;

        const setValues = localStorage.getItem('setValues');
        let adherenceSign = mySignatureRefAderence.current.toDataURL();

        let isEmpty = mySignatureRefAderence.current.isEmpty();
        let isEmpty1;
        if (formData.investmentType === 'Joint') {
          isEmpty1 = mySignaturejointHolderDetails.current.isEmpty();
        }

        let data = {
          columnName: 'adherenceSign',
          fileName: formData.investmentType === 'Joint' ? 'priAdherenceSign' : 'adherenceSign',
          document: adherenceSign,
          investmentType:formData.investmentType
        };
        if (isEmpty) {
          $('.signError').html('Please sign');
        } else if (isEmpty1 && formData.investmentType === 'Joint') {
          $('.signError1').html('Please sign');
        } else {
          setImageLoader(true);
          if (setValues === 'Yes') {
            axios
              .post(`${multisavedata}/genImageUrl`, data, {
                headers: { Authorization: `Bearer ${formData.token}` }
              })
              .then((res) => {
                formData.adherenceSignUrl = res.data.url;
                formData.result = res.status;
                if (res.status === 200) {
                  setImageLoader(false);

                  if (formData.investmentType === 'Joint') {
                    secondaryjointsign();
                  } else {
                    // formData.class = 'Twentyone';
                    // formData.termss = false;
                    // formData.termss1 = false;
                    // formData.terms = false;
                    // formData.termsss = false;
                    // formData.termssss = false;
                    go('Twentyone2');
                  }
                } else {
                  setImageLoader(false);
                }
              });
            // go("Twentyone");
          } else {
            localStorage.setItem('Step20', 'Step20');
            setImageLoader(true);

            axios
              .post(`${multisavedata}/genImageUrl`, data, {
                headers: { Authorization: `Bearer ${formData.token}` }
              })
              .then((res) => {
                formData.adherenceSignUrl = res.data.url;

                if (res.status === 200) {
                  setImageLoader(false);

                  if (formData.investmentType === 'Joint') {
                    secondaryjointsign();
                  } else {
                    // formData.class = 'Twentyone';
                    // formData.termss = false;
                    // formData.termss1 = false;
                    // formData.terms = false;
                    // formData.termsss = false;
                    // formData.termssss = false;
                    go('Twentyone2');
                  }
                }
              });
          }
        }
      }
    } else {
      // if (
      //   OptionFund12 === 'Select' ||
      //   OptionFund12 === null ||
      //   OptionFund12 === 'undefined' ||
      //   OptionFund12 === '' ||
      //   OptionFund12 === undefined
      // ) {
      //   $('.Role').html('Please select your role');
      // } else 
      {
        formData.terms = '';
        formData.termss = '';
        var date = new Date();
        var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        $('#printDate').text(val);
        formData.certificateAD_Date = val;

        const setValues = localStorage.getItem('setValues');
        let adherenceSign = mySignatureRefAderence.current.toDataURL();

        let isEmpty = mySignatureRefAderence.current.isEmpty();

        let data = {
          columnName: 'adherenceSign',
          fileName: formData.investmentType === 'Joint' ? 'priAdherenceSign' : 'adherenceSign',
          document: adherenceSign,
          investmentType:formData.investmentType
        };
        if (isEmpty) {
          $('.signError').html('Please sign');
        } else {
          setImageLoader(true);
          if (setValues === 'Yes') {
            axios
              .post(`${multisavedata}/genImageUrl`, data, {
                headers: { Authorization: `Bearer ${formData.token}` }
              })
              .then((res) => {
                formData.adherenceSignUrl = res.data.url;
                formData.result = res.status;
                if (res.status === 200) {
                  setImageLoader(false);
                  formData.class = 'Twentyone';
                  formData.termss = false;
                  formData.termss1 = false;
                  formData.terms = false;
                  formData.termsss = false;
                  formData.termssss = false;
                  go('Twentyone2');
                } else {
                  setImageLoader(false);
                }
              });
            // go("Twentyone");
          } else {
            localStorage.setItem('Step20', 'Step20');
            setImageLoader(true);

            axios
              .post(`${multisavedata}/genImageUrl`, data, {
                headers: { Authorization: `Bearer ${formData.token}` }
              })
              .then((res) => {
                formData.adherenceSignUrl = res.data.url;

                if (res.status === 200) {
                  setImageLoader(false);

                  if (formData.investmentType === 'Joint') {
                    formData.class = 'Twentyone';
                    formData.termss = false;
                    formData.termss1 = false;
                    formData.terms = false;
                    formData.termsss = false;
                    formData.termssss = false;
                    go('Twentyone2');
                  } else {
                    formData.class = 'Twentyone';
                    formData.termss = false;
                    formData.termss1 = false;
                    formData.terms = false;
                    formData.termsss = false;
                    formData.termssss = false;
                    go('Twentyone2');
                  }
                }
              });
          }
        }
      }
    }
  }

  const { go } = navigation;

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 7: Adherence Agreement</h2> :
              <h2>Step 6: Adherence Agreement</h2>
          }
          {/* <h2>Step 6: Terms & Agreements: Adherence Agreement</h2> */}
        </div>
        <div className="form terms_cond_main">
          <div className="Blackbg-logo">
            <div className="logo-Blackbg">
              <img src={logo} alt="TermsLOgo" className="TermsLOgo"></img>

              <div className="onpage">{/* <span>1/2</span> */}</div>
              <div className="input-date">
                <div className="d-flex ">
                  <label className="colorChanges">Dated:</label>
                  <label id="printDate" className="colorChanges">
                    {' '}
                    DD/MM/YYYY{' '}
                  </label>
                </div>
              </div>
            </div>

            <div className="FundNAME-AGREEMENT">
              <div className="main-FundNAME-AGREEMENT">
                <p className="ADHERENCE">ADHERENCE AGREEMENT</p>
                <span>MORTGAGE FUND</span>
              </div>
            </div>
          </div>

          <div className="Seconf-part-AGREEMENT">
            <div className="main-wrapper-AGREEMENT">
              <div className="AGREEMENT-left">
                <p>Between</p>
              </div>
              <div className="AGREEMENT-center">
                <p className="outer-style-AGREEMENT">
                  MyFarm Mortgage Fund Limited Partnership (FSP1000594)
                </p>
              </div>
              <div className="right-AGREEMENT">
                <p>(the Limited Partnership)</p>
              </div>
            </div>

            <div className="main-wrapper-AGREEMENT">
              <div className="AGREEMENT-left">
                <p>AND</p>
              </div>
              <div className="AGREEMENT-center">
                <p className="outer-style-AGREEMENT">MyFarm Capital Partners Limited (6277159) </p>
              </div>
              <div className="right-AGREEMENT">
                <p>(the General Partner)</p>
              </div>
            </div>
            <div className="main-wrapper-AGREEMENT">
              <div className="AGREEMENT-left">
                <p>AND</p>
              </div>
              <div className="AGREEMENT-center">
                <p className="outer-style-AGREEMENT">MyFarm Group NZ Limited (5986215) </p>
              </div>
              <div className="right-AGREEMENT">
                <p>(the Initial Partner)</p>
              </div>
            </div>

            <div className="main-wrapper-AGREEMENT">
              <div className="AGREEMENT-left">
                <p>AND</p>
              </div>
              <div className="AGREEMENT-center">
                <p className="outer-style-AGREEMENT">
                  {
                    formData.investmentType == "Company" ?

                      <div>
                        {formData.companyName}
                      </div> :
                      <b className="capitallll">
                        {formData.investmentType === 'Joint'
                          ? (formData.firstNameindi != null &&
                            formData.firstNameindi != '' &&
                            formData.firstNameindi != 'undefined'&&
                            formData.firstNameindi != undefined
                            ? ' And ' + formData.firstNameindi
                            : formData.firstName != null &&
                              formData.firstName != '' &&
                              formData.firstName != 'undefined'&&
                              formData.firstName != undefined

                              ? formData.firstName
                              : '') +
                          ' ' +
                          (formData.middleNameindi != null &&
                            formData.middleNameindi != '' &&
                            formData.middleNameindi != 'undefined'&&
                            formData.middleNameindi != undefined
                            ? formData.middleNameindi
                            : formData.middleName != null &&
                              formData.middleName != '' &&
                              formData.middleName != 'undefined'&&
                              formData.middleName != undefined
                              ? formData.middleName + ' '
                              : '') +
                          '' +
                          (formData.lastNameindi != null &&
                            formData.lastNameindi != '' &&
                            formData.lastNameindi != 'undefined'&&
                            formData.lastNameindi != undefined
                            ? formData.lastNameindi
                            : formData.lastName != null &&
                              formData.lastName != '' &&
                              formData.lastName != 'undefined'&&
                              formData.lastName != undefined
                              ? formData.lastName
                              : '') +
                          (formData.firstNameJoint != null &&
                            formData.firstNameJoint != '' &&
                            formData.firstNameJoint != 'undefined'&&
                            formData.firstNameJoint != undefined
                            ? formData.firstNameJoint
                            : formData.firstName1 != null &&
                              formData.firstName1 != '' &&
                              formData.firstName1 != 'undefined'&&
                              formData.firstName1 != undefined
                              ? ' And ' + formData.firstName1
                              : '') +
                          ' ' +
                          (formData.middleNamejoint != null &&
                            formData.middleNamejoint != '' &&
                            formData.middleNamejoint != 'undefined'&&
                            formData.middleNamejoint != undefined
                            ? formData.middleNamejoint
                            : formData.middleName1 != null &&
                              formData.middleName1 != '' &&
                              formData.middleName1 != 'undefined'&&
                              formData.middleName1 != undefined
                              ? formData.middleName1 + ' '
                              : '') +
                          '' +
                          (formData.lastNamejoint != null &&
                            formData.lastNamejoint != '' &&
                            formData.lastNamejoint != 'undefined'&&
                            formData.lastNamejoint != undefined
                            ? ' ' + formData.lastNamejoint
                            : formData.lastName1 != null &&
                              formData.lastName1 != '' &&
                              formData.lastName1 != 'undefined'&&
                              formData.lastName1 != undefined
                              ? ' ' + formData.lastName1
                              : '')
                          : formData.firstName +
                          ' ' +
                          (formData.middleName === 'undefined' ||
                            formData.middleName === undefined ||
                            formData.middleName === ''
                            ? ''
                            : formData.middleName + ' ') +
                          (formData.lastName === 'undefined' ||
                            formData.lastName === undefined ||
                            formData.lastName === ''
                            ? ''
                            : formData.lastName)}
                      </b>
                  }
                </p>
              </div>
              <div className="right-AGREEMENT">
                <p>(the New Partner)</p>
              </div>
            </div>
            <div className="main-wrapper-AGREEMENT">
              <div className="AGREEMENT-left"></div>
              <div className="AGREEMENT-center-span">
                <span>(together, the Parties)</span>
              </div>
            </div>
          </div>

          <div className="ADHERENCE-AGREEMENT-list-content ">
            <div className="content-AGREEMENT">
              <p className="list-header remove_space1">RECITAL</p>
              <ol type="A" className="ORDER-Content">
                <li>
                  From
                  <div className="AGREEMENT-input">
                    <label className="AGREEMENT-input-date"></label>

                    <label id="printDate1" className="AGREEMENT-input-date">
                      DD/MM/YYYY
                    </label>
                  </div>
                  <b>(the Effective Date)</b> the New Partner is intending to become a Limited
                  Partner (as defined in the Limited Partnership Agreement dated 23rd July 2020 and
                  made between the General Partner and the Initial Partner{' '}
                  <b>(the Partnership Agreement)</b>.
                </li>
                <li>
                  The Parties have agreed to enter into this Agreement pursuant to the terms of
                  the Partnership Agreement.
                </li>
              </ol>

              <ol className="ORDER-Content">
                <p className="list-header remove_space1">OPERATIVE PART</p>
                <li class="order-list-item">
                  <b className="bold-bottom-space">CONSIDERATION</b>

                  <div className='main-wrapper'>


                    <ol>
                      {/* <div className='ORDER-Content_number'></div>  */}

                      <li className='LIST__CONSIDERATION list_one'><p className='one'>1</p>
                        In consideration of, and subject to the New Partner being admitted as a
                        Limited Partner (as defined in the Partnership Agreement) from the Effective
                        Date:
                      </li>
                    </ol>

                  </div>

                  <ol className='main_wrapper_margin'>

                    {/* <li>
                      In consideration of, and subject to the New Partner being admitted as a
                      Limited Partner (as defined in the Partnership Agreement) from the Effective
                      Date:
                    </li> */}


                    <div className="outter-AGREEMENT">


                      <ol type='a'>
                        <li> The New Partner agrees that the New Partner is to have the benefit and
                          obligations of and be bound by the terms of the Partnership Agreement as if
                          the New Partner were an initial Limited Partner.</li>

                        <li>
                          The Parties agree that the New Partner is to have the benefit of and be
                          bound by the terms and conditions of the Partnership Agreement as if the New
                          Partner were named as a Limited Partner (as defined in the Partnership
                          Agreement).
                        </li>
                        <li>
                          This Agreement is governed in all respects by the laws of New Zealand
                          and each of the Parties irrevocably submits to the non-exclusive
                          jurisdiction of the New Zealand Courts about any claim, dispute or matter
                          arising out or relating to this Agreement.
                        </li>
                      </ol>

                    </div>


                  </ol>
                </li>


                <br />
                <li class="order-list-item">
                  <b className="bold-bottom-space">
                    {' '}
                    NEW PARTNER CONTACT INFORMATION AND REGISTER INSTRUCTIONS
                  </b>
                </li>

                <table className="PARTNER-info">
                  <tbody>
                    <tr>
                      <td className="PARTNER-info-content">New Partner Name/Entity</td>
                      <td className="PARTNER-info-content-second capitallll">
                        {' '}
                        {
                          formData.investmentType == "Company" ?

                            <div>
                              {formData.companyName}
                            </div> : <>
                              {
                                formData.investmentType === 'Joint'
                                  ? (formData.firstNameindi != null &&
                                    formData.firstNameindi != '' &&
                                    formData.firstNameindi != 'undefined'&&
                                    formData.firstNameindi != undefined
                                    ? ' And ' + formData.firstNameindi
                                    : formData.firstName != null &&
                                      formData.firstName != '' &&
                                      formData.firstName != 'undefined'&&
                                      formData.firstName != undefined
                                      ? formData.firstName
                                      : '') +
                                  ' ' +
                                  (formData.middleNameindi != null &&
                                    formData.middleNameindi != '' &&
                                    formData.middleNameindi != 'undefined'&&
                                    formData.middleNameindi != undefined
                                    ? formData.middleNameindi
                                    : formData.middleName != null &&
                                      formData.middleName != '' &&
                                      formData.middleName != 'undefined'&&
                                      formData.middleName != undefined
                                      ? formData.middleName + ' '
                                      : '') +
                                  '' +
                                  (formData.lastNameindi != null &&
                                    formData.lastNameindi != '' &&
                                    formData.lastNameindi != 'undefined'&&
                                    formData.lastNameindi != undefined
                                    ? formData.lastNameindi
                                    : formData.lastName != null &&
                                      formData.lastName != '' &&
                                      formData.lastName != 'undefined'&&
                                      formData.lastName != undefined
                                      ? formData.lastName
                                      : '') +
                                  (formData.firstNameJoint != null &&
                                    formData.firstNameJoint != '' &&
                                    formData.firstNameJoint != 'undefined'&&
                                    formData.firstNameJoint != undefined
                                    ? ' & ' + formData.firstNameJoint
                                    : formData.firstName1 != null &&
                                      formData.firstName1 != '' &&
                                      formData.firstName1 != 'undefined'&&
                                      formData.firstName1 != undefined
                                      ? ' And ' + formData.firstName1
                                      : '') +
                                  ' ' +
                                  (formData.middleNamejoint != null &&
                                    formData.middleNamejoint != '' &&
                                    formData.middleNamejoint != 'undefined'&&
                                    formData.middleNamejoint != undefined
                                    ? formData.middleNamejoint
                                    : formData.middleName1 != null &&
                                      formData.middleName1 != '' &&
                                      formData.middleName1 != 'undefined'&&
                                      formData.middleName1 != undefined
                                      ? formData.middleName1 + ' '
                                      : '') +
                                  '' +
                                  (formData.lastNamejoint != null &&
                                    formData.lastNamejoint != '' &&
                                    formData.lastNamejoint != 'undefined'&&
                                    formData.lastNamejoint != undefined
                                    ? ' ' + formData.lastNamejoint
                                    : formData.lastName1 != null &&
                                      formData.lastName1 != '' &&
                                      formData.lastName1 != 'undefined'&&
                                      formData.lastName1 != undefined
                                      ? ' ' + formData.lastName1
                                      : '')
                                  : formData.firstName +
                                  ' ' +
                                  (formData.middleName === 'undefined' ||
                                    formData.middleName === undefined ||
                                    formData.middleName === ''
                                    ? ''
                                    : formData.middleName + ' ') +
                                  (formData.lastName === 'undefined' ||
                                    formData.lastName === undefined ||
                                    formData.lastName === ''
                                    ? ''
                                    : formData.lastName)
                              }
                            </>}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="PARTNER-info-content">New Partner Contact Number</td>
                      <td className="PARTNER-info-content-second">
                        {'+'}
                        {formData.priContactCountryCode != null &&
                          formData.priContactCountryCode !== ''
                          ? formData.priContactCountryCode
                          : '64'}{' '}
                        {formData.priContactNumber != null &&
                          formData.priContactNumber != 'undefined'
                          ? formData.priContactNumber
                          : ''}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="PARTNER-info-content">New Partner Contact Number</td>
                      <td className="PARTNER-info-content-second">
                        {'+'}
                        {formData.priContactCountryCode != null &&
                          formData.priContactCountryCode !== ''
                          ? formData.priContactCountryCode
                          : '64'}{' '}
                        {formData.priContactNumber != null &&
                          formData.priContactNumber != 'undefined'
                          ? formData.priContactNumber
                          : ''}
                          <br/>
                          {formData.investmentType=="Joint"?'+':''}
                        {formData.investmentType=="Joint"?formData.priContactCountryCode != null &&
                          formData.priContactCountryCode !== ''
                          ? formData.priContactCountryCode
                          : '64' :""}{' '}
                        {formData.investmentType=="Joint" ?formData.priContactNumber2 != null &&
                          formData.priContactNumber2 != 'undefined'
                          ? formData.priContactNumber2
                          : '':""}
                      </td>
                    </tr>
                    <tr>
                      <td className="PARTNER-info-content">New Partner Contact Email</td>
                      <td className="PARTNER-info-content-second">{formData.userEmail}</td>
                    </tr>
                    <tr>
                      <td className="PARTNER-info-content">
                        New Partner Nominated Bank Account
                      </td>
                      <td className="PARTNER-info-content-second">
                        {formData.primaryBankAccountNumber}
                        <br />
                        {formData.primaryBankAccountNumber2}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ol>
            </div>
            
            <div class="main-signature-content">
              <div className="EXECUTED-content_top_cnt">
                <div className="EXECUTED-content content-3">
                  <p>EXECUTED AS AN AGREEMENT:</p>
                  <div className="second-EXECUTED-signature">
                    <div className="d-flex">
                      <p>
                        {' '}
                        <b>Dated:</b>
                      </p>{' '}
                      <b>
                        <p id="printDate"> {valss}</p>
                      </b>
                    </div>
                    {/* <p className='remove-margin'>Office held (Individual/Director/Trustee)</p> */}
                  </div>
                  <div className="upper-space-table">
                    <table className="behalf-sign sign-2">
                      <tr>
                        <td className="term-Signed term-SignedNew">Signature of applicant:</td>
                        {/* &nbsp; */}
                        <td className="term-entity term-entityNew" >
                          {/* {formData.investmentType == 'Joint'
                            ? (formData.firstNameindi != null &&
                              formData.firstNameindi != '' &&
                              formData.firstNameindi != 'undefined'
                              ? formData.firstNameindi
                              : formData.firstName != null &&
                                formData.firstName != '' &&
                                formData.firstName != 'undefined'
                                ? formData.firstName
                                : '') +
                            ' ' +
                            (formData.middleNameindi != null &&
                              formData.middleNameindi != '' &&
                              formData.middleNameindi != 'undefined'
                              ? formData.middleNameindi
                              : formData.middleName != null &&
                                formData.middleName != '' &&
                                formData.middleName != 'undefined'
                                ? formData.middleName + ' '
                                : '') +
                            '' +
                            (formData.lastNameindi != null &&
                              formData.lastNameindi != '' &&
                              formData.lastNameindi != 'undefined'
                              ? ' ' + formData.lastNameindi
                              : formData.lastName != null &&
                                formData.lastName != '' &&
                                formData.lastName != 'undefined'
                                ? ' ' + formData.lastName
                                : '')
                            :
                            formData.investmentType == 'Individual'
                              ? (formData.firstNameindi != null &&
                                formData.firstNameindi != '' &&
                                formData.firstNameindi != 'undefined'
                                ? formData.firstNameindi
                                : formData.firstName != null &&
                                  formData.firstName != '' &&
                                  formData.firstName != 'undefined'
                                  ? formData.firstName
                                  : '') +
                              ' ' +
                              (formData.middleNameindi != null &&
                                formData.middleNameindi != '' &&
                                formData.middleNameindi != 'undefined'
                                ? formData.middleNameindi
                                : formData.middleName != null &&
                                  formData.middleName != '' &&
                                  formData.middleName != 'undefined'
                                  ? formData.middleName + ' '
                                  : '') +
                              '' +
                              (formData.lastNameindi != null &&
                                formData.lastNameindi != '' &&
                                formData.lastNameindi != 'undefined'
                                ? ' ' + formData.lastNameindi
                                : formData.lastName != null &&
                                  formData.lastName != '' &&
                                  formData.lastName != 'undefined'
                                  ? ' ' + formData.lastName
                                  : '')
                              : ''} */}
                          {(formData.firstNameindi !== null &&
                            formData.firstNameindi !== '' &&
                            formData.firstNameindi !== 'undefined' &&
                            formData.firstNameindi !== undefined
                            ? formData.firstNameindi
                            : formData.firstName !== null &&
                              formData.firstName !== '' &&
                              formData.firstName !== 'undefined' &&
                              formData.firstName !== undefined
                              ? formData.firstName
                              : '') +
                            ' ' +
                            (formData.middleNameindi !== null &&
                              formData.middleNameindi !== '' &&
                              formData.middleNameindi !== 'undefined' &&
                              formData.middleNameindi != undefined
                              ? formData.middleNameindi
                              : formData.middleName !== null &&
                                formData.middleName !== '' &&
                                formData.middleName !== 'undefined' &&
                                formData.middleName !== undefined
                                ? formData.middleName + ' '
                                : '') +
                            '' +
                            (formData.lastNameindi !== null &&
                              formData.lastNameindi !== '' &&
                              formData.lastNameindi !== 'undefined' &&
                              formData.lastNameindi !== undefined
                              ? formData.lastNameindi
                              : formData.lastName !== null &&
                                formData.lastName !== '' &&
                                formData.lastName !== 'undefined' &&
                                formData.lastName !== undefined
                                ? formData.lastName
                                : '')}
                          {
                            formData.investmentType === 'Individual' || formData.investmentType == 'Company' ? '' : <></>
                          }

                          {
                            formData.investmentType === 'Joint' ?
                              <>
                                {/* {formData.investmentType === 'Joint'
                                  ? (formData.firstNameJoint != null &&
                                    formData.firstNameJoint != '' &&
                                    formData.firstNameJoint != 'undefined'
                                    ? formData.firstNameJoint
                                    : formData.firstName1 != null &&
                                      formData.firstName1 != '' &&
                                      formData.firstName1 != 'undefined'
                                      ? formData.firstName1
                                      : '') +
                                  ' ' +
                                  (formData.middleNamejoint != null &&
                                    formData.middleNamejoint != '' &&
                                    formData.middleNamejoint != 'undefined'
                                    ? formData.middleNamejoint
                                    : formData.middleName1 != null &&
                                      formData.middleName1 != '' &&
                                      formData.middleName1 != 'undefined'
                                      ? formData.middleName1 + ' '
                                      : '') +
                                  '' +
                                  (formData.lastNamejoint != null &&
                                    formData.lastNamejoint != '' &&
                                    formData.lastNamejoint != 'undefined'
                                    ? formData.lastNamejoint
                                    : formData.lastName1 != null &&
                                      formData.lastName1 != '' &&
                                      formData.lastName1 != 'undefined'
                                      ? formData.lastName1
                                      : '')
                                  :
                                  // (formData.firstNameindi != null &&
                                  //   formData.firstNameindi != '' &&
                                  //   formData.firstNameindi != 'undefined'
                                  //   ? formData.firstNameindi
                                  //   : formData.firstName != null &&
                                  //     formData.firstName != '' &&
                                  //     formData.firstName != 'undefined'
                                  //     ? formData.firstName
                                  //     : '')
                                  +
                                  ' ' +
                                  // (formData.middleNameindi != null &&
                                  //   formData.middleNameindi != '' &&
                                  //   formData.middleNameindi != 'undefined'
                                  //   ? formData.middleNameindi
                                  //   : formData.middleName != null &&
                                  //     formData.middleName != '' &&
                                  //     formData.middleName != 'undefined'
                                  //     ? formData.middleName + ' '
                                  //     : '') 
                                  +
                                  '' +
                                  (formData.lastNameindi != null &&
                                    formData.lastNameindi != '' &&
                                    formData.lastNameindi != 'undefined'
                                    ? formData.lastNameindi
                                    : formData.lastName != null &&
                                      formData.lastName != '' &&
                                      formData.lastName != 'undefined'
                                      ? formData.lastName
                                      : '')
                                } */}
                              </> : null
                          }
                          {/* {
                            formData.investmentType == "Company" ?
                              <p >
                                {formData.companyName !== null &&
                                  formData.companyName !== '' &&
                                  formData.companyName !== undefined &&
                                  formData.companyName !== 'undefined' ? (
                                  <div>
                                    {formData.companyName}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </p>
                              : ""
                          }

 */}

                        </td> &nbsp;
                        {/* <td className="term-investor">as New Partner:</td> */}
                      </tr>

                    </table>
                  </div>
                </div>

                <div className="box-EXECUTED-signature">
                  <div className="second-EXECUTED-signature sign-2">
                    {/* <div className="d-flex">
                      <p>
                        {' '}
                        <b>Dated:</b>
                      </p>{' '}
                      <b>
                        <p id="printDate"> {valss}</p>
                      </b>
                    </div> */}
                    {/* <p className='remove-margin'>Office held (Individual/Director/Trustee)</p> */}
                  </div>

                  {/* <div className="first-EXECUTED-signature">
                    <p className="round-EXECUTED paddingNone">
                      <div className="d-flex ">
                        <label id="printDate2"> DD/MM/YYYY </label>
                      </div>
                    </p>
                    <p className="round-EXECUTED paddingNone capitallll">
                      {formData.investmentType == 'Joint' || formData.investmentType == 'Company'
                        ? (formData.firstNameindi != null &&
                          formData.firstNameindi != '' &&
                          formData.firstNameindi != 'undefined'
                          ? formData.firstNameindi
                          : formData.firstName != null &&
                            formData.firstName != '' &&
                            formData.firstName != 'undefined'
                            ? formData.firstName
                            : '') +
                        ' ' +
                        (formData.middleNameindi != null &&
                          formData.middleNameindi != '' &&
                          formData.middleNameindi != 'undefined'
                          ? formData.middleNameindi
                          : formData.middleName != null &&
                            formData.middleName != '' &&
                            formData.middleName != 'undefined'
                            ? formData.middleName + ' '
                            : '') +
                        '' +
                        (formData.lastNameindi != null &&
                          formData.lastNameindi != '' &&
                          formData.lastNameindi != 'undefined'
                          ? ' ' + formData.lastNameindi
                          : formData.lastName != null &&
                            formData.lastName != '' &&
                            formData.lastName != 'undefined'
                            ? ' ' + formData.lastName
                            : '')
                        : formData.investmentType == 'Individual'
                          ? (formData.firstNameindi != null &&
                            formData.firstNameindi != '' &&
                            formData.firstNameindi != 'undefined'
                            ? formData.firstNameindi
                            : formData.firstName != null &&
                              formData.firstName != '' &&
                              formData.firstName != 'undefined'
                              ? formData.firstName
                              : '') +
                          ' ' +
                          (formData.middleNameindi != null &&
                            formData.middleNameindi != '' &&
                            formData.middleNameindi != 'undefined'
                            ? formData.middleNameindi
                            : formData.middleName != null &&
                              formData.middleName != '' &&
                              formData.middleName != 'undefined'
                              ? formData.middleName + ' '
                              : '') +
                          '' +
                          (formData.lastNameindi != null &&
                            formData.lastNameindi != '' &&
                            formData.lastNameindi != 'undefined'
                            ? ' ' + formData.lastNameindi
                            : formData.lastName != null &&
                              formData.lastName != '' &&
                              formData.lastName != 'undefined'
                              ? ' ' + formData.lastName
                              : '')
                          : ''}
                    </p>
                    <p className="round-EXECUTED paddingNone">
                      <Option
                        type="text"
                        autoComplete="off"
                        className={
                          OptionFund12 === 'Select' ||
                            OptionFund12 === undefined ||
                            OptionFund12 === '' ||
                            OptionFund12 === null
                            ? 'colorgrey'
                            : 'colorblack'
                        }
                        onClick={removeError}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                        name="OptionFund12"
                        value={OptionFund12}
                      />
                      <span className="error errorroll Role"></span>
                    </p>
                  </div> */}
                </div>
              </div>

              <div className="signature-content signature-content-2">
                <div className="Signature-person">
                  <div onClick={removeError}>
                    <label>Signature of authorised person:</label>
                    <SignaturePad
                      className="signature_h"
                      clearButton="true"
                      // className="sigCanvas"
                      ref={mySignatureRefAderence}
                    />
                    <br></br>
                    <span className="error signError"></span>
                  </div>
                  <button onClick={Clear}>Clear</button>
                </div>
              </div>
            </div>

            {formData.investmentType === 'Joint' &&
              (jointHolderDetails === 'true' ||
                jointHolderDetails === 'undefined' ||
                jointHolderDetails === undefined) ? (
              <div class="main-signature-content">
                <div className="EXECUTED-content_top_cnt">
                  {/* <div className="EXECUTED-content">
                    <p>EXECUTED AS AN AGREEMENT:</p>
                    <div className="upper-space-table">
                      <table className="behalf-sign">
                        <tr>
                          <td className="term-Signed">And</td>
                        </tr>
                        <tr>
                        <td className="term-Signed">SIGNED for and on behalf of</td>&nbsp;
                        <td className="term-entity" >
                        {formData.investmentType === 'Joint'
                          ? (formData.firstNameJoint != null &&
                            formData.firstNameJoint != '' &&
                            formData.firstNameJoint != 'undefined'
                            ? formData.firstNameJoint
                            : formData.firstName1 != null &&
                              formData.firstName1 != '' &&
                              formData.firstName1 != 'undefined'
                              ? formData.firstName1
                              : '') +
                          ' ' +
                          (formData.middleNamejoint != null &&
                            formData.middleNamejoint != '' &&
                            formData.middleNamejoint != 'undefined'
                            ? formData.middleNamejoint
                            : formData.middleName1 != null &&
                              formData.middleName1 != '' &&
                              formData.middleName1 != 'undefined'
                              ? formData.middleName1 + ' '
                              : '') +
                          '' +
                          (formData.lastNamejoint != null &&
                            formData.lastNamejoint != '' &&
                            formData.lastNamejoint != 'undefined'
                            ? formData.lastNamejoint
                            : formData.lastName1 != null &&
                              formData.lastName1 != '' &&
                              formData.lastName1 != 'undefined'
                              ? formData.lastName1
                              : '')
                          : (formData.firstNameindi != null &&
                            formData.firstNameindi != '' &&
                            formData.firstNameindi != 'undefined'
                            ? formData.firstNameindi
                            : formData.firstName != null &&
                              formData.firstName != '' &&
                              formData.firstName != 'undefined'
                              ? formData.firstName
                              : '') +
                          ' ' +
                          (formData.middleNameindi != null &&
                            formData.middleNameindi != '' &&
                            formData.middleNameindi != 'undefined'
                            ? formData.middleNameindi
                            : formData.middleName != null &&
                              formData.middleName != '' &&
                              formData.middleName != 'undefined'
                              ? formData.middleName + ' '
                              : '') +
                          '' +
                          (formData.lastNameindi != null &&
                            formData.lastNameindi != '' &&
                            formData.lastNameindi != 'undefined'
                            ? formData.lastNameindi
                            : formData.lastName != null &&
                              formData.lastName != '' &&
                              formData.lastName != 'undefined'
                              ? formData.lastName
                              : '')}
                        </td>
                         &nbsp;<td>as New Partner:</td> 
                        </tr>
                      </table>
                    </div>
                    <p>SIGNED for and on behalf of  as New Partner:</p>
                  </div> */}
                  <div className="box-EXECUTED-signature">
                    <div className="second-EXECUTED-signature">
                      {/* <p className='Dates remove-margin'>Date</p>
                      <p className='remove-margin'>Full name of Authorised Person</p>
                      <p className='remove-margin'>Office held (Individual/Director/Trustee)</p> */}
                    </div>
                    {/* <div className="first-EXECUTED-signature">
                      <p className="round-EXECUTED paddingNone">
                        <div className="d-flex ">
                          <label id="printDate21"> DD/MM/YYYY </label>
                        </div>
                      </p>
                      <p className="round-EXECUTED paddingNone textCapitalize">
                        {formData.investmentType === 'Joint'
                          ? (formData.firstNameJoint != null &&
                            formData.firstNameJoint != '' &&
                            formData.firstNameJoint != 'undefined'
                            ? formData.firstNameJoint
                            : formData.firstName1 != null &&
                              formData.firstName1 != '' &&
                              formData.firstName1 != 'undefined'
                              ? formData.firstName1
                              : '') +
                          ' ' +
                          (formData.middleNamejoint != null &&
                            formData.middleNamejoint != '' &&
                            formData.middleNamejoint != 'undefined'
                            ? formData.middleNamejoint
                            : formData.middleName1 != null &&
                              formData.middleName1 != '' &&
                              formData.middleName1 != 'undefined'
                              ? formData.middleName1 + ' '
                              : '') +
                          '' +
                          (formData.lastNamejoint != null &&
                            formData.lastNamejoint != '' &&
                            formData.lastNamejoint != 'undefined'
                            ? formData.lastNamejoint
                            : formData.lastName1 != null &&
                              formData.lastName1 != '' &&
                              formData.lastName1 != 'undefined'
                              ? formData.lastName1
                              : '')
                          : (formData.firstNameindi != null &&
                            formData.firstNameindi != '' &&
                            formData.firstNameindi != 'undefined'
                            ? formData.firstNameindi
                            : formData.firstName != null &&
                              formData.firstName != '' &&
                              formData.firstName != 'undefined'
                              ? formData.firstName
                              : '') +
                          ' ' +
                          (formData.middleNameindi != null &&
                            formData.middleNameindi != '' &&
                            formData.middleNameindi != 'undefined'
                            ? formData.middleNameindi
                            : formData.middleName != null &&
                              formData.middleName != '' &&
                              formData.middleName != 'undefined'
                              ? formData.middleName + ' '
                              : '') +
                          '' +
                          (formData.lastNameindi != null &&
                            formData.lastNameindi != '' &&
                            formData.lastNameindi != 'undefined'
                            ? formData.lastNameindi
                            : formData.lastName != null &&
                              formData.lastName != '' &&
                              formData.lastName != 'undefined'
                              ? formData.lastName
                              : '')}
                      </p>
                      <p className="round-EXECUTED paddingNone">
                        <Option
                          type="text"
                          autoComplete="off"
                          className={
                            OptionFund121 === 'Select' ||
                              OptionFund121 === undefined ||
                              OptionFund121 === '' ||
                              OptionFund121 === null
                              ? 'colorgrey'
                              : 'colorblack'
                          }
                          onClick={removeError}
                          onChange={(e) => {
                            setForm(e);
                            removeError();
                          }}
                          name="OptionFund121"
                          value={OptionFund121}
                        />
                        <span className="error errorroll Role1"></span>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="upper-space-table ">
                  <table className="behalf-sign bhf-sign-2">
                    <tr>
                      <td className="term-Signed">Signature of applicant:</td>
                      <td className="term-entity">
                        <div className="centername">
                        {formData.investmentType === 'Joint'
                            ? (formData.firstNameJoint != null &&
                              formData.firstNameJoint != '' &&
                              formData.firstNameJoint != 'undefined'
                              ? formData.firstNameJoint
                              : formData.firstName1 != null &&
                                formData.firstName1 != '' &&
                                formData.firstName1 != 'undefined'
                                ? formData.firstName1
                                : '') +
                            ' ' +
                            (formData.middleNamejoint != null &&
                              formData.middleNamejoint != '' &&
                              formData.middleNamejoint != 'undefined'
                              ? formData.middleNamejoint
                              : formData.middleName1 != null &&
                                formData.middleName1 != '' &&
                                formData.middleName1 != 'undefined'
                                ? formData.middleName1 + ' '
                                : '') +
                            '' +
                            (formData.lastNamejoint != null &&
                              formData.lastNamejoint != '' &&
                              formData.lastNamejoint != 'undefined'
                              ? formData.lastNamejoint
                              : formData.lastName1 != null &&
                                formData.lastName1 != '' &&
                                formData.lastName1 != 'undefined'
                                ? formData.lastName1
                                : '')
                            : (formData.firstNameindi != null &&
                              formData.firstNameindi != '' &&
                              formData.firstNameindi != 'undefined'
                              ? formData.firstNameindi
                              : formData.firstName != null &&
                                formData.firstName != '' &&
                                formData.firstName != 'undefined'
                                ? formData.firstName
                                : '') +
                            ' ' +
                            (formData.middleNameindi != null &&
                              formData.middleNameindi != '' &&
                              formData.middleNameindi != 'undefined'
                              ? formData.middleNameindi
                              : formData.middleName != null &&
                                formData.middleName != '' &&
                                formData.middleName != 'undefined'
                                ? formData.middleName + ' '
                                : '') +
                            '' +
                            (formData.lastNameindi != null &&
                              formData.lastNameindi != '' &&
                              formData.lastNameindi != 'undefined'
                              ? formData.lastNameindi
                              : formData.lastName != null &&
                                formData.lastName != '' &&
                                formData.lastName != 'undefined'
                                ? formData.lastName
                                : '')}
                        </div>
                      </td>

                    </tr>
                  </table>
                  <div><span className="error errercolor OptionFundCF23"></span></div>
                </div>
                <div className="signature-content">
                  <div className="Signature-person">
                    <div onClick={removeError}>
                      <label>Signature of authorised person:</label>
                      <SignaturePad
                        className="signature_h "
                        clearButton="true"
                        // className="sigCanvas"
                        ref={mySignaturejointHolderDetails}
                      />
                      <br></br>
                      <span className="error signError1"></span>
                    </div>
                    <button onClick={Clear12}>Clear</button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="fotter-btn">
            <button className="preview" onClick={previousHandler}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>

            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Twentyone1;
