import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import logo from '../logo.png';
import swal from 'sweetalert';
import info from '../info.png';
import $ from 'jquery';
import MenuSidebar from './MenuSidebar';
import { getCode, getName } from 'country-list';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';


const Twentyone11 = ({ func, functwo, setForm, formData, navigation, saveAndExist }) => {
  const { otherOccupation, fulllname, termss, countryOfBirth, terms } = formData;
  const { previous, next } = navigation;
  // const query=useQuery();;

  // let queryValue=query.get("update") || "";
  // const queryCheck=query.get("name") || "";
  const queryValue = sessionStorage.getItem("queryValue");

  // Validation All section starts.....................
  const [check, setCheck] = useState(true);
  formData.check = check;
  const [check2, setCheck2] = useState(true);
  formData.check2 = check2;
  const [check3, setCheck3] = useState(true);
  formData.check3 = check3;
  const [check4, setCheck4] = useState(true);
  formData.check4 = check4;
  const [check5, setCheck5] = useState(true);
  formData.check5 = check5;

  const [check6, setCheck6] = useState(true);
  formData.check6 = check6;

  const [check61, setCheck61] = useState(true);
  formData.check61 = check61;

  const [check7, setCheck7] = useState(true);
  formData.check7 = check7;
  // Validation All section endsWith...............

  const infodetls2 = () => {
    swal({
      text: "You need to complete all required information before submitting your application. Please return to the menu button(s) on the left shown in red, which indicate the section where some fields have not yet been completed, and fill in those questions. Once you have completed the missing field(s), you can click on the menu button Review & Submit to return to this page and submit your application."
    })
  };

  useEffect(() => {
    if (check7 === false || check === false || check2 === false || check3 === false || check4 === false || check5 === false || check6 === false || check61 === false) {
      infodetls2()
    }
  })

  const [investbussiness1, setInvestbussiness1] = useState(formData.investmentBusines != "" &&
    formData.investmentBusiness != null ? formData.investmentBusiness.split('##') : ''
  );
  const [investproduct1, setInvestproduct1] = useState(
    formData.investmentProductExp != null ? formData.investmentProductExp.split('##') : ''
  );

  const [investment1, setInvestment] = useState(
    formData.investmentCriteria != null ? formData.investmentCriteria.split('##') : ''
  );
  const [otherinvest1, setOtherinvest] = useState(
    formData.otherInvestCriteria != null ? formData.otherInvestCriteria.split('##') : ''
  );

  const [certficateDetails2, setCertificateDetails2] = useState(
    formData.Certificate2 !== null && formData.Certificate2 !== "" && formData.Certificate2 !== "undefined"
      && formData.Certificate2 !== undefined ? formData.Certificate2.split('##') : ""
  )

  formData.hhhhh =
    investbussiness1 !== null &&
      investbussiness1 !== '' &&
      investbussiness1 !== 'undefined' &&
      investbussiness1 !== undefined
      ? investbussiness1
      : '';
  formData.hhhhh1 =
    investment1 !== null &&
      investment1 !== '' &&
      investment1 !== 'undefined' &&
      investment1 !== undefined
      ? investment1
      : '';
  formData.hhhhh12 =
    investproduct1 !== null &&
      investproduct1 !== '' &&
      investproduct1 !== 'undefined' &&
      investproduct1 !== undefined
      ? investproduct1
      : '';



  let JointAcount = sessionStorage.getItem('Jointacoount');
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '17';
    go('submit');
  };


  const saveandexit = () => {
    func();
  };

  let setValues = localStorage.getItem('setValues');

  let applicationType = sessionStorage.getItem('applicationType');


  let previousHandler = () => {
    if (applicationType === 'Trust') {
      go('Jointfivteen');
    } else {
      formData.termss = termss
      formData.terms = terms
      formData.termsss1 = false
      formData.termsss2 = false
      formData.termsss3 = false
      formData.futureInvestementOpportunities = false
      if (queryValue == null || queryValue == undefined || queryValue == "") {
        formData.class = 'Twentyone';
        // previous();
        go('Twentyone')
      }
      else {
        formData.class = 'Nine';
        // previous();
        go('Eleven')
      }

    }
  };

  ///

  function setDateformate(date) {
    if (
      date !== null &&
      date !== 'undefined' &&
      typeof date !== 'undefined' &&
      date.includes('/')
    ) {
      let dat = date.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
    return date;
  }


  let residentialAddress1 = sessionStorage.getItem("residentialAddress1");
  let residentialAddress2 = sessionStorage.getItem("residentialAddress2");

  const handleClick = async (e, values) => {
    if (queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "") {
      // alert("jjj")
      functwo();

    }
    else {
      let pages = formData.tickCount;


      if (formData.investmentType == 'Individual' && pages === 7) {
        formData.color = false
        functwo();
      }
      else if (formData.investmentType == 'Company' && pages === 7) {
        formData.color = false
        functwo();
      }
      else if (formData.investmentType == 'Trust' && pages === 7) {
        formData.color = false
        functwo();
      }
      else if (formData.investmentType == 'Joint' && pages === 8) {
        formData.color = false
        functwo();
      } else {
        formData.color = true
        if (formData.seventhTick === false || formData.eightTick === false) {
          $('#seven').addClass("fillColor");
        }
        if (formData.firstTick === false) {
          $('#first').addClass("fillColor");
          setCheck2(false);
        }
        if (formData.secondTick === false) {
          $('#second').addClass("fillColor");
          setCheck6(false)
        }
        if (formData.secondTick2 === false) {
          $('#second2').addClass("fillColor");
          setCheck61(false)
        }
        if (formData.secondTick2 === false) {
          $('#second3').addClass("fillColor");
          setCheck61(false)
        }
        if (formData.thirdTick === false || formData.fourthTick == false) {
          $('#third').addClass("fillColor");
          setCheck(false);
        }
        if (formData.fifthTick === false || formData.sixthTick == false) {
          setCheck3(false);
          if (formData.documentProof1 != "" && formData.documentProof1 != null && formData.documentProof1 != "undefined" && formData.documentProof1 != undefined) {
            formData.documentnotupload = true
          }
          else {
            formData.documentnotupload = false
          }
          if (formData.documentProof21 != "" && formData.documentProof21 != null && formData.documentProof21 != "undefined" && formData.documentProof21 != undefined) {
            formData.documentnotupload1 = true
          }
          else {
            formData.documentnotupload1 = false
          }
          $('#fifth').addClass("fillColor");
        }
        if (formData.seventhTick === false || formData.eightTick === false) {
          setCheck4(false)
        }
        if (formData.nineTick === false) {
          $('#nine').addClass("fillColor");
          setCheck5(false)
        }
        if (formData.elevenTick === false) {
          $('#eleven').addClass("fillColor");
          setCheck7(false)
        }
      }
    }
  };


  function removeError() {
    $('.error').html('');
  }
  const { go } = navigation;
  // function numberWithCommas(x) {
  //   return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
  // }

  let IndividualExpiryDate = sessionStorage.getItem("documentExpiryDates");
  let JointExpiryDate = sessionStorage.getItem("documentExpiryDates1");
  console.log("functwo", functwo)
  // alert(functwo)
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 8: Review & Submit</h2> :
              <h2>Step 7: Review & Submit</h2>
          }
          {/* <h2>Application: Review & Submit</h2> */}
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          {!JointAcount ? (
            <>

              {queryValue != null && queryValue != undefined || queryValue != "" ? <p></p> :
                <div className="save-exit se_invff">
                  <p className="save next" onClick={saveandexit}>
                    SAVE DATA{' '}
                  </p>
                  <img src={info} onClick={infodetls} className="info" />
                </div>
              }
            </>
          ) : null}

          {formData.investmentType === 'Individual' || formData.investmentType === 'Company' || formData.investmentType === 'Trust' ? (
            <div className="ind">
              <h3>Investment Details:</h3>
              <div className="review_b">
                <label>Entity Type</label>
                <p>{formData.investmentType} </p>
              </div>
              {formData.fundCode !== null &&
                formData.fundCode !== '' &&
                formData.fundCode !== 'undefined' &&
                formData.fundCode !== undefined ? (
                <div className="review_b">
                  <label>Investment Opportunity</label>

                  <p>{formData.fundCode} </p>
                </div>
              ) : (
                ''
              )}
              {formData.totalInvestment !== null &&
                formData.totalInvestment !== '' &&
                formData.totalInvestment !== 'undefined' &&
                formData.totalInvestment !== undefined ? (
                <div className="review_b">
                  <label>Investment Amount</label>
                  <p>${formData.totalInvestment} </p>
                </div>
              ) : (
                ''
              )}
              {formData.investmentType === 'Company' ? (
                <>
                  <hr />
                  <h3>Entity Details:</h3>

                  {formData.companyName !== null &&
                    formData.companyName !== '' &&
                    formData.companyName !== undefined &&
                    formData.companyName !== 'undefined' ? (
                    <div className="review_b">
                      <label>Company Name</label>
                      <p className='textCapitalize'>{formData.companyName}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.companyNumber !== null &&
                    formData.companyNumber !== '' &&
                    formData.companyNumber !== undefined &&
                    formData.companyNumber !== 'undefined' ? (
                    <div className="review_b">
                      <label>Company Number</label>
                      <p className='textCapitalize'>{formData.companyNumber}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.companyTradingName !== null &&
                    formData.companyTradingName !== '' &&
                    formData.companyTradingName !== undefined &&
                    formData.companyTradingName !== 'undefined' ? (
                    <div className="review_b">
                      <label>Trading Name (if relevant)</label>
                      <p className='textCapitalize'>{formData.companyTradingName}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.companyRegisterAddress !== null &&
                    formData.companyRegisterAddress !== '' &&
                    formData.companyRegisterAddress !== undefined &&
                    formData.companyRegisterAddress !== 'undefined' ? (
                    <div className="review_b">
                      <label>Registered Address</label>
                      <p className='textCapitalize'>{formData.companyRegisterAddress}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.otherCountryCompanyRegistered !== null &&
                    formData.otherCountryCompanyRegistered !== '' &&
                    formData.otherCountryCompanyRegistered !== "Select" &&
                    formData.otherCountryCompanyRegistered !== undefined &&
                    formData.otherCountryCompanyRegistered !== 'undefined' ? (
                    <div className="review_b">
                      <label>Other Country The Company Is Registered In</label>
                      <p className='textCapitalize'>{formData.otherCountryCompanyRegistered}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.fullLegalCompanyName !== null &&
                    formData.fullLegalCompanyName !== '' &&
                    formData.fullLegalCompanyName !== undefined &&
                    formData.fullLegalCompanyName !== 'undefined' ? (
                    <div className="review_b">
                      <label>Full Legal Company Name</label>
                      <p className='textCapitalize'>{formData.fullLegalCompanyName}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {
                    (formData.secFullLegalCompanyName !== null &&
                      formData.secFullLegalCompanyName !== '' && formData.secFullLegalCompanyName !== undefined)
                      ? (
                        <div className="review_b">
                          <label>Other Country The Company Is Registered In</label>
                          <p className='textCapitalize'>{formData.companySecCountryOfResidence}</p>
                        </div>
                      ) : (
                        null
                      )}
                  {formData.secFullLegalCompanyName !== null &&
                    formData.secFullLegalCompanyName !== '' &&
                    formData.secFullLegalCompanyName !== undefined &&
                    formData.secFullLegalCompanyName !== 'undefined' ? (
                    <div className="review_b">
                      <label>Full Legal Company Name</label>
                      <p className='textCapitalize'>{formData.secFullLegalCompanyName}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.inputList && formData.inputList.map((trustIndividual) => {
                    return (
                      <>
                        <h4>Company Shareholder Details:</h4>
                        {
                          trustIndividual.fullLegalName !== null && trustIndividual.fullLegalName !== 'undefined' && trustIndividual.fullLegalName !== "" ?
                            <div className="review_b">
                              <label>Name of Shareholder </label>
                              <p className='capitallll'>{trustIndividual.fullLegalName}</p>
                            </div> : null
                        }

                        {
                          trustIndividual.companySharePercent !== null && trustIndividual.companySharePercent !== undefined && trustIndividual.companySharePercent !== "" ?
                            <div className="review_b">
                              <label>Company Share %</label>
                              <p className='capitallll'>{trustIndividual.companySharePercent}%</p>
                            </div> : ""
                        }

                        {
                          trustIndividual.isNoShareCompany !== null && trustIndividual.isNoShareCompany !== undefined && trustIndividual.isNoShareCompany !== "" ?
                            <div className="review_b">
                              <label>Shareholder a Trust or a Company</label>
                              <p className='capitallll'>{trustIndividual.isNoShareCompany}</p>
                            </div> : ""
                        }

                        {
                          trustIndividual.userEmail !== null && trustIndividual.userEmail !== undefined && trustIndividual.userEmail !== "" ?
                            <div className="review_b">
                              <label>Shareholder Email</label>
                              <p className="JointHoldersEmail">{trustIndividual.userEmail}</p>
                            </div> : ""
                        }

                      </>
                    )

                  }
                  )}

                  {formData.inputData && formData.inputData.map((companyinputData) => {
                    return (
                      <>
                        <h4>Company Director Details:</h4>
                        {
                          companyinputData.fullLegalName !== null && companyinputData.fullLegalName !== 'undefined' && companyinputData.fullLegalName !== "" ?
                            <div className="review_b">
                              <label>Name of Director </label>
                              <p className='capitallll'>{companyinputData.fullLegalName}</p>
                            </div> : null
                        }
                        {
                          companyinputData.DirectorRole !== null && companyinputData.DirectorRole !== 'undefined' && companyinputData.DirectorRole !== "" ?
                            <div className="review_b">
                              <label>Role </label>
                              <p className='capitallll'>{companyinputData.DirectorRole}</p>
                            </div> : null
                        }
                        {
                          companyinputData.DirectorRole == 'Other' ?
                            <div className="review_b">
                              <label>Other Role </label>
                              <p className='capitallll'>{companyinputData.otherDirectorDetailRole}</p>
                            </div> : null
                        }
                        {
                          companyinputData.userEmail !== null && companyinputData.userEmail !== 'undefined' && companyinputData.userEmail !== "" ?
                            <div className="review_b">
                              <label>Director Email</label>
                              <p className="JointHoldersEmail">{companyinputData.userEmail}</p>
                            </div> : null
                        }

                      </>
                    )

                  }
                  )}

                  <hr />
                  {
                    formData.isHoldingPersonalAssets == "true" ?
                      <div className="review_b">
                        <label>Holding Personal Assets</label>
                        <p className='textCapitalize'>Yes</p>
                      </div> :

                      <div className="review_b">
                        <label>Holding Personal Assets</label>
                        <p className='textCapitalize'>No</p>
                      </div>
                  }

                  {
                    formData.isCompanyIndividualOperating == "true" ?
                      <div className="review_b">
                        <label>Company Individual Operating</label>
                        <p className='textCapitalize'>Yes</p>
                      </div> :

                      <div className="review_b">
                        <label>Company Individual Operating</label>
                        <p className='textCapitalize'>No</p>
                      </div>
                  }
                  {
                    formData.individualFullName !== "" &&
                      formData.individualFullName !== null &&
                      formData.individualFullName !== undefined ? (
                      <div className="review_b">
                        <label>Company Individual Full Name</label>
                        <p className='textCapitalize'>{formData.individualFullName}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }
                  {
                    formData.IndividualRole !== "" &&
                      formData.IndividualRole !== null &&
                      formData.IndividualRole !== undefined ? (
                      <div className="review_b">
                        <label>Company Individual Role</label>
                        <p className='textCapitalize'>{formData.IndividualRole}</p>
                      </div>
                    ) : (
                      ''
                    )
                  } {
                    formData.IndividualEmail !== "" &&
                      formData.IndividualEmail !== null &&
                      formData.IndividualEmail !== undefined ? (
                      <div className="review_b">
                        <label>Company Individual Email</label>
                        <p className="JointHoldersEmail">{formData.IndividualEmail}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }
                  {
                    formData.isOtherCompanyIndividual == "true" ?

                      <div className="review_b">
                        <label>Other Company Individual</label>
                        <p className='textCapitalize'>Yes</p>
                      </div> :

                      <div className="review_b">
                        <label>Other Company Individual</label>
                        <p className='textCapitalize'>No</p>
                      </div>

                  }
                  {
                    formData.secondaryIndividualFullName !== "" &&
                      formData.secondaryIndividualFullName !== null &&
                      formData.secondaryIndividualFullName !== undefined ? (
                      <div className="review_b">
                        <label>Company Secondary Individual Full Name</label>
                        <p className='textCapitalize'>{formData.secondaryIndividualFullName}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }{
                    formData.secondaryIndividualRole !== "" &&
                      formData.secondaryIndividualRole !== null &&
                      formData.secondaryIndividualRole !== undefined ? (
                      <div className="review_b">
                        <label>Company Secondary Individual Role</label>
                        <p className='textCapitalize'>{formData.secondaryIndividualRole}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }{
                    formData.SecondaryIndividualEmail !== "" &&
                      formData.SecondaryIndividualEmail !== null &&
                      formData.SecondaryIndividualEmail !== undefined ? (
                      <div className="review_b">
                        <label>Company Secondary Individual Email</label>
                        <p className="JointHoldersEmail">{formData.SecondaryIndividualEmail}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }

                  {
                    formData.companyIrdNumber !== null &&
                      formData.companyIrdNumber !== '' &&
                      formData.companyIrdNumber !== undefined &&
                      formData.companyIrdNumber !== 'undefined' ? (
                      <div className="review_b">
                        <label>Company IRD Number</label>
                        <p className='textCapitalize'>{formData.companyIrdNumber}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }


                  {formData.rwtComp !== null &&
                    formData.rwtComp !== '' &&
                    formData.rwtComp !== 'undefined' &&
                    formData.rwtComp !== undefined ? (
                    <div className="review_b">
                      <label> Tax Type</label>
                      <p>{formData.rwtComp} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.checkboxComp !== null &&
                    formData.checkboxComp !== '' &&
                    formData.checkboxComp !== 'undefined' &&
                    formData.checkboxComp === true && formData.rwtComp === "Non Resident Withholding Tax" ? (
                    <div className="review_b">
                      <label>Resident Withholding Tax Exemption</label>
                      <p>{formData.checkboxComp === true ? "Yes" : "No"}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.rwtValueComp !== null &&
                    formData.rwtValueComp !== '' &&
                    formData.rwtValueComp !== 'undefined' &&
                    formData.rwtValueComp !== undefined &&
                    formData.rwtComp === "Resident Withholding Tax" ? (
                    <div className="review_b">
                      <label> Resident Withholding Tax Rate</label>
                      <p>{formData.rwtValueComp} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* {formData.companyCountryOfResidence !== null &&
                    formData.companyCountryOfResidence !== '' &&
                    formData.companyCountryOfResidence !== 'undefined' &&
                    formData.companyCountryOfResidence !== undefined &&
                    formData.rwtComp === "Resident Withholding Tax" ? (
                    <div className="review_b">
                      <label> Country of tax residence</label>
                      <p>{formData.companyCountryOfResidence} </p>
                    </div>
                  ) : (
                    ''
                  )} */}
                  {/* {formData.companyTinNumber !== null &&
                    formData.companyTinNumber !== '' &&
                    formData.companyTinNumber !== 'undefined' &&
                    formData.companyTinNumber !== undefined &&
                    formData.rwtComp === "Resident Withholding Tax" ? (
                    <div className="review_b">
                      <label>Tax Identification Number (TIN)</label>
                      <p>{formData.companyTinNumber} </p>
                    </div>
                  ) : (
                    ''
                  )} */}
                  {/* {formData.companyTinReason !== null &&
                    formData.companyTinReason !== '' &&
                    formData.companyTinReason !== 'undefined' &&
                    formData.companyTinReason !== undefined &&
                    formData.rwtComp === "Resident Withholding Tax" ? (
                    <div className="review_b">
                     <label>Reason if TIN is not available</label>
                      <p>{formData.companyTinReason} </p>
                    </div>
                  ) : (
                    ''
                  )} */}
                  {
                    formData.isCompanyOhterCountryCitizen == 'true' ?
                      formData.companyCountryOfResidence !== null &&
                        formData.companyCountryOfResidence !== '' &&
                        formData.companyCountryOfResidence !== 'undefined' &&
                        formData.companyCountryOfResidence !== undefined
                        ?
                        (<div className="review_b">
                          <label>Country of Tax Residence</label>
                          <p>{formData.companyCountryOfResidence} </p>
                        </div>)
                        :
                        ('')
                      : ''
                  }
                  {
                    formData.isCompanyOhterCountryCitizen == 'true' ?
                      formData.companyTinNumber !== null &&
                        formData.companyTinNumber !== '' &&
                        formData.companyTinNumber !== 'undefined' &&
                        formData.companyTinNumber !== undefined
                        ?
                        (<div className="review_b">
                          <label>Tax Identification Number (TIN)</label>
                          <p>{formData.companyTinNumber} </p>
                        </div>)
                        :
                        ('')
                      : ''
                  }
                  {
                    formData.isCompanyOhterCountryCitizen == 'true' ?
                      formData.companyTinReason !== null &&
                        formData.companyTinReason !== '' &&
                        formData.companyTinReason !== 'undefined' &&
                        formData.companyTinReason !== undefined
                        ?
                        (<div className="review_b">
                          <label>Reason If TIN Is Not Available</label>
                          <p>{formData.companyTinReason} </p>
                        </div>)
                        : ('') : ''
                  }
                  {
                    formData.companyFundReason !== null &&
                      formData.companyFundReason !== '' &&
                      formData.companyFundReason !== undefined &&
                      formData.companyFundReason !== 'undefined' ? (
                      <div className="review_b">
                        <label>Company Fund Reason</label>
                        <p className='textCapitalize'>{formData.companyFundReason}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }

                  {
                    formData.companyGenerateIncome !== null &&
                      formData.companyGenerateIncome !== '' &&
                      formData.companyGenerateIncome !== undefined &&
                      formData.companyGenerateIncome !== 'undefined' ? (
                      <div className="review_b">
                        <label>Company Generate Income</label>
                        <p className='textCapitalize'>{formData.companyGenerateIncome}</p>
                      </div>
                    ) : (
                      ''
                    )
                  }
                </>
              ) : ('')}
              {formData.investmentType === 'Trust' ? (
                <>
                  <hr />
                  <h3>Trust Details:</h3>
                  {formData.trustName !== null &&
                    formData.trustName !== '' &&
                    formData.trustName !== undefined &&
                    formData.trustName !== 'undefined' ? (
                    <div className="review_b">
                      <label>Trust Name</label>
                      <p className='textCapitalize'>{formData.trustName}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.trustcountryOfIncorporation !== null &&
                    formData.trustcountryOfIncorporation !== '' &&
                    formData.trustcountryOfIncorporation !== undefined &&
                    formData.trustcountryOfIncorporation !== 'undefined' ? (
                    <div className="review_b">
                      <label>Country of Incorporation</label>
                      <p className='textCapitalize'>{formData.trustcountryOfIncorporation}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trustRegisterAddress !== null &&
                    formData.trustRegisterAddress !== '' &&
                    formData.trustRegisterAddress !== undefined &&
                    formData.trustRegisterAddress !== 'undefined' ? (
                    <div className="review_b">
                      <label>Trust Register Address</label>
                      <p className='textCapitalize'>{formData.trustRegisterAddress}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trust_type !== null &&
                    formData.trust_type !== '' &&
                    formData.trust_type !== undefined &&
                    formData.trust_type !== 'undefined' ? (
                    <div className="review_b">
                      <label>Trust Type</label>
                      <p className='textCapitalize'>{formData.trust_type}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trust_type_other !== null &&
                    formData.trust_type_other !== '' &&
                    formData.trust_type_other !== undefined &&
                    formData.trust_type_other !== 'undefined' ? (
                    <div className="review_b">
                      <label>Trust Other Type</label>
                      <p className='textCapitalize'>{formData.trust_type_other}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.inputDataList && formData.inputDataList.map((trustIndividual) => {
                    return (
                      <>
                        <hr></hr>
                        <h3>Effective Control Of The Trust</h3>
                        <div className="review_b">
                          <label>Full Legal Name</label>
                          <p className='capitallll'>{trustIndividual.fullLegalName}</p>
                        </div>
                        <div className="review_b">
                          <label>Trust User Email</label>
                          <p className='capitallll'>{trustIndividual.userEmail}</p>
                        </div>
                        <div className="review_b">
                          <label>Trust User Roles</label>
                          <p>{`${trustIndividual.isSettlor == true ? "Settlor" : ""} ${trustIndividual.isTrustee == true ? "Trustee" : ""} ${trustIndividual.isAuthPerson == true ? "Auth-Person" : ""} ${trustIndividual.isEffectiveControl == true ? "Effective-Control" : ""}`.trim().replaceAll(' ', ', ').replaceAll(',,,', ',').replaceAll(', ,', ',').replaceAll(" ,", "").replaceAll("-", " ")}</p>
                          {/* <p className='capitallll'>{trustIndividual.is_settlor == true ? "Settlor" : ""} {trustIndividual.is_trustee == true ? "Trustee," : ""} {trustIndividual.is_auth_person == true ? "Auth Person ," : ""} {trustIndividual.is_effective_control == true ? "Effective Control" : ""}</p> */}
                        </div>
                      </>
                    )
                  })}
                  {formData.directorRoles && formData.directorRoles.map((directorRolesIndividual) => {
                    return (
                      <>
                        <hr></hr>
                        <h3>Corporate Trustee Company</h3>
                        {formData.optionsd !== null &&
                          formData.optionsd !== '' &&
                          formData.optionsd !== 'undefined' &&
                          formData.optionsd !== undefined 
                           ? (
                          <div className="review_b">
                            <label>Company Name</label>
                            <p>{formData.optionsd} </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* {formData.optionsd} */}
                        <div className="review_b">
                          <label>Full Legal Name</label>
                          <p className='capitallll'>{directorRolesIndividual.fullLegalName}</p>
                        </div>
                        <div className="review_b">
                          <label>Trust User Email</label>
                          <p className='capitallll'>{directorRolesIndividual.userEmail}</p>
                        </div>

                      </>
                    )
                  })}<hr />
                  <h3>Tax Details</h3>
                  {formData.TrustIrdNumber !== null &&
                    formData.TrustIrdNumber !== '' &&
                    formData.TrustIrdNumber !== undefined &&
                    formData.TrustIrdNumber !== 'undefined' ? (
                    <div className="review_b">
                      <label>Trust IRD Number</label>
                      <p className='textCapitalize'>{formData.TrustIrdNumber}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.rwtTrust !== null &&
                    formData.rwtTrust !== '' &&
                    formData.rwtTrust !== 'undefined' &&
                    formData.rwtTrust !== undefined ? (
                    <div className="review_b">
                      <label> Tax Type</label>
                      <p>{formData.rwtTrust} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.checkboxTrust !== null &&
                    formData.checkboxTrust !== '' &&
                    formData.checkboxTrust !== 'undefined' &&
                    formData.checkboxTrust === true && formData.rwtTrust === "Non Resident Withholding Tax" ? (
                    <div className="review_b">
                      <label>Resident Withholding Tax Exemption</label>
                      <p>{formData.checkboxTrust === true ? "Yes" : "No"}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.rwtValueTrust !== null &&
                    formData.rwtValueTrust !== '' &&
                    formData.rwtValueTrust !== 'undefined' &&
                    formData.rwtValueTrust !== undefined &&
                    formData.rwtTrust === "Resident Withholding Tax" ? (
                    <div className="review_b">
                      <label> Resident Withholding Tax Rate</label>
                      <p>{formData.rwtValueTrust} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trustCountryOfResidence !== null &&
                    formData.trustCountryOfResidence !== '' &&
                    formData.trustCountryOfResidence !== 'undefined' &&
                    formData.trustCountryOfResidence !== undefined ? (
                    <div className="review_b">
                      <label>Trust Country of Residence</label>
                      <p>{formData.trustCountryOfResidence} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trustTinNumber !== null &&
                    formData.trustTinNumber !== '' &&
                    formData.trustTinNumber !== 'undefined' &&
                    formData.trustTinNumber !== undefined ? (
                    <div className="review_b">
                      <label>Trust Tin Number</label>
                      <p>{formData.trustTinNumber} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trustTinReason !== null &&
                    formData.trustTinReason !== '' &&
                    formData.trustTinReason !== 'undefined' &&
                    formData.trustTinReason !== undefined ? (
                    <div className="review_b">
                      <label>Trust Tin Reason</label>
                      <p>{formData.trustTinReason} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.trustFundReason !== null &&
                    formData.trustFundReason !== '' &&
                    formData.trustFundReason !== 'undefined' &&
                    formData.trustFundReason !== undefined ? (
                    <div className="review_b">
                      <label>Trust Fund Reason</label>
                      <p>{formData.trustFundReason} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trustsettlorFund !== null &&
                    formData.trustsettlorFund !== '' &&
                    formData.trustsettlorFund !== 'undefined' &&
                    formData.trustsettlorFund !== undefined ? (
                    <div className="review_b">
                      <label>Trust Settlor Fund </label>
                      <p>{formData.trustsettlorFund} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.trustGenerateIncome !== null &&
                    formData.trustGenerateIncome !== '' &&
                    formData.trustGenerateIncome !== 'undefined' &&
                    formData.trustGenerateIncome !== undefined ? (
                    <div className="review_b">
                      <label>Trust Generate Income </label>
                      <p>{formData.trustGenerateIncome} </p>
                    </div>
                  ) : (
                    ''
                  )}

                </>
              ) : ('')}

              <hr />

              <h3>Personal Information:</h3>
              {formData.fullName !== null &&
                formData.fullName !== '' &&
                formData.fullName !== undefined &&
                formData.fullName !== 'undefined' ? (
                <div className="review_b">
                  <label>Full Name</label>
                  <p className='textCapitalize'>{formData.fullName}</p>
                </div>
              ) : (
                ''
              )}

              {formData.preferredName !== null &&
                formData.preferredName !== '' &&
                formData.preferredName !== undefined &&
                formData.preferredName !== 'undefined' ? (
                <div className="review_b">
                  <label>Preferred Name</label>
                  <p className='textCapitalize'>{formData.preferredName}</p>
                </div>
              ) : (
                ''
              )}

              {formData.userEmail !== null &&
                formData.userEmail !== '' &&
                formData.userEmail !== 'undefined' &&
                formData.userEmail !== undefined ? (
                <div className="review_b">
                  <label>Email</label>
                  <p className="JointHoldersEmail">{formData.userEmail}</p>
                </div>
              ) : (
                ''
              )}

              {/* {formData.preferredname !== null &&
                formData.preferredname !== '' &&
                formData.preferredname !== undefined &&
                formData.preferredname !== 'undefined' ? (
                <div className="review_b">
                  <label>Preferred Name</label>
                  <p>{formData.preferredname} </p>
                </div>
              ) : (
                ''
              )} */}

              {formData.dateOfBirth !== null &&
                formData.dateOfBirth !== '' &&
                formData.dateOfBirth !== 'undefined' &&
                formData.dateOfBirth !== undefined ? (
                <div className="review_b">
                  <label>Date of Birth</label>
                  <p>{formData.dateOfBirth} </p>
                </div>
              ) : (
                ''
              )}

              {((formData.isPrimaryResdAddress !== null &&
                formData.isPrimaryResdAddress !== '' &&
                formData.isPrimaryResdAddress !== 'undefined' &&
                formData.isPrimaryResdAddress !== undefined)) ? (
                <div className="review_b">
                  <label>Residential Address </label>
                  <p>{formData.isPrimaryResdAddress != null &&
                    formData.isPrimaryResdAddress != '' &&
                    formData.isPrimaryResdAddress != 'undefined' && formData.manuallyAddress != 'false'
                    ? formData.isPrimaryResdAddress
                    :
                    formData.manuallyAddress == 'false' ?


                      formData.street_number +
                      (formData.region != null && formData.region != '' && formData.region != undefined ? ', ' + formData.region : '') +
                      (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
                      (formData.postalCode != null && formData.postalCode != ''
                        ? ', ' + formData.postalCode
                        : '') +
                      (formData.country != null && formData.country != '' ? ', ' + formData.country : '')
                      : null} </p>
                </div>
              ) : (
                ''
              )}
              {
                (formData.isPrimaryPostalAdress !== null &&
                  formData.isPrimaryPostalAdress !== '' &&
                  formData.isPrimaryPostalAdress !== 'undefined' &&
                  formData.isPrimaryPostalAdress !== undefined) || (formData.isPrimaryResdAddress !== null &&
                    formData.isPrimaryResdAddress !== '' &&
                    formData.isPrimaryResdAddress !== 'undefined' &&
                    formData.isPrimaryResdAddress !== undefined) ? (
                  <div className="review_b">
                    <label> {formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal Address" : (formData.isPrimaryPostalAdress !== null &&
                      formData.isPrimaryPostalAdress !== '' &&
                      formData.isPrimaryPostalAdress !== 'undefined' &&
                      formData.isPrimaryPostalAdress !== undefined) ?
                      "Postal Address" : null} </label>
                    <p className='post'>{formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal address is the same as home address" : formData.isPrimaryPostalAdress} </p>
                  </div>
                ) : (
                  ''
                )}



              {
                formData.priContactType !== '' &&
                  formData.priContactType !== undefined &&
                  formData.priContactType !== 'undefined' ? (
                  <div className="review_b">
                    <label> Primary Contact Type</label>
                    <p>{formData.priContactType == null ? "Mobile" : formData.priContactType} </p>
                  </div>
                ) : (
                  ''
                )}

              {formData.priContactNumber !== null &&
                formData.priContactNumber !== '' &&
                formData.priContactNumber !== 'undefined' &&
                formData.priContactNumber !== undefined ? (
                <div className="review_b">
                  <label>Primary Mobile Number</label>
                  <p>{"+"}{formData.priContactCountryCode != null && formData.priContactCountryCode !== "" ? formData.priContactCountryCode : "64"}{" "}{formData.priContactNumber} </p>
                </div>
              ) : (
                ''
              )}

              {
                (formData.secContactNumber !== '' && formData.secContactNumber !== null &&
                  formData.secContactNumber !== undefined &&
                  formData.secContactNumber !== 'undefined' ? (
                  <div className="review_b">
                    <label> Secondary Contact Type</label>
                    <p>{formData.secContactType === null ? "Home" : formData.secContactType} </p>
                  </div>
                ) : (
                  ''
                ))}


              {
                (formData.secContactNumber !== null &&
                  formData.secContactNumber !== '' &&
                  formData.secContactNumber !== 'undefined' &&
                  formData.secContactNumber !== undefined ? (
                  <div className="review_b">
                    <label>Secondary Mobile Number</label>
                    <p>{"+"}{formData.secContactCountryCode != null ? formData.secContactCountryCode : "64"}{" "}{formData.secContactNumber} </p>
                  </div>
                ) : (
                  ''
                ))}

              {formData.occupation !== null &&
                formData.occupation !== '' &&
                formData.occupation !== 'undefined' &&
                formData.occupation !== undefined ? (
                <div className="review_b">
                  <label>Occupation </label>
                  <p>{formData.occupation === "Other" ? otherOccupation : formData.occupation} </p>
                </div>
              ) : (
                ''
              )}

              {formData.placeOfBirth !== null &&
                formData.placeOfBirth !== '' &&
                formData.placeOfBirth !== 'undefined' &&
                formData.placeOfBirth !== undefined ? (
                <div className="review_b">
                  <label>Place of Birth </label>
                  <p>{formData.placeOfBirth} </p>
                </div>
              ) : (
                ''
              )}

              {formData.countryOfBirth !== null &&
                formData.countryOfBirth !== '' &&
                formData.countryOfBirth !== undefined &&
                formData.countryOfBirth !== 'undefined' ? (
                <div className="review_b">
                  <label>Country of Birth</label>
                  <p>{getName(formData.countryOfBirth)} </p>
                </div>
              ) : (
                ''
              )}

              {formData.residingCountry !== null &&
                formData.residingCountry !== '' &&
                formData.residingCountry !== 'undefined' &&
                formData.residingCountry !== undefined ? (
                <div className="review_b">
                  <label>Country of Residence</label>

                  <p>{getName(formData.residingCountry)} </p>
                </div>
              ) : (
                ''
              )}

              {formData.userIrdNumber !== null &&
                formData.userIrdNumber !== '' &&
                formData.userIrdNumber !== 'undefined' &&
                formData.userIrdNumber !== undefined ? (
                <div className="review_b">
                  <label>IRD Number</label>
                  <p>{formData.userIrdNumber} </p>
                </div>
              ) : (
                ''
              )}

              {formData.rwt !== null &&
                formData.rwt !== '' &&
                formData.rwt !== 'undefined' &&
                formData.rwt !== undefined ? (
                <div className="review_b">
                  <label> Tax Type</label>
                  <p>{formData.rwt} </p>
                </div>
              ) : (
                ''
              )}

              {formData.checkboxx !== null &&
                formData.checkboxx !== '' &&
                formData.checkboxx !== 'undefined' &&
                formData.checkboxx === true && formData.rwt === "Non Resident Withholding Tax" ? (
                <div className="review_b">
                  <label>Resident Withholding Tax Exemption</label>
                  <p>{formData.checkboxx === true ? "Yes" : "No"}</p>
                </div>
              ) : (
                ''
              )}

              {formData.rwtValue !== null &&
                formData.rwtValue !== '' &&
                formData.rwtValue !== 'undefined' &&
                formData.rwtValue !== undefined &&
                formData.rwt === "Resident Withholding Tax" ? (
                <div className="review_b">
                  <label> Resident Withholding Tax Rate</label>
                  <p>{formData.rwtValue} </p>
                </div>
              ) : (
                ''
              )}

              {formData.tinResidential !== null &&
                formData.tinResidential !== '' &&
                formData.tinResidential !== 'undefined' &&
                formData.tinResidential !== undefined ? (
                <div className="review_b">
                  <label>Country of tax residence</label>

                  <p>{getName(formData.tinResidential)} </p>
                </div>
              ) : (
                ''
              )}

              {formData.tinNumber !== null &&
                formData.tinNumber !== '' &&
                formData.tinNumber !== 'undefined' &&
                formData.tinNumber !== undefined ? (
                <div className="review_b">
                  <label>Tax Identification Number (TIN)</label>
                  <p>{formData.tinNumber} </p>
                </div>
              ) : (
                ''
              )}

              {formData.tinreason !== null &&
                formData.tinreason !== '' &&
                formData.tinreason !== 'undefined' &&
                formData.tinreason !== undefined ? (
                <div className="review_b">
                  <label>Reason if TIN is not available</label>
                  <p>{formData.tinreason} </p>
                </div>
              ) : (
                ''
              )}

              <hr />


              {formData.biometric === "Biometric" ? "" :
                <>
                  <h3>Identity Verification:</h3>
                  {(formData.idType === '' || formData.idType === '1' || formData.idType === '2' || formData.idType === '3') ? (
                    <div className="review_b">
                      <label>Identity Verification Type </label>

                      <p>{formData.idType === '1' ||
                        formData.idType === '' ||
                        formData.idType === 'undefined' ||
                        formData.idType === undefined ||
                        formData.idType === null
                        ? 'NZ Driver Licence'
                        : formData.idType === '2'
                          ? 'NZ Passport'
                          : formData.idType === '3' ? "Other" : null
                        // : formData.otherDocumentName
                      }
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="review_b">
                    <label>First Name</label>
                    <p>{formData.firstName} </p>
                  </div>
                  {formData.middleName !== null &&
                    formData.middleName !== '' &&
                    formData.middleName !== 'undefined' &&
                    formData.middleName !== undefined ? (
                    <div className="review_b">
                      <label>Middle Name</label>
                      <p>{formData.middleName} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.lastName !== null &&
                    formData.lastName !== '' &&
                    formData.lastName !== 'undefined' &&
                    formData.lastName !== undefined ? (
                    <div className="review_b">
                      <label>Last Name</label>
                      <p>{formData.lastName} </p>
                    </div>
                  ) : (
                    ''
                  )}</>}



              {formData.biometric === "Biometric" ? "" :
                <>
                  {(formData.idType === '' || formData.idType === '1') &&
                    formData.documentNumber !== null &&
                    formData.documentNumber !== '' &&
                    formData.documentNumber !== undefined &&
                    formData.documentNumber !== 'undefined' ? (
                    <div className="review_b">
                      <label>Licence Number</label>
                      <p>{formData.documentNumber} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.idType === '2' &&
                    formData.documentNumber !== null &&
                    formData.documentNumber !== '' &&
                    formData.documentNumber !== 'undefined' &&
                    formData.documentNumber !== undefined ? (
                    <div className="review_b">
                      <label>Passport Number</label>
                      <p>{formData.documentNumber} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.idType === '3' &&
                    formData.documentNumber !== null &&
                    formData.documentNumber !== '' &&
                    formData.documentNumber !== 'undefined' &&
                    formData.documentNumber !== undefined ? (
                    <div className="review_b">
                      <label>Document Number</label>
                      <p>{formData.documentNumber} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.idType === '3' &&
                    formData.otherDocumentName !== null &&
                    formData.otherDocumentName !== '' &&
                    formData.otherDocumentName !== 'undefined' &&
                    formData.otherDocumentName !== undefined ? (
                    <div className="review_b">
                      <label>Document Type</label>
                      <p>{formData.otherDocumentName} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {(formData.idType === '' || formData.idType === '1') &&
                    IndividualExpiryDate !== null &&
                    IndividualExpiryDate !== '' &&
                    IndividualExpiryDate !== 'undefined' &&
                    IndividualExpiryDate !== undefined ? (
                    <div className="review_b">
                      <label>Licence Expiry Date</label>
                      <p>{IndividualExpiryDate} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.idType === '2' &&
                    IndividualExpiryDate !== null &&
                    IndividualExpiryDate !== '' &&
                    IndividualExpiryDate !== 'undefined' &&
                    IndividualExpiryDate !== undefined ? (
                    <div className="review_b">
                      <label>Passport Expiry Date</label>
                      <p>{IndividualExpiryDate} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.idType === '3' &&
                    IndividualExpiryDate !== null &&
                    IndividualExpiryDate !== '' &&
                    IndividualExpiryDate !== 'undefined' &&
                    IndividualExpiryDate !== undefined ? (
                    <div className="review_b">
                      <label>Document Expiry Date</label>
                      <p>{IndividualExpiryDate} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.idType === '2' &&
                    formData.documentCountryOfIssue !== null &&
                    formData.documentCountryOfIssue !== '' &&
                    formData.documentCountryOfIssue !== 'undefined' &&
                    formData.documentCountryOfIssue !== undefined ? (
                    <div className="review_b">
                      <label>Country of Issue </label>
                      <p>{getName(formData.documentCountryOfIssue)} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.idType === '3' &&
                    formData.documentCountryOfIssue !== null &&
                    formData.documentCountryOfIssue !== '' &&
                    formData.documentCountryOfIssue !== 'undefined' &&
                    formData.documentCountryOfIssue !== undefined ? (
                    <div className="review_b">
                      <label>Country of Issue </label>

                      <p>{getName(formData.documentCountryOfIssue)} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {
                    ((formData.idType === '1' || formData.idType === '') &&
                      formData.documentVersionNumber !== null &&
                      formData.documentVersionNumber !== '' &&
                      formData.documentVersionNumber !== 'undefined' &&
                      formData.documentVersionNumber !== undefined) ? (
                      <div className="review_b">
                        <label>Licence Version Number</label>
                        <p>{formData.documentVersionNumber} </p>
                      </div>
                    ) : (
                      ''
                    )}</>}



              <hr />

              {(formData.primaryBankAccountName !== '' &&
                formData.primaryBankAccountName !== null &&
                formData.primaryBankAccountName !== undefined &&
                formData.primaryBankAccountName !== 'undefined') ||
                (formData.primaryBankAccountNumber !== '' &&
                  formData.primaryBankAccountNumber !== null &&
                  formData.primaryBankAccountNumber !== 'undefined' &&
                  formData.primaryBankAccountNumber !== undefined) ? (
                <>
                  {' '}
                  <h3>Bank Account Details:</h3>
                  {formData.primaryBankAccountName !== '' &&
                    formData.primaryBankAccountName !== null &&
                    formData.primaryBankAccountName !== undefined &&
                    formData.primaryBankAccountName !== 'undefined' ? (
                    <div className="review_b">
                      <label>Account Name</label>
                      <p>{formData.primaryBankAccountName} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.primaryBankAccountNumber !== '' &&
                    formData.primaryBankAccountNumber !== null &&
                    formData.primaryBankAccountNumber !== 'undefined' &&
                    formData.primaryBankAccountNumber !== undefined ? (
                    <div className="review_b">
                      <label>Account Number</label>
                      <p>{formData.primaryBankAccountNumber} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.bankSwiftCode !== '' &&
                    formData.bankSwiftCode !== null &&
                    formData.bankSwiftCode !== 'undefined' &&
                    formData.bankSwiftCode !== undefined ? (
                    <div className="review_b">
                      <label>Swift Code</label>
                      <p>{formData.bankSwiftCode} </p>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}


              <hr />

              {formData.isMinimumInvest == "Yes" ?
                <div className="review_bm">

                  <>
                    <h3>Wholesale Investor Certificate B</h3>
                    <div className="list">
                      <p>Confirm that I/We understand that—</p>
                      <div className="checkbox-my">
                        <label className="containers years set-check font-fami">{
                          formData.usualLegalRules == true ?
                            <b> The usual legal rules that require information to be given to
                              investors for offers of financial products do not apply if the amount
                              invested upfront by me (plus any other investments I/We have already
                              made in those financial products) is $750,000 or more; and</b> :
                            <p> The usual legal rules that require information to be given to
                              investors for offers of financial products do not apply if the amount
                              invested upfront by me (plus any other investments I/We have already
                              made in those financial products) is $750,000 or more; and</p>
                        }
                          <input
                            type="checkbox"
                            name="usualLegalRules"
                            checked={
                              formData.usualLegalRules == true ? true : false
                            }


                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.notCompleteInformation == true ?
                            <b>I/We may not receive a complete and balanced set of information about
                              this investment; and</b> :
                            <p>I/We may not receive a complete and balanced set of information about
                              this investment; and</p>
                        }
                          <input
                            type="checkbox"
                            className=""
                            name="notCompleteInformation"
                            checked={
                              formData.notCompleteInformation == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.fewerLegalProtection == true ?
                            <b>I/We have fewer legal protections for this investment; and</b> :
                            <p>I/We have fewer legal protections for this investment; and</p>
                        }
                          <input
                            type="checkbox"
                            name="fewerLegalProtection"
                            checked={
                              formData.fewerLegalProtection == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.notSuitableInvestment == true ?
                            <b> This investment is not suitable for retail investors; and</b> :
                            <p> This investment is not suitable for retail investors; and</p>
                        }
                          <input
                            type="checkbox"
                            name="notSuitableInvestment"
                            checked={
                              formData.notSuitableInvestment == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.seekFinancialAdvice == true ?
                            <b>I/We have been advised to ask questions, read all documents carefully,
                              and seek independent financial advice.</b> :
                            <p>I/We have been advised to ask questions, read all documents carefully,
                              and seek independent financial advice.</p>
                        }
                          <input
                            type="checkbox"
                            name="seekFinancialAdvice"
                            checked={
                              formData.seekFinancialAdvice == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span className="error Certificate2"></span>
                      </div>
                    </div>
                  </>
                </div>
                : null

              }



              <div className="cert">
                {
                  formData.isMinimumInvest == "Yes" ? null :

                    <>
                      <div className="review_bm">
                        <div className="mainfetch-div">
                          {
                            formData.largePersonValue === true || formData.twoYearInvestEntity2 === true || formData.largePersonNone == true ?
                              <>
                                {
                                  // Twelve Page Start................ 
                                  formData.investmentCriteriaNone == true && formData.investmentBusinessNone == true ? null :
                                    <>
                                      <h3>Wholesale Certificate A</h3>

                                      <label className='person'>Large persons</label>
                                      {/* <br /> */}
                                      <p>The Investor is ‘large’ on the basis that at least one of the following paragraphs applies immediately before this certificate is given.</p>

                                      <div className="checkbox-my">

                                        <label className="containers restContainer font-fami">{
                                          formData.largePersonValue === true ? <b>As at the last day of each of the Investor's two most recently completed financial
                                            years, the net assets of the Investor and the entities controlled by the Investor
                                            exceeded $5 million.</b> :
                                            <p>As at the last day of each of the Investor's two most recently completed financial
                                              years, the net assets of the Investor and the entities controlled by the Investor
                                              exceeded $5 million.</p>
                                        }

                                          <input
                                            type="checkbox"
                                            name="largePersonValue"
                                            checked={
                                              formData.largePersonValue === true ? true : false
                                            }
                                          />

                                          <span className="checkmark"></span>
                                        </label>
                                        <label className="containers restContainer font-fami">
                                          {
                                            formData.twoYearInvestEntity2 === true ? <b>In each of the Investor's two most recently completed financial years, the total
                                              consolidated turnover of the Investor and the entities controlled by the Investor
                                              exceeded $5 million.</b> :
                                              <p>In each of the Investor's two most recently completed financial years, the total
                                                consolidated turnover of the Investor and the entities controlled by the Investor
                                                exceeded $5 million.</p>
                                          }

                                          <input
                                            type="checkbox"
                                            name="twoYearInvestEntity2"
                                            checked={
                                              formData.twoYearInvestEntity2 === true ? true : false
                                            }
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>


                                      {/* thirteenPage start................. */}
                                      {
                                        // formData.largePersonValue == true || formData.twoYearInvestEntity2 == true ? null :
                                        <>
                                          <label className='person'>Investment Business</label>
                                          <p className='font-fami'>
                                            The Investor was not established or acquired with a view to using it as an entity to
                                            which offers of financial products may be made in reliance upon the exclusion in clause
                                            3(2)(a) and the Investor is an “investment business” on the basis that:
                                          </p>

                                          <div className="checkbox-my">
                                            <label className="containers restContainer font-fami">{
                                              formData.investorPrincipalBusiness == true ?
                                                <b>The Investor’s principal business consists of one or more of the following:</b> :
                                                <p>The Investor’s principal business consists of one or more of the following:</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="investorPrincipalBusiness"
                                                checked={
                                                  formData.investorPrincipalBusiness == true ? true : false
                                                }

                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>

                                          <div className="checkbox-my">
                                            <div className="">
                                              <label className="containers restContainer font-fami hh">{
                                                formData.investingFinancialProducts == true ?
                                                  <b> Investing in financial products</b> : <p>Investing in financial products</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="investingFinancialProducts"
                                                  checked={
                                                    formData.investingFinancialProducts == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.actingUnderwriter == true ?
                                                  <b>Acting as an underwriter</b> : <p>Acting as an underwriter</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="actingUnderwriter"
                                                  checked={
                                                    formData.actingUnderwriter == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.financialAdviceService == true ?
                                                  <b>Providing a financial advice service</b> : <p>Providing a financial advice service</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="financialAdviceService"
                                                  checked={
                                                    formData.financialAdviceService == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.clientMoneyPropertyService == true ?
                                                  <b>Providing a client money or property service</b> :
                                                  <p>Providing a client money or property service</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="clientMoneyPropertyService"
                                                  checked={
                                                    formData.clientMoneyPropertyService == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.tradingFinancialProducts == true ?
                                                  <b>Trading in financial products on behalf of other persons.</b> :
                                                  <p>Trading in financial products on behalf of other persons.</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="tradingFinancialProducts"
                                                  checked={
                                                    formData.tradingFinancialProducts == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>

                                              </label>
                                              <span className="error investmentBusiness1"></span>
                                            </div>
                                          </div>

                                          <div className="checkbox-my">
                                            <label className="containers restContainer font-fami">{
                                              formData.registeredBankRBNZ == true ?
                                                <b>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</b> :
                                                <p>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="registeredBankRBNZ"
                                                checked={
                                                  formData.registeredBankRBNZ == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.NBDTInvestor == true ?
                                                <b>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</b> :
                                                <p>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="NBDTInvestor"
                                                checked={
                                                  formData.NBDTInvestor == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.licensedIssuerInsurance == true ?
                                                <b>The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</b> :
                                                <p> The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="licensedIssuerInsurance"
                                                checked={
                                                  formData.licensedIssuerInsurance == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.registeredSchemeManager == true ?
                                                <b>The Investor is the manager of a registered scheme, or a discretionary investment
                                                  management service, that holds a market services licence under the Financial Markets
                                                  Conduct Act 2013.</b> :
                                                <p>The Investor is the manager of a registered scheme, or a discretionary investment
                                                  management service, that holds a market services licence under the Financial Markets
                                                  Conduct Act 2013.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="registeredSchemeManager"
                                                checked={
                                                  formData.registeredSchemeManager == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.derivativesIssuer == true ?
                                                <b>The Investor is a derivatives issuer that holds a market services license under the
                                                  Financial Markets Conduct Act 2013.</b> :
                                                <p>The Investor is a derivatives issuer that holds a market services license under the
                                                  Financial Markets Conduct Act 2013.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="derivativesIssuer"
                                                checked={
                                                  formData.derivativesIssuer == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.FinancialAdvisor == true ?
                                                <b>The Investor is a financial adviser.</b> :
                                                <p>The Investor is a financial adviser.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="FinancialAdvisor"
                                                checked={
                                                  formData.FinancialAdvisor == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <br />
                                          </div>
                                        </>
                                      }

                                      {/* Fifteen Page Start.................... */}
                                      {
                                        // (formData.largePersonValue == true || formData.twoYearInvestEntity2 == true)
                                        //   ? null : formData.investmentBusinessNone == true ?
                                        <>

                                          <label className='person'>Investment activity criteria</label>
                                          {/* <br /> */}

                                          <p>The Investor meets the ‘investment activity criteria’ on the basis that at least one of the following paragraphs apply.</p>
                                          <div className="checkbox-my">
                                            <label className="containers years set-check font-fami">{
                                              formData.ownedPortfolio2yrs === true ?
                                                <b>The investor owns, or at any time during the 2-year period immediately before this
                                                  certificate is given has owned, a portfolio of financial products (other than
                                                  membership of a retirement scheme or financial products issued by an associate) of a
                                                  value of at least $1 million (in aggregate).</b> :
                                                <p>The investor owns, or at any time during the 2-year period immediately before this
                                                  certificate is given has owned, a portfolio of financial products (other than
                                                  membership of a retirement scheme or financial products issued by an associate) of a
                                                  value of at least $1 million (in aggregate).</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="ownedPortfolio2yrs"
                                                checked={formData.ownedPortfolio2yrs === true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers years set-check font-fami">{
                                              formData.carriedTransactions2yrs === true ?
                                                <b>The investor has, during the 2-year period immediately before this certificate is
                                                  given, carried out one or more transaction(s) to acquire financial products (other
                                                  than membership of a retirement scheme or financial products issued by an associate)
                                                  where the amount payable under those transactions (in aggregate) is at least $1
                                                  million.</b> :
                                                <p>The investor has, during the 2-year period immediately before this certificate is
                                                  given, carried out one or more transaction(s) to acquire financial products (other
                                                  than membership of a retirement scheme or financial products issued by an associate)
                                                  where the amount payable under those transactions (in aggregate) is at least $1
                                                  million.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="carriedTransactions2yrs"
                                                checked={
                                                  formData.carriedTransactions2yrs === true ? true : false
                                                }

                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <label className="containers years set-check font-fami">{
                                              formData.investmentBusiness10yrs === true ?
                                                <b>The Investor is an individual who has, within the last ten years immediately before
                                                  this certificate is given, been employed or engaged in an investment business and has,
                                                  for at least two years during that ten-year period, participated to a material extent
                                                  in the investment decisions made by the investment business.</b> :
                                                <p>The Investor is an individual who has, within the last ten years immediately before
                                                  this certificate is given, been employed or engaged in an investment business and has,
                                                  for at least two years during that ten-year period, participated to a material extent
                                                  in the investment decisions made by the investment business.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                className=""
                                                name="investmentBusiness10yrs"
                                                checked={
                                                  formData.investmentBusiness10yrs === true ? true : false
                                                }

                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>

                                          {formData.ownedPortfolio2yrs === true || formData.carriedTransactions2yrs === true || formData.investmentBusiness10yrs === true ?
                                            <div className="fullspace pandingfinsh ">
                                              <label>Please define the specific financial products of at least $1mil. </label>
                                              <div className="checkbox-my left-more">
                                                <div>
                                                  <label className="containers font-fami">{
                                                    formData.equitySecurities === true ?
                                                      <b>Equity securities (includes listed shares)</b> : <p>Equity securities (includes listed shares)</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="equitySecurities"
                                                      checked={formData.equitySecurities === true ? true : false}
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <label className="containers font-fami">{
                                                    formData.managedFunds === true ? <b> Managed funds</b> : <p> Managed funds</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="managedFunds"
                                                      checked={formData.managedFunds === true ? true : false}

                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <label className="containers font-fami">{
                                                    formData.derivativeInstruments === true ? <b> Derivative instruments</b> : <p> Derivative instruments</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="derivativeInstruments"
                                                      checked={formData.derivativeInstruments === true ? true : false}
                                                    />

                                                    <span className="checkmark font-fami"></span>
                                                  </label>
                                                  <label className="containers">{
                                                    formData.debtSecurities === true ? <b>Debt securities</b> : <p>Debt securities</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="debtSecurities"
                                                      checked={formData.debtSecurities === true ? true : false}

                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            : null
                                          }

                                        </>
                                        // : null
                                      }
                                    </>
                                  //  : null
                                }
                              </>
                              : null
                          }


                          {
                            formData.investmentCriteriaNone == true ?
                              formData.investmentBusinessNone == false ? null :
                                <div className="checkbox-my">
                                  <h3>Wholesale Certificate B</h3>
                                  <label className="containers restContainer  font-fami">{
                                    formData.startupOrBusiness == true ?
                                      <b>I/We previously invested in early stage, start up, or established businesses.</b> :
                                      <p>I/We previously invested in early stage, start up, or established businesses.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="startupOrBusiness"
                                      checked={formData.startupOrBusiness == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>


                                  <label className="containers restContainer  font-fami">{
                                    formData.investorPresentation == true ?
                                      <b>In the course of it’s investment activities, I/We have been involved in investor
                                        presentations and/or due diligence meetings and through this have gained an
                                        appreciation for the risks and opportunities associated with investing in businesses.</b> :
                                      <p>In the course of it’s investment activities, I/We have been involved in investor
                                        presentations and/or due diligence meetings and through this have gained an
                                        appreciation for the risks and opportunities associated with investing in businesses.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="investorPresentation"
                                      checked={formData.investorPresentation == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label className="containers restContainer  font-fami">{
                                    formData.capitalRaisingDirector == true ?
                                      <b> I/We have served as a Director of a business that was involved in capital raising.</b> :
                                      <p> I/We have served as a Director of a business that was involved in capital raising.</p>
                                  }

                                    <input
                                      type="checkbox"
                                      name="capitalRaisingDirector"
                                      checked={formData.capitalRaisingDirector == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label className="containers restContainer  font-fami">{
                                    formData.investingSharesSecurities == true ?
                                      <b>I/We have experience in investing in shares and other securities (listed and unlisted)
                                        and understand the risks with investing in different types of financial products.</b> :
                                      <p>I/We have experience in investing in shares and other securities (listed and unlisted)
                                        and understand the risks with investing in different types of financial products.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="investingSharesSecurities"
                                      checked={formData.investingSharesSecurities == true ? true : false}

                                    />
                                    <span className="checkmark"></span>
                                  </label>


                                  <label className="containers restContainer  font-fami">{
                                    formData.myOwnInvestmentDecisions == true ?
                                      <b> I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                                        benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                                      </b> :
                                      <p> I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                                        benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                                      </p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="valueAndRisksOfInvesting"
                                      disabled={true}
                                      checked={formData.myOwnInvestmentDecisions == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>

                                  <label className="containers restContainer  font-fami">{
                                    formData.valueAndRisksOfInvesting == true ?
                                      <b>In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                                        this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                                      </b> :
                                      <p>In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                                        this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                                      </p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="valueAndRisksOfInvesting"
                                      disabled={true}
                                      checked={formData.valueAndRisksOfInvesting == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label className="containers restContainer  font-fami">{
                                    formData.acquiringDisposingFinancialProducts == true ?
                                      <b>I/We have other relevant experience of acquiring or disposing of financial products,
                                        details being the following.</b> :
                                      <p>I/We have other relevant experience of acquiring or disposing of financial products,
                                        details being the following.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      // name="valueAndRisksOfInvesting"
                                      disabled={true}
                                      checked={formData.acquiringDisposingFinancialProducts == true ? true : false}

                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <span className="error investmentProductExp"></span>
                                  {formData.acquiringDisposingFinancialProducts == true ?
                                    <div className="fullspace pandingfinsh Other">
                                      <textarea
                                        className="mouse otherInput"
                                        // name="acquiringDisposingFinancialProductsOther"
                                        disabled={true}
                                        value={formData.acquiringDisposingFinancialProductsOther}
                                        type="text"
                                        placeholder="Please provide details"

                                      ></textarea>
                                    </div> : null}
                                  {formData.rwt12 !== null &&
                                    formData.rwt12 !== '' &&
                                    formData.rwt12 !== 'undefined' &&
                                    formData.rwt12 !== undefined && formData.rwt12 !== "Select" ? (
                                    <div className="review_b">
                                      <label>Financial Adviser Type</label>
                                      <p>{formData.rwt12} </p>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {formData.fullLegalNamesign !== null &&
                                    formData.fullLegalNamesign !== '' &&
                                    formData.fullLegalNamesign !== 'undefined' &&
                                    formData.fullLegalNamesign !== undefined ? (
                                    <div className="review_b">
                                      <label>Financial Adviser Name</label>
                                      <p>{formData.fullLegalNamesign} </p>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {formData.userEmail1 !== null &&
                                    formData.userEmail1 !== '' &&
                                    formData.userEmail1 !== 'undefined' &&
                                    formData.userEmail1 !== undefined ? (
                                    <div className="review_b directoreml">
                                      <label>Email</label>
                                      <p>{formData.userEmail1} </p>
                                    </div>
                                  ) : (
                                    ''
                                  )}


                                </div>
                              : null
                          }


                        </div>
                      </div>
                    </>
                }
                <hr />
              </div>

            </div>

          ) : formData.investmentType === 'Joint' ? (
            <div className="Joint00">
              {/* <h1>Joint</h1> */}
              <h3>Investment Details:</h3>

              <div className="Investor-Certificate-joint">
                <div className="review_b">
                  <label>Entity Type</label>
                  <p>{formData.investmentType} </p>
                </div>
                {formData.fundCode !== null &&
                  formData.fundCode !== '' &&
                  formData.fundCode !== 'undefined' &&
                  formData.fundCode !== undefined ? (
                  <div className="review_b">
                    <label>Investment Opportunity</label>
                    <p>{formData.fundCode} </p>
                  </div>
                ) : (
                  ''
                )}
                {formData.totalInvestment !== null &&
                  formData.totalInvestment !== '' &&
                  formData.totalInvestment !== 'undefined' &&
                  formData.totalInvestment !== undefined ? (
                  <div className="review_b">
                    <label>Investment Amount</label>
                    <p>${formData.totalInvestment} </p>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="Investor-Certificate-joint">
                <h3>Personal Information: First Joint Holder</h3>

                <div className="review_b">
                  <label>Full Name</label>
                  <p className='textCapitalize'>{formData.fullName}</p>
                </div>

                {formData.preferredName !== null &&
                  formData.preferredName !== '' &&
                  formData.preferredName !== undefined &&
                  formData.preferredName !== 'undefined' ? (
                  <div className="review_b">
                    <label>Preferred Name</label>
                    <p className='textCapitalize'>{formData.preferredName}</p>
                  </div>
                ) : (
                  ''
                )}
                {formData.userEmail !== null &&
                  formData.userEmail !== '' &&
                  formData.userEmail !== 'undefined' &&
                  formData.userEmail !== undefined ? (
                  <div className="review_b">
                    <label>Email</label>
                    <p className="JointHoldersEmail">{formData.userEmail}</p>
                  </div>
                ) : (
                  ''
                )}



                {formData.dateOfBirth !== null &&
                  formData.dateOfBirth !== '' &&
                  formData.dateOfBirth !== 'undefined' &&
                  formData.dateOfBirth !== undefined ? (
                  <div className="review_b">
                    <label>Date of Birth</label>
                    <p>{formData.dateOfBirth} </p>
                  </div>
                ) : (
                  ''
                )}

                {((formData.isPrimaryResdAddress !== null &&
                  formData.isPrimaryResdAddress !== '' &&
                  formData.isPrimaryResdAddress !== 'undefined' &&
                  formData.isPrimaryResdAddress !== undefined)) ? (
                  <div className="review_b">
                    <label>Residential Address </label>
                    <p>{formData.isPrimaryResdAddress != null &&
                      formData.isPrimaryResdAddress != '' &&
                      formData.isPrimaryResdAddress != 'undefined' && formData.manuallyAddress != 'false'
                      ? formData.isPrimaryResdAddress
                      :
                      formData.manuallyAddress == 'false' ?


                        formData.street_number +
                        (formData.region != null && formData.region != '' && formData.region != undefined ? ', ' + formData.region : '') +
                        (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
                        (formData.postalCode != null && formData.postalCode != ''
                          ? ', ' + formData.postalCode
                          : '') +
                        (formData.country != null && formData.country != '' ? ', ' + formData.country : '')
                        : null} </p>
                  </div>
                ) : (
                  ''
                )}



                {
                  (formData.isPrimaryPostalAdress !== null &&
                    formData.isPrimaryPostalAdress !== '' &&
                    formData.isPrimaryPostalAdress !== 'undefined' &&
                    formData.isPrimaryPostalAdress !== undefined) || (formData.isPrimaryResdAddress !== null &&
                      formData.isPrimaryResdAddress !== '' &&
                      formData.isPrimaryResdAddress !== 'undefined' &&
                      formData.isPrimaryResdAddress !== undefined) ? (
                    <div className="review_b">
                      <label> {formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal Address" : (formData.isPrimaryPostalAdress !== null &&
                        formData.isPrimaryPostalAdress !== '' &&
                        formData.isPrimaryPostalAdress !== 'undefined' &&
                        formData.isPrimaryPostalAdress !== undefined) ?
                        "Postal Address" : null} </label>
                      <p className='post'>{formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal address is the same as home address" : formData.isPrimaryPostalAdress} </p>
                    </div>
                  ) : (
                    ''
                  )}

                {/* {formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === "false" ? (
                  <div className="review_b">
                    <label>Postal Address</label>
                    <p>{formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" ? "postal address is same as home address" : formData.userPostalAddress} </p>
                  </div>
                ) : (
                  ''
                )} */}

                {
                  formData.priContactType !== '' &&
                    formData.priContactType !== undefined &&
                    formData.priContactType !== 'undefined' ? (
                    <div className="review_b">
                      <label> Primary Contact Type</label>
                      <p>{formData.priContactType !== null ? formData.priContactType : "Mobile"} </p>
                    </div>
                  ) : (
                    ''
                  )}
                {formData.priContactNumber !== null &&
                  formData.priContactNumber !== '' &&
                  formData.priContactNumber !== 'undefined' &&
                  formData.priContactNumber !== undefined ? (
                  <div className="review_b">
                    <label>Primary Mobile Number</label>
                    <p>{"+"}{formData.priContactCountryCode != null && formData.priContactCountryCode !== "" ? formData.priContactCountryCode : "64"}{" "}{formData.priContactNumber} </p>
                  </div>
                ) : (
                  ''
                )}

                {
                  (formData.secContactNumber !== '' && formData.secContactNumber !== null &&
                    formData.secContactNumber !== undefined &&
                    formData.secContactNumber !== 'undefined' ? (
                    <div className="review_b">
                      <label> Secondary Contact Type</label>
                      <p>{formData.secContactType === null || formData.secContactType === ""
                        || formData.secContactType === "undefined" || formData.secContactType === undefined ? "Home" : formData.secContactType} </p>
                    </div>
                  ) : (
                    ''
                  ))}



                {
                  (formData.secContactNumber !== null &&
                    formData.secContactNumber !== '' &&
                    formData.secContactNumber !== 'undefined' &&
                    formData.secContactNumber !== undefined ? (
                    <div className="review_b">
                      <label>Secondary Mobile Number</label>
                      <p>{"+"}{formData.secContactCountryCode != null ? formData.secContactCountryCode : "64"}{" "}{formData.secContactNumber} </p>
                    </div>
                  ) : (
                    ''
                  ))}
                {formData.occupation !== null &&
                  formData.occupation !== '' &&
                  formData.occupation !== 'undefined' &&
                  formData.occupation !== undefined ? (
                  <div className="review_b">
                    <label>Occupation </label>
                    <p>{formData.occupation === "Other" ? formData.otherOccupation : formData.occupation} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.placeOfBirth !== null &&
                  formData.placeOfBirth !== '' &&
                  formData.placeOfBirth !== 'undefined' &&
                  formData.placeOfBirth !== undefined ? (
                  <div className="review_b">
                    <label>Place of Birth </label>
                    <p>{formData.placeOfBirth} </p>
                  </div>
                ) : (
                  ''
                )}
                {formData.countryOfBirth !== null &&
                  formData.countryOfBirth !== '' &&
                  formData.countryOfBirth !== 'undefined' &&
                  formData.countryOfBirth !== undefined ? (
                  <div className="review_b">
                    <label>Country of Birth</label>

                    <p>{getName(formData.countryOfBirth)} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.residingCountry !== null &&
                  formData.residingCountry !== '' &&
                  formData.residingCountry !== 'undefined' &&
                  formData.residingCountry !== undefined ? (
                  <div className="review_b">
                    <label>Country of Residence</label>

                    <p>{getName(formData.residingCountry)} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.userIrdNumber !== null &&
                  formData.userIrdNumber !== '' &&
                  formData.userIrdNumber !== 'undefined' &&
                  formData.userIrdNumber !== undefined ? (
                  <div className="review_b">
                    <label>IRD Number</label>
                    <p>{formData.userIrdNumber} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.rwt !== null &&
                  formData.rwt !== '' &&
                  formData.rwt !== 'undefined' &&
                  formData.rwt !== undefined ? (
                  <div className="review_b">
                    <label> Tax Type</label>
                    <p>{formData.rwt} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.checkboxx !== null &&
                  formData.checkboxx !== '' &&
                  formData.checkboxx !== 'undefined' &&
                  formData.checkboxx === true && formData.rwt === "Non Resident Withholding Tax" ? (
                  <div className="review_b">
                    <label>Resident Withholding Tax Exemption</label>
                    <p>{formData.checkboxx === true ? "Yes" : "No"}</p>
                  </div>
                ) : (
                  ''
                )}

                {formData.rwtValue !== null &&
                  formData.rwtValue !== '' &&
                  formData.rwtValue !== 'undefined' &&
                  formData.rwtValue !== undefined && formData.rwt === "Resident Withholding Tax" ? (
                  <div className="review_b">
                    <label> Resident Withholding Tax Rate</label>
                    <p>{formData.rwtValue} </p>
                  </div>
                ) : (
                  ''
                )}



                {formData.tinResidential !== null &&
                  formData.tinResidential !== '' &&
                  formData.tinResidential !== 'undefined' &&
                  formData.tinResidential !== undefined ? (
                  <div className="review_b">
                    <label>Country of tax residence</label>

                    <p>{getName(formData.tinResidential)} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.tinNumber !== null &&
                  formData.tinNumber !== '' &&
                  formData.tinNumber !== 'undefined' &&
                  formData.tinNumber !== undefined ? (
                  <div className="review_b">
                    <label>Tax Identification Number (TIN)</label>
                    <p>{formData.tinNumber} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.tinreason !== null &&
                  formData.tinreason !== '' &&
                  formData.tinreason !== 'undefined' &&
                  formData.tinreason !== undefined ? (
                  <div className="review_b">
                    <label>Reason if TIN is not available</label>
                    <p>{formData.tinreason} </p>
                  </div>
                ) : (
                  ''
                )}

                <hr />
              </div>

              <div className="Investor-Certificate-joint">
                <h3>Personal Information: Second Joint Holder</h3>
                {formData.secondholder !== null &&
                  formData.secondholder !== '' &&
                  formData.secondholder !== 'undefined' &&
                  formData.secondholder !== undefined ? (
                  <div className="review_b">
                    <label>Full Name</label>
                    <p className='textCapitalize'> {formData.secondholder}</p>
                  </div>
                ) : (
                  ''
                )}
                {formData.preferredName2 !== null &&
                  formData.preferredName2 !== '' &&
                  formData.preferredName2 !== undefined &&
                  formData.preferredName2 !== 'undefined' ? (
                  <div className="review_b">
                    <label>Preferred Name</label>
                    <p className='textCapitalize'>{formData.preferredName2}</p>
                  </div>
                ) : (
                  ''
                )}

                {formData.email1 !== null &&
                  formData.email1 !== '' &&
                  formData.email1 !== 'undefined' &&
                  formData.email1 !== undefined ? (
                  <div className="review_b">
                    <label>Email</label>
                    <p className="JointHoldersEmail">{formData.email1}</p>
                  </div>
                ) : (
                  ''
                )}

                {/* {formData.preferredName2 !== null &&
                  formData.preferredName2 !== '' &&
                  formData.preferredName2 !== undefined &&
                  formData.preferredName2 !== 'undefined' ? (
                  <div className="review_b">
                    <label>Preferred Name</label>
                    <p>{formData.preferredName2} </p>
                  </div>
                ) : (
                  ''
                )} */}

                {formData.dateOfBirth2 !== null &&
                  formData.dateOfBirth2 !== '' &&
                  formData.dateOfBirth2 !== 'undefined' &&
                  formData.dateOfBirth2 !== undefined ? (
                  <div className="review_b">
                    <label>Date of Birth</label>
                    <p>{formData.dateOfBirth2} </p>
                  </div>
                ) : (
                  ''
                )}


                {((formData.isSecondryResdAddress !== null &&
                  formData.isSecondryResdAddress !== '' &&
                  formData.isSecondryResdAddress !== 'undefined' &&
                  formData.isSecondryResdAddress !== undefined)) ? (
                  <div className="review_b">
                    <label>Residential Address</label>
                    <p>{
                      formData.isSecondryResdAddress != null &&
                        formData.isSecondryResdAddress != 'undefined' &&
                        formData.isSecondryResdAddress != '' && formData.manuallyAddress2 != "false"

                        ? formData.isSecondryResdAddress : formData.manuallyAddress2 == "false" ?

                          formData.street_number3 +
                          (formData.region3 != '' && formData.region3 != null && formData.region3 != undefined ? ', ' + formData.region3 : '') +
                          (formData.city3 != '' ? ', ' + formData.city3 : '') +
                          (formData.postalCode3 != null ? ', ' + formData.postalCode3 : '') +
                          (formData.country3 != '' ? ', ' + formData.country3 : '')
                          : null} </p>
                  </div>
                ) : (
                  ''
                )}

                {/* {( ( formData.isSecondryPostalAdre != null &&  formData.isSecondryPostalAdre != "" &&  formData.isSecondryPostalAdre != "undefined" &&  formData.isSecondryPostalAdre != undefined)) 

        ? (
                    <div className="review_b">
                      { formData.isSecondryPostalAdre == "" ? null : <label>Postal Address</label>}
                      <p className='post'>{formData.isUserAddressSame2 === "true" || formData.isUserAddressSame2 === "undefined" || formData.isUserAddressSame2 == "" || formData.isUserAddressSame2 == undefined || formData.isUserAddressSame2 == null ? "Postal address is the same as home address" :  formData.isSecondryPostalAdre} </p>
                    </div>
                  ) : (
                    ''
                  )} */}

                {
                  (formData.isSecondryPostalAdre !== null &&
                    formData.isSecondryPostalAdre !== '' &&
                    formData.isSecondryPostalAdre !== 'undefined' &&
                    formData.isSecondryPostalAdre !== undefined) || (formData.isPrimaryResdAddress !== null &&
                      formData.isSecondryResdAddress !== '' &&
                      formData.isSecondryResdAddress !== 'undefined' &&
                      formData.isSecondryResdAddress !== undefined) ? (
                    <div className="review_b">
                      <label> {formData.isUserAddressSame2 === "true" || formData.isUserAddressSame2 === "undefined" || formData.isUserAddressSame2 === undefined ? "Postal Address" : (formData.isSecondryPostalAdre !== null &&
                        formData.isSecondryPostalAdre !== '' &&
                        formData.isSecondryPostalAdre !== 'undefined' &&
                        formData.isSecondryPostalAdre !== undefined) ?
                        "Postal Address" : null} </label>
                      <p className='post'>{formData.isUserAddressSame2 === "true" || formData.isUserAddressSame2 === "undefined" || formData.isUserAddressSame2 === undefined ? "Postal address is the same as home address" : formData.isSecondryPostalAdre} </p>
                    </div>
                  ) : (
                    ''
                  )}


                {formData.priContactType2 !== null &&
                  formData.priContactType2 !== '' ? (
                  <div className="review_b">
                    <label> Primary Contact Type</label>
                    <p>{formData.priContactType2 !== null && formData.priContactType2 !== ""
                      && formData.priContactType2 !== "undefined" && formData.priContactType2 !== undefined ? formData.priContactType2 : "Mobile"} </p>
                  </div>
                ) : (
                  ''
                )}
                {formData.priContactNumber2 !== null &&
                  formData.priContactNumber2 !== '' &&
                  formData.priContactNumber2 !== 'undefined' &&
                  formData.priContactNumber2 !== undefined ? (
                  <div className="review_b">
                    <label>Primary Mobile Number</label>
                    <p>{"+"}{formData.priContactCountryCode2 != null && formData.priContactCountryCode2 !== "" ? formData.priContactCountryCode2 : "64"}{" "}{formData.priContactNumber2} </p>
                  </div>
                ) : (
                  ''
                )}

                {/* {formData.isAlternateContact1== true|| formData.isAlternateContact1 == "undefined" || formData.isAlternateContact1 == null?"":(  formData.secContactType2 !== null &&
                  formData.secContactType2 !== ''   ? (
                  <div className="review_b">
                    <label> Secondary Contact Type</label>
                    <p>{formData.secContactType2 != null?formData.secContactType2:"Home"} </p>
                  </div>
                ) : (
                  ''
                ))} */}
                {(formData.secContactNumber2 !== null &&
                  formData.secContactNumber2 !== '' &&
                  formData.secContactNumber2 !== 'undefined' &&
                  formData.secContactNumber2 !== undefined ? (
                  <div className="review_b">
                    <label> Secondary Contact Type</label>
                    <p>{formData.secContactType2 != null && formData.secContactType2 != ""
                      && formData.secContactType2 != "undefined" && formData.secContactType2 != undefined ? formData.secContactType2 : "Home"} </p>
                  </div>
                ) : (
                  ''
                ))
                }



                {(formData.secContactNumber2 !== null &&
                  formData.secContactNumber2 !== '' &&
                  formData.secContactNumber2 !== 'undefined' &&
                  formData.secContactNumber2 !== undefined ? (
                  <div className="review_b">
                    <label>Secondary Mobile Number</label>
                    <p>{"+"}{formData.secContactCountryCode2 != null && formData.secContactCountryCode2 != "" ? formData.secContactCountryCode2 : "64"}{" "}{formData.secContactNumber2} </p>
                  </div>
                ) : (
                  ''
                ))
                }

                {formData.occupation2 !== null &&
                  formData.occupation2 !== '' &&
                  formData.occupation2 !== 'undefined' &&
                  formData.occupation2 !== undefined ? (
                  <div className="review_b">
                    <label>Occupation </label>
                    <p>{formData.occupation2 === "Other" ? formData.otherOccupation2 : formData.occupation2} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.placeOfBirth2 !== null &&
                  formData.placeOfBirth2 !== '' &&
                  formData.placeOfBirth2 !== 'undefined' &&
                  formData.placeOfBirth2 !== undefined ? (
                  <div className="review_b">
                    <label>Place of Birth </label>
                    <p>{formData.placeOfBirth2} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.countryOfBirth2 !== null &&
                  formData.countryOfBirth2 !== '' &&
                  formData.countryOfBirth2 !== 'undefined' &&
                  formData.countryOfBirth2 !== undefined ? (
                  <div className="review_b">
                    <label>Country of Birth</label>

                    <p>{getName(formData.countryOfBirth2)} </p>
                  </div>
                ) : (
                  ''
                )}
                {formData.residingCountry2 !== null &&
                  formData.residingCountry2 !== '' &&
                  formData.residingCountry2 !== 'undefined' &&
                  formData.residingCountry2 !== undefined ? (
                  <div className="review_b">
                    <label>Country of Residence</label>

                    <p>{getName(formData.residingCountry2)} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.userIrdNumber2 !== null &&
                  formData.userIrdNumber2 !== '' &&
                  formData.userIrdNumber2 !== 'undefined' &&
                  formData.userIrdNumber2 !== undefined ? (
                  <div className="review_b">
                    <label>IRD Number</label>
                    <p>{formData.userIrdNumber2} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.rwt2 !== null &&
                  formData.rwt2 !== '' &&
                  formData.rwt2 !== 'undefined' &&
                  formData.rwt2 !== undefined ? (
                  <div className="review_b">
                    <label> Tax Type</label>
                    <p>{formData.rwt2} </p>

                  </div>
                ) : (
                  ''
                )}

                {formData.checkboxxjoint2 !== null &&
                  formData.checkboxxjoint2 !== '' &&
                  formData.checkboxxjoint2 !== 'undefined' &&
                  formData.checkboxxjoint2 === true && formData.rwt2 === "Non Resident Withholding Tax" ? (
                  <div className="review_b">
                    <label>Resident Withholding Tax Exemption</label>
                    <p>{formData.checkboxxjoint2 === true ? "Yes" : "No"}</p>
                  </div>
                ) : (
                  ''
                )}

                {formData.rwtValue2 !== null &&
                  formData.rwtValue2 !== '' &&
                  formData.rwtValue2 !== 'undefined' &&
                  formData.rwtValue2 !== undefined && formData.rwt2 === "Resident Withholding Tax" ? (
                  <div className="review_b">
                    <label> Resident Withholding Tax Rate</label>
                    <p>{formData.rwtValue2} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.tinResidential2 !== null &&
                  formData.tinResidential2 !== '' &&
                  formData.tinResidential2 !== 'undefined' &&
                  formData.tinResidential2 !== undefined ? (
                  <div className="review_b">
                    <label>Country of tax residence</label>

                    <p>{getName(formData.tinResidential2)} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.tinNumber2 !== null &&
                  formData.tinNumber2 !== '' &&
                  formData.tinNumber2 !== 'undefined' &&
                  formData.tinNumber2 !== undefined ? (
                  <div className="review_b">
                    <label>Tax Identification Number (TIN)</label>
                    <p>{formData.tinNumber2} </p>
                  </div>
                ) : (
                  ''
                )}

                {formData.tinReason2 !== null &&
                  formData.tinReason2 !== '' &&
                  formData.tinReason2 !== 'undefined' &&
                  formData.tinReason2 !== undefined ? (
                  <div className="review_b">
                    <label>Reason if TIN is not available</label>
                    <p>{formData.tinReason2} </p>
                  </div>
                ) : (
                  ''
                )}

                <hr />
              </div>

              <div className="Identity-joint">
                {formData.biometric === "Biometric" ? "" : <>
                  <h3>Identity Verification: First Joint Holder</h3>

                  {(formData.idType === '' || formData.idType === '1' || formData.idType === '2' || formData.idType === '3') ? (
                    <div className="review_b">
                      <label>Identity Verification Type </label>
                      <p>{formData.idType === '1' ||
                        formData.idType === '' ||
                        formData.idType === 'undefined' ||
                        formData.idType === undefined ||
                        formData.idType === null
                        ? 'NZ Driver Licence'
                        : formData.idType === '2'
                          ? 'NZ Passport'
                          : formData.idType === '3' ? "Other" : null
                        //  formData.otherDocumentName
                      }</p>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="review_b">
                    <label>First Name</label>
                    <p>{formData.firstName} </p>
                  </div>
                  {formData.middleName !== null &&
                    formData.middleName !== '' &&
                    formData.middleName !== 'undefined' &&
                    formData.middleName !== undefined ? (
                    <div className="review_b">
                      <label>Middle Name</label>
                      <p>{formData.middleName} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.lastName !== null &&
                    formData.lastName !== '' &&
                    formData.lastName !== 'undefined' &&
                    formData.lastName !== undefined ? (
                    <div className="review_b">
                      <label>Last Name</label>
                      <p>{formData.lastName} </p>
                    </div>
                  ) : (
                    ''
                  )}
                </>}


                {formData.biometric === "Biometric" ? "" :
                  <>
                    {(formData.idType === '' || formData.idType === '1') &&
                      formData.documentNumber !== null &&
                      formData.documentNumber !== '' &&
                      formData.documentNumber !== undefined &&
                      formData.documentNumber !== 'undefined' ? (
                      <div className="review_b">
                        <label>Licence Number</label>
                        <p>{formData.documentNumber} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType === '2' &&
                      formData.documentNumber !== null &&
                      formData.documentNumber !== '' &&
                      formData.documentNumber !== 'undefined' &&
                      formData.documentNumber !== undefined ? (
                      <div className="review_b">
                        <label>Passport Number</label>
                        <p>{formData.documentNumber} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType === '3' &&
                      formData.documentNumber !== null &&
                      formData.documentNumber !== '' &&
                      formData.documentNumber !== 'undefined' &&
                      formData.documentNumber !== undefined ? (
                      <div className="review_b">
                        <label>Document Number</label>
                        <p>{formData.documentNumber} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.idType === '3' &&
                      formData.otherDocumentName !== null &&
                      formData.otherDocumentName !== '' &&
                      formData.otherDocumentName !== 'undefined' &&
                      formData.otherDocumentName !== undefined ? (
                      <div className="review_b">
                        <label>Document Type</label>
                        <p>{formData.otherDocumentName} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType === '' ||
                      (formData.idType === '1' &&
                        IndividualExpiryDate !== null &&
                        IndividualExpiryDate !== '' &&
                        IndividualExpiryDate !== 'undefined' &&
                        IndividualExpiryDate !== undefined) ? (
                      <div className="review_b">
                        <label>Licence Expiry Date</label>
                        <p>{IndividualExpiryDate} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType === '2' &&
                      IndividualExpiryDate !== null &&
                      IndividualExpiryDate !== '' &&
                      IndividualExpiryDate !== 'undefined' &&
                      IndividualExpiryDate !== undefined ? (
                      <div className="review_b">
                        <label>Passport Expiry Date</label>
                        <p>{IndividualExpiryDate} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.idType === '3' &&
                      IndividualExpiryDate !== null &&
                      IndividualExpiryDate !== '' &&
                      IndividualExpiryDate !== 'undefined' &&
                      IndividualExpiryDate !== undefined ? (
                      <div className="review_b">
                        <label>Document Expiry Date</label>
                        <p>{IndividualExpiryDate} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType === '2' &&
                      formData.documentCountryOfIssue !== null &&
                      formData.documentCountryOfIssue !== '' &&
                      formData.documentCountryOfIssue !== 'undefined' &&
                      formData.documentCountryOfIssue !== undefined ? (
                      <div className="review_b">
                        <label>Country of Issue </label>

                        <p>{getName(formData.documentCountryOfIssue)} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.idType === '3' &&
                      formData.documentCountryOfIssue !== null &&
                      formData.documentCountryOfIssue !== '' &&
                      formData.documentCountryOfIssue !== 'undefined' &&
                      formData.documentCountryOfIssue !== undefined ? (
                      <div className="review_b">
                        <label>Country of Issue </label>

                        <p>{getName(formData.documentCountryOfIssue)} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {
                      ((formData.idType === '1' || formData.idType === '') &&
                        formData.documentVersionNumber !== null &&
                        formData.documentVersionNumber !== '' &&
                        formData.documentVersionNumber !== 'undefined' &&
                        formData.documentVersionNumber !== undefined) ? (
                        <div className="review_b">
                          <label>Licence Version Number</label>
                          <p>{formData.documentVersionNumber} </p>
                        </div>
                      ) : (
                        ''
                      )}

                  </>}


                <hr />
              </div>

              <div className="Identity-joint ">
                {formData.biometric2 === "Biometric" ? "" :
                  <>
                    <h3>Identity Verification: Second Joint Holder</h3>

                    <div className="review_b">
                      <label>Identity Verification Type </label>
                      <p>{formData.idType2 === '1' ||
                        formData.idType2 === '' ||
                        formData.idType2 === 'undefined' ||
                        formData.idType2 === undefined ||
                        formData.idType2 === null
                        ? 'NZ Driver Licence'
                        : formData.idType2 === '2'
                          ? 'NZ Passport'
                          : formData.idType2 === '3' ? "Other" : null
                        // formData.otherDocumentName2
                      }</p>
                    </div>
                    {formData.firstName1 !== null &&
                      formData.firstName1 !== '' &&
                      formData.firstName1 !== 'undefined' &&
                      formData.firstName1 !== undefined ? (
                      <div className="review_b">
                        <label>First Name</label>
                        <p>{formData.firstName1} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.middleName1 != null && formData.middleName1 != "" && formData.middleName1 != "undefined" && formData.middleName1 != undefined ? (
                      <div className="review_b">
                        <label>Middle Name</label>
                        <p>{formData.middleName1} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.lastName1 !== null &&
                      formData.lastName1 !== '' &&
                      formData.lastName1 !== 'undefined' &&
                      formData.lastName1 !== undefined ? (
                      <div className="review_b">
                        <label>Last Name</label>
                        <p>{formData.lastName1} </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </>}


                {formData.biometric2 === "Biometric" ? "" :
                  <>
                    {(formData.idType2 === '' || formData.idType2 === '1') &&
                      formData.documentNumber2 !== null &&
                      formData.documentNumber2 !== '' &&
                      formData.documentNumber2 !== undefined &&
                      formData.documentNumber2 !== 'undefined' ? (
                      <div className="review_b">
                        <label>Licence Number</label>
                        <p>{formData.documentNumber2} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType2 === '2' &&
                      formData.documentNumber2 !== null &&
                      formData.documentNumber2 !== '' &&
                      formData.documentNumber2 !== undefined &&
                      formData.documentNumber2 !== 'undefined' ? (
                      <div className="review_b">
                        <label>Passport Number</label>
                        <p>{formData.documentNumber2} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType2 === '3' &&
                      formData.documentNumber2 !== null &&
                      formData.documentNumber2 !== '' &&
                      formData.documentNumber2 !== undefined &&
                      formData.documentNumber2 !== 'undefined' ? (
                      <div className="review_b">
                        <label>Document Number</label>
                        <p>{formData.documentNumber2} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.idType2 === '3' &&
                      formData.otherDocumentName2 !== null &&
                      formData.otherDocumentName2 !== '' &&
                      formData.otherDocumentName2 !== 'undefined' &&
                      formData.otherDocumentName2 !== undefined ? (
                      <div className="review_b">
                        <label>Document Type</label>
                        <p>{formData.otherDocumentName2} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {(formData.idType2 === '' || formData.idType2 === '1')
                      &&
                      JointExpiryDate !== null &&
                      JointExpiryDate !== '' &&
                      JointExpiryDate !== undefined &&
                      JointExpiryDate !== 'undefined' ? (
                      <div className="review_b">
                        <label>Licence Expiry Date</label>
                        <p>{JointExpiryDate} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType2 === '2' &&
                      JointExpiryDate !== null &&
                      JointExpiryDate !== '' &&
                      JointExpiryDate !== undefined &&
                      JointExpiryDate !== 'undefined' ? (
                      <div className="review_b">
                        <label>Passport Expiry Date</label>
                        <p>{JointExpiryDate} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.idType2 === '3' &&
                      JointExpiryDate !== null &&
                      JointExpiryDate !== '' &&
                      JointExpiryDate !== undefined &&
                      JointExpiryDate !== 'undefined' ? (
                      <div className="review_b">
                        <label>Document Expiry Date</label>
                        <p>{JointExpiryDate} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.idType2 === '2'
                      &&
                      formData.documentCountryOfIssue2 !== null &&
                      formData.documentCountryOfIssue2 !== '' &&
                      formData.documentCountryOfIssue2 !== undefined &&
                      formData.documentCountryOfIssue2 !== 'undefined' ? (
                      <div className="review_b">
                        <label>Country of Issue </label>

                        <p>{getName(formData.documentCountryOfIssue2)} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.idType2 === '3' &&
                      formData.documentCountryOfIssue2 !== null &&
                      formData.documentCountryOfIssue2 !== '' &&
                      formData.documentCountryOfIssue2 !== 'undefined' &&
                      formData.documentCountryOfIssue2 !== undefined ? (
                      <div className="review_b">
                        <label>Country of Issue </label>
                        <p>{getName(formData.documentCountryOfIssue2)} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {(formData.idType2 === '' || formData.idType2 === '1')
                      &&
                      formData.documentVersionNumber2 !== null &&
                      formData.documentVersionNumber2 !== '' &&
                      formData.documentVersionNumber2 !== 'undefined' &&
                      formData.documentVersionNumber2 !== undefined ? (
                      <div className="review_b">
                        <label>Licence Version Number</label>
                        <p>{formData.documentVersionNumber2} </p>
                      </div>
                    ) : (
                      ''
                    )}

                  </>}



                <hr />
              </div>

              <div className="BankAccount-Detail">
                {(formData.primaryBankAccountName !== '' &&
                  formData.primaryBankAccountName !== null &&
                  formData.primaryBankAccountName !== undefined &&
                  formData.primaryBankAccountName !== 'undefined') ||
                  (formData.primaryBankAccountNumber !== '' &&
                    formData.primaryBankAccountNumber !== null &&
                    formData.primaryBankAccountNumber !== 'undefined' &&
                    formData.primaryBankAccountNumber !== undefined) ? (
                  <>
                    {' '}
                    {formData.isNZBank12 === 'false' ?
                      <h3>Bank Account Details: First Joint Holder</h3> : <h3>Bank Account Details:</h3>}
                    {formData.primaryBankAccountName !== '' &&
                      formData.primaryBankAccountName !== null &&
                      formData.primaryBankAccountName !== undefined &&
                      formData.primaryBankAccountName !== 'undefined' ? (
                      <div className="review_b">
                        <label>Account Name</label>
                        <p>{formData.primaryBankAccountName} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.primaryBankAccountNumber !== '' &&
                      formData.primaryBankAccountNumber !== null &&
                      formData.primaryBankAccountNumber !== 'undefined' &&
                      formData.primaryBankAccountNumber !== undefined ? (
                      <div className="review_b">
                        <label>Account Number</label>
                        <p>{formData.primaryBankAccountNumber} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.bankSwiftCode !== '' &&
                      formData.bankSwiftCode !== null &&
                      formData.bankSwiftCode !== 'undefined' &&
                      formData.bankSwiftCode !== undefined ? (
                      <div className="review_b">
                        <label>Swift Code</label>
                        <p>{formData.bankSwiftCode} </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}

                <hr />
              </div>
              <div className="BankAccount-Detail">
                {(formData.primaryBankAccountName2 !== '' &&
                  formData.primaryBankAccountName2 !== null && formData.isNZBank12 !== 'true' &&
                  formData.primaryBankAccountName2 !== 'undefined' &&
                  formData.primaryBankAccountName2 !== undefined) ||
                  (formData.primaryBankAccountNumber2 !== '' &&
                    formData.primaryBankAccountNumber2 !== null &&
                    formData.primaryBankAccountNumber2 !== 'undefined' &&
                    formData.primaryBankAccountNumber2 !== undefined) ? (
                  <>
                    {' '}
                    <h3>Bank Account Details: Second Joint Holder</h3>
                    {formData.primaryBankAccountName2 !== '' &&
                      formData.primaryBankAccountName2 !== null &&
                      formData.primaryBankAccountName2 !== undefined && formData.isNZBank12 !== 'true' &&
                      formData.primaryBankAccountName2 !== 'undefined' ? (
                      <div className="review_b">
                        <label>Account Name</label>
                        <p>{formData.primaryBankAccountName2} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.primaryBankAccountNumber2 !== '' && formData.isNZBank12 !== 'true' &&
                      formData.primaryBankAccountNumber2 !== null &&
                      formData.primaryBankAccountNumber2 !== 'undefined' &&
                      formData.primaryBankAccountNumber2 !== undefined ? (
                      <div className="review_b">
                        <label>Account Number</label>
                        <p>{formData.primaryBankAccountNumber2} </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {formData.bankSwiftCode2 !== '' &&
                      formData.bankSwiftCode2 !== null && formData.isNZBank12 !== 'true' &&
                      formData.bankSwiftCode2 !== 'undefined' &&
                      formData.bankSwiftCode2 !== undefined ? (
                      <div className="review_b">
                        <label>Swift Code</label>
                        <p>{formData.bankSwiftCode2} </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}

                <hr />
              </div>

              {formData.isMinimumInvest == "Yes" ?
                <div className="review_bm">

                  <>
                    <h3>Wholesale Investor Certificate B</h3>
                    <div className="list">
                      <p>Confirm that I/We understand that—</p>
                      <div className="checkbox-my">
                        <label className="containers years set-check font-fami">{
                          formData.usualLegalRules == true ?
                            <b> The usual legal rules that require information to be given to
                              investors for offers of financial products do not apply if the amount
                              invested upfront by me (plus any other investments I/We have already
                              made in those financial products) is $750,000 or more; and</b> :
                            <p> The usual legal rules that require information to be given to
                              investors for offers of financial products do not apply if the amount
                              invested upfront by me (plus any other investments I/We have already
                              made in those financial products) is $750,000 or more; and</p>
                        }
                          <input
                            type="checkbox"
                            name="usualLegalRules"
                            checked={
                              formData.usualLegalRules == true ? true : false
                            }

                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.notCompleteInformation == true ?
                            <b>I/We may not receive a complete and balanced set of information about
                              this investment; and</b> :
                            <p>I/We may not receive a complete and balanced set of information about
                              this investment; and</p>
                        }
                          <input
                            type="checkbox"
                            className=""
                            name="notCompleteInformation"
                            checked={
                              formData.notCompleteInformation == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.fewerLegalProtection == true ?
                            <b>I/We have fewer legal protections for this investment; and</b> :
                            <p>I/We have fewer legal protections for this investment; and</p>
                        }
                          <input
                            type="checkbox"
                            name="fewerLegalProtection"
                            checked={
                              formData.fewerLegalProtection == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.notSuitableInvestment == true ?
                            <b> This investment is not suitable for retail investors; and</b> :
                            <p> This investment is not suitable for retail investors; and</p>
                        }
                          <input
                            type="checkbox"
                            name="notSuitableInvestment"
                            checked={
                              formData.notSuitableInvestment == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="containers years set-check font-fami">{
                          formData.seekFinancialAdvice == true ?
                            <b>I/We have been advised to ask questions, read all documents carefully,
                              and seek independent financial advice.</b> :
                            <p>I/We have been advised to ask questions, read all documents carefully,
                              and seek independent financial advice.</p>
                        }
                          <input
                            type="checkbox"
                            name="seekFinancialAdvice"
                            checked={
                              formData.seekFinancialAdvice == true ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span className="error Certificate2"></span>
                      </div>
                    </div>
                  </>
                </div>
                : null

              }

              <div className="cert">
                {
                  formData.isMinimumInvest == "Yes" ? null :

                    <>
                      <div className="review_bm">
                        <div className="mainfetch-div">
                          {
                            formData.largePersonValue === true || formData.twoYearInvestEntity2 === true || formData.largePersonNone == true ?
                              <>
                                {
                                  // Twelve Page Start................ 
                                  formData.investmentCriteriaNone == true && formData.investmentBusinessNone == true ? null :
                                    <>
                                      <h3>Wholesale Certificate A</h3>

                                      <label className='person'>Large persons</label>
                                      {/* <br /> */}
                                      <p>The Investor is ‘large’ on the basis that at least one of the following paragraphs applies immediately before this certificate is given.</p>

                                      <div className="checkbox-my">

                                        <label className="containers restContainer font-fami">{
                                          formData.largePersonValue === true ? <b>As at the last day of each of the Investor's two most recently completed financial
                                            years, the net assets of the Investor and the entities controlled by the Investor
                                            exceeded $5 million.</b> :
                                            <p>As at the last day of each of the Investor's two most recently completed financial
                                              years, the net assets of the Investor and the entities controlled by the Investor
                                              exceeded $5 million.</p>
                                        }

                                          <input
                                            type="checkbox"
                                            name="largePersonValue"
                                            checked={
                                              formData.largePersonValue === true ? true : false
                                            }
                                          />

                                          <span className="checkmark"></span>
                                        </label>
                                        <label className="containers restContainer font-fami">
                                          {
                                            formData.twoYearInvestEntity2 === true ? <b>In each of the Investor's two most recently completed financial years, the total
                                              consolidated turnover of the Investor and the entities controlled by the Investor
                                              exceeded $5 million.</b> :
                                              <p>In each of the Investor's two most recently completed financial years, the total
                                                consolidated turnover of the Investor and the entities controlled by the Investor
                                                exceeded $5 million.</p>
                                          }

                                          <input
                                            type="checkbox"
                                            name="twoYearInvestEntity2"
                                            checked={
                                              formData.twoYearInvestEntity2 === true ? true : false
                                            }
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>


                                      {/* thirteenPage start................. */}
                                      {
                                        // formData.largePersonValue == true || formData.twoYearInvestEntity2 == true ? null :
                                        <>
                                          <label className='person'>Investment Business</label>
                                          <p className='font-fami'>
                                            The Investor was not established or acquired with a view to using it as an entity to
                                            which offers of financial products may be made in reliance upon the exclusion in clause
                                            3(2)(a) and the Investor is an “investment business” on the basis that:
                                          </p>

                                          <div className="checkbox-my">
                                            <label className="containers restContainer font-fami">{
                                              formData.investorPrincipalBusiness == true ?
                                                <b>The Investor’s principal business consists of one or more of the following (tick all applicable):</b> :
                                                <p>The Investor’s principal business consists of one or more of the following (tick all applicable):</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="investorPrincipalBusiness"
                                                checked={
                                                  formData.investorPrincipalBusiness == true ? true : false
                                                }

                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>

                                          <div className="checkbox-my">
                                            <div className="">
                                              <label className="containers restContainer font-fami hh">{
                                                formData.investingFinancialProducts == true ?
                                                  <b> Investing in financial products</b> : <p>Investing in financial products</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="investingFinancialProducts"
                                                  checked={
                                                    formData.investingFinancialProducts == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.actingUnderwriter == true ?
                                                  <b>Acting as an underwriter</b> : <p>Acting as an underwriter</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="actingUnderwriter"
                                                  checked={
                                                    formData.actingUnderwriter == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.financialAdviceService == true ?
                                                  <b>Providing a financial advice service</b> : <p>Providing a financial advice service</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="financialAdviceService"
                                                  checked={
                                                    formData.financialAdviceService == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.clientMoneyPropertyService == true ?
                                                  <b>Providing a client money or property service</b> :
                                                  <p>Providing a client money or property service</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="clientMoneyPropertyService"
                                                  checked={
                                                    formData.clientMoneyPropertyService == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>

                                              <label className="containers restContainer font-fami hh">{
                                                formData.tradingFinancialProducts == true ?
                                                  <b>Trading in financial products on behalf of other persons.</b> :
                                                  <p>Trading in financial products on behalf of other persons.</p>
                                              }
                                                <input
                                                  type="checkbox"
                                                  name="tradingFinancialProducts"
                                                  checked={
                                                    formData.tradingFinancialProducts == true ? true : false
                                                  }
                                                />
                                                <span className="checkmark"></span>

                                              </label>
                                              <span className="error investmentBusiness1"></span>
                                            </div>
                                          </div>

                                          <div className="checkbox-my">
                                            <label className="containers restContainer font-fami">{
                                              formData.registeredBankRBNZ == true ?
                                                <b>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</b> :
                                                <p>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="registeredBankRBNZ"
                                                checked={
                                                  formData.registeredBankRBNZ == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.NBDTInvestor == true ?
                                                <b>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</b> :
                                                <p>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="NBDTInvestor"
                                                checked={
                                                  formData.NBDTInvestor == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.licensedIssuerInsurance == true ?
                                                <b>The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</b> :
                                                <p> The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="licensedIssuerInsurance"
                                                checked={
                                                  formData.licensedIssuerInsurance == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.registeredSchemeManager == true ?
                                                <b>The Investor is the manager of a registered scheme, or a discretionary investment
                                                  management service, that holds a market services licence under the Financial Markets
                                                  Conduct Act 2013.</b> :
                                                <p>The Investor is the manager of a registered scheme, or a discretionary investment
                                                  management service, that holds a market services licence under the Financial Markets
                                                  Conduct Act 2013.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="registeredSchemeManager"
                                                checked={
                                                  formData.registeredSchemeManager == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.derivativesIssuer == true ?
                                                <b>The Investor is a derivatives issuer that holds a market services license under the
                                                  Financial Markets Conduct Act 2013.</b> :
                                                <p>The Investor is a derivatives issuer that holds a market services license under the
                                                  Financial Markets Conduct Act 2013.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="derivativesIssuer"
                                                checked={
                                                  formData.derivativesIssuer == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers restContainer font-fami">{
                                              formData.FinancialAdvisor == true ?
                                                <b>The Investor is a financial adviser.</b> :
                                                <p>The Investor is a financial adviser.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="FinancialAdvisor"
                                                checked={
                                                  formData.FinancialAdvisor == true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            {/* <br /> */}
                                          </div>
                                        </>
                                      }

                                      {/* Fifteen Page Start.................... */}
                                      {
                                        // (formData.largePersonValue == true || formData.twoYearInvestEntity2 == true)
                                        //   ? null : formData.investmentBusinessNone == true ?
                                        <>

                                          <label className='person'>Investment activity criteria</label>
                                          {/* <br /> */}

                                          <p>The Investor meets the ‘investment activity criteria’ on the basis that at least one of the following paragraphs apply.</p>
                                          <div className="checkbox-my">
                                            <label className="containers years set-check font-fami">{
                                              formData.ownedPortfolio2yrs === true ?
                                                <b>The investor owns, or at any time during the 2-year period immediately before this
                                                  certificate is given has owned, a portfolio of financial products (other than
                                                  membership of a retirement scheme or financial products issued by an associate) of a
                                                  value of at least $1 million (in aggregate).</b> :
                                                <p>The investor owns, or at any time during the 2-year period immediately before this
                                                  certificate is given has owned, a portfolio of financial products (other than
                                                  membership of a retirement scheme or financial products issued by an associate) of a
                                                  value of at least $1 million (in aggregate).</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="ownedPortfolio2yrs"
                                                checked={formData.ownedPortfolio2yrs === true ? true : false
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <label className="containers years set-check font-fami">{
                                              formData.carriedTransactions2yrs === true ?
                                                <b>The investor has, during the 2-year period immediately before this certificate is
                                                  given, carried out one or more transaction(s) to acquire financial products (other
                                                  than membership of a retirement scheme or financial products issued by an associate)
                                                  where the amount payable under those transactions (in aggregate) is at least $1
                                                  million.</b> :
                                                <p>The investor has, during the 2-year period immediately before this certificate is
                                                  given, carried out one or more transaction(s) to acquire financial products (other
                                                  than membership of a retirement scheme or financial products issued by an associate)
                                                  where the amount payable under those transactions (in aggregate) is at least $1
                                                  million.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                name="carriedTransactions2yrs"
                                                checked={
                                                  formData.carriedTransactions2yrs === true ? true : false
                                                }

                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <label className="containers years set-check font-fami">{
                                              formData.investmentBusiness10yrs === true ?
                                                <b>The Investor is an individual who has, within the last ten years immediately before
                                                  this certificate is given, been employed or engaged in an investment business and has,
                                                  for at least two years during that ten-year period, participated to a material extent
                                                  in the investment decisions made by the investment business.</b> :
                                                <p>The Investor is an individual who has, within the last ten years immediately before
                                                  this certificate is given, been employed or engaged in an investment business and has,
                                                  for at least two years during that ten-year period, participated to a material extent
                                                  in the investment decisions made by the investment business.</p>
                                            }
                                              <input
                                                type="checkbox"
                                                className=""
                                                name="investmentBusiness10yrs"
                                                checked={
                                                  formData.investmentBusiness10yrs === true ? true : false
                                                }

                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>

                                          {formData.ownedPortfolio2yrs === true || formData.carriedTransactions2yrs === true || formData.investmentBusiness10yrs === true ?
                                            <div className="fullspace pandingfinsh ">
                                              <label>Please define the specific financial products of at least $1mil. </label>
                                              <div className="checkbox-my left-more">
                                                <div>
                                                  <label className="containers font-fami">{
                                                    formData.equitySecurities === true ?
                                                      <b>Equity securities (includes listed shares)</b> : <p>Equity securities (includes listed shares)</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="equitySecurities"
                                                      checked={formData.equitySecurities === true ? true : false}
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <label className="containers font-fami">{
                                                    formData.managedFunds === true ? <b> Managed funds</b> : <p> Managed funds</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="managedFunds"
                                                      checked={formData.managedFunds === true ? true : false}

                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <label className="containers font-fami">{
                                                    formData.derivativeInstruments === true ? <b> Derivative instruments</b> : <p> Derivative instruments</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="derivativeInstruments"
                                                      checked={formData.derivativeInstruments === true ? true : false}
                                                    />

                                                    <span className="checkmark font-fami"></span>
                                                  </label>
                                                  <label className="containers">{
                                                    formData.debtSecurities === true ? <b>Debt securities</b> : <p>Debt securities</p>
                                                  }
                                                    <input
                                                      type="checkbox"
                                                      name="debtSecurities"
                                                      checked={formData.debtSecurities === true ? true : false}

                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            : null
                                          }

                                        </>

                                      }
                                    </>

                                }
                              </>
                              : null
                          }


                          {
                            formData.investmentCriteriaNone == true ?
                              formData.investmentBusinessNone == false ? null :

                                <div className="checkbox-my">
                                  <h3>Wholesale Certificate B</h3>
                                  <label className="containers restContainer  font-fami">{
                                    formData.startupOrBusiness == true ?
                                      <b>I/We previously invested in early stage, start up, or established businesses.</b> :
                                      <p>I/We previously invested in early stage, start up, or established businesses.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="startupOrBusiness"
                                      checked={formData.startupOrBusiness == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>


                                  <label className="containers restContainer  font-fami">{
                                    formData.investorPresentation == true ?
                                      <b>In the course of it’s investment activities, I/We have been involved in investor
                                        presentations and/or due diligence meetings and through this have gained an
                                        appreciation for the risks and opportunities associated with investing in businesses.</b> :
                                      <p>In the course of it’s investment activities, I/We have been involved in investor
                                        presentations and/or due diligence meetings and through this have gained an
                                        appreciation for the risks and opportunities associated with investing in businesses.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="investorPresentation"
                                      checked={formData.investorPresentation == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label className="containers restContainer  font-fami">{
                                    formData.capitalRaisingDirector == true ?
                                      <b> I/We have served as a Director of a business that was involved in capital raising.</b> :
                                      <p> I/We have served as a Director of a business that was involved in capital raising.</p>
                                  }

                                    <input
                                      type="checkbox"
                                      name="capitalRaisingDirector"
                                      checked={formData.capitalRaisingDirector == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label className="containers restContainer  font-fami">{
                                    formData.investingSharesSecurities == true ?
                                      <b>I/We have experience in investing in shares and other securities (listed and unlisted)
                                        and understand the risks with investing in different types of financial products.</b> :
                                      <p>I/We have experience in investing in shares and other securities (listed and unlisted)
                                        and understand the risks with investing in different types of financial products.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="investingSharesSecurities"
                                      checked={formData.investingSharesSecurities == true ? true : false}

                                    />
                                    <span className="checkmark"></span>
                                  </label>


                                  <label className="containers restContainer  font-fami">{
                                    formData.myOwnInvestmentDecisions == true ?
                                      <b> I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                                        benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                                      </b> :
                                      <p> I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                                        benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                                      </p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="valueAndRisksOfInvesting"
                                      disabled={true}
                                      checked={formData.myOwnInvestmentDecisions == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>

                                  <label className="containers restContainer  font-fami">{
                                    formData.valueAndRisksOfInvesting == true ?
                                      <b>In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                                        this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                                      </b> :
                                      <p>In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                                        this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                                      </p>
                                  }
                                    <input
                                      type="checkbox"
                                      name="valueAndRisksOfInvesting"
                                      disabled={true}
                                      checked={formData.valueAndRisksOfInvesting == true ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label className="containers restContainer  font-fami">{
                                    formData.acquiringDisposingFinancialProducts == true ?
                                      <b>I/We have other relevant experience of acquiring or disposing of financial products,
                                        details being the following.</b> :
                                      <p>I/We have other relevant experience of acquiring or disposing of financial products,
                                        details being the following.</p>
                                  }
                                    <input
                                      type="checkbox"
                                      // name="valueAndRisksOfInvesting"
                                      disabled={true}
                                      checked={formData.acquiringDisposingFinancialProducts == true ? true : false}

                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <span className="error investmentProductExp"></span>
                                  {formData.acquiringDisposingFinancialProducts == true ?
                                    <div className="fullspace pandingfinsh Other">
                                      <textarea
                                        className="mouse otherInput"
                                        // name="acquiringDisposingFinancialProductsOther"
                                        disabled={true}
                                        value={formData.acquiringDisposingFinancialProductsOther}
                                        type="text"
                                        placeholder="Please provide details"
                                      ></textarea>
                                    </div> : null}
                                  {formData.rwt12 !== null &&
                                    formData.rwt12 !== '' &&
                                    formData.rwt12 !== 'undefined' &&
                                    formData.rwt12 !== undefined && formData.rwt12 !== "Select" ? (
                                    <div className="review_b">
                                      <label>Financial Adviser Type</label>
                                      <p>{formData.rwt12} </p>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {formData.fullLegalNamesign !== null &&
                                    formData.fullLegalNamesign !== '' &&
                                    formData.fullLegalNamesign !== 'undefined' &&
                                    formData.fullLegalNamesign !== undefined ? (
                                    <div className="review_b">
                                      <label>Financial Adviser Name</label>
                                      <p>{formData.fullLegalNamesign} </p>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {formData.userEmail1 !== null &&
                                    formData.userEmail1 !== '' &&
                                    formData.userEmail1 !== 'undefined' &&
                                    formData.userEmail1 !== undefined ? (
                                    <div className="review_b directoreml">
                                      <label>Email</label>
                                      <p>{formData.userEmail1} </p>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              : null}
                        </div>
                      </div>
                    </>
                }
                <hr />
              </div>
            </div>
          ) : (
            ''
          )}


          <div className="fotter-btn appli_rew">
            <button className="preview" onClick={previousHandler}>
              <img src={leftarrow} className="leftarrow" />
              Previous
            </button>

            <button type="submit" className="next setwidht" onClick={handleClick}>
              Submit Application
              <img src={rightarrow} className="rightarrow" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Twentyone11;