import React from "react";

const states = [
  ["Select", "Select"],
  ["Resident Withholding Tax", "Resident Withholding Tax"],
  ["Non Resident Withholding Tax", "Non Resident Withholding Tax"],
  ["AIL 2%", "AIL 2%"]
];

const Rwt = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others}>
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default Rwt;
