import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import logo from '../logo.png';
import $ from "jquery";
import swal from 'sweetalert';
import info from '../info.png';
const Jointtwentythree = ({ setForm, formData, navigation, saveAndExist, setValues, infodetls }) => {
  const { investmentProductExp, disposedOfFinancial, otherInvestProductExp, timeOfFinancial, otherExperience } = formData;
  const { previous, next } = navigation;

  useEffect(() => {
    $(document).ready(function () {
      $('.my input[type="checkbox"]').click(function () {
        var inputValue = $(this).attr("value");
        if (inputValue === "Other") {
          if ($(this).prop('checked') == true) {
            // var targetBox = $("." + inputValue);
            $(".box").show();
          } else {
            formData.otherInvestProductExp = undefined;
         $(".otherInput").val('');
        //  alert($(".otherInput").val())
            $(".box").hide();
          }
        }

      });
    });
    });

  //   $('#input-test').change(function () {
  //   //   // alert($(this).prop('checked', false));
    
  // alert();
  //     if ($("#input-test").is(":checked") === false) {
  //   //     $(':checkbox:checked').each(function (i) {
  //   //       $(this).prop('checked', false);
  
  //   //     });
  //   //     $("#input-test").prop('checked', true);
      
  //   $(".otherInput").val('');
  //   }
  //   });
  
    // // $('.restContainer').click(function () {
    //   // alert($("#input-test").is(":checked"))
    //    $("#input-test").prop('checked', false);
    //    formData.otherInvestProductExp = undefined;
    //    $(".box").hide();
    //    $(".otherInput").val('');
    
    // });
  // });


  

  saveAndExist = () => {
    formData.status = "PENDING"
    formData.step = "16"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }

  function removeError() {
    $('.error').html('');
  }

  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  function handleClick(e) {
    var val = [];
    $(':checkbox:checked').each(function (i) {
      val[i] = $(this).val();
    });
    let xValue = val.join("##");
    formData.investmentProductExp = xValue;
    localStorage.setItem("step16", "step16")
    if (typeof formData.investmentProductExp == 'undefined' || formData.investmentProductExp === "") {
      $('.investmentProductExp').html('Please select an answer');
    }else if(((formData.investmentProductExp).includes('##Other') || (formData.investmentProductExp).includes('Other'))  && (formData.otherInvestProductExp === undefined || formData.otherInvestProductExp == 'undefined' ||formData.otherInvestProductExp == "") ){ 
      $('.investmentProductExpOther').html('Please provide details');
        }    else
    if (setValues === "Yes") {

    } else {
       go("Jointtwentyfive")
    }
  }
  const { go } = navigation;
  return (
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls} className="info" />
      </div>
      <h3>Investor certificate</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item active"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
     

      </div>
      <p>It is likely you do not qualify for as a “Wholesale Investor”. To determine whether
you are an “Eligible Investor” we will look at your investment experience.</p><br />
      <label>Which of the following products do you have investment experience with?
</label><br />

      <p>Select at least one of the below options</p>


      <div className="checkbox-my">

        <label className="containers">Shares
  <input type="checkbox" name="investmentProductExp" value="Shares" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers">Managed funds
  <input type="checkbox" name="investmentProductExp" value="Shares" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>
        <label className="containers">Commercial property
  <input type="checkbox" name="investmentProductExp" value="Commercial property" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers my">Other
  <input type="checkbox" name="investmentProductExp" id="input-test" value="Other" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>
        <span className="error investmentProductExp"></span>




        <div className="fullspace pandingfinsh Other box">
        <textarea className="mouse otherInput" name ="otherInvestProductExp" value={otherInvestProductExp} type="text" placeholder="Please provide details" onChange={e=>{setForm(e) ; removeError()}}></textarea>
          <span className="error investmentProductExpOther"></span>

        </div>


      </div>


      <div className="six">
        <label>How frequently have you acquired or disposed of financial products?</label>
        <textarea className="full" type="text" name="disposedOfFinancial" onChange={e=>{setForm(e) ; removeError()}} placeholder="Please provide details, for example, how many times a month/year do you trade?" />

        <label>Estimate the length of time since you first started trading financial products?</label>
        <textarea className="mouse" type="text" name="timeOfFinancial" onChange={e=>{setForm(e) ; removeError()}} placeholder="Please provide details" />

        <label>Do you have any other experience worth noting?</label>
        <textarea className="mouse" name="otherExperience" onChange={e=>{setForm(e) ; removeError()}} type="text" placeholder="Please provide details" />

        <p>If you are having trouble understanding or completing the above information, you may not qualify as an “Eligible Investor”. Please contact the MyFarm Investment Team on <a href='tel: 06 323 2954' target='blank'> 06 323 2954 </a> or <a href='mailto:investments@myfarm.co.nz' target='blank'>investments@myfarm.co.nz</a> </p>
      </div>
      <br />
      <div className="fotter-btn">
        <button className="preview" onClick={previous}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={handleClick}  >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
  );
};

export default Jointtwentythree;
