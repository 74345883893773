import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import attch from "../attch.png";
import Webcam from "react-webcam";
import leftarrow from "../leftarrow.png";
import rightarrow from "../rightarrow.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import info from "../info.png";
import logo from "../logo.png";
import $ from "jquery";
import camera from "../camrea.png";
import MenuSidebar from "../Individual/MenuSidebar";
const Six = ({ setForm, formData, navigation, saveAndExist, func, infodetls, imageinfo }) => {
	const {
		
		firstNameone,
		lastNameone,
	} = formData;
	const { previous, next } = navigation;
	const [file, setFile] = useState(null);
	let JointAcount = sessionStorage.getItem("Jointacoount");
	async function uploadWithJSON() {
		const toBase64 = (file) =>
			new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		const data = {
			img64: await toBase64(file),
		};
		next();
		localStorage.setItem("idImageone", data.img64);
	}
	useEffect(() => {
		function readURL(input, imgControlName) {
			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.onload = function(e) {
					$(imgControlName).attr("src", e.target.result);
				};
				reader.readAsDataURL(input.files[0]);
				$("#name_tag").text(input.files[0].name);
			}
		}

		$("#imag2").change(function() {
			var imgControlName = "#ImgPreview2";
			readURL(this, imgControlName);
			$(".preview2").addClass("it");
			$(".btn-rmv2").addClass("rmv");
		});

		$("#removeImage2").click(function(e) {
			e.preventDefault();
			$("#imag2").val("");
			$("#ImgPreview2").attr("src", "");
			$(".preview2").text("");
			$(".btn-rmv2").removeClass("rmv");
		});

		$("#colorselector")
			.change(function() {
				$(".error").html("");
				var select = $(this)
					.find(":selected")
					.val();
				$(".hide").hide();
				$("#" + select).show();
			})
			.change();
	});

	let idImage = localStorage.getItem("idImage");
	const [statee, setState] = useState();
	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: "user",
		facingMode: "environment",
	};
	const handleClick = () => (
		setState(!statee), $(".setSelfieImage").removeAttr("src", captureImg)
	);

	const webcamRef = React.useRef(null);
	let captureImg = "";
	const capture = React.useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		captureImg = imageSrc;
		$(".setSelfieImage").attr("src", captureImg);
		setState(captureImg);
		localStorage.setItem("idImageone", captureImg);

	}, [webcamRef]);
	
	saveAndExist = () => {
		formData.status = "PENDING";
		formData.step = "17";
		func();
	};
	infodetls = () => {
		swal({
			text:
				"By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
		});
	};
	imageinfo = () => {
		swal({
			content: {
				element: "div",
				attributes: {
					innerHTML:
						"<p>MyFarm is required to comply with the New Zealand Government regulations to fight the funding of terrorism and money laundering activities. We are required to obtain, verify and record information about your identity.</p><p>As part of our ongoing Anti-Money laundering obligations, we compare and match the photo contained in your ID against a photo of you.</p>",
				},
			},
		});
	};

	function handleClick2(e) {
		var x = $("#colorselector option:selected").val();
		var pic = $(".setSelfieImage").attr("src");
		let uploadPic = $("#name_tag").text();
		if (x === "0") {
			$(".selectoptionerror").html("Please select options");
		} else if (x === "1") {
			if (typeof pic === "undefined" || pic === "" || pic === null) {
				$(".takePhoteError").html("Please take a photo");
			} else {
				next();
			}
		} else if (x === "2") {
			if (
				typeof uploadPic === "undefined" ||
				uploadPic === "" ||
				uploadPic === null
			) {
				$(".uploadPhoteError").html("Please upload photo of your ID");
			} else {
				uploadWithJSON();
			}
		}
	}

	return (
		<>
		<MenuSidebar navigation={navigation} formData={formData}/>
		<div className="main_container">
		
		<div className="form">
		<img src={logo} alt="img" className="imageCover" />
			{!JointAcount ? (
				<>
					<div className="save-exit se_invff joint-holder">
						<p className="save next" onClick={saveAndExist}>
						SAVE DATA{" "}
						</p>
						<img src={info} onClick={infodetls} className="info" />
					</div>
				</>
			) : null}
			<h3>Please provide identification details for {firstNameone +   (lastNameone != "undefined" && lastNameone != null ?" " + lastNameone: "" )} <img src={info} onClick={imageinfo} className="infos" /></h3>
			
			<label>Please provide a copy of your photo ID</label>

			<div className="button dropdown">
				<select id="colorselector">
					<option value="0" selected>
						Select
					</option>
					<option value="1">Take a photo</option>
					<option value="2">Upload a copy</option>
				</select>
				<span className="error selectoptionerror"></span>
			</div>

			<div id="2" className="colors red hide">
				<Row>
					<Col xs={1}>
						<div class="yes">
							<span class="btn_upload">
								<input
									type="file"
									id="imag2"
									title=""
									accept="image/jpeg,image/png,application/pdf"
									onChange={(e) => setFile(e.target.files[0])}
								/>
								<img src={attch} />
							</span>
						</div>
					</Col>
					<Col xs={11}>
						<label>Upload a clear copy of your photo ID</label>
						<div className="spceimg">
							<span id="name_tag" class="preview2"></span>
							<input
								type="button"
								id="removeImage2"
								value="x"
								class="btn-rmv2"
							/>
							<span className="error uploadPhoteError"></span>
						</div>
					</Col>
				</Row>
			</div>

			<div id="1" className="colors red hide">
				<Col xs={12}>
					<p>Hold your ID in front of the camera, and take a clear photo</p>
					<div className="id">
						<div class="">
							{!statee ? (
								<>
									<Webcam
										audio={false}
										ref={webcamRef}
										screenshotFormat="image/jpeg"
										width={480}
										videoConstraints={videoConstraints}
									/>
									<button className="takepic" onClick={capture}>
										<img src={camera} /> Take a photo
									</button>
								</>
							) : null}

							<img class="setSelfieImage" src=""></img>
							<span className="error takePhoteError"></span>
							{statee ? (
								<div>
									<button className="takepic" onClick={handleClick}>
										Refresh{" "}
									</button>
								</div>
							) : null}
						</div>
					</div>
				</Col>
			</div>

			<br />
			<div className="fotter-btn">
				<button className="preview" onClick={previous}>
					<img src={leftarrow} className="leftarrow" alt=""/>
					Previous
				</button>
				{statee ? (
					<div>
						<button type="submit" className="next" onClick={handleClick2}>
							Continue
							<img src={rightarrow} className="rightarrow"  alt=""/>{" "}
						</button>
					</div>
				) : (
					<button type="submit" className="next" onClick={handleClick2}>
						Continue
						<img src={rightarrow} className="rightarrow"  alt=""/>{" "}
					</button>
				)}
			</div>
		</div>
		</div>
		</>
	);
};

export default Six;
