/*eslint eqeqeq: "off"*/
import { useAuth0 } from '@auth0/auth0-react';
import { formatMs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import AxiosInstance from '../axios';
import axios from 'axios';
import { Domain, audience, duvalapi } from '../../auth/Configuration';
import { checkPropTypes } from 'prop-types';

const Zero = ({ setForm, formData, navigation, saveAndExist, saveAndExists, logInButtons, functioss, investmentTypde }) => {
  const { go } = navigation;

  console.log('formData.companyShareholderDetails', formData.companyShareholderDetails);

  const {
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    funapp,
    logout,
  } = useAuth0();




  const [userapplication1, setUserapplication1] = useState([]);
  const [taxotherdetails, setTaxotherdetails] = useState();
  const [tinnumber, setTinnumber] = useState();
  const [tinreason, setTinreason] = useState();
  formData.contactNumber = userapplication1;

  function check() {
    user.profile != 'User' ? logout() : application();
  }

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          formData.token = accessidToken.__raw;
          check();
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    getUserMetadata();
  }, []);

  const [adminapplication, setAdminapplication] = useState();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  useEffect(() => { }, [adminapplication]);

  const [dualData, setDualData] = useState('');
  const [dualData1, setDualData1] = useState('');
  const [trustData, setTrustData] = useState('');

  const [dualJoint, setDualJoint] = useState(null);
  console.log("dualData1dualData1dualData1", trustData)
  // formData.submissionStatus = dualData !== null ? dualData.submissionStatus : '';

  formData.isPrimary = dualData !== null ? dualData.isPrimary : ''

  sessionStorage.setItem(
    'documentExpiryDates',
    dualData !== null
      ? dualData.documentExpiryDate == null
        ? ''
        : dualData.documentExpiryDate
      : ''
  );

  sessionStorage.setItem(
    'documentExpiryDates1',
    dualData !== null
      ? dualData.documentExpiryDates1 == null
        ? ''
        : dualData.documentExpiryDates1
      : ''
  );
  sessionStorage.setItem(
    'dateOfBirth',
    dualData !== null ? (dualData.dateOfBirth == null ? '' : dualData.dateOfBirth) : ''
  );
  sessionStorage.setItem(
    'residentialAddress1',
    dualData !== null
      ? dualData.isPrimaryResdAddress == null
        ? ''
        : dualData.userResidentialAddress
      : ''
  );
  sessionStorage.setItem(
    'PostalAddress1',
    dualData !== null ? (dualData.userPostalAddress == null ? '' : dualData.userPostalAddress) : ''
  );
  sessionStorage.setItem(
    'street_number',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].addressLine1
          : ''
      : ''
  );
  sessionStorage.setItem(
    'city',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].city
          : ''
      : ''
  );
  sessionStorage.setItem(
    'country',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].country
          : ''
      : ''
  );
  sessionStorage.setItem(
    'postal_code',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].postalCode
          : ''
      : ''
  );
  sessionStorage.setItem(
    'region',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].suburb
          : ''
      : ''
  );

  console.log("dualDatadualData", dualData)
  console.log("lllll", dualData.certificateDetails ?
  dualData.certificateDetails.signatureA : "")
  formData.downsignatureA =dualData.certificateDetails ?
  dualData.certificateDetails.signatureA : "";
  if (dualData !== null && dualData != undefined) {
    formData.fullName = dualData.fullLegalName;
    formData.investmentType = dualData.investmentType;
    formData.fundCode = dualData.fundCode;
    formData.totalInvestment = dualData.totalInvestment;
    formData.documentProof1 = dualData.documentIdentificationProof;
    formData.documentProof21 = dualData.documentIdentificationProofSecondary;
    formData.userEmail = dualData.userEmail;
    formData.userCode = dualData.userCode;
    formData.applicationStage = dualData.applicationStage;
    formData.shareholdingPercentage = dualData.primaryShareOfIncome != null ? dualData.primaryShareOfIncome : 50;
    formData.investorRelation = dualData.investorRelation;
    formData.firstName1 = dualData.firstName;
    formData.middleName = dualData.middleName;
    formData.lastName = dualData.lastName;

    formData.preferredName = dualData.preferredName;
    formData.dateOfBirth = dualData.dateOfBirth;
    // formData.userResidentialAddress = dualData.userResidentialAddress;
    formData.isPrimaryResdAddress = dualData !== null ? dualData.userResidentialAddress : '';

    formData.manuallyAddress = dualData.isGoogleAddress == 1 ? 'true' : 'false';
    formData.isUserAddressSame = dualData.isUserAddressSame === 1 ? 'true' : 'false';
    // formData.userPostalAddress = dualData.userPostalAddress;
    formData.isUserAddressSame =
      dualData !== null ? (dualData.isUserAddressSame === 1 ? 'true' : 'false') : '';
    formData.isPrimaryPostalAdress = dualData !== null ? dualData.userPostalAddress : '';
    formData.isAlternateContact = dualData.isAlternateContact == 0 ? false : true;
    if (dualData.addressDetails != null) {
      formData.street_number = dualData.addressDetails[0].addressLine1;
      formData.city = dualData.addressDetails[0].city;
      formData.country = dualData.addressDetails[0].country;
      formData.postalCode = dualData.addressDetails[0].postalCode;
      formData.region = dualData.addressDetails[0].suburb;
      formData.countryyCode = dualData.addressDetails[0].countryCode;
    }

    formData.priContactType = dualData.priContactType;
    formData.priContactCountryCode =
      dualData.priContactCountryCode !== null ? dualData.priContactCountryCode : '64';
    formData.priContactNumber = dualData.priContactNumber;
    formData.codeVerify = dualData.isPriContactNumberVerified == 0 ? 'false' : 'true';
    formData.secContactType = dualData.secContactType;
    formData.secContactCountryCode =
      dualData.secContactCountryCode !== null ? dualData.secContactCountryCode : '64';
    formData.secContactNumber = dualData.secContactNumber;
    formData.occupation = dualData.occupation;
    formData.otherOccupation = dualData.otherOccupation;
    formData.placeOfBirth = dualData.placeOfBirth;
    formData.countryOfBirth = dualData.countryOfBirth;
    formData.residingCountry = dualData.residingCountry;
    formData.userIrdNumber = dualData.userIrdNumber;
    formData.userIrdNumberProof = dualData.userIrdNumberProof;

    // formData.rwt =
    //   dualData.rwt === 1
    //     ? 'Resident Withholding Tax'
    //     : dualData.nrwt === 1
    //       ? 'Non Resident Withholding Tax'
    //       : dualData.AIL === 1
    //         ? 'AIL 2%'
    //         : '';
    formData.rwt =
      dualData !== null
        ? dualData.taxType
        : '';
    formData.rwtValue = dualData.rwtValue;
    formData.checkboxx = dualData.taxExemption === 1 ? true : false;
    // formData.examptProof = dualData. examptProof;
    formData.examptProof = dualData !== null ? dualData.examptProof : '';


    formData.isPostalAdd11 = dualData.isOtherCitizen == 0 ? 'true' : 'false';

    if (dualData.tinDetails != null) {
      // formData.tinDetails = dualData.tinDetails[0].id;
      // formData.tinId = dualData.tinDetails[0].id;
      formData.tinResidential = dualData.tinDetails[0].countryOfResidence;
      formData.tinNumber = dualData.tinDetails[0].tinNumber;
      formData.tinreason = dualData.tinDetails[0].tinReason;
    }

    formData.biometric = dualData.identificationType;
    formData.idType =
      dualData.documentIdType === 'NZ Driver Licence'
        ? '1'
        : dualData.documentIdType === 'NZ Passport'
          ? '2'
          : dualData.documentIdType === 'other'
            ? '3'
            : '';
    formData.documentNumber = dualData.documentNumber;
    formData.documentExpiryDate = dualData.documentExpiryDate;
    formData.documentVersionNumber = dualData.documentVersionNumber;
    // formData.documentCountryOfIssue = dualData.documentCountryOfIssue !==""&& dualData.documentCountryOfIssue!==undefined? dualData.documentCountryOfIssue:"";
    formData.documentCountryOfIssue = dualData !== null ? dualData.documentCountryOfIssue : '';

    formData.documentProof = dualData.documentProof;
    formData.otherDocumentName = dualData.otherDocumentName;
    formData.isNZBank = dualData.isNZBank === 0 ? 'false' : 'true';
    formData.primaryBankAccountName = dualData.primaryBankName;
    formData.primaryBankAccountNumber = dualData.primaryBankAccountNumber;
    formData.bankSwiftCode = dualData.bankSwiftCode;
    formData.primaryBankProof = dualData.primaryBankProof;
    formData.isNZBank12 =
      dualData.isPrimaryBank != null ? (dualData.isPrimaryBank == 1 ? 'true' : 'false') : 'true';
    formData.Capture = dualData.captureReference;
    formData.step = dualData.step;
    formData.isPrimary = dualData.isPrimary == 1 ? true : false;
  }

  if (dualData.certificateDetails !== undefined && dualData.certificateDetails !== null) {
    formData.largePersonValue =
      dualData.certificateDetails.largePersonValue ==
        "As at the last day of each of the Investor's two most recently completed financial years, the net assets of the Investor and the entities controlled by the Investor exceed $5 million."
        ? true
        : false;
    formData.twoYearInvestEntity2 =
      dualData.certificateDetails.twoYearInvestEntity2 ==
        "In each of the Investor's two most recently completed financial years, the total consolidated turnover of the Investor and the entities controlled by the Investor exceeded $5 million."
        ? true
        : false;
    formData.investorPrincipalBusiness =
      dualData.certificateDetails.investorPrincipalBusiness ==
        'The Investor’s principal business consists of one or more of the following (tick all that apply)'
        ? true
        : false;
    formData.investingFinancialProducts =
      dualData.certificateDetails.investingFinancialProducts == 'Investing in financial products'
        ? true
        : false;
    formData.actingUnderwriter =
      dualData.certificateDetails.actingUnderwriter == 'Acting as an underwriter' ? true : false;
    formData.financialAdviceService =
      dualData.certificateDetails.financialAdviceService == 'Providing a financial advice service'
        ? true
        : false;
    formData.clientMoneyPropertyService =
      dualData.certificateDetails.clientMoneyPropertyService ==
        'Providing a client money or property service'
        ? true
        : false;
    formData.tradingFinancialProducts =
      dualData.certificateDetails.tradingFinancialProducts ==
        'Trading in financial products on behalf of other persons.'
        ? true
        : false;
    formData.registeredBankRBNZ =
      dualData.certificateDetails.registeredBankRBNZ ==
        'The Investor is a registered bank under the Reserve Bank of New Zealand Act 2010.'
        ? true
        : false;
    formData.NBDTInvestor =
      dualData.certificateDetails.NBDTInvestor ==
        'The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.'
        ? true
        : false;
    formData.licensedIssuerInsurance =
      dualData.certificateDetails.licensedIssuerInsurance ==
        'The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.'
        ? true
        : false;
    formData.registeredSchemeManager =
      dualData.certificateDetails.registeredSchemeManager ==
        'The Investor is the manager of a registered scheme, or a discretionary investment management service, that holds a market services licence under the Financial Markets Conduct Act 2013.'
        ? true
        : false;
    formData.derivativesIssuer =
      dualData.certificateDetails.derivativesIssuer ==
        'The Investor is a derivatives issuer that holds a market services license under the Financial Markets Conduct Act 2013.'
        ? true
        : false;
    formData.FinancialAdvisor =
      dualData.certificateDetails.FinancialAdvisor == 'The Investor is a Financial Adviser'
        ? true
        : false;
    formData.ownedPortfolio2yrs =
      dualData.certificateDetails.ownedPortfolio2yrs ==
        'The investor owns, or at any time during the 2-year period immediately before this certificate is given has owned, a portfolio of financial products (other than membership of a retirement scheme or financial products issued by an associate) of a value of at least $1 million (in aggregate).'
        ? true
        : false;
    formData.carriedTransactions2yrs =
      dualData.certificateDetails.carriedTransactions2yrs ==
        'The investor has, during the 2-year period immediately before this certificate is given, carried out one or more transaction(s) to acquire financial products (other than membership of a retirement scheme or financial products issued by an associate) where the amount payable under those transactions (in aggregate) is at least $1 million.'
        ? true
        : false;
    formData.investmentBusiness10yrs =
      dualData.certificateDetails.investmentBusiness10yrs ==
        'The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business.'
        ? true
        : false;
    formData.equitySecurities =
      dualData.certificateDetails.equitySecurities == 'Equity securities (includes listed shares)'
        ? true
        : false;
    formData.managedFunds =
      dualData.certificateDetails.managedFunds == 'Managed funds' ? true : false;
    formData.derivativeInstruments =
      dualData.certificateDetails.derivativeInstruments == 'Derivative instruments' ? true : false;
    formData.debtSecurities =
      dualData.certificateDetails.debtSecurities == 'Debt securities' ? true : false;
    formData.startupOrBusiness =
      dualData.certificateDetails.startupOrBusiness ==
        'I/We previously invested in early stage, start up, or established businesses'
        ? true
        : false;
    formData.investorPresentation =
      dualData.certificateDetails.investorPresentation ==
        'In the course of it’s investment activities, I/We have been involved in investor presentations and/or due diligence meetings and through this have gained an appreciation for the risks and opportunities associated with investing in businesses.'
        ? true
        : false;
    formData.myOwnInvestmentDecisions =
      dualData.certificateDetails.myOwnInvestmentDecisions ==
        'I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.'
        ? true
        : false;
    formData.valueAndRisksOfInvesting =
      dualData.certificateDetails.valueAndRisksOfInvesting ==
        'In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.'
        ? true
        : false;
    formData.capitalRaisingDirector =
      dualData.certificateDetails.capitalRaisingDirector ==
        'I/We have served as a Director of a business that was involved in capital raising'
        ? true
        : false;
    formData.investingSharesSecurities =
      dualData.certificateDetails.investingSharesSecurities ==
        'I/We have experience in investing in shares and other securities (listed and unlisted) and understand the risks with investing in different types of financial products.'
        ? true
        : false;
    formData.acquiringDisposingFinancialProducts =
      dualData.certificateDetails.acquiringDisposingFinancialProductsOther != null &&
        dualData.certificateDetails.acquiringDisposingFinancialProductsOther != 'undefined' &&
        dualData.certificateDetails.acquiringDisposingFinancialProductsOther != '' &&
        dualData.certificateDetails.acquiringDisposingFinancialProductsOther != undefined
        ? true
        : false;
    formData.acquiringDisposingFinancialProductsOther =
      dualData.certificateDetails.acquiringDisposingFinancialProductsOther;
    formData.investmentBusinessNone = dualData.certificateDetails.none_certificate;

    formData.investmentCriteriaNone = dualData.certificateDetails.none_certificate_1;
    formData.largePersonNone = dualData.certificateDetails.largePersonNone;

    formData.isMinimumInvest = dualData.certificateDetails.isMinimumInvest == 1 ? 'Yes' : 'No';
    formData.fewerLegalProtection = dualData.certificateDetails.fewerLegalProtection;
    // formData.companyName = dualData1.companyName == null ? '' : dualData1.companyName;
  
    formData.usualLegalRules =
      dualData.certificateDetails.usualLegalRules ==
        'The usual legal rules that require information to be given to investors for offers of financial products do not apply if the amount invested upfront by me (plus any other investments I/We have already made in those financial products) is $750,000 or more; and'
        ? true
        : false;
    formData.notCompleteInformation =
      dualData.certificateDetails.notCompleteInformation ==
        'I/We may not receive a complete and balanced set of information about this investment; and'
        ? true
        : false;
    formData.fewerLegalProtection =
      dualData.certificateDetails.fewerLegalProtection ==
        'I/We have fewer legal protections for this investment; and'
        ? true
        : false;
    formData.notSuitableInvestment =
      dualData.certificateDetails.notSuitableInvestment ==
        'This investment is not suitable for retail investors; and'
        ? true
        : false;
    formData.seekFinancialAdvice =
      dualData.certificateDetails.seekFinancialAdvice ==
        'I/We have been advised to ask questions, read all documents carefully, and seek independent financial advice.'
        ? true
        : false;
    formData.rwt12 = dualData.certificateDetails.nominatedUserType;
    formData.fullLegalNamesign = dualData.certificateDetails.nominatedUserName;
    formData.userEmail1 = dualData.certificateDetails.nominatedUserEmail;
    formData.certificateNumber = dualData.certificateDetails.certificateNumber;
  }

  // company Details:
  if (dualData1 !== null && formData.investmentType === 'Company') {

    formData.companyName = dualData1.companyName == null ? '' : dualData1.companyName;
    formData.companyNumber = dualData1.companyNumber == null ? '' : dualData1.companyNumber;
    formData.companyTradingName =
      dualData1.companyTradingName == null ? '' : dualData1.companyTradingName;

    formData.companyRegisterAddress =
      dualData1.companyRegisterAddress == null ? '' : dualData1.companyRegisterAddress;

    formData.companyIrdNumber =
      dualData1.companyIrdNumber == null ? '' : dualData1.companyIrdNumber;

    formData.companyAddressProof =
      dualData1.companyAddressProof == null ? '' : dualData1.companyAddressProof;

    formData.fullLegalCompanyName =
      dualData1.fullLegalCompanyName == null ? '' : dualData1.fullLegalCompanyName;

    formData.entityCompanyOne =
      dualData1.entityCompanyOne != null
        ? dualData1.entityCompanyOne == 0
          ? 'false'
          : 'true'
        : 'false';

    formData.isotherCompanyRegister = dualData1.isotherCompanyRegister == 0 ? false : true;

    formData.isHoldingPersonalAssets =
      dualData1.isHoldingPersonalAssets != null
        ? dualData1.isHoldingPersonalAssets == 0
          ? 'false'
          : 'true'
        : 'false';

    formData.isCompanyIndividualOperating =
      dualData1.isCompanyIndividualOperating != null
        ? dualData1.isCompanyIndividualOperating == 0
          ? 'false'
          : 'true'
        : 'false';

    formData.isOtherCompanyIndividual =
      dualData1.isOtherCompanyIndividual != null
        ? dualData1.isOtherCompanyIndividual == 0
          ? 'false'
          : 'true'
        : 'false';

    formData.otherCountryCompanyRegistered =
      dualData1.otherCountryCompanyRegistered == null
        ? ''
        : dualData1.otherCountryCompanyRegistered;

    formData.secFullLegalCompanyName =
      dualData1.secFullLegalCompanyName == null ? '' : dualData1.secFullLegalCompanyName;

    formData.companySecCountryOfResidence =
      dualData1.companySecCountryOfResidence == null ? '' : dualData1.companySecCountryOfResidence;

    formData.individualFullName =
      dualData1.individualFullName == null ? '' : dualData1.individualFullName;

    formData.IndividualRole = dualData1.IndividualRole == null ? '' : dualData1.IndividualRole;
    formData.IndividualEmail = dualData1.IndividualEmail == null ? '' : dualData1.IndividualEmail;

    formData.secondaryIndividualFullName =
      dualData1.secondaryIndividualFullName == null ? '' : dualData1.secondaryIndividualFullName;

    formData.secondaryIndividualRole =
      dualData1.secondaryIndividualRole == null ? '' : dualData1.secondaryIndividualRole;

    formData.SecondaryIndividualEmail =
      dualData1.SecondaryIndividualEmail == null ? '' : dualData1.SecondaryIndividualEmail;

    formData.companySharePercents = dualData.companySharePercent;
    formData.isNoShareCompanys = dualData.isNoShareCompany;

    formData.rwtComp = dualData1 !== null
      ? dualData1.companyTaxType
      : '';

    formData.rwtValueComp = dualData1.companyRwtValue;
    formData.companyExamptProof = dualData1 !== null ? dualData1.companyExamptProof : '';

    formData.isCompanyOhterCountryCitizen =
      dualData1.isCompanyOhterCountryCitizen == 0 ? 'false' : 'true';
    formData.checkboxComp = dualData1.companyTaxExemption === 1 ? true : false;
    formData.companyCountryOfResidence =
      dualData1.companyCountryOfResidence == null ? '' : dualData1.companyCountryOfResidence;

    formData.companyTinNumber =
      dualData1.companyTinNumber == null ? '' : dualData1.companyTinNumber;

    formData.companyTinReason =
      dualData1.companyTinReason == null ? '' : dualData1.companyTinReason;
    formData.companyFundReason =
      dualData1.companyFundReason == null ? '' : dualData1.companyFundReason;
    formData.companyGenerateIncome =
      dualData1.companyGenerateIncome == null ? '' : dualData1.companyGenerateIncome;
    formData.companyFundsDocument =
      dualData1.companyFundsDocument == null ? '' : dualData1.companyFundsDocument;

    formData.inputList =
      dualData1.companyShareholderDetails == null ? '' : dualData1.companyShareholderDetails;

    formData.inputData =
      dualData1.companyDirectorDetails == null ? '' : dualData1.companyDirectorDetails;
  }

  if (trustData !== null && formData.investmentType === 'Trust') {

    formData.trustName = trustData.trustName == null ? '' : trustData.trustName;
    formData.trustcountryOfIncorporation = trustData.trustcountryOfIncorporation == null ? '' : trustData.trustcountryOfIncorporation;
    formData.trustAddressProof = trustData.trustAddressProof == null ? '' : trustData.trustAddressProof;
    formData.trustRegisterAddress = trustData.trustRegisterAddress == null ? '' : trustData.trustRegisterAddress;
    formData.trust_type = trustData.trustType == null ? '' : trustData.trustType;
    formData.trust_type_other = trustData.trustTypeOther == null ? '' : trustData.trustTypeOther;
    // formData.listTrust = trustData.listTrust == null ? '' : trustData.listTrust;
    formData.trustDeedProof = trustData.trustDeedProof == null ? '' : trustData.trustDeedProof;
    formData.TrustIrdNumber = trustData.trustIrdNumber == null ? '' : trustData.trustIrdNumber;
    formData.trustIrdNumberProof = trustData.trustIrdNumberProof == null ? '' : trustData.trustIrdNumberProof;

    formData.rwtTrust = trustData !== null
      ? trustData.trustTaxType
      : '';

    formData.rwtValueTrust = trustData.trustRwtValue;
    formData.checkboxTrust = trustData.trustTaxExemption === 1 ? true : false;
    formData.trustExamptProof = trustData !== null ? trustData.trustExamptProof : '';

    formData.trustCountryOfResidence = trustData.trustCountryOfResidence == null ? '' : trustData.trustCountryOfResidence;
    formData.trustTinNumber = trustData.trustTinNumber == null ? '' : trustData.trustTinNumber;
    formData.trustTinReason = trustData.trustTinReason == null ? '' : trustData.trustTinReason;
    formData.trustFundReason = trustData.trustFundReason == null ? '' : trustData.trustFundReason;
    formData.trustsettlorFund = trustData.trustsettlorFundsGensettlorFundsGen == null ? '' : trustData.trustsettlorFundsGensettlorFundsGen;
    formData.trustGenerateIncome = trustData.trustSourceOfIncome == null ? '' : trustData.trustSourceOfIncome;
    formData.trustSourceOfFundsProof = trustData.trustSourceOfFundsProof == null ? '' : trustData.trustSourceOfFundsProof;
    formData.isTrustOhterCountryCitizen = trustData.isTrustCitizen == 1 ? 'true' : 'false';;
    formData.inputDataList = trustData.trustIndividualDetails;
    formData.inputBenefisList = trustData.beneficiariesDetails;
    formData.optionsd = trustData.trustCompanyName == null ? '' : trustData.trustCompanyName;
    formData.userEmail = trustData.userEmail == null ? trustData.userEmail :'' ;

  }
console.log(trustData,'trustData')
  //for joint

  if (dualJoint !== null && dualJoint !== undefined && formData.investmentType === 'Joint') {
    sessionStorage.setItem(
      'documentExpiryDates1',
      dualJoint.documentExpiryDate == null ||
        dualJoint.documentExpiryDate == 'undefined' ||
        dualJoint.documentExpiryDate == undefined ||
        dualJoint.documentExpiryDate == ''
        ? ''
        : dualJoint.documentExpiryDate
    );
    sessionStorage.setItem(
      'dateOfBirth2',
      dualJoint.dateOfBirth == null ? '' : dualJoint.dateOfBirth
    );
    sessionStorage.setItem(
      'residentialAddress2',
      dualJoint.userResidentialAddress == null ? '' : dualJoint.userResidentialAddress
    );
    sessionStorage.setItem(
      'PostalAddress2',
      dualJoint.userPostalAddress == null ||
        dualJoint.userPostalAddress == 'undefined' ||
        dualJoint.userPostalAddress == undefined
        ? ''
        : dualJoint.userPostalAddress
    );

    // formData.isPostalAdd11 = dualData.isOtherCitizen == 0 ? 'true' : 'false';

    sessionStorage.setItem(
      'street_number2',
      dualJoint.addressDetails == null
        ? ''
        : dualJoint.addressDetails[0] != null
          ? dualJoint.addressDetails[0].addressLine1
          : ''
    );
    sessionStorage.setItem(
      'city2',
      dualJoint.addressDetails == null
        ? ''
        : dualJoint.addressDetails[0] != null
          ? dualJoint.addressDetails[0].city
          : ''
    );
    sessionStorage.setItem(
      'country2',
      dualJoint.addressDetails == null
        ? ''
        : dualJoint.addressDetails[0] != null
          ? dualJoint.addressDetails[0].country
          : ''
    );
    sessionStorage.setItem(
      'postal_code2',
      dualJoint.addressDetails == null
        ? ''
        : dualJoint.addressDetails[0] != null
          ? dualJoint.addressDetails[0].postalCode
          : ''
    );
    sessionStorage.setItem(
      'region2',
      dualJoint.addressDetails == null
        ? ''
        : dualJoint.addressDetails[0] != null
          ? dualJoint.addressDetails[0].suburb
          : ''
    );

    // formData.shareholdingPercentage =
    //   dualData.primaryShareOfIncome != null ? dualData.primaryShareOfIncome : 50;
      formData.shareholdingPercentage =
      dualData.primaryShareOfIncome !== undefined ||
        dualData.primaryShareOfIncome !== 'undefined' ||
        dualData.primaryShareOfIncome !== null
        ? dualData.primaryShareOfIncome
        : 50;

    formData.secondholder = dualJoint.fullLegalName;

    formData.documentProof21 = dualData.documentIdentificationProofSecondary;

    formData.preferredName2 =
      dualJoint.preferredName !== undefined ||
        dualJoint.preferredName !== 'undefined' ||
        dualJoint.preferredName !== null
        ? dualJoint.preferredName
        : '';

    formData.dateOfBirth2 = dualJoint.dateOfBirth;

    formData.isSecondryPostalAdre = dualJoint !== null ? dualJoint.userPostalAddress : '';
    formData.isSecondryResdAddress =
      dualJoint !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualJoint.userResidentialAddress
        : '';
    formData.isUserAddressSame2 =
      dualJoint !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualJoint.isUserAddressSame == 1
          ? 'true'
          : 'false'
        : '';

    formData.manuallyAddress2 = dualJoint.isGoogleAddress == 1 ? 'true' : 'false';

    formData.street_number3 = dualJoint.addressDetails[0].addressLine1;

    formData.city3 = dualJoint.addressDetails[0].city;

    formData.country3 = dualJoint.addressDetails[0].country;

    formData.postalCode3 = dualJoint.addressDetails[0].postalCode;

    formData.region3 = dualJoint.addressDetails[0].suburb;

    formData.countryyCode3 = dualJoint.addressDetails[0].countryCode;

    formData.email1 =
      dualJoint.userEmail === dualJoint.userCode ? dualData.userEmail : dualJoint.userEmail;

    formData.usercode1 = dualJoint != null ? dualJoint.userCode : '';

    formData.isAlternateContact1 = dualJoint.isAlternateContact == 0 ? false : true;

    formData.Capture2 = dualJoint.captureReference;
    formData.priContactType2 = dualJoint.priContactType;
    formData.priContactCountryCode2 = dualJoint.priContactCountryCode;
    formData.priContactNumber2 = dualJoint.priContactNumber;
    formData.secContactType2 = dualJoint.secContactType;
    formData.secContactCountryCode2 = dualJoint.secContactCountryCode;
    formData.secContactNumber2 = dualJoint.secContactNumber;
    formData.occupation2 = dualJoint.occupation;
    formData.otherOccupation2 = dualJoint.otherOccupation;
    formData.placeOfBirth2 = dualJoint.placeOfBirth;
    formData.countryOfBirth2 = dualJoint.countryOfBirth;
    formData.residingCountry2 = dualJoint.residingCountry;
    formData.userIrdNumber2 = dualJoint.userIrdNumber;
    formData.userIrdNumberProof2 = dualJoint.userIrdNumberProof;

    formData.rwt2 =
      dualJoint !== null
        ? dualJoint.taxType
        : '';
    formData.rwtValue2 = dualJoint.rwtValue;
    formData.isPostalAdd12 = dualJoint.isOtherCitizen == 0 ? 'true' : 'false';
    formData.checkboxxjoint2 = dualJoint.taxExemption === 1 ? true : false;
    formData.examptProof2 = dualJoint !== null ? dualJoint.examptProof : '';
    
    if (dualJoint.tinDetails != null) {
      formData.tinResidential2 = dualJoint.tinDetails[0].countryOfResidence;
      formData.tinNumber2 = dualJoint.tinDetails[0].tinNumber;
      formData.tinReason2 = dualJoint.tinDetails[0].tinReason;
    }

    // for Joint
    formData.Capture2 = dualJoint.captureReference;
    formData.biometric2 = dualJoint.identificationType;
    formData.idType2 =
      dualJoint.documentIdType === 'NZ Driver Licence'
        ? '1'
        : dualJoint.documentIdType === 'NZ Passport'
          ? '2'
          : dualJoint.documentIdType === 'other'
            ? '3'
            : '';

    formData.middleName = dualJoint.middleName == null ? '' : dualJoint.middleName;
    formData.documentNumber2 = dualJoint.documentNumber;
    formData.documentExpiryDate2 = dualJoint.documentExpiryDate;
    formData.documentVersionNumber2 = dualJoint.documentVersionNumber;
    // formData.documentCountryOfIssue2 = dualJoint.documentCountryOfIssue;
    formData.documentCountryOfIssue2 = dualJoint !== null ? dualJoint.documentCountryOfIssue : '';

    formData.documentProof2 = dualJoint.documentProof;
    formData.otherDocumentName2 = dualJoint.otherDocumentName;

    //for Joint
    formData.isNZBank12 =
      dualJoint.isPrimaryBank != null ? (dualJoint.isPrimaryBank == 1 ? 'true' : 'false') : 'true';

    formData.isNZBank2 = dualJoint.isNZBank === 0 ? 'false' : 'true';
    formData.primaryBankAccountName2 = dualJoint.primaryBankName;
    formData.primaryBankAccountNumber2 = dualJoint.primaryBankAccountNumber;
    formData.bankSwiftCode2 = dualJoint.bankSwiftCode;
    formData.primaryBankProof2 = dualJoint.primaryBankProof;
    formData.certificateNumber =
      dualJoint.certificateDetails !== null &&
        dualJoint.certificateDetails !== 'undefined' &&
        dualJoint.certificateDetails !== undefined
        ? dualJoint.certificateDetails.certificateNumber
        : null;
  }
  // formData.signatureA = dualJoint&& dualJoint.certificateDetails !=null && 
  // dualJoint.certificateDetails.signatureA !== null &&
  //       dualJoint.certificateDetails.signatureA !== 'undefined' &&
  //       dualJoint.certificateDetails.signatureA !== undefined
  //       ? dualJoint.certificateDetails.signatureA
  //       : null;
  if(dualJoint){
    console.log("nnnnnnnnn",dualJoint)

  }
  const application = async () => {
    if (isAuthenticated && user.email_verified == true) {
      const applicationdata1 = await axios
        .get(`${duvalapi}/user/details/${investmentTypde != undefined && investmentTypde !== null &&
          investmentTypde !== "" ? investmentTypde : 'undefined'}`, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })

        .then((applicationdata1) => {
          sessionStorage.setItem('investorRelation', applicationdata1.data[0].investorRelation);
          setDualData(applicationdata1.data[0] !== null ? applicationdata1.data[0] : null);
          setDualData1(
            applicationdata1.data[0] !== null ? applicationdata1.data[0].companyDetails : null
          );
          setTrustData(
            applicationdata1.data[0] !== null ? applicationdata1.data[0].trustDetails : null
          );

          setDualJoint(applicationdata1.data[1] !== null ? applicationdata1.data[1] : null);


          if (formData.step === '5') {
            formData.class = 'Second';

            // go('Eight');
            go('Second');
          } else {
            formData.class = 'Second';

            go('Second');
          }
        })
        .catch((error) => {
          go('Second');
        });

    }
  };

  logInButtons = () => {
    go('First');
  };


  // console.log(" formData.application",application)

  return (
    <div className="main-loader">
      <div className="loader"></div>
    </div>
  );
};

export default Zero;
