import React, { useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import logo from '../logo.png';
import swal from 'sweetalert';
import $ from 'jquery';
import info from '../info.png';
import MenuSidebar from './MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';



const Fifteen = ({ func, setForm, formData, navigation }) => {
  const { previous, next } = navigation;
  const saveandexit = () => {
    func();
  };
  const query = useQuery();

  let queryValue = query.get("update");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function handleClick(e) {

    formData.investorPrincipalBusiness = false
    formData.investingFinancialProducts = false
    formData.actingUnderwriter = false
    formData.financialAdviceService = false
    formData.clientMoneyPropertyService = false
    formData.tradingFinancialProducts = false
    formData.registeredBankRBNZ = false
    formData.NBDTInvestor = false
    formData.licensedIssuerInsurance = false
    formData.registeredSchemeManager = false
    formData.derivativesIssuer = false
    formData.FinancialAdvisor = false
    formData.twoYearInvestEntity = formData.twoYearInvestEntity === true ? true : false

    if (formData.investmentCriteriaNone == true) {
      next()
    }
    else if (formData.ownedPortfolio2yrs === false &&
      formData.carriedTransactions2yrs === false &&
      formData.investmentBusiness10yrs === false &&
      formData.equitySecurities === false &&
      formData.managedFunds === false &&
      formData.derivativeInstruments === false &&
      formData.debtSecurities === false) {
      $('.investmentCriteria').html('Please select an answer');

    } else if (formData.equitySecurities === false && formData.managedFunds === false &&
      formData.derivativeInstruments === false && formData.debtSecurities === false) {
      $('.checkbox_error').html('Please select an answer');

    }

    else {
      formData.acquiringDisposingFinancialProductsOther = null
      formData.startupOrBusiness = false
      formData.investorPresentation = false
      formData.myOwnInvestmentDecisions = false
      formData.valueAndRisksOfInvesting = false

      formData.capitalRaisingDirector = false
      formData.investingSharesSecurities = false
      formData.acquiringDisposingFinancialProducts = false
      go('Eleven');
    }
  }
  // function removeError() {
  //   $('.error').html('');
  // }
  function removeError() {
    $('.error').html('');
  }

  const { go } = navigation;
  const noneCondition = () => {
    formData.equitySecurities = false
    formData.managedFunds = false
    formData.derivativeInstruments = false
    formData.debtSecurities = false
  }

  const noneAll = () => {
    formData.ownedPortfolio2yrs = false
    formData.carriedTransactions2yrs = false
    formData.investmentBusiness10yrs = false
    formData.equitySecurities = false
    formData.managedFunds = false
    formData.derivativeInstruments = false
    formData.debtSecurities = false

  }
  const Nonefalse = () => {
    formData.investmentCriteriaNone = false
  }

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Wholesale Certification A</h2> :
              <h2>Step 5: Wholesale Certification A</h2>

          }
          {/* <h2>Wholesale Certification: Wholesale Certification 1</h2> */}
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          {queryValue ? <p></p> :
            <div
              disabled={formData.isPrimary == 1 ? false : true}>
              <div className="save-exit se_invff">
                <p className="save next" onClick={saveandexit}>
                  SAVE DATA{' '}
                </p>
                <img src={info} onClick={infodetls} className="info" alt="" />
              </div>
            </div>
          }
          <div className="grid-container">
            <div className="grid-item "></div>
            <div className="grid-item active"></div>

          </div>
          <label className='font-fami'><b>Investment activity criteria</b></label>

          <p>The Investor meets the ‘investment activity criteria’ on the basis that at least one of the following paragraphs apply:</p>

          <div className="checkbox-my">
            <label className="containers years set-check font-fami">{
              formData.ownedPortfolio2yrs === true ? <b>
                The Investor owns, or at any time during the 2-year period immediately before this
                certificate is given has owned, a portfolio of financial products (other than
                membership of a retirement scheme or financial products issued by an associate) of a
                value of at least $1 million (in aggregate).
              </b> : <p>
                The Investor owns, or at any time during the 2-year period immediately before this
                certificate is given has owned, a portfolio of financial products (other than
                membership of a retirement scheme or financial products issued by an associate) of a
                value of at least $1 million (in aggregate).
              </p>
            }
              <input
                disabled={formData.isPrimary == 0 ? true : false}

                type="checkbox"
                name="ownedPortfolio2yrs"
                checked={formData.ownedPortfolio2yrs === true ? true : false
                }
                onChange={(e) => setForm(e)}
                onClick={() => {
                  Nonefalse();
                  noneCondition();
                  removeError();

                }
                }
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers years set-check font-fami">{formData.carriedTransactions2yrs === true ?
              <b>The Investor has, during the 2-year period immediately before this certificate is
                given, carried out one or more transaction(s) to acquire financial products (other
                than membership of a retirement scheme or financial products issued by an associate)
                where the amount payable under those transactions (in aggregate) is at least $1
                million.</b> :
              <p>The Investor has, during the 2-year period immediately before this certificate is
                given, carried out one or more transaction(s) to acquire financial products (other
                than membership of a retirement scheme or financial products issued by an associate)
                where the amount payable under those transactions (in aggregate) is at least $1
                million.</p>
            }
              <input
                disabled={formData.isPrimary == 0 ? true : false}

                type="checkbox"
                name="carriedTransactions2yrs"
                checked={
                  formData.carriedTransactions2yrs === true ? true : false
                }
                onClick={() => {
                  Nonefalse();
                  noneCondition();
                  removeError();


                }}
                onChange={(e) => {
                  setForm(e);
                }}
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers years set-check font-fami">{
              formData.investmentBusiness10yrs === true ? <b>The Investor is an individual who has, within the last ten years immediately before
                this certificate is given, been employed or engaged in an investment business and has,
                for at least two years during that ten-year period, participated to a material extent
                in the investment decisions made by the investment business.</b> :
                <p>The Investor is an individual who has, within the last ten years immediately before
                  this certificate is given, been employed or engaged in an investment business and has,
                  for at least two years during that ten-year period, participated to a material extent
                  in the investment decisions made by the investment business.</p>
            }
              <input
                disabled={formData.isPrimary == 0 ? true : false}

                type="checkbox"
                className=""
                name="investmentBusiness10yrs"
                checked={
                  formData.investmentBusiness10yrs === true ? true : false
                }
                onClick={() => {
                  Nonefalse();
                  noneCondition();
                  removeError();

                }}
                onChange={(e) => {
                  setForm(e);
                }}
              />
              <span className="checkmark"></span>
            </label>
            <label className="containers other-check font-fami">{formData.investmentCriteriaNone == true ? <b> None of the above</b> :
              <p> None of the above</p>}

              <input
                disabled={formData.isPrimary == 0 ? true : false}

                type="checkbox"
                onClick={() => {
                  noneAll();
                  removeError();
                }}
                name="investmentCriteriaNone"

                checked={formData.investmentCriteriaNone == true ? true : false}
                onChange={(e) => {
                  setForm(e);

                }
                }

              />
              <span className="checkmark"></span>
            </label>
            <span className="error investmentCriteria"></span>
          </div>

          {formData.ownedPortfolio2yrs === true || formData.carriedTransactions2yrs === true || formData.investmentBusiness10yrs === true ?
            <div className="fullspace pandingfinsh ">
              <label>Please define the specific financial products of at least $1mil. </label>
              <div className="checkbox-my left-more">
                <p>Select all that apply:</p>
                <div>
                  <label className="containers font-fami">{
                    formData.equitySecurities === true ? <b> Equity securities (includes listed shares)</b> :
                      <p> Equity securities (includes listed shares)</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="equitySecurities"
                      checked={formData.equitySecurities === true ? true : false}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      onClick={() => Nonefalse()}

                    />
                    <span className="checkmark equitySecurities"></span>
                  </label>
                  <label className="containers font-fami">{
                    formData.managedFunds === true ? <b> Managed funds</b> : <p> Managed funds</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="managedFunds"
                      checked={formData.managedFunds === true ? true : false}
                      onClick={() => Nonefalse()}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}

                    />
                    <span className="checkmark equitySecurities"></span>
                  </label>
                  <label className="containers font-fami">{
                    formData.derivativeInstruments === true ? <b> Derivative instruments</b> : <p> Derivative instruments</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"

                      onClick={() => Nonefalse()}
                      name="derivativeInstruments"
                      checked={formData.derivativeInstruments === true ? true : false}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}

                    />
                    <span className="checkmark equitySecurities font-fami"></span>
                  </label>
                  <label className="containers">{
                    formData.debtSecurities === true ? <b>Debt securities</b> : <p>Debt securities</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="debtSecurities"
                      checked={formData.debtSecurities === true ? true : false}
                      onClick={() => Nonefalse()}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                    <span className="checkmark equitySecurities"></span>
                  </label>
                  <span className='error checkbox_error'></span>
                </div>
              </div>
            </div>
            : null
          }

          <br />
          <br />
          <div className="fotter-btn">
            <button className="preview past" onClick={previous}>
              <img src={leftarrow} className="leftarrow" alt="leftarrow" />
              Previous
            </button>
            <button type="submit" className="next" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Fifteen;
