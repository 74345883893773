import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import rightarrow from "../pages/rightarrow.png";
function LoginButton() {
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  return !isAuthenticated && (
   
      <button onClick={loginWithRedirect}>CONTINUE MY EXISTING APPLICATION<img src={rightarrow} className="rightarrow" alt=''/></button>
    
    
  );
}

export default LoginButton;