import React, {  useState } from "react";
import leftarrow from "../leftarrow.png";
import rightarrow from "../rightarrow.png";
import ItemForm from "../ItemForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import info from "../info.png";
import logo from "../logo.png";
import Copy from "../Copy.png";
import $ from "jquery";
import swal from "sweetalert";
import moment from "moment";
import MenuSidebar from "./MenuSidebar";
import { infodetls } from '../Individual/SaveInfo';

const Twenty = ({func, setForm, formData, navigation }) => {
	const {
		units,
		fullName,
	} = formData;
	const { next } = navigation;
	
	const saveandexit=()=>{
		func();
	
	  }
	function removeError() {
		$(".error").html("");
	}


	// multi = multi.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

	function handleClick(e) {
		next()
	}
	const { go } = navigation;

	
	// function previous1(e) {
	// 	{
	// 		myValuestwo1 === "step16"
	// 			? go("Eighteen")
	// 			: setValues === "Yes"
	// 			? go("Ten")
	// 			: formData.isTwoYearInvest === "true"
	// 			? go("Nineteen")
	// 			: go("Eleven");
	// 	}
	// }

	$(function() {
		$(".promocopy-outer button").click(function() {
			$(this)
				.prev("input")
				.focus();
			$(this)
				.prev("input")
				.select();

			document.execCommand("copy");
		});
	});
	// function setDateformate(dateObj) {
	// 	if (
	// 		dateObj !== null &&
	// 		dateObj !== "undefined" &&
	// 		typeof dateObj !== "undefined" &&
	// 		dateObj.includes("-")
	// 	) {
	// 		let dat = dateObj.split("-");
	// 		dat = dat[2] + "-" + dat[1] + "-" + dat[0];
	// 		let momentObj = moment(dat, "DD-MM-YYYY");
	// 		let showDate = moment(momentObj).format("DD MMMM, YYYY");
	// 		return showDate;
	// 	}
	// 	return date;
	// }
	return (
		<>
		 <MenuSidebar navigation={navigation} formData={formData}/>
	
		{/* <div className="form">
			<img src={logo} alt="img" className="imageCover" />
			<div className="save-exit se_invff">
			<p className="save next" onClick={saveandexit}>
			Save Data{" "}
        </p>
				<img src={info} onClick={infodetls} className="info" />
			</div>
			<h3>Payment information</h3>
			
			<p>
				To secure your investment of {units} units in the{" "}
				{userapplication.syndicateName}, please read the below information, and
				arrange to make the deposit payment.
			</p>
			<br />
			<p>
				I/We confirm that I/We am/are, or the entity, which takes up the
				investment, is/are able to make that payment.
			</p>
			<br />
			<p>
				I/We accept that I/We must make full payment totalling $
				{userapplication.valuePerUnit} per Unit (100% of investment) as follows:
			</p>

			<ul className="usual">
				<li>
					a) A deposit payment of NZ ${multis} per Unit (
					{userapplication.percentageValue}% of Investment) upon application and
					no later than {setDateformate(date)}.{" "}
				</li>
				<li>b) {userapplication.furtherPaymentsBlurb} </li>
			</ul>
			<Row>
				<Col md={12}>
					<label>Deposit Details </label>
				</Col>
			</Row>
			<br />

			<Row>
				<Col>
					<div className="">
						<label>Account name</label>
						<ItemForm
							// label="Account name"
							id="fullName"
							className="full"
							readonly
							value="Sharp Tudhope Trust Account"
							name="accountName"
							
						/>
					</div>
				</Col>
				<Col>
					<div className="">
						<label>Bank name</label>
						<ItemForm
							// label="Bank name"
							id="emails"
							className="full"
							placeholder="ANZ"
							readonly
							value="ANZ"
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<div className="pw-promolist__tablist__right promocopy-outer">
						<label>Account number</label>
						<input
							readonly
							type="text"
							className="full"
							value="06-0433-0020939-00"
							id="promoCopy"
						/>
						<button className="copyall">
							<span class="tersalin">
								<img src={Copy} />
							</span>
						</button>
					</div>
				</Col>
				
			</Row>

			<Row>
				<Col md={12}>
					<div className="">
						<label>Payment reference</label>
						<ItemForm
							
							id="emails"
							className="full"
							placeholder="<Payment reference> <investment entity name>"
							name="paymentReferance"
							value={
								formData.investmentType === "Trust"
									? formData.refernace + " " + formData.trustName
									: formData.investmentType === "Company"
									? formData.refernace + " " + formData.companyName
									: formData.refernace + " " + fullName
							}
							onChange={(e) => {
								setForm(e);
								removeError();
							}}
							readonly
						/>
					</div>
				</Col>
				<Col md={12}>
					<div className="">
						<label>Deposit amount</label>
						<ItemForm
							id="emails"
							className="full"
							value={"$" + multis}
							onChange={(e) => {
								setForm(e);
								removeError();
							}}
							readonly
						/>
					</div>
				</Col>
			</Row>
			<div className="fotter-btn">
				<button className="preview past " onClick={previous1}>
					<img src={leftarrow} className="leftarrow" alt=""/>
					Previous
				</button>
				<button type="submit" className="next" onClick={handleClick}>
					Continue
					<img src={rightarrow} className="rightarrow"  alt=""/>{" "}
				</button>
			</div>
		</div> */}
		
		</>
	);
};

export default Twenty;
