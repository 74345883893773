/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import ReactFlagsSelect from 'react-flags-select';
import { getCode } from 'country-list';
import Rwt from '../Rwt';
import Nrwt from '../Nrwt';
import MenuSidebar from './MenuSidebar';
import axios from 'axios';
import { multisavedata } from '../../auth/Configuration';
import Swal from 'sweetalert2';
import { infodetls } from './SaveInfo';
import UploadImageShareholder from './UploadImageShareholder';
import useQuery from '../useQuery';


const Four = ({ setForm, formData, navigation, infodetls11, func }) => {
  const {
    rwtValue,
    checkboxx,
    rwt,
    placeOfBirth,
    isPostalAdd1,
    countryOfBirth,
    occupation,
    userIrdNumber,
    residingCountry,
    otherOccupation,
    lastName,
    middleName,
    firstName
  } = formData;
  const { next } = navigation;
  const query = useQuery();

  let queryValue = query.get("update");
  let countrtyCode = [
    'NZ',
    'AF',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BR',
    'IO',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CO',
    'KM',
    'CG',
    'CD',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'PF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'HT',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RO',
    'RU',
    'RW',
    'KN',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SZ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'US',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VI',
    'YE',
    'ZM',
    'ZW'
  ];

  const [selected, setSelected] = useState(
    formData.countryOfBirth !== null &&
      formData.countryOfBirth !== '' &&
      formData.countryOfBirth !== 'undefined' &&
      formData.countryOfBirth !== undefined
      ? formData.countryOfBirth
      : 'NZ'
  );
  const [selected1, setSelected1] = useState(
    formData.residingCountry !== null &&
      formData.residingCountry !== '' &&
      formData.residingCountry !== 'undefined' &&
      formData.residingCountry !== undefined
      ? formData.residingCountry
      : 'NZ'
  );
  const [selected2, setSelected2] = useState(
    formData.tinResidential !== null &&
      formData.tinResidential !== '' &&
      formData.tinResidential !== 'undefined' &&
      formData.tinResidential !== undefined
      ? formData.tinResidential
      : ''
  );

  formData.countryOfBirth = selected;
  formData.residingCountry = selected1;
  if (isPostalAdd1 === 'false') {
    formData.tinResidential = selected2;
  } else {
    formData.tinResidential = null;
  }



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      isPostalAdd1 === 'true' ||
      isPostalAdd1 === '' ||
      isPostalAdd1 === 'undefined' ||
      isPostalAdd1 === undefined
    ) {
      formData.isTwoYearInvest === 'true' ? go('Nineteen') : go('Four');
    }
    $(document).ready(function () {
      $('#hide').click(function () {
        $('#showmy').hide();
        $('#hidemy').show();
      });

      $('#show').click(function () {
        $('#hidemy').hide();
        $('#showmy').show();
      });
      $('.flagsave').click(function () {
        let country = $('.counrtyselext #select_flag_button > span > span').text();
        localStorage.setItem('countryOfBirth', country);
      });
      $(document).ready(function () {
        $('.flagsave').click(function () {
          let countryOfCitizenship = $('.counrtyselext1 #select_flag_button > span > span').text();
          localStorage.setItem('countryOfCitizenship', countryOfCitizenship);
        });
      });
      $(document).ready(function () {
        let isNZCitizen = true;
        localStorage.setItem('isNZCitizen', isNZCitizen);
        $('.yesno').click(function () {
          isNZCitizen = $(this).val();
          localStorage.setItem('isNZCitizen', isNZCitizen);
        });
      });
      $('.flagsave').click(function () {
        let residingCountry = $('.counrtyselext2 #select_flag_button > span > span').text();
        localStorage.setItem('residingCountry', residingCountry);
      });
    });
    $('.toogles').click(function () {
      $('.toogles').removeClass('actives');
      $(this).addClass('actives');
    });
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);
      }
    }

    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });

    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
  });

  useEffect(() => {
    $(document).ready(function () {
      $('.quantity').keypress(function (e) {
        if (
          (e.which >= 65 && e.which <= 90) ||
          e.which === 32 ||
          e.which === 45 ||
          (e.which >= 97 && e.which <= 122)
        ) {
          return true;
        } else {
          return false;
        }
      });
    });
    $(document).ready(function () {
      $('.numbers').keypress(function (e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
          return false;
        }
      });
    });
    $('.digitsOnly').keypress(function (e) {
      var regex = new RegExp(/^[a-zA-Z\s/0-9]+$/);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });

  function handleClick(e) {
    // formData.fullName = firstName + (middleName ? " " + middleName : "") + (lastName ? " " + lastName : "")

    formData.residingCountry = selected1;
    if (formData.placeOfBirth === null || formData.placeOfBirth == undefined || formData.placeOfBirth === "") {
      $('.placeOfBirtherror').html('Please enter city/town were you born in')
    }
    if (formData.userIrdNumber === null || formData.userIrdNumber == undefined || formData.userIrdNumber === "") {
      $('.userIrdNumbers').html('Please enter your IRD number')
    } if (formData.userIrdNumberProof === null || formData.userIrdNumberProof == undefined || formData.userIrdNumberProof === "") {
      $('.userIrdNumberProof').html('Please attach IRD number proof')
    }
    if (formData.rwt !== "Resident Withholding Tax" && formData.rwt !== "Non Resident Withholding Tax" && formData.rwt !== "AIL 2%") {
      $('.funderror').html('Please confirm your tax type')
    }
    if ((formData.rwtValue == null || formData.rwtValue == undefined || formData.rwtValue === "Select") && formData.rwt == "Resident Withholding Tax") {
      $('.rwterror').html('Please select tax rate')
    } if ((formData.examptProof === null || formData.examptProof === "" || formData.examptProof == undefined) && formData.rwt == "Resident Withholding Tax" && rwtValue === 'Exempt') {
      $('.exemptprooferror').html('Please attach proof of exemption')
    }

    if (formData.placeOfBirth === null || formData.placeOfBirth == undefined || formData.placeOfBirth === "") {
      $('.placeOfBirtherror').html('Please enter city/town were you born in')
    }
    else if (formData.userIrdNumber === null || formData.userIrdNumber == undefined || formData.userIrdNumber === "") {
      $('.userIrdNumbers').html('Please enter your IRD number')
    } else if (formData.userIrdNumberProof === null || formData.userIrdNumberProof == undefined || formData.userIrdNumberProof === "") {
      $('.userIrdNumberProof').html('Please attach IRD number proof')
    }
    else if (formData.rwt !== "Resident Withholding Tax" && formData.rwt !== "Non Resident Withholding Tax" && formData.rwt !== "AIL 2%") {
      $('.funderror').html('Please confirm your tax type')
    }
    else if ((formData.rwtValue == null || formData.rwtValue == undefined || formData.rwtValue === "Select") && formData.rwt == "Resident Withholding Tax") {
      $('.rwterror').html('Please select tax rate')
    } else if ((formData.examptProof === null || formData.examptProof === "" || formData.examptProof == undefined) && formData.rwt == "Resident Withholding Tax" && rwtValue === 'Exempt') {
      $('.exemptprooferror').html('Please attach proof of exemption ')
    }
    else if (isPostalAdd1 === 'false') {
      formData.tinResidential = selected2;
      formData.class = 'Five';

      next();
    } else {
      formData.tinResidential = null;
      formData.class = 'Five';

      next();

    }
  }
  function removeError() {
    $('.error').html('');
  }


  infodetls11 = () => {
    Swal.fire({
      html:
        'For help selecting your correct tax rate, please click on this link to visit the ' +
        '<a href="https://www.ird.govt.nz/income-tax/withholding-taxes/resident-withholding-tax-rwt/using-the-right-rwt-tax-rate" target="_blank">IRD website</a> '
    });
  };

  const { go } = navigation;

  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });

  const saveandexit = () => {
    func();
  };

  function checkboxchange(e) {
    // const { name, value } = e.target;
  }
  formData.rwt = rwt;

  function Onprevious() {
    if (formData.investmentType === 'Company') {
      go('CompanyThird')
    } else if (formData.investmentType === 'Trust') {
      go('ThirdTrust')
    } else {
      go('Third');
    }
  }
  useEffect(() => {


    if (isPostalAdd1 === 0 || isPostalAdd1 === 'true') {
      formData.tinResidential = '';
      formData.tinNumber = '';
      formData.tinreason = '';
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formData.check === false) {

      if (formData.placeOfBirth === null || formData.placeOfBirth == undefined || formData.placeOfBirth === "") {
        $('.placeOfBirtherror').html('Please enter city/town were you born in')
      }
      if (formData.userIrdNumber === null || formData.userIrdNumber == undefined || formData.userIrdNumber === "") {
        $('.userIrdNumbers').html('Please enter your IRD number')
      }
      if (formData.rwt !== "Resident Withholding Tax" && formData.rwt !== "Non Resident Withholding Tax" && formData.rwt !== "AIL 2%") {
        $('.funderror').html('Please confirm your tax type')
      }
      if ((formData.rwtValue == null || formData.rwtValue == undefined || formData.rwtValue === "Select") && formData.rwt == "Resident Withholding Tax") {
        $('.rwterror').html('Please select tax rate')
      } if ((formData.examptProof === null || formData.examptProof === "" || formData.examptProof == undefined) && formData.rwt == "Resident Withholding Tax" && rwtValue === 'Exempt') {
        $('.exemptprooferror').html('Please attach proof of exemption')
      }


    }
  })
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  // let { document } = documentFile64;
  const datetime = new Date();
  const [errror, setErrror] = useState();

  let labeltxt = 'Please attach proof of exemption (maximum file size is 2MB).';
  const columnName = 'documentProof';
  const fileName = `examptProof`;
  const valuedefine = "exampt";

  let labeltxtird = 'Please attach proof of your IRD number';


  const [image, setImage] = useState();
  const downloadImage = (imageUrl) => {
    let postadv = {
      downloadUrl: imageUrl
    };
    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };

  function removeErrorOnlyOne(valueClass) {
    $(`.${valueClass}`).html('');

  }

  // alert(formData.lastName)
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2> Step 2: Personal Information - {' '}
            {(formData.fullName != null && formData.fullName != undefined && formData.fullName != "" ? formData.fullName : '')}

          </h2>
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          {queryValue ? <p></p> :
            <div
              disabled={formData.isPrimary == 1 ? false : true}
            >
              <div className="save-exit se_invff">
                <p className="save next" onClick={saveandexit}>
                  SAVE DATA{' '}
                </p>
                <img src={info} onClick={infodetls} alt="infoImage" className="info" />
              </div>
            </div>
          }
          <Row className="country__w">
            <Col md={12}>
              <label>
                {/* Place of birth */}
                What city/town were you born in?

              </label>
              <ItemForm
                autoComplete="off"
                type="text"
                className="quantity"
                onKeyPress={() => removeErrorOnlyOne("placeOfBirtherror")}
                name="placeOfBirth"
                value={placeOfBirth}
                onChange={(e) => {
                  setForm(e);
                  removeErrorOnlyOne("placeOfBirtherror");
                }}
                placeholder="Enter town/city of birth"
              />
              <span className="error placeOfBirtherror"></span>
            </Col>
            <Col className="FlagsSelectcss" md={12} >
              {' '}
              <label>
                {/* Country of birth */}
                What country were you born in?
              </label>
              <ReactFlagsSelect
                autoComplete="off"
                name="countryOfBirth"
                selected={selected}
                placeholder="Select"
                value={countryOfBirth}
                onChange={(e) => {
                  setForm(e);

                }}
                onSelect={(code) => setSelected(code)}
                countries={countrtyCode}
                defaultCountry={countryOfBirth}
              />
            </Col>
          </Row>
          <span className="error countryOfBirtherror"></span>

          <Row className="fullinput"></Row>

          <Row className=" mrgns">
            <div className="fullspace pandingfinsh block">
              <Col lg={12} className="FlagsSelectcss">
                <div>
                  <label>
                    {/* Country of residence */}
                    What country do you live in?
                  </label>
                  <ReactFlagsSelect
                    autoComplete="off"
                    name="residingCountry"
                    selected={selected1}
                    value={residingCountry}
                    placeholder="Select"
                    onChange={(e) => {
                      setForm(e);
                      // removeError();
                    }}
                    onSelect={(code) => setSelected1(code)}
                    countries={countrtyCode}
                    defaultCountry={
                      residingCountry && residingCountry !== null
                        ? getCode(residingCountry.toUpperCase())
                        : null
                    }
                  />

                  <span className="error residingCountry"></span>
                </div>
              </Col>
              <Col lg={12}>
                <label>
                  {/* Please enter your IRD number */}
                  What is your New Zealand IRD number?
                </label>
                <InputMask
                  autoComplete="off"
                  mask="999-999-999"
                  maskChar={null}
                  lassName="full"
                  type="text"
                  name="userIrdNumber"
                  value={userIrdNumber}
                  maskPlaceholder={null}
                  onKeyPress={() => removeErrorOnlyOne("userIrdNumbers")}
                  onChange={(e) => {
                    setForm(e);
                    removeErrorOnlyOne("userIrdNumbers");
                  }}
                  placeholder="xxx-xxx-xxx"
                />
                <span className="error userIrdNumbers"></span>
              </Col>
            </div>
           
              <Col>
                <UploadImageShareholder
                  buttonName='CLICK TO ATTACH'
                  formData={formData}
                  columnName={'userIrdNumberProof'}
                  fileName={'user_Ird_Number_Proof'}
                  labeltxt={labeltxtird}
                  valuedefine={"userIrdNumberProof"}
                />
                <span className="error userIrdNumberProof"></span>
              </Col>
            

          </Row>

          <Row className="">
            <Col xs={12}>
              <label className='d-inline'>What type of Resident Withholding Tax will you pay on your interest payments?
              </label>
              <img src={info} onClick={infodetls11} className="info myinfo trr" />
            </Col>
            <Col xs={12}>
              <Rwt
                autoComplete="off"
                type="text"
                className={
                  rwt === 'Select' ||
                    rwt === undefined ||
                    rwt === 'undefined' ||
                    rwt === '' ||
                    rwt === null
                    ? 'colorgrey'
                    : 'colorblack'
                }
                onClick={() => { removeErrorOnlyOne("funderror"); removeErrorOnlyOne("rwterror"); removeErrorOnlyOne('exemptprooferror'); }}
                onChange={(e) => {
                  setForm(e);
                  removeErrorOnlyOne("funderror");

                }}
                name="rwt"
                value={formData.rwt}
              />
              <span className="error funderror"></span>
            </Col>

            <Col xs={12}>
              {rwt === 'Resident Withholding Tax' ? (
                <Nrwt
                  type="text"
                  label="Select your  Resident Withholding Tax rate"
                  className={
                    rwtValue === 'Select' ||
                      rwtValue === undefined ||
                      rwtValue === '' ||
                      rwtValue === null
                      ? 'colorgrey'
                      : 'colorblack'
                  }
                  onClick={() => removeErrorOnlyOne("rwterror")}
                  onChange={(e) => {
                    setForm(e);
                    removeErrorOnlyOne("rwterror");

                  }}
                  name="rwtValue"
                  value={formData.rwtValue}
                />
              ) : rwt !== 'Resident Withholding Tax' && rwt !== 'AIL 2%' && rwt === 'Non Resident Withholding Tax' ? (
                <div className="checkbox-my nrdt_chack">
                  {' '}
                  <div className="checkmystyle">
                    {' '}
                    <>
                      <input
                        type="checkbox"
                        id="TickHere"
                        defaultChecked={checkboxx === true ? true : false}
                        name="checkboxx"
                        onChange={(e) => {
                          setForm(e);
                          checkboxchange(e);
                        }}
                      />
                      <span class="checkmark"></span>{' '}
                      <label for="TickHere">Tick here if you hold a valid Resident Withholding Tax exemption.</label>
                    </>
                  </div>{' '}
                </div>
              ) : (
                ''
              )}

              <span className="error rwterror"></span>

            </Col>
            {rwtValue === 'Exempt' && rwt == 'Resident Withholding Tax' ?

              <Col xs={12}>

                <UploadImageShareholder
                  formData={formData}
                  columnName={columnName}
                  fileName={fileName}
                  labeltxt={labeltxt}
                  buttonName='CLICK TO ATTACH'
                  valuedefine={valuedefine}
                />
              </Col>

              : ""
            }

            <Col xs={12}>
              <span className='exemptprooferror error'></span>

            </Col>
          </Row>


          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={Onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
            {/* <button type="submit" className="next flagsave" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Four;
