import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { audience, duvalapi } from '../../auth/Configuration';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

// material
import { alpha, styled } from '@mui/material/styles';
import { AppBar, Toolbar } from '@mui/material';
// components
// import { MHidden } from '../../components/@material-extend';
import logo from "../../pages/logo.png";
// ----------------------------------------------------------------------
const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
// ----------------------------------------------------------------------
DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar, }) {
  const {
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    logout
  } = useAuth0();

  sessionStorage.setItem("user", user)
  console.log("user.profile", user.profile)

  const userLogin = user.profile

  function check() {
    user.profile !== userLogin ? logout() : application();
  }
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
      console.log(accessToken)
    } catch (e) { }

    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      })

      if (
        accessidToken.__raw !== 'undefined' ||
        accessidToken.__raw !== null ||
        accessidToken.__raw !== undefined
      ) {
        sessionStorage.setItem("tokii", accessidToken.__raw)

        check();
      }

    } catch (e) { }

  };
  const [kkk, setKkk] = useState();
  useEffect(() => {
    getUserMetadata();
  });


  const application = async () => {
    let token = sessionStorage.getItem("tokii")
    if (isAuthenticated && user.email_verified === true) {
      const applicationdata1 = await axios
        .get(`${duvalapi}/user/details/undefined`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        .then((applicationdata1) => {
          setKkk(applicationdata1 && applicationdata1.data && applicationdata1.data[0] && applicationdata1.data[0].investorRelation ? applicationdata1.data[0].investorRelation : "")
          sessionStorage.setItem("investorRelation", applicationdata1.data[0].investorRelation)
        })
      console.log(applicationdata1)

    }
    else {
      Swal.fire({
        text: 'Please verify your email address before logging in to continue your application.',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
          logout()
        }
      })
    }
  };

  function abc() {
    Swal.fire({
      position: 'top-end',
      html: `If you have any questions or need assistance, please contact our Investments Team:<p> P: 0800 693276 <p/> E: investments@myfarm.co.nz`,
      showConfirmButton: false,
      customClass: 'blackkk',
      showCloseButton: true,
    });
  }
  return (
    isAuthenticated && user.email_verified === true ?
      <RootStyle className="Header__main">
        <ToolbarStyle>

          <div className="header_TP">
            <span>
              <img src={logo} alt="" /></span>
            <div>
              <button
                className="button bg-white contact_us"
                onClick={abc}
              >contact us</button>
            </div>
          </div>

        </ToolbarStyle>
      </RootStyle> : null
  );
}