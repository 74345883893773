import React, { useState, useEffect } from "react";
import leftarrow from "../leftarrow.png";
import rightarrow from "../rightarrow.png";
import download from "../download.png";
import ItemForm from "../ItemForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../logo.png";
import swal from "sweetalert";
import info from "../info.png";
import $ from "jquery";
const Jointtwentynine = ({
	setForm,
	formData,
	navigation,
	saveAndExist,
	infodetls,
}) => {
	const {} = formData;
	const { previous, next } = navigation;
	let JointAcount = sessionStorage.getItem("Jointacoount");
	saveAndExist = () => {
		formData.status = "PENDING";
		formData.step = "21";
		// swal({
		//   text: "Your application saved",
		// });
		go("submit");
	};
	infodetls = () => {
		swal({
			text:
				"By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
		});
	};
	useEffect(() => {
		$("#terms").scroll(function() {
			console.log($(this).scrollTop());
			if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
				$("#payment").removeAttr("disabled");
				$("#term_conditionTest1").val("true");
			}
		});
		$("#termss").scroll(function() {
			console.log($(this).scrollTop());
			if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
				$("#payments").removeAttr("disabled");
				$("#term_conditionTest2").val("true");
			}
		});
		// $(document).ready(function () {
		//   alert(payment)
		//   let payment = "term";
		//   $(".yesno").click(function () {
		//     payment = $(this).val();
		//     alert(payment)
		//   });
		// });

		//   $(document).ready(function(){
		//     $('input[type="checkbox"]').click(function(){
		//         if($(this).prop("checked") == true){
		//             // $(".error").html("");
		//         }
		//         else if($(this).prop("checked") == false){
		//             // $("#result").html("Checkbox is unchecked.");
		//         }
		//     });
		// });

		$("#payment").click(function() {
			let test = $("#term_conditionTest1").val();
			if (
				$("#terms").scrollTop() + $("#terms").innerHeight() >=
					$("#terms")[0].scrollHeight ||
				test === "true"
			) {
				return true;
			} else {
				// $(".result").html(
				// 	"Please read and review terms and conditions to proceed. You will need to scroll to the bottom before you can accept."
				// );
				// return false;
				return true;
			}
		});
		$("#payments").click(function() {
			let test = $("#term_conditionTest2").val();
			if (
				$("#termss").scrollTop() + $("#termss").innerHeight() >=
					$("#termss")[0].scrollHeight ||
				test === "true"
			) {
				return true;
			} else {
				// $(".results").html(
				// 	"Please read and review privacy policy to proceed. You will need to scroll to the bottom before you can accept."
				// );
				// return false;
				return true;
			}
		});
	});
	function handleClick(e) {
		// $('.flirt').prop("checked") == true;
		// if ($(".flirt").prop("checked") == false) {
		// 	$(".result").html(
		// 		"Please read and review terms and conditions to proceed. You will need to scroll to the bottom before you can accept."
		// 	);
		// } else if ($(".flirts").prop("checked") == false) {
		// 	next()
		// 	// $(".results").html(
		// 	// 	"Please read and review privacy policy to proceed. You will need to scroll to the bottom before you can accept."
		// 	// );
		// } else {
		// 	if (formData.entity === "Company") {
		// 		go("CompanyTwentyEight");
		// 	} else {
		// 		next();
		// 	}
		// }
		next();
	}
	function removeError() {
		$(".error").html("");
	}
	const { go } = navigation;
	return (
		<div className="main_container">
		<div className="form terms_cond_main">
			<img src={logo} alt="img" className="imageCover" />
			{!JointAcount ? (
				<>
					<div className="save-exit">
						<p className="save" onClick={saveAndExist}>
							Save & exit{" "}
						</p>
						<img src={info} onClick={infodetls} className="info" />
					</div>
				</>
			) : null}
			<h3>Terms and conditions</h3>
			<div className="grid-container">
				<div className="grid-item "></div>
				<div className="grid-item"></div>
				<div className="grid-item "></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item"></div>
				<div className="grid-item active"></div>
			</div>
			<p className="policy_font">Please read and accept the below Terms and Conditions and Privacy
					Policy</p>
			<p>You will need to scroll to the bottom before you can accept.</p>
			 
			<div className="usual back-color terms_cond" id="terms">
				<li>I/We confirm that:</li>
				<ul>
					<li> a) I/We have received a copy of the Information Memorandum</li>
					<li>
						b) Investment decisions are very important, and it has been made
						clear to me/us that I/we am/are free to take such other professional
						advice as I/we feel necessary. I/We have been provided with all the
						relevant information I/we required to make the investment decision
						and I/we have taken any advice that I/we think appropriate.
					</li>
					<li>
						c) I/we acknowledge and accept the Disclaimers and Declarations of
						Interest as set out on page 3 of the Information Memorandum.
					</li>
					<li>
						d) I/We confirm that I/we am/are, or the entity, which takes up the
						investment, is/are able to make that payment. I/we acknowledge and
						agree that I/we will become bound as a limited partner under the
						Limited Partnership Agreement, as set out in the Information
						Memorandum.
					</li>
					<li>
						e) I/We acknowledge that my/our completed application once submitted
						to MyFarm, cannot be withdrawn without authorisation by MyFarm.
					</li>
					<li>
						f) I/We accept that if I/we do not make full payment by the due date
						advised I/we will be charged and interest will accrue at 13% (or
						such greater rate as specified in any relevant contract for which
						the funds are required) on all outstanding funds from the due date
						until payment or otherwise.
					</li>
					<li>
						g) Under the terms of the Unsolicited Electronic Messages Act 2007,
						I/we provide my/our consent to receiving commercial electronic
						messages for the purpose of the Act. I/We acknowledge that Sharp
						Tudhope does not act for me/us in connection with this investment.
					</li>
				</ul>
				<li>
					Anti Money Laundering (AML) authority I/we agree to co-operate with
					MyFarm and the Partnership in complying with any and all of their
					obligations relating to the AML and any corresponding regulations
					including but not limited to providing them with such further
					information that they may require in order to discharge their
					obligations under the AML. I/We consent to the disclosure of the
					information contained in this Application (and of any further
					information that may be required by MyFarm and/or the Partnership) to
					third parties for that purpose. MyFarm reserved the right to reject
					any Application without completed AML requirements. Declaration and
					signature I/We hereby apply for the number of units shown above and
					agree to accept such Units on and subject to the terms and conditions
					set out in the dated , and on the terms set out in the Application
					instructions. I/We understand that and MyFarm Limited will hold
					personal information in respect of me/us in relation to my/our
					investment in . I/We understand that I/we may request to see and, if
					necessary, request the correction of the personal information. I/We
					declare that that all the details and statements made by me/us in this
					Application Form are compete and accurate.
				</li>
			</div>
			<Row>
				<Col md={1}>
					<a
						href="https://myfarm-dev-s3.s3-ap-southeast-2.amazonaws.com/upload-file/Terms+and+Conditions.pdf"
						target="blank"
					>
						{" "}
						<img src={download} className="download" />
					</a>
				</Col>
				<Col md={11}>
					<label>Download Client Terms and Conditions</label>
				</Col>
			</Row>
			<div className="checkbox-my left">
				<label className="containers">
					I have read and agree to the Terms and Conditions
					<input
						type="checkbox"
						id="payment"
						className="flirt"
						onClick={removeError}
						name="terms"
					/>
					<input
						type="hidden"
						class=""
						value="false"
						id="term_conditionTest1"
					/>
					<input
						type="hidden"
						class=""
						value="false"
						id="term_conditionTest2"
					/>
					<span className="checkmark"></span>
					<div className="error result position-na"></div>
				</label>
			</div>
			<div className="usual back-color" id="termss">
				<li>Privacy Policy:</li>
				<ul>
					<li> a) I/We have received a copy of the Information Memorandum</li>
					<li>
						b) Investment decisions are very important, and it has been made
						clear to me/us that I/we am/are free to take such other professional
						advice as I/we feel necessary. I/We have been provided with all the
						relevant information I/we required to make the investment decision
						and I/we have taken any advice that I/we think appropriate.
					</li>
					<li>
						c) I/we acknowledge and accept the Disclaimers and Declarations of
						Interest as set out on page 3 of the Information Memorandum.
					</li>
					<li>
						d) I/We confirm that I/we am/are, or the entity, which takes up the
						investment, is/are able to make that payment. I/we acknowledge and
						agree that I/we will become bound as a limited partner under the
						Limited Partnership Agreement, as set out in the Information
						Memorandum.
					</li>
					<li>
						e) I/We acknowledge that my/our completed application once submitted
						to MyFarm, cannot be withdrawn without authorisation by MyFarm.
					</li>
					<li>
						f) I/We accept that if I/we do not make full payment by the due date
						advised I/we will be charged and interest will accrue at 13% (or
						such greater rate as specified in any relevant contract for which
						the funds are required) on all outstanding funds from the due date
						until payment or otherwise.
					</li>
					<li>
						g) Under the terms of the Unsolicited Electronic Messages Act 2007,
						I/we provide my/our consent to receiving commercial electronic
						messages for the purpose of the Act. I/We acknowledge that Sharp
						Tudhope does not act for me/us in connection with this investment.
					</li>
				</ul>
				<li>
					Anti Money Laundering (AML) authority I/we agree to co-operate with
					MyFarm and the Partnership in complying with any and all of their
					obligations relating to the AML and any corresponding regulations
					including but not limited to providing them with such further
					information that they may require in order to discharge their
					obligations under the AML. I/We consent to the disclosure of the
					information contained in this Application (and of any further
					information that may be required by MyFarm and/or the Partnership) to
					third parties for that purpose. MyFarm reserved the right to reject
					any Application without completed AML requirements. Declaration and
					signature I/We hereby apply for the number of units shown above and
					agree to accept such Units on and subject to the terms and conditions
					set out in the dated , and on the terms set out in the Application
					instructions. I/We understand that and MyFarm Limited will hold
					personal information in respect of me/us in relation to my/our
					investment in . I/We understand that I/we may request to see and, if
					necessary, request the correction of the personal information. I/We
					declare that that all the details and statements made by me/us in this
					Application Form are compete and accurate.
				</li>
			</div>

			{/* <Row>
        <Col md={1}>
          <a href="https://myfarm-dev-s3.s3-ap-southeast-2.amazonaws.com/upload-file/Privacy+Policy.pdf" target="blank"> <img src={download} className="download" /></a>
        </Col>
        <Col md={11}>
          <label>Download Client Privacy Policy</label>
        </Col>


      </Row> */}
			<Row>
				<Col>
					<div className="checkbox-my left">
						<label className="containers">
							I have read and agree to the Privacy Policy
							<input
								type="checkbox"
								id="payments"
								onClick={removeError}
								className="flirts"
								value="term"
								name="terms"
							/>
							<span className="checkmark"></span>
							<div className="error results position-na"></div>
						</label>
					</div>
				</Col>
			</Row>

			<div className="fotter-btn">
				<button className="preview" onClick={previous}>
					<img src={leftarrow} className="leftarrow" alt=""/>
					Previous
				</button>
				<button type="submit" className="next setwidht" onClick={handleClick}>
					Submit Application
					<img src={rightarrow} className="rightarrow"  alt=""/>{" "}
				</button>
			</div>
		</div>
		</div>
	);
};

export default Jointtwentynine;
