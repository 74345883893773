import React from 'react';

const states = [
  ['Select', 'Select'],
  ['Chartered Accountant', 'Chartered Accountant'],
  ['Lawyer', 'Lawyer'],
  ['Financial Adviser', 'Financial Adviser']
];

const TypeOfPerson = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others}>
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default TypeOfPerson;
