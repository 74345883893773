import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import AxiosInstance from '../axios';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import swal from 'sweetalert';
import $ from 'jquery';
import info from '../info.png';
import logo from '../logo.png';
import SignaturePad from 'react-signature-pad-wrapper';
import MenuSidebar from './MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import logoBlack from '../logoBlack.png';
import Option from './Option';
import { infodetls } from '../Individual/SaveInfo';



// var SignaturePad = require("react-signature-pad");

const Seventeen = ({ func, setForm, formData, navigation, saveAndExist, step16, }) => {
  const {
    secondholder,
    idType1,
    middleName1,
    lastName1,
    licenceNumbersecond,
    firstName1,
    versionNumbersecond,
    documentCountryOfIssue1,
    passportCountryOfIssuesecond,
    passportNumbersecond,
    documentExpiryDatesecond,
    documentType1,
    documentNumber1,
    acquiringDisposingFinancialProductsOther,
    OptionFundCF33,
    OptionFundCF34
  } = formData;
  const [namefull1, setNamefull1] = useState(secondholder ? secondholder.split(' ') : '');
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];
  const queryValue = sessionStorage.getItem("queryValue");

  formData.firstName1 = namefull1[0];

  if (namefull1.length > 1) {
    formData.lastName1 = namefull1[namefull1.length - 1];
  }

  if (namefull1.length > 2) {
    formData.middleName1 = namefull1
      .slice(1, namefull1.length - 1)
      .join()
      .replace(/,/g, ' ');
  }

  const [userapplication, setUserapplication] = useState([]);
  const mySignatureRefB = useRef(null);
  const mySignatureRefB1 = useRef(null);

  useEffect(() => {
    formData.signatureUrl = '';
  }, []);

  const [imageLoader, setImageLoader] = useState();

  const saveandexit = () => {
    func();
  };
  const { Syndication, fullName, investmentProductExp } = formData;
  const { previous, next } = navigation;
  useEffect(() => {
    $(document).ready(function () {
      var date = new Date();

      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate').text(val);
      formData.certificateB_Date = val;
    });
    $('.set-previuos').click(function () {
      localStorage.setItem('step16', 'step16');

      if (step16) {
        go('Sixteen');
      } else {
        go('Sixteen');
      }
    });
  });

  const Clear = () => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);

    const setValues = localStorage.getItem('setValues');
    let signatureC = mySignatureRefB.current.clear();

    formData.signatureC = signatureC;

    //    let data = {
    //     columnName: 'documentProof',
    //     fileName:"documentProof",
    //     document:signatureA
    //   };
  };

  const Clear1 = () => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);

    const setValues = localStorage.getItem('setValues');
    let secSignatureC = mySignatureRefB1.current.clear();

    formData.secSignatureC = secSignatureC;

    //    let data = {
    //     columnName: 'documentProof',
    //     fileName:"documentProof",
    //     document:signatureA
    //   };
  };
  useEffect(() => {
    if (formData.secSignatureC != "" && formData.secSignatureC != null && formData.secSignatureC != undefined) {
      Clear1();
    }

  }, []);
  useEffect(() => {
    if (formData.signatureC != "" && formData.signatureC != null && formData.signatureC != undefined) {
      Clear();
    }

  }, []);

  const { go } = navigation;
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '17';
    // swal({
    //   text: "Your application saved",
    // });
    go('submit');
  };

  const hadnleclick22 = () => {
    next();
  };

  function secondarysignjoint() {
    let secSignatureC = mySignatureRefB1.current.toDataURL();
    let isEmpty = mySignatureRefB1.current.isEmpty();
    const setValues = localStorage.getItem('setValues');
    // formData.secSignatureC = secSignatureC;
    // formData.secSignatureCUrl = secSignatureC;

    let datajoint = {
      columnName: 'signatureA',
      fileName: 'secSignatureB',
      document: secSignatureC,
      investmentType: formData.investmentType
    };
    // alert(isEmpty);
    // alert(signatureC);

    setImageLoader(true);

    if (setValues === 'Yes') {
      axios
        .post(`${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, datajoint, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          if (res.status === 200) {
            setImageLoader(false);
            formData.secSignatureCUrl = res.data.url;
            formData.secSignatureC = res.data.url;
            next();
          } else {
            setImageLoader(false);
          }
        });
    } else {
      localStorage.setItem('Step20', 'Step20');
      setImageLoader(true);

      axios
        .post(`${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, datajoint, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          if (res.status === 200) {
            formData.secSignatureCUrl = res.data.url;
            formData.secSignatureC = res.data.url;
            next();

            setImageLoader(false);
          }
        });
    }
  }

  async function signature() {
    if (formData.isPrimary == 0) {
      if (queryValue == null || queryValue == undefined || queryValue == "") {
        formData.class = 'Eighteen';
        go('Eighteen');
      }
      else {
        formData.class = 'Seventeen';
        go('Seventeen');
      }
    }
    if (formData.isPrimary == 1) {
      formData.certificateNumber = 3;
      let signatureC = mySignatureRefB.current.toDataURL();
      let isEmpty = mySignatureRefB.current.isEmpty();
      const setValues = localStorage.getItem('setValues');
      // formData.signatureC = signatureC;
      // formData.signatureUrlB = signatureC;
      let isEmpty1;
      if (formData.investmentType === 'Joint') {
        isEmpty1 = mySignatureRefB1.current.isEmpty();
      }
      let data = {
        columnName: 'signatureA',
        fileName: formData.investmentType === 'Joint' ? 'priSignatureB' :
          formData.investmentType === 'Company' ? 'signatureA' : 'signatureA',
        document: signatureC,
        investmentType: formData.investmentType
      };

      function removeError() {
        $('.error').html('');
      }

      if (isEmpty) {
        $('.signError').html('Please sign');

      }

      else if (formData.investmentType === 'Joint' && isEmpty1) {
        $('.signError1').html('Please sign');
      }
      else {
        setImageLoader(true);

        if (setValues === 'Yes') {
          axios
            .post(`${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, data, {
              headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
              formData.signatureUrlB = res.data.url;
              formData.signatureC = res.data.url;
              formData.result = res.status;
              if (res.status === 200) {
                setImageLoader(false);
                formData.CertificationValue = true;
                if (formData.investmentType === 'Joint') {
                  secondarysignjoint();
                } else {
                  next();
                }
              } else {
                setImageLoader(false);
                formData.CertificationValue = true;
              }
            });
          // go("Twentyone");
        } else {
          localStorage.setItem('Step20', 'Step20');
          setImageLoader(true);

          axios
            .post(`${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, data, {
              headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
              formData.signatureUrlB = res.data.url;
              formData.signatureC = res.data.url;

              if (res.status === 200) {
                setImageLoader(false);
                formData.CertificationValue = true;
                if (formData.investmentType === 'Joint') {
                  secondarysignjoint();
                } else {
                  next();
                }
              } else {
                formData.CertificationValue = true;
              }
            });
        }
      }
    }

  }


  const handleClick = (() => {
    signature();
  })
  // const handleClick = () => {
  //   if (formData.investmentType === 'Joint') {
  //     if (OptionFundCF33 === 'Select' ||
  //       OptionFundCF33 === undefined ||
  //       OptionFundCF33 === '' ||
  //       OptionFundCF33 === null) {
  //       $('.OptionFundCF33').html('Please select your role');
  //     } else if (OptionFundCF34 === 'Select' ||
  //       OptionFundCF34 === undefined ||
  //       OptionFundCF34 === '' ||
  //       OptionFundCF34 === null) {
  //       $('.OptionFundCF34').html('Please select your role');
  //     } else {
  //       signature();
  //     }
  //   } else if (OptionFundCF33 === 'Select' ||
  //     OptionFundCF33 === undefined ||
  //     OptionFundCF33 === '' ||
  //     OptionFundCF33 === null) {
  //     $('.OptionFundCF33').html('Please select your role');
  //   } else {
  //     signature();
  //   }
  // }
  function removeError() {
    $('.error').html('');
  }


  useEffect(() => {
    formData.signatureA1Url = null;
    formData.signatureA1 = null;
    formData.signatureUrl = null;
    formData.signatureA = null;
    formData.secSignatureCUrl = null;
    formData.secSignatureC = null;
    formData.signatureUrlB = null;
    formData.signatureC = null;
    formData.certificateNumber = 1;
    formData.certificateNumber = 2;

  }, []);

  var today = new Date();
  var date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Wholesale Investor Certificate B</h2> :
              <h2>Step 5: Wholesale Investor Certificate B</h2>
          }
          {/* <h2> Wholesale Investor Certificate 3</h2> */}
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" alt="info" />
          </div>

          <div className="wholesale_content_header">
            <div className="logo_Wholesale">
              <img src={logoBlack} alt="logoBlack" className="logobLACK" />
              <p>{/* <b>(1/2)</b> */}</p>
            </div>
            <div className="content">
              <b>
                {' '}
                <h3 className="Wholesale_headering">Wholesale Investor Certificate B</h3>
              </b>

              <h4 className="jkdjk">Eligible Investor Certificate</h4>
              {/* <p className="Wholesale__Para">
                (Clause 41 of Schedule 1 and Clause 4 of Schedule 5 to the Financial Markets Conduct
                Act 2013){' '}
              </p> */}
            </div>
          </div>
          <div className="Wholesale__Investor__Conetnt">
            <p className="Bold_warning">Warning</p>
            <p>
              {' '}
              {/* New Zealand law normally requires people who offer financial products* to give
              information to investors before they invest. This information is designed to help
              investors make an informed decision. */}
              The law normally requires people who offer financial products to give information to investors before they invest. This information is designed to help investors make an informed decision.
            </p>

            <p>
              {' '}
              {/* If you give this certificate, the usual rules do not apply to offers of financial
              products made to you. As a result, you may not receive a complete and balanced set of
              information. You will also have fewer other legal protections for these investments. */}
              If you are a wholesale investor, the usual rules do not apply to offers of financial products made to you. As a result, you may not receive a complete and balanced set of information. You will also have fewer other legal protections for these investments.
              {' '}
            </p>

            <p> Ask questions, read all documents carefully, and seek independent financial advice before committing yourself.</p>
            {/* <p>
              Ask questions, read all documents carefully, and seek independent financial advice
              before committing yourself.
            </p> */}
            <p className="Bold_warning">Offence</p>
            <p>
              It is an offence to give a certificate knowing that it is false or misleading in a
              material particular. The offence has a penalty of a fine not exceeding $50,000.
            </p>
          </div>
          {/* <div className="italic_text">
            <i>
              *Financial product is defined in the Financial Markets Conduct Act 2013 and means a
              debt security, equity security, managed investment product or a derivative. It does
              not include an asset such as a house or any associated borrowing required to purchase
              a house. Please refer to sections 7 to 10 of the Financial Markets Conduct Act 2013
              for further information.
            </i>
          </div> */}



          {/* <h5> Offer of financial products</h5> */}
          {/* <ol start="1" className="orderAlignment">
            <li className="orderListAlignment">
              <p>
                This certificate relates to offer for the subscription of units in a limited
                partnership, being the Fund, which is managed by MyFarm Investments as
                either Fund Manager and/or General Partner, and any financial advice service to be
                provided in relation to that offer.{' '}
              </p>
            </li>
          </ol>
          <p>
            <ol start="2" className="orderAlignment">
              <li className="orderListAlignment">
                I/We{' '}
                <b className="textCapitalize">
                  {
                    formData.investmentType === 'Company' ?
                      <>
                        {formData.companyName}
                      </> : <>
                        {formData.investmentType === 'Joint' ?
                          formData.fullName + " and " + formData.secondholder : formData.fullName}</>
                  }

                </b>{' '}
                certify to the Fund and to MyFarm Investments that I/We have previous
                experience in acquiring or disposing of financial products that allows me to asses:
              </li>
            </ol>
          </p>
          <ul className="usuals">
            <ol>
              <p className="orderListAlignment">
                2.1. The merits of this offer and any financial advice service to be provided
                (including
                <p className="spacebtween">
                  assessing the value and the risks of the financial products involved);
                </p>
              </p>
              <p className="orderListAlignment">
                2.2. My own information needs in relation to this offer and any financial advice
                service
                <p className="spacebtween"> to be provided; and </p>
              </p>
              <p className="orderListAlignment">
                2.3. The adequacy of the information provided by any person involved in this offer
                and
                <p className="spacebtween">any financial advice service to be provided.</p>
              </p>
            </ol>
          </ul> */}
          <ol start="" className="orderAlignment">
            <li className="orderListAlignment">
              {/* <p>My grounds for the above statement are (select the ground(s) that apply to you from the following list): </p> */}
              <p>The grounds for this certification are:</p>
            </li>
          </ol>

          <div className="mainfetch-div">
            <div className="Investor-wrapper">
              <label>
                {/* <b>Eligible Investor</b> */}
              </label>

              <div className="checkbox-my">

                <label className="containers restContainer  font-fami">{
                  formData.startupOrBusiness == true ?
                    <b>I/We previously invested in early stage, start up, or established businesses.</b> :
                    <p>I/We previously invested in early stage, start up, or established businesses.</p>
                }
                  <input
                    type="checkbox"
                    name="startupOrBusiness"
                    checked={formData.startupOrBusiness == true ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>


                <label className="containers restContainer  font-fami">{
                  formData.investorPresentation == true ?
                    <b>In the course of it’s investment activities, I/We have been involved in investor
                      presentations and/or due diligence meetings and through this have gained an
                      appreciation for the risks and opportunities associated with investing in businesses.</b> :
                    <p>In the course of it’s investment activities, I/We have been involved in investor
                      presentations and/or due diligence meetings and through this have gained an
                      appreciation for the risks and opportunities associated with investing in businesses.</p>
                }
                  <input
                    type="checkbox"
                    name="investorPresentation"
                    checked={formData.investorPresentation == true ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="containers restContainer  font-fami">{
                  formData.capitalRaisingDirector == true ?
                    <b> I/We have served as a Director of a business that was involved in capital raising.</b> :
                    <p> I/We have served as a Director of a business that was involved in capital raising.</p>
                }

                  <input
                    type="checkbox"
                    name="capitalRaisingDirector"
                    checked={formData.capitalRaisingDirector == true ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="containers restContainer  font-fami">{
                  formData.investingSharesSecurities == true ?
                    <b>I/We have experience in investing in shares and other securities (listed and unlisted)
                      and understand the risks with investing in different types of financial products.</b> :
                    <p>I/We have experience in investing in shares and other securities (listed and unlisted)
                      and understand the risks with investing in different types of financial products.</p>
                }
                  <input
                    type="checkbox"
                    name="investingSharesSecurities"
                    checked={formData.investingSharesSecurities == true ? true : false}

                  />
                  <span className="checkmark"></span>
                </label>


                <label className="containers restContainer  font-fami">{
                  formData.myOwnInvestmentDecisions == true ?
                    <b> I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                      benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                    </b> :
                    <p> I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the
                      benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.
                    </p>
                }
                  <input
                    type="checkbox"
                    name="valueAndRisksOfInvesting"
                    disabled={true}
                    checked={formData.myOwnInvestmentDecisions == true ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="containers restContainer  font-fami">{
                  formData.valueAndRisksOfInvesting == true ?
                    <b>In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                      this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                    </b> :
                    <p>In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and
                      this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.
                    </p>
                }
                  <input
                    type="checkbox"
                    name="valueAndRisksOfInvesting"
                    disabled={true}
                    checked={formData.valueAndRisksOfInvesting == true ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="containers restContainer  font-fami">{
                  formData.acquiringDisposingFinancialProducts == true ?
                    <b>I/We have other relevant experience of acquiring or disposing of financial products,
                      details being the following.</b> :
                    <p>I/We have other relevant experience of acquiring or disposing of financial products,
                      details being the following.</p>
                }
                  <input
                    type="checkbox"
                    // name="valueAndRisksOfInvesting"
                    disabled={true}
                    checked={formData.acquiringDisposingFinancialProducts == true ? true : false}

                  />
                  <span className="checkmark"></span>
                </label>
                <span className="error investmentProductExp"></span>
                {formData.acquiringDisposingFinancialProducts == true ?
                  <div className="fullspace pandingfinsh Other">
                    <textarea
                      className="mouse otherInput"
                      // name="acquiringDisposingFinancialProductsOther"
                      disabled={true}
                      value={acquiringDisposingFinancialProductsOther}
                      type="text"
                      placeholder="Please provide details"

                    ></textarea>
                  </div> : null}

              </div>


              {/* <p><b>{formData.startupOrBusiness === true?" I/We previously invested in early stage, start up, or established businesses":""}</b></p>
              <p><b>{formData.investorPresentation === true?"In the course of it’s investment activities, I/We have been involved in investor presentations and/or due diligence meetings and through this have gained an appreciation for the risks and opportunities associated with investing in businesses":""}</b></p>
              <p><b>{formData.myOwnInvestmentDecisions === true?"I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio":""}</b></p>
              <p><b>{formData.valueAndRisksOfInvesting === true?"In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products":""}</b></p>            
              <p><b>{formData.capitalRaisingDirector === true?"I/We have served as a Director of a business that was involved in capital raising":""}</b></p>
              <p><b>{formData.investingSharesSecurities === true?" I/We have experience in investing in shares and other securities (listed and unlisted) and understand the risks with investing in different types of financial products.":""}</b></p>
              <p><b>{formData.acquiringDisposingFinancialProducts === true?"I/We have other relevant experience of acquiring or disposing of financial products, details being the following.":""}</b></p>
             */}
            </div>
            {/* <div class="">
                <p><b className='ALIGNTEXTTT'>{formData.acquiringDisposingFinancialProducts === true?formData.acquiringDisposingFinancialProductsOther:""}</b></p>
              </div> */}
          </div>

          {/* <div className="d-flex">
            <p>
              {' '}
              <b>Dated:</b>
            </p>{' '}
            <b>
              <p id="printDate" />
            </b>
          </div> */}

          <div className="signupButton">{/* <b>Please click to read this warning</b> */}</div>
          {/* <div className="">
            <p>
              <ol start="4" className="orderAlignment">
                <li className="orderListAlignmentfont-fami ">
                  I/We certify that I/We understand that by providing the above certification, I am
                  certifying myself to be an “eligible investor” (as defined in clause 3(3)(a) of
                  Schedule 1 to the Financial Markets Conduct Act 2013). I/We understand that the
                  consequences of certifying that I am an eligible investor are that:
                  <ol className="orderAlignment spacetop">
                    <p className="orderListAlignment font-fami">
                      4.1. The usual set of rules do not apply to offers of financial products made
                      to me
                      <p className="spacebtween font-fami">
                        {' '}
                        and, as a result, I may not receive a complete and balanced set of
                        information and I will have fewer legal protections for these investments;
                        and
                      </p>
                    </p>
                    <p className="orderListAlignment font-fami">
                      4.2. The competency standards and requirements of the code of conduct for
                      financial
                      <p className="spacebtween font-fami">
                        {' '}
                        advice services will not be applicable and my adviser may not be a member of
                        an approved dispute resolution scheme.
                      </p>
                    </p>
                  </ol>
                </li>
              </ol>
            </p>
          </div> */}
          {/* <br /> */}
          <div onClick={removeError}>
            <div className="upper-space-table">
              <p>
                {' '}
                <b>Dated: {date}</b>
              </p>{' '}
              <table className="behalf-sign">
                <tr>
                  <td className="term-Signed ">Signature of applicant:</td>
                  <td className="term-entity term-entityss ">
                    {(formData.firstNameindi !== null &&
                      formData.firstNameindi !== '' &&
                      formData.firstNameindi !== 'undefined' &&
                      formData.firstNameindi !== undefined
                      ? formData.firstNameindi
                      : formData.firstName !== null &&
                        formData.firstName !== '' &&
                        formData.firstName !== 'undefined' &&
                        formData.firstName !== undefined
                        ? formData.firstName
                        : '') +
                      ' ' +
                      (formData.middleNameindi !== null &&
                        formData.middleNameindi !== '' &&
                        formData.middleNameindi !== 'undefined' &&
                        formData.middleNameindi !== undefined
                        ? formData.middleNameindi
                        : formData.middleName !== null &&
                          formData.middleName !== '' &&
                          formData.middleName !== 'undefined' &&
                          formData.middleName !== undefined
                          ? formData.middleName + ' '
                          : '') +
                      '' +
                      (formData.lastNameindi !== null &&
                        formData.lastNameindi !== '' &&
                        formData.lastNameindi !== 'undefined' &&
                        formData.lastNameindi !== undefined
                        ? ' ' + formData.lastNameindi
                        : formData.lastName !== null &&
                          formData.lastName !== '' &&
                          formData.lastName !== 'undefined' &&
                          formData.lastName !== undefined
                          ? ' ' + formData.lastName
                          : '')}
                  </td>
                  {/* <td className="term-investor">
                    <p className="round-EXECUTED paddingNone">
                      <Option
                        // disabled={disCheck}
                        type="text"
                        autoComplete="off"
                        className={
                          OptionFundCF33 === 'Select' ||
                            OptionFundCF33 === undefined ||
                            OptionFundCF33 === '' ||
                            OptionFundCF33 === null
                            ? 'colorgrey'
                            : 'colorblack'
                        }
                        onClick={removeError}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                        name="OptionFundCF33"
                        value={OptionFundCF33}
                      />

                    </p>


                  </td> */}

                  {/* <td className="term-investor">as Investor:</td> */}
                </tr>
              </table>
              <div><span className="error errercolor OptionFundCF33"></span></div>
            </div>
            {formData.isPrimary == 1 ?
              <div className="second-main-wrapper">
                <div
                //  className='dis_sign' 
                >

                  <SignaturePad
                    className="signature_h sigCanvas"
                    clearButton="true"
                    ref={mySignatureRefB}
                  // ref={(ref) => {
                  //   setSignaturePad(ref)
                  // }}
                  />

                  <br></br>
                  <span className="error error1 signError"></span>
                  <button onClick={Clear}>Clear</button>
                  <br />
                </div>
              </div>
              :
              <img alt='ee' className='signature-img' src={formData.downsignatureA} />}
            {/* <div className="second-main-wrapper">
              <div
              //  className='dis_sign' 
              >

                <SignaturePad
                  className="signature_h sigCanvas"
                  clearButton="true"
                  ref={mySignatureRefB}
                // ref={(ref) => {
                //   setSignaturePad(ref)
                // }}
                />

                <br></br>
                <span className="error error1 signError"></span>
                <button onClick={Clear}>Clear</button>
                <br />
              </div>
            </div> */}

          </div>

          <div>
            {formData.investmentType === 'Joint' ? (
              <>
                {' '}
                <div onClick={removeError}>
                  {/* <br /> */}
                  <div className="upper-space-table">
                    <table className="behalf-sign">
                      <tr>
                        <td className="term-Signed">Signature of applicant:</td>
                        <td className="term-entity">
                          {formData.investmentType === 'Joint'
                            ? (formData.firstNameJoint !== null &&
                              formData.firstNameJoint !== '' &&
                              formData.firstNameJoint !== 'undefined' &&
                              formData.firstNameJoint !== undefined
                              ? formData.firstNameJoint
                              : formData.firstName1 !== null &&
                                formData.firstName1 !== '' &&
                                formData.firstName1 !== 'undefined' &&
                                formData.firstName1 !== undefined
                                ? formData.firstName1
                                : '') +
                            ' ' +
                            (formData.middleNamejoint !== null &&
                              formData.middleNamejoint !== '' &&
                              formData.middleNamejoint !== 'undefined' &&
                              formData.middleNamejoint !== undefined
                              ? formData.middleNamejoint
                              : formData.middleName1 !== null &&
                                formData.middleName1 !== '' &&
                                formData.middleName1 !== 'undefined' &&
                                formData.middleName1 !== undefined
                                ? formData.middleName1 + ' '
                                : '') +
                            '' +
                            (formData.lastNamejoint !== null &&
                              formData.lastNamejoint !== '' &&
                              formData.lastNamejoint !== 'undefined' &&
                              formData.lastNamejoint !== undefined
                              ? ' ' + formData.lastNamejoint
                              : formData.lastName1 !== null &&
                                formData.lastName1 !== '' &&
                                formData.lastName1 !== 'undefined' &&
                                formData.lastName1 !== undefined
                                ? ' ' + formData.lastName1
                                : '')
                            : ''}
                        </td>
                        {/* <td className="term-investor">
                          <p className="round-EXECUTED paddingNone">
                            <Option
                              // disabled={disCheck}
                              type="text"
                              autoComplete="off"
                              className={
                                OptionFundCF34 === 'Select' ||
                                  OptionFundCF34 === undefined ||
                                  OptionFundCF34 === '' ||
                                  OptionFundCF34 === null
                                  ? 'colorgrey'
                                  : 'colorblack'
                              }
                              onClick={removeError}
                              onChange={(e) => {
                                setForm(e);
                                removeError();
                              }}
                              name="OptionFundCF34"
                              value={OptionFundCF34}
                            />
                          </p>


                        </td> */}

                      </tr>
                    </table>
                    <div> <span className="error errercolor  OptionFundCF34"></span></div>

                  </div>
                  <div className="second-main-wrapper">
                    <div
                    // className='dis_sign' 
                    >
                      <SignaturePad
                        className="signature_h"
                        clearButton="true"
                        id="signature-pad-canvas"
                        ref={mySignatureRefB1}
                      />

                      <br></br>
                      <span className="error error1 signError1"></span>
                      <button onClick={Clear1}>Clear</button>

                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <br />
          <div className="fotter-btn">
            <button className="preview set-previuos">
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>

            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : formData.signatureUrlB !== null &&
              formData.signatureUrlB !== '' &&
              formData.signatureUrlB !== 'undefined' &&
              formData.signatureUrlB !== undefined ? (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Seventeen;
