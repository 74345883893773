import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import info from '../info.png';
import swal from 'sweetalert';
import ReactFlagsSelect from 'react-flags-select';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import $, { now } from 'jquery';
import InputMask from 'react-input-mask';
import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import UploadImage from '../Individual/UploadImage';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';


const EntityOne = ({
    func,
    setForm,
    formData,
    navigation,
    saveAndExist,

}) => {

    const {
        companyName,
        companyNumber,
        companyTradingName,
        entityCompanyOne,
        otherCountryCompanyRegistered,
        companySecCountryOfResidence,
        fullLegalCompanyName,
        secFullLegalCompanyName,
        // companyRegisterAddress,
    } = formData;

    const saveandexit = () => {
        // formData.fullName = fullName != "undefined" && fullName != "" && fullName != null ? fullName.trim() : ""
        func();
    };
    const query = useQuery();
    let queryValue = query.get("update");
    let countrtyCode = [
        'NZ',
        'AF',
        'AL',
        'DZ',
        'AS',
        'AD',
        'AO',
        'AI',
        'AG',
        'AR',
        'AM',
        'AW',
        'AU',
        'AT',
        'AZ',
        'BS',
        'BH',
        'BD',
        'BB',
        'BY',
        'BE',
        'BZ',
        'BJ',
        'BM',
        'BT',
        'BO',
        'BA',
        'BW',
        'BR',
        'IO',
        'BG',
        'BF',
        'BI',
        'KH',
        'CM',
        'CA',
        'CV',
        'KY',
        'CF',
        'TD',
        'CL',
        'CN',
        'CO',
        'KM',
        'CG',
        'CD',
        'CK',
        'CR',
        'CI',
        'HR',
        'CU',
        'CW',
        'CY',
        'CZ',
        'DK',
        'DJ',
        'DM',
        'DO',
        'EC',
        'EG',
        'SV',
        'GQ',
        'ER',
        'EE',
        'ET',
        'FK',
        'FO',
        'FJ',
        'FI',
        'FR',
        'PF',
        'GA',
        'GM',
        'GE',
        'DE',
        'GH',
        'GI',
        'GR',
        'GL',
        'GD',
        'GU',
        'GT',
        'GG',
        'GN',
        'GW',
        'HT',
        'HN',
        'HK',
        'HU',
        'IS',
        'IN',
        'ID',
        'IR',
        'IQ',
        'IE',
        'IM',
        'IL',
        'IT',
        'JM',
        'JP',
        'JE',
        'JO',
        'KZ',
        'KE',
        'KI',
        'KP',
        'KR',
        'KW',
        'KG',
        'LA',
        'LV',
        'LB',
        'LS',
        'LR',
        'LY',
        'LI',
        'LT',
        'LU',
        'MO',
        'MK',
        'MG',
        'MW',
        'MY',
        'MV',
        'ML',
        'MT',
        'MH',
        'MQ',
        'MR',
        'MU',
        'MX',
        'FM',
        'MD',
        'MC',
        'MN',
        'ME',
        'MS',
        'MA',
        'MZ',
        'MM',
        'NA',
        'NR',
        'NP',
        'NL',
        'NZ',
        'NI',
        'NE',
        'NG',
        'NU',
        'NF',
        'MP',
        'NO',
        'OM',
        'PK',
        'PW',
        'PS',
        'PA',
        'PG',
        'PY',
        'PE',
        'PH',
        'PN',
        'PL',
        'PT',
        'PR',
        'QA',
        'RO',
        'RU',
        'RW',
        'KN',
        'WS',
        'SM',
        'ST',
        'SA',
        'SN',
        'RS',
        'SC',
        'SL',
        'SG',
        'SX',
        'SK',
        'SI',
        'SB',
        'SO',
        'ZA',
        'SS',
        'ES',
        'LK',
        'SD',
        'SR',
        'SZ',
        'SE',
        'CH',
        'SY',
        'TW',
        'TJ',
        'TZ',
        'TH',
        'TG',
        'TK',
        'TO',
        'TT',
        'TN',
        'TR',
        'TM',
        'TC',
        'TV',
        'UG',
        'UA',
        'AE',
        'GB',
        'US',
        'UY',
        'UZ',
        'VU',
        'VE',
        'VN',
        'VI',
        'YE',
        'ZM',
        'ZW'
    ];

    const [selected, setSelected] = useState(formData.entityCompanyOne == 'true' ?
        (formData.otherCountryCompanyRegistered !== null &&
            formData.otherCountryCompanyRegistered !== '' &&
            formData.otherCountryCompanyRegistered !== 'undefined' &&
            formData.otherCountryCompanyRegistered !== undefined
            ? formData.otherCountryCompanyRegistered
            : "") : ""
    );

    const [selected2, setSelected2] = useState(formData.entityCompanyOne == 'true' ?
        (formData.companySecCountryOfResidence !== null &&
            formData.companySecCountryOfResidence !== '' &&
            formData.companySecCountryOfResidence !== 'undefined' &&
            formData.companySecCountryOfResidence !== undefined
            ? formData.companySecCountryOfResidence
            : '') : ""
    );

    formData.companySecCountryOfResidence = selected2;
    formData.otherCountryCompanyRegistered = selected;


    // formData.fullName = fullName.trim()
    const { previous, next } = navigation;
    useEffect(() => {
        $('.toogles').click(function () {
            $('.toogles').removeClass('active');
            $(this).addClass('active');
        });
        $('.step-change').click(function () {
            $('.step-change').removeClass('active');
            $(this).addClass('active');
        });
        $('document').ready(function () {
            $("input[type='tel']").attr('readonly', true);
        });
        $('.flagsave').click(function () {
            let phoneCode = $('.form-control').val();
            localStorage.setItem('phoneCode', phoneCode);
        });
        $('#SHAREvALUE').keyup(function () {
            let input = $(this).val();
            if (input < 100) {
                return true;
            } else {
                return false;
            }
        });
    });
    function splitFullName(fn) {
        fn = fn.replace(/(^[\s]*|[\s]*$)/g, '');
        fn = fn.replace(/\s+/g, ' ');
        var fnarr = fn.split(' ');
        formData.firstNames = fnarr[0];
        formData.firstNameone = formData.firstNames;
        if (fnarr.length >= 3) {
            formData.lastNames = fnarr[fnarr.length - 1];
            formData.lastNameone = formData.lastNames;
            fnarr.shift();
            fnarr.pop();
            fnarr = fnarr.toString().replace(/,/g, ' ');
            formData.middleNames = fnarr;
            formData.middleNameone = formData.middleNames;
        } else if (fnarr.length === 3) {
            formData.middleNames = fnarr[1];
            formData.middleNameone = formData.middleNames;
            formData.lastNames = fnarr[2];
            formData.lastNameone = formData.lastNames;
        } else if (fnarr.length === 2) {
            formData.lastNames = fnarr[1];
            formData.lastNameone = formData.lastNames;
        }
    }

    function Onprevious(e) {
        formData.class = 'Second';

        go('Second');
    }

    const [errorResults, setErrorResults] = useState();
    function handleClick(e) {
        formData.directorRoles = directorRoles
        if (formData.companyAddressProof === null || formData.companyAddressProof == undefined || formData.companyAddressProof === "") {
            $('.companyAddressProof').html('Please attach company address proof')
        } else {
            formData.class = 'Jointhird';
            setCountry(true);

            go('EntityTwo');
        }
    }

    function removeError() {
        $('.error').html('');
    }
    function numbertest() {
        let input = $(this).val();
        if (input < 100) {
            return true;
        } else {
            return false;
        }
    }
    $('input').on('paste', function (e) {
        $('.error').html('');
    });
    const [value, setValue] = useState();
    const [currentDate, setNewDate] = useState(null);
    const onChange = (event, data) => setNewDate(data.value);
    const { go } = navigation;
    saveAndExist = () => {
        formData.status = 'PENDING';
        formData.step = '3';
        go('submit');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [imageLoader, setImageLoader] = useState();
    const [documentFile64, setdocumentFile64] = useState('');
    let { document } = documentFile64;
    const datetime = new Date();
    const [errror, setErrror] = useState();

    let labeltxt = ' Please attach a certified copy of proof of address';
    const columnName = 'companyAddressProof';
    const fileName = `Company_Address_Proof`;

    const getInitialData = {
        // full_legal_name: "",
        // user_email: "",
    };

    const [inputList, setInputList] = useState(
        formData.inputList != null && formData.inputList != '' && formData.inputList != 'undefined'
            ? formData.inputList
            : [getInitialData]
    );

    const [country, setCountry] = useState();

    const handleAddClick = () => {
        setCountry(true)
    };
    const handleRemoveClick = () => {
        setCountry(false);
        formData.secFullLegalCompanyName = '';
        setSelected2('')
    };


    formData.country = country
    let PostalAddress1 = sessionStorage.getItem('PostalAddress3');

    //SEARCH API
    const [comNumber, setcomNumber] = useState();
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    console.log("comNumbercomNumber", comNumber)
    let optionsds = sessionStorage.getItem("optionsd")

    const [searchTerm, setSearchTerm] = useState("");


    //sara
    const [options, setOptions] = useState();

    //single
    const [optionsd, setOptionsd] = useState();

    const [running, setRunning] = useState();
    const [running2, setRunning2] = useState();
    const [running2t, setRunning2t] = useState();


    useEffect(() => {

        //    alert(running2t);
        //    alert(formData.companyRegisterAddress);
    }, [formData.inputData]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm != null && searchTerm != '' && searchTerm != 'undefined') {
                setRunning(true);
            }

            axios
                .get(
                    `https://xl4q10bqil.execute-api.ap-southeast-2.amazonaws.com/dev/company/search/${searchTerm}`
                )
                .then((result) => {
                    setRunning(false);
                    setOptions(result.data.items);
                });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    console.log("optionsoptionsoptions", options)
    formData.options = options
    const [showing, setShowing] = useState(true);

    const showed = () => {
        if (showing == true) {
            setShowing(false);
        } else {
            setShowing(true);
        }
    };
    const shooowed = () => {
        if (showing == false) {
            setShowing(true);
        } else {
            setShowing(true);
        }
    };

    const [address, setAddress] = useState();

    const [directorRoles, setDirectorRoles] = useState();

    const Companysearch = (value) => {
        setRunning2(true);
        axios
            .get(
                `https://xl4q10bqil.execute-api.ap-southeast-2.amazonaws.com/dev/company/director/${value}`,
                {
                    headers: { Authorization: `Bearer ${formData.token}` },
                }
            )
            .then((result) => {
                if (result.data.companyAddress != null && result.data.companyAddress != '') {
                    var companyRegisterAddress1 = result.data.companyAddress
                    var tes = companyRegisterAddress1.find((tst) => tst.addressType === "REGISTERED");
                    // console.log('dddddddd', tes.address1 + tes.address2);
                    setRunning2t(tes.address1);
                    formData.companyRegisterAddress = `${tes != "" && tes != null && tes != undefined && tes != 'undefined' && tes.address1 != undefined && tes.address1 != 'undefined' && tes.address1 != null && tes.address1 != "" ? tes.address1 : ""}, ${tes != undefined && tes != "" && tes != null && tes != 'undefined' && tes.address2 != undefined && tes.address2 != 'undefined' && tes.address2 != null && tes.address2 != "" ? tes.address2 : ""}, ${tes != undefined && tes != "" && tes != null && tes != 'undefined' && tes.address3 != undefined && tes.address3 != 'undefined' && tes.address3 != null && tes.address3 != "" ? tes.address3 : ""}`
                } else {
                    formData.companyRegisterAddress = ""
                }

                formData.inputData = result.data.directorName;
                formData.inputList = result.data.shareholder

                if (result.status == 502) {
                    setRunning2(false);
                } else {
                    setRunning2(false);
                }

            }).catch((error) => {
                setRunning2(false);
            })

    };

    const [addnumber, setAddnumber] = useState(formData.isotherCompanyRegister == true ? true : false);
    const AddRemoveBtn = () => {
        setAddnumber(!addnumber);
        if (addnumber === false) {
            formData.isotherCompanyRegister = true;
        } else {
            formData.isotherCompanyRegister = false;
            formData.secFullLegalCompanyName = '';
            setSelected2('')
        }
    };

    formData.addnumber = addnumber;
    // alert(formData.isPrimary)

    return (
        <>
            <MenuSidebar navigation={navigation} formData={formData} />
            <div className="main_container">
                <div class="heading__top">
                    <h2>Step 2: Company Details</h2>
                </div>
                <div className="form">
                    <img src={logo} alt="img" className="imageCover" />
                    {queryValue ? <p></p> :
                        <div className="save-exit se_invff">
                            <p
                                disabled={formData.isPrimary == 1 ? false : true}
                                className="save next"
                                onClick={saveandexit}>
                                SAVE DATA{' '}
                            </p>
                            <img src={info} onClick={infodetls} className="info" />
                        </div>
                    }
                    <Row>
                        <Col>
                            <label>Company name</label>
                            <ItemForm
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="text"
                                name="companyName"
                                onKeyPress={removeError}
                                className="quantity"
                                onChange={(e) => {
                                    formData.companyNumber = ""
                                    formData.companyRegisterAddress = ""
                                    formData.companyName = e.target.value;
                                    setSearchTerm(e.target.value);
                                    removeError();
                                }}
                                value={companyName}
                                // value={optionsd}
                                onClick={() =>
                                    shooowed()
                                }

                                placeholder="Start typing company name, then select"
                            />
                            {running == true ? (
                                <Spin indicator={antIcon} className="spinnner cust_loader" />
                            ) : null}

                        </Col>
                    </Row>
                    <div>
                        {showing == true
                            ? options &&
                            options.map((ttt) => {
                                // console.log("kkkk", ttt);
                                return (
                                    <button
                                        className="trustSix"
                                        onClick={(e) => {
                                            formData.companyName = ttt.entityName
                                            formData.companyNumber = ttt.nzbn
                                            Companysearch(ttt.nzbn);
                                            showed();
                                            setcomNumber(ttt.nzbn)
                                        }}
                                    >
                                        {ttt.entityName}
                                    </button>
                                );
                            })
                            : ""}
                    </div>
                    <Row>
                        <Col>
                            <label>Company number</label>
                            <ItemForm
                                type="text"
                                disabled={formData.isPrimary == 1 ? false : true}
                                name="companyNumber"
                                onKeyPress={removeError}
                                className="numbers"
                                onChange={(e) => {
                                    formData.companyNumber = e.target.value;
                                    setForm(e);
                                    removeError();
                                }}
                                value={companyNumber}
                                placeholder="Enter company number"
                                maxLength={12}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Trading name (if relevant)</label>
                            <ItemForm
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="text"
                                name="companyTradingName"
                                className="capitaluseraddress"
                                onKeyPress={removeError}
                                onChange={(e) => {
                                    setForm(e);
                                    removeError();
                                }}
                                value={companyTradingName}
                                placeholder="Enter trading name "
                            />
                            <span className="error secondholder"></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <label>Registered Address</label>
                            <InputMask
                                disabled={formData.isPrimary == 1 ? false : true}
                                name="companyRegisterAddress"
                                autoComplete="off"
                                className="capitaluseraddress"
                                placeholder="Enter company registered address"
                                onKeyPress={removeError}
                                onChange={(e) => {
                                    setForm(e);
                                    removeError();
                                }}
                                value={formData.companyRegisterAddress}

                            />
                            {running2 == true ? (

                                <Spin indicator={antIcon} className="spinnner cust_loader" />
                            ) : null}
                        </Col>

                    </Row>

                    {/* <Row className="fullinput">
                        <Col xs={3}>
                            <div class="yes1">
                                <button className="click-hover">
                                    <span class="btn_upload">
                                        <input
                                            //disabled={formData.isPrimary == 1 ? false : true}
                                            type="file"
                                            id="imag2"
                                            title=""
                                            value={document}
                                            onChange={(e) => {
                                                uploadWithJSON(e.target.files[0]);
                                                removeError();
                                            }}
                                            onClick={(e) => {
                                                e.target.value = null;
                                                removeError();
                                            }}
                                        />
                                    </span>
                                    CLICK TO ATTACH
                                </button>
                            </div>
                        </Col>

                        <Col xs={9} className="attach-style-proof">
                            <label className="bankClick">
                               
                            </label>
                            <span className="error file ml-0"></span>
                        </Col>
                    </Row>
                    <div className="spceimg">
                        {formData.companyAddressProof === '' ||
                            formData.companyAddressProof === null ||
                            formData.companyAddressProof === 'undefined' ||
                            formData.companyAddressProof === undefined ? (
                            ''
                        ) : (
                            <a href={formData.companyAddressProof}
                                //disabled={formData.isPrimary == 1 ? false : true}
                                className="licedocument-downloade">
                                {' '}
                                <div className="main-downloade-wrapper">
                                    <div className="downloads">
                                        <i
                                            //disabled={formData.isPrimary == 1 ? false : true}
                                            className="fa fa-download downloadeStyle"></i>
                                    </div>
                                    <p className="downloadeMsg">View file already provided </p>
                                </div>
                            </a>
                        )}
                        <span id="name_tag" class="preview2"></span>
                        <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
                        {imageLoader === true ? (
                            <div class="progress">
                                <span class="progress-bar" style={{ width: '100%' }}></span>
                            </div>
                        ) : null}
                    </div> */}
                    {/* {formData.isPrimary == 1 ?
                        <>
                            <UploadImage
                                buttonName='CLICK TO ATTACH'
                                formData={formData}
                                columnName={columnName}
                                fileName={fileName}
                                labeltxt={labeltxt}
                                valuedefine={"companyAddressProof"}
                            />
                            <span className="error companyAddressProof"></span>
                        </> 
                        :
                        <>
                            <button>CLICK TO ATTACH</button>
                            <div className="main-downloade-wrapper">
                                <div className="downloads">
                                    <i className="fa fa-download downloadeStyle"></i>
                                </div>
                                <p className="downloadeMsg">View file already provided </p>
                            </div>

                        </>

                    } */}
                    <>
                            <UploadImage
                                buttonName='CLICK TO ATTACH'
                                formData={formData}
                                columnName={columnName}
                                fileName={fileName}
                                labeltxt={labeltxt}
                                valuedefine={"companyAddressProof"}
                            />
                            <span className="error companyAddressProof"></span>
                        </> 
                    {/* <UploadImage
                        buttonName='CLICK TO ATTACH'
                        formData={formData}
                        columnName={columnName}
                        fileName={fileName}
                        labeltxt={labeltxt}
                        valuedefine={"companyAddressProof"}
                        disabled={formData.isPrimary == 1 ? false : true}
                      
                    />
                    
                    <span className="error companyAddressProof"></span> */}
                    {/* <UploadImage
                        buttonName='CLICK TO ATTACH'
                        disabled={formData.isPrimary === 1 ? false : true}
                        formData={formData}
                        columnName={columnName}
                        fileName={fileName}
                        labeltxt={labeltxt}
                        valuedefine={"companyAddressProof"}
                    /> */}

                    <Row className=" check-mb  check-mb1">
                        <Col>
                            <label>Is the company registered in any other country than New Zealand?</label>
                        </Col>
                        <Col
                            lg={12}
                            disabled={formData.isPrimary == 1 ? false : true}
                        >
                            <div className="button_group space-bottom">
                                <div class="switch-field nospace">
                                    <div className="check_YN_Bank swtich11">
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-one"
                                            className="yesno"
                                            name="entityCompanyOne"
                                            value="false"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-one"
                                            className={`toogles postalAddressButton ${typeof entityCompanyOne === 'undefined' || entityCompanyOne === 'false'
                                                ? 'actives'
                                                : null
                                                }`}
                                            id="show"
                                        >
                                            No
                                        </label>
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-two"
                                            name="entityCompanyOne"
                                            value="true"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-two"
                                            className={`toogles postalAddressButtons ${entityCompanyOne === 'true' ? 'actives' : null
                                                }`}
                                            id="hide"
                                        >
                                            Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {formData.entityCompanyOne == 'true' ? (
                        <>
                            <Row>
                                <Col className="FlagsSelectcss" md={12} onClick={removeError}>
                                    {' '}
                                    <label>Select which other country the company is registered in</label>
                                    <ReactFlagsSelect
                                        disabled={formData.isPrimary == 1 ? false : true}
                                        autoComplete="off"
                                        name="otherCountryCompanyRegistered"
                                        selected={selected}
                                        placeholder="Select"
                                        value={otherCountryCompanyRegistered}
                                        onChange={(e) => {
                                            setForm(e);
                                            removeError();
                                        }}
                                        onSelect={(code) => setSelected(code)}
                                        countries={countrtyCode}
                                        defaultCountry={otherCountryCompanyRegistered}
                                    />
                                </Col>
                                <Col>
                                    <label>Full legal company name</label>
                                    <ItemForm
                                        disabled={formData.isPrimary == 1 ? false : true}
                                        type="text"
                                        name="fullLegalCompanyName"
                                        onKeyPress={removeError}
                                        className="quantity2"
                                        onChange={(e) => {
                                            setForm(e);
                                            removeError();
                                        }}
                                        value={fullLegalCompanyName}
                                        placeholder="Enter legal name"
                                    />
                                </Col>
                            </Row>
                            <Row>

                                {addnumber === true ? (
                                    <>
                                        <Col className="FlagsSelectcss" md={12} onClick={removeError}>
                                            {' '}
                                            <label>Select which other country the company is registered in</label>
                                            <ReactFlagsSelect
                                                disabled={formData.isPrimary == 1 ? false : true}
                                                autoComplete="off"
                                                name="companySecCountryOfResidence"
                                                selected={selected2}
                                                placeholder="Select"
                                                dfaultValue={companySecCountryOfResidence}
                                                onChange={(e) => {
                                                    setForm(e);
                                                    removeError();
                                                }}
                                                onSelect={(code) => setSelected2(code)}
                                                countries={countrtyCode}
                                                defaultCountry={companySecCountryOfResidence}
                                            />
                                        </Col>
                                        <Col >
                                            <label>Full legal company name</label>
                                            <ItemForm
                                                disabled={formData.isPrimary == 1 ? false : true}
                                                type="text"
                                                name="secFullLegalCompanyName"
                                                onKeyPress={removeError}
                                                className="quantity"
                                                onChange={(e) => {
                                                    setForm(e);
                                                    removeError();
                                                }}
                                                value={secFullLegalCompanyName}
                                                placeholder="Enter legal name"
                                            />
                                        </Col>
                                    </>

                                ) : (
                                    ''
                                )}

                                <Col sm={12} className="Shareholder">
                                    <div className="Trust_ind_btn">
                                        <button
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            // className="border_BTN"
                                            onClick={AddRemoveBtn}>
                                            {addnumber === true ? 'Remove' : '+Country'}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        ''
                    )}

                    <div className="fotter-btn">
                        <button className="preview" onClick={Onprevious}>
                            <img src={leftarrow} className="leftarrow" alt="" />
                            Previous
                        </button>
                        {imageLoader === true ? (
                            <button type="submit" className="next flagsave">
                                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                                <img src={rightarrow} className="rightarrow" alt="" />{' '}
                            </button>
                        ) : (
                            <button type="submit" className="next flagsave" onClick={handleClick}>
                                Continue
                                <img src={rightarrow} className="rightarrow" alt="" />{' '}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default EntityOne;
