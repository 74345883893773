import React, { useState, useEffect } from "react";
import axios from "axios";
import AxiosInstance from "./axios";
import swal from "sweetalert";
import $ from "jquery";
import { useAuth0 } from "@auth0/auth0-react";
import MenuSidebar from "./Individual/MenuSidebar";


// import { formatMs } from '@material-ui/core';

const Submit = ({ formData,navigation }) => {
  const { logout } = useAuth0();
  // useEffect(() => {
  //   logout()
  // }, [])

  $(".loader").show();
  const { go } = formData;
  return (
    <>
  
 <MenuSidebar navigation={navigation} formData={formData}/>
 <div className="main_container">
 
    <div>
     
      {/* <div id="preloader">
        <div id="status">&nbsp;</div>
      </div> */}
    </div>
    </div>
    </>
  );
};

export default Submit;
