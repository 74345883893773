import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Swal from 'sweetalert2';
import axios from "axios";
import logo from '../logo.png';
import $ from "jquery";
import swal from 'sweetalert';
import info from '../info.png';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import BiometricOption from '../BiometricOption';
import MenuSidebar from "./MenuSidebar";
import { Biometricverify, multisavedata } from 'src/auth/Configuration';
import { infodetls } from './SaveInfo';
import useQuery from '../useQuery';


const Five = ({ setForm, formData, navigation, func }) => {
  const { fullName, biometric } = formData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const query = useQuery();

  // let queryValue = query.get("update");
  const { next } = navigation;
  const [imageLoader, setImageLoader] = useState();

  const namefull = fullName ? fullName.trim().split(' ') : ''

  if (fullName !== null && fullName !== "" && fullName !== "undefined") {

    formData.firstName1 = namefull[0];

    if (namefull.length > 1) {
      formData.lastName1 = namefull[namefull.length - 1];
    }

    if (namefull.length > 2) {
      formData.middleName1 = namefull
        .slice(1, namefull.length - 1)
        .join()
        .replace(/,/g, ' ');

    }
  }
  const saveandexit = () => {
    func();
  }
  useEffect(() => {
    var setLicenseDate = formData.licenceExpiryDate
    if (typeof setLicenseDate !== "undefined" && setLicenseDate !== "" && setLicenseDate !== null && setLicenseDate !== "null") {
      $(".ui.icon.input.counrtyselext6>input").val(setLicenseDate);
    }
    var setPassportDate = formData.passportExpiryDate
    if (typeof setPassportDate !== "undefined" && setPassportDate !== "" && setPassportDate !== null && setPassportDate !== "null") {
      $(".ui.icon.input.counrtyselext7>input").val(setPassportDate);
    }
    var setDocumentsDate = formData.documentExpiryDate
    if (typeof setDocumentsDate !== "undefined" && setDocumentsDate !== "" && setDocumentsDate !== null && setDocumentsDate !== "null") {
      $(".ui.icon.input.counrtyselext8>input").val(setDocumentsDate);
    }
    $(function () {
      $('#colorselector').change(function () {
        var select = $(this).find(':selected').val();
        $(".hide").hide();
        $('#' + select).show();

      }).change();
    });

    $(".flagsave").click(function () {

      let passportCountryOfIssue = $(".counrtyselext3 #select_flag_button > span > span").text();
      localStorage.setItem('passportCountryOfIssue', passportCountryOfIssue);

      let documentCountryOfIssue = $(".counrtyselext4 #select_flag_button > span > span").text();
      localStorage.setItem('documentCountryOfIssue', documentCountryOfIssue);

    });
  });
  const queryValue = sessionStorage.getItem("queryValue");

  function Handleprevious(e) {
    if (queryValue == null || queryValue == undefined || queryValue == "") {
      formData.class = "Third"
      go("Four")
    }
    else {
      formData.class = "Second"
      go("Second")
    }

  }

  let BioData = {
    reference: "IDscan reference 101",
    callback: window.location.href + "/idscan/complete",
    // callback:"https://www.duvalgroup.co.nz/",
    // callback:"https://duvalapp.invsta.io/",
    notificationUrl: "https://mysite.com/id-notify?id=001"

  }
  const BiometricVerification = async () => {

    formData.BiometricCheck = false
    formData.stepfive = "five";
    setImageLoader(true);
    const Bio = await axios.post(`${Biometricverify}/verify/identity/idScan${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, BioData)
      .then((Bio) => {
        formData.step = "5"
        if (Bio.status === 200) {
          let hous = "2"
          formData.Capture = Bio.data.token
          func(hous);

          let postdata = {
            captureReference: formData.Capture !== null ? formData.Capture : "",
            identificationType: "Biometric",
            step: formData.step
          }
          setImageLoader(false);
          window.open(Bio.data.link);

          if (queryValue == null || queryValue == "null" || queryValue == "undefined" || queryValue == undefined || queryValue == "") {
            axios
              .put(`${multisavedata}/onboard/individual`, postdata, {
                headers: { Authorization: `Bearer ${formData.token}` }
              })
              .then((res) => {
                if (res.status === 200) {

                  setImageLoader(false);
                  window.open(Bio.data.link);
                  if (formData.investmentType === "Joint") {
                    formData.class = 'Eight'
                    go("Jointten")
                  }
                  else {

                    formData.class = 'Eight'
                    go("Eight")
                    // formData.repeatOff = true
                  };
                } else {
                  ;
                }
              })
              .catch(() => {
                setImageLoader(false);
                ;
                Swal.fire({
                  // icon: 'error',
                  // title: 'Oops...',
                  text: 'Something went wrong!'
                });
              });
          }

        }

      })
    console.log(Bio)
  }

  if (formData.biometricComplete === true) {
    formData.sixthTick = true
  }

  function handleClick(e) {

    if (formData.biometric === "Biometric") {

      if (formData.biometricComplete !== true) {
        formData.bioVerify = true
        formData.repeatOff = false

        BiometricVerification();
      }
      else {
        go("Eight")
        formData.class = "Eight"
      }
    }

    else if (formData.biometric === "document") {
      formData.biometricComplete = false
      next();
    }
    else {
      $('.IdentityError').html('Please Select Identity');

    }
  }
  function removeError() {
    $('.error').html('');
  }



  const inform = () => {
    Swal.fire({
      html: '<p class="sizeText">Certified copies must be stamped or endorsed as true copies of the originals by a person authorized by law to take statutory declarations. This maybe a Lawyer, Notary Public, Justice of Peace or Court Official.</p>'

    })

  }
  const { go } = navigation;

  $('select').change(
    function () {
      $(this).css("color", $(this).addClass("select_op_color"));
    });


  useEffect(() => {
    // VAlidation star

    if (formData.check3 === false) {
      if (
        formData.biometric === 'Select' ||
        formData.biometric === undefined ||
        formData.biometric === '' ||
        formData.biometric === null
      ) {
        $('.IdentityError').html('Please Select Identity');
      }
    }
    // Validation End
  })



  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          {
            formData.investmentType == "Company" ?
              <h2>Step 4: Identity Verification - {(formData.firstName1 !== null && formData.firstName1 !== "" && formData.firstName1 !== "undefined" && formData.firstName1 !== undefined ? formData.firstName1 : "") + " " + (formData.lastName1 !== null && formData.lastName1 !== "" && formData.lastName1 !== "undefined" && formData.lastName1 !== undefined ? formData.lastName1 : "")}</h2>
              : <h2>Step 3: Identity Verification - {(formData.firstName1 !== null && formData.firstName1 !== "" && formData.firstName1 !== "undefined" && formData.firstName1 !== undefined ? formData.firstName1 : "") + " " + (formData.lastName1 !== null && formData.lastName1 !== "" && formData.lastName1 !== "undefined" && formData.lastName1 !== undefined ? formData.lastName1 : "")}</h2>
          }
        </div>


        <div className="form">

          <img
            src={logo}
            alt="img"
            className="imageCover"
          />
          {queryValue ? <p></p> :
            <div
              disabled={formData.isPrimary == 1 ? false : true}
            >
              <div className="save-exit se_invff">
                <p className="save next" onClick={saveandexit}>
                  SAVE DATA{" "}
                </p><img src={info} onClick={infodetls} className="info" alt="infoImage" />
              </div>
            </div>
          }

          <div className="grid-container">
            <div className="grid-item "></div>
            <div className="grid-item"></div>
            <div className="grid-item "></div>
            <div className="grid-item active"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>


          </div>

          <div className="note_text">
            <div>

              <p className='mb-2'>There are two ways you can verify your identity:</p>
              <ol className='change_option_title'>
                <b>Option 1</b>
                <li>Use an online biometrics process, where you will be required to take a photo of yourself and of your identification.
                  <li>To use the biometrics process, your identification must be either a New Zealand Passport or a New Zealand Driver's Licence, and you will need to use your mobile phone to take these photos and complete this process.</li>
                  <i><b>Note:</b> If you do not have either a New Zealand Passport or a New Zealand Driver's Licence, or do not have a mobile phone that can take selfie photos, please select Option 2.<br /></i>

                </li>
                <b>Option 2</b>
                <li>Upload a copy of your Passport or a copy of your New Zealand Driver's Licence.
                  {/* //<img src={info} onClick={inform} alt="info1" className="fffff" /> */}
                </li>


              </ol>
            </div>


            <div>
              <label>Please select how you’d like to verify your identity.</label>
              <div className="button dropdown fullinput">
                <BiometricOption
                  autoComplete="off"
                  type="text"
                  className={biometric === "Select" || biometric === undefined || biometric === "" || biometric === null ? "colorgrey" : "colorblack"}
                  onClick={removeError}
                  onChange={(e) => {
                    setForm(e);
                    removeError();
                  }}
                  name="biometric"
                  value={formData.biometric} />
                <span className="error IdentityError"></span>
              </div>
            </div>

          </div>
          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={Handleprevious}><img src={leftarrow} className="leftarrow" alt="" />Previous</button>

            {imageLoader === true ?
              <button className=" flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
              :
              <button type="submit" className="next flagsave" onClick={handleClick} >Continue<img src={rightarrow} className="rightarrow" alt="" /> </button>

            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Five;
