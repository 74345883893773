import React from "react";

const states = [
  ["Mobile", "Mobile"],
  ["Home", "Home"],
  ["Work", "Work"],
  ["Other", "Other"]
];

const ContactType1 = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others}>
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default ContactType1;
