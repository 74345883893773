import React from "react";
import leftarrow from "../leftarrow.png";
import rightarrow from "../rightarrow.png";
import info from "../info.png";
import logo from "../logo.png";
import $ from "jquery";
import swal from "sweetalert";
import MenuSidebar from "../Individual/MenuSidebar";
const Twelve = ({ setForm, formData, navigation, saveAndExist, infodetls }) => {
	const { twoYearInvestEntity } = formData;
	const { previous, next } = navigation;
	function handleClick(e) {
		let xValue = $("input[name=twoYearInvestEntity]:checked").val();
		formData.twoYearInvestEntity = xValue;

		localStorage.setItem("step10", "step10");
		localStorage.removeItem("step15");
		if (
			typeof formData.twoYearInvestEntity == "undefined" ||
			formData.twoYearInvestEntity === ""
		) {
			$(".twoYearInvestEntity").html("Please select an answer");
		} else if (formData.twoYearInvestEntity !== "None") {
			go("Jointtwentyfour");
		} else {
			localStorage.setItem("step10", "step10");
			next();
		}
	}
	function removeError() {
		$(".error").html("");
	}
	saveAndExist = () => {
		formData.status = "pending";
		formData.step = "17";
		swal({
			text:
				"By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
		});
		go("submit");
	};
	infodetls = () => {
		// swal({
		//   text: "Your application saved",
		// });
	};
	const { go } = navigation;
	return (
		<>
		<MenuSidebar navigation={navigation} formData={formData}/>
		<div className="main_container">
		<div className="form">
			<img src={logo} alt="img" className="imageCover" />
			<div className="save-exit">
				<p className="save" onClick={saveAndExist}>
				SAVE DATA{" "}
				</p>
				<img src={info} onClick={infodetls} className="info" />
			</div>
			<h3>Investor certificate</h3>
			
			<label>
				Are you a large investor? In the last 2 years, has the investment
				entity:
			</label>
			<br />
			<p>Select one of the below options</p>

			<div className="checkbox-my">
				<label className="checkmystyle">
					Owned or controlled net assets of $5m+
					<input
						type="radio"
						name="twoYearInvestEntity"
						onClick={removeError}
						value="Owned or controlled net assets of $5m+"
						onChange={(e) => {
							setForm(e);
							removeError();
						}}
					/>
					<span className="checkmark"></span>
				</label>

				<label className="checkmystyle">
					Had or controlled a business with an annual turnover of $5m+ for each
					of the last two years
					<input
						type="radio"
						name="twoYearInvestEntity"
						onClick={removeError}
						value="Had or controlled a business with an annual turnover of $5m+ for each of the last two years"
						onChange={(e) => {
							setForm(e);
							removeError();
						}}
					/>
					<span className="checkmark"></span>
				</label>

				<label className="checkmystyle">
					None of the above
					<input
						type="radio"
						name="twoYearInvestEntity"
						onClick={removeError}
						value="None"
						onChange={(e) => {
							setForm(e);
							removeError();
						}}
					/>
					<span className="checkmark"></span>
				</label>
				<span className="error twoYearInvestEntity"></span>
			</div>
			<br />
			<div className="fotter-btn">
				<button className="preview" onClick={previous}>
					<img src={leftarrow} className="leftarrow" alt="perivous" />
					Previous
				</button>
				<button type="submit" className="next" onClick={handleClick}>
					Continue
					<img src={rightarrow} className="rightarrow" alt="continue" />{" "}
				</button>
			</div>
		</div>
		</div>
		</>
	);
};

export default Twelve;
