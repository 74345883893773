import React, { useState, useEffect ,useRef } from 'react';
import axios from "axios";
import AxiosInstance from "../axios"
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import swal from 'sweetalert';
import $ from "jquery";
import info from '../info.png';
import logo from '../logo.png';
import SignaturePad from 'react-signature-pad-wrapper'
// var SignaturePad = require('react-signature-pad');

const Jointtwentyfive = ({ setForm, formData, navigation,saveAndExist,step16,infodetls }) => {
  const [userapplication, setUserapplication] = useState([]);
  const mySignatureRefB = useRef(null);
  useEffect(() => {
    application();
  }, []);
  const application = async () => {
    const applicationdata = await AxiosInstance.get("/admin/syndicate/" + Syndication);
    setUserapplication(applicationdata.data);
    console.log(applicationdata.data);
  };
  const { Syndication, fullName } = formData;
  const { previous, next } = navigation;
  useEffect(() => {
    $(document).ready(function () {
      var date = new Date();

      var val = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      $("#printDate").text(val);
        formData.certificateB_Date = val;


    });
    $('.signupForm').hide();
    $(".signupButton").click(function () {
      $('.signupForm').show();
    }, function () {
      $('.signupForm').show();
    });
    $(".signupXButton").click(function () {
      $('.signupForm').hide();
    });
    $(".set-previuos").click(function () {
      localStorage.setItem("step16","step16")

      if (step16) {
        go("Sixteen");
      } else {
        go("Sixteen");
      }
    });
  });
  
  const { go } = navigation;
  saveAndExist = () => {
    formData.status ="PENDING"
    formData.step ="17"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }

    async function uploadDataWithSignature() {
    let signatureB = mySignatureRefB.current.toDataURL();
    let isEmpty = mySignatureRefB.current.isEmpty();
    // alert(isEmpty);
    // alert(signatureB);
    if(isEmpty){
 $('.signError').html('Please sign ..');
    }else{
      formData.signatureB = signatureB;
      console.log("sig natureB+ " +formData.signatureB );
      // alert(formData.signatureB);
      next();
    }
  
    
  }
   function removeError() {
    $('.error').html('');
  }

  return (
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls}  className="info" />
      </div>
      <h3>Certificate B - Eligible Investor Certificate</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item active"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
     

      </div>
      <p>This certificate and confirmation (in the form of Certificate C on the following page) is required to verify that the investor meets the eligibility requirements as an "Eligible Investor" under Schedule 1, clause 3(3)(a) of the FMCA and accordingly confirm that neither MyFarm Limited (as Offeror), {userapplication.syndicateName} (as Issuer) are required to make disclosure in respect of this offer under Part 3 of the FMCA.</p><br />

      {formData.entity === "Trust" ? <p>In relation to the offer from MyFarm Limited of units in  {userapplication.syndicateName} ("the financial product" on offer and "the transaction"),I/We {formData.trustName} (“the Investor”) Certify that: </p> : <p>In relation to the offer from MyFarm Limited of units in  {userapplication.syndicateName} ("the financial product" on offer and "the transaction"),I/We {formData.fullName} and {formData.secondholder} (“the Investor”) Certify that: </p>}

      <p>
        {/* 1) I/We {fullName} ("the investor") <br />Certify that: <br /> */}
        1) I/We have previous experience in acquiring or disposing of financial
        products that allows me/us to assess:</p>
      <ul className="usual">
        <li>a) The merits of the transaction, including assessing the value and the risks of the financial products involved; and</li>
        <li>b) My/Our own information needs in relation to the transaction; and</li>
        <li>c) The adequacy of the information provided by MyFarm Limited as the offeror</li>
      </ul>
      <p>2) I/We do understand the consequences of certifying myself/ourselves to be Eligible Investor.</p>
      <div className="d-flex">
        <label>Dated:</label>
        <label id="printDate"> DD/MM/YYYY </label>
      </div>

      <div className="signupButton">Please click to read this warning</div>
      <div className="signupForm"><div className="signupFormPaw"></div><p><b>Warning</b></p>
      <b>The law normally requires people who offer financial products to give information to investors before they invest. This information is designed to help investors make an informed decision.<br/ ><br />
If you are a wholesale investor, the usual rules do not apply to offers of financial products made to you. As a result, you may not receive a complete and balanced set of information. You will also have fewer other legal protection for these investments. Ask questions, read all documents carefully, and seek independent financial advice before committing yourself.</b>
<br /><br /><p><b>Offence</b></p>
<b>It is an offence to give a certificate knowing that it is false or misleading in a material particular. The offence has a penalty of a fine not exceeding $50,000</b>



<div className="signupXButton" >X</div></div>
      <br />
      <div onClick={removeError}>
      <label>Signature:</label>
      <SignaturePad  className="signature_h" clearButton="true" className='sigCanvas' ref={mySignatureRefB}/>
      <br></br>
         <span className="error signError"></span>
         </div>

      <br />
      <div className="fotter-btn">
        <button className="preview set-previuos"><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={uploadDataWithSignature}  >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
  );
};

export default Jointtwentyfive;
