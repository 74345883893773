/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import PhoneInput from 'react-phone-input-2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import logo from '../logo.png';
import $ from 'jquery';
import InputMask from 'react-input-mask';
import "react-phone-input-2/lib/bootstrap.css";
import info from '../info.png';
import swal from 'sweetalert';
import ReactFlagsSelect from 'react-flags-select';
import { getCode } from 'country-list';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import MenuSidebar from '../Individual/MenuSidebar';
import ContactType1 from '../ContactType1';
import Swal from 'sweetalert2';
import { twilio } from '../../auth/Configuration';
import { infodetls } from '../Individual/SaveInfo';
import SearchAddress from '../Individual/SearchAddress';
import SearchAddress2 from '../Individual/SearchAddress2';

const ThirdTrust = ({
    func,
    setForm,
    formData,
    navigation,
    VerifyData,
    VerifyData1,
    ResendDataotp,

}) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        manuallyAddress,
        fullName,
        preferredName,
        isUserAddressSame,
        postal,
        priContactNumber,
        priContactType,
        residentialGoogleAddress,
        street_number, city, country, postalCode, region,
    } = formData;


    let countrtyCode = [
        'NZ',
        'AF',
        'AL',
        'DZ',
        'AS',
        'AD',
        'AO',
        'AI',
        'AG',
        'AR',
        'AM',
        'AW',
        'AU',
        'AT',
        'AZ',
        'BS',
        'BH',
        'BD',
        'BB',
        'BY',
        'BE',
        'BZ',
        'BJ',
        'BM',
        'BT',
        'BO',
        'BA',
        'BW',
        'BR',
        'IO',
        'BG',
        'BF',
        'BI',
        'KH',
        'CM',
        'CA',
        'CV',
        'KY',
        'CF',
        'TD',
        'CL',
        'CN',
        'CO',
        'KM',
        'CG',
        'CD',
        'CK',
        'CR',
        'CI',
        'HR',
        'CU',
        'CW',
        'CY',
        'CZ',
        'DK',
        'DJ',
        'DM',
        'DO',
        'EC',
        'EG',
        'SV',
        'GQ',
        'ER',
        'EE',
        'ET',
        'FK',
        'FO',
        'FJ',
        'FI',
        'FR',
        'PF',
        'GA',
        'GM',
        'GE',
        'DE',
        'GH',
        'GI',
        'GR',
        'GL',
        'GD',
        'GU',
        'GT',
        'GG',
        'GN',
        'GW',
        'HT',
        'HN',
        'HK',
        'HU',
        'IS',
        'IN',
        'ID',
        'IR',
        'IQ',
        'IE',
        'IM',
        'IL',
        'IT',
        'JM',
        'JP',
        'JE',
        'JO',
        'KZ',
        'KE',
        'KI',
        'KP',
        'KR',
        'KW',
        'KG',
        'LA',
        'LV',
        'LB',
        'LS',
        'LR',
        'LY',
        'LI',
        'LT',
        'LU',
        'MO',
        'MK',
        'MG',
        'MW',
        'MY',
        'MV',
        'ML',
        'MT',
        'MH',
        'MQ',
        'MR',
        'MU',
        'MX',
        'FM',
        'MD',
        'MC',
        'MN',
        'ME',
        'MS',
        'MA',
        'MZ',
        'MM',
        'NA',
        'NR',
        'NP',
        'NL',
        'NZ',
        'NI',
        'NE',
        'NG',
        'NU',
        'NF',
        'MP',
        'NO',
        'OM',
        'PK',
        'PW',
        'PS',
        'PA',
        'PG',
        'PY',
        'PE',
        'PH',
        'PN',
        'PL',
        'PT',
        'PR',
        'QA',
        'RO',
        'RU',
        'RW',
        'KN',
        'WS',
        'SM',
        'ST',
        'SA',
        'SN',
        'RS',
        'SC',
        'SL',
        'SG',
        'SX',
        'SK',
        'SI',
        'SB',
        'SO',
        'ZA',
        'SS',
        'ES',
        'LK',
        'SD',
        'SR',
        'SZ',
        'SE',
        'CH',
        'SY',
        'TW',
        'TJ',
        'TZ',
        'TH',
        'TG',
        'TK',
        'TO',
        'TT',
        'TN',
        'TR',
        'TM',
        'TC',
        'TV',
        'UG',
        'UA',
        'AE',
        'GB',
        'US',
        'UY',
        'UZ',
        'VU',
        'VE',
        'VN',
        'VI',
        'YE',
        'ZM',
        'ZW'
    ];

    const handleChange = (e) => {
        e.preventDefault();
    };

    const { next } = navigation;
    var hasPostal = false;
    if (formData.postalad == 'false') {
        hasPostal = true;
    }


    useEffect(() => {
        if (priContactType === 'Mobile' ||
            priContactType == undefined ||
            priContactType === '' ||
            priContactType === null) {

            formData.priContactType = 'Mobile';
        }

        formData.isUserAddressSame = isUserAddressSame;
        var d = new Date();
        const date = document.querySelector('#date').value;

        formData.dateOfBirth = date;


        //
        $('.toogles').click(function () {
            $('.toogles').removeClass('active');
            $(this).addClass('active');
        });
        $('.toogles1').click(function () {
            $('.toogles1').removeClass('active');
            $(this).addClass('active');
        });
        $('.step-change').click(function () {
            $('.step-change').removeClass('active');
            $(this).addClass('active');
        });
        $('document').ready(function () {
            $("input[type='tel']").attr('readonly', true);
        });

        $('.flagsave').click(function () {
            let contactCountryCode = $('.form-control').val();
            localStorage.setItem('phoneCode', contactCountryCode);
        });


    }, []);

    function setDateformateToValid(date) {
        var d = new Date();

        const datevalue1 = document.querySelector('#date').value;

        formData.dateOfBirth = datevalue1;

        const dateValue = document.querySelector('#date').value;
        formData.dateOfBirth = dateValue;

        if (
            date !== null &&
            date !== 'undefined' &&
            typeof date !== 'undefined' &&
            date.includes('/')
        ) {
            let dat = date.split('/');
            dat = dat[2] + '-' + dat[1] + '-' + dat[0];
            return dat;
        }
    }

    function curd(selectedDate) {
        var setDate = new Date(setDateformateToValid(selectedDate));
        var d = new Date();
        var pastYear = d.getFullYear() - 18;
        d.setFullYear(pastYear);
        if (d < setDate) {
            return true;
        } else {
            return false;
        }
    }

    function setDateformateToValid(date) {
        var d = new Date();

        if (
            date !== null &&
            date !== 'undefined' &&
            typeof date !== 'undefined' &&
            date.includes('/')
        ) {
            let dat = date.split('/');
            dat = dat[2] + '-' + dat[1] + '-' + dat[0];
            return dat;
        }
    }
    formData.fullName = fullName ? fullName.trim() : ""


    const saveandexit = () => {

        var d = new Date();

        const date = document.querySelector('#date').value;

        formData.dateOfBirth = date;
        if (
            priContactType === 'Mobile' ||
            priContactType === undefined ||
            priContactType === '' ||
            priContactType === null
        ) {
            if (
                formData.codeVerify != 'false'
            ) {
                func();
            } else {
                swal({
                    text: 'Please click on the button to verify your number, then enter the unique verification code, and click the SUBMIT button before continuing '
                });
            }
        } else {
            if (
                formData.codeVerify != 'false'
            ) {
                func();
            } else {
                swal({
                    text: 'Please click on the button to verify your number, then enter the unique verification code, and click the SUBMIT button before continuing '
                });
            }
        }
    };

    function previousclcik(e) {
        window.scrollTo(0, 0);
    
        var d = new Date();
    
        const date = document.querySelector('#date').value;
    
        formData.dateOfBirth = date;
    
        const dateValue = document.querySelector('#date').value;
        formData.dateOfBirth = dateValue;
    
        $('.error').html('');
        var btnVal = $('.postalAddressButton').hasClass('active');
    
        var residentialAddr = $('#resAddr').val();
        formData.userResidentialAddress = residentialAddr;
        let residetialGoglemap = $('#resAddr').data('val');
        formData.userResidentialAddressGoogleAddress = residetialGoglemap;
    
        var residentialAddrs = $('#resAddrs').val();
        
        formData.userPostalAddress = residentialAddrs;
        if (formData.professinalTrustee == "true") {
          formData.postalad = 'false';
          formData.class = 'Jointhird';
          go('TrustFour');
        }
        else {
          formData.postalad = 'false';
          formData.class = 'Jointhird';
          go('TrustThird');
        }
      }
    const [otp, setOtp] = useState('Verify mobile');
    const [otpdone, setOtpdone] = useState(false);
    const [checkPhon, setCheckPhon] = useState(true);
    const [optLoader, setOptLoader] = useState(false);

    const [otp1, setOtp1] = useState('VERIFY WITH PHONE CALL');
    const [otpdone1, setOtpdone1] = useState(false);
    const [checkPhon1, setCheckPhon1] = useState(true);

    function handleClick12(e) {
        let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
        let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
        formData.country = selected123;
        formData.lastName = ""
        formData.middleName = ""
        var d = new Date();

        const date = document.querySelector('#date').value;

        formData.dateOfBirth = date;

        const dateValue = document.querySelector('#date').value;
        formData.dateOfBirth = dateValue;

        $('.error').html('');
        var btnVal = $('.postalAddressButton').hasClass('active');
        manuallyAddress === "false"
            ?
            formData.isPrimaryResdAddress = formData.street_number + "," + formData.region + "," + formData.city + "," + formData.postalCode + "," + formData.country
            :
            console.log("dd")
        if (formData.isPrimaryResdAddress === ",,,,NZ") {
            formData.isPrimaryResdAddress = ""
        }
        // var residentialAddrs = $('#resAddrs').val();
        // formData.userPostalAddress = residentialAddrs;
        if (fullName === '' || fullName === undefined || fullName === null) {
            $('.fullNameerror').html('Please enter your full legal name ')
        }

        if (
            typeof formData.dateOfBirth == 'undefined' ||
            formData.dateOfBirth === '' ||
            formData.dateOfBirth === null
        ) {
            $('.doberror').html('Please enter your date of birth');
        } else if (
            curd(formData.dateOfBirth) ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.doberror').html('Please enter valid date of birth');
        } else if (
            formData.dateOfBirth.split('/')[0] > 31 ||
            formData.dateOfBirth.split('/')[0] == 0 ||
            formData.dateOfBirth.split('/')[1] == 0 ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.titleerror12').html('Please enter valid date of birth');

        } if (formData.isPrimaryResdAddress == "" || formData.isPrimaryResdAddress === null || formData.isPrimaryResdAddress === "undefined") {
            $('.residential').html('Please enter your address ')
        }
        if ((formData.isPrimaryPostalAdress === '' || formData.isPrimaryPostalAdress === undefined || formData.isPrimaryPostalAdress === null) && isUserAddressSame === "false") {
            $('.postalError').html('Please enter your postal address ')
        }
        if ((formData.priContactNumber === '' || formData.priContactNumber === undefined || formData.priContactNumber === null)) {
            $('.enterPhone').html('Please enter your phone number and click to verify this ')
        }
        if ((otp == "Verify mobile") && formData.codeVerify === 'false' && (priContactType === 'Mobile')) {
            $('.errIfno').html('Please click to verify your mobile number ')
        }
        if ((otp1 == "VERIFY WITH PHONE CALL") && formData.codeVerify === 'false' && priContactType !== 'Mobile'
        ) {
            $('.errIfno').html('Please click to verify your contact number  ')
        }
        if ((formData.verification === '' || formData.verification === undefined || formData.verification === null) && formData.codeVerify === "false") {
            $('.verificationerror').html('Please enter your unique verification code')
        }
        // if ((formData.secContactNumber === '' || formData.secContactNumber === undefined || formData.secContactNumber === null) && addnumber === true) {
        //   $('.addNumber').html('Please enter your additional number ')
        // }

        if ((formData.street_number == null || formData.street_number == "" || formData.street_number == undefined || formData.street_number == "undefined") && manuallyAddress === "false") {
            $('.street_numbererror').html('Please enter your street number ')
        }
        if ((formData.city == "" || formData.city == null || formData.city == "undefined") && manuallyAddress === "false") {
            $('.cityerror').html('Please enter your city')

        }
        if ((formData.postalCode == "" || formData.postalCode == null || formData.postalCode == "undefined") && manuallyAddress === "false") {
            $('.postalCodeerror').html('Please enter your postal code')

        }



        if (fullName === '' || fullName === undefined || fullName === null) {
            $('.fullNameerror').html('Please enter your full legal name ')
        }

        else if (
            typeof formData.dateOfBirth == 'undefined' ||
            formData.dateOfBirth === '' ||
            formData.dateOfBirth === null
        ) {
            $('.doberror').html('Please enter your date of birth');
        } else if (
            curd(formData.dateOfBirth) ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.doberror').html('Please enter valid date of birth');
        } else if (
            formData.dateOfBirth.split('/')[0] > 31 ||
            formData.dateOfBirth.split('/')[0] == 0 ||
            formData.dateOfBirth.split('/')[1] == 0 ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.titleerror12').html('Please enter valid date of birth');

        } else if (formData.isPrimaryResdAddress === "" || formData.isPrimaryResdAddress === null || formData.isPrimaryResdAddress === "undefined") {
            $('.residential').html('Please enter your address')
        } else if ((formData.isPrimaryPostalAdress === '' || formData.isPrimaryPostalAdress === undefined || formData.isPrimaryPostalAdress === null) && isUserAddressSame === "false") {
            $('.postalError').html('Please enter your postal address ')
        }
        else if ((formData.street_number == null || formData.street_number == "" || formData.street_number == undefined || formData.street_number == "undefined") && manuallyAddress === "false") {
            $('.street_numbererror').html('Please enter your street number ')
        }
        else if ((formData.city == "" || formData.city == null || formData.city == "undefined") && manuallyAddress === "false") {
            $('.cityerror').html('Please enter your city')

        }
        else if ((formData.postalCode == "" || formData.postalCode == null || formData.postalCode == "undefined") && manuallyAddress === "false") {
            $('.postalCodeerror').html('Please enter your postal code')

        }
        else if ((formData.priContactNumber === '' || formData.priContactNumber === undefined || formData.priContactNumber === null)) {
            $('.enterPhone').html('Please enter your phone number and click to verify this ')
        }
        else if ((otp == "Verify mobile") && formData.codeVerify === 'false' && (priContactType === 'Mobile' ||
            priContactType === undefined ||
            priContactType === '' ||
            priContactType === null)) {
            $('.errIfno').html('Please click to verify your mobile number ')
        }
        else if ((otp1 == "VERIFY WITH PHONE CALL") && formData.codeVerify === 'false' && priContactType !== 'Mobile'
        ) {
            $('.errIfno').html('Please click to verify your contact number  ')
        }
        else if ((formData.verification === '' || formData.verification === undefined || formData.verification === null) && formData.codeVerify === "false") {
            $('.verificationerror').html('Please enter your unique verification code')
        }
        // else if ((formData.secContactNumber === '' || formData.secContactNumber === undefined || formData.secContactNumber === null) && addnumber === true) {
        //   $('.addNumber').html('Please enter your additional number ')
        // }
        else if (formData.codeVerify === 'false') {
            $('.sumbitCodeError').html(' Please click to submit your verification code')
        }

        else {
            if (btnVal) {
                formData.postalad = 'false';
                if (
                    formData.codeVerify != 'false'
                ) {
                    go("Four");
                } else {
                    swal({
                        text: 'Please click on the button to verify your number, then enter the unique verification code, and click the SUBMIT button before continuing '
                    });
                }
            } else {
                formData.postalad = 'false';
                if (formData.codeVerify != 'false') {
                    go("Four");
                } else {
                    swal({
                        text: 'Please click on the button to verify your number, then enter the unique verification code, and click the SUBMIT button before continuing '
                    });
                }
            }
        }

    }

    useEffect(() => {
        if (formData.isUserAddressSame == "true" || formData.isUserAddressSame == true) {
            formData.isPrimaryPostalAdress = "";

        }
    });

    function handleClick123(e) {
        let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
        let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
        formData.country = selected123;
        formData.lastName = ""
        formData.middleName = ""
        var d = new Date();

        const date = document.querySelector('#date').value;

        formData.dateOfBirth = date;

        const dateValue = document.querySelector('#date').value;
        formData.dateOfBirth = dateValue;

        $('.error').html('');
        var btnVal = $('.postalAddressButton').hasClass('active');
        manuallyAddress === "false"
            ?
            formData.isPrimaryResdAddress = formData.street_number + "," + formData.region + "," + formData.city + "," + formData.postalCode + "," + formData.country
            :
            console.log("dd")
        if (formData.isPrimaryResdAddress === ",,,,NZ") {

            formData.isPrimaryResdAddress = ""
        }
        // var residentialAddrs = $('#resAddrs').val();
        // formData.userPostalAddress = residentialAddrs;
        if ((formData.street_number == null || formData.street_number == "" || formData.street_number == undefined || formData.street_number == "undefined") && manuallyAddress === "false") {
            $('.street_numbererror').html('Please enter your street number ')
        }
        if ((formData.city == "" || formData.city == null || formData.city == "undefined") && manuallyAddress === "false") {
            $('.cityerror').html('Please enter your city')

        }
        if ((formData.postalCode == "" || formData.postalCode == null || formData.postalCode == "undefined") && manuallyAddress === "false") {
            $('.postalCodeerror').html('Please enter your postal code')

        }

        if (fullName === '' || fullName === undefined || fullName === null) {
            $('.fullNameerror').html('Please enter your full legal name ')
        }
        if (
            typeof formData.dateOfBirth == 'undefined' ||
            formData.dateOfBirth === '' ||
            formData.dateOfBirth === null
        ) {
            $('.doberror').html('Please enter your date of birth');
        } else if (
            curd(formData.dateOfBirth) ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.doberror').html('Please enter valid date of birth');
        } else if (
            formData.dateOfBirth.split('/')[0] > 31 ||
            formData.dateOfBirth.split('/')[0] == 0 ||
            formData.dateOfBirth.split('/')[1] == 0 ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.titleerror12').html('Please enter valid date of birth');

        } if (formData.isPrimaryResdAddress === "" || formData.isPrimaryResdAddress === null || formData.isPrimaryResdAddress === "undefined") {
            $('.residential').html('Please enter your address')
        }
        if ((formData.isPrimaryPostalAdress === '' || formData.isPrimaryPostalAdress === undefined || formData.isPrimaryPostalAdress === null) && isUserAddressSame === "false") {
            $('.postalError').html('Please enter your postal address ')
        }
        if ((formData.priContactNumber === '' || formData.priContactNumber === undefined || formData.priContactNumber === null)) {
            $('.enterPhone').html('Please enter your phone number and click to verify this ')
        }
        if ((otp == "Verify mobile") && formData.codeVerify === 'false' && (priContactType === 'Mobile' ||
            priContactType === undefined ||
            priContactType === '' ||
            priContactType === null)) {
            $('.errIfno').html('Please click to verify your mobile number ')
        }
        if ((otp1 == "VERIFY WITH PHONE CALL") && formData.codeVerify === 'false' && priContactType !== 'Mobile'
        ) {
            $('.errIfno').html('Please click to verify your contact number  ')
        }
        if ((formData.verification === '' || formData.verification === undefined || formData.verification === null) && formData.codeVerify === "false") {
            $('.verificationerror').html('Please enter your unique verification code')
        }
        // if ((formData.secContactNumber === '' || formData.secContactNumber === undefined || formData.secContactNumber === null) && addnumber === true) {
        //   $('.addNumber').html('Please enter your additional number ')
        // }




        if (fullName === '' || fullName === undefined || fullName === null) {
            $('.fullNameerror').html('Please enter your full legal name ')
        }

        else if (
            typeof formData.dateOfBirth == 'undefined' ||
            formData.dateOfBirth === '' ||
            formData.dateOfBirth === null
        ) {
            $('.doberror').html('Please enter your date of birth');
        } else if (
            curd(formData.dateOfBirth) ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.doberror').html('Please enter valid date of birth');
        } else if (
            formData.dateOfBirth.split('/')[0] > 31 ||
            formData.dateOfBirth.split('/')[0] == 0 ||
            formData.dateOfBirth.split('/')[1] == 0 ||
            formData.dateOfBirth.split('/')[1] > 12 ||
            formData.dateOfBirth.split('/')[2] < 1900
        ) {
            $('.titleerror12').html('Please enter valid date of birth');

        } else if (formData.isPrimaryResdAddress === "" || formData.isPrimaryResdAddress === null || formData.isPrimaryResdAddress === "undefined" || formData.isPrimaryResdAddress === undefined) {
            $('.residential').html('Please enter your address')
        } else if ((formData.isPrimaryPostalAdress === '' || formData.isPrimaryPostalAdress === undefined || formData.isPrimaryPostalAdress === null) && isUserAddressSame === "false") {
            $('.postalError').html('Please enter your postal address ')
        }
        else if ((formData.street_number == null || formData.street_number == "" || formData.street_number == undefined || formData.street_number == "undefined") && manuallyAddress === "false") {
            $('.street_numbererror').html('Please enter your street number ')
        }
        else if ((formData.city == "" || formData.city == null || formData.city == "undefined") && manuallyAddress === "false") {
            $('.cityerror').html('Please enter your city')

        }
        else if ((formData.postalCode == "" || formData.postalCode == null || formData.postalCode == "undefined") && manuallyAddress === "false") {
            $('.postalCodeerror').html('Please enter your postal code')

        }
        else if ((formData.priContactNumber === '' || formData.priContactNumber === undefined || formData.priContactNumber === null)) {
            $('.enterPhone').html('Please enter your phone number and click to verify this ')
        }
        else if ((otp == "Verify mobile") && formData.codeVerify === 'false' && (priContactType === 'Mobile' ||
            priContactType === undefined ||
            priContactType === '' ||
            priContactType === null)) {
            $('.errIfno').html('Please click to verify your mobile number ')
        }
        else if ((otp1 == "VERIFY WITH PHONE CALL") && formData.codeVerify === 'false' && priContactType !== 'Mobile'
        ) {
            $('.errIfno').html('Please click to verify your contact number  ')
        }
        else if ((formData.verification === '' || formData.verification === undefined || formData.verification === null) && formData.codeVerify === "false") {
            $('.verificationerror').html('Please enter your unique verification code')
        }
        // else if ((formData.secContactNumber === '' || formData.secContactNumber === undefined || formData.secContactNumber === null) && addnumber === true) {
        //   $('.addNumber').html('Please enter your additional number ')
        // }
        else if (formData.codeVerify === 'false') {
            $('.sumbitCodeError').html(' Please click to submit your verification code')
        }



        else {
            if (btnVal) {
                formData.postalad = 'false';
                if (formData.codeVerify != 'false') {
                    go("Four");
                } else {
                    swal({
                        text: 'Please click on the button to verify your number, then enter the unique verification code, and click the SUBMIT button before continuing '
                    });
                }
            }
            else {
                formData.postalad = 'false';
                if (formData.codeVerify != 'false') {
                    go("Four");
                    

                } else {
                    swal({
                        text: 'Please click on the button to verify your number, then enter the unique verification code, and click the SUBMIT button before continuing '
                    });
                }
            }
        }

    }

    function datechange() {
        var d = new Date();

        const date = document.querySelector('#date').value;

        formData.dateOfBirth = date;

        const dateValue = document.querySelector('#date').value;

        formData.dateOfBirth = dateValue;
    }

    let dateOfBirth = sessionStorage.getItem('dateOfBirth');
    let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
    let PostalAddress1 = sessionStorage.getItem('PostalAddress1');

    useEffect(() => {
        let dateOfBirth = sessionStorage.getItem('dateOfBirth');
        $('.ui.icon.input>input').val(dateOfBirth);
    });
    function birthdates() {
        dateOfBirth = $('.ui.icon.input>input').val();
        sessionStorage.setItem('dateOfBirth', dateOfBirth);
    }
    function removeError() {
        $('.error').html('');
    }
    $('input').on('paste', function (e) {
        $('.error').html('');
    });
    const { go } = navigation;





    function removeError() {
        $('.error').html('');
    }

    const resendOtp = async () => {
        await axios
            .post(twilio + '/start-verify', optdatat, {
                headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
                res.status === 200
                    ? ResendDataotp()
                    : Swal.fire(
                        '',
                        'Unfortunately an error has occurred while generating your OTP.',
                        ''
                    );
            })
            .catch((error) => {
                Swal.fire(
                    '',
                    'Unfortunately an error has occurred while generating your OTP.',
                    ''
                );
            });
    };
    const resendOtp1 = async () => {
        await axios
            .post(twilio + '/start-verify', optdatatcall, {
                headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
                res.status === 200
                    ? ResendDataotp()
                    : Swal.fire(
                        '',
                        'Unfortunately an error has occurred while submitting your OTP.',
                        ''
                    );
            })
            .catch((error) => {
                Swal.fire(
                    '',
                    'Unfortunately an error has occurred while submitting your OTP.',
                    ''
                );
            });
    };

    let optdatat = {
        to: '+' + formData.priContactCountryCode + ' ' + priContactNumber,
        channel: 'sms',
        locale: 'en'
    };
    let optdatatcall = {
        to: '+' + formData.priContactCountryCode + ' ' + priContactNumber,
        channel: 'call',
        locale: 'en'
    };
    const onSubmitotp = async () => {
        setOtp('Please wait');
        await axios
            .post(twilio + '/start-verify', optdatat, {
                headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
                res.status === 200
                    ? setOtp('UNIQUE VERIFICATION CODE SENT')
                    : setOtp('UNIQUE VERIFICATION CODE SENT');
            })
            .catch((error) => {
                setOtp('UNIQUE VERIFICATION CODE SENT');

            });
    };
    const onSubmitotp1 = async () => {
        setOtp1('Please wait');
        await axios
            .post(twilio + '/start-verify', optdatatcall, {
                headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
                res.status === 200
                    ? setOtp1('UNIQUE VERIFICATION CODE SENT')
                    : setOtp1('UNIQUE VERIFICATION CODE SENT');
            })
            .catch((error) => {
                setOtp1('UNIQUE VERIFICATION CODE SENT');
            });
    };
    VerifyData = () => {

        Swal.fire({
            html: 'We have sent a text message containing a unique verification number to the above mobile number. If you have not received this code within 30 seconds, please click on the link below to request another verification code',

            showCancelButton: true,
            confirmButtonText: `Resend Code`,
            cancelButtonText: 'CANCEL'
        }).then((result) => {
            if (result.isConfirmed) {

                resendOtp();
            }
        });
    };
    VerifyData1 = () => {
        Swal.fire({
            html: 'We have sent a text message containing a unique verification number to the above mobile number. If you have not received this code within 30 seconds, please click on the link below to request another verification code',

            showCancelButton: true,
            confirmButtonText: `Resend Code`,
            // denyButtonText: `Don't save`
            cancelButtonText: 'CANCEL'
        }).then((result) => {
            if (result.isConfirmed) {
                resendOtp1();
            }
        });
    };
    ResendDataotp = () => {
        Swal.fire({ html: 'Please check your mobile for the verification code.' });
    };

    function verifyMobile(e) {
        if (
            typeof priContactNumber == 'undefined' ||
            priContactNumber === '' ||
            priContactNumber === null
        ) {
            $('.mobileerror').html('Please enter your phone number including 021, 022, 027,  etc');
        } else if (priContactNumber.length < 9) {
            $('.mobileerror').html('Please enter your phone number including 021, 022, 027, etc');
        } else if (e.length !== 9) {
            removeError();
            onSubmitotp();
        }
    }

    function verifyMobile1(e) {
        if (
            typeof priContactNumber == 'undefined' ||
            priContactNumber === '' ||
            priContactNumber === null
        ) {
            $('.mobileerror').html('Enter your phone number including area code');
        } else if (priContactNumber.length <= 9) {
            $('.mobileerror').html('Enter your phone number including area codec');
        } else if (e.length !== 9) {
            removeError();
            onSubmitotp1();
        }
    }


    function setPass(e) {
        formData.codeVerify = 'false';
        setCheckPhon(false);
        formData.verification = '';

        setOtp('Verify mobile');
        if (e.length !== 9) {
            $('.eerror').html('Please enter your phone number including 021, 022, 027, etc');
        } else {
            removeError();
        }
    }

    console.log(" formData.codeVerify formData.codeVerify", formData.codeVerify)

    function setPass1(e) {
        formData.codeVerify = 'false';
        setCheckPhon1(false);
        // alert("dd2322")

        formData.verification = '';

        setOtp1('VERIFY WITH PHONE CALL');
        $('.eerror').html('Please entjjjjer yoggur phone number including 021, 022, 027, etc');
        if (e.length !== 9) {
            $('.eerror').html('Enter your phone number including area code');
        }
        if (priContactType === 'Home' ||
            priContactType === 'Work' ||
            priContactType === 'Other') {
            $('.eerror').html('Please enter your phone number including the area code, eg 07, 09, 021, 022, etc');
        } else {
            removeError();
        }
    }

    function setPass3(e) {
        if (e.length !== 9) {
            $('.eerror4').html('Enter your phone number including area code');
        } else {
            removeError();
        }
    }

    const tyetsyv = () => {
        setCheckPhon(false);
        formData.codeVerify = 'false'
        formData.verification = '';
        setOtp('Verify mobile');

        optdatat = {
            to: '+' + formData.priContactCountryCode + ' ' + priContactNumber,
            channel: 'sms',
            locale: 'en'
        };
    };

    const tyetsyv1 = () => {
        formData.codeVerify = 'false';

        setCheckPhon1(false);
        formData.verification = '';
        setOtp1('VERIFY WITH PHONE CALL');

        optdatat = {
            to: '+' + formData.priContactCountryCode + ' ' + priContactNumber,
            channel: 'sms',
            locale: 'en'
        };
    };

    let verdata = {
        to: '+' + formData.priContactCountryCode + ' ' + priContactNumber,
        verification_code: formData.verification,
        investmentType: formData.investmentType
    };


    const verff = () => {
        formData.verification = '';
        formData.codeVerify = 'true';
        setOptLoader(false);
        setOtpdone(true);
        setCheckPhon(true);
        setOtp('Number Verified');
    };

    const verff1 = () => {
        formData.verification = '';
        formData.codeVerify = 'true';

        setOptLoader(false);
        setOtpdone1(true);
        setCheckPhon1(true);
        setOtp1('Number Verified');
    };

    const SaveVerificationCode = () => {
        formData.codeVerify = 'true';
        verff();
    };
    const onSubmitverifyotp1 = async () => {

        setOptLoader(true);
        await axios
            .post(twilio + '/check-verify', verdata, {
                headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
                res.status === 200 && res.data.status !== 'pending'
                    ? SaveVerificationCode()
                    : $('.verificationerror').html(
                        'Incorrect verification code entered. Please try again.'
                    );
            })
            .catch((error) => {
                setOptLoader(false);

                $('.verificationerror').html('Incorrect verification code entered. Please try again.');
            });

    };

    const SaveVerificationCode1 = () => {
        formData.codeVerify = 'true';
        verff1();
    };

    const onSubmitverifyotp12 = async () => {

        setOptLoader(true);
        await axios
            .post(twilio + '/check-verify', verdata, {
                headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
                res.status === 200 && res.data.status !== 'pending'
                    ? SaveVerificationCode1()
                    : $('.verificationerror').html(
                        'Incorrect verification code entered. Please try again.'
                    );
            })
            .catch((error) => {
                setOptLoader(false);

                $('.verificationerror').html('Incorrect verification code entered. Please try again.');
            });

    };
    const [tesf, setTesf] = useState()
    const [conLoader, setConLoader] = useState(true)

    useEffect(() => {
        const timer = setTimeout(
            () => {
                setTesf(true);
                setConLoader(false);
            },
            1500
        );

    }, []);
    useEffect(() => {
        if (isUserAddressSame == true || isUserAddressSame == "true") {
            sessionStorage.setItem("PostalAddress1", "")
            formData.PostalAddress1 = ""
        }

    })

    const ResetName = () => {

        formData.lastName = ""
        formData.middleName = ""
    }

    const chenging = () => {
        sessionStorage.removeItem("street_number");
        sessionStorage.removeItem("locality");
        sessionStorage.removeItem("administrative_area_level_1");
        sessionStorage.removeItem("sublocality_level_1");
        sessionStorage.removeItem("postal_code");
        sessionStorage.removeItem("premise");
        sessionStorage.removeItem("route");
        sessionStorage.removeItem("country");
        sessionStorage.removeItem("residentialAddress1")
    };
    const chenging2 = () => {
        formData.street_number = ""
        formData.city = "";
        formData.country = "NZ";
        formData.postalCode = "";
        formData.region = "";
        formData.isPrimaryResdAddress = ""
    };




    const residentialSave1 = (e) => {
        formData.street_number = e.target.value
        formData.isPrimaryResdAddress = e.target.value
    }

    const residentialSave7 = (e) => {
        formData.region = e.target.value
        formData.isPrimaryResdAddress = formData.street_number + (e.target.value != null ? "," + e.target.value : "")
    }

    const residentialSave3 = (e) => {
        formData.city = e.target.value
        formData.isPrimaryResdAddress = formData.street_number + (formData.region != null ? "," + formData.region : "") + (e.target.value != null ? "," + e.target.value : "")

    }
    const residentialSave6 = (e) => {

        formData.postalCode = e.target.value
        formData.isPrimaryResdAddress = formData.street_number + (formData.region != null ? "," + formData.region : "") + (formData.city != null ? "," + formData.city : "") + (e.target.value != null ? "," + e.target.value : "")

    }
    const residentialSave4 = (e) => {

        formData.country = e.target.value
        formData.country = selected123;
        formData.isPrimaryResdAddress = formData.street_number + (formData.region != null ? "," + formData.region : "") + (formData.city != null ? "," + formData.city : "") + (formData.postalCode != null ? "," + formData.postalCode : "") + (e.target.valuey != null ? "," + e.target.value : "")
    }
    // const residentialSave5 = (e) => {
    //   formData.countryyCode = e.target.value

    // }

    useEffect(() => {
        if (formData.manuallyAddress == "false") {
            sessionStorage.removeItem("street_number");
            sessionStorage.removeItem("locality");
            sessionStorage.removeItem("administrative_area_level_1");
            sessionStorage.removeItem("sublocality_level_1");
            sessionStorage.removeItem("postal_code");
            sessionStorage.removeItem("premise");
            sessionStorage.removeItem("route");
            sessionStorage.removeItem("country");
            sessionStorage.removeItem("residentialAddress1")
        }
        else if (formData.manuallyAddress == "true") {
            formData.street_number = ""
            formData.city = "";
            formData.country = "";
            formData.postalCode = "";
            formData.region = "";
            // formData.isPrimaryResdAddress = ""
        }
    }, [])
    const [selected123, setSelected123] = useState(
        formData.country !== null &&
            formData.country !== '' &&
            formData.country !== 'undefined' &&
            formData.country !== undefined
            ? formData.country
            : 'NZ'
    );
    formData.country = selected123;


    useEffect(() => {
        if (formData.check === false) {
            if (fullName === '' || fullName === undefined || fullName === null) {
                $('.fullNameerror').html('Please enter your full legal name ')
            }

            if (
                typeof formData.dateOfBirth == 'undefined' ||
                formData.dateOfBirth === '' ||
                formData.dateOfBirth === null
            ) {
                $('.doberror').html('Please enter your date of birth');
            } else if (
                curd(formData.dateOfBirth) ||
                formData.dateOfBirth.split('/')[1] > 12 ||
                formData.dateOfBirth.split('/')[2] < 1900
            ) {
                $('.doberror').html('Please enter valid date of birth');
            } else if (
                formData.dateOfBirth.split('/')[0] > 31 ||
                formData.dateOfBirth.split('/')[0] == 0 ||
                formData.dateOfBirth.split('/')[1] == 0 ||
                formData.dateOfBirth.split('/')[1] > 12 ||
                formData.dateOfBirth.split('/')[2] < 1900
            ) {
                $('.titleerror12').html('Please enter valid date of birth');

            } if (formData.isPrimaryResdAddress === "" || formData.isPrimaryResdAddress === null || formData.isPrimaryResdAddress === "undefined" || formData.isPrimaryResdAddress === undefined) {
                $('.residential').html('Please enter your address ')
            }
            if ((formData.isPrimaryPostalAdress === '' || formData.isPrimaryPostalAdress === undefined || formData.isPrimaryPostalAdress === null) && isUserAddressSame === "false") {
                $('.postalError').html('Please enter your postal address ')
            }
            if ((formData.priContactNumber === '' || formData.priContactNumber === undefined || formData.priContactNumber === null)) {
                $('.enterPhone').html('Please enter your phone number and click to verify this ')
            }
            if ((otp == "Verify mobile") && formData.codeVerify === 'false' && (priContactType === 'Mobile')) {
                $('.errIfno').html('Please click to verify your mobile number ')
            }
            if ((otp1 == "VERIFY WITH PHONE CALL") && formData.codeVerify === 'false' && priContactType !== 'Mobile'
            ) {
                $('.errIfno').html('Please click to verify your contact number  ')
            }
            if ((formData.verification === '' || formData.verification === undefined || formData.verification === null) && formData.codeVerify === "false") {
                $('.verificationerror').html('Please enter your unique verification code')
            }
            // if ((formData.secContactNumber === '' || formData.secContactNumber === undefined || formData.secContactNumber === null) && addnumber === true) {
            //   $('.addNumber').html('Please enter your additional number ')
            // }

            if ((formData.street_number == null || formData.street_number == "" || formData.street_number == undefined || formData.street_number == "undefined") && manuallyAddress === "false") {
                $('.street_numbererror').html('Please enter your street number ')
            }
            if ((formData.city == "" || formData.city == null || formData.city == "undefined") && manuallyAddress === "false") {
                $('.cityerror').html('Please enter your city')

            }
            if ((formData.postalCode == "" || formData.postalCode == null || formData.postalCode == "undefined") && manuallyAddress === "false") {
                $('.postalCodeerror').html('Please enter your postal code')

            }


        }
    })

    function removeErrorOnlyOne(valueClass) {
        $(`.${valueClass}`).html('');
    }
    // alert(manuallyAddress)
    return (
        <>
            <MenuSidebar navigation={navigation} formData={formData} />
            <div className="main_container">
                <div className="heading__top">
                    <h2> Step 2: Personal Information - {' '}
                        {(formData.fullName != null && formData.fullName != undefined && formData.fullName != "" ? formData.fullName : '')}

                    </h2>
                </div>

                <div className="form ">
                    <img src={logo} alt="img" className="imageCover" />
                    <div className="save-exit se_invff">
                        <p className="save next" onClick={saveandexit}>
                            SAVE DATA{' '}
                        </p>
                        <img src={info} onClick={infodetls} className="info" />
                    </div>


                    <Row>
                        <Col md={12}>
                            <label>Full legal name</label>
                            <ItemForm
                                autoComplete="off"
                                type="text"
                                name="fullName"
                                onKeyPress={() => removeErrorOnlyOne("fullNameerror")}
                                maxLength={170}
                                className="quantity"
                                id="fullName"
                                onPaste={handleChange}
                                onChange={(e) => {
                                    setForm(e);
                                    removeErrorOnlyOne("fullNameerror")
                                    ResetName();

                                }}
                                onClick={ResetName}
                                value={fullName}
                                placeholder="Enter full legal name"
                            />
                            <span className="error fullNameerror"></span>
                        </Col>
                        <Col md={12}>
                            {' '}
                            <label>Preferred name (if applicable)</label>
                            <ItemForm
                                autoComplete="off"
                                name="preferredName"
                                className="quantity"
                                // onKeyPress={removeError}
                                value={preferredName}
                                maxLength={170}
                                onPaste={handleChange}
                                onChange={(e) => {
                                    setForm(e);
                                    // removeError();
                                }}
                                placeholder="Enter preferred name"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="date-w">
                            <label>Date of birth</label>

                            <SemanticDatepicker
                                autoComplete="off"
                                className="datemy"
                                onPaste={handleChange}
                                id="date"
                                clearIcon="close"
                                onClick={() => removeErrorOnlyOne("errorData")
                                }
                                onChange={(e) => {
                                    removeErrorOnlyOne("errorData")
                                        ;
                                    datechange();
                                    birthdates();
                                }}
                                maxDate={new Date(moment().subtract(18, 'years'))}
                                name="date"
                                format="DD/MM/YYYY"
                                allowOnlyNumbers
                                value={dateOfBirth ? new Date(setDateformateToValid(dateOfBirth)) : null}
                            />

                            <span className="error errorData doberror" />
                            <span className="error errorData titleerror12" />
                            <span className="error errorData titleerror13" />
                            <span className="error errorData titleerror14" />
                            <div class="address_data">



                                <Row>
                                    <Col md={9}>

                                        {manuallyAddress === "true" || manuallyAddress == "undefined" || manuallyAddress == undefined || manuallyAddress == null ?
                                            <>
                                                <SearchAddress
                                                    formData={formData}
                                                    className='hj'
                                                    placeholder="Enter home address"
                                                    label="Residential address"
                                                    name="isPrimaryResdAddress"
                                                    value2={formData.isPrimaryResdAddress}
                                                />
                                                {manuallyAddress === "true" ? <span className="error residential"></span> : null}
                                            </>



                                            : manuallyAddress === "false" ?
                                                <Row >
                                                    <Col >
                                                        <label>Street number & name</label>
                                                        <input
                                                            className='hj'
                                                            type="text"
                                                            name="street_number"
                                                            placeholder="Enter street number & name"

                                                            onChange={(e) => {

                                                                residentialSave1(e);
                                                                setForm(e);
                                                                removeErrorOnlyOne("street_numbererror")
                                                            }}
                                                            value={street_number}
                                                        />
                                                        <span className='error street_numbererror '></span>
                                                        <label>Suburb</label>
                                                        <input
                                                            className='hj'
                                                            type="text"
                                                            name="region"
                                                            placeholder="Enter suburb"
                                                            onChange={(e) => {
                                                                residentialSave7(e);
                                                                setForm(e);

                                                            }}
                                                            value={region}
                                                        />
                                                        <label>City</label>
                                                        <input
                                                            className='hj'
                                                            type="text"
                                                            name="city"
                                                            placeholder="Enter city"
                                                            onChange={(e) => {
                                                                residentialSave3(e);
                                                                setForm(e);
                                                                removeErrorOnlyOne("cityerror")
                                                            }}
                                                            value={city}
                                                        />
                                                        <span className='error cityerror'></span>
                                                        <label>Postal code</label>
                                                        <input
                                                            maxLength={4}
                                                            onInput={(e) => {
                                                                if (e.target.value.length > e.target.maxLength)
                                                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                                            }}
                                                            className="numbers hj"
                                                            type="number"
                                                            name="postalCode"
                                                            onWheel={(e) => e.target.blur()}
                                                            placeholder="Enter postal code"
                                                            onChange={(e) => {
                                                                residentialSave6(e);
                                                                setForm(e);
                                                                removeErrorOnlyOne("postalCodeerror")
                                                            }}
                                                            value={postalCode}
                                                        />
                                                        <span className='error postalCodeerror'></span>
                                                        <label>Country</label>
                                                        <ReactFlagsSelect
                                                            autoComplete="off"
                                                            name="country"
                                                            selected={selected123}
                                                            value={country}
                                                            className="FlagsSelectcss dffk"
                                                            // className="dff"
                                                            placeholder="Select"
                                                            onChange={(e) => {
                                                                residentialSave4(e);
                                                                setForm(e);
                                                            }}
                                                            onSelect={(code) => setSelected123(code)}
                                                            countries={countrtyCode}
                                                            defaultCountry={
                                                                country && country !== null
                                                                    ? getCode(country.toUpperCase())
                                                                    : null
                                                            }
                                                        />

                                                    </Col>

                                                </Row>
                                            : null
                                        }
                                    </Col >
                                    {/* <div className="trust_form"> */}
                                    <Col xs={3}>
                                        <div className="button_group space-bottom switch_botton hjj">
                                            <div class="switch-field nospace">
                                                <div className="check_YN_Bank swtich10 resize">
                                                    <input
                                                        type="radio"
                                                        id="radio-one1"
                                                        className="yesno"
                                                        name="manuallyAddress"
                                                        value="true"
                                                        onChange={(e) => {
                                                            setForm(e);
                                                            chenging2(e);
                                                            removeError();
                                                        }}
                                                        onClick={(e) => chenging2(e)}
                                                    />
                                                    <label
                                                        for="radio-one1"
                                                        className={`toogles1 postalAddressButton ${typeof manuallyAddress === 'undefined' || manuallyAddress === 'true'
                                                            ? 'actives'
                                                            : null
                                                            }`}
                                                        id="show"
                                                    >
                                                        Auto Lookup
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        id="radio-two2"
                                                        name="manuallyAddress"
                                                        value="false"
                                                        onChange={(e) => {
                                                            setForm(e);
                                                            chenging(e);
                                                            removeError();
                                                        }}
                                                        onClick={(e) => chenging(e)}
                                                    />
                                                    <label
                                                        for="radio-two2"
                                                        className={`toogles1 postalAddressButtons ${manuallyAddress === 'false' ? 'actives' : null
                                                            }`}
                                                        id="hide"
                                                    >
                                                        Manually Type
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    {/* </div> */}
                                </Row>

                            </div>
                        </Col>
                    </Row>

                    <Row className=" check-mb">
                        <Col>
                            <label className='mb-1.5'>
                                {/* Is your postal address same as home address? */}
                                Is your postal address the same as your home address?
                            </label>
                        </Col>
                        <Col xs={12}>
                            <div className="button_group space-bottom ">
                                <div class="switch-field nospace">
                                    <div className="check_YN_Bank swtich11">
                                        <input
                                            type="radio"
                                            id="radio-one"
                                            className="yesno"
                                            name="isUserAddressSame"
                                            value="true"
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError();
                                            }}
                                        />

                                        <input
                                            autoComplete="off"
                                            type="radio"
                                            id="radio-two"
                                            name="isUserAddressSame"
                                            value="false"
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-two"
                                            className={`toogles postalAddressButtons ${isUserAddressSame === 'false' ? 'actives' : null
                                                }`}
                                            id="show"
                                        >
                                            No
                                        </label>
                                        <label
                                            for="radio-one"
                                            className={`toogles  postalAddressButton ${isUserAddressSame === 'undefined' ||
                                                isUserAddressSame === undefined ||
                                                isUserAddressSame === 'true' ||
                                                isUserAddressSame === 'true'
                                                ? 'actives'
                                                : null
                                                }`}
                                            id="hide"
                                        >
                                            Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className="col-md-12">

                            {isUserAddressSame === 'false' && tesf === true ? (
                                <>
                                    <SearchAddress2
                                        formData={formData}
                                        placeholder="Enter postal address"
                                        name="maniAdds"
                                        value2={formData.isPrimaryPostalAdress}
                                    />
                                    <span className="error postalError"></span>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </Row>
                    <ItemForm
                        autoComplete="off"
                        className="none"
                        name="postal"
                        value={postal}
                        onChange={postal}
                        placeholder="postal"
                    />

                    <Row className="fullinput fullinputNumber">
                        <Col md={12}>
                            <label>Contact number</label>
                        </Col>
                        <Col md={2} className="d-flex dropdown-mobile">
                            <ContactType1
                                autoComplete="off"
                                type="text"
                                className={
                                    priContactType === 'Mobile' ||
                                        priContactType === undefined ||
                                        priContactType === '' ||
                                        priContactType === null
                                        ? 'colorblack'
                                        : 'colorblack'
                                }

                                onChange={(e) => {
                                    setForm(e);
                                    setPass1(e);
                                }}
                                name="priContactType"
                                value={formData.priContactType}
                            />
                        </Col>

                        {priContactType === 'Mobile' ||
                            priContactType === undefined ||
                            priContactType === '' ||
                            priContactType === null ? (
                            <>
                                <Col md={6} className="d-flex counrtyselext5-phone">
                                    <div className="numberSet">
                                        <PhoneInput
                                            className="counrtyselext5 digitsOnlynone "
                                            readonly
                                            country={'nz'}
                                            preferredCountries={['nz']}
                                            name="priContactCountryCode"
                                            value={formData.priContactCountryCode}
                                            onChange={(phone) => {
                                                formData.priContactCountryCode = phone;
                                                tyetsyv();
                                            }}
                                        />
                                    </div>
                                    <div className="numberSets InputMask-number">
                                        <InputMask
                                            autoComplete="off"
                                            mask="9999999999999"
                                            maskChar={null}
                                            onKeyPress={() => removeErrorOnlyOne("enterPhone")}
                                            maskPlaceholder={null}
                                            className="numbers setinput"
                                            name="priContactNumber"
                                            value={priContactNumber}
                                            onPaste={handleChange}
                                            onChange={(e) => {
                                                setForm(e);
                                                setPass(e);
                                            }}
                                            onClick={(e) => {
                                                setPass(e);
                                            }}
                                            placeholder="Enter number"

                                        />
                                        <span className="error enterPhone " />

                                        <span className="error eerror mobileerror " />
                                        {otp === 'UNIQUE VERIFICATION CODE SENT' ? (
                                            <span className="msg-show">
                                                {' '}
                                                Please check your mobile for the verification code.{' '}
                                            </span>
                                        ) : null}
                                    </div>
                                </Col>

                                <Col xs={4} className="numberSets veri_btn">
                                    {otpdone === true && checkPhon === true ? (
                                        <Button type="button" className="border_trans_btn disable">
                                            NUMBER VERIFIED
                                        </Button>
                                    ) : null}
                                    {formData.codeVerify === 'false' ? (
                                        <>
                                            <button
                                                type="button"
                                                className={
                                                    otp === 'Verify mobile' ? 'border_trans_btn' : 'border_trans_btn disable'
                                                }
                                                onClick={verifyMobile}
                                            >
                                                {otp}
                                            </button>
                                            <span className="error errIfno" />

                                        </>
                                    ) : null}
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col md={6} className="d-flex counrtyselext5-phone">
                                    <div className="numberSet">
                                        <PhoneInput
                                            className="counrtyselext5 digitsOnlynone "
                                            readonly
                                            country={'nz'}
                                            preferredCountries={['nz']}
                                            name="priContactCountryCode"
                                            value={formData.priContactCountryCode}
                                            onChange={(phone) => {
                                                formData.priContactCountryCode = phone;
                                                tyetsyv1();
                                            }}
                                        />
                                    </div>
                                    <div className="numberSets InputMask-number">
                                        <InputMask
                                            autoComplete="off"
                                            mask="9999999999999"
                                            maskChar={null}
                                            onKeyPress={() => removeErrorOnlyOne("errorphone")}
                                            maskPlaceholder={null}
                                            className="numbers setinput"
                                            name="priContactNumber"
                                            value={priContactNumber}
                                            onPaste={handleChange}
                                            onChange={(e) => {
                                                setForm(e);
                                                setPass1(e);

                                                removeErrorOnlyOne("errorphone")
                                            }}
                                            onClick={(e) => {
                                                setPass1(e);
                                            }}
                                            placeholder="Enter number"
                                        />
                                        <span className="error errorphone enterPhone " />

                                        <span className="error errorphone eerror  mobileerror " />
                                        {otp1 === 'UNIQUE VERIFICATION CODE SENT' ? (
                                            <span className="msg-show">
                                                {' '}
                                                You should receive an automated phone call providing the unique verification
                                                code.{' '}
                                            </span>
                                        ) : null}

                                    </div>

                                </Col>

                                <Col xs={4} className="numberSets veri_btn">
                                    {otpdone1 === true && checkPhon1 === true ? (
                                        <Button type="button" className="border_trans_btn disable">
                                            NUMBER VERIFIED
                                        </Button>
                                    ) : null}
                                    {formData.codeVerify === 'false' ? (
                                        <button
                                            type="button"
                                            className={
                                                otp1 === 'VERIFY WITH PHONE CALL'
                                                    ? 'border_trans_btn'
                                                    : 'border_trans_btn disable'
                                            }
                                            onClick={verifyMobile1}
                                        >
                                            {otp1}

                                        </button>
                                    ) : null}

                                    <span className="error errIfno" />
                                </Col>
                            </>
                        )}
                    </Row>
                    {priContactType === 'Mobile' ||
                        priContactType === undefined ||
                        priContactType === '' ||
                        priContactType === null ? (
                        <>
                            {formData.codeVerify === 'false' ? (
                                <Row className="fullinput">
                                    <Col md={12}>
                                        <div className='d-flex align-items-center'>
                                            <label>Verification code</label>
                                            <img
                                                src={info}
                                                className="Info_icon jjjj" alt=""
                                                onClick={VerifyData}

                                            />
                                            <span className="error verifyBtn" id="errorOtp" />

                                        </div>

                                    </Col>
                                    <Col md={6}>
                                        <input
                                            mask="999999"
                                            id="inpt"
                                            maxLength={6}
                                            name="verification"
                                            placeholder="Enter unique verification code"
                                            className="numbers"
                                            value={formData.verification ? formData.verification : ''}
                                            onChange={(e) => {
                                                setForm(e);
                                                removeErrorOnlyOne("verificationerror")
                                            }}
                                        />
                                        <span className="error verificationerror" id="errorOtp" />
                                    </Col>


                                    <Col md={6}>
                                        {optLoader === true ? (
                                            <button className="border_trans_btn">Please wait</button>
                                        ) : (
                                            <>
                                                <button className="border_trans_btn" onClick={onSubmitverifyotp1}>
                                                    SUBMIT
                                                </button>
                                                <span className="error sumbitCodeError" />

                                            </>

                                        )}
                                    </Col>
                                </Row>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {formData.codeVerify === 'false' ? (
                                <Row className="fullinput">
                                    <Col md={12}>
                                        <div className='d-flex align-items-center'>
                                            <label>Verification code</label>
                                            <img
                                                src={info}
                                                className="Info_icon jjjj" alt=""
                                                onClick={VerifyData1}

                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <input
                                            mask="999999"
                                            id="inpt"
                                            maxLength={6}
                                            name="verification"
                                            placeholder="Enter unique verification code"
                                            className="numbers"
                                            value={formData.verification ? formData.verification : ''}
                                            onChange={(e) => {
                                                setForm(e);
                                                removeErrorOnlyOne("verificationerror")

                                            }}
                                        />
                                        <span className="error verificationerror" id="errorOtp" />
                                    </Col>

                                    <Col md={6}>
                                        {optLoader === true ? (
                                            <button className="border_trans_btn">Please wait</button>
                                        ) : (
                                            <>
                                                <button className="border_trans_btn" onClick={onSubmitverifyotp12}>
                                                    SUBMIT
                                                </button>
                                                <span className="error sumbitCodeError" />
                                            </>

                                        )}
                                    </Col>
                                </Row>
                            ) : null}
                        </>
                    )}

                    <br />
                    <div className="fotter-btn">
                        <button className="preview" onClick={previousclcik}>
                            <img src={leftarrow} className="leftarrow" alt="" />
                            Previous
                        </button>
                        {conLoader == true ? (
                            <button type="submit" className="next flagsave">
                                Please Wait
                                <img src={rightarrow} className="rightarrow" alt="" />{' '}
                            </button>
                        ) : (
                            <>
                                {priContactType === 'Mobile' ||
                                    priContactType === undefined ||
                                    priContactType === '' ||
                                    priContactType === null ? (
                                    <button type="submit" className="next flagsave" onClick={handleClick12}>
                                        Continue
                                        <img src={rightarrow} className="rightarrow" alt="" />{' '}
                                    </button>
                                ) : (
                                    <button type="submit" className="next flagsave" onClick={handleClick123}>
                                        Continue
                                        <img src={rightarrow} className="rightarrow" alt="" />{' '}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThirdTrust;
