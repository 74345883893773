import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import $ from 'jquery';
import info from '../info.png';
import swal from 'sweetalert';
import logo from '../logo.png';
import SignaturePad from 'react-signature-pad-wrapper';
import MenuSidebar from './MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import logoBlack from '../logoBlack.png';
import Option from './Option';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';



const Eleven = ({
  func,
  formData,
  navigation,
  setForm,

}) => {
  const {
    secondholder,
    OptionFundCF23,
    OptionFundCF2
  } = formData;
  const query = useQuery();

  // let queryValue = query.get("update");
  const queryValue = sessionStorage.getItem("queryValue");

  console.log("queryvalue", queryValue)
  const namefull1 = secondholder ? secondholder.split(' ') : ''
  formData.firstName1 = namefull1[0];

  if (namefull1.length > 1) {
    formData.lastName1 = namefull1[namefull1.length - 1];
  }

  if (namefull1.length > 2) {
    formData.middleName1 = namefull1
      .slice(1, namefull1.length - 1)
      .join()
      .replace(/,/g, ' ');
  }

  const [imageLoader, setImageLoader] = useState();
  const mySignatureRef = useRef(null);
  const mySignatureRef1 = useRef(null);

  const saveandexit = () => {
    func();
  };
  const onPrevious = () => {

    if (formData.investorPrincipalBusiness === true ||
      formData.investingFinancialProducts === true ||
      formData.actingUnderwriter === true ||
      formData.financialAdviceService === true ||
      formData.clientMoneyPropertyService === true ||
      formData.tradingFinancialProducts === true ||
      formData.registeredBankRBNZ === true ||
      formData.NBDTInvestor === true ||
      formData.licensedIssuerInsurance === true ||
      formData.registeredSchemeManager === true ||
      formData.derivativesIssuer === true ||
      formData.FinancialAdvisor === true) {

      go("Thirteen")

    }
    else if (
      formData.ownedPortfolio2yrs === true ||
      formData.carriedTransactions2yrs === true ||
      formData.investmentBusiness10yrs === true ||
      formData.equitySecurities === true ||
      formData.managedFunds === true ||
      formData.derivativeInstruments === true ||
      formData.debtSecurities === true
    ) {
      go("Fifteen")

    }
    else {
      go("Twelve")
    }
  }

  const Clear = () => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    formData.certificateA_Date = val;


    let signatureA = mySignatureRef.current.clear();

    formData.signatureA = signatureA;
  };

  const Clear1 = () => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    formData.certificateA_Date1 = val;


    let signatureA1 = mySignatureRef1.current.clear();

    formData.signatureA1 = signatureA1;
  };

  useEffect(() => {
    if (formData.signatureA1 != "" && formData.signatureA1 != null && formData.signatureA1 != undefined) {
      Clear1();
    }

  }, []);
  useEffect(() => {
    if (formData.signatureA != "" && formData.signatureA != null && formData.signatureA != undefined) {
      Clear();
    }

  }, []);


  function signaturejoint1() {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    formData.certificateA_Date1 = val;

    const setValues = localStorage.getItem('setValues');
    let signatureA1 = mySignatureRef1.current.toDataURL();


    // formData.signatureA1 = signatureA1;
    // formData.signatureA1Url = signatureA1;

    let datajoint = {
      columnName: 'signatureA',
      fileName: formData.investmentType === 'Joint' ? 'secSignatureA' : 'signatureA',
      document: signatureA1,
      investmentType: formData.investmentType
    };

    setImageLoader(true);
    if (setValues === 'Yes') {
      axios
        .post(`${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, datajoint, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          formData.signatureA1Url = res.data.url;
          formData.signatureA1 = res.data.url;
          formData.result = res.status;
          if (res.status === 200) {
            setImageLoader(false);
            if (queryValue == null || queryValue == undefined || queryValue == "") {
              formData.class = 'Twentyone';

              go('Twentyone');
            }
            else {
              formData.class = 'Twentyone11';

              go('Twentyone11');
            }

          } else {
            setImageLoader(false);
          }
        });
    } else {
      localStorage.setItem('Step20', 'Step20');
      setImageLoader(true);

      axios
        .post(`${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, datajoint, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          formData.signatureA1Url = res.data.url;
          formData.signatureA1 = res.data.url;

          if (res.status === 200) {
            setImageLoader(false);
            formData.CertificationValue = true;

            if (queryValue == null || queryValue == undefined || queryValue == "") {
              formData.class = 'Twentyone';

              go('Twentyone');
            }
            else {
              formData.class = 'Twentyone11';

              go('Twentyone11');
            }
          }
        });
    }
  }
  useEffect(() => {
    formData.signatureUrlB = '';
    formData.certificateNumber = 2;
    formData.certificateNumber = 3;
  }, [formData]);



  const signature = () => {
    if (formData.isPrimary == 0) {
      if (queryValue == null || queryValue == undefined || queryValue == "") {
        formData.class = 'Twentyone';

        go('Twentyone');
      }
      else {
        formData.class = 'Twentyone11';

        go('Twentyone11');
      }
    }
    else {
      formData.certificateNumber = 1;
      // formData.rwt12 = null
      // formData.userEmail1 = null
      // formData.fullLegalNamesign = null

      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate').text(val);
      formData.certificateA_Date = val;

      const setValues = localStorage.getItem('setValues');
      let signatureA = mySignatureRef.current.toDataURL();

      let isEmpty = mySignatureRef.current.isEmpty();

      let isEmpty1;

      if (formData.investmentType === 'Joint') {
        // alert("sdddddd233")

        isEmpty1 = mySignatureRef1.current.isEmpty();
      }

      // formData.signatureA = signatureA;
      // formData.signatureUrl = signatureA;

      let data = {
        columnName: 'signatureA',
        fileName: formData.investmentType === 'Joint' ?
          'priSignatureA' : formData.investmentType === 'Company' ? 'signatureA' : "signatureA",
        document: signatureA,
        investmentType: formData.investmentType
      };


      if (isEmpty) {
        $('.signError').html('Please sign');

      } else if (isEmpty1 && formData.investmentType === 'Joint') {
        $('.signError1').html('Please sign');
      } else {

        setImageLoader(true);
        if (setValues === 'Yes') {
          axios
            .post(queryValue ? `${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}` : `${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, data, {
              headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
              formData.signatureUrl = res.data.url;
              formData.signatureA = res.data.url;
              formData.result = res.status;
              if (res.status === 200) {
                setImageLoader(false);
                formData.CertificationValue = true;
                if (formData.investmentType === 'Joint') {
                  signaturejoint1();
                } else {
                  if (queryValue == null || queryValue == undefined || queryValue == "") {
                    formData.class = 'Twentyone';

                    go('Twentyone');
                  }
                  else {
                    formData.class = 'Twentyone11';

                    go('Twentyone11');
                  }
                }
              } else {
                setImageLoader(false);
                formData.CertificationValue = true;
              }
            });
        } else {
          localStorage.setItem('Step20', 'Step20');
          setImageLoader(true);

          axios
            .post(queryValue ? `${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}` : `${multisavedata}/genImageUrl${queryValue != null && queryValue != "null" && queryValue != "undefined" && queryValue != undefined && queryValue != "" ? "?update=updatesign" : ""}`, data, {
              headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
              formData.signatureUrl = res.data.url;
              formData.signatureA = res.data.url;

              if (res.status === 200) {
                setImageLoader(false);
                formData.CertificationValue = true;
                if (formData.investmentType === 'Joint') {
                  signaturejoint1();
                } else {
                  if (queryValue == null || queryValue == undefined || queryValue == "") {
                    formData.class = 'Twentyone';

                    go('Twentyone');
                  }
                  else {
                    formData.class = 'Twentyone11';

                    go('Twentyone11');
                  }
                }
              } else {
                formData.CertificationValue = true;
              }
            });
        }
      }
    }

  };

  useEffect(() => {
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate').text(val);
      formData.certificateA_Date = val;
    });
    //selector.click( over, out )
    $('.signupForm').hide();
    $('.signupButton').click(
      function () {
        $('.signupForm').show();
      },
      function () {
        $('.signupForm').show();
      }
    );
    $('.signupXButton').click(function () {
      $('.signupForm').hide();
      $('.signupForm').hide();
    });
    $('.signupXButton').click(function () {
      $('.signupForm').hide();
    });
    // $(".set-con").click(function() {

    // });
  }, [formData]);
  const { go } = navigation;

  useEffect(() => {
    formData.signatureA1Url = null;
    formData.signatureA1 = null;
    formData.signatureUrl = null;
    formData.signatureA = null;
    formData.secSignatureCUrl = null;
    formData.secSignatureC = null;
    formData.signatureUrlB = null;
    formData.signatureC = null;
    localStorage.removeItem('step16');
    $('.past').click(function () {
      const myValues = localStorage.getItem('step10');
      const myValuesone = localStorage.getItem('step13');
      const myValuestwo = localStorage.getItem('step15');
      const myValuestwo1 = localStorage.getItem('step16');
      if (myValues === 'step10' && myValuesone === 'step13') {
        go('Thirteen');
        // localStorage.removeItem("step13");
      }
      if (myValues === 'step10' && myValuestwo === 'step15') {
        go('Fifteen');
        //  localStorage.removeItem("step15");
      }
      if (myValues === 'step10' && myValuestwo1 === 'step16') {
        go('Sixteen');
      }
      if (!myValuesone && !myValuestwo) {
        go('Twelve');
        localStorage.removeItem('step10');
      }
    });
  }, [go]);




  const hadnleclick = () => {
    signature();

  }
  function removeError() {
    $('.error').html('');
  }
  // const deletion = () => {
  //   formData.signatureUrl = '';
  // };


  const allNone = (e) => {
    formData.investorPrincipalBusiness = false
    formData.investingFinancialProducts = false
    formData.actingUnderwriter = false
    formData.financialAdviceService = false
    formData.clientMoneyPropertyService = false
    formData.tradingFinancialProducts = false
    formData.registeredBankRBNZ = false
    formData.NBDTInvestor = false
    formData.licensedIssuerInsurance = false
    formData.registeredSchemeManager = false
    formData.derivativesIssuer = false
    formData.FinancialAdvisor = false
  }

  const Nonefalse = () => {
    formData.investmentBusinessNone = false
  }


  const [dis, setDis] = useState(true)

  useEffect(() => {
    if (formData.investorPrincipalBusiness == true) {
      setDis(false)
    }
    else {
      setDis(true)
      formData.investingFinancialProducts = false
      formData.actingUnderwriter = false
      formData.financialAdviceService = false
      formData.clientMoneyPropertyService = false
      formData.tradingFinancialProducts = false
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // alert(formData.downsignatureA)
  // console.log("yyyy",formData)
  // console.log("rytut",formData.certificateDetails)
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {/* <h2>Wholesale Investor Certificate 1</h2> */}
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Wholesale Investor Certificate A</h2> :
              <h2>Step 5: Wholesale Investor Certificate A</h2>

          }
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          {queryValue ? <p></p> :
            <div
              disabled={formData.isPrimary == 1 ? false : true}>
              <div className="save-exit se_invff">
                <p className="save next" onClick={saveandexit}>
                  SAVE DATA{' '}
                </p>
                <img src={info} onClick={infodetls} className="info" alt="" />
              </div>
            </div>
          }
          <div className="cantant">
            <div className="wholesale_content_header">
              <div className="logo_Wholesale">
                <img src={logoBlack} alt="logoBlack" className="logobLACK" />
                <p></p>
              </div>
              <div className="content">
                <h3 className="Wholesale_headering ">Wholesale Investor Certificate A</h3>
                {/* <p className="Wholesale__Para ghgh">
                  (Clause 44 of Schedule 1 of the Financial Markets Conduct Act 2013)
                </p> */}
              </div>
            </div>

            <div className="textlight">
              <div className="Wholesale__Investor__Conetnt">
                <p className="Bold_warning">Warning</p>
                <p>
                  {' '}
                  The law normally requires people who offer financial products* to give information
                  to Investors before they invest. This information is designed to help Investors
                  make an informed decision.
                </p>

                <p>
                  {' '}
                  If you are a Wholesale Investor, the usual rules do not apply to offers of
                  financial products made to you. As a result, you may not receive a complete and
                  balanced set of information. You will also have fewer other legal protections for
                  these investments.
                </p>

                <p>
                  {' '}
                  Ask questions, read all documents carefully, and seek independent financial advice
                  before committing yourself.
                </p>
              </div>
              <div className="italic_text">
                <i>
                  *Financial product is defined in the Financial Markets Conduct Act 2013 and means
                  a debt security, equity security, managed investment product or a derivative. It
                  does not include an asset such as a house or any associated borrowing required to
                  purchase a house. Please refer to sections 7 to 10 of the Financial Markets
                  Conduct Act 2013 for further information.
                </i>
              </div>
              <br />
              <div className="Wholesale__Investor__Conetnt">
                <p className="Bold_warning">Offence</p>
                It is an offence to give a certificate knowing that it is false or misleading in a
                material particular. The offence has a penalty of a fine not exceeding $50,000.
                <br />
              </div>
              <div className="Wholesale__Investor__Conetnt Bottom-space__Wholesale">
                <p className="Bold_warning">Certification</p>
                <p>
                  This certificate is required to verify that the investor meets the respective eligibility requirements as a “Wholesale Investor” under Schedule 1, clause 3(2)(a) – (c) of the FMCA and accordingly confirm that  MyFarm are required to make disclosures in respect of this offer under Part 3 of the FMCA.
                </p>
                <p>
                  <div className='certificate_text'>
                    <p style={{ margin: '0', display: 'inline-block' }}>I/WE,</p>
                    <p className="certifies__Input newdivadd" style={{ margin: '0 5px', }}>
                      {formData.investmentType == "Trust" ? <div>
                        {formData.trustName}
                      </div> : <>
                        {
                          formData.investmentType == "Company" ?

                            <div>
                              {formData.companyName}
                            </div> :


                            <b className="capitallll">
                              {formData.investmentType === 'Joint'
                                ? (formData.firstNameindi != null &&
                                  formData.firstNameindi != '' &&
                                  formData.firstNameindi != 'undefined'
                                  ? ' And ' + formData.firstNameindi
                                  : formData.firstName != null &&
                                    formData.firstName != '' &&
                                    formData.firstName != 'undefined'
                                    ? formData.firstName
                                    : '') +
                                ' ' +
                                (formData.middleNameindi != null &&
                                  formData.middleNameindi != '' &&
                                  formData.middleNameindi != 'undefined'
                                  ? formData.middleNameindi
                                  : formData.middleName != null &&
                                    formData.middleName != '' &&
                                    formData.middleName != 'undefined'
                                    ? formData.middleName + ' '
                                    : '') +
                                '' +
                                (formData.lastNameindi != null &&
                                  formData.lastNameindi != '' &&
                                  formData.lastNameindi != 'undefined'
                                  ? formData.lastNameindi
                                  : formData.lastName != null &&
                                    formData.lastName != '' &&
                                    formData.lastName != 'undefined'
                                    ? formData.lastName
                                    : '') +
                                (formData.firstNameJoint != null &&
                                  formData.firstNameJoint != '' &&
                                  formData.firstNameJoint != 'undefined'
                                  ? ' & ' + formData.firstNameJoint
                                  : formData.firstName1 != null &&
                                    formData.firstName1 != '' &&
                                    formData.firstName1 != 'undefined'
                                    ? ' And ' + formData.firstName1
                                    : '') +
                                ' ' +
                                (formData.middleNamejoint != null &&
                                  formData.middleNamejoint != '' &&
                                  formData.middleNamejoint != 'undefined'
                                  ? formData.middleNamejoint
                                  : formData.middleName1 != null &&
                                    formData.middleName1 != '' &&
                                    formData.middleName1 != 'undefined'
                                    ? formData.middleName1 + ' '
                                    : '') +
                                '' +
                                (formData.lastNamejoint != null &&
                                  formData.lastNamejoint != '' &&
                                  formData.lastNamejoint != 'undefined'
                                  ? ' ' + formData.lastNamejoint
                                  : formData.lastName1 != null &&
                                    formData.lastName1 != '' &&
                                    formData.lastName1 != 'undefined'
                                    ? ' ' + formData.lastName1
                                    : '')
                                : formData.firstName +
                                ' ' +
                                (formData.middleName === 'undefined' ||
                                  formData.middleName === undefined ||
                                  formData.middleName === ''
                                  ? ''
                                  : formData.middleName + ' ') +
                                (formData.lastName === 'undefined' ||
                                  formData.lastName === undefined ||
                                  formData.lastName === ''
                                  ? ''
                                  : formData.lastName)}
                            </b>
                        }
                      </>}


                      {/* {(
                          formData.firstNameindi !== null &&
                        formData.firstNameindi !== '' &&
                        formData.firstNameindi !== 'undefined'&&
                        formData.firstNameindi !== undefined
                        ? formData.firstNameindi
                        : formData.firstName !== null &&
                          formData.firstName !== '' &&
                          formData.firstName !== 'undefined'&&
                          formData.firstName !== undefined
                          ? formData.firstName
                          : '') +
                        ' ' +
                        (formData.middleNameindi !== null &&
                          formData.middleNameindi !== '' &&
                          formData.middleNameindi !== 'undefined'&&
                          formData.middleNameindi != undefined
                          ? formData.middleNameindi
                          : formData.middleName !== null &&
                            formData.middleName !== '' &&
                            formData.middleName !== 'undefined'&&
                            formData.middleName !== undefined
                            ? formData.middleName + ' '
                            : '') +
                        '' +
                        (formData.lastNameindi !== null &&
                          formData.lastNameindi !== '' &&
                          formData.lastNameindi !== 'undefined'&&
                          formData.lastNameindi !== undefined
                          ? formData.lastNameindi
                          : formData.lastName !== null &&
                            formData.lastName !== '' &&
                            formData.lastName !== 'undefined'&&
                            formData.lastName !== undefined
                            ? formData.lastName
                            : ''
                            )} */}
                    </p>
                    <b>(the Investor)</b> HEREBY CERTIFY THAT I am/we are a Wholesale Investor under Schedule 1, clause 3(2) of the FMCA of the following kind (tick one of the following):

                  </div>
                </p>
                {/* <p className="spacebtween">
                  {' '}
                  <ol type="a">
                    <li>
                      {' '}
                      The Investor is a ‘wholesale investor’ within the meaning of clause 3(2) of
                      Schedule 1 in respect of offers of financial products, and a ‘wholesale
                      client’ within the meaning of clause 4 of Schedule 5 of the Financial Markets
                      Conduct Act 2013 in respect of financial advice, and
                    </li>
                    <li>
                      {' '}
                      The Investor understands the consequences of certifying itself as a ‘wholesale
                      investor’ and a ‘wholesale client’.
                    </li>
                  </ol>
                </p> */}
              </div>
            </div>
            {/* <br /> */}

            <div>
              <p className='person'>Grounds</p>
              <p>
                The paragraph(s) in clause 3(2) that are claimed to apply to the Investor and the
                grounds on which the Investor claims that the paragraph applies are:
              </p>
            </div>

            {/* Twelve Page Start................ */}
            <label className="person">Large persons</label>

            <p>The Investor is ‘large’ on the basis that at least one of the following paragraphs applies immediately before this certificate is given:</p>

            <div className="checkbox-my">

              <label className="containers restContainer font-fami">{
                formData.largePersonValue === true ? <b>As at the last day of each of the Investor's two most recently completed financial
                  years, the net assets of the Investor and the entities controlled by the Investor
                  exceeded $5 million.</b> :
                  <p>As at the last day of each of the Investor's two most recently completed financial
                    years, the net assets of the Investor and the entities controlled by the Investor
                    exceeded $5 million.</p>
              }

                <input

                  type="checkbox"
                  disabled={formData.isPrimary == 0 ? true : false}
                  name="largePersonValue"
                  checked={
                    formData.largePersonValue === true ? true : false
                  }
                />

                <span className="checkmark"></span>
              </label>
              <label className="containers restContainer font-fami">
                {
                  formData.twoYearInvestEntity2 === true ? <b>In each of the Investor's two most recently completed financial years, the total
                    consolidated turnover of the Investor and the entities controlled by the Investor
                    exceeded $5 million.</b> :
                    <p>In each of the Investor's two most recently completed financial years, the total
                      consolidated turnover of the Investor and the entities controlled by the Investor
                      exceeded $5 million.</p>
                }

                <input
                  disabled={formData.isPrimary == 0 ? true : false}

                  type="checkbox"
                  name="twoYearInvestEntity2"
                  checked={
                    formData.twoYearInvestEntity2 === true ? true : false
                  }
                />
                <span className="checkmark"></span>
              </label>


              {/* <br /> */}
            </div>

            {/* thirteenPage start................. */}
            {
              // formData.largePersonValue == true || formData.twoYearInvestEntity2 == true ? null :
              <>
                <label className="person">Investment Business</label>
                <p className='font-fami'>
                  The Investor was not established or acquired with a view to using it as an entity to
                  which offers of financial products may be made in reliance upon the exclusion in clause
                  3(2)(a) and the Investor is an “investment business” on the basis that;
                </p>

                <div className="checkbox-my">
                  <label className="containers restContainer font-fami">{
                    formData.investorPrincipalBusiness == true ?
                      <b>The Investor’s principal business consists of one or more of the following :</b> :
                      <p>The Investor’s principal business consists of one or more of the following :</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="investorPrincipalBusiness"
                      checked={
                        formData.investorPrincipalBusiness == true ? true : false
                      }

                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div className="checkbox-my">
                  <div className="">
                    <label className="containers restContainer font-fami hh">{
                      formData.investingFinancialProducts == true ?
                        <b> Investing in financial products</b> : <p>Investing in financial products</p>
                    }
                      <input
                        disabled={formData.isPrimary == 0 ? true : false}

                        type="checkbox"
                        name="investingFinancialProducts"
                        checked={
                          formData.investingFinancialProducts == true ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                    </label>

                    <label className="containers restContainer font-fami hh">{
                      formData.actingUnderwriter == true ?
                        <b>Acting as an underwriter</b> : <p>Acting as an underwriter</p>
                    }
                      <input
                        disabled={formData.isPrimary == 0 ? true : false}

                        type="checkbox"
                        name="actingUnderwriter"
                        checked={
                          formData.actingUnderwriter == true ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                    </label>

                    <label className="containers restContainer font-fami hh">{
                      formData.financialAdviceService == true ?
                        <b>Providing a financial advice service</b> : <p>Providing a financial advice service</p>
                    }
                      <input
                        disabled={formData.isPrimary == 0 ? true : false}

                        type="checkbox"
                        name="financialAdviceService"
                        checked={
                          formData.financialAdviceService == true ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                    </label>

                    <label className="containers restContainer font-fami hh">{
                      formData.clientMoneyPropertyService == true ?
                        <b>Providing a client money or property service</b> :
                        <p>Providing a client money or property service</p>
                    }
                      <input
                        disabled={formData.isPrimary == 0 ? true : false}

                        type="checkbox"
                        name="clientMoneyPropertyService"
                        checked={
                          formData.clientMoneyPropertyService == true ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                    </label>

                    <label className="containers restContainer font-fami hh">{
                      formData.tradingFinancialProducts == true ?
                        <b>Trading in financial products on behalf of other persons.</b> :
                        <p>Trading in financial products on behalf of other persons.</p>
                    }
                      <input
                        disabled={formData.isPrimary == 0 ? true : false}

                        type="checkbox"
                        name="tradingFinancialProducts"
                        checked={
                          formData.tradingFinancialProducts == true ? true : false
                        }
                      />
                      <span className="checkmark"></span>

                    </label>
                    <span className="error investmentBusiness1"></span>
                  </div>
                </div>

                <div className="checkbox-my">
                  <label className="containers restContainer font-fami">{
                    formData.registeredBankRBNZ == true ?
                      <b>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</b> :
                      <p>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="registeredBankRBNZ"
                      checked={
                        formData.registeredBankRBNZ == true ? true : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="containers restContainer font-fami">{
                    formData.NBDTInvestor == true ?
                      <b>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</b> :
                      <p>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="NBDTInvestor"
                      checked={
                        formData.NBDTInvestor == true ? true : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="containers restContainer font-fami">{
                    formData.licensedIssuerInsurance == true ?
                      <b>The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</b> :
                      <p> The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="licensedIssuerInsurance"
                      checked={
                        formData.licensedIssuerInsurance == true ? true : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="containers restContainer font-fami">{
                    formData.registeredSchemeManager == true ?
                      <b>The Investor is the manager of a registered scheme, or a discretionary investment
                        management service, that holds a market services licence under the Financial Markets
                        Conduct Act 2013.</b> :
                      <p>The Investor is the manager of a registered scheme, or a discretionary investment
                        management service, that holds a market services licence under the Financial Markets
                        Conduct Act 2013.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="registeredSchemeManager"
                      checked={
                        formData.registeredSchemeManager == true ? true : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="containers restContainer font-fami">{
                    formData.derivativesIssuer == true ?
                      <b>The Investor is a derivatives issuer that holds a market services license under the
                        Financial Markets Conduct Act 2013.</b> :
                      <p>The Investor is a derivatives issuer that holds a market services license under the
                        Financial Markets Conduct Act 2013.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="derivativesIssuer"
                      checked={
                        formData.derivativesIssuer == true ? true : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="containers restContainer font-fami">{
                    formData.FinancialAdvisor == true ?
                      <b>The Investor is a financial adviser.</b> :
                      <p>The Investor is a financial adviser.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="FinancialAdvisor"
                      checked={
                        formData.FinancialAdvisor == true ? true : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  {/* <br /> */}
                </div>

              </>
            }

            {/* Fifteen Page Start.................... */}
            {
              // (formData.largePersonValue == true || formData.twoYearInvestEntity2 == true)
              //  ? null : formData.investmentBusinessNone == true ?
              <>

                <label className='person'>Investment activity criteria</label>

                <p>The Investor meets the ‘investment activity criteria’ on the basis that at least one of the following paragraphs apply:</p>
                <div className="checkbox-my">
                  <label className="containers years set-check font-fami">{
                    formData.ownedPortfolio2yrs === true ?
                      <b>The investor owns, or at any time during the 2-year period immediately before this
                        certificate is given has owned, a portfolio of financial products (other than
                        membership of a retirement scheme or financial products issued by an associate) of a
                        value of at least $1 million (in aggregate).</b> :
                      <p>The investor owns, or at any time during the 2-year period immediately before this
                        certificate is given has owned, a portfolio of financial products (other than
                        membership of a retirement scheme or financial products issued by an associate) of a
                        value of at least $1 million (in aggregate).</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="ownedPortfolio2yrs"
                      checked={formData.ownedPortfolio2yrs === true ? true : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="containers years set-check font-fami">{
                    formData.carriedTransactions2yrs === true ?
                      <b>The investor has, during the 2-year period immediately before this certificate is
                        given, carried out one or more transaction(s) to acquire financial products (other
                        than membership of a retirement scheme or financial products issued by an associate)
                        where the amount payable under those transactions (in aggregate) is at least $1
                        million.</b> :
                      <p>The investor has, during the 2-year period immediately before this certificate is
                        given, carried out one or more transaction(s) to acquire financial products (other
                        than membership of a retirement scheme or financial products issued by an associate)
                        where the amount payable under those transactions (in aggregate) is at least $1
                        million.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      name="carriedTransactions2yrs"
                      checked={
                        formData.carriedTransactions2yrs === true ? true : false
                      }

                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="containers years set-check font-fami">{
                    formData.investmentBusiness10yrs === true ?
                      <b>The Investor is an individual who has, within the last ten years immediately before
                        this certificate is given, been employed or engaged in an investment business and has,
                        for at least two years during that ten-year period, participated to a material extent
                        in the investment decisions made by the investment business.</b> :
                      <p>The Investor is an individual who has, within the last ten years immediately before
                        this certificate is given, been employed or engaged in an investment business and has,
                        for at least two years during that ten-year period, participated to a material extent
                        in the investment decisions made by the investment business.</p>
                  }
                    <input
                      disabled={formData.isPrimary == 0 ? true : false}

                      type="checkbox"
                      className=""
                      name="investmentBusiness10yrs"
                      checked={
                        formData.investmentBusiness10yrs === true ? true : false
                      }

                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                {
                  formData.ownedPortfolio2yrs === true || formData.carriedTransactions2yrs === true || formData.investmentBusiness10yrs === true ?
                    <div className="fullspace pandingfinsh ">
                      <label>Please define the specific financial products of at least $1mil. </label>
                      <div className="checkbox-my left-more">
                        <div>
                          <label className="containers font-fami">{
                            formData.equitySecurities === true ?
                              <b>Equity securities (includes listed shares)</b> : <p>Equity securities (includes listed shares)</p>
                          }
                            <input
                              disabled={formData.isPrimary == 0 ? true : false}

                              type="checkbox"
                              name="equitySecurities"
                              checked={formData.equitySecurities === true ? true : false}
                            />
                            <span className="checkmark"></span>
                          </label>

                          <label className="containers font-fami">{
                            formData.managedFunds === true ? <b> Managed funds</b> : <p> Managed funds</p>
                          }
                            <input
                              disabled={formData.isPrimary == 0 ? true : false}

                              type="checkbox"
                              name="managedFunds"
                              checked={formData.managedFunds === true ? true : false}

                            />
                            <span className="checkmark"></span>
                          </label>

                          <label className="containers font-fami">{
                            formData.derivativeInstruments === true ? <b> Derivative instruments</b> : <p> Derivative instruments</p>
                          }
                            <input
                              disabled={formData.isPrimary == 0 ? true : false}

                              type="checkbox"
                              name="derivativeInstruments"
                              checked={formData.derivativeInstruments === true ? true : false}
                            />

                            <span className="checkmark font-fami"></span>
                          </label>
                          <label className="containers">{
                            formData.debtSecurities === true ? <b>Debt securities</b> : <p>Debt securities</p>
                          }
                            <input
                              disabled={formData.isPrimary == 0 ? true : false}

                              type="checkbox"
                              name="debtSecurities"
                              checked={formData.debtSecurities === true ? true : false}

                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    : null
                }

              </>
              // :null
            }



            <div className="d-flex">
              <p>
                {' '}
                <b>Dated:</b>
              </p>{' '}
              <b>
                <p id="printDate" />
              </b>
            </div>
            <div className="textlight"></div>
            {/* <br /> */}
            <div onClick={removeError}>
              <div className="upper-space-table">
                <table className="behalf-sign b-sign-3">
                  <tr>
                    <td className="term-Signed">Signature of applicant: </td>
                    <td className="term-entity">
                      {(formData.firstNameindi !== null &&
                        formData.firstNameindi !== '' &&
                        formData.firstNameindi !== 'undefined' &&
                        formData.firstNameindi !== undefined
                        ? formData.firstNameindi
                        : formData.firstName !== null &&
                          formData.firstName !== '' &&
                          formData.firstName !== 'undefined' &&
                          formData.firstName !== undefined
                          ? formData.firstName
                          : '') +
                        ' ' +
                        (formData.middleNameindi !== null &&
                          formData.middleNameindi !== '' &&
                          formData.middleNameindi !== 'undefined' &&
                          formData.middleNameindi != undefined
                          ? formData.middleNameindi
                          : formData.middleName !== null &&
                            formData.middleName !== '' &&
                            formData.middleName !== 'undefined' &&
                            formData.middleName !== undefined
                            ? formData.middleName + ' '
                            : '') +
                        '' +
                        (formData.lastNameindi !== null &&
                          formData.lastNameindi !== '' &&
                          formData.lastNameindi !== 'undefined' &&
                          formData.lastNameindi !== undefined
                          ? formData.lastNameindi
                          : formData.lastName !== null &&
                            formData.lastName !== '' &&
                            formData.lastName !== 'undefined' &&
                            formData.lastName !== undefined
                            ? formData.lastName
                            : '')}
                    </td>
                  </tr>
                </table>
                <div> <span className="error errercolor OptionFundCF2"></span></div>
              </div>
            </div>
            {formData.isPrimary == 1 ?
              <div className="second-main-wrapper">
                <div
                  // className='dis_sign'
                  onClick={(e) => {
                    removeError();
                  }}
                >

                  <SignaturePad
                    className="signature_h sigCanvas"
                    clearButton="true"
                    // id="signature-pad-canvas"
                    ref={mySignatureRef}
                  />

                  <br></br>
                  <span className="error error1 signError"></span>
                  <button onClick={Clear}>Clear</button>
                </div>
              </div>
              : <img alt='ee' className='signature-img'  src={formData.downsignatureA} />
            }
            {/* <div className="second-main-wrapper">
              <div
                // className='dis_sign'
                onClick={(e) => {
                  removeError();
                }}
              >
                 
                <SignaturePad
                  className="signature_h"
                  clearButton="true"
                  id="signature-pad-canvas"
                  ref={mySignatureRef}
                />

                <br></br>
                <span className="error error1 signError"></span>
                <button onClick={Clear}>Clear</button>
              </div>
            </div> */}


            <div>
              {formData.investmentType === 'Joint' ? (
                <>
                  {' '}
                  <div onClick={removeError}>
                    <div className="upper-space-table">
                      <table className="behalf-sign">
                        <tr>
                          <td className="term-Signed">Signature of applicant:</td>
                          <td className="term-entity">
                            <div className="centername">
                              {formData.investmentType === 'Joint'
                                ? (formData.firstNameJoint != null &&
                                  formData.firstNameJoint != '' &&
                                  formData.firstNameJoint != 'undefined'
                                  ? formData.firstNameJoint
                                  : formData.firstName1 != null &&
                                    formData.firstName1 != '' &&
                                    formData.firstName1 != 'undefined'
                                    ? formData.firstName1
                                    : '') +
                                ' ' +
                                (formData.middleNamejoint != null &&
                                  formData.middleNamejoint != '' &&
                                  formData.middleNamejoint != 'undefined'
                                  ? formData.middleNamejoint
                                  : formData.middleName1 != null &&
                                    formData.middleName1 != '' &&
                                    formData.middleName1 != 'undefined'
                                    ? formData.middleName1 + ' '
                                    : '') +
                                '' +
                                (formData.lastNamejoint != null &&
                                  formData.lastNamejoint != '' &&
                                  formData.lastNamejoint != 'undefined'
                                  ? formData.lastNamejoint
                                  : formData.lastName1 != null &&
                                    formData.lastName1 != '' &&
                                    formData.lastName1 != 'undefined'
                                    ? formData.lastName1
                                    : '')
                                : (formData.firstNameindi != null &&
                                  formData.firstNameindi != '' &&
                                  formData.firstNameindi != 'undefined'
                                  ? formData.firstNameindi
                                  : formData.firstName != null &&
                                    formData.firstName != '' &&
                                    formData.firstName != 'undefined'
                                    ? formData.firstName
                                    : '') +
                                ' ' +
                                (formData.middleNameindi != null &&
                                  formData.middleNameindi != '' &&
                                  formData.middleNameindi != 'undefined'
                                  ? formData.middleNameindi
                                  : formData.middleName != null &&
                                    formData.middleName != '' &&
                                    formData.middleName != 'undefined'
                                    ? formData.middleName + ' '
                                    : '') +
                                '' +
                                (formData.lastNameindi != null &&
                                  formData.lastNameindi != '' &&
                                  formData.lastNameindi != 'undefined'
                                  ? formData.lastNameindi
                                  : formData.lastName != null &&
                                    formData.lastName != '' &&
                                    formData.lastName != 'undefined'
                                    ? formData.lastName
                                    : '')}
                            </div>
                          </td>

                        </tr>
                      </table>
                      <div><span className="error errercolor OptionFundCF23"></span></div>
                    </div>
                  </div>
                  <div className="second-main-wrapper">
                    <div
                      // className='dis_sign11'
                      onClick={(e) => {
                        removeError();
                      }}
                    >

                      <SignaturePad
                        className="signature_h"
                        clearButton="true"
                        id="signature-pad-canvas"
                        ref={mySignatureRef1}
                      />

                      <br></br>
                      <span className="error error1 signError1"></span>
                      <button onClick={Clear1}>Clear</button>
                    </div>

                  </div>

                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <br />
          <div className="fotter-btn">
            <button className="preview " onClick={() => onPrevious()}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>

            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : formData.signatureUrl !== null &&
              formData.signatureUrl !== '' &&
              formData.signatureUrl !== 'undefined' &&
              formData.signatureUrl !== undefined ? (
              <button type="submit" className="next flagsave" onClick={hadnleclick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={hadnleclick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Eleven;
