import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, useStep } from 'react-hooks-helper';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import info from '../info.png';
import swal from 'sweetalert';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import $, { now } from 'jquery';
import InputMask from 'react-input-mask';
import Option2 from '../Individual/Option2';
import MenuSidebar from '../Individual/MenuSidebar';
// import Option2 from '../Individual/Option2';
import { infodetls } from '../Individual/SaveInfo';
import useQuery from '../useQuery';


const EntityThird1 = ({
    func,
    setForm,
    formData,
    navigation,
    saveAndExist,


}) => {
    const {
        fullName,
        secondholder,
        isHoldingPersonalAssets,
        isCompanyIndividualOperating,
        isOtherCompanyIndividual,
        individualFullName,
        IndividualRole,
        IndividualEmail,
        secondaryIndividualFullName,
        secondaryIndividualRole,
        SecondaryIndividualEmail,
    } = formData;
    const query = useQuery();

    let queryValue = query.get("update");
    const saveandexit = () => {
        formData.secondholder =
            secondholder != 'undefined' && secondholder != '' && secondholder != null
                ? secondholder.trim()
                : '';
        formData.fullName =
            fullName != 'undefined' && fullName != '' && fullName != null ? fullName.trim() : '';
        func();
    };
    const [namefull1, setNamefull1] = useState(
        formData.secondholder ? formData.secondholder.trim().split(' ') : ''
    );
    let text2 = ['world!', 'ny', 'jjij'];
    let result = text2[-1];
    if (
        formData.secondholder != null &&
        formData.secondholder != '' &&
        formData.secondholder != 'undefined'
    ) {
        formData.firstName12 = namefull1[0];
        if (namefull1.length > 1) {
            formData.lastName12 = namefull1[namefull1.length - 1];
        }

        if (namefull1 && namefull1.length > 2) {
            formData.middleName12 = namefull1
                .slice(1, namefull1.length - 1)
                .join()
                .replace(/,/g, ' ');
        }
    }

    // formData.fullName = fullName.trim()
    const { previous, next } = navigation;
    useEffect(() => {
        $('.toogles').click(function () {
            $('.toogles').removeClass('active');
            $(this).addClass('active');
        });
        $('.step-change').click(function () {
            $('.step-change').removeClass('active');
            $(this).addClass('active');
        });
        $('document').ready(function () {
            $("input[type='tel']").attr('readonly', true);
        });
        $('.flagsave').click(function () {
            let phoneCode = $('.form-control').val();
            localStorage.setItem('phoneCode', phoneCode);
        });
        $('#SHAREvALUE').keyup(function () {
            let input = $(this).val();
            if (input < 100) {
                return true;
            } else {
                return false;
            }
        });
    });


    function Onprevious(e) {
        // formData.class = "Second"
        formData.class = 'Jointhird';
        go('EntityThird');
    }

    const handleChange = (e) => {
        e.preventDefault();
    };



    function handleClick(e) {

        let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (formData.isCompanyIndividualOperating === "true" && (formData.individualFullName === "" || formData.individualFullName === null || formData.individualFullName === "undefined" || formData.individualFullName === undefined)) {
            $('.fullNameerror').html('Please enter first and last name')
        }
        else if (formData.isCompanyIndividualOperating === "true" && (!emailExpression.test(formData.IndividualEmail))) {
            $('.IndividualEmail').html(
                "Please enter a valid email address, such as example@email.com"
            );
        }
        else if (formData.isCompanyIndividualOperating === "true" && (formData.IndividualRole === null || formData.IndividualRole == undefined || formData.IndividualRole === "" || formData.IndividualEmail === "undefined")) {
            $('.IndividualRole').html('Please enter their Role')
        }
        else if (formData.isOtherCompanyIndividual === "true" && (formData.secondaryIndividualFullName === null || formData.secondaryIndividualFullName == undefined || formData.secondaryIndividualFullName === "" || formData.IndividualEmail === "undefined")) {
            $('.secondaryIndividualFullName').html('Please enter first and last name')
        }
        else if (formData.isOtherCompanyIndividual === "true" && (!emailExpression.test(formData.SecondaryIndividualEmail))) {
            $('.SecondaryIndividualEmail').html(
                "Please enter a valid email address, such as example@email.com"
            );
        }
        else if (formData.isOtherCompanyIndividual === "true" && (formData.secondaryIndividualRole === null || formData.secondaryIndividualRole == undefined || formData.secondaryIndividualRole === "" || formData.secondaryIndividualRole === "undefined")) {
            $('.secondaryIndividualRole').html('Please enter their Role')
        }
        else {
            formData.class = 'Jointhird';
            go('EntityFour');
        }
    }

    function removeError() {
        $('.error').html('');
    }

    $('input').on('paste', function (e) {
        $('.error').html('');
    });

    const { go } = navigation;

    saveAndExist = () => {
        // formData.status = 'PENDING';
        // formData.step = '3';
        go('submit');
    };



    useEffect(() => {
        if (formData.isCompanyIndividualOperating == "false") {
            formData.individualFullName = ""
            formData.IndividualRole = ""
            formData.IndividualEmail = ""

        }
        if (formData.isOtherCompanyIndividual == "false") {
            formData.secondaryIndividualFullName = ""
            formData.secondaryIndividualRole = ""
            formData.SecondaryIndividualEmail = ""
        }
    })

    return (
        <>
            <MenuSidebar navigation={navigation} formData={formData} />
            <div className="main_container">
                <div class="heading__top">
                    <h2>Step 2: Company Details</h2>
                </div>
                <div className="form entitythirdone ">
                    <img src={logo} alt="img" className="imageCover" />
                    {queryValue ? <p></p> :
                        <div className="save-exit se_invff">
                            <p className="save next" onClick={saveandexit}>
                                SAVE DATA{' '}
                            </p>
                            <img src={info} onClick={infodetls} className="info" />
                        </div>
                    }

                    <Row className=" check-mb  check-mb1">
                        <Col md={6}>
                            <label>Is the company used as a vehicle for holding personal assets?</label>
                        </Col>
                        <Col
                            md={6}
                            disabled={formData.isPrimary == 1 ? false : true}
                        >
                            <div className="button_group space-bottom">
                                <div class="switch-field nospace">
                                    <div className="check_YN_Bank swtich11">
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-one"
                                            className="yesno"
                                            name="isHoldingPersonalAssets"
                                            value="false"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-one"
                                            className={`toogles postalAddressButton ${typeof isHoldingPersonalAssets === 'undefined' || isHoldingPersonalAssets === 'false'
                                                ? 'actives'
                                                : null
                                                }`}
                                            id="show"
                                        >
                                            No
                                        </label>
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-two"
                                            name="isHoldingPersonalAssets"
                                            value="true"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-two"
                                            className={`toogles postalAddressButtons ${isHoldingPersonalAssets === 'true' ? 'actives' : null
                                                }`}
                                            id="hide"
                                        >
                                            Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className=" check-mb  check-mb1">
                        <Col md={6}>
                            <label>Are there any individuals on whose behalf the company is operating?</label>
                        </Col>
                        <Col md={6} >
                            <div className="button_group space-bottom">
                                <div class="switch-field nospace">
                                    <div className="check_YN_Bank swtich11">
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-one1"
                                            className="yesno"
                                            name="isCompanyIndividualOperating"
                                            value="false"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-one1"
                                            className={`toogles postalAddressButton ${typeof isCompanyIndividualOperating === 'undefined' || isCompanyIndividualOperating === 'false'
                                                ? 'actives'
                                                : null
                                                }`}
                                            id="show"
                                        >
                                            No
                                        </label>
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-two2"
                                            name="isCompanyIndividualOperating"
                                            value="true"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-two2"
                                            className={`toogles postalAddressButtons ${isCompanyIndividualOperating === 'true' ? 'actives' : null
                                                }`}
                                            id="hide"
                                        >
                                            Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        isCompanyIndividualOperating == "true" ?
                            <>
                                <Row className="Select_role">

                                    <Col lg={12}>

                                        <ItemForm
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="text"
                                            name="individualFullName"
                                            className="quantity capitaluseraddress"
                                            value={individualFullName}
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError()
                                            }}
                                            placeholder="Enter first and last name "
                                        />
                                        <span className="error fullNameerror"></span>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col lg={12}>
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            name="IndividualEmail"
                                            autoComplete="off"
                                            placeholder="Email address"
                                            className="quantity_com capitaluseraddress"
                                            onKeyPress={removeError}
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError();

                                            }}
                                            value={IndividualEmail}
                                        />
                                        <span className="error IndividualEmail"></span>
                                    </Col>
                                    <Col lg={12} className="Select_role select_lebal_remove">

                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="text"
                                            name="IndividualRole"
                                            className="quantity capitaluseraddress"
                                            value={
                                                IndividualRole
                                            }
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError()
                                            }}
                                            placeholder="Enter their role "
                                        />
                                        <span className="error IndividualRole"></span>
                                    </Col>
                                </Row>
                            </> : null
                    }

                    <Row className=" check-mb  check-mb1">
                        <Col md={6} >
                            <label>Are there any other individuals (other than Shareholders and Directors
                                already listed) who can act on behalf of the company?</label>
                        </Col>
                        <Col
                            md={6}
                            disabled={formData.isPrimary == 1 ? false : true}
                        >
                            <div className="button_group space-bottom">
                                <div class="switch-field nospace">
                                    <div className="check_YN_Bank swtich11">
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-one2"
                                            className="yesno"
                                            name="isOtherCompanyIndividual"
                                            value="false"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-one2"
                                            className={`toogles postalAddressButton ${typeof isOtherCompanyIndividual === 'undefined' || isOtherCompanyIndividual === 'false'
                                                ? 'actives'
                                                : null
                                                }`}
                                            id="show"
                                        >
                                            No
                                        </label>
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="radio"
                                            id="radio-two3"
                                            name="isOtherCompanyIndividual"
                                            value="true"
                                            onChange={(e) => {
                                                setForm(e);
                                                // removeError();
                                            }}
                                        />
                                        <label
                                            for="radio-two3"
                                            className={`toogles postalAddressButtons ${isOtherCompanyIndividual === 'true' ? 'actives' : null
                                                }`}
                                            id="hide"
                                        >
                                            Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        isOtherCompanyIndividual == "true" ?
                            <>
                                <Row className="Select_role">

                                    <Col lg={12}>

                                        <ItemForm
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="text"
                                            name="secondaryIndividualFullName"
                                            className="quantity capitaluseraddress"
                                            value={secondaryIndividualFullName}
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError()
                                            }}
                                            placeholder="Enter first and last name "
                                        />
                                        <span className="error secondaryIndividualFullName"></span>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg={12} >
                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            name="SecondaryIndividualEmail"
                                            autoComplete="off"
                                            placeholder="Email address"
                                            className="quantity_com capitaluseraddress"
                                            onKeyPress={removeError}
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError();

                                            }}
                                            value={SecondaryIndividualEmail}
                                        />
                                        <span className="error SecondaryIndividualEmail"></span>
                                    </Col>
                                    <Col lg={12} className="Select_role">

                                        <input
                                            disabled={formData.isPrimary == 1 ? false : true}
                                            type="text"
                                            name="secondaryIndividualRole"
                                            className="capitaluseraddress quantity "
                                            value={secondaryIndividualRole}
                                            onChange={(e) => {
                                                setForm(e);
                                                removeError()
                                            }}
                                            placeholder="Enter their role "
                                        />
                                        <span className="error secondaryIndividualRole"></span>
                                    </Col>
                                </Row>
                            </> : null
                    }
                    <div className="spceimg ml-50">
                        <span id="name_tag" class="preview2"></span>
                        <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
                    </div>
                    <br />
                    <div className="fotter-btn">
                        <button className="preview" onClick={Onprevious}>
                            <img src={leftarrow} className="leftarrow" alt="" />
                            Previous
                        </button>

                        <button type="submit" className="next flagsave" onClick={handleClick}>
                            Continue
                            <img src={rightarrow} className="rightarrow" alt="" />{' '}
                        </button>
                    </div>
                </div>
            </div >
        </>
    );
};
export default EntityThird1;
