import React from "react";

const states = [
  ["Select", "Select"],
  ["Biometric", "Option 1: Biometrics process with selfie photos"],
  ["document", "Option 2: Upload copy of identification document"]

];

const BiometricOption = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others}>
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default BiometricOption;
