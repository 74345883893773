import React from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

// if(userValid.email_verified == true){
//   const RootStyle = styled('div')({
//     display: 'flex',
//     minHeight: '100%',
//     overflow: 'hidden'
//   });
// }
// else{
//   const RootStyle = styled('div')({
//     display: 'none',
//   });
// }


const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {

 
  let investorRelation = sessionStorage.getItem( "investorRelation");
  
  return (
    <RootStyle >
      <DashboardNavbar 
      // onOpenSidebar={() => setOpen(true)} 
      investorRelation={investorRelation} />
      <div className='left__nav__main'>
      </div>
      <MainStyle className="content__box__main">
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
