import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import attch from '../attch.png';
import Container from 'react-bootstrap/Container';
import ItemForm from '../ItemForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import logo from '../logo.png';
import InputMask from 'react-input-mask';
import info from '../info.png';
import $ from 'jquery';
import swal from 'sweetalert';
import MenuSidebar from '../Individual/MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';

const Five = ({ func, bankowner, setForm, formData, navigation, saveAndExist }) => {
  const { isNZBank, isNZBank1, isNZBank2, accountName, accountNumber, swiftCode } = formData;
  const [file, setFile] = useState(null);
  async function uploadWithJSON() {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    next();
    // go("Ten")
    localStorage.setItem('bankFile', data.img64);
  }
  const { fullName } = formData;
  const { previous, next } = navigation;
  const { go } = navigation;

  let idImage = localStorage.getItem('idImage');
  // let idImage = localStorage.getItem('bankFile');
  const [state, setstate] = useState();
  const [stateet, setStatet] = useState();
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user'
  };
  const handleClick = () => (setStatet(!stateet), $('.setSelfieImage').attr('src', ''));

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '7';
    // swal({
    //   text: "Your application saved",
    // });
  func();
  };

 
  function handleClick2(e) {
    var data = $('.setSelfieImage').attr('src');

   
      formData.class = "Nine"
     go("Nine")
    
  }
  function removeError() {
    $('.error').html('');
  }
  // const saveandexit = () => {
  //   func();
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <MenuSidebar navigation={navigation} formData={formData}/>
    <div className="main_container">
      <div className="heading__top">
        <h2>Application: Bank Account Details</h2>
      </div>

      <div className="form ">
      <img src={logo} alt="img" className="imageCover" />
      <div className="save-exit se_invff joint-holder">
        <p className="save next" onClick={saveAndExist}>
        SAVE DATA{" "}
        </p>
        <img src={info} onClick={infodetls} className="info" />
        </div>

        <div className="grid-container">
          <div className="grid-item "></div>
          <div className="grid-item "></div>
          <div className="grid-item "></div>
          <div className="grid-item"></div>
          <div className="grid-item active"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
        </div>

        <Row>
          <Col xs={8}>
            <label>Are you providing one joint bank account, or two bank accounts?</label>
          </Col>
          <Col xs={4}>
            <div className="button_group space-bottom">
              <div class="switch-field nospace">
                <div className="check_YN_Bank">
                  <input
                    type="radio"
                    id="radio-two1"
                    name="isNZBank1"
                    value="false"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <label
                    for="radio-two1"
                    className={`toogles1 postalAddressButtons1 ${
                      isNZBank1 === 'false' ? 'actives' : null
                    }`}
                    id="show"
                  >
                    ACCOUNT
                  </label>
                  <input
                    type="radio"
                    id="radio-one1"
                    className="yesno"
                    name="isNZBank1"
                    value="true"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <label
                    for="radio-one1"
                    className={`toogles1 postalAddressButton1 ${
                      typeof isNZBank1 === 'undefined' || isNZBank1 === 'true' ? 'actives' : null
                    }`}
                    id="hide"
                  >
                    ACCOUNT
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>

		<Row className="fullinput">
			<Col md={6}>
			<h4 className="mt-3">Bank account details for
      First joint account holder:</h4>
			<Row>
          <Col xs={8}>
            <label>IS THIS A NEW ZEALAND BANK ACCOUNT?</label>
          </Col>

          <Col xs={4}>
            <div className="button_group space-bottom">
              <div class="switch-field nospace">
                <div className="check_YN_Bank">
                  <input
                    type="radio"
                    id="radio-two"
                    name="isNZBank"
                    value="false"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <label
                    for="radio-two"
                    className={`toogles postalAddressButtons ${
                      isNZBank === 'false' ? 'actives' : null
                    }`}
                    id="show"
                  >
                    NO
                  </label>
                  <input
                    type="radio"
                    id="radio-one"
                    className="yesno"
                    name="isNZBank"
                    value="true"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <label
                    for="radio-one"
                    className={`toogles postalAddressButton ${
                      typeof isNZBank === 'undefined' || isNZBank === 'true' ? 'actives' : null
                    }`}
                    id="hide"
                  >
                    YES
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="col-md-12">
            {isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined' ? (
              <div className="row">
                <Col xs={12}>
                  <ItemForm
                    label="Bank account name "
                    className="quantity"
                    name="accountName"
                    value={accountName}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    type="text"
                    placeholder="Enter account name"
                    onKeyPress={removeError}
                  />
                  <span className="error accountName"></span>
                  <label>Bank account number</label>
                  <InputMask
                    mask="99-9999-9999999-99"
                    maskChar={null}
                    maskPlaceholder={null}
                    className="numbers"
                    name="accountNumber"
                    type=""
                    value={accountNumber}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="xx-xxxx-xxxxxxx-xx"
                    onKeyPress={removeError}
                  />
                  <span className="error accountNumber"></span>
                </Col>
              </div>
            ) : (
              <div className="row">
                <Col xs={12}>
                  <label>Bank account name</label>
                  <ItemForm
                    className="quantity"
                    name="accountName"
                    type="text"
                    value={accountName}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    onKeyPress={removeError}
                    placeholder="Enter account name"
                  />
                  <span className="error accountName"></span>
                  <label>Bank swift code</label>
                  <ItemForm
                    className="digitsOnly"
                    type=""
                    name="swiftCode"
                    value={swiftCode}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter swift code"
                    onKeyPress={removeError}
                  />{' '}
                  <span className="error swiftCode"></span>
                  <label>Account/IBAN Number</label>
                  <ItemForm
                    className="numbers"
                    type="text"
                    name="accountNumber"
                    value={accountNumber}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter account number"
                    onKeyPress={removeError}
                  />
                  <span className="error accountNumber"></span>
                </Col>
              </div>
            )}
          </div>

          {/* <Col xs={12}>
  
      <Search   placeholder="Enter account name"/>
  
      </Col> */}
        </Row>

        <Row className="fullinput">
          <Col xs={4}>
            <div class="yes">
              <span class="btn_upload">
                <input
                  type="file"
                  id="imag2"
                  title=""
                  onClick={removeError}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {/* <img src={attch} alt="" /> */}
              </span>
              <p className="clickAttach">CLICK TO ATTACH</p>
            </div>
          </Col>

          <Col xs={11} className="pl-0">
            <label>Upload proof of account</label>
            <span className="error file ml-0"></span>
          </Col>
        </Row>
			</Col>

			<Col md={6}>
			<h4 className="mt-3">Bank account details for
Second joint account holder:</h4>
			<Row>
          <Col xs={8}>
            <label>IS THIS A NEW ZEALAND BANK ACCOUNT?</label>
          </Col>

          <Col xs={4}>
            <div className="button_group space-bottom">
              <div class="switch-field nospace">
                <div className="check_YN_Bank">
                  <input
                    type="radio"
                    id="radio-two2"
                    name="isNZBank2"
                    value="false"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <label
                    for="radio-two2"
                    className={`toogles postalAddressButtons ${
                      isNZBank2 === 'false' ? 'actives' : null
                    }`}
                    id="show"
                  >
                    NO
                  </label>
                  <input
                    type="radio"
                    id="radio-one2"
                    className="yesno"
                    name="isNZBank2"
                    value="true"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <label
                    for="radio-one2"
                    className={`toogles postalAddressButton ${
                      typeof isNZBank2 === 'undefined' || isNZBank2 === 'true' ? 'actives' : null
                    }`}
                    id="hide"
                  >
                    YES
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="col-md-12">
            {isNZBank2 === 'true' || isNZBank2 === null || typeof isNZBank2 === 'undefined' ? (
              <div className="row">
                <Col xs={12}>
                  <ItemForm
                    label="Bank account name "
                    className="quantity"
                    name="accountName"
                    value={accountName}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    type="text"
                    placeholder="Enter account name"
                    onKeyPress={removeError}
                  />
                  <span className="error accountName"></span>
                  <label>Bank account number</label>
                  <InputMask
                    mask="99-9999-9999999-99"
                    maskChar={null}
                    maskPlaceholder={null}
                    className="numbers"
                    name="accountNumber"
                    type=""
                    value={accountNumber}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="xx-xxxx-xxxxxxx-xx"
                    onKeyPress={removeError}
                  />
                  <span className="error accountNumber"></span>
                </Col>
              </div>
            ) : (
              <div className="row">
                <Col xs={12}>
                  <label>Bank account name</label>
                  <ItemForm
                    className="quantity"
                    name="accountName"
                    type="text"
                    value={accountName}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    onKeyPress={removeError}
                    placeholder="Enter account name"
                  />
                  <span className="error accountName"></span>
                  <label>Bank swift code</label>
                  <ItemForm
                    className="digitsOnly"
                    type=""
                    name="swiftCode"
                    value={swiftCode}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter swift code"
                    onKeyPress={removeError}
                  />{' '}
                  <span className="error swiftCode"></span>
                  <label>Account/IBAN Number</label>
                  <ItemForm
                    className="numbers"
                    type="text"
                    name="accountNumber"
                    value={accountNumber}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter account number"
                    onKeyPress={removeError}
                  />
                  <span className="error accountNumber"></span>
                </Col>
              </div>
            )}
          </div>

          {/* <Col xs={12}>
  
      <Search   placeholder="Enter account name"/>
  
      </Col> */}
        </Row>
        <Row className="fullinput">
          <Col xs={4}>
            <div class="yes">
              <span class="btn_upload">
                <input
                  type="file"
                  id="imag2"
                  title=""
                  onClick={removeError}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {/* <img src={attch} alt="" /> */}
              </span>
              <p className="clickAttach">CLICK TO ATTACH</p>
            </div>
          </Col>
          <Col xs={8} className="pl-0">
            <label>Upload proof of account</label>
            <span className="error file ml-0"></span>
          </Col>
        </Row>
			</Col>
		</Row>
        <div className="spceimg">
          <span id="name_tag" class="preview2"></span>
          <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
        </div>
        <br />
        <div className="fotter-btn">
          <button className="preview" onClick={previous}>
            <img src={leftarrow} className="leftarrow" alt="" />
            Previous
          </button>
          <button type="submit" className="next" onClick={handleClick2}>
            Continue
            <img src={rightarrow} className="rightarrow" alt="" />{' '}
          </button>
        </div>
      </div>
      </div>
    </>
  );
};

export default Five;
