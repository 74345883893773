/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import ItemForm from '../ItemForm';
// import attch from '../attch.png';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import InputMask from 'react-input-mask';
import ReactFlagsSelect from 'react-flags-select';
import logo from '../logo.png';
import $, { now } from 'jquery';
import info from '../info.png';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import swal from 'sweetalert';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { getCode } from 'country-list';
// import { validateDateInput, IdExpDateValidator, expiryDateError } from '../components/Dateformator';
import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import Swal from 'sweetalert2';
import { infodetls } from '../Individual/SaveInfo';
import UploadImage from '../Individual/UploadImage';

// const { useState } = React;

const Six = ({ setForm, formData, navigation, saveAndExist, idinfo, func }) => {
  const {
    fullName,
    idType,
    documentVersionNumber,
    documentCountryOfIssue,
    documentExpiryDate,
    otherDocumentName,
  } = formData;
  const { next } = navigation;



  const [namefull, setNamefull] = useState(fullName ? fullName.trim().split(' ') : '');
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];




  const [firstName, setFirstName] = useState(fullName != null && fullName != "" && fullName != "undefined" ? namefull[0] : "");

  const [lastName, setLastName] = useState(namefull.length > 1 ? namefull[namefull.length - 1] : "")

  const [middleName, setMiddleName] = useState(namefull && namefull.length > 2 ? namefull.slice(1, namefull.length - 1).join().replace(/,/g, ' ') : "")



  const setNamer = (e) => {
    setFirstName(e.target.value)


  }

  const setNamer1 = (e) => {
    setMiddleName(e.target.value)
  }


  const setNamer2 = (e) => {
    setLastName(e.target.value)


  }


  const saveandexit = () => {
    formData.documentCountryOfIssue = selected;
    var x = $('#colorselector option:selected').val();
    formData.idType = x;
    if (x === '1' || x === '') {
      if (
        formData.documentProof !== null &&
        formData.documentProof !== undefined &&
        formData.documentProof !== 'undefined' &&
        formData.documentProof !== ''
      ) {
        func();
      } else {
        func();
      }
    } else if (formData.idType === '2') {
      if (
        formData.documentProof !== null &&
        formData.documentProof !== undefined &&
        formData.documentProof !== 'undefined' &&
        formData.documentProof !== ''
      ) {
        func();
      } else {
        func();
      }
    } else {
      if (
        formData.documentProof !== null &&
        formData.documentProof !== undefined &&
        formData.documentProof !== 'undefined' &&
        formData.documentProof !== ''
      ) {
        func();
      } else {
        func();
      }
    }
  };

  let countrtyCode = [
    'NZ',
    'AF',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BR',
    'IO',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CO',
    'KM',
    'CG',
    'CD',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'PF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'HT',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RO',
    'RU',
    'RW',
    'KN',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SZ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'US',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VI',
    'YE',
    'ZM',
    'ZW'
  ];

  let errortext = 'please uploade a valid image';
  const [errror, setErrror] = useState();
  const [imageLoader, setImageLoader] = useState();
  const [imageLoader1, setImageLoader1] = useState();

  const [state, setState] = useState({ shown: true });

  const [file, setFile] = useState({
    image: ''
  });
  const [imgeFile64, setImgeFile64] = useState('');
  const [documentFile64, setdocumentFile64] = useState('');
  let token = sessionStorage.getItem('token');

  const [user, setUser] = useState();
  // let { document, fileName } = documentFile64;
  const datetime = new Date();
  let labeltxt = 'Please attach a copy of your photo identification document (maximum file size is 2MB).';
  const columnName =  'documentProof';
  let valuedefine='document';
  const fileName =   idType === '1' ||
            idType === 'undefined' ||
            idType === '' ||
            idType === undefined ||
            idType === null ||
            idType === 'NZ Drivers Licence'
            ? `NZ_Drivers_Licence`
            : idType === '2'
              ? `NZ_Passport`
              : idType === '3'
                ? `other`
                : ''
  // async function uploadWithJSON(file, e) {
  //   formData.documentProof = '';

  //   setErrror('');
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);

  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   // next();
  //   setdocumentFile64(data.img64);

  //   if (
  //     data.img64 !== null &&
  //     data.img64 !== '' &&
  //     data.img64 !== 'undefined' &&
  //     data.img64 !== undefined
  //   ) {
  //     let datadocument = {
  //       columnName: 'documentProof',
  //       fileName:
  //         idType === '1' ||
  //           idType === 'undefined' ||
  //           idType === '' ||
  //           idType === undefined ||
  //           idType === null ||
  //           idType === 'NZ Drivers Licence'
  //           ? `NZ_Drivers_Licence`
  //           : idType === '2'
  //             ? `NZ_Passport`
  //             : idType === '3'
  //               ? `other`
  //               : '',
  //       document: data.img64
  //     };
  //     setImageLoader(true);
  //     axios
  //       .post(`${multisavedata}/genimageurl`, datadocument, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         if (
  //           formData.idType === '1' ||
  //           formData.idType === 'undefined' ||
  //           formData.idType === undefined ||
  //           formData.idType === null ||
  //           (formData.idType === '' && res)
  //         ) {
  //           formData.documentProof = res.data.url;

  //           if (res.status === 200) {
  //             setImageLoader(false);
  //           } else {
  //             setImageLoader(false);
  //           }
  //         } else if (formData.idType === '2' && res) {
  //           formData.documentProof = res.data.url;

  //           if (res.status === 200) {
  //             setImageLoader(false);
  //           } else {
  //             setImageLoader(false);
  //           }
  //         } else if (formData.idType === '3' && res) {
  //           formData.documentProof = res.data.url;

  //           if (res.status === 200) {
  //             setImageLoader(false);
  //           } else {
  //             setImageLoader(false);
  //           }
  //         } else {
  //           if (res.status === 200) {
  //             setImageLoader(false);
  //           } else {
  //             setImageLoader(false);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setImageLoader(false);
  //       });
  //   }
  // }
  // async function uploadWithJSONONE(file, e) {
  //   formData.documentnotupload = true
  //   formData.documentProof1 = '';

  //   setErrror('');
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);

  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   // next();
  //   setdocumentFile64(data.img64);

  //   if (
  //     data.img64 !== null &&
  //     data.img64 !== '' &&
  //     data.img64 !== 'undefined' &&
  //     data.img64 !== undefined
  //   ) {
  //     let datadocument = {
  //       columnName: 'documentIdentificationProof',
  //       fileName:`NZ_Drivers_Licence_indentity_${datetime}`,
  //       document: data.img64
  //     };
  //     setImageLoader1(true);
  //     axios
  //       .post(`${multisavedata}/genImageUrl`, datadocument, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         if (
  //           formData.idType === '1' ||
  //           formData.idType === 'undefined' ||
  //           formData.idType === undefined ||
  //           formData.idType === null ||
  //           (formData.idType === '' && res)
  //         ) {
  //           formData.documentProof1 = res.data.url;

  //           if (res.status === 200) {
  //             setImageLoader1(false);
  //           } else {
  //             setImageLoader1(false);
  //           }
  //         } else if (formData.idType === '2' && res) {
  //           formData.documentProof1 = res.data.url;

  //           if (res.status === 200) {
  //             setImageLoader1(false);
  //           } else {
  //             setImageLoader1(false);
  //           }
  //         } else if (formData.idType === '3' && res) {
  //           formData.documentProof1 = res.data.url;

  //           if (res.status === 200) {
  //             setImageLoader1(false);
  //           } else {
  //             setImageLoader1(false);
  //           }
  //         } else {
  //           if (res.status === 200) {
  //             setImageLoader1(false);
  //           } else {
  //             setImageLoader1(false);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setImageLoader1(false);
  //       });
  //   }
  // }

  let documentExpiryDates = sessionStorage.getItem('documentExpiryDates');

  useEffect(() => {
    let documentExpiryDates = sessionStorage.getItem('documentExpiryDates');
    $('.ui.icon.input>input').val(documentExpiryDates);

    let dataStr = sessionStorage.getItem('allData');

    let dataObj = JSON.parse(dataStr);
    function setDateformate(date) {
      if (
        date !== null &&
        date !== 'undefined' &&
        typeof date !== 'undefined' &&
        date.includes('-')
      ) {
        let dat = date.split('-');
        dat = dat[2] + '/' + dat[1] + '/' + dat[0];
        return dat;
      }
      return date;
    }
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);

      }
    }
    function readURL1(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);

        $('#name_tag1').text(input.files[0].name);
      }
    }

    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });
    $('#imag3').change(function () {
      var imgControlName = '#ImgPreview3';
      readURL1(this, imgControlName);
      $('.preview3').addClass('it');
      $('.btn-rmv3').addClass('rmv');
    });

    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
    $('#removeImage3').click(function (e) {
      e.preventDefault();
      $('#imag3').val('');
      $('#ImgPreview3').attr('src', '');
      $('.preview3').text('');
      $('.btn-rmv3').removeClass('rmv');
    });
    // if (licdatee !== null && licdatee !== "undefined" && typeof licdatee !== "undefined") {
    //   $(".ui.icon.input.counrtyselext6>input").val(setDateformate(licdatee))
    // }
    // if (pasdatee !== null && pasdatee !== "undefined" && typeof pasdatee !== "undefined") {
    //   $(".ui.icon.input.counrtyselext7>input").val(setDateformate(pasdatee))
    // }
    // if (pasdatee !== null && pasdatee !== "undefined" && typeof pasdatee !== "undefined") {
    //   $(".ui.icon.input.counrtyselext8>input").val(setDateformate(pasdatee))
    // }

    $(function () {
      $('#colorselector')
        .change(function () {
          var select = $(this).find(':selected').val();
          $('.hide').hide();
          $('#' + select).show();
        })
        .change();
    });

    $('.flagsave').click(function () {
      let documentCountryOfIssue = $('.counrtyselext3 #select_flag_button > span > span').text();
      localStorage.setItem('documentCountryOfIssue', documentCountryOfIssue);
    });
  });
  const [validate, setValidate] = useState(false)
  function expirydatesremovee(valuee) {
    sessionStorage.removeItem('documentExpiryDates');

    formData.documentExpiryDates1 = ""
    {
      valuee === 'pass'
        ? sessionStorage.setItem('documentExpiryDates', "")
        : valuee === 'lice'
          ? sessionStorage.setItem('documentExpiryDates', "")
          : sessionStorage.setItem('documentExpiryDates', "")
    }

  }
  function expirydates(valuee) {
    {
      valuee === 'pass'
        ? (documentExpiryDates = $('.ui.icon.input.pass>input').val())
        : valuee === 'lice'
          ? (documentExpiryDates = $('.ui.icon.input.lice>input').val())
          : (documentExpiryDates = $('.ui.icon.input.other>input').val());
    }

    sessionStorage.setItem('documentExpiryDates', documentExpiryDates);
    formData.documentExpiryDates = documentExpiryDates
    var d = new Date();
    var y = d.getFullYear()
    var m = d.getMonth() + 1;
    var D = d.getDate();
    var str = formData.documentExpiryDates !== "undefined" && formData.documentExpiryDates !== undefined && formData.documentExpiryDates !== null ? formData.documentExpiryDates : documentExpiryDate;
    var year = str.split("/").pop();
    var date = str.split("/");
    var month = date[1];
    if ((year > y)) {

      setValidate(true)
      removeError();
      // alert("df",validate)

    }
    else if ((year >= y) && (month > m)) {
      setValidate(true)
      removeError();
    }
    else if ((year >= y) && (month >= m) && (date[0] >= D)) {
      setValidate(true)
      removeError();

    }
    else {

      setValidate(false)
      sessionStorage.removeItem('documentExpiryDates');
      formData.documentExpiryDates = ""
    }
  }
  useEffect(() => {
    sessionStorage.setItem('documentExpiryDates', documentExpiryDates);
    formData.documentExpiryDates = documentExpiryDates
    var d = new Date();
    var y = d.getFullYear()
    var m = d.getMonth() + 1;
    var D = d.getDate();
    var str = formData.documentExpiryDates !== "undefined" && formData.documentExpiryDates !== undefined && formData.documentExpiryDates !== null ? formData.documentExpiryDates : "";
    var year = str.split("/").pop();
    var date = str.split("/");
    var month = date[1];
    if ((year > y)) {

      setValidate(true)
      removeError();
      // alert("df",validate)

    }
    else if ((year >= y) && (month > m)) {
      setValidate(true)
      removeError();
    }
    else if ((year >= y) && (month >= m) && (date[0] >= D)) {
      setValidate(true)
      removeError();

    }
    else {

      setValidate(false)
      sessionStorage.removeItem('documentExpiryDates');
      formData.documentExpiryDates = ""
    }
  }, [])
  function previousclick(e) {
    formData.fullName = firstName + (middleName ? " " + middleName : "") + (lastName ? " " + lastName : "")
    formData.documentCountryOfIssue = selected;
    var x = $('#colorselector option:selected').val();
    formData.idType = x;
    if (x === '1' || x === '') {

      if (
        formData.documentProof !== null &&
        formData.documentProof !== undefined &&
        formData.documentProof !== 'undefined' &&
        formData.documentProof !== ''
      ) {

        go('Jointeight');
      } else {
        go('Jointeight');
      }
    } else if (formData.idType === '2') {

      if (
        formData.documentProof !== null &&
        formData.documentProof !== undefined &&
        formData.documentProof !== 'undefined' &&
        formData.documentProof !== ''
      ) {
        go('Jointeight');
      } else {
        go('Jointeight');
      }
    } else {


      if (
        formData.documentProof !== null &&
        formData.documentProof !== undefined &&
        formData.documentProof !== 'undefined' &&
        formData.documentProof !== ''
      ) {
        go('Jointeight');
      } else {
        go('Jointeight');
      }
    }
  }

  function handleClick(e) {
    formData.fullName = firstName + (middleName ? " " + middleName : "") + (lastName ? " " + lastName : "")
    let name = $('#name_tag').text();
    let nameExpression = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
    formData.documentCountryOfIssue = selected;
    var x = $('#colorselector option:selected').val();

    formData.idType = x;

    if (formData.documentProof === '' || formData.documentProof === null || formData.documentProof === undefined) {
      $('.documentError').html('Please attach identification document');
    }else{
      if (x === '1' || x === '') {
        if (
          formData.documentProof !== null &&
          formData.documentProof !== undefined &&
          formData.documentProof !== 'undefined' &&
          formData.documentProof !== ''
        ) {
          if (validate === true) {
            next();
          }
          else {
            $(".licexperror").html("Please select valid expiry date");
          }
        } else {
          if (validate === true) {
            next();
          }
          else {
            $(".licexperror").html("Please select valid expiry date");
          }
  
        }
      } else if (formData.idType === '2') {
        if (
          formData.documentProof !== null &&
          formData.documentProof !== undefined &&
          formData.documentProof !== 'undefined' &&
          formData.documentProof !== ''
        ) {
          next();
        } else {
          next();
        }
      } else {
        documentExpiryDates = $('.ui.icon.input.counrtyselext8>input').val();
        formData.documentExpiryDates = documentExpiryDates;
  
        if (
          formData.documentProof !== null &&
          formData.documentProof !== undefined &&
          formData.documentProof !== 'undefined' &&
          formData.documentProof !== ''
        ) {
          next();
        } else {
          next();
        }
        }

    }
  
  
    }
  function removeError() {
    $('.error').html('');
  }




  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, data) => setNewDate(data.value);

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '5';
    // swal({
    //   text: "Your application saved",
    // });
    go('submit');
  };
 
  idinfo = () => {
    swal({
      text: 'MyFarm is required to comply with the New Zealand Government regulations to fight the funding of terrorism and money laundering activities. We are required to obtain, verify, and record information about your identity.'
    });
  };
  const { go } = navigation;

  const [selected, setSelected] = useState(formData.documentCountryOfIssue);

  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });
  const inform = () => {
    swal({
      text: 'Certified copies must be stamped or endorsed as true copies of the originals by a person authorized by law to take statutory declarations. This maybe a Lawyer, Notary Public, Justice of Peace or Court Official.'
    });
  };
  const inform1 = () => {
    Swal.fire({
      html:
        '<p class="sizeText">The secondary form of identification must be a certified copy of one of the following:</p><ul class="sideleft"><li>Credit card statement issued by a registered bank (dated within the last 3 months)</li><li>Bank statement issued by a registered bank (dated within the last 3 months)</li><li>SuperGold Card</li><li>NZ Defence Photo ID</li><li>Police Photo ID</li><li>Community Services Card</li><li>Statement issued by a government agency</li></ul>',

    })
  };

  function setvalue() {
    formData.documentNumber = '';
    sessionStorage.setItem('documentExpiryDates', '');

    formData.documentExpiryDate = '';
    formData.documentVersionNumber = '';
    formData.documentCountryOfIssue = '';
    formData.documentProof = '';
    $('#name_tag').text('');
    $('.btn-rmv2').text('');
    $('.preview2').text('');
    $('.preview3').text('');
    $('#removeImage2').html('');
    $('.btn-rmv2').removeClass('rmv');
    $('.btn-rmv3').removeClass('rmv');
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // VAlidation Start
    if (formData.check3 === false) {
      if (firstName === '') {
        $('.firstName').html('Please enter your first name');
      }
      if (formData.documentNumber === '' || formData.documentNumber === null || formData.documentNumber === undefined) {
        $('.documentNumber').html('Please enter your document number');
      }
      if (formData.documentProof === '' || formData.documentProof === null || formData.documentProof === undefined) {
        $('.documentProof').html('Please attach your document proof');
      }
      if (formData.otherDocumentName === '' || formData.otherDocumentName === null || formData.otherDocumentName === undefined) {
        $('.otherDocumentName').html('Please enter your document type');
      }
      if (documentExpiryDates === '' || documentExpiryDates === null || documentExpiryDates === undefined) {
        $('.documentExpiryDates').html('Please select document expiry date');
      }
      if (lastName === '') {
        $('.lastName').html('Please enter your last name');
      }
      if (formData.documentNumber === '' || formData.documentNumber === null || formData.documentNumber === undefined) {
        $('.licenceNumber').html('Please enter your licence number');
      }

      if (formData.documentNumber === '' || formData.documentNumber === null || formData.documentNumber === undefined) {
        $('.Passportnumber').html('Please enter document passport number');
      }

      if (documentVersionNumber === '' || documentVersionNumber === null || documentVersionNumber === undefined) {
        $('.documentVersionNumber').html('Please enter your version number');
      }
      if (formData.documentProof === '' || formData.documentProof === null || formData.documentProof === undefined) {
        $('.documentError').html('Please attach identification document');
      }
    }
    // Validation End
  })
  const [image, setImage] = useState();
  const downloadImage = (imageUrl) => {
    let postadv = {
      downloadUrl: imageUrl
    };
    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };
  
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          {/* <h2>Application: Identity Verification - {(fullName != "undefined"&& fullName != null ? formData.firstName:firstNameindi != null && firstNameindi != "" && firstNameindi != "undefined"?firstNameindi:"")+ ' ' + (fullName  != "undefined"&& fullName != null ?formData.lastName:lastNameindi != "" && lastNameindi != null && lastNameindi != "undefined"?lastNameindi:"")}</h2> */}
          <h2>Step 4: Identity Verification - {(firstName != null && firstName != "" && firstName != "undefined" && firstName != undefined ? firstName : "") + " " + (lastName != null && lastName != "" && lastName != "undefined" && lastName != undefined ? lastName : "")}</h2>
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>
          <div className="grid-container">
            <div className="grid-item "></div>
            <div className="grid-item"></div>
            <div className="grid-item "></div>
            <div className="grid-item active"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
          </div>

          <Row className="six_page">
            <Col md={12}>
              <label className='font-fami'>Select the type of photo identification you are providing</label>

              <div className="button dropdown">
                <select
                  id="colorselector"
                  name="idType"
                  value={formData.idType}
                  onChange={(e) => {
                    setvalue();
                    setForm(e);
                    removeError();
                  }}
                >
                  <option value="1" selected>
                    NZ Driver Licence
                  </option>
                  <option value="2">NZ Passport</option>
                  <option value="3">Other</option>
                </select>
              </div>

              <div class="output date-w">
                <label> First name</label>
                <ItemForm
                  type="text"
                  className="quantity"
                  name="firstName"
                  maxLength={70}
                  onKeyPress={removeError}
                  value={firstName}
                  onChange={(e) => {
                    setForm(e);
                    setNamer(e)
                    removeError();
                  }}
                  placeholder="Enter first name(s)"
                />
                <span className="error firstName fullNameerror"></span>
                <label> Middle name</label>
                <ItemForm
                  type="text"
                  className="quantity"
                  name="middleName"
                  maxLength={70}
                  onKeyPress={removeError}
                  value={middleName}
                  onChange={(e) => {
                    setForm(e);
                    setNamer1(e)
                    removeError();
                  }}
                  placeholder="Enter middle name(s)"
                />
                {/* <span className="error middleName"></span> */}
                <label> Last name</label>
                <ItemForm
                  type="text"
                  className="quantity"
                  name="lastName"
                  maxLength={70}
                  onKeyPress={removeError}
                  value={lastName}
                  onChange={(e) => {
                    setForm(e);
                    setNamer2(e)
                    removeError();
                  }}
                  placeholder="Enter last name(s)"
                />
                <span className="error lastName"></span>
                <div id="1" className="colors red hide">
                  <label> Licence number</label>
                  <ItemForm
                    className="digitsOnly"
                    type="text"
                    name="documentNumber"
                    onKeyPress={removeError}
                    maxLength={10}
                    value={formData.documentNumber}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter licence number"
                  />
                  <span className="error licenceNumber"></span>
                  <label> Expiry date</label>
                  <SemanticDatepicker
                    onChange={(e) => {
                      removeError();
                      expirydates('lice');
                    }}
                    onClick={(e) => {
                      removeError();
                      expirydatesremovee('lice');
                    }}
                    autoComplete="off"
                    className="lice counrtyselext6 numbers input__w50_a"
                    minDate={now()}
                    format="DD/MM/YYYY"
                    name="documentExpiryDate"
                    // value={documentExpiryDate}

                    allowOnlyNumbers
                  />
                  <span className="error documentExpiryDates licexperror" />
                  {/* <ItemForm type="date" name="documentExpiryDate" value={documentExpiryDate} onChange={e=>{setForm(e) ; removeError()}} placeholder="dd/mm/yyyy" /> */}

                  <label> Version number</label>
                  <InputMask

                    className="numbers"
                    name="documentVersionNumber"
                    onKeyPress={removeError}
                    value={documentVersionNumber}
                    maxLength={3}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter version number"
                  />
                  <span className="error documentVersionNumber"></span>
                </div>

                <div id="2" className="colors yellow hide" onClick={removeError}>
                  <label> Passport number</label>
                  <ItemForm
                    className="digitsOnly"
                    name="documentNumber"
                    maxLength={10}
                    onKeyPress={removeError}
                    value={formData.documentNumber}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter passport number"
                  />
                  <span className="error Passportnumber passportNumber"></span>
                  <label> Expiry date</label>
                  {/* <ItemForm type="date" name="documentExpiryDate" value={documentExpiryDate} onChange={e=>{setForm(e) ; removeError()}} placeholder="dd/mm/yyyy" /> */}
                  <SemanticDatepicker
                    onChange={(e) => {
                      removeError();
                      expirydates('pass');
                    }}
                    onClick={(e) => {
                      removeError();
                      expirydatesremovee('pass');
                    }}
                    className="pass counrtyselext7 numbers input__w50_a"
                    minDate={now()}
                    format="DD/MM/YYYY"
                    name="documentExpiryDate"
                    allowOnlyNumbers
                  // value={documentExpiryDate}
                  />
                  <span className="error documentExpiryDates licexperror" />

                  <div className="FlagsSelectcss">
                    <label> Country of issue</label>
                    <ReactFlagsSelect
                      name="documentCountryOfIssue"
                      selected={selected}
                      onSelect={(code) => setSelected(code)}
                      className="counrtyselext3 input__w50"
                      value={documentCountryOfIssue}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      defaultCountry={
                        documentCountryOfIssue && documentCountryOfIssue !== null
                          ? getCode(documentCountryOfIssue.toUpperCase())
                          : null
                      }
                      countries={countrtyCode}
                      placeholder="Select"
                    />
                  </div>
                  <span className="error documentCountryOfIssueerror"></span>
                </div>

                <div id="3" className="colors blue hide" onClick={removeError}>
                  <label> Document type</label>
                  <ItemForm
                    className="digitsOnly"
                    type="text"
                    maxLength={15}
                    onKeyPress={removeError}
                    name="otherDocumentName"
                    value={otherDocumentName}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="EG passport, firearms licence"
                  />
                  <span className="error otherDocumentName"></span>
                  <label> Document number</label>
                  <input
                    type="text"
                    name="documentNumber"
                    onKeyPress={removeError}
                    value={formData.documentNumber}
                    maxLength={10}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter number"
                    className="digitsOnly"
                  />
                  <span className="error documentNumber"></span>

                  <label> Expiry date</label>
                  {/* <ItemForm type="date" name="documentExpiryDate" value={documentExpiryDate} onChange={e=>{setForm(e) ; removeError()}} placeholder="dd/mm/yyyy" /> */}
                  <SemanticDatepicker
                    onChange={(e) => {
                      removeError();
                      expirydates('other');
                    }}
                    onClick={(e) => {
                      removeError();
                      expirydatesremovee('other');
                    }}
                    className="other counrtyselext8 numbers"
                    minDate={now()}
                    format="DD/MM/YYYY"
                    name="documentExpiryDate"
                    allowOnlyNumbers
                  />
                  <span className="error documentExpiryDates licexperror" />

                  <div className="FlagsSelectcss">
                    <label> Country of issue</label>

                    <ReactFlagsSelect
                      name="documentCountryOfIssue"
                      selected={selected}
                      value={documentCountryOfIssue}
                      placeholder="Select"
                      onKeyPress={removeError}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      onSelect={(code) => setSelected(code)}
                      defaultCountry={
                        documentCountryOfIssue && documentCountryOfIssue !== null
                          ? getCode(documentCountryOfIssue.toUpperCase())
                          : null
                      }
                    />
                  </div>

                  <span className="error documentCountryOfIssueerror"></span>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row className="fullinput">
            <Col xs={12} className='d-flex flex-wrap'>
              <div className='attach'>
                <div class="yes1">
                  <button className="click-hover">
                    {' '}
                    <span class="btn_upload">
                      <input
                        type="file"
                        id="imag2"
                        title=""
                        name="image"
                        onClick={() => { removeError(); }}
                        value={document}
                        onChange={(e) => {
                          uploadWithJSON(e.target.files[0]);
                          removeError();
                        }}
                      />
                      
                    </span>
                    CLICK TO ATTACH
                  </button>
                </div>
              </div>
              <div className="attach-styhle-proof">
                <label className="bankdClick">
                  Please attach a copy of your photo identification document.
                </label>
              </div>
            </Col>

           
          </Row>
          <br />
          {formData.documentProof === '' || formData.documentProof === null ? <span className='error documentProof'></span> : ''}
          <div className="spceimg">
            {formData.idType === '1' ||
              formData.idType === '' ||
              formData.idType === 'undefined' ||
              formData.idType === undefined ||
              formData.idType === null ? (
              formData.documentProof !== null &&
                formData.documentProof !== undefined &&
                formData.documentProof !== 'undefined' &&
                formData.documentProof !== '' ? (
                <a href="#" className="documentProof-downloade" onClick={() => { downloadImage(formData.documentProof) }}>
                  {' '}
                  <div className="main-downloade-wrapper">
                    <div className="downloads">
                      <i className="fa fa-download downloadeStyle"></i>
                    </div>
                    <p className="downloadeMsg">View attached file</p>
                  </div>
                </a>
              ) : (
                ''
              )
            ) : formData.idType === '2' ? (
              formData.documentProof !== null &&
                formData.documentProof !== undefined &&
                formData.documentProof !== 'undefined' &&
                formData.documentProof !== '' ? (
                <a href="#" className="documentProof-downloade" onClick={() => { downloadImage(formData.documentProof) }}>
                  {' '}
                  <div className="main-downloade-wrapper">
                    <div className="downloads">
                      <i className="fa fa-download downloadeStyle"></i>
                    </div>
                    <p className="downloadeMsg">View attached file</p>
                  </div>
                </a>
              ) : (
                ''
              )
            ) : formData.idType === '3' ? (
              formData.documentProof !== null &&
                formData.documentProof !== undefined &&
                formData.documentProof !== 'undefined' &&
                formData.documentProof !== '' ? (
                <a href="#" className="documentProof-downloade" onClick={() => { downloadImage(formData.documentProof) }}>
                  {' '}
                  <div className="main-downloade-wrapper">
                    <div className="downloads">
                      <i className="fa fa-download downloadeStyle"></i>
                    </div>
                    <p className="downloadeMsg">View attached file</p>
                  </div>
                </a>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            <span id="name_tag" class="preview2"></span>
            <input type="button" id="removeImage2" value="x" class="btn-rmv2" />

            <p className="red">{errror}</p>
            {imageLoader === true ? (
              <div class="progress">
                <span class="progress-bar" style={{ width: '100%' }}></span>
              </div>
            ) : null}
          </div>

          {formData.idType === '1' || formData.idType == "" || formData.idType == "undefined" ?
            
            : ""} */}
           <UploadImage
            formData={formData}
            columnName={columnName}
            fileName={fileName}
            labeltxt={labeltxt}
            buttonName='CLICK TO ATTACH'
            valuedefine={valuedefine}
          />
          
          <span className='documentError error'></span>



          <div className="fotter-btn">
            <button className="preview" onClick={previousclick}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Six;
