import React, { useEffect, useState } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import { boolean } from 'yup';
import MenuSidebar from './MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';


const Thirteen = ({ func, setForm, formData, navigation, saveAndExist }) => {
  const { investmentBusiness } = formData;
  const { previous, next } = navigation;
  const saveandexit = () => {
    func();
  };

  const allNone = (e) => {
    formData.investorPrincipalBusiness = false
    formData.investingFinancialProducts = false
    formData.actingUnderwriter = false
    formData.financialAdviceService = false
    formData.clientMoneyPropertyService = false
    formData.tradingFinancialProducts = false
    formData.registeredBankRBNZ = false
    formData.NBDTInvestor = false
    formData.licensedIssuerInsurance = false
    formData.registeredSchemeManager = false
    formData.derivativesIssuer = false
    formData.FinancialAdvisor = false
  }

  const Nonefalse = () => {
    formData.investmentBusinessNone = false
  }

  useEffect(() => {
    if (formData.investorPrincipalBusiness == true) {
      setDis(false)
    }
    else {
      setDis(true)
      formData.investingFinancialProducts = false
      formData.actingUnderwriter = false
      formData.financialAdviceService = false
      formData.clientMoneyPropertyService = false
      formData.tradingFinancialProducts = false
    }
  })


  let certificateDetails = {};




  function removeError() {
    $('.error').html('');
  }


  const { go } = navigation;


  const [dis, setDis] = useState(true)

  function handleClick(e) {
    formData.Certificate2 = ""

    formData.twoYearInvestEntity = formData.twoYearInvestEntity == true ? true : false


    if (formData.investmentBusinessNone == true) {
      go('Fifteen');
    }

    else {
      formData.ownedPortfolio2yrs = false
      formData.carriedTransactions2yrs = false
      formData.investmentBusiness10yrs = false
      formData.equitySecurities = false
      formData.managedFunds = false
      formData.derivativeInstruments = false
      formData.debtSecurities = false

      if (formData.investorPrincipalBusiness == true) {
        if (formData.investingFinancialProducts == false &&
          formData.actingUnderwriter == false &&
          formData.financialAdviceService == false &&
          formData.clientMoneyPropertyService == false &&
          formData.tradingFinancialProducts == false) {
          $('.investmentBusiness1').html('Please select at least one of these options');
          // go('Eleven');
        }
        else if (
          formData.investorPrincipalBusiness == true ||
          formData.registeredBankRBNZ == true ||
          formData.NBDTInvestor == true ||
          formData.licensedIssuerInsurance == true ||
          formData.registeredSchemeManager == true ||
          formData.derivativesIssuer == true ||
          formData.FinancialAdvisor == true
        ) {
          formData.acquiringDisposingFinancialProductsOther = null
          formData.startupOrBusiness = false
          formData.investorPresentation = false
          formData.myOwnInvestmentDecisions = false
          formData.valueAndRisksOfInvesting = false
          formData.capitalRaisingDirector = false
          formData.investingSharesSecurities = false
          formData.acquiringDisposingFinancialProducts = false
          go('Eleven');
        }
      }
      else if (formData.investorPrincipalBusiness == true ||
        formData.registeredBankRBNZ == true ||
        formData.NBDTInvestor == true ||
        formData.licensedIssuerInsurance == true ||
        formData.registeredSchemeManager == true ||
        formData.derivativesIssuer == true ||
        formData.FinancialAdvisor == true) {
        go('Eleven');

      }
      else {
        $('.investmentBusiness').html('Please select an answer');
      }

    }
  }
  console.log("formData.twoYearInvestEntity", formData.twoYearInvestEntity)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {/* <h2>Wholesale Certification: Wholesale Certification 1 </h2> */}
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Wholesale Certification A</h2> :
              <h2>Step 5: Wholesale Certification A</h2>

          }
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff ">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" alt="info" />
          </div>
          <label className='person'>Investment Business</label>
          <p className='font-fami'>
            The Investor was not established or acquired with a view to using it as an entity to
            which offers of financial products may be made in reliance upon the exclusion in clause
            3(2)(a) and the Investor is an “investment business” on the basis that;
          </p>
          {/* <p className='font-fami'>Select at least one of the below options</p> */}

          <div className="checkbox-my">
            <label className="containers restContainer font-fami">
              {
                formData.investorPrincipalBusiness == true ?
                  <p><b>The Investor’s principal business consists of one or more of the following :</b></p> :
                  <p>The Investor’s principal business consists of one or more of the following :</p>

              }
              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="investorPrincipalBusiness"
                checked={
                  formData.investorPrincipalBusiness == true ? true : false
                }
                onChange={(e) => {
                  setForm(e);
                }}
                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }
                }
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className="checkbox-my">
            <div className="">
              <label className="containers restContainer font-fami hh">{
                formData.investingFinancialProducts == true ? <b>Investing in financial products</b> : <p>Investing in financial products</p>
              }
                <input

                  type="checkbox"
                  name="investingFinancialProducts"
                  checked={
                    formData.investingFinancialProducts == true ? true : false

                  }
                  onClick={(e) => {
                    removeError()
                    Nonefalse(e)
                  }
                  }
                  disabled={dis}
                  onChange={(e) => {
                    setForm(e)
                  }
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label className="containers restContainer font-fami hh">{
                formData.actingUnderwriter == true ? <b>Acting as an underwriter</b> : <p>Acting as an underwriter</p>
              }
                <input
                  type="checkbox"
                  name="actingUnderwriter"
                  checked={
                    formData.actingUnderwriter == true ? true : false

                  }
                  disabled={dis}
                  onClick={(e) => {
                    removeError()
                    Nonefalse(e)
                  }
                  }
                  onChange={(e) => {
                    setForm(e)
                  }
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label className="containers restContainer font-fami hh">{
                formData.financialAdviceService == true ? <b> Providing a financial advice service</b> : <p> Providing a financial advice service</p>
              }
                <input
                  type="checkbox"
                  name="financialAdviceService"
                  checked={
                    formData.financialAdviceService == true ? true : false
                  }
                  disabled={dis}
                  onClick={(e) => {
                    removeError()
                    Nonefalse(e)
                  }
                  }
                  onChange={(e) => {
                    setForm(e)
                  }
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label className="containers restContainer font-fami hh">{

                formData.clientMoneyPropertyService == true ? <b>Providing a client money or property service</b> : <p>Providing a client money or property service</p>
              }
                <input
                  type="checkbox"
                  name="clientMoneyPropertyService"
                  checked={
                    formData.clientMoneyPropertyService == true ? true : false

                  }
                  disabled={dis}
                  onClick={(e) => {
                    removeError()
                    Nonefalse(e)
                  }
                  }
                  onChange={(e) => {
                    setForm(e)

                  }
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label className="containers restContainer font-fami hh">{
                formData.tradingFinancialProducts == true ? <b>Trading in financial products on behalf of other persons.</b> :
                  <p>Trading in financial products on behalf of other persons.</p>
              }
                <input
                  type="checkbox"
                  name="tradingFinancialProducts"
                  checked={
                    formData.tradingFinancialProducts == true ? true : false
                  }
                  disabled={dis}
                  onClick={(e) => {
                    removeError()
                    Nonefalse(e)
                  }
                  }
                  onChange={(e) => {
                    setForm(e)
                  }
                  }
                />
                <span className="checkmark"></span>

              </label>
              <span className="error investmentBusiness1"></span>
            </div>
          </div>

          <div className="checkbox-my">
            <label className="containers restContainer font-fami">{
              formData.registeredBankRBNZ == true ? <b>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</b> :
                <p>The Investor is a registered bank under the Reserve Bank of New Zealand Act 1989.</p>
            }

              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="registeredBankRBNZ"
                checked={
                  formData.registeredBankRBNZ == true ? true : false
                }
                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }
                }
                onChange={(e) => setForm(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="containers restContainer font-fami">{
              formData.NBDTInvestor == true ? <b>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</b> :
                <p>The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.</p>
            }
              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="NBDTInvestor"
                checked={
                  formData.NBDTInvestor == true ? true : false
                }
                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }
                }
                onChange={(e) => setForm(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="containers restContainer font-fami">{
              formData.licensedIssuerInsurance == true ?
                <b> The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</b> :
                <p>The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.</p>
            }
              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"

                name="licensedIssuerInsurance"
                checked={
                  formData.licensedIssuerInsurance == true ? true : false
                }
                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }
                }
                onChange={(e) => setForm(e)}
              />
              <span className="checkmark"></span>
            </label>

            <label className="containers restContainer font-fami">{
              formData.registeredSchemeManager == true ?
                <b>The Investor is the manager of a registered scheme, or a discretionary investment
                  management service, that holds a market services licence under the Financial Markets
                  Conduct Act 2013.
                </b> :
                <p>The Investor is the manager of a registered scheme, or a discretionary investment
                  management service, that holds a market services licence under the Financial Markets
                  Conduct Act 2013.</p>
            }

              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="registeredSchemeManager"
                checked={
                  formData.registeredSchemeManager == true ? true : false
                }

                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }}
                onChange={(e) => setForm(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="containers restContainer font-fami">{
              formData.derivativesIssuer == true ? <b>
                The Investor is a derivatives issuer that holds a market services license under the
                Financial Markets Conduct Act 2013.
              </b> :
                <p>
                  The Investor is a derivatives issuer that holds a market services license under the
                  Financial Markets Conduct Act 2013.
                </p>
            }

              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="derivativesIssuer"
                checked={
                  formData.derivativesIssuer == true ? true : false
                }
                onClick={(e) => {
                  removeError()
                  Nonefalse(e)
                }}
                onChange={(e) => setForm(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="containers restContainer font-fami">{
              formData.FinancialAdvisor == true ? <b>The Investor is a financial adviser.</b> :
                <p>The Investor is a financial adviser.</p>
            }
              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="FinancialAdvisor"
                checked={
                  formData.FinancialAdvisor == true ? true : false
                }
                onClick={(e) => {
                  Nonefalse(e)
                  removeError()
                }
                }
                onChange={(e) => setForm(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="containers font-fami">{
              formData.investmentBusinessNone == true ? <b>None of the above</b> : <p> None of the above</p>
            }
              <input
                disabled={formData.isPrimary == 1 ? false : true}
                type="checkbox"
                name="investmentBusinessNone"
                checked={
                  formData.investmentBusinessNone == true ? true : false
                }
                onClick={(e) => {
                  allNone(e)
                  removeError()
                }}
                onChange={(e) => {
                  setForm(e)

                }}
              />
              <span className="checkmark"></span>
            </label>

            <span className="error investmentBusiness"></span>
            <br />
          </div>

          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={previous}>
              <img src={leftarrow} className="leftarrow" alt="leftarrow" />
              Previous
            </button>
            <button type="submit" className="next" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="rightarrow" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Thirteen;
