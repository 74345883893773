/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import axios from 'axios';
import Swal from 'sweetalert2';
import Zero from './Individual/Zero';
import Second from './Individual/Second';
import { useAuth0 } from '@auth0/auth0-react';
import Third from './Individual/Third';
import Four from './Individual/Four';
import Five from './Individual/Five';
import Six from './Individual/Six';
import Seven from './Individual/Seven';
import Eight from './Individual/Eight';
import Nine from './Individual/Nine';
import Ten from './Individual/Ten';
import Eleven from './Individual/Eleven';
import Twelve from './Individual/Twelve';
import Thirteen from './Individual/Thirteen';
import Fifteen from './Individual/Fifteen';
import Sixteen from './Individual/Sixteen';
import Seventeen from './Individual/Seventeen';
import Eighteen from './Individual/Eighteen';
import Nineteen from './Individual/Nineteen';
import Twenty from './Individual/Twenty';
import Twentyone from './Individual/Twentyone';
import Twentyone1 from './Individual/Twentyone1';
import Twentyone2 from './Individual/Twentyone2';
import Twentytwo from './Individual/Twentytwo';
import Twentyone11 from './Individual/Twentyone11';

import JoinThird from './Joint/Jointhird';
import Jointfour from './Joint/Jointfour';
import Jointfive from './Joint/Jointfive';
import Jointsix from './Joint/Jointsix';
import Jointseven from './Joint/Jointseven';
import Jointeight from './Joint/Jointeight';
import Jointnine from './Joint/Jointnine';
import Jointten from './Joint/Jointten';
import Jointeleven from './Joint/Jointeleven';
import Jointtwelve from './Joint/Jointtwelve';
import Jointthirteen from './Joint/Jointthirteen';
import Jointfourteen from './Joint/Jointfourteen';
import Jointfivteen from './Joint/Jointfivteen';
import Jointsexteen from './Joint/Jointsexteen';
import Jointseventeen from './Joint/Jointseventeen';
import Jointeighteen from './Joint/Jointeighteen';
import Jointninteen from './Joint/Jointninteen';
import Jointtwenty from './Joint/Jointtwenty';
import Jointtwentyone from './Joint/Jointtwentyone';
import Jointtwentytwo from './Joint/Jointtwentytwo';
import Jointtwentythree from './Joint/Jointtwentythree';
import Jointtwentyfour from './Joint/Jointtwentyfour';
import Jointtwentyfive from './Joint/Jointtwentyfive';
import Jointtwentysix from './Joint/Jointtwentysix';
import Jointtwentyseven from './Joint/Jointtwentyseven';
import Jointtwentyeight from './Joint/Jointtwentyeight';
import Jointtwentynine from './Joint/Jointtwentynine';
import Jointtwentyten from './Joint/Jointtwentyten';

import EntityOne from './Company/EntityOne';
import EntityTwo from './Company/EntityTwo';
import EntityThird from './Company/EntityThird';
import EntityThird1 from './Company/EntityThird1';
import EntityFour from './Company/EntityFour';
import CompanyThird from './Company/CompanyThird';
import TrustFirst from './Trust/TrustFirst';
import TrustSecond from './Trust/TrustSecond';
import TrustSecond2 from './Trust/TrustSecond2';
import TrustThird from './Trust/TrustThird';
import TrustFour from './Trust/TrustFour';
import ThirdTrust from './Trust/ThirdTrust';

import Review from './Review';
import Submit from './Submit';
import $ from 'jquery';
import MenuSidebar from './Individual/MenuSidebar';
import { multisavedata } from 'src/auth/Configuration';
import DashboardNavbar from 'src/layouts/dashboard/DashboardNavbar';
const steps = [
  { id: 'Zero' },
  { id: 'MenuSidebar' },
  { id: 'Second' },
  { id: 'Third' },
  { id: 'Four' },
  { id: 'Five' },
  { id: 'Six' },
  { id: 'Seven' },
  { id: 'Eight' },
  { id: 'Nine' },
  { id: 'Ten' },
  { id: 'Twelve' },
  { id: 'Thirteen' },
  { id: 'Fifteen' },
  { id: 'Sixteen' },
  { id: 'Eleven' },
  { id: 'Seventeen' },
  { id: 'Eighteen' },
  { id: 'Twentyone' },
  { id: 'Twentyone1' },
  { id: 'Twentyone2' },
  { id: 'Twentyone11' },
  { id: 'Twentytwo' },

  { id: 'review' },
  { id: 'Jointhird' },
  { id: 'Jointfour' },
  { id: 'Jointfive' },
  { id: 'Jointsix' },
  { id: 'Jointseven' },
  { id: 'Jointeight' },
  { id: 'Jointnine' },
  { id: 'Jointten' },
  { id: 'Jointeleven' },
  { id: 'Jointtwelve' },
  { id: 'Jointfourteen' },
  { id: 'Jointfivteen' },
  { id: 'Jointsexteen' },
  { id: 'Jointseventeen' },
  { id: 'Jointeighteen' },
  { id: 'Jointninteen' },
  { id: 'Jointtwenty' },
  { id: 'Jointtwentyone' },
  { id: 'Jointtwentytwo' },
  { id: 'Jointtwentythree' },
  { id: 'Jointtwentyfour' },
  { id: 'Jointtwentyfive' },
  { id: 'Jointtwentysix' },
  { id: 'Jointtwentyseven' },
  { id: 'Jointtwentyeight' },
  { id: 'Jointtwentynine' },
  { id: 'Jointtwentyten' },
  { id: 'Jointtwentyten2222' },
  // company Start
  { id: 'EntityOne' },
  { id: 'EntityTwo' },
  { id: 'EntityThird' },
  { id: 'EntityThird1' },
  { id: 'EntityFour' },
  { id: 'CompanyThird' },
  { id: 'TrustFirst' },
  { id: 'TrustSecond' },
  { id: 'TrustSecond2' },
  { id: 'TrustThird' },
  { id: 'TrustFour' },
  { id: 'ThirdTrust' },
  // { id: 'CompanyFour' },

  { id: 'submit' }
];
// console.log('cccccccccccccccccc',steps)


const defaultData = {};

const MultiStepForm = ({ images }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const myfunc = () => {
    alert('jjjj')
  };
  const queryValue = sessionStorage.getItem("queryValue");
// alert(queryValue)
  let queryapi = "";
  if (queryValue != null && queryValue != undefined && queryValue != "") {
    queryapi = "?update=true"
  }
  useEffect(() => {
    $(document).ready(function () {
      //called when key is pressed in textbox
      $('.quantity').keypress(function (e) {
        //if the letter is not digit then display error and don't type anything
        if (
          (e.which >= 65 && e.which <= 90) ||
          e.which === 32 ||
          e.which === 33 ||
          e.which === 36 ||
          e.which === 38 ||
          e.which === 39 ||
          e.which === 40 ||
          e.which === 41 ||
          e.which === 45 ||
          e.which === 46 ||
          (e.which >= 97 && e.which <= 122)
        ) {
          //display error message
          return true;
        } else {
          // $("#errmsg").html("Character Only").show().fadeOut("slow");
          return false;
        }
      });
    });
    $(document).ready(function () {
      //called when key is pressed in textbox
      $('.numbers').keypress(function (e) {
        //if the letter is not digit then display error and don't type anything
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
          //display error message
          return false;
        }
      });
    });
    $('.digitsOnly').keypress(function (e) {
      var regex = new RegExp(/^[a-zA-Z\s/0-9]+$/);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });
  

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const [formData, setForm] = useForm(defaultData);

  const props = { formData, setForm, navigation };
  console.log('checkDataaaaaaaaaaaaaa',formData)

  const [loader, setLoader] = useState();

  const { go } = navigation;

  const {
    logout
  } = useAuth0();

  let certificateData = {

    isMinimumInvest: formData.isMinimumInvest === "Yes" ? true : false,
    certificateNumber:
      formData.certificateNumber !== '' &&
        formData.certificateNumber !== null &&
        formData.certificateNumber !== 'undefined'
        ? formData.certificateNumber
        : '',

    nominatedUserType: formData.rwt12 != null ? formData.rwt12 : null,
    nominatedUserName: formData.fullLegalNamesign != null ? formData.fullLegalNamesign : null,
    nominatedUserEmail: formData.userEmail1 != null ? formData.userEmail1 : null,

    usualLegalRules: formData.usualLegalRules == true ? "The usual legal rules that require information to be given to investors for offers of financial products do not apply if the amount invested upfront by me (plus any other investments I/We have already made in those financial products) is $750,000 or more; and" : "",
    notCompleteInformation: formData.notCompleteInformation == true ? "I/We may not receive a complete and balanced set of information about this investment; and" : "",
    fewerLegalProtection: formData.fewerLegalProtection == true ? "I/We have fewer legal protections for this investment; and" : "",
    notSuitableInvestment: formData.notSuitableInvestment == true ? "This investment is not suitable for retail investors; and" : "",
    seekFinancialAdvice: formData.seekFinancialAdvice == true ? "I/We have been advised to ask questions, read all documents carefully, and seek independent financial advice." : "",
    largePersonValue: formData.largePersonValue == true ? "As at the last day of each of the Investor's two most recently completed financial years, the net assets of the Investor and the entities controlled by the Investor exceed $5 million." : "",
    twoYearInvestEntity2: formData.twoYearInvestEntity2 == true ? "In each of the Investor's two most recently completed financial years, the total consolidated turnover of the Investor and the entities controlled by the Investor exceeded $5 million." : "",

    investorPrincipalBusiness: formData.investorPrincipalBusiness == true ? "The Investor’s principal business consists of one or more of the following (tick all that apply)" : null,
    investingFinancialProducts: formData.investingFinancialProducts == true ? "Investing in financial products" : null,
    actingUnderwriter: formData.actingUnderwriter == true ? "Acting as an underwriter" : null,
    financialAdviceService: formData.financialAdviceService == true ? "Providing a financial advice service" : null,
    clientMoneyPropertyService: formData.clientMoneyPropertyService == true ? "Providing a client money or property service" : null,
    tradingFinancialProducts: formData.tradingFinancialProducts == true ? "Trading in financial products on behalf of other persons." : null,
    registeredBankRBNZ: formData.registeredBankRBNZ == true ? "The Investor is a registered bank under the Reserve Bank of New Zealand Act 2010." : null,
    NBDTInvestor: formData.NBDTInvestor == true ? "The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013." : null,
    licensedIssuerInsurance: formData.licensedIssuerInsurance == true ? "The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010." : null,
    registeredSchemeManager: formData.registeredSchemeManager == true ? "The Investor is the manager of a registered scheme, or a discretionary investment management service, that holds a market services licence under the Financial Markets Conduct Act 2013." : null,
    derivativesIssuer: formData.derivativesIssuer == true ? "The Investor is a derivatives issuer that holds a market services license under the Financial Markets Conduct Act 2013." : null,
    FinancialAdvisor: formData.FinancialAdvisor == true ? "The Investor is a Financial Adviser" : null,
    ownedPortfolio2yrs: formData.ownedPortfolio2yrs == true ? "The investor owns, or at any time during the 2-year period immediately before this certificate is given has owned, a portfolio of financial products (other than membership of a retirement scheme or financial products issued by an associate) of a value of at least $1 million (in aggregate)." : null,
    carriedTransactions2yrs: formData.carriedTransactions2yrs == true ? "The investor has, during the 2-year period immediately before this certificate is given, carried out one or more transaction(s) to acquire financial products (other than membership of a retirement scheme or financial products issued by an associate) where the amount payable under those transactions (in aggregate) is at least $1 million." : null,
    investmentBusiness10yrs: formData.investmentBusiness10yrs == true ? "The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business." : null,
    equitySecurities: formData.equitySecurities == true ? "Equity securities (includes listed shares)" : null,
    managedFunds: formData.managedFunds == true ? "Managed funds" : null,
    derivativeInstruments: formData.derivativeInstruments == true ? "Derivative instruments" : null,
    debtSecurities: formData.debtSecurities == true ? "Debt securities" : null,
    startupOrBusiness: formData.startupOrBusiness == true ? "I/We previously invested in early stage, start up, or established businesses" : null,
    investorPresentation: formData.investorPresentation == true ? "In the course of it’s investment activities, I/We have been involved in investor presentations and/or due diligence meetings and through this have gained an appreciation for the risks and opportunities associated with investing in businesses." : null,
    myOwnInvestmentDecisions: formData.myOwnInvestmentDecisions == true ? "I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio." : null,
    valueAndRisksOfInvesting: formData.valueAndRisksOfInvesting == true ? "In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products." : null,

    capitalRaisingDirector: formData.capitalRaisingDirector == true ? "I/We have served as a Director of a business that was involved in capital raising" : null,
    investingSharesSecurities: formData.investingSharesSecurities == true ? "I/We have experience in investing in shares and other securities (listed and unlisted) and understand the risks with investing in different types of financial products." : null,
    acquiringDisposingFinancialProducts: formData.acquiringDisposingFinancialProducts == true ? "I/We have other relevant experience of acquiring or disposing of financial products, details being the following." : null,

    acquiringDisposingFinancialProductsOther: formData.acquiringDisposingFinancialProductsOther,
    none_certificate: formData.investmentBusinessNone,
    none_certificate_1: formData.investmentCriteriaNone,
    largePersonNone: formData.largePersonNone,

    signatureA:
      formData.signatureUrl !== null &&
        formData.signatureUrl !== '' &&
        formData.signatureUrl !== 'undefined' &&
        formData.signatureUrl !== undefined
        ? formData.signatureUrl
        : formData.signatureUrlB !== null &&
          formData.signatureUrlB !== '' &&
          formData.signatureUrlB !== 'undefined' &&
          formData.signatureUrlB !== undefined
          ? formData.signatureUrlB
          : '',

    adherenceSign:
      formData.adherenceSignUrl !== null &&
        formData.adherenceSignUrl !== '' &&
        formData.adherenceSignUrl !== 'undefined' &&
        formData.adherenceSignUrl !== undefined
        ? formData.adherenceSignUrl
        : '',
    subscriptionSign:
      formData.signatureSubscriptionUrl !== null &&
        formData.signatureSubscriptionUrl !== '' &&
        formData.signatureSubscriptionUrl !== 'undefined' &&
        formData.signatureSubscriptionUrl !== undefined
        ? formData.signatureSubscriptionUrl
        : '',
  };

  let certificateDataSecondary = {
    acquiringDisposingFinancialProductsOther: formData.acquiringDisposingFinancialProductsOther,
    isMinimumInvest: formData.isMinimumInvest === "Yes" ? true : false,
    certificateNumber:
      formData.certificateNumber !== '' &&
        formData.certificateNumber !== null &&
        formData.certificateNumber !== 'undefined'
        ? formData.certificateNumber
        : '',

    nominatedUserType: formData.rwt12 != null ? formData.rwt12 : null,
    nominatedUserName: formData.fullLegalNamesign != null ? formData.fullLegalNamesign : null,
    nominatedUserEmail: formData.userEmail1 != null ? formData.userEmail1 : null,

    usualLegalRules: formData.usualLegalRules == true ? "The usual legal rules that require information to be given to investors for offers of financial products do not apply if the amount invested upfront by me (plus any other investments I/We have already made in those financial products) is $750,000 or more; and" : "",
    notCompleteInformation: formData.notCompleteInformation == true ? "I/We may not receive a complete and balanced set of information about this investment; and" : "",
    fewerLegalProtection: formData.fewerLegalProtection == true ? "I/We have fewer legal protections for this investment; and" : "",
    notSuitableInvestment: formData.notSuitableInvestment == true ? "This investment is not suitable for retail investors; and" : "",
    seekFinancialAdvice: formData.seekFinancialAdvice == true ? "I/We have been advised to ask questions, read all documents carefully, and seek independent financial advice." : "",
    largePersonValue: formData.largePersonValue == true ? "As at the last day of each of the Investor's two most recently completed financial years, the net assets of the Investor and the entities controlled by the Investor exceed $5 million." : "",
    twoYearInvestEntity2: formData.twoYearInvestEntity2 == true ? "In each of the Investor's two most recently completed financial years, the total consolidated turnover of the Investor and the entities controlled by the Investor exceeded $5 million." : "",
    investorPrincipalBusiness: formData.investorPrincipalBusiness == true ? "The Investor’s principal business consists of one or more of the following (tick all that apply)" : null,
    investingFinancialProducts: formData.investingFinancialProducts == true ? "Investing in financial products" : null,
    actingUnderwriter: formData.actingUnderwriter == true ? "Acting as an underwriter" : null,
    financialAdviceService: formData.financialAdviceService == true ? "Providing a financial advice service" : null,
    clientMoneyPropertyService: formData.clientMoneyPropertyService == true ? "Providing a client money or property service" : null,
    tradingFinancialProducts: formData.tradingFinancialProducts == true ? "Trading in financial products on behalf of other persons." : null,
    registeredBankRBNZ: formData.registeredBankRBNZ == true ? "The Investor is a registered bank under the Reserve Bank of New Zealand Act 2010." : null,
    NBDTInvestor: formData.NBDTInvestor == true ? "The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013." : null,
    licensedIssuerInsurance: formData.licensedIssuerInsurance == true ? "The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010." : null,
    registeredSchemeManager: formData.registeredSchemeManager == true ? "The Investor is the manager of a registered scheme, or a discretionary investment management service, that holds a market services licence under the Financial Markets Conduct Act 2013." : null,
    derivativesIssuer: formData.derivativesIssuer == true ? "The Investor is a derivatives issuer that holds a market services license under the Financial Markets Conduct Act 2013." : null,
    FinancialAdvisor: formData.FinancialAdvisor == true ? "The Investor is a Financial Adviser" : null,
    ownedPortfolio2yrs: formData.ownedPortfolio2yrs == true ? "The investor owns, or at any time during the 2-year period immediately before this certificate is given has owned, a portfolio of financial products (other than membership of a retirement scheme or financial products issued by an associate) of a value of at least $1 million (in aggregate)." : null,
    carriedTransactions2yrs: formData.carriedTransactions2yrs == true ? "The investor has, during the 2-year period immediately before this certificate is given, carried out one or more transaction(s) to acquire financial products (other than membership of a retirement scheme or financial products issued by an associate) where the amount payable under those transactions (in aggregate) is at least $1 million." : null,
    investmentBusiness10yrs: formData.investmentBusiness10yrs == true ? "The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business." : null,
    equitySecurities: formData.equitySecurities == true ? "Equity securities (includes listed shares)" : null,
    managedFunds: formData.managedFunds == true ? "Managed funds" : null,
    derivativeInstruments: formData.derivativeInstruments == true ? "Derivative instruments" : null,
    debtSecurities: formData.debtSecurities == true ? "Debt securities" : null,
    startupOrBusiness: formData.startupOrBusiness == true ? "I/We previously invested in early stage, start up, or established businesses" : null,
    investorPresentation: formData.investorPresentation == true ? "In the course of it’s investment activities, I/We have been involved in investor presentations and/or due diligence meetings and through this have gained an appreciation for the risks and opportunities associated with investing in businesses." : null,
    myOwnInvestmentDecisions: formData.myOwnInvestmentDecisions == true ? "I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio." : null,
    valueAndRisksOfInvesting: formData.valueAndRisksOfInvesting == true ? "In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products." : null,
    capitalRaisingDirector: formData.capitalRaisingDirector == true ? "I/We have served as a Director of a business that was involved in capital raising" : null,
    investingSharesSecurities: formData.investingSharesSecurities == true ? "I/We have experience in investing in shares and other securities (listed and unlisted) and understand the risks with investing in different types of financial products." : null,
    acquiringDisposingFinancialProductsOther: formData.acquiringDisposingFinancialProductsOther,
    acquiringDisposingFinancialProducts: formData.acquiringDisposingFinancialProducts == true ? "I/We have other relevant experience of acquiring or disposing of financial products, details being the following." : null,
    none_certificate: formData.investmentBusinessNone,
    none_certificate_1: formData.investmentCriteriaNone,
    largePersonNone: formData.largePersonNone,

    adherenceSign:
      formData.mySignaturejointHolderDetailsUrl !== null &&
        formData.mySignaturejointHolderDetailsUrl !== '' &&
        formData.mySignaturejointHolderDetailsUrl !== 'undefined' &&
        formData.mySignaturejointHolderDetailsUrl !== undefined
        ? formData.mySignaturejointHolderDetailsUrl
        : '',
    subscriptionSign:
      formData.signatureSubscriptionUrljoint !== null &&
        formData.signatureSubscriptionUrljoint !== '' &&
        formData.signatureSubscriptionUrljoint !== 'undefined' &&
        formData.signatureSubscriptionUrljoint !== undefined
        ? formData.signatureSubscriptionUrljoint
        : '',

    signatureA:
      formData.secSignatureCUrl !== null &&
        formData.secSignatureCUrl !== '' &&
        formData.secSignatureCUrl !== 'undefined' &&
        formData.secSignatureCUrl !== undefined
        ? formData.secSignatureCUrl
        : formData.signatureA1Url !== null &&
          formData.signatureA1Url !== '' &&
          formData.signatureA1Url !== 'undefined' &&
          formData.signatureA1Url !== undefined
          ? formData.signatureA1Url
          : '',
  };

  const newDeatils = formData.certificateDetails || {};

  for (const [key, value] of Object.entries(newDeatils)) {
    certificateData[key] = value;
    certificateDataSecondary[key] = value;
  }

  const application2 = async () => {
    applicat("save", "Progress")
  };

  const application4 = async () => {
    applicat("saveLogout", "Progress")
  };
  const application3 = async () => {
    applicat("post", "Submit")

  };

  const applicat = async (value, status, loder) => {
    let IndividualExpiryDate = sessionStorage.getItem('documentExpiryDates');
    let JointExpiryDate = sessionStorage.getItem('documentExpiryDates1');
    let IndividualDOB = sessionStorage.getItem('dateOfBirth');
    let jointDOB = sessionStorage.getItem('dateOfBirth2');
    let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
    let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
    let residentialAddress2 = sessionStorage.getItem('residentialAddress2');
    let PostalAddress2 = sessionStorage.getItem('PostalAddress2');
    let city = sessionStorage.getItem('locality');
    let postal_code = sessionStorage.getItem('postal_code');
    let country = sessionStorage.getItem('country');
    let region = sessionStorage.getItem('sublocality_level_1');
    let route = sessionStorage.getItem('route');
    let street_number = sessionStorage.getItem('street_number');

    let city2 = sessionStorage.getItem('locality1');
    let postal_code2 = sessionStorage.getItem('postal_code1');
    let country2 = sessionStorage.getItem('country1');
    let region2 = sessionStorage.getItem('sublocality_level_11');
    let route2 = sessionStorage.getItem('route1');
    let street_number2 = sessionStorage.getItem('street_number1');


    function checkData(value) {
      if (value !== null && value !== "" && value !== 'undefined') {
        return value;
      } else {
        return ""
      }
    }

    let adress;
    if (formData.investmentType === 'Joint') {
      adress = {
        suburb:
          formData.region != null && formData.region != '' && formData.region != 'undefined'
            ? formData.region
            : region2,
        addressLine1:
          (formData.street_number != null &&
            formData.street_number != 'undefined' &&
            formData.street_number != ''
            ? formData.street_number
            : street_number2 != null && street_number2 != 'undefined'
              ? street_number2
              : '') +
          ' ' +
          (route2 == null ? '' : route2),
        postalCode:
          formData.postalCode != null &&
            formData.postalCode != 'undefined' &&
            formData.postalCode != ''
            ? formData.postalCode
            : postal_code2 != null && postal_code2 != 'undefined' && postal_code2 != ''
              ? postal_code2
              : '',
        city:
          formData.city != null && formData.city != 'undefined' && formData.city != ''
            ? formData.city
            : city2,
        countryCode:
          formData.countryyCode != null &&
            formData.countryyCode != 'undefined' &&
            formData.countryyCode != ''
            ? formData.countryyCode
            : 'NZ',
        country:
          formData.country != null && formData.country != 'undefined' && formData.country != ''
            ? formData.country
            : country2,
        // isPrimary: true,
        // isActive: true
      }
    }
    else {
      adress = {
        suburb:
          formData.region != null && formData.region != '' && formData.region != 'undefined'
            ? formData.region
            : region,
        addressLine1:
          (formData.street_number != null &&
            formData.street_number != 'undefined' &&
            formData.street_number != ''
            ? formData.street_number
            : street_number != null && street_number != 'undefined'
              ? street_number
              : '') +
          ' ' +
          (route == null ? '' : route),
        postalCode:
          formData.postalCode != null &&
            formData.postalCode != 'undefined' &&
            formData.postalCode != ''
            ? formData.postalCode
            : postal_code != null && postal_code != 'undefined' && postal_code != ''
              ? postal_code
              : '',
        city:
          formData.city != null && formData.city != 'undefined' && formData.city != ''
            ? formData.city
            : city,
        countryCode:
          formData.countryyCode != null &&
            formData.countryyCode != 'undefined' &&
            formData.countryyCode != ''
            ? formData.countryyCode
            : 'NZ',
        country:
          formData.country != null && formData.country != 'undefined' && formData.country != ''
            ? formData.country
            : country,
        // isPrimary: true,
        // isActive: true
      }
    }

    let postdata = {
      companyDetails: {
        // companyName: formData.optionsd !== null && formData.optionsd !== "" && formData.optionsd !== 'undefined' ? formData.optionsd : null,
        companyName: checkData(formData.companyName),
        companyNumber: checkData(formData.companyNumber),
        companyTradingName: checkData(formData.companyTradingName),
        companyRegisterAddress: checkData(formData.companyRegisterAddress),
        companyAddressProof: checkData(formData.companyAddressProof),

        fullLegalCompanyName: checkData(formData.fullLegalCompanyName),
        otherCountryCompanyRegistered: checkData(formData.otherCountryCompanyRegistered),
        secFullLegalCompanyName: checkData(formData.secFullLegalCompanyName),
        companySecCountryOfResidence: checkData(formData.companySecCountryOfResidence),
        isotherCompanyRegister: formData.isotherCompanyRegister != null &&
          formData.isotherCompanyRegister != 'undefined' &&
          formData.isotherCompanyRegister != ''
          ? formData.isotherCompanyRegister == 'true'
            ? true
            : false
          : false,

        entityCompanyOne:
          formData.entityCompanyOne != null &&
            formData.entityCompanyOne != 'undefined' &&
            formData.entityCompanyOne != ''
            ? formData.entityCompanyOne == 'true'
              ? true
              : false
            : false,

        // entityCompanyOne:formData.entityCompanyOne==true?"true":false,
        isHoldingPersonalAssets: formData.isHoldingPersonalAssets != null &&
          formData.isHoldingPersonalAssets != 'undefined' &&
          formData.isHoldingPersonalAssets != ''
          ? formData.isHoldingPersonalAssets == 'true'
            ? true
            : false
          : false,

        isCompanyIndividualOperating: formData.isCompanyIndividualOperating != null &&
          formData.isCompanyIndividualOperating != 'undefined' &&
          formData.isCompanyIndividualOperating != ''
          ? formData.isCompanyIndividualOperating == 'true'
            ? true
            : false
          : false,
        isOtherCompanyIndividual: formData.isOtherCompanyIndividual != null &&
          formData.isOtherCompanyIndividual != 'undefined' &&
          formData.isOtherCompanyIndividual != ''
          ? formData.isOtherCompanyIndividual == 'true'
            ? true
            : false
          : false,

        individualFullName: checkData(formData.individualFullName),
        IndividualRole: checkData(formData.IndividualRole),
        IndividualEmail: checkData(formData.IndividualEmail),
        secondaryIndividualFullName: checkData(formData.secondaryIndividualFullName),
        secondaryIndividualRole: checkData(formData.secondaryIndividualRole),
        SecondaryIndividualEmail: checkData(formData.SecondaryIndividualEmail),
        companyIrdNumber: checkData(formData.companyIrdNumber),

        // companyNrwt: formData.rwtComp === 'Non Resident Withholding Tax' ? true : false,
        // companyAIL: formData.rwtComp === 'AIL 2%' ? true : false,
        // companyRwt: formData.rwtComp === 'Resident Withholding Tax' ? true : false,

        companyTaxType: formData.rwtComp,
        companyRwtValue: formData.rwtComp === 'Resident Withholding Tax' ? formData.rwtValueComp : null,
        companyExamptProof: checkData(formData.companyExamptProof),
        companyTaxExemption: formData.checkboxComp === true ? true : false,

        companyCountryOfResidence: checkData(formData.companyCountryOfResidence),
        companyTinNumber: checkData(formData.companyTinNumber),
        companyTinReason: checkData(formData.companyTinReason),
        isCompanyOhterCountryCitizen:
          formData.isCompanyOhterCountryCitizen != null &&
            formData.isCompanyOhterCountryCitizen != 'undefined' &&
            formData.isCompanyOhterCountryCitizen != ''
            ? formData.isCompanyOhterCountryCitizen == 'true'
              ? true
              : false
            : false,

        companyFundReason: checkData(formData.companyFundReason),
        companyGenerateIncome: checkData(formData.companyGenerateIncome),
        companyFundsDocument: checkData(formData.companyFundsDocument),

        companyShareholderDetails: formData.inputList !== null && formData.inputList !== "" && formData.inputList !== undefined ? formData.inputList : [],
        companyDirectorDetails: formData.inputData !== null && formData.inputData !== "" && formData.inputData !== undefined ? formData.inputData : [],
      },

      trustDetails: {
        trustName: checkData(formData.trustName),
        trustcountryOfIncorporation: checkData(formData.trustcountryOfIncorporation),
        trustAddressProof: checkData(formData.trustAddressProof),
        // trustRegisterAddress: checkData(formData.trustRegisterAddress),
        trustRegisterAddress: formData.manuallyAddressTrust == 'false' ?
          (formData.street_number1 +
            (formData.region1 != null && formData.region1 != undefined && formData.region1 != '' ? ', ' + formData.region1 : '') +
            (formData.city1 != null && formData.city1 != '' ? ', ' + formData.city1 : '') +
            (formData.postalCode1 != null && formData.postalCode1 != ''
              ? ', ' + formData.postalCode1
              : '') +
            (formData.country != null && formData.country != '' ? ',' + formData.country : '')) :
          formData.trustRegisterAddress,
        trustType: checkData(formData.trust_type),
        trustTypeOther: checkData(formData.trust_type_other),
        // trustDeedProof: checkData(formData.trustDeedProof),
        trustIrdNumber: checkData(formData.TrustIrdNumber),
        isTrustCitizen: formData.isTrustOhterCountryCitizen != null &&
          formData.isTrustOhterCountryCitizen != 'undefined' &&
          formData.isTrustOhterCountryCitizen != '' ?
          formData.isTrustOhterCountryCitizen == 'true' ? true : false : false,
        trustIrdNumberProof: checkData(formData.trustIrdNumberProof),

        // trustIrdNumberProof: "",

        // trustNrwt: formData.rwtTrust === 'Non Resident Withholding Tax' ? true : false,
        // trustAIL: formData.rwtTrust === 'AIL 2%' ? true : false,
        // trustRwt: formData.rwtTrust === 'Resident Withholding Tax' ? true : false,

        trustTaxType: formData.rwtTrust,
        trustRwtValue: formData.rwtTrust === 'Resident Withholding Tax' ? formData.rwtValueTrust : null,
        trustExamptProof: checkData(formData.trustExamptProof),
        trustTaxExemption: formData.checkboxTrust === true ? true : false,

        trustCountryOfResidence: formData.trustCountryOfResidence,
        trustTinNumber: formData.trustTinNumber != null && formData.trustTinNumber != "" && formData.trustTinNumber != "undefined" ? formData.trustTinNumber : null,
        trustTinReason: formData.trustTinReason != null && formData.trustTinReason != "" && formData.trustTinReason != "undefined" ? formData.trustTinReason : null,

        trustFundReason: checkData(formData.trustFundReason),
        trustsettlorFundsGensettlorFundsGen: checkData(formData.trustsettlorFund),
        trustSourceOfIncome: checkData(formData.trustGenerateIncome),
        trustSourceOfFundsProof: checkData(formData.trustSourceOfFundsProof),
        trustIndividualDetails: formData.inputDataList !== null && formData.inputDataList !== "" && formData.inputDataList !== undefined ? formData.inputDataList : [],
        beneficiariesDetails: formData.inputBenefisList !== null && formData.inputBenefisList !== "" && formData.inputBenefisList !== undefined ? formData.inputBenefisList : [],
        trustCompanyName: checkData(formData.optionsd),
        trustDirectorDetails: formData.directorRoles !== null && formData.directorRoles !== "" && formData.directorRoles !== undefined ? formData.directorRoles : [],

        // optionsd: formData.optionsd != null && formData.optionsd != "" && formData.optionsd != "undefined" ? formData.optionsd : null,

        // userEmail: formData.userEmail !== null && formData.userEmail !== "" && formData.userEmail !== undefined && formData.userEmail !== 'undefined' ? formData.userEmail : null,
        // fullLegalName: formData.fullLegalName  !== null && formData.fullLegalName  !== "" && formData.fullLegalName  !== undefined && formData.fullLegalName  !== 'undefined' ? formData.fullLegalName : null,
        // isCompanyDirectorYou: formData.inputList !== null && formData.inputList !== "" && formData.inputList !== undefined && formData.inputList !== 'undefined' ? formData.inputList : null,

      },



      secondaryDetails: {
        applicationStage: formData.applicationStage,
        investmentType:
          formData.investmentType != null && formData.investmentType != 'Select'
            ? formData.investmentType
            : '',
        occupation: checkData(formData.occupation),
        fundCode: checkData(formData.fundCode),
        totalInvestment: checkData(formData.totalInvestment),

        primaryShareOfIncome: formData.shareholdingPercentage != undefined ? 100 - formData.shareholdingPercentage : 50,

        fullLegalName:
          formData.secondholder != null &&
            formData.secondholder != '' &&
            formData.secondholder != 'undefined'
            ? formData.secondholder
            : '',
        preferredName: checkData(formData.preferredName2),
        dateOfBirth: checkData(jointDOB),
        userResidentialAddress:
          formData.manuallyAddress2 == 'false'
            ? (formData.street_number1 +
              (formData.region1 != '' && formData.region1 != null && formData.region1 != undefined ? ', ' + formData.region1 : '') +
              (formData.city1 != '' ? ', ' + formData.city1 : '') +
              (formData.postalCode1 != null ? ', ' + formData.postalCode1 : '') +
              (formData.country1 != '' ? ', ' + formData.country1 : ''))
            : formData.isSecondryResdAddress,
        isUserAddressSame:
          formData.isUserAddressSame2 === 'true' ||
            formData.isUserAddressSame2 === '' ||
            formData.isUserAddressSame2 === 'undefined' ||
            formData.isUserAddressSame2 === undefined
            ? 1
            : 0,
        userPostalAddress: formData.isSecondryPostalAdre != null && formData.isSecondryPostalAdre != '' ? formData.isSecondryPostalAdre : null,

        isGoogleAddress:
          formData.manuallyAddress2 != null &&
            formData.manuallyAddress2 != 'undefined' &&
            formData.manuallyAddress2 != ''
            ? formData.manuallyAddress2 == 'true' || formData.manuallyAddress2 == true
              ? true
              : formData.manuallyAddress2 == 'false' || formData.manuallyAddress2 == false
                ? false
                : true
            : true,
        priContactType:
          formData.priContactType2 === 'Mobile' ||
            formData.priContactType2 === '' ||
            formData.priContactType2 === 'undefined' ||
            formData.priContactType2 === undefined ||
            formData.priContactType2 === null
            ? 'Mobile'
            : formData.priContactType2,

        priContactCountryCode:
          formData.priContactCountryCode2 === undefined ||
            formData.priContactCountryCode2 === 'undefined' ||
            formData.priContactCountryCode2 === null
            ? '64'
            : formData.priContactCountryCode2,
        priContactNumber: formData.priContactNumber2,
        isAlternateContact: formData.isAlternateContact1,

        secContactType:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactType2 !== 'undefined' &&
              formData.secContactType2 !== '' &&
              formData.secContactType2 !== null
              ? formData.secContactType2
              : 'Home',

        secContactCountryCode:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactCountryCode2 === undefined ||
              formData.secContactCountryCode2 === null ||
              formData.secContactCountryCode2 === 'undefined'
              ? '64'
              : formData.secContactCountryCode2,
        secContactNumber: formData.isAlternateContact1 == false ? null : formData.secContactNumber2,

        occupation: checkData(formData.occupation2),
        otherOccupation: checkData(formData.otherOccupation2),
        placeOfBirth: checkData(formData.placeOfBirth2),
        countryOfBirth: checkData(formData.countryOfBirth2),
        residingCountry: checkData(formData.residingCountry2),
        userIrdNumber: checkData(formData.userIrdNumber2),
        userIrdNumberProof: checkData(formData.userIrdNumberProof2),

        taxType: formData.rwt2,
        rwtValue: formData.rwt2 === 'Resident Withholding Tax' ? formData.rwtValue2 : null,
        taxExemption: formData.checkboxxjoint2 === true ? true : false,
        examptProof: checkData(formData.examptProof2),

        captureReference: checkData(formData.Capture2),

        addressDetails: {
          suburb:
            formData.region3 != null && formData.region3 != '' && formData.region3 != 'undefined'
              ? formData.region3
              : region,
          addressLine1:
            (formData.street_number3 != null &&
              formData.street_number3 != 'undefined' &&
              formData.street_number3 != ''
              ? formData.street_number3
              : street_number != null && street_number != 'undefined'
                ? street_number
                : '') +
            ' ' +
            (route == null ? '' : route),
          postalCode:
            formData.postalCode3 != null &&
              formData.postalCode3 != 'undefined' &&
              formData.postalCode3 != ''
              ? formData.postalCode3
              : postal_code != null && postal_code != 'undefined' && postal_code != ''
                ? postal_code
                : '',
          city:
            formData.city3 != null && formData.city3 != 'undefined' && formData.city3 != ''
              ? formData.city3
              : city,
          countryCode:
            formData.countryyCode3 != null &&
              formData.countryyCode3 != 'undefined' &&
              formData.countryyCode3 != ''
              ? formData.countryyCode3
              : 'NZ',
          country:
            formData.country3 != null && formData.country3 != 'undefined' && formData.country3 != ''
              ? formData.country3
              : country,
          // isPrimary: true,
          // isActive: true
        },
        isOtherCitizen:
          formData.isPostalAdd12 === 'true' ||
            formData.isPostalAdd12 === '' ||
            formData.isPostalAdd12 === 'undefined' ||
            formData.isPostalAdd12 === undefined
            ? 0
            : 1,
        // isOtherCitizen: formData.isPostalAdd12 === 'false' ? 1 : 0,

        tinDetails: [
          {

            countryOfResidence: checkData(formData.tinResidential2),
            tinNumber: checkData(formData.tinNumber2),
            tinReason: checkData(formData.tinReason2),

          }
        ],
        captureReference: checkData(formData.Capture2),
        identificationType: checkData(formData.biometric2),
        documentIdType:
          formData.biometric2 == 'Biometric'
            ? ''
            : formData.idType2 === '1' ||
              formData.idType2 === '' ||
              formData.idType2 === 'undefined' ||
              formData.idType2 === undefined ||
              formData.idType2 === null
              ? 'NZ Driver Licence'
              : formData.idType2 === '2'
                ? 'NZ Passport'
                : formData.idType2 === '3'
                  ? 'other'
                  : null,
        firstName:
          formData.firstName12 != null &&
            formData.firstName12 != '' &&
            formData.firstName12 != 'undefined'
            ? formData.firstName12
            : null,
        middleName:
          formData.middleName12 != null &&
            formData.middleName12 != '' &&
            formData.middleName12 != 'undefined'
            ? formData.middleName12
            : null,
        lastName:
          formData.lastName12 != null &&
            formData.lastName12 != '' &&
            formData.lastName12 != 'undefined'
            ? formData.lastName12
            : null,
        documentNumber: formData.biometric2 == 'Biometric' ? null : formData.documentNumber2,
        documentExpiryDate:
          formData.biometric2 == 'Biometric'
            ? null
            : JointExpiryDate != null && JointExpiryDate != ''
              ? JointExpiryDate
              : null,
        documentVersionNumber:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentVersionNumber2 != null
              ? formData.documentVersionNumber2
              : null,
        documentCountryOfIssue:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentCountryOfIssue2 != null
              ? formData.documentCountryOfIssue2
              : null,
        otherDocumentName:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.otherDocumentName2 != null
              ? formData.otherDocumentName2
              : null,
        documentProof:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentProof2 != null
              ? formData.documentProof2
              : null,
        isNZBank:
          formData.isNZBank2 === 'true'
            ? true
            : formData.isNZBank2 === undefined
              ? null
              : formData.isNZBank12 === 'false' && formData.isNZBank12 === 'false'
                ? false
                : true,
        // isNZBank: ,
        isPrimaryBank:
          formData.isNZBank12 === undefined ||
            formData.isNZBank12 === null ||
            formData.isNZBank12 === 'true'
            ? true
            : false,
        primaryBankAccountName: checkData(formData.primaryBankAccountName2),
        primaryBankName: checkData(formData.primaryBankAccountName2),
        primaryBankAccountNumber: checkData(formData.primaryBankAccountNumber2),

        primaryBankProof: checkData(formData.primaryBankProof2),

        bankSwiftCode:
          formData.isNZBank2 === 'undefined' || formData.isNZBank2 === 'true'
            ? ''
            : formData.bankSwiftCode2 != null
              ? formData.bankSwiftCode2
              : null,
        entityName: `${formData.fullName} & ${formData.secondholder}`,

        userEmail:
          formData.userEmail === formData.email1
            ? formData.userCode
            : formData.email1 != null && formData.email1 != '' && formData.email1 != 'undefined'
              ? formData.email1
              : formData.userCode,

        certificateDetails: certificateDataSecondary
      },

      // step: '',
      applicationStage: formData.applicationStage,
      primaryShareOfIncome: formData.shareholdingPercentage,
      investmentType:
        formData.investmentType != null && formData.investmentType != 'Select'
          ? formData.investmentType
          : null,
      occupation: checkData(formData.occupation),
      fundCode: checkData(formData.fundCode),
      totalInvestment: checkData(formData.totalInvestment),

      fullLegalName:
        formData.fullName != null && formData.fullName != '' && formData.fullName != 'undefined'
          ? formData.fullName
          : (formData.firstNameindi != null &&
            formData.firstNameindi != '' &&
            formData.firstNameindi != 'undefined'
            ? formData.firstNameindi
            : formData.firstName != null &&
              formData.firstName != '' &&
              formData.firstName != 'undefined'
              ? formData.firstName
              : '') +
          ' ' +
          (formData.middleNameindi != null &&
            formData.middleNameindi != '' &&
            formData.middleNameindi != 'undefined'
            ? formData.middleNameindi
            : formData.middleName != null &&
              formData.middleName != '' &&
              formData.middleName != 'undefined'
              ? formData.middleName
              : '') +
          '' +
          (formData.lastNameindi != null &&
            formData.lastNameindi != '' &&
            formData.lastNameindi != 'undefined'
            ? ' ' + formData.lastNameindi
            : formData.lastName != null &&
              formData.lastName != '' &&
              formData.lastName != 'undefined'
              ? ' ' + formData.lastName
              : ''),
      preferredName: checkData(formData.preferredName),
      dateOfBirth: checkData(IndividualDOB),
      isPrimaryData: true,
      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,



      userResidentialAddress: formData.manuallyAddress == 'false' ?
        (formData.street_number +
          (formData.region != null && formData.region != undefined && formData.region != '' ? ', ' + formData.region : '') +
          (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
          (formData.postalCode != null && formData.postalCode != ''
            ? ', ' + formData.postalCode
            : '') +
          (formData.country != null && formData.country != '' ? ',' + formData.country : '')) :
        formData.isPrimaryResdAddress,



      isUserAddressSame:
        formData.isUserAddressSame === 'true' ||
          formData.isUserAddressSame === '' ||
          formData.isUserAddressSame === 'undefined' ||
          formData.isUserAddressSame === undefined
          ? 1
          : 0,
      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,

      userPostalAddress: formData.isPrimaryPostalAdress != null && formData.isPrimaryPostalAdress != '' ? formData.isPrimaryPostalAdress : null,

      //  PostalAddress1 != null && PostalAddress1 != '' ? PostalAddress1 : null,
      priContactType:
        formData.priContactType === 'Mobile' ||
          formData.priContactType === '' ||
          formData.priContactType === 'undefined' ||
          formData.priContactType === undefined ||
          formData.priContactType === null
          ? 'Mobile'
          : formData.priContactType,

      priContactCountryCode:
        formData.priContactCountryCode === undefined ||
          formData.priContactCountryCode === 'undefined' ||
          formData.priContactCountryCode === null
          ? '64'
          : formData.priContactCountryCode,
      priContactNumber:
        formData.codeVerify === 'true' ? formData.priContactNumber : null,
      isPrimaryBank: true,
      isPriContactNumberVerified: formData.codeVerify === 'true' ? 1 : 0,
      isAlternateContact: formData.isAlternateContact,

      secContactType:
        formData.isAlternateContact == false
          ? null
          : formData.secContactType !== 'undefined' &&
            formData.secContactType !== null &&
            formData.secContactType !== ''
            ? formData.secContactType
            : 'Home',

      secContactCountryCode:
        formData.isAlternateContact == false
          ? null
          : formData.secContactCountryCode === undefined ||
            formData.secContactCountryCode === null ||
            formData.secContactCountryCode === 'undefined'
            ? '64'
            : formData.secContactCountryCode,
      secContactNumber: formData.isAlternateContact == false ? null : formData.secContactNumber,
      occupation: formData.occupation != null ? formData.occupation : null,
      otherOccupation: formData.otherOccupation != null ? formData.otherOccupation : null,
      placeOfBirth: formData.placeOfBirth != null ? formData.placeOfBirth : null,
      countryOfBirth: formData.countryOfBirth != null ? formData.countryOfBirth : null,
      residingCountry: formData.residingCountry != null ? formData.residingCountry : null,
      userIrdNumber: formData.userIrdNumber != null ? formData.userIrdNumber : null,
      nominatedUserType: formData.rwt12 != null ? formData.rwt12 : null,
      nominatedUserName: formData.fullLegalNamesign != null ? formData.fullLegalNamesign : null,
      nominatedUserEmail: formData.userEmail1 != null ? formData.userEmail1 : null,
      userIrdNumberProof: checkData(formData.userIrdNumberProof),


      taxType: formData.rwt,
      rwtValue: formData.rwt === 'Resident Withholding Tax' ? formData.rwtValue : null,
      taxExemption: formData.checkboxx === true ? true : false,
      examptProof: checkData(formData.examptProof),
      // examptProof:
      //   formData.examptProof !== null &&
      //     formData.examptProof !== '' &&
      //     formData.examptProof !== 'undefined'
      //     ? formData.examptProof
      //     : null,

      // userIrdNumberProof: null,
      // nrwt: formData.rwt === 'Non Resident Withholding Tax' ? true : false,
      // AIL: formData.rwt === 'AIL 2%' ? true : false,
      // rwt: formData.rwt === 'Resident Withholding Tax' ? true : false,



      addressDetails: adress,
      // isOtherCitizen: formData.isPostalAdd1 === 'false' ? 1 : 0,
      isOtherCitizen:
        formData.isPostalAdd11 === 'true' ||
          formData.isPostalAdd11 === '' ||
          formData.isPostalAdd11 === 'undefined' ||
          formData.isPostalAdd11 === undefined
          ? 0
          : 1,

      tinDetails: [
        {
          countryOfResidence: checkData(formData.tinResidential),
          tinNumber: checkData(formData.tinNumber),
          tinreason: checkData(formData.tinreason),
        }
      ],
      captureReference: checkData(formData.Capture),
      identificationType: checkData(formData.biometric),
      documentIdType:
        formData.biometric == 'Biometric'
          ? ''
          : formData.idType === '1' ||
            formData.idType === '' ||
            formData.idType === 'undefined' ||
            formData.idType === undefined ||
            formData.idType === null
            ? 'NZ Driver Licence'
            : formData.idType === '2'
              ? 'NZ Passport'
              : formData.idType === '3'
                ? 'other'
                : null,
      firstName:
        formData.firstName != null && formData.firstName != '' && formData.firstName != 'undefined'
          ? formData.firstName
          : formData.firstNameindi != '' &&
            formData.firstNameindi != null &&
            formData.firstNameindi != 'undefined'
            ? formData.firstNameindi
            : null,
      middleName:
        formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName
            : '',
      lastName:
        formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined'
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined'
            ? formData.lastName
            : '',
      documentProof:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentProof != null
            ? formData.documentProof
            : null,
      documentNumber:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentNumber != null
            ? formData.documentNumber
            : null,
      documentExpiryDate:
        formData.biometric == 'Biometric'
          ? null
          : IndividualExpiryDate != null && IndividualExpiryDate != ''
            ? IndividualExpiryDate
            : null,
      documentVersionNumber:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentVersionNumber != null
            ? formData.documentVersionNumber
            : null,
      documentCountryOfIssue:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentCountryOfIssue != null
            ? formData.documentCountryOfIssue
            : null,
      otherDocumentName:
        formData.biometric == 'Biometric'
          ? null
          : formData.otherDocumentName != null
            ? formData.otherDocumentName
            : null,
      primaryBankAccountName: checkData(formData.primaryBankAccountName),
      primaryBankName: checkData(formData.primaryBankAccountName),
      primaryBankAccountNumber: checkData(formData.primaryBankAccountNumber),
      primaryBankProof: checkData(formData.primaryBankProof),
      bankSwiftCode:
        formData.isNZBank === 'undefined' || formData.isNZBank === 'true'
          ? null
          : formData.bankSwiftCode != null
            ? formData.bankSwiftCode
            : null,
      entityName: `${formData.fullName}`,

      isPrimaryBank:
        formData.isNZBank12 === undefined ||
          formData.isNZBank12 === null ||
          formData.isNZBank12 === 'true'
          ? true
          : false,
      isNZBank: formData.isNZBank === 'undefined' || formData.isNZBank === 'true' ? true : false,
      certificateDetails: certificateData,


      // company: company
    };
    const queryValue = sessionStorage.getItem("queryValue");

    let endPoint;

    if (formData.investmentType === 'Joint') {
      delete postdata.companyDetails;
      delete postdata.trustDetails;
      endPoint = "/onboard/joint"

    } else if (formData.investmentType === 'Individual') {
      delete postdata.secondaryDetails;
      delete postdata.companyDetails;
      delete postdata.trustDetails;
      endPoint = "/onboard/individual"

    } else if (formData.investmentType === 'Company') {
      delete postdata.secondaryDetails;
      delete postdata.trustDetails;
      endPoint = "/onboard/company"

    } else if (formData.investmentType === 'Trust') {
      delete postdata.secondaryDetails;
      delete postdata.companyDetails;
      endPoint = "/onboard/trust"

    }

    if (value == "save" && status === "Progress") {

      if (queryValue == null || queryValue == "null" || queryValue == "undefined" || queryValue == undefined || queryValue == "") {
        formData.applicationStage = 'In Progress';
        // setLoader(true);
        {

          loder == 'loder' ? formData.miniloader = true : setLoader(true);
        }
        await axios
          .put(`${multisavedata}${endPoint}`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (queryValue != null && queryValue != undefined && queryValue != "") {
              sessionStorage.setItem("queryValue", null);
              sessionStorage.setItem("queryCheck", null);
            }

            if (res.status === 200) {
              if (formData.bioVerify == true) {
                {
                  loder == 'loder' ? formData.miniloader = false :
                    Swal.fire({
                      html: '<p class="sweettt">Your application data has been saved. A new internet browser window should open and take you through the biometrics process (if the new window has not opened, the pop up may have been blocked, in which case you will need to allow the pop up to open).</p><p class="sweettt">Once you have completed the biometrics process, please come back to this page to resume your application.</p>',
                      confirmButtonColor: '#231f20',
                      allowOutsideClick: false
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                      }
                    });
                }

                setLoader(false);
                formData.miniloader = false;
                formData.bioVerify = '';
              } else {
                {
                  loder == 'loder' ? formData.miniloader = false :
                    Swal.fire({
                      html: 'Your application data has been saved, you can now logout and resume your application when you are ready.',
                      confirmButtonColor: '#231f20',
                      allowOutsideClick: false
                    });
                }

                setLoader(false);
                formData.miniloader = false;

              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
              formData.miniloader = false;

            }
          })
          .catch(() => {
            if (queryValue != null && queryValue != undefined && queryValue != "") {
              sessionStorage.setItem("queryValue", null);
              sessionStorage.setItem("queryCheck", null);
            }
            setLoader(false);
            formData.miniloader = false;
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      }

    } else if (value == "post" && status === "Submit") {
      formData.applicationStage = 'Submitted';
      Swal.fire({
        html: 'Please wait a moment while we submit your application. Don’t close this window or click out of it.',
        didOpen: () => {
          Swal.showLoading();
        }
      });
      await axios
        .post(`${multisavedata}${endPoint}${queryapi}`, postdata, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          if (queryValue != null && queryValue != undefined && queryValue != "") {
            sessionStorage.setItem("queryValue", null);
            sessionStorage.setItem("queryCheck", null);
          }
          if (res.status === 200) {
            go('Twentytwo');
            setLoader(false);
            Swal.close();
          } else if (res.status === 202) {
            Swal.fire({
              html: `${res.data.message}`
            });
            setLoader(false);
          } else {
            Swal.close();

            setLoader(false);
          }
        })
        .catch(() => {
          if (queryValue != null && queryValue != undefined && queryValue != "") {
            sessionStorage.setItem("queryValue", null);
            sessionStorage.setItem("queryCheck", null);
          }
          Swal.fire({
            // icon: 'error',
            // title: 'Oops...',
            html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
            allowOutsideClick: false
          });
          setLoader(false);
        });
    }

  };

  const savedata = (value) => {
    formData.investmentType = value;
    applicat("save", "Progress", 'loder')
  }
  switch (id) {
    case 'Zero':
      return <Zero functioss="kkkkkks" {...props} />;
    case 'Second':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Second func={application2} func1={application4} fun2={savedata} {...props} />
      );
    case 'Third':
      if (formData.investmentType == 'Joint') {
        return loader ? (
          <div className="main-loader">
            <div className="loader"></div>
          </div>
        ) : (
          <JoinThird func={application2} {...props} />
        );
      } else if (formData.investmentType == 'Individual') {
        return loader ? (
          <div className="main-loader">
            <div className="loader"></div>
          </div>
        ) : (
          <Third func={application2} {...props} />
        );
      }

    case 'Four':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Four func={application2} {...props} />
      );
    case 'Five':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Five func={application2} {...props} />
      );
    case 'Six':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Six func={application2} {...props} />
      );
    case 'Seven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Seven func={application2} {...props} />
      );
    case 'Eight':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Eight func={application2} {...props} />
      );
    case 'Nine':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Nine func={application2} {...props} />
      );
    case 'Ten':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Ten func={application2} {...props} />
      );
    case 'Twelve':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twelve func={application2} {...props} />
      );
    case 'Thirteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Thirteen func={application2} {...props} />
      );
    // case "Fourteen":
    // return <Fourteen {...props} />;
    case 'Fifteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Fifteen func={application2} {...props} />
      );

    case 'EntityOne':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <EntityOne func={application2} {...props} />
      );

    case 'EntityTwo':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <EntityTwo func={application2} {...props} />
      );
    case 'EntityThird':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <EntityThird func={application2} {...props} />
      );
    case 'EntityThird1':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <EntityThird1 func={application2} {...props} />
      );


    case 'EntityFour':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <EntityFour func={application2} {...props} />
      );
    case 'CompanyThird':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <CompanyThird func={application2} {...props} />
      );
    case 'TrustFirst':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <TrustFirst func={application2} {...props} />
      );
    case 'TrustSecond':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <TrustSecond func={application2} {...props} />
      );
    case 'TrustSecond2':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <TrustSecond2 func={application2} {...props} />
      );

    case 'TrustThird':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <TrustThird func={application2} {...props} />
      );

    case 'TrustFour':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <TrustFour func={application2} {...props} />
      );

    case 'ThirdTrust':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <ThirdTrust func={application2} {...props} />
      );

    case 'Sixteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Sixteen func={application2} {...props} />
      );
    case 'Eleven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Eleven func={application2} {...props} />
      );

    case 'Seventeen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Seventeen func={application2} {...props} />
      );
    case 'Eighteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Eighteen func={application2} {...props} />
      );
    case 'Nineteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Nineteen func={application2} {...props} />
      );
    case 'Twenty':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twenty func={application2} {...props} />
      );
    case 'Twentyone':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone func={application2} {...props} />
      );
    case 'Twentyone1':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone1 func={application2} {...props} />
      );
    case 'Twentyone2':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone2 func={application2} {...props} />
      );
    case 'Twentytwo':
      return loader ? (
        <div className="main-loader">
          {' '}
          <sidebarConfig />
          <div className="loader"></div>
        </div>
      ) : (
        <Twentytwo func={application2} {...props} />
      );
    case 'review':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Review func={application2} {...props} />
      );
    case 'Jointhird':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <JoinThird func={application2} {...props} />
      );
    case 'MenuSidebar':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <MenuSidebar
          // certificateData={certificateData}
          // certificateDataSecondary={certificateDataSecondary}
          func1={application4}
          {...props}

        />
      );
    case 'Jointfour':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfour func={application2} {...props} />
      );
    case 'Jointfive':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfive func={application2} {...props} />
      );
    case 'Jointsix':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointsix func={application2} {...props} />
      );
    case 'Jointseven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointseven func={application2} {...props} />
      );
    case 'Jointeight':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointeight func={application2} {...props} />
      );
    case 'Jointnine':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointnine func={application2} {...props} />
      );
    // case "Jointninimore":
    //   return <Jointninimore {...props} />;
    case 'Jointten':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointten func={application2} {...props} />
      );

    case 'Jointeleven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointeleven func={application2} {...props} />
      );
    case 'Jointtwelve':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointtwelve func={application2} {...props} />
      );
    case 'Jointthirteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointthirteen func={application2} {...props} />
      );
    case 'Jointfourteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfourteen func={application2} {...props} />
      );
    case 'Jointfivteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfivteen func={application2} {...props} />
      );
    case 'Jointsexteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointsexteen func={application2} {...props} />
      );
    case 'Jointseventeen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointseventeen func={application2} {...props} />
      );
    case 'Jointeighteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointeighteen func={application2} {...props} />
      );
    case 'Jointninteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointninteen func={application2} {...props} />
      );
    case 'Jointtwenty':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointtwenty func={application2} {...props} />
      );
    case 'Jointtwentyten2222':
      return <DashboardNavbar func={application2} {...props} />;
    case 'Jointtwentyone':
      return <Jointtwentyone func={application2} {...props} />;
    case 'Jointtwentytwo':
      return <Jointtwentytwo func={application2} {...props} />;
    case 'Jointtwentythree':
      return <Jointtwentythree func={application2} {...props} />;
    case 'Jointtwentyfour':
      return <Jointtwentyfour func={application2} {...props} />;
    case 'Jointtwentyfive':
      return <Jointtwentyfive func={application2} {...props} />;
    case 'Jointtwentysix':
      return <Jointtwentysix func={application2} {...props} />;
    case 'Jointtwentyseven':
      return <Jointtwentyseven func={application2} {...props} />;
    case 'Jointtwentyeight':
      return <Jointtwentyeight func={application2} {...props} />;
    case 'Jointtwentynine':
      return <Jointtwentynine func={application2} {...props} />;
    case 'Jointtwentyten':
      return <Jointtwentyten func={application2} {...props} />;

    case 'Twentyone11':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone11 func={application2} functwo={application3} {...props} />
      );

    case 'submit':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Submit func={application2} {...props} />
      );

    default:
      return null;
  }
};

export default MultiStepForm;
