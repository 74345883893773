import React, { useState } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Webcam from "react-webcam";
import logo from '../logo.png';
import axios from "axios";
import info from '../info.png';
import $ from "jquery";
import swal from 'sweetalert';
import camera from '../camrea.png';
const Seven = ({formData, navigation, saveAndExist, infodetls,id1info }) => {
  const { } = formData;
  const { previous, next } = navigation;
  const { go } = navigation;

  let idImage = localStorage.getItem('idImage');
  const [stateet, setStatet] = useState();
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
    
  };
 
  const handleClick = () => (
    setStatet(!stateet),
    $(".setSelfieImage").attr("src", "")
  )

  const webcamRef = React.useRef(null);
  let captureImg = "";
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      captureImg = imageSrc;
      $(".setSelfieImage").attr("src", captureImg);
      removeError()
      localStorage.setItem('captureImg', captureImg);
      setStatet(captureImg);



      let data = { "image": idImage, "image1": captureImg, "type": "checkComparision" };
      axios.post('https://0dhuy0yw6a.execute-api.ap-southeast-2.amazonaws.com/prod/rekognation-api/upload', data
      )
        .then(res => {
          localStorage.setItem('detectComparison', res.data.detectComparison);

        })
    },
    [webcamRef]
  );

  saveAndExist = () => {
    formData.status = "PENDING"
    formData.step = "7"
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  id1info = () => {
    swal({
      content: {
          element: "div",
          attributes: {
              innerHTML:
                  "<p>MyFarm is required to comply with the New Zealand Government regulations to fight the funding of terrorism and money laundering activities. We are required to obtain, verify, and record information about your identity.</p><p>As part of our ongoing Anti-Money laundering obligations, we compare and match the photo contained in your ID against a photo of you.</p>",
          },
      },
  });
  }
  function handleClick2(e) {
    var data = $(".setSelfieImage").attr("src");

    if (data === null || data === "") {
      $('.setSelfieImage').html('Please take a photo');
    }
    else {
      next();

    }
    
  }
  function removeError() {
    $('.error').html('');
  }
  return (
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit se_invff">
        <p className="save next" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls} className="info" />
      </div>
      <h3>Facial matching <img src={info} onClick={id1info} className="infos" /></h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item active"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
     

      </div>
      <p>Please take a clear photo of yourself (head and shoulders)</p>
      {!stateet
        ?
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={480}
            videoConstraints={videoConstraints}
          />
          <button className="takepic" onClick={capture} ><img src={camera} /> Take a photo</button>
        </>
        : null
      }

      <img class="setSelfieImage" src=""></img>
      
      {stateet
        ?
        <div>
          <button className="takepic" onClick={handleClick}>Refresh</button>
        </div>


        : null
      }

<span className="error setSelfieImage" ></span>
      <br />
      <div className="fotter-btn">
        <button className="preview" onClick={previous}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={handleClick2} >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div >
  );
};

export default Seven;
