import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import logo from '../logo.png';
import swal from 'sweetalert';
import info from '../info.png';
import $ from "jquery";
const Jointtwentyseven = ({ setForm, formData, navigation, saveAndExist,infodetls }) => {
  const { } = formData;
  const { previous, next } = navigation;

  const { go } = navigation;
  // useEffect(() => {
  //   $(".set-previuos").click(function () {
  //     const setValues = localStorage.getItem("setValuesmy")
  //     if (setValues === "Yes") {
  //       go("Third");
  //     } else {
  //       previous();
  //     }
  //   });
  // });
  
  function previous1(e) {
    {
      (formData.isTwoYearInvest === "true")
      ?
      go("Jointhird")
      :
      previous();
    }
  }
  // let value = $("this").html();
  saveAndExist = () => {
    formData.status ="PENDING"
    formData.step ="19"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  return (
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls}  className="info" />
      </div>
      <h3>Existing Investor</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item " ></div>
        <div className="grid-item "></div>
     

      </div>
      <p>If you have previously made an investment with MyFarm in the previous 2 years in the same entity/investment owner name as you have entered here, we may already have your details on file and therefore require less information from you now.</p><br />
      <p>Once you have submitted your application details for this current investment offer, we will check our records and will be in contact with you to confirm that your previous details are still correct, and to advise of any additional information we may require.</p><br />




      <div className="fotter-btn">
        <button className="preview set-previuos" onClick={previous1}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={next}  >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
  );
};

export default Jointtwentyseven;
