import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import MenuSidebar from '../Individual/MenuSidebar';
import axios from 'axios';
import TextField from "@material-ui/core/TextField";

const TrustSecond2 = ({ setForm, formData, navigation, func }) => {
    const {
        checkboxx,
        professinalTrustee,


    } = formData;
    const { previous, next } = navigation;

    function removeError() {
        $('.error').html('');
    }


    const { go } = navigation;
    const [forword, setForword] = useState(true)
    const getInitialData1 = {
        fullLegalName: "",
        dateOfBirth: ""
    };

    const [inputBenefisList, setinputBenefisList] = useState(
        formData.inputBenefisList != null &&
            formData.inputBenefisList != "" &&
            formData.inputBenefisList != undefined &&
            formData.inputBenefisList != "undefined" ? formData.inputBenefisList : [getInitialData1 !== "" && getInitialData1 !== undefined ? getInitialData1 : null]);

    function handleClick(e) {
        const total_index = inputBenefisList.length - 1;
        if (total_index == 0) {
            if (inputBenefisList[0].fullLegalName == "" || inputBenefisList[0].fullLegalName == null || inputBenefisList[0].fullLegalName == undefined || inputBenefisList[0].fullLegalName == 'undefined') {
                $('.fullLegalName0').html('Please enter full legal name');

            } else if (inputBenefisList[0].dateOfBirth == "" || inputBenefisList[0].dateOfBirth == null || inputBenefisList[0].dateOfBirth == undefined || inputBenefisList[0].dateOfBirth == 'undefined' || inputBenefisList[0].dateOfBirth == "Select") {
                $('.dateOfBirth0').html('Please attach date Of Birth proof');

            } else {
                go('TrustThird');
            }
        }
        else if (total_index == 1) {
            if (inputBenefisList[0].fullLegalName == "" || inputBenefisList[0].fullLegalName == null || inputBenefisList[0].fullLegalName == undefined || inputBenefisList[0].fullLegalName == 'undefined') {
                $('.fullLegalNames0').html('Please enter full legal name');

            }
            else if (inputBenefisList[0].dateOfBirth == "" || inputBenefisList[0].dateOfBirth == null || inputBenefisList[0].dateOfBirth == undefined || inputBenefisList[0].dateOfBirth == 'undefined' || inputBenefisList[0].dateOfBirth == "Select") {
                $('.directRole0').html('Please attach date Of Birth proof');
            }



            else if (inputBenefisList[1].fullLegalName == "" || inputBenefisList[1].fullLegalName == null || inputBenefisList[1].fullLegalName == undefined || inputBenefisList[1].fullLegalName == 'undefined') {
                $('.fullLegalName1').html('Please enter full legal name');

            }
            else if (inputBenefisList[1].dateOfBirth == "" || inputBenefisList[1].dateOfBirth == null || inputBenefisList[1].dateOfBirth == undefined || inputBenefisList[1].dateOfBirth == 'undefined' || inputBenefisList[1].dateOfBirth == "Select") {
                $('.dateOfBirth1').html('Please attach date Of Birth proof');
            }

            else {
                go('TrustThird');
            }
        }
        else if (total_index == 2) {
            if (inputBenefisList[0].fullLegalName == "" || inputBenefisList[0].fullLegalName == null || inputBenefisList[0].fullLegalName == undefined || inputBenefisList[0].fullLegalName == 'undefined') {
                $('.fullLegalNames0').html('Please enter full legal name');

            }
            else if (inputBenefisList[0].dateOfBirth == "" || inputBenefisList[0].dateOfBirth == null || inputBenefisList[0].dateOfBirth == undefined || inputBenefisList[0].dateOfBirth == 'undefined' || inputBenefisList[0].dateOfBirth == "Select") {
                $('.directRole0').html('Please attach date Of Birth proof');
            }



            else if (inputBenefisList[1].fullLegalName == "" || inputBenefisList[1].fullLegalName == null || inputBenefisList[1].fullLegalName == undefined || inputBenefisList[1].fullLegalName == 'undefined') {
                $('.fullLegalName1').html('Please enter full legal name');

            }
            else if (inputBenefisList[1].dateOfBirth == "" || inputBenefisList[1].dateOfBirth == null || inputBenefisList[1].dateOfBirth == undefined || inputBenefisList[1].dateOfBirth == 'undefined' || inputBenefisList[1].dateOfBirth == "Select") {
                $('.dateOfBirth1').html('Please attach date Of Birth proof');
            }
            else if (inputBenefisList[2].fullLegalName == "" || inputBenefisList[2].fullLegalName == null || inputBenefisList[2].fullLegalName == undefined || inputBenefisList[2].fullLegalName == 'undefined') {
                $('.fullLegalName2').html('Please enter full legal name');

            }
            else if (inputBenefisList[2].dateOfBirth == "" || inputBenefisList[2].dateOfBirth == null || inputBenefisList[2].dateOfBirth == undefined || inputBenefisList[2].dateOfBirth == 'undefined' || inputBenefisList[2].dateOfBirth == "Select") {
                $('.dateOfBirth2').html('Please attach date Of Birth proof');
            }

            else {
                go('TrustThird');
            }
        }
        else if (total_index == 3) {
            if (inputBenefisList[0].fullLegalName == "" || inputBenefisList[0].fullLegalName == null || inputBenefisList[0].fullLegalName == undefined || inputBenefisList[0].fullLegalName == 'undefined') {
                $('.fullLegalNames0').html('Please enter full legal name');

            }
            else if (inputBenefisList[0].dateOfBirth == "" || inputBenefisList[0].dateOfBirth == null || inputBenefisList[0].dateOfBirth == undefined || inputBenefisList[0].dateOfBirth == 'undefined' || inputBenefisList[0].dateOfBirth == "Select") {
                $('.directRole0').html('Please attach date Of Birth proof');
            }



            else if (inputBenefisList[1].fullLegalName == "" || inputBenefisList[1].fullLegalName == null || inputBenefisList[1].fullLegalName == undefined || inputBenefisList[1].fullLegalName == 'undefined') {
                $('.fullLegalName1').html('Please enter full legal name');

            }
            else if (inputBenefisList[1].dateOfBirth == "" || inputBenefisList[1].dateOfBirth == null || inputBenefisList[1].dateOfBirth == undefined || inputBenefisList[1].dateOfBirth == 'undefined' || inputBenefisList[1].dateOfBirth == "Select") {
                $('.dateOfBirth1').html('Please attach date Of Birth proof');
            }
            else if (inputBenefisList[2].fullLegalName == "" || inputBenefisList[2].fullLegalName == null || inputBenefisList[2].fullLegalName == undefined || inputBenefisList[2].fullLegalName == 'undefined') {
                $('.fullLegalName2').html('Please enter full legal name');

            }
            else if (inputBenefisList[2].dateOfBirth == "" || inputBenefisList[2].dateOfBirth == null || inputBenefisList[2].dateOfBirth == undefined || inputBenefisList[2].dateOfBirth == 'undefined' || inputBenefisList[2].dateOfBirth == "Select") {
                $('.dateOfBirth2').html('Please attach date Of Birth proof');
            }
            else if (inputBenefisList[3].fullLegalName == "" || inputBenefisList[3].fullLegalName == null || inputBenefisList[3].fullLegalName == undefined || inputBenefisList[3].fullLegalName == 'undefined') {
                $('.fullLegalName3').html('Please enter full legal name');

            }
            else if (inputBenefisList[3].dateOfBirth == "" || inputBenefisList[3].dateOfBirth == null || inputBenefisList[3].dateOfBirth == undefined || inputBenefisList[3].dateOfBirth == 'undefined' || inputBenefisList[3].dateOfBirth == "Select") {
                $('.dateOfBirth3').html('Please attach date Of Birth proof');
            }

            else {
                go('TrustThird');
            }
        }
    }


    const handleAddClick = () => {
        setinputBenefisList([...inputBenefisList, getInitialData1]);
    };

    const handleRemoveClick = (index, e) => {

        const newList = [...inputBenefisList];
        newList.splice(index, 1);
        const result = axios.delete(`https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev/delete/shareholder/${e.target.value}`, {
            headers: {
                Authorization: `Bearer ${formData.token}`

            }
        }).then((res) => {
            if (res.status == 404) {
                // alert("deleted")
            }
        }
        ).catch((error) => {
            // alert("not Deleted")
        })
        setinputBenefisList(newList);
        formData.inputBenefisList = newList
        setinputBenefisList(newList);
        formData.inputBenefisList = newList

    };

    console.log("formData", inputBenefisList)
    formData.inputBenefisList = inputBenefisList
    const [Dob, SetDob] = useState('');
    function checkDate(e, hh) {

        var selectedDate = document.getElementById('dateOfBirth').value;
        // alert(selectedDate);
        SetDob(selectedDate);
        console.log("kjkkkkkkkl", selectedDate)
        var now = new Date();
        var dt1 = Date.parse(now),
            dt2 = Date.parse(selectedDate);
        if (dt2 < dt1) {
        }
    }

    return (
        <>
            <MenuSidebar navigation={navigation} formData={formData} />
            <div className="main_container">
                <div className="heading__top">
                    <h2>Step 2: Trust Details</h2>
                </div>

                <div className="form">
                    <img src={logo} alt="img" className="imageCover" />

                    <p className="please">
                        Please enter the name and date of birth for all beneficiaries of the Trust.
                    </p>

                    {inputBenefisList.map((x, index) => {
                        return (
                            <>
                                <div className={'bdetail' + index + 1 + '' + 'box'}>
                                    <Row className="Trust_row trust_row_sec align-items-center">
                                        <Col sm={6} className="col_trust">

                                            <input type="text"
                                                placeholder="Enter first & last name"
                                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                disabled={formData.isPrimary == 1 ? false : true}
                                                className='validation_name'
                                                onChange={(event) => {
                                                    removeError(event)
                                                    setForm(event);
                                                    const secArray = inputBenefisList;
                                                    secArray[index].fullLegalName = event.target.value;
                                                    setinputBenefisList([...secArray]);
                                                }}
                                                name="fullLegalName"
                                                value={inputBenefisList[index].fullLegalName}

                                            />
                                            <span className={`error fullLegalName${index}`}></span>
                                        </Col>
                                        <Col className="date-w" sm={6}>
                                            <input type="Date"
                                                placeholder="DD/MM/YYYY"
                                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                disabled={formData.isPrimary == 1 ? false : true}
                                                className='validation_name'
                                                onChange={(event) => {
                                                    removeError(event)
                                                    setForm(event);
                                                    const secArray = inputBenefisList;
                                                    secArray[index].dateOfBirth = event.target.value;
                                                    setinputBenefisList([...secArray]);
                                                }}
                                                name="dateOfBirth"
                                                value={inputBenefisList[index].dateOfBirth}
                                                max="2005-01-01"
                                            />

                                            <span className={`error dateOfBirth${index}`}></span>
                                        </Col>
                                    </Row>


                                    <div className='indu_remove_btn'>
                                        <Col className="revove_btn" sm={12}>
                                            <div className="removebtn2">
                                                {inputBenefisList.length > 1 && (
                                                    <button className='removebtn'
                                                        onClick={(e) => handleRemoveClick(index, e)}
                                                        name={x.fullLegalName}
                                                        value={x.userEmail}
                                                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                        disabled={formData.isPrimary == 1 ? false : true}

                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </div>
                                        </Col>
                                    </div>

                                </div>
                            </>
                        );
                    })}
                    <Row>
                        <Col sm={12}>
                            <div className="Trust_ind_btn">
                                {inputBenefisList.length <= 3 ? (
                                    <button
                                        onClick={handleAddClick}
                                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                        disabled={formData.isPrimary == 1 ? false : true}

                                    >+Beneficiary</button>
                                ) : null}
                            </div>
                        </Col>
                    </Row>


                    <div className="fotter-btn">
                        <button className="preview" onClick={previous}>
                            <img src={leftarrow} className="leftarrow" alt="" />
                            Previous
                        </button>
                        <button type="submit" className="next flagsave" onClick={handleClick}>
                            Continue
                            <img src={rightarrow} className="rightarrow" alt="" />{' '}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrustSecond2;

