import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import axios from 'axios';
import { multisavedata } from '../../auth/Configuration';
import info from '../info.png';
import swal from 'sweetalert';

const UploadImageShareholder = ({ formData, columnName, fileName, labeltxt, inform, showIcon, buttonName, valuedefine, bankcls }) => {
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  let { document } = documentFile64;
  const [imgSize, setimgSize] = useState();
  const queryValue = sessionStorage.getItem("queryValue");

  let queryapi = "";
  if (queryValue != null && queryValue != undefined && queryValue != "") {
    queryapi = "?update=true"
  }
  // alert(formData.companyFundsDocument='true')
  async function uploadWithJSON(file) {

    if (valuedefine == "bank2") {
      formData.primaryBankProof2 = '';
    } else if (valuedefine == 'document') {
      formData.documentProof = '';
    } else if (valuedefine == 'document2') {
      formData.documentProof2 = '';
    } else if (valuedefine == 'companyAddressProof') {
      formData.companyAddressProof = '';
    } else if (valuedefine == 'companyFundsDocument') {
      formData.companyFundsDocument = '';
    } else if (valuedefine == "bank") {
      formData.primaryBankProof = '';
    } else if (valuedefine == "exampt") {
      formData.examptProof = '';
    } else if (valuedefine == "companyExamptProof") {
      formData.companyExamptProof = '';
    } else if (valuedefine == "exampt2") {
      formData.examptProof2 = '';
    } else if (valuedefine == "trustAddressProof") {
      formData.trustAddressProof = '';
    } else if (valuedefine == "trustDeedProof") {
      formData.trustDeedProof = '';
    } else if (valuedefine == "trustExamptProof") {
      formData.trustExamptProof = '';
    } else if (valuedefine == "trustSourceOfFundsProof") {
      formData.trustSourceOfFundsProof = '';
    } else if (valuedefine == "userIrdNumberProof") {
      formData.userIrdNumberProof = '';
    } else if (valuedefine == "userIrdNumberProof2") {
      formData.userIrdNumberProof2 = '';
    } else if (valuedefine == "trustIrdNumberProof") {
      formData.trustIrdNumberProof = '';
    }


    setdocumentFile64(file.name);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });

    const data = {
      img64: await toBase64(file)
    };

    setdocumentFile64(data.img64);

    const imgs = file.type;
    if (file.size > 2202009) {
      if (valuedefine == "bank2" || valuedefine == "bank") {
        swal({
          text: 'The nominated Bank Account must be in the same name as the investment entity/owner. Maximum file size is 2MB.'
        });
      } else if (valuedefine == "document" || valuedefine == "document2") {
        swal({
          text: 'Please attach a copy of your photo identification document (maximum file size is 2MB).'

        });

      } else if (valuedefine == "exampt" || valuedefine == "exampt2") {
        swal({
          text: 'Please attach proof of exemption (maximum file size is 2MB).'

        });
      } else if (valuedefine == "companyExamptProof") {
        swal({
          text: 'Please attach proof of exemption (maximum file size is 2MB).'

        });
      } else if (valuedefine == "companyAddressProof") {
        swal({
          text: 'Please attach proof of address (maximum file size is 2MB).'

        });
      }
      else if (valuedefine == "trustAddressProof") {
        swal({
          text: 'Please attach proof of address (maximum file size is 2MB).'

        });
      }
      else if (valuedefine == "trustDeedProof") {
        swal({
          text: 'Please attach proof of deed (maximum file size is 2MB).'

        });
      }
      else if (valuedefine == "trustExamptProof") {
        swal({
          text: 'Please attach proof of exemption (maximum file size is 2MB).'

        });
      }
      else if (valuedefine == "trustExamtrustSourceOfFundsProofptProof") {
        swal({
          text: 'Please attach proof of funds (maximum file size is 2MB).'

        });
      }

      else if (valuedefine == "trustSourceOfFundsProof") {
        swal({
          text: 'Please attach proof of source of funds (maximum file size is 2MB).'

        });
      }
      else if (valuedefine == "companyAddressProof") {
        swal({
          text: 'Please attach proof of exemption (maximum file size is 2MB).'

        });
      }
      else if (valuedefine == "companyFundsDocument") {
        swal({
          text: 'Please attach proof of exemption (maximum file size is 2MB).'

        });
      } else if (valuedefine == "userIrdNumberProof") {
        swal({
          text: 'Please attach user IRD proof (maximum file size is 2MB).'

        });
      } else if (valuedefine == "userIrdNumberProof2") {
        swal({
          text: 'Please attach user IRD proof (maximum file size is 2MB).'

        });
      } else if (valuedefine == "trustIrdNumberProof") {
        swal({
          text: 'Please attach trust Ird number proof (maximum file size is 2MB).'

        });
      }


    } else {

      console.log("8888888", file.type)
      if (
        data.img64 !== null &&
        data.img64 !== '' &&
        data.img64 !== 'undefined' &&
        data.img64 !== undefined
      ) {
        let dataupdate = {
          columnName: columnName,
          fileName: fileName,
          document: data.img64,
          investmentType: formData.investmentType
        };
        setImageLoader(true);
        axios
          .post(`${multisavedata}/genImageUrl${queryapi}`, dataupdate, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (valuedefine == "bank2") {
              formData.primaryBankProof2 = res.data.url;
            } else if (valuedefine == 'document') {
              formData.documentProof = res.data.url;
            } else if (valuedefine == 'document2') {
              formData.documentProof2 = res.data.url;
            } else if (columnName == 'companyAddressProof') {
              formData.companyAddressProof = res.data.url;
            } else if (columnName == 'companyFundsDocument') {
              formData.companyFundsDocument = res.data.url;
            } else if (valuedefine == "bank") {
              formData.primaryBankProof = res.data.url;
            } else if (valuedefine == "exampt") {
              formData.examptProof = res.data.url;
            } else if (valuedefine == "companyExamptProof") {
              formData.companyExamptProof = res.data.url;
            } else if (valuedefine == "exampt2") {
              formData.examptProof2 = res.data.url;
            } else if (valuedefine == "trustAddressProof") {
              formData.trustAddressProof = res.data.url;
            } else if (valuedefine == "trustDeedProof") {
              formData.trustDeedProof = res.data.url;
            } else if (valuedefine == "trustExamptProof") {
              formData.trustExamptProof = res.data.url;
            } else if (valuedefine == "trustSourceOfFundsProof") {
              formData.trustSourceOfFundsProof = res.data.url;
            } else if (valuedefine == "userIrdNumberProof") {
              formData.userIrdNumberProof = res.data.url;
            } else if (valuedefine == "userIrdNumberProof2") {
              formData.userIrdNumberProof2 = res.data.url;
            } else if (valuedefine == "trustIrdNumberProof") {
              formData.trustIrdNumberProof = res.data.url;
            }
            if (res.status === 200) {
              setImageLoader(false);
            } else {
              setImageLoader(false);
            }
          })
          .catch((error) => {
            setImageLoader(false);
          });
      }
    }
  }
  function removeError() {
    $('.error').html('');
  }
  if (valuedefine == "bank2") {
    formData.ProofValue = formData.primaryBankProof2;
  } else if (valuedefine == "bank") {
    formData.ProofValue = formData.primaryBankProof;

  } else if (valuedefine == 'document') {
    formData.ProofValue = formData.documentProof;
  } else if (valuedefine == 'document2') {
    formData.ProofValue = formData.documentProof2;
  } else if (columnName == 'companyAddressProof') {
    formData.ProofValue = formData.companyAddressProof;
  } else if (columnName == 'companyFundsDocument') {
    formData.ProofValue = formData.companyFundsDocument;
  } else if (valuedefine == "exampt") {
    formData.ProofValue = formData.examptProof;
  } else if (valuedefine == "companyExamptProof") {
    formData.ProofValue = formData.companyExamptProof;
  } else if (valuedefine == "exampt2") {
    formData.ProofValue = formData.examptProof2;
  } else if (valuedefine == "trustAddressProof") {
    formData.ProofValue = formData.trustAddressProof;
  } else if (valuedefine == "trustDeedProof") {
    formData.ProofValue = formData.trustDeedProof;
  } else if (valuedefine == "trustExamptProof") {
    formData.ProofValue = formData.trustExamptProof;
  } else if (valuedefine == "trustSourceOfFundsProof") {
    formData.ProofValue = formData.trustSourceOfFundsProof;
  } else if (valuedefine == "userIrdNumberProof") {
    formData.ProofValue = formData.userIrdNumberProof;
  } else if (valuedefine == "userIrdNumberProof2") {
    formData.ProofValue = formData.userIrdNumberProof2;
  } else if (valuedefine == "trustIrdNumberProof") {
    formData.ProofValue = formData.trustIrdNumberProof;
  }
  const downloadImage = (imageUrl) => {
    let postadv;
    if (valuedefine == "bank2") {
      postadv = {
        downloadUrl: formData.primaryBankProof2,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "bank") {
      postadv = {
        downloadUrl: formData.primaryBankProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == 'document') {
      postadv = {
        downloadUrl: formData.documentProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };
    } else if (valuedefine == 'document2') {
      postadv = {
        downloadUrl: formData.documentProof2,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (columnName == 'companyAddressProof') {
      postadv = {
        downloadUrl: formData.companyAddressProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (columnName == 'companyFundsDocument') {
      postadv = {
        downloadUrl: formData.companyFundsDocument,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "exampt") {
      postadv = {
        downloadUrl: formData.examptProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "companyExamptProof") {
      postadv = {
        downloadUrl: formData.companyExamptProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "exampt2") {
      postadv = {
        downloadUrl: formData.examptProof2,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    }
    else if (valuedefine == "trustAddressProof") {
      postadv = {
        downloadUrl: formData.trustAddressProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };
    } else if (valuedefine == "trustDeedProof") {
      postadv = {
        downloadUrl: formData.trustDeedProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "trustExamptProof") {
      postadv = {
        downloadUrl: formData.trustExamptProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "trustSourceOfFundsProof") {
      postadv = {
        downloadUrl: formData.trustSourceOfFundsProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "userIrdNumberProof") {
      postadv = {
        downloadUrl: formData.userIrdNumberProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "userIrdNumberProof2") {
      postadv = {
        downloadUrl: formData.userIrdNumberProof2,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    } else if (valuedefine == "trustIrdNumberProof") {
      postadv = {
        downloadUrl: formData.trustIrdNumberProof,
        investmentType: formData.investmentType,
        // investmentType:formData.investmentType
      };

    }

    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };
  const [imgType, setimgType] = useState();
  console.log("imgTypeimgType", imgType);
  console.log("imgSize", imgSize);
  // alert(formData.ProofValue)
  return (
    <>
      <Row className="fullinput uploaded_btn ">

        <div className='btn_custom'>
          <div class="yes1">
            <button

              className="click-hover" >
              <span class="btn_upload">
                <input
                //   disabled={formData.isPrimary == 1 ? false : true}
                  type="file"
                  id="imag2"
                  title=""
                  value={document}
                  onChange={(e) => {

                    uploadWithJSON(e.target.files[0]);
                    setimgType(e.target.files[0].type);
                    setimgSize(e.target.files[0].size);
                    removeError();
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                    removeError();
                  }}
                />
              </span>
              {buttonName}

              {/* {BankInfo=="BankInfo"?<img src={info} onClick={infobanks} className="info backi" alt="" /> :""} */}

            </button>
          </div>
        </div>

        <div className=" ">
          <label className="bankClick">
            {labeltxt}
            {showIcon == true ? (
              <img src={info} onClick={inform} className="inform_identity_informbutton" />
            ) : (
              ''
            )}
          </label>
          <span className="error file ml-0"></span>
        </div>
      </Row>
      <div className="spceimg">
        {formData.ProofValue === '' ||
          formData.ProofValue === null ||
          formData.ProofValue === 'undefined' ||
          formData.ProofValue === undefined ? (
          ''
        ) : (
          <a href='#' className="licedocument-downloade" onClick={() => downloadImage(formData.ProofValue)}>
            {' '}
            <div className="main-downloade-wrapper">
              <div className="downloads">
                <i className="fa fa-download downloadeStyle"></i>
              </div>
              <p className="downloadeMsg">View file already provided </p>
            </div>
          </a>
        )
        }
        {/* <span id="name_tag" class="preview2"></span> */}
        {/* <input type="button" id="removeImage2" value="x" class="btn-rmv2" /> */}
        {imageLoader === true ? (
          <div class="progress">
            <span class="progress-bar" style={{ width: '100%' }}></span>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default UploadImageShareholder;
