import React,{useEffect} from "react";
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import info from '../info.png';
import logo from '../logo.png';
import $ from "jquery";
import swal from 'sweetalert';
import MenuSidebar from "../Individual/MenuSidebar";
const Thirteen = ({ setForm, formData, navigation, saveAndExist, infodetls }) => {
  const { investmentBusiness } = formData;
  const { previous, next } = navigation;

  useEffect(() => {
    // $(document).ready(function () {
  let getVal=[];
{if(formData.investmentBusiness){
  if(typeof formData.investmentBusiness != "boolean"){
     getVal =(formData.investmentBusiness).split('##')
    $('input[name="investmentBusiness"]').each(function (i) {
    for(let j= 0 ; j < getVal.length; j++){
  
      if( $(this).val() == getVal[j]){
        // alert();
        $(this).prop('checked', true);
      } ;
    }
    });


  }
}
}
})



  function handleClick(e) {
    var val = [];
    $(':checkbox:checked').each(function (i) {
      val[i] = $(this).val();
    });
    let xValue = val.join("##");

    formData.investmentBusiness = xValue;
// alert( formData.investmentBusiness )
    localStorage.setItem("step13", "step13")
    localStorage.removeItem("step15");


    if (typeof formData.investmentBusiness == 'undefined' || formData.investmentBusiness === "") {
      $('.investmentBusiness').html('Please select an answer');
    }
    else if (formData.investmentBusiness !== "None") {
      go("Jointtwentyfour")
    }
    else {

      localStorage.setItem("step13", "step13")
      next();
    }
  }

  // $('#input-test :checkbox:checked').change(function () {
  //   alert($(this).prop('checked', false));
  // });

  $('.containers').click(function () {
    // alert($("#input-test").is(":checked"))
    if ($("#input-test").is(":checked")) {
      $(':checkbox:checked').each(function (i) {
        $(this).prop('checked', false);

      });
      $("#input-test").prop('checked', true);
    } else {

    }
  });

  $('.restContainer').click(function () {
    // alert($("#input-test").is(":checked"))
     $("#input-test").prop('checked', false);
  
  });

  function removeError() {
    $('.error').html('');
  }
  saveAndExist = () => {
    formData.status = "pending"
    formData.step = "13"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  const { go } = navigation;
  return (
    <>
    <MenuSidebar navigation={navigation} formData={formData}/>
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >  SAVE DATA</p><img src={info} onClick={infodetls} className="info" />
      </div>
      <h3>Investor certificate</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
      

      </div>
      <label>Are you an “Investment Business”?</label>
      <br />
      <p>Select at least one of the below options</p>




      <div className="checkbox-my">
        <label className="containers restContainer">Your principal day-to-day business is investing in financial products
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="Your principal day-to-day business is investing in financial products" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers restContainer">Trading in financial products on behalf of others
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="Trading in financial products on behalf of others" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers restContainer">Financial Adviser to financial products (LINK Section 9 of the Financial Advisers Act)
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="Financial Adviser to financial products (LINK Section 9 of the Financial Advisers Act)" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>


        <label className="containers restContainer">Broking to financial products (LINK to 77b of FAA)
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="Broking to financial products (LINK to 77b of FAA)" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers restContainer">QFE/Authorised Financial Adviser
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="QFE/Authorised Financial Adviser" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>


        <label className="containers restContainer">Acting as an Underwriter
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="Acting as an Underwriter " onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers restContainer">Registered Bank/Non-bank deposit taker
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="Registered Bank/Non-bank deposit taker " onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers restContainer">Licensed Insurer [link s6(1) Insurance Act 2010]
  <input type="checkbox" name="investmentBusiness" onClick={removeError} value="Licensed Insurer [link s6(1) Insurance Act 2010]" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>

        <label className="containers " >None of the above
  <input type="checkbox" name="investmentBusiness" id="input-test" onClick={removeError} value="None" onChange={e=>{setForm(e) ; removeError()}} />
          <span className="checkmark"></span>
        </label>


        <span className="error investmentBusiness"></span>
        <br />
        <p>Refer FMCA Sch1, Cl 3(2)(a ) – further defined at Sch1, Cl37</p>
      </div>

      <br />
      <div className="fotter-btn">
        <button className="preview" onClick={previous}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={handleClick}  >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
    </>
  );
};

export default Thirteen;
