import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import info from '../info.png';
import $ from 'jquery';
import swal from 'sweetalert';
import TypeOfPerson from '../TypeOfPerson';
import MenuSidebar from './MenuSidebar';
import InputMask from 'react-input-mask';
import { infodetls } from '../Individual/SaveInfo';


const Eighteen = ({ func, setForm, formData, navigation, }) => {
  const {
    userEmail1,
    fullLegalNamesign,
    rwt12
  } = formData;
  const { next } = navigation;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveandexit = () => {
    func();
  };

  function Onprevious(e) {
    go('Seventeen');
  }


  const [errorResults, setErrorResults] = useState();
  function handleClick(e) {
    let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    formData.rwt12 = rwt12
    formData.fullLegalNamesign = fullLegalNamesign
    formData.userEmail1 = userEmail1
    const setValues = localStorage.getItem('setValues');
    if (formData.rwt12 === "Select" || formData.rwt12 === "" || formData.rwt12 === "undefined" || formData.rwt12 === undefined || formData.rwt12 === null) {
      $(".rwtselect").html(
        "Please select an option"
      );
    }
    else if (fullLegalNamesign === "" || fullLegalNamesign === null || fullLegalNamesign === "undefined" || fullLegalNamesign === undefined) {
      $(".fullnamee").html(
        "Please provide their name"
      );
    }
    else if (!emailExpression.test(formData.userEmail1)) {
      $(".emailerror").html(
        "Please enter a valid email address, such as example@email.com"
      );
    }

    else if (setValues === 'No') {
      formData.class = 'Twentyone';
      go('Twentyone');
      formData.CertificationValue = true;
    } else {
      formData.class = 'Twentyone';
      formData.CertificationValue = true;

      next();
    }
  }

  // saveAndExist = () => {
  //   formData.status = 'PENDING';
  //   formData.step = '17';
  //   go('submit');
  // };
  function removeError() {
    $('.error').html('');
  }


  const { go } = navigation;


  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {
            formData.investmentType == "Company" || formData.investmentType == "Joint" ?
              <h2>Step 6: Wholesale Investor Certificate B - Confirmation</h2> :
              <h2>Step 5: Wholesale Investor Certificate B - Confirmation</h2>
          }
          {/* <h2>Wholesale Investor Certificate 3 - Confirmation</h2> */}
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" alt="" />
          </div>

          <p>
            In addition to your declaration that you meet the requirements of an Eligible Investor,
            we are required to have this information confirmed by either a Chartered Accountant, a
            Lawyer, or a Financial Adviser. Please provide the details of your nominated Chartered
            Accountant, Lawyer, or Financial Advisor. Upon submission of this application, we will
            send an email to them requesting them to confirm your eligibility.
          </p>

          <TypeOfPerson
            autoComplete="off"
            type="text"
            disabled={formData.isPrimary == 1 ? false : true}

            className={
              formData.rwt12 === "Select" || formData.rwt12 === "" || formData.rwt12 === null ? 'colorgrey '
                : 'colorblack '
            }
            onChange={(e) => {
              setForm(e);
              removeError()
            }}
            name="rwt12"
            value={formData.rwt12}
          />
          <span className="error rwtselect">{errorResults}</span>

          <Row className="fullinput">
            <Col md={6}>
              <label>Enter your accountant, lawyer, or financial adviser's name</label>
              <InputMask
                name="fullLegalNamesign"
                autoComplete="off"
                className='textCapitalize'
                onChange={(e) => {
                  setForm(e);
                  removeError()
                }}
                disabled={formData.isPrimary == 1 ? false : true}

                value={formData.fullLegalNamesign}
                placeholder="Enter their name and last name"
              />
              <span className="error fullnamee">{errorResults}</span>
            </Col>
          </Row>
          <label>Enter their email address</label>
          <InputMask
            name="userEmail1"
            autoComplete="off"
            placeholder="Enter email"
            onChange={(e) => {
              setForm(e);
              setErrorResults(!errorResults);
              removeError()
            }}
            disabled={formData.isPrimary == 1 ? false : true}

            value={formData.userEmail1}
          />
          <span className="error emailerror">{errorResults}</span>
          <br />
          <div className="fotter-btn">
            <button className="preview " onClick={Onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            <button type="submit" className="next set-c" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eighteen;
