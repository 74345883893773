import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import info from '../info.png';
import swal from 'sweetalert';
import ReactFlagsSelect from 'react-flags-select';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import $, { now } from 'jquery';
import InputMask from 'react-input-mask';
import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import UploadImage from '../Individual/UploadImage';
import { infodetls } from '../Individual/SaveInfo';
import SearchAddressTrust from '../Individual/SearchAddressTrust';
import { getCode } from 'country-list';
import UploadImage2 from '../Individual/UploadImage2';



const TrustFirst = ({
    func,
    setForm,
    formData,
    navigation,
    saveAndExist,

}) => {
    formData.listTrust = [];
    formData.arryImg = [];
    const {
        manuallyAddressTrust,
        postalCode1,
        country,
        region1,
        trustName,
        city1,
        street_number1,
        trust_type,
        trust_type_other,
        trustcountryOfIncorporation,
        trustRegisterAddress
    } = formData;

    const saveandexit = () => {
        // formData.fullName = fullName != "undefined" && fullName != "" && fullName != null ? fullName.trim() : ""
        func();
    };
    let countrtyCode = [
        'NZ',
        'AF',
        'AL',
        'DZ',
        'AS',
        'AD',
        'AO',
        'AI',
        'AG',
        'AR',
        'AM',
        'AW',
        'AU',
        'AT',
        'AZ',
        'BS',
        'BH',
        'BD',
        'BB',
        'BY',
        'BE',
        'BZ',
        'BJ',
        'BM',
        'BT',
        'BO',
        'BA',
        'BW',
        'BR',
        'IO',
        'BG',
        'BF',
        'BI',
        'KH',
        'CM',
        'CA',
        'CV',
        'KY',
        'CF',
        'TD',
        'CL',
        'CN',
        'CO',
        'KM',
        'CG',
        'CD',
        'CK',
        'CR',
        'CI',
        'HR',
        'CU',
        'CW',
        'CY',
        'CZ',
        'DK',
        'DJ',
        'DM',
        'DO',
        'EC',
        'EG',
        'SV',
        'GQ',
        'ER',
        'EE',
        'ET',
        'FK',
        'FO',
        'FJ',
        'FI',
        'FR',
        'PF',
        'GA',
        'GM',
        'GE',
        'DE',
        'GH',
        'GI',
        'GR',
        'GL',
        'GD',
        'GU',
        'GT',
        'GG',
        'GN',
        'GW',
        'HT',
        'HN',
        'HK',
        'HU',
        'IS',
        'IN',
        'ID',
        'IR',
        'IQ',
        'IE',
        'IM',
        'IL',
        'IT',
        'JM',
        'JP',
        'JE',
        'JO',
        'KZ',
        'KE',
        'KI',
        'KP',
        'KR',
        'KW',
        'KG',
        'LA',
        'LV',
        'LB',
        'LS',
        'LR',
        'LY',
        'LI',
        'LT',
        'LU',
        'MO',
        'MK',
        'MG',
        'MW',
        'MY',
        'MV',
        'ML',
        'MT',
        'MH',
        'MQ',
        'MR',
        'MU',
        'MX',
        'FM',
        'MD',
        'MC',
        'MN',
        'ME',
        'MS',
        'MA',
        'MZ',
        'MM',
        'NA',
        'NR',
        'NP',
        'NL',
        'NZ',
        'NI',
        'NE',
        'NG',
        'NU',
        'NF',
        'MP',
        'NO',
        'OM',
        'PK',
        'PW',
        'PS',
        'PA',
        'PG',
        'PY',
        'PE',
        'PH',
        'PN',
        'PL',
        'PT',
        'PR',
        'QA',
        'RO',
        'RU',
        'RW',
        'KN',
        'WS',
        'SM',
        'ST',
        'SA',
        'SN',
        'RS',
        'SC',
        'SL',
        'SG',
        'SX',
        'SK',
        'SI',
        'SB',
        'SO',
        'ZA',
        'SS',
        'ES',
        'LK',
        'SD',
        'SR',
        'SZ',
        'SE',
        'CH',
        'SY',
        'TW',
        'TJ',
        'TZ',
        'TH',
        'TG',
        'TK',
        'TO',
        'TT',
        'TN',
        'TR',
        'TM',
        'TC',
        'TV',
        'UG',
        'UA',
        'AE',
        'GB',
        'US',
        'UY',
        'UZ',
        'VU',
        'VE',
        'VN',
        'VI',
        'YE',
        'ZM',
        'ZW'
    ];

    const [selected, setSelected] = useState(
        // formData.entityCompanyOne == 'false' ?
        (formData.otherCountryCompanyRegistered !== null &&
            formData.otherCountryCompanyRegistered !== '' &&
            formData.otherCountryCompanyRegistered !== 'undefined' &&
            formData.otherCountryCompanyRegistered !== undefined
            ? formData.otherCountryCompanyRegistered
            : "")
        // : ""
    );
    formData.otherCountryCompanyRegistered = selected;


    // formData.fullName = fullName.trim()
    const { previous, next } = navigation;
    useEffect(() => {
        $('.toogles').click(function () {
            $('.toogles').removeClass('active');
            $(this).addClass('active');
        });
        $('.step-change').click(function () {
            $('.step-change').removeClass('active');
            $(this).addClass('active');
        });
        $('document').ready(function () {
            $("input[type='tel']").attr('readonly', true);
        });
        $('.flagsave').click(function () {
            let phoneCode = $('.form-control').val();
            localStorage.setItem('phoneCode', phoneCode);
        });
        $('#SHAREvALUE').keyup(function () {
            let input = $(this).val();
            if (input < 100) {
                return true;
            } else {
                return false;
            }
        });
    });
    function splitFullName(fn) {
        fn = fn.replace(/(^[\s]*|[\s]*$)/g, '');
        fn = fn.replace(/\s+/g, ' ');
        var fnarr = fn.split(' ');
        formData.firstNames = fnarr[0];
        formData.firstNameone = formData.firstNames;
        if (fnarr.length >= 3) {
            formData.lastNames = fnarr[fnarr.length - 1];
            formData.lastNameone = formData.lastNames;
            fnarr.shift();
            fnarr.pop();
            fnarr = fnarr.toString().replace(/,/g, ' ');
            formData.middleNames = fnarr;
            formData.middleNameone = formData.middleNames;
        } else if (fnarr.length === 3) {
            formData.middleNames = fnarr[1];
            formData.middleNameone = formData.middleNames;
            formData.lastNames = fnarr[2];
            formData.lastNameone = formData.lastNames;
        } else if (fnarr.length === 2) {
            formData.lastNames = fnarr[1];
            formData.lastNameone = formData.lastNames;
        }
    }

    function Onprevious(e) {
        formData.class = 'Second';

        go('Second');
    }

    const [errorResults, setErrorResults] = useState();
    function handleClick(e) {
        if (formData.trustName == "" || formData.trustName == null || formData.trustName == undefined) {
            $('.trustnameerrs').html('Please enter Trust name ')

        } if (formData.trustRegisterAddress == "" || formData.trustRegisterAddress == null || formData.trustRegisterAddress == "undefined") {
            $('.residential').html('Please enter your address')

        }
        else if (formData.trustDeedProof === "" || formData.trustDeedProof === null || formData.trustDeedProof === "undefined") {
            $('.trustDeedProof').html('Please attach trust deed proof')

        }
        else if ((formData.street_number1 == null || formData.street_number1 == "" || formData.street_number1 == undefined || formData.street_number1 == "undefined") && formData.manuallyAddressTrust === "false") {
            $('.street_numbererror').html('Please enter your street number ')
        }
        else if ((formData.city1 == "" || formData.city1 == null || formData.city1 == "undefined") && formData.manuallyAddressTrust === "false") {
            $('.cityerror').html('Please enter your city')

        }
        else if ((formData.postalCode1 == "" || formData.postalCode1 == null || formData.postalCode1 == "undefined") && formData.manuallyAddressTrust === "false") {
            $('.postalCodeerror').html('Please enter your postal code')

        } else if (formData.trust_type == "" || formData.trust_type == "Select" || formData.trust_type == null || formData.trust_type == "undefined") {
            $('.trustselect').html('Please select trust type')

        } else if ((formData.trust_type_other == "" || formData.trust_type_other == null || formData.trust_type_other == "undefined") && formData.trust_type === "Other") {
            $('.trustOtherSelect').html('Please enter text field')

        } else if (formData.trustAddressProof == "" || formData.trustAddressProof == null || formData.trustAddressProof == "undefined") {
            $('.trustAddressProof').html('Please attach trust address proof')

        } else {
            formData.class = 'Jointhird';
            go('TrustSecond');
        }

    }


    function removeError() {
        $('.error').html('');
    }

    function removeErrorOnlyOne(valueClass) {
        $(`.${valueClass}`).html('');
    }
    function numbertest() {
        let input = $(this).val();
        if (input < 100) {
            return true;
        } else {
            return false;
        }
    }
    $('input').on('paste', function (e) {
        $('.error').html('');
    });
    const [value, setValue] = useState();
    const [currentDate, setNewDate] = useState(null);
    const onChange = (event, data) => setNewDate(data.value);
    const { go } = navigation;
    saveAndExist = () => {
        formData.status = 'PENDING';
        formData.step = '3';
        go('submit');
    };
    const chenging = () => {
        sessionStorage.removeItem("street_number1");
        sessionStorage.removeItem("locality");
        sessionStorage.removeItem("administrative_area_level_1");
        sessionStorage.removeItem("sublocality_level_1");
        sessionStorage.removeItem("postal_code");
        sessionStorage.removeItem("premise");
        sessionStorage.removeItem("route");
        sessionStorage.removeItem("country");
        sessionStorage.removeItem("residentialAddress1")
    };
    const chenging2 = () => {
        formData.street_number1 = ""
        formData.city1 = "";
        formData.country = "NZ";
        formData.postalCode1 = "";
        formData.region1 = "";
        formData.isPrimaryResdAddress = ""
    };


    const residentialSave1 = (e) => {
        formData.street_number1 = e.target.value
        formData.isPrimaryResdAddress = e.target.value
    }

    const residentialSave7 = (e) => {
        formData.region1 = e.target.value
        formData.isPrimaryResdAddress = formData.street_number1 + (e.target.value != null ? "," + e.target.value : "")
    }

    const residentialSave3 = (e) => {
        formData.city1 = e.target.value
        formData.isPrimaryResdAddress = formData.street_number1 + (formData.region1 != null ? "," + formData.region : "") + (e.target.value != null ? "," + e.target.value : "")

    }
    const residentialSave6 = (e) => {

        formData.postalCode1 = e.target.value
        formData.isPrimaryResdAddress = formData.street_number1 + (formData.region1 != null ? "," + formData.region1 : "") + (formData.city1 != null ? "," + formData.city1 : "") + (e.target.value != null ? "," + e.target.value : "")

    }
    const residentialSave4 = (e) => {

        formData.country = e.target.value
        formData.country = selected123;
        formData.isPrimaryResdAddress = formData.street_number1 + (formData.region1 != null ? "," + formData.region1 : "") + (formData.city1 != null ? "," + formData.city1 : "") + (formData.postalCode1 != null ? "," + formData.postalCode1 : "") + (e.target.valuey != null ? "," + e.target.value : "")
    }


    useEffect(() => {
        if (formData.manuallyAddressTrust == "false") {
            sessionStorage.removeItem("street_number1");
            sessionStorage.removeItem("locality");
            sessionStorage.removeItem("administrative_area_level_1");
            sessionStorage.removeItem("sublocality_level_1");
            sessionStorage.removeItem("postal_code");
            sessionStorage.removeItem("premise");
            sessionStorage.removeItem("route");
            sessionStorage.removeItem("country");
            sessionStorage.removeItem("residentialAddress1")
        }
        else if (formData.manuallyAddressTrust == "true") {
            formData.street_number1 = ""
            formData.city1 = "";
            formData.country = "";
            formData.postalCode1 = "";
            formData.region1 = "";
            // formData.isPrimaryResdAddress = ""
        }
    }, [])
    const [selected123, setSelected123] = useState(
        formData.country !== null &&
            formData.country !== '' &&
            formData.country !== 'undefined' &&
            formData.country !== undefined
            ? formData.country
            : 'NZ'
    );
    formData.country = selected123;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [imageLoader, setImageLoader] = useState();
    const [documentFile64, setdocumentFile64] = useState('');
    let { document } = documentFile64;
    const datetime = new Date();
    const [errror, setErrror] = useState();

    let labeltxt = 'Please attach a certified copy of proof of address';
    let labeltxt2 = 'Please attach a copy of the Trust Deed, and Deeds of Retirement/Appointment';

    //   const [selected, setSelected] = useState(formData.documentCountryOfIssue);

    const [selected11, setSelected11] = useState(
        formData.trustcountryOfIncorporation !== null &&
            formData.trustcountryOfIncorporation !== '' &&
            formData.trustcountryOfIncorporation !== 'undefined' &&
            formData.trustcountryOfIncorporation !== undefined
            ? formData.trustcountryOfIncorporation
            : 'NZ'
    );
    // formData.otherCountryCompanyRegistered = selected11;
    formData.trustcountryOfIncorporation = selected11;


    const columnName = 'trustAddressProof';
    const fileName = `Trust_Address_Proof`;

    const colName = 'trustDeedProof'
    const fileNames = 'trust_Deed_Proof'

    const MAX_COUNT = 3;
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);


    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        console.log(uploadedFiles, "hghgjgj");
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)

    }

    const handleFileEvent = (e) => {
        console.log("eeeee", e)
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }
    return (
        <>
            <MenuSidebar navigation={navigation} formData={formData} />
            <div className="main_container">
                <div class="heading__top">
                    <h2>Step 2: Trust Details</h2>
                </div>
                <div className="form">
                    <img src={logo} alt="img" className="imageCover" />
                    <div className="save-exit se_invff">
                        <p
                            className="save next"
                            onClick={saveandexit}>
                            SAVE DATA{' '}
                        </p>
                        <img src={info} onClick={infodetls} className="info" />
                    </div>

                    <Row className="fullinput">
                        <Col md={6}>
                            <label>Trust name</label>
                            <ItemForm
                                disabled={formData.isPrimary == 1 ? false : true}
                                type="text"
                                name="trustName"
                                onKeyPress={removeError}
                                className="letterOnly quantity_Trust trustname"
                                id="trustName"
                                onChange={(e) => {
                                    setForm(e);
                                    removeError(e);
                                }}
                                value={trustName}
                                placeholder="Enter Trust name"
                                readOnlyFuc
                            />
                            <span className="error trustnameerrs"></span>
                            {/* <span className="error trustnameerr"></span> */}

                        </Col>
                        <Col md={12}>
                        <>
                                    <UploadImage2
                                        buttonName='CLICK TO ATTACH'
                                        disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                        formData={formData}
                                        columnName={colName}
                                        fileName={fileNames}
                                        labeltxt={labeltxt2}
                                        valuedefine={"trustDeedProof"}
                                    />
                                    <span className="error trustDeedProof"></span>
                                </> 
                            {/* {formData.isPrimary == 1 ?
                                <>
                                    <UploadImage2
                                        buttonName='CLICK TO ATTACH'
                                        disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                        formData={formData}
                                        columnName={colName}
                                        fileName={fileNames}
                                        labeltxt={labeltxt2}
                                        valuedefine={"trustDeedProof"}
                                    />
                                    <span className="error trustDeedProof"></span>
                                </> 
                                : 
                                <button>CLICK TO ATTACH</button>
                            } */}


                            {/* <input
                                id='fileUpload'
                                type='file'
                                multiple accept='application/pdf, image/png, image/jpeg'
                                onChange={handleFileEvent}
                                disabled={fileLimit}
                                labeltxt={labeltxt2}
                         
                            />
                            <label htmlFor='fileUpload'>
                                <a className={`btn btn-primary attach ${!fileLimit ? '' : 'disabled'} `}>CLICK TO ATTACH</a>
                            </label>

                            <div className="uploaded-files-list">
                                {uploadedFiles.map(file => (
                                    <div>
                                        {file.name}
                                    </div>
                                ))}
                            </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>

                            {manuallyAddressTrust == "true" || manuallyAddressTrust == "undefined" || manuallyAddressTrust == undefined || manuallyAddressTrust == null ?
                                <>
                                    <SearchAddressTrust

                                        disabled={formData.isPrimary == 1 ? false : true}
                                        formData={formData}
                                        className='hj'
                                        placeholder="Enter Trust address"
                                        label="Trust address"
                                        name="trustRegisterAddress"
                                        value2={formData.trustRegisterAddress}
                                    />
                                    {manuallyAddressTrust == "true" || manuallyAddressTrust == undefined ? <span className="error residential"></span> : null}
                                </>
                                : manuallyAddressTrust === "false" ?
                                    <Row >
                                        <Col >
                                            <label>Street number & name</label>
                                            <input
                                                // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                                disabled={formData.isPrimary == 1 ? false : true}
                                                className='hj'
                                                type="text"
                                                name="street_number1"
                                                placeholder="Enter street number & name"

                                                onChange={(e) => {

                                                    residentialSave1(e);
                                                    setForm(e);
                                                    removeErrorOnlyOne("street_numbererror")
                                                }}
                                                value={street_number1}
                                            />
                                            <span className='error street_numbererror '></span>
                                            <label>Suburb</label>
                                            <input
                                                // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                                disabled={formData.isPrimary == 1 ? false : true}

                                                className='hj'
                                                type="text"
                                                name="region1"
                                                placeholder="Enter suburb"
                                                onChange={(e) => {
                                                    residentialSave7(e);
                                                    setForm(e);

                                                }}
                                                value={region1}
                                            />
                                            <label>City</label>
                                            <input
                                                // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                                disabled={formData.isPrimary == 1 ? false : true}

                                                className='hj'
                                                type="text"
                                                name="city1"
                                                placeholder="Enter city"
                                                onChange={(e) => {
                                                    residentialSave3(e);
                                                    setForm(e);
                                                    removeErrorOnlyOne("cityerror")
                                                }}
                                                value={city1}
                                            />
                                            <span className='error cityerror'></span>
                                            <label>Postal code</label>
                                            <input
                                                // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                                disabled={formData.isPrimary == 1 ? false : true}

                                                maxLength={4}
                                                onInput={(e) => {
                                                    if (e.target.value.length > e.target.maxLength)
                                                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                                                }}
                                                className="numbers hj"
                                                type="number"
                                                name="postalCode1"
                                                onWheel={(e) => e.target.blur()}
                                                placeholder="Enter postal code"
                                                onChange={(e) => {
                                                    residentialSave6(e);
                                                    setForm(e);
                                                    removeErrorOnlyOne("postalCodeerror")
                                                }}
                                                value={postalCode1}
                                            />
                                            <span className='error postalCodeerror'></span>
                                            <label>Country</label>
                                            <ReactFlagsSelect
                                                // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                                disabled={formData.isPrimary == 1 ? false : true}

                                                autoComplete="off"
                                                name="country"
                                                selected={selected123}
                                                value={country}
                                                className="FlagsSelectcss dffk"
                                                // className="dff"
                                                placeholder="Select"
                                                onChange={(e) => {
                                                    residentialSave4(e);
                                                    setForm(e);
                                                }}
                                                onSelect={(code) => setSelected123(code)}
                                                countries={countrtyCode}
                                                defaultCountry={
                                                    country && country !== null
                                                        ? getCode(country.toUpperCase())
                                                        : null
                                                }
                                            />
                                        </Col>

                                    </Row>

                                    : ''
                            }
                        </Col >
                        <Col xs={3}>
                            <div className="button_group space-bottom switch_botton hjj">
                                <div class="switch-field nospace">
                                    <div className="check_YN_Bank swtich10 resize">
                                        <input
                                            // disabled={formData.isPrimary == null && formData.isPrimary == 1 ? false : true}
                                            disabled={formData.isPrimary == 1 ? false : true}

                                            type="radio"
                                            id="radio-one1"
                                            className="yesno"
                                            name="manuallyAddressTrust"
                                            value="true"
                                            onChange={(e) => {
                                                setForm(e);
                                                chenging2(e);
                                                removeError();
                                            }}
                                            onClick={(e) => chenging2(e)}
                                        />
                                        <label
                                            for="radio-one1"
                                            className={`toogles1 postalAddressButton ${typeof manuallyAddressTrust === 'undefined' || manuallyAddressTrust === 'true'
                                                ? 'actives'
                                                : null
                                                }`}
                                            id="show"
                                        >
                                            Auto Lookup
                                        </label>
                                        <input
                                            // disabled={formData.isPrimary == null && formData.isPrimary == 1 ? false : true}
                                            disabled={formData.isPrimary == 1 ? false : true}

                                            type="radio"
                                            id="radio-two2"
                                            name="manuallyAddressTrust"
                                            value="false"
                                            onChange={(e) => {
                                                formData.trustRegisterAddress = null;

                                                setForm(e);
                                                chenging(e);
                                                removeError();
                                            }}
                                            onClick={(e) => chenging(e)}
                                        />
                                        <label
                                            for="radio-two2"
                                            className={`toogles1 postalAddressButtons ${manuallyAddressTrust === 'false' ? 'actives' : null
                                                }`}
                                            id="hide"
                                        >
                                            Manually Type
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <UploadImage
                        // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                        disabled={formData.isPrimary == 1 ? false : true}

                        buttonName='CLICK TO ATTACH'
                        formData={formData}
                        columnName={columnName}
                        fileName={fileName}
                        labeltxt={labeltxt}
                        valuedefine={"trustAddressProof"}
                    />
                    <span className="error trustAddressProof"></span>

                
                   {/* { formData.isPrimary == 1?
                <>
                <UploadImage
                        // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                        disabled={formData.isPrimary == 1 ? false : true}

                        buttonName='CLICK TO ATTACH'
                        formData={formData}
                        columnName={columnName}
                        fileName={fileName}
                        labeltxt={labeltxt}
                        valuedefine={"trustAddressProof"}
                    />
                    <span className="error trustAddressProof"></span>

                </>
                : 
                <button>CLICK TO ATTACH</button>  
                } */}

                    
                    <Row className="fullinput jointhird">
                        <Col lg={12} className="FlagsSelectcss flag-2">
                            <div onClick={removeError}>
                                <label>Country of Incorporation</label>
                                <ReactFlagsSelect
                                    // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                    disabled={formData.isPrimary == 1 ? false : true}
                                    autoComplete="off"
                                    name="trustcountryOfIncorporation"
                                    selected={selected11}
                                    value={trustcountryOfIncorporation}
                                    placeholder="Select"
                                    onChange={(e) => {
                                        setForm(e);
                                        removeError();
                                    }}
                                    onSelect={(code) => setSelected11(code)}
                                    countries={countrtyCode}
                                    defaultCountry={
                                        trustcountryOfIncorporation && trustcountryOfIncorporation !== null
                                            ? trustcountryOfIncorporation.toUpperCase()
                                            : null
                                    }
                                />

                                <span className="error trustcountryOfIncorporation"></span>
                            </div>
                        </Col>
                    </Row>
                    <Col className='jointhird_two'>
                        <label>What type of Trust is this</label>
                        <select
                            // disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                            disabled={formData.isPrimary == 1 ? false : true}
                            type="select"
                            placeholder="Enter Trust Type"
                            className={trust_type == "Discretionary Trust" || trust_type == 'Charitable Trust' || trust_type == 'Other' ? 'trust-select-black' : 'trust-select-grey'}
                            name="trust_type"
                            value={trust_type}
                            onChange={(e) => {
                                setForm(e);
                                removeError();
                            }}>
                            <option>Select</option>
                            <option>Discretionary Trust</option>
                            <option>Charitable Trust</option>
                            <option>Other</option>
                        </select>
                        <span className='error trustselect'></span>

                        {
                            trust_type == "Other" ?
                                <>
                                    <input type="text"
                                        disabled={formData.isPrimary == null && formData.isPrimary == 0 ? false : true}
                                        placeholder='Enter type of trust'
                                        name="trust_type_other"
                                        onChange={(e) => {
                                            setForm(e);
                                            removeError();
                                        }}
                                        value={trust_type_other}

                                    />
                                    <span className='error trustOtherSelect'></span>
                                </>
                                : ""

                        }
                        <span className='error trust_type_other2'></span>

                    </Col>

                    <div className="fotter-btn">
                        <button className="preview" onClick={Onprevious}>
                            <img src={leftarrow} className="leftarrow" alt="" />
                            Previous
                        </button>
                        {imageLoader === true ? (
                            <button type="submit" className="next flagsave">
                                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                                <img src={rightarrow} className="rightarrow" alt="" />{' '}
                            </button>
                        ) : (
                            <button type="submit" className="next flagsave" onClick={handleClick}>
                                Continue
                                <img src={rightarrow} className="rightarrow" alt="" />{' '}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default TrustFirst;
