import axios from "axios";
const RECT_AAP_URL_Test =
	"https://4gqrvq3b3k.execute-api.ap-southeast-2.amazonaws.com/dev";
const RECT_AAP_URL_PROD =
	"https://duvalfunctionapp.azureedge.net/api/duval";

// const RECT_AAP_URL_STAG =
// 	"https://xy5mduhn7b.execute-api.ap-southeast-2.amazonaws.com/stag";

const RECT_AAP_URL_STAG = "https://dobhyvulqa01c.cloudfront.net/";

const instance = axios.create({
	baseURL: RECT_AAP_URL_PROD,
});
export default instance;
