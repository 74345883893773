import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AxiosInstance from "../axios"
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import logo from '../logo.png';
import info from '../info.png';
import swal from 'sweetalert';
import workflow from '../workflow.png';
import MenuSidebar from '../Individual/MenuSidebar';

const Nine = ({ setForm, formData, navigation,func,saveAndExist,infodetls }) => {

  const [userapplication, setUserapplication] = useState([]);
  useEffect(() => {
    application();
  }, []);
  const application = async () => {
    const applicationdata = await AxiosInstance.get("/admin/syndicate/" + Syndication);
    setUserapplication(applicationdata.data);
  };

  const { Syndication } = formData;
  const { previous, next } = navigation;

  saveAndExist = () => {
    formData.status ="PENDING"
    formData.step ="9"
  func();
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }

  function previous1(e) {
    { (formData.nominatedProfessional1 === "later")
    ?
    go("Jointten")
    : 
    
    previous();
    }
    
  }


  const { go } = navigation;
  return (
    <>
    <MenuSidebar navigation={navigation} formData={formData}/>
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
     <div className="save-exit joint-holder">
        <p className="save" onClick={saveAndExist} >  SAVE DATA </p><img src={info} onClick={infodetls}  className="info" />
      </div>
      <h3>Eligibility to invest</h3>
     
      <p>This investment offer is only available to Wholesale or Eligible Investors.</p>
      <p className="text-justify">Before any subscription can be considered for acceptance
           by MyFarm Ltd (the Offeror) in respect of the offer of units in {userapplication.syndicateName} (an Issuer) the investing party (and potentially their financial or legal adviser)
             will be required to complete an investment certificate(s) as per the following diagram.
</p>


      <br />
      <img src={workflow} className="width-set" />
      <br />
      <br />
      <p className="text-justify">More information is provided on the following pages
         to help with your selection of the appropriate Investor Category and Certificate. Acceptance occurs on commencement of the Limited Partnership Agreement.</p>
      <br />


      <br />
      <div className="fotter-btn">
        <button className="preview" onClick={previous1}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={next}  >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
    </>
  );
};

export default Nine;
