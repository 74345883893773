/*eslint eqeqeq: "off"*/
import React, { useEffect, useMemo, useState } from 'react';
import axios from "axios";
import { Biometricverify, multisavedata } from 'src/auth/Configuration';
import Avatar, { ConfigProvider } from 'react-avatar';
import $ from 'jquery';
import { useAuth0 } from '@auth0/auth0-react';
import { useIdleTimer } from 'react-idle-timer'
import Swal from 'sweetalert2';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import swal from 'sweetalert';
import useQuery from '../useQuery';

const MenuSidebar = ({ navigation, formData, func, func1 }) => {
  const {
    logout
  } = useAuth0();
  const { bankSwiftCode2 } = formData;
  const query=useQuery();
  let queryValue=query.get("update") || "";
  let queryValue3 = query.get("type") || "";
  const queryCheck=query.get("name") || "";
  // const queryValue = sessionStorage.getItem("queryValue");
  // const checkData=queryValue.split("/");
  // const queryCheck = sessionStorage.getItem("queryCheck");
  const checkCondition = sessionStorage.getItem("queryChIdOrCer");
// alert(query.get("update"))
console.log('query.get("update")',query.get("update"))

  // alert("val",query.get("update"))
  // alert("name",query.get("name"))
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  let certificateData = {

    isMinimumInvest: formData.isMinimumInvest == 'Yes' ? true : false,
    certificateNumber:
      formData.certificateNumber !== '' &&
        formData.certificateNumber !== null &&
        formData.certificateNumber !== 'undefined'
        ? formData.certificateNumber
        : '',

    nominatedUserType: formData.rwt12 !== null ? formData.rwt12 : null,
    nominatedUserName: formData.fullLegalNamesign !== null ? formData.fullLegalNamesign : null,
    nominatedUserEmail: formData.userEmail1 !== null ? formData.userEmail1 : null,

    usualLegalRules: formData.usualLegalRules === true ? "The usual legal rules that require information to be given to investors for offers of financial products do not apply if the amount invested upfront by me (plus any other investments I/We have already made in those financial products) is $750,000 or more; and" : "",
    notCompleteInformation: formData.notCompleteInformation === true ? "I/We may not receive a complete and balanced set of information about this investment; and" : "",
    // materialExtent10yrs: formData.materialExtent10yrs === true ? "The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business." : "",
    fewerLegalProtection: formData.fewerLegalProtection === true ? "I/We have fewer legal protections for this investment; and" : "",
    notSuitableInvestment: formData.notSuitableInvestment === true ? "This investment is not suitable for retail investors; and" : "",
    seekFinancialAdvice: formData.seekFinancialAdvice === true ? "I/We have been advised to ask questions, read all documents carefully, and seek independent financial advice." : "",
    largePersonValue: formData.largePersonValue == true ? "As at the last day of each of the Investor's two most recently completed financial years, the net assets of the Investor and the entities controlled by the Investor exceed $5 million." : "",
    twoYearInvestEntity2: formData.twoYearInvestEntity2 == true ? "In each of the Investor's two most recently completed financial years, the total consolidated turnover of the Investor and the entities controlled by the Investor exceeded $5 million." : "",

    investorPrincipalBusiness: formData.investorPrincipalBusiness === true ? "The Investor’s principal business consists of one or more of the following (tick all that apply)" : null,
    investingFinancialProducts: formData.investingFinancialProducts === true ? "Investing in financial products" : null,
    actingUnderwriter: formData.actingUnderwriter === true ? "Acting as an underwriter" : null,
    financialAdviceService: formData.financialAdviceService === true ? "Providing a financial advice service" : null,
    clientMoneyPropertyService: formData.clientMoneyPropertyService === true ? "Providing a client money or property service" : null,
    tradingFinancialProducts: formData.tradingFinancialProducts === true ? "Trading in financial products on behalf of other persons." : null,
    registeredBankRBNZ: formData.registeredBankRBNZ === true ? "The Investor is a registered bank under the Reserve Bank of New Zealand Act 2010." : null,
    NBDTInvestor: formData.NBDTInvestor === true ? "The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013." : null,
    licensedIssuerInsurance: formData.licensedIssuerInsurance === true ? "The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010." : null,
    registeredSchemeManager: formData.registeredSchemeManager === true ? "The Investor is the manager of a registered scheme, or a discretionary investment management service, that holds a market services licence under the Financial Markets Conduct Act 2013." : null,
    derivativesIssuer: formData.derivativesIssuer === true ? "The Investor is a derivatives issuer that holds a market services license under the Financial Markets Conduct Act 2013." : null,
    FinancialAdvisor: formData.FinancialAdvisor === true ? "The Investor is a Financial Adviser" : null,
    ownedPortfolio2yrs: formData.ownedPortfolio2yrs === true ? "The investor owns, or at any time during the 2-year period immediately before this certificate is given has owned, a portfolio of financial products (other than membership of a retirement scheme or financial products issued by an associate) of a value of at least $1 million (in aggregate)." : null,
    carriedTransactions2yrs: formData.carriedTransactions2yrs === true ? "The investor has, during the 2-year period immediately before this certificate is given, carried out one or more transaction(s) to acquire financial products (other than membership of a retirement scheme or financial products issued by an associate) where the amount payable under those transactions (in aggregate) is at least $1 million." : null,
    investmentBusiness10yrs: formData.investmentBusiness10yrs === true ? "The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business." : null,
    equitySecurities: formData.equitySecurities === true ? "Equity securities (includes listed shares)" : null,
    managedFunds: formData.managedFunds === true ? "Managed funds" : null,
    derivativeInstruments: formData.derivativeInstruments === true ? "Derivative instruments" : null,
    debtSecurities: formData.debtSecurities === true ? "Debt securities" : null,
    startupOrBusiness: formData.startupOrBusiness === true ? "I/We previously invested in early stage, start up, or established businesses" : null,
    investorPresentation: formData.investorPresentation === true ? "In the course of it’s investment activities, I/We have been involved in investor presentations and/or due diligence meetings and through this have gained an appreciation for the risks and opportunities associated with investing in businesses." : null,
    myOwnInvestmentDecisions: formData.myOwnInvestmentDecisions === true ? "I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio." : null,
    valueAndRisksOfInvesting: formData.valueAndRisksOfInvesting === true ? "In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products." : null,
    capitalRaisingDirector: formData.capitalRaisingDirector === true ? "I/We have served as a Director of a business that was involved in capital raising" : null,
    investingSharesSecurities: formData.investingSharesSecurities === true ? "I/We have experience in investing in shares and other securities (listed and unlisted) and understand the risks with investing in different types of financial products." : null,
    acquiringDisposingFinancialProductsOther: formData.acquiringDisposingFinancialProductsOther,
    acquiringDisposingFinancialProducts: formData.acquiringDisposingFinancialProducts === true ? "I/We have other relevant experience of acquiring or disposing of financial products, details being the following." : null,
    none_certificate: formData.investmentBusinessNone,
    none_certificate_1: formData.investmentCriteriaNone,
    largePersonNone: formData.largePersonNone,

    signatureA:
      formData.signatureUrl !== null &&
        formData.signatureUrl !== '' &&
        formData.signatureUrl !== 'undefined' &&
        formData.signatureUrl !== undefined
        ? formData.signatureUrl
        : formData.signatureUrlB !== null &&
          formData.signatureUrlB !== '' &&
          formData.signatureUrlB !== 'undefined' &&
          formData.signatureUrlB !== undefined
          ? formData.signatureUrlB
          : '',

    adherenceSign:
      formData.adherenceSignUrl !== null &&
        formData.adherenceSignUrl !== '' &&
        formData.adherenceSignUrl !== 'undefined' &&
        formData.adherenceSignUrl !== undefined
        ? formData.adherenceSignUrl
        : '',
    subscriptionSign:
      formData.signatureSubscriptionUrl !== null &&
        formData.signatureSubscriptionUrl !== '' &&
        formData.signatureSubscriptionUrl !== 'undefined' &&
        formData.signatureSubscriptionUrl !== undefined
        ? formData.signatureSubscriptionUrl
        : '',
  };
  let certificateDataSecondary = {
    isMinimumInvest: formData.isMinimumInvest == 'Yes' ? true : false,
    certificateNumber:
      formData.certificateNumber !== '' &&
        formData.certificateNumber !== null &&
        formData.certificateNumber !== 'undefined'
        ? formData.certificateNumber
        : '',

    nominatedUserType: formData.rwt12 !== null ? formData.rwt12 : null,
    nominatedUserName: formData.fullLegalNamesign !== null ? formData.fullLegalNamesign : null,
    nominatedUserEmail: formData.userEmail1 !== null ? formData.userEmail1 : null,

    usualLegalRules: formData.usualLegalRules === true ? "The usual legal rules that require information to be given to investors for offers of financial products do not apply if the amount invested upfront by me (plus any other investments I/We have already made in those financial products) is $750,000 or more; and" : "",
    notCompleteInformation: formData.notCompleteInformation === true ? "I/We may not receive a complete and balanced set of information about this investment; and" : "",
    // materialExtent10yrs: formData.materialExtent10yrs === true ? "The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business." : "",
    fewerLegalProtection: formData.fewerLegalProtection === true ? "I/We have fewer legal protections for this investment; and" : "",
    notSuitableInvestment: formData.notSuitableInvestment === true ? "This investment is not suitable for retail investors; and" : "",
    seekFinancialAdvice: formData.seekFinancialAdvice === true ? "I/We have been advised to ask questions, read all documents carefully, and seek independent financial advice." : "",
    largePersonValue: formData.largePersonValue == true ? "As at the last day of each of the Investor's two most recently completed financial years, the net assets of the Investor and the entities controlled by the Investor exceed $5 million." : "",
    twoYearInvestEntity2: formData.twoYearInvestEntity2 == true ? "In each of the Investor's two most recently completed financial years, the total consolidated turnover of the Investor and the entities controlled by the Investor exceeded $5 million." : "",

    investorPrincipalBusiness: formData.investorPrincipalBusiness === true ? "The Investor’s principal business consists of one or more of the following (tick all that apply)" : null,
    investingFinancialProducts: formData.investingFinancialProducts === true ? "Investing in financial products" : null,
    actingUnderwriter: formData.actingUnderwriter === true ? "Acting as an underwriter" : null,
    financialAdviceService: formData.financialAdviceService === true ? "Providing a financial advice service" : null,
    clientMoneyPropertyService: formData.clientMoneyPropertyService === true ? "Providing a client money or property service" : null,
    tradingFinancialProducts: formData.tradingFinancialProducts === true ? "Trading in financial products on behalf of other persons." : null,
    registeredBankRBNZ: formData.registeredBankRBNZ === true ? "The Investor is a registered bank under the Reserve Bank of New Zealand Act 2010." : null,
    NBDTInvestor: formData.NBDTInvestor === true ? "The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013." : null,
    licensedIssuerInsurance: formData.licensedIssuerInsurance === true ? "The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010." : null,
    registeredSchemeManager: formData.registeredSchemeManager === true ? "The Investor is the manager of a registered scheme, or a discretionary investment management service, that holds a market services licence under the Financial Markets Conduct Act 2013." : null,
    derivativesIssuer: formData.derivativesIssuer === true ? "The Investor is a derivatives issuer that holds a market services license under the Financial Markets Conduct Act 2013." : null,
    FinancialAdvisor: formData.FinancialAdvisor === true ? "The Investor is a Financial Adviser" : null,
    ownedPortfolio2yrs: formData.ownedPortfolio2yrs === true ? "The investor owns, or at any time during the 2-year period immediately before this certificate is given has owned, a portfolio of financial products (other than membership of a retirement scheme or financial products issued by an associate) of a value of at least $1 million (in aggregate)." : null,
    carriedTransactions2yrs: formData.carriedTransactions2yrs === true ? "The investor has, during the 2-year period immediately before this certificate is given, carried out one or more transaction(s) to acquire financial products (other than membership of a retirement scheme or financial products issued by an associate) where the amount payable under those transactions (in aggregate) is at least $1 million." : null,
    investmentBusiness10yrs: formData.investmentBusiness10yrs === true ? "The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business." : null,
    equitySecurities: formData.equitySecurities === true ? "Equity securities (includes listed shares)" : null,
    managedFunds: formData.managedFunds === true ? "Managed funds" : null,
    derivativeInstruments: formData.derivativeInstruments === true ? "Derivative instruments" : null,
    debtSecurities: formData.debtSecurities === true ? "Debt securities" : null,
    startupOrBusiness: formData.startupOrBusiness === true ? "I/We previously invested in early stage, start up, or established businesses" : null,
    investorPresentation: formData.investorPresentation === true ? "In the course of it’s investment activities, I/We have been involved in investor presentations and/or due diligence meetings and through this have gained an appreciation for the risks and opportunities associated with investing in businesses." : null,
    myOwnInvestmentDecisions: formData.myOwnInvestmentDecisions === true ? "I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio." : null,
    valueAndRisksOfInvesting: formData.valueAndRisksOfInvesting === true ? "In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products." : null,

    capitalRaisingDirector: formData.capitalRaisingDirector === true ? "I/We have served as a Director of a business that was involved in capital raising" : null,
    investingSharesSecurities: formData.investingSharesSecurities === true ? "I/We have experience in investing in shares and other securities (listed and unlisted) and understand the risks with investing in different types of financial products." : null,
    acquiringDisposingFinancialProductsOther: formData.acquiringDisposingFinancialProductsOther,
    acquiringDisposingFinancialProducts: formData.acquiringDisposingFinancialProducts === true ? "I/We have other relevant experience of acquiring or disposing of financial products, details being the following." : null,
    none_certificate: formData.investmentBusinessNone,
    none_certificate_1: formData.investmentCriteriaNone,
    largePersonNone: formData.largePersonNone,

    adherenceSign:
      formData.mySignaturejointHolderDetailsUrl !== null &&
        formData.mySignaturejointHolderDetailsUrl !== '' &&
        formData.mySignaturejointHolderDetailsUrl !== 'undefined' &&
        formData.mySignaturejointHolderDetailsUrl !== undefined
        ? formData.mySignaturejointHolderDetailsUrl
        : '',
    subscriptionSign:
      formData.signatureSubscriptionUrljoint !== null &&
        formData.signatureSubscriptionUrljoint !== '' &&
        formData.signatureSubscriptionUrljoint !== 'undefined' &&
        formData.signatureSubscriptionUrljoint !== undefined
        ? formData.signatureSubscriptionUrljoint
        : '',

    signatureA:
      formData.secSignatureCUrl !== null &&
        formData.secSignatureCUrl !== '' &&
        formData.secSignatureCUrl !== 'undefined' &&
        formData.secSignatureCUrl !== undefined
        ? formData.secSignatureCUrl
        : formData.signatureA1Url !== null &&
          formData.signatureA1Url !== '' &&
          formData.signatureA1Url !== 'undefined' &&
          formData.signatureA1Url !== undefined
          ? formData.signatureA1Url
          : '',
  };

  const timeout = 900000;
  const handleOnIdle = () => {
    application4();

  };

  const { } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  });
  const namefull = formData.fullName ? formData.fullName.split(' ') : ''

  formData.firstName = namefull[0];
  if (namefull.length > 1) {
    formData.lastName = namefull[namefull.length - 1];
  }
  if (namefull.length > 2) {
    formData.middleName = namefull
      .slice(1, namefull.length - 1)
      .join()
      .replace(/,/g, ' ');
  }
  const { go } = navigation;
  function HandleSecond(e) {
    if (formData.investmentType === 'Joint') {
      formData.class = 'Jointhird';
      go('Jointhird');
    }

    formData.class = 'Second';
    go('Second');
  }
  // useEffect(()=>{
  //   if(queryValue){
  //     formData.class = 'Five';
  //     go('Five');
  //   }
  // },[])

  function HandleThird(e) {
    if (formData.investmentType === 'Joint') {
      formData.class = 'Third';
      go('Jointfour')
    } else if (formData.investmentType === 'Company') {
      formData.class = 'Third';
      go('CompanyThird')
    } else if (formData.investmentType === 'Trust') {
      formData.class = 'Third';
      go('ThirdTrust')
    } else {
      formData.class = 'Third';
      go('Third');

    }

  }
  function HandleEight(e) {
    formData.class = 'Eight';

    formData.investmentType === 'Joint' ? go('Jointtwelve') : go('Eight');

  }
  function handleFive(e) {
    formData.class = 'Five';

    formData.investmentType === 'Joint' ? go('Jointeight') : go('Five');


    //   go("Indentity")
  }
  function HandleNine(e) {
    formData.class = 'Nine';

    formData.investmentType === 'Joint' ? go('Nine') : go('Nine');

  }
  function Handletwentyone(e) {
    formData.class = 'Twentyone';
    go('Twentyone');
  }

  function Handletwentyone11(e) {
    formData.class = 'Twentyone11';
    go('Twentyone11');
  }
  const llogggout = () => {

    if (formData.applicationStage === "In Progress") {
      application4()

    }
    else {

      logout()
    }
  }
  const [isActive, setActive] = useState('false');

  // const handleToggle = useMemo(() => setActive(!isActive), [])

  const handleToggle = () => {
    setActive(!isActive)
  };

  function HandleJointThird() {

    if (formData.investmentType == "Joint") {
      formData.class = 'Jointhird';
      go('Jointhird');
    }
  }

  function HandleEntityOne() {
    if (formData.investmentType == "Company") {
      formData.class = 'Jointhird';
      go('EntityOne')
    }
  }

  function HandleTrustEntityOne() {
    if (formData.investmentType == "Trust") {
      formData.class = 'Jointhird';
      go('TrustFirst')
    }
  }

  // tick functionality

  const {
    isNZBank,
  } = formData;

  let {
    firstTick,
    secondTick,
    secondTick2,
    secondTick3,
    thirdTick,
    fourthTick,
    fifthTick,
    sixthTick,
    seventhTick,
    eightTick,
    nineTick,
    elevenTick
  } = false;

  //  for second page
  const [right, setRight] = useState(false);
  const sss = () => {
    // console.log("formData.totalInvestment123", formData.totalInvestment)
    if (
      formData.investmentType !== null &&
      formData.investmentType !== '' &&
      formData.investmentType !== 'undefined' &&
      formData.investmentType !== undefined &&
      formData.investmentType !== 'Select'
      // &&
      // formData.fundCode !== null &&
      // formData.fundCode !== '' &&
      // formData.fundCode !== 'undefined' &&
      // formData.fundCode !== undefined &&
      // formData.fundCode !== 'Select' &&
      // formData.totalInvestment !== null &&
      // formData.totalInvestment !== '' &&
      // formData.totalInvestment !== 'undefined' &&
      // formData.totalInvestment !== undefined
      // &&
      // formData.totalInvestment.replace(/,/g, '') >= "1000000"
      // // formData.totalInvestment.replace(/,/g, '') >="250000"
      // && (formData.totalInvestment.replace(/,/g, '') % 50000 === 0)
    ) {

      firstTick = true;
      setRight(true);
      $('#first').removeClass("fillColor");
      formData.firstTick = true
    }
    else {
      formData.firstTick = false
    }
  };
  //for company entity details
  const [companyentity, setCompanyentity] = useState(false);

  const companyentitydetails = () => {
    if (formData.companyName !== null && formData.companyName !== '' && formData.companyName !== undefined
      && formData.companyNumber !== null && formData.companyNumber !== '' && formData.companyNumber !== undefined
      // && formData.companyTradingName !== null && formData.companyTradingName !== '' && formData.companyTradingName !== undefined
      && formData.companyRegisterAddress !== null && formData.companyRegisterAddress !== '' && formData.companyRegisterAddress !== undefined && formData.companyRegisterAddress !== 'undefined'
      && formData.companyAddressProof !== null && formData.companyAddressProof !== '' && formData.companyAddressProof !== undefined
      // && (formData.entityCompanyOne == 'false' ?
      //   formData.otherCountryCompanyRegistered !== "" && formData.otherCountryCompanyRegistered !== undefined && formData.otherCountryCompanyRegistered !== "undefined" && formData.otherCountryCompanyRegistered !== null
      //   && formData.fullLegalCompanyName !== "" && formData.fullLegalCompanyName !== undefined && formData.fullLegalCompanyName !== "undefined" && formData.fullLegalCompanyName !== null :
      //   formData.otherCountryCompanyRegistered == "" || formData.otherCountryCompanyRegistered == undefined || formData.otherCountryCompanyRegistered == null ||
      //   formData.fullLegalCompanyName == "" || formData.fullLegalCompanyName == undefined || formData.fullLegalCompanyName == null)

      // && ((formData.entityCompanyOne == 'true' && formData.addnumber == true) ?
      //   formData.companySecCountryOfResidence !== "" && formData.companySecCountryOfResidence !== undefined && formData.companySecCountryOfResidence !== "undefined" && formData.companySecCountryOfResidence !== null
      //   && formData.secFullLegalCompanyName !== "" && formData.secFullLegalCompanyName !== undefined && formData.secFullLegalCompanyName !== "undefined" && formData.secFullLegalCompanyName !== null :
      //   formData.companySecCountryOfResidence == "" || formData.companySecCountryOfResidence == undefined || formData.companySecCountryOfResidence == null ||
      //   formData.secFullLegalCompanyName == "" || formData.secFullLegalCompanyName == undefined || formData.secFullLegalCompanyName == null)

      && formData.companyIrdNumber !== null && formData.companyIrdNumber !== '' && formData.companyIrdNumber !== undefined

      && formData.rwtComp !== null && formData.rwtComp !== "" && formData.rwtComp !== "Select"
      && (formData.rwtComp === "Resident Withholding Tax" || formData.rwtComp === "1" ? formData.rwtValueComp !== null && formData.rwtValueComp !== "" && formData.rwtValueComp !== undefined && formData.rwtValueComp !== "Select" : formData.rwtComp !== null && formData.rwtComp !== "" && formData.rwtComp !== "Select")

      && (formData.isCompanyOhterCountryCitizen === "true" || formData.isCompanyOhterCountryCitizen === true ?
        formData.companyCountryOfResidence !== null && formData.companyCountryOfResidence !== "" && formData.companyCountryOfResidence !== "undefined" && formData.companyCountryOfResidence !== undefined &&
        // formData.companyTinReason !== null && formData.companyTinReason !== "" && formData.companyTinReason !== "undefined" && formData.companyTinReason !== undefined &&
        formData.companyTinNumber !== null && formData.companyTinNumber !== "" && formData.companyTinNumber !== "undefined" && formData.companyTinNumber !== undefined
        :
        formData.companyCountryOfResidence === null || formData.companyCountryOfResidence === "" || formData.companyCountryOfResidence === "undefined" || formData.companyCountryOfResidence === undefined ||
        // formData.companyTinReason === null || formData.companyTinReason === "" || formData.companyTinReason === "undefined" || formData.companyTinReason === undefined ||
        formData.companyTinNumber === null || formData.companyTinNumber === "" || formData.companyTinNumber === "undefined" || formData.companyTinNumber === undefined
      )
      && (formData.isCompanyIndividualOperating == "true" ?
        formData.individualFullName !== null && formData.individualFullName !== "" && formData.individualFullName !== undefined && formData.individualFullName !== 'undefined'
        && formData.IndividualEmail !== null && formData.IndividualEmail !== "" && formData.IndividualEmail !== undefined && formData.IndividualEmail !== 'undefined'
        && formData.IndividualRole !== null && formData.IndividualRole !== "" && formData.IndividualRole !== undefined && formData.IndividualRole !== 'undefined'
        :
        formData.individualFullName == null || formData.individualFullName !== "" || formData.individualFullName !== undefined || formData.individualFullName !== 'undefined' ||
        formData.IndividualEmail == null || formData.IndividualEmail !== "" || formData.IndividualEmail !== undefined || formData.IndividualEmail !== 'undefined' ||
        formData.IndividualRole == null || formData.IndividualRole !== "" || formData.IndividualRole !== undefined || formData.IndividualRole !== 'undefined'
      )
      && (formData.isOtherCompanyIndividual == "true" ?
        formData.secondaryIndividualFullName !== null && formData.secondaryIndividualFullName !== "" && formData.secondaryIndividualFullName !== undefined && formData.secondaryIndividualFullName !== 'undefined'
        && formData.SecondaryIndividualEmail !== null && formData.SecondaryIndividualEmail !== "" && formData.SecondaryIndividualEmail !== undefined && formData.SecondaryIndividualEmail !== 'undefined'
        && formData.secondaryIndividualRole !== null && formData.secondaryIndividualRole !== "" && formData.secondaryIndividualRole !== undefined && formData.secondaryIndividualRole !== 'undefined'
        :
        formData.secondaryIndividualFullName == null || formData.secondaryIndividualFullName !== "" || formData.secondaryIndividualFullName !== undefined || formData.secondaryIndividualFullName !== 'undefined' ||
        formData.SecondaryIndividualEmail == null || formData.SecondaryIndividualEmail !== "" || formData.SecondaryIndividualEmail !== undefined || formData.SecondaryIndividualEmail !== 'undefined' ||
        formData.secondaryIndividualRole == null || formData.secondaryIndividualRole !== "" || formData.secondaryIndividualRole !== undefined || formData.secondaryIndividualRole !== 'undefined'
      )
      // &&
      //  formData.companyFundReason !== null && formData.companyFundReason !== '' && formData.companyFundReason !== undefined && formData.companyFundReason !== 'undefined'
      // && formData.companyGenerateIncome !== null && formData.companyGenerateIncome !== '' && formData.companyGenerateIncome !== undefined && formData.companyGenerateIncome !== 'undefined'

      && formData.companyFundsDocument !== null && formData.companyFundsDocument !== '' && formData.companyFundsDocument !== undefined
      && ((formData.rwtValueComp === 'Exempt' && formData.rwtComp == 'Resident Withholding Tax') ? formData.companyExamptProof !== null && formData.companyExamptProof !== '' && formData.companyExamptProof !== undefined :
        formData.companyExamptProof == null || formData.companyExamptProof == '' || formData.companyExamptProof == undefined || formData.companyExamptProof == "undefined")

    ) {
      secondTick2 = true;
      setCompanyentity(true);
      $('#second2').removeClass("fillColor");
      formData.secondTick2 = true
    } else {
      formData.secondTick2 = false
    }
  }

  //for trust Details

  const [trustdetails, setTrustdetails] = useState(false);
  const trustydetails = () => {
    if (
      formData.trustName !== null && formData.trustName !== "" && formData.trustName !== undefined &&
      ((formData.manuallyAddressTrust !== 'false' && formData.isPrimaryResdAddressTrust !== null && formData.isPrimaryResdAddressTrust !== "") ||
        (formData.manuallyAddressTrust == 'false' && formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined)
      ) && formData.trust_type !== "Select" && formData.trust_type !== null &&
      formData.TrustIrdNumber !== "" && formData.TrustIrdNumber !== undefined && formData.TrustIrdNumber !== null
      && formData.trustIrdNumberProof !== "" && formData.trustIrdNumberProof !== undefined && formData.trustIrdNumberProof !== null


      && formData.rwtTrust !== null && formData.rwtTrust !== "" && formData.rwtTrust !== "Select"
      && (formData.rwtTrust === "Resident Withholding Tax" || formData.rwtTrust === "1" ? formData.rwtValueTrust !== null && formData.rwtValueTrust !== "" && formData.rwtValueTrust !== undefined && formData.rwtValueTrust !== "Select" : formData.rwtTrust !== null && formData.rwtTrust !== "" && formData.rwtTrust !== "Select")

      && formData.trustDeedProof !== null && formData.trustDeedProof !== '' && formData.trustDeedProof !== undefined
      && formData.trustAddressProof !== null && formData.trustAddressProof !== '' && formData.trustAddressProof !== undefined
      && formData.trustSourceOfFundsProof !== null && formData.trustSourceOfFundsProof !== '' && formData.trustSourceOfFundsProof !== undefined


      && (formData.isTrustOhterCountryCitizen === "true" ?
        formData.trustCountryOfResidence !== null && formData.trustCountryOfResidence !== "" && formData.trustCountryOfResidence !== "undefined" && formData.trustCountryOfResidence !== undefined &&
        formData.trustTinNumber !== null && formData.trustTinNumber !== "" && formData.trustTinNumber !== "undefined" && formData.trustTinNumber !== undefined
        :
        formData.trustCountryOfResidence === null || formData.trustCountryOfResidence === "" || formData.trustCountryOfResidence === "undefined" || formData.trustCountryOfResidence === undefined ||
        formData.trustTinNumber === null || formData.trustTinNumber === "" || formData.trustTinNumber === "undefined" || formData.trustTinNumber === undefined
      ) && formData.tick == "true"
    ) {
      secondTick3 = true;
      setTrustdetails(true);
      $('#second3').removeClass("fillColor");
      formData.secondTick3 = true
    } else {
      formData.secondTick3 = false
    }
  }
  // console.log(formData.tick, 'formData.tick')
  // for joint entity details
  const [jointthirdentity, setJointthirdentity] = useState(false);
  const jointentitydetailsss = () => {
    if (
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.fullName !== 'undefined' &&
      formData.fullName !== undefined &&
      formData.secondholder !== null &&
      formData.secondholder !== '' &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined &&
      formData.email1 !== null && formData.email1 !== "" && formData.email1 !== "undefined"
      && formData.email1 !== undefined
    ) {

      secondTick = true;
      setJointthirdentity(true);
      $('#second').removeClass("fillColor");
      formData.secondTick = true
    }
    else {
      formData.secondTick = false
    }
  };

  // for personal information

  const [personal, setPersonal] = useState(false);
  let IndividualDOB = sessionStorage.getItem('dateOfBirth');
  let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
  let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
  let PostalAddress2 = sessionStorage.getItem('PostalAddress2');

  const IndividualPersonal = () => {

    if (
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.codeVerify !== 'false' &&
      IndividualDOB !== null &&
      IndividualDOB !== '' &&
      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' &&
          formData.isPrimaryResdAddress !== undefined && formData.manuallyAddress !== 'false') ||
        (formData.manuallyAddress == 'false' && formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined)
      )
      &&
      formData.priContactNumber !== null && formData.priContactNumber !== undefined &&
      formData.priContactNumber !== '' &&
      formData.placeOfBirth !== null &&
      formData.placeOfBirth !== '' &&
      formData.countryOfBirth !== null &&
      formData.countryOfBirth !== '' &&
      formData.residingCountry !== null &&
      formData.residingCountry !== '' &&
      formData.userIrdNumber !== null &&
      formData.userIrdNumber !== '' &&
      formData.userIrdNumberProof !== null && formData.userIrdNumberProof !== undefined && formData.userIrdNumberProof !== '' &&
      formData.rwt !== null &&
      formData.rwt !== '' &&
      formData.rwt !== 'Select' &&
      ((formData.rwt === 'Resident Withholding Tax'
        ? formData.rwtValue !== null &&
        formData.rwtValue !== '' &&
        formData.rwtValue !== 'Select' &&
        (formData.rwtValue == 'Exempt' && formData.rwt == 'Resident Withholding Tax'
          ? formData.examptProof !== '' &&
          formData.examptProof !== null &&
          formData.examptProof !== 'undefined' &&
          formData.examptProof !== undefined
          : formData.rwtValue !== 'Select' &&
          formData.rwtValue !== undefined &&
          formData.rwtValue !== '' &&
          formData.rwtValue !== null)
        : formData.rwt !== null && formData.rwt !== '' && formData.rwt !== 'Select') ||
        formData.rwt === 'Non Resident Withholding Tax' ||
        formData.rwt === 'AIL 2%') &&

      (formData.isUserAddressSame === 'false'
        ? formData.isPrimaryPostalAdress !== null &&
        formData.isPrimaryPostalAdress !== '' &&
        formData.isPrimaryPostalAdress !== 'undefined' &&
        formData.isPrimaryPostalAdress !== undefined
        : formData.isPrimaryPostalAdress === null ||
        formData.isPrimaryPostalAdress === '' ||
        formData.isPrimaryPostalAdress === 'undefined' ||
        formData.isPrimaryPostalAdress === undefined)

    ) {

      thirdTick = true;
      setPersonal(true);
      $('#third').removeClass('fillColor');
      formData.thirdTick = true;
    }

    else {
      formData.thirdTick = false;
    }

  };

  // for Joint Personal Information

  const [jointpersonal, setJointpersonal] = useState(false);
  let jointDOB = sessionStorage.getItem('dateOfBirth2');
  let residentialAddress2 = sessionStorage.getItem('residentialAddress2');

  const jointPersonalinformation = () => {
    if (
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.secondholder !== null &&
      formData.secondholder !== '' &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined &&
      formData.codeVerify !== 'false' &&
      IndividualDOB !== null &&
      IndividualDOB !== '' &&
      jointDOB !== null &&
      jointDOB !== '' &&

      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' && formData.manuallyAddress !== 'false') ||
        (formData.manuallyAddress == 'false' && formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined)) &&
      (
        (formData.isSecondryResdAddress !== '' &&
          formData.isSecondryResdAddress !== null &&
          formData.isSecondryResdAddress !== 'undefined' && formData.manuallyAddress2 !== 'false') || (formData.manuallyAddress2 == 'false' && formData.street_number3 !== null &&
            formData.street_number3 !== '' &&
            formData.street_number3 !== undefined &&
            formData.city3 !== null &&
            formData.city3 !== '' &&
            formData.city3 !== undefined &&
            formData.postalCode3 !== null &&
            formData.postalCode3 !== '' &&
            formData.postalCode3 !== undefined
        )) &&
      formData.priContactNumber !== null && formData.priContactNumber !== undefined &&
      formData.priContactNumber !== '' &&
      formData.priContactNumber2 !== null && formData.priContactNumber2 !== undefined &&
      formData.priContactNumber2 !== '' &&

      formData.placeOfBirth !== null &&
      formData.placeOfBirth !== '' &&
      formData.placeOfBirth2 !== null &&
      formData.placeOfBirth2 !== '' &&
      formData.placeOfBirth2 !== 'undefined' &&
      formData.placeOfBirth2 !== undefined &&
      formData.countryOfBirth !== null &&
      formData.countryOfBirth !== '' &&
      formData.countryOfBirth2 !== null &&
      formData.countryOfBirth2 !== '' &&
      formData.countryOfBirth2 !== undefined &&
      formData.countryOfBirth2 !== 'undefined' &&
      formData.residingCountry !== null &&
      formData.residingCountry !== '' &&
      formData.residingCountry2 !== null &&
      formData.residingCountry2 !== '' &&
      formData.residingCountry2 !== undefined &&
      formData.residingCountry2 !== 'undefined' &&
      formData.userIrdNumber !== null &&
      formData.userIrdNumber !== '' &&
      formData.userIrdNumberProof !== null && formData.userIrdNumberProof !== undefined && formData.userIrdNumberProof !== '' &&
      formData.userIrdNumberProof2 !== null && formData.userIrdNumberProof2 !== undefined && formData.userIrdNumberProof2 !== '' &&

      formData.userIrdNumber2 !== null &&
      formData.userIrdNumber2 !== '' &&
      formData.userIrdNumber2 !== undefined &&
      formData.userIrdNumber2 !== 'undefined' &&
      formData.rwt !== null &&
      formData.rwt !== '' &&
      formData.rwt !== 'Select' &&
      formData.rwt !== undefined &&

      formData.rwt2 !== null &&
      formData.rwt2 !== 'Select' &&
      formData.rwt2 !== '' &&
      formData.rwt2 !== 'undefined' &&
      formData.rwt2 !== undefined &&
      ((formData.rwt === 'Resident Withholding Tax'
        ? formData.rwtValue !== null &&
        formData.rwtValue !== '' &&
        formData.rwtValue !== 'Select' &&
        (formData.rwtValue == 'Exempt' && formData.rwt == 'Resident Withholding Tax'
          ? formData.examptProof !== '' &&
          formData.examptProof !== null &&
          formData.examptProof !== 'undefined' &&
          formData.examptProof !== undefined
          : formData.rwtValue !== 'Select' &&
          formData.rwtValue !== undefined &&
          formData.rwtValue !== '' &&
          formData.rwtValue !== null)
        : formData.rwt !== null && formData.rwt !== '' && formData.rwt !== 'Select') ||
        formData.rwt === 'Non Resident Withholding Tax' ||
        formData.rwt === 'AIL 2%')

      &&
      ((formData.rwt2 === 'Resident Withholding Tax'
        ? formData.rwtValue2 !== null &&
        formData.rwtValue2 !== '' &&
        formData.rwtValue2 !== 'Select' &&
        (formData.rwtValue2 == 'Exempt' && formData.rwt2 == 'Resident Withholding Tax'
          ? formData.examptProof2 !== '' &&
          formData.examptProof2 !== null &&
          formData.examptProof2 !== 'undefined' &&
          formData.examptProof2 !== undefined
          : formData.rwtValue2 !== 'Select' &&
          formData.rwtValue2 !== undefined &&
          formData.rwtValue2 !== '' &&
          formData.rwtValue2 !== null)
        : formData.rwt2 !== null && formData.rwt2 !== '' && formData.rwt2 !== 'Select') ||
        formData.rwt2 === 'Non Resident Withholding Tax' ||
        formData.rwt2 === 'AIL 2%')
      &&
      ((formData.isUserAddressSame === 'false' || formData.isUserAddressSame === false)
        ? formData.isPrimaryPostalAdress !== null &&
        formData.isPrimaryPostalAdress !== '' &&
        formData.isPrimaryPostalAdress !== 'undefined' &&
        formData.isPrimaryPostalAdress !== undefined
        : formData.isPrimaryPostalAdress === null ||
        formData.isPrimaryPostalAdress === '' ||
        formData.isPrimaryPostalAdress === 'undefined' ||
        formData.isPrimaryPostalAdress === undefined) &&
      (formData.isUserAddressSame2 === 'false'
        ? formData.isSecondryPostalAdre !== null &&
        formData.isSecondryPostalAdre !== '' &&
        formData.isSecondryPostalAdre !== 'undefined' &&
        formData.isSecondryPostalAdre !== undefined
        : formData.isSecondryPostalAdre === null ||
        formData.isSecondryPostalAdre === '' ||
        formData.isSecondryPostalAdre === 'undefined' ||
        formData.isSecondryPostalAdre === undefined)
    ) {
      fourthTick = true;
      setJointpersonal(true);
      $('#third').removeClass('fillColor');
      formData.fourthTick = true;
      formData.thirdTick = true;
    } else {
      formData.fourthTick = false;
    }
  };

  // for Identity Verification

  const [identity, setIdentity] = useState(false);
  let IndividualExpiryDate = sessionStorage.getItem("documentExpiryDates");
  const IndividualIdentityVerify = () => {
    if (
      (formData.idType !== null &&
        formData.idType !== '' &&
        formData.biometric !== 'Biometric' &&
        formData.documentNumber &&
        IndividualExpiryDate !== null &&
        IndividualExpiryDate !== '' &&
        formData.fullName !== null &&
        formData.fullName !== '' &&
        formData.lastName !== null &&
        formData.lastName !== '' &&

        (formData.idType === '1' ||
          formData.idType === '2' ||
          formData.idType === '' ||
          formData.idType === null

          ? formData.documentProof !== null &&
          formData.documentProof !== '' &&
          formData.documentProof !== 'undefined'

          : formData.documentProof !== null &&
          formData.documentProof !== '' &&
          formData.documentProof !== 'undefined' &&
          formData.otherDocumentName !== '' &&
          formData.otherDocumentName !== null) &&

        (formData.idType === '1' ?
          (formData.documentVersionNumber !== null && formData.documentVersionNumber !== undefined && formData.documentVersionNumber !== '') : (formData.documentCountryOfIssue !== null && formData.documentCountryOfIssue !== '' && formData.documentCountryOfIssue !== undefined))) ||
      formData.biometricComplete === true
    ) {
      fifthTick = true;
      setIdentity(true);
      $('#fifth').removeClass("fillColor");
      formData.fifthTick = true
    }
    else {
      formData.fifthTick = false
    }
  };

  // for joint Identity Verification
  let JointExpiryDate = sessionStorage.getItem("documentExpiryDates1");
  const [jointIdentity, setJointIdentity] = useState(false);

  const JointIdentityVerify = () => {
    if ((((formData.idType !== null && formData.idType !== "" && formData.biometric !== "Biometric") &&
      formData.documentNumber && IndividualExpiryDate !== null && IndividualExpiryDate !== "" && formData.fullName !== null && formData.fullName !== "" &&
      (formData.idType === "1" || formData.idType === "2" || formData.idType === "" || formData.idType === null ?
        formData.documentProof !== null && formData.documentProof !== "" && formData.documentProof !== "undefined" : formData.documentProof !== null && formData.documentProof !== "" && formData.documentProof !== "undefined"
        && formData.otherDocumentName !== '' && formData.otherDocumentName !== null) &&
      (formData.idType === "1" ? (formData.documentVersionNumber !== null && formData.documentVersionNumber !== "") : (formData.documentCountryOfIssue !== null && formData.documentCountryOfIssue !== "")
      )
    ) || (formData.biometricComplete === true || formData.biometricComplete === "undefined"))
      &&
      (((formData.idType2 !== null && formData.idType2 !== "" && formData.biometric2 !== "Biometric") &&
        formData.documentNumber2 && JointExpiryDate !== null && JointExpiryDate !== "" && formData.secondholder !== null && formData.secondholder !== "" &&
        (formData.idType2 === "1" || formData.idType2 === "2" || formData.idType2 === "" || formData.idType2 === null ?
          formData.documentProof2 !== null && formData.documentProof2 !== "" && formData.documentProof2 !== "undefined" : formData.documentProof2 !== null && formData.documentProof2 !== "" && formData.documentProof2 !== "undefined" && formData.otherDocumentName2 !== "" && formData.otherDocumentName2 !== null)

        &&
        (formData.idType2 === "1" ? (formData.documentVersionNumber2 !== null && formData.documentVersionNumber2 !== undefined && formData.documentVersionNumber2 !== "") :
          (formData.documentCountryOfIssue2 !== null && formData.documentCountryOfIssue2 !== "" && formData.documentCountryOfIssue2 !== undefined && formData.documentCountryOfIssue2 !== "undefined")
        )
      ) || (formData.biometricComplete1 === true))
    ) {
      sixthTick = true;
      setJointIdentity(true);
      $('#fifth').removeClass("fillColor");
      formData.sixthTick = true
      formData.fifthTick = true
    }
    else {

      formData.sixthTick = false
    }
  };

  // for Bank Details
  const [bank, setBank] = useState(false);
  const IndividualBank = () => {
    if (
      formData.isNZBank !== '' &&
      formData.isNZBank !== null &&
      (formData.isNZBank === 'true' || formData.isNZBank === null || typeof formData.isNZBank === 'undefined'
        ? formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" && formData.primaryBankAccountName !== 'undefined' && formData.primaryBankAccountName !== undefined &&
        formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "" && formData.primaryBankAccountNumber !== "undefined" && formData.primaryBankAccountNumber !== undefined &&
        formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== undefined
        && formData.primaryBankProof !== "undefined"
        : formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" &&
        formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "" &&
        formData.bankSwiftCode !== null && formData.bankSwiftCode !== "undefined" && formData.bankSwiftCode !== "" && formData.bankSwiftCode !== undefined &&
        formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== undefined
        && formData.primaryBankProof !== "undefined"
      )
    ) {
      seventhTick = true;
      $('#seven').removeClass("fillColor");
      formData.seventhTick = true

      setBank(true);
    }
    else {
      formData.seventhTick = false
    }
  };

  // for joint Bank details

  const [jointBank, setJointBank] = useState(false);

  const jointBankdetails = () => {
    if (
      formData.isNZBank12 === "false" ?
        (formData.isNZBank !== '' &&
          formData.isNZBank !== null &&
          ((formData.isNZBank === 'true' || formData.isNZBank === null || typeof formData.isNZBank === 'undefined'
            ? formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" &&
            formData.primaryBankAccountName !== "undefined" && formData.primaryBankAccountName !== undefined &&
            formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" &&
            formData.primaryBankAccountNumber !== "" &&
            formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
            formData.primaryBankProof !== undefined
            : formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" &&
            formData.primaryBankAccountName !== "undefined" && formData.primaryBankAccountName !== undefined &&
            formData.primaryBankAccountNumber !== "" && formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" &&
            formData.bankSwiftCode !== null && formData.bankSwiftCode !== "undefined" && formData.bankSwiftCode !== "" && formData.bankSwiftCode !== undefined &&
            formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
            formData.primaryBankProof !== undefined)
            &&
            (formData.isNZBank2 === 'true' || formData.isNZBank2 === null || typeof formData.isNZBank2 === 'undefined'
              ?
              formData.primaryBankAccountName2 !== null && formData.primaryBankAccountName2 !== "" && formData.primaryBankAccountName2 !== "undefined"
              && formData.primaryBankAccountName2 !== undefined &&
              formData.primaryBankAccountNumber2 !== null && formData.primaryBankAccountNumber2 !== "" && formData.primaryBankAccountNumber2 !== "undefined" &&
              formData.primaryBankAccountNumber2 !== undefined &&
              formData.primaryBankProof2 !== null && formData.primaryBankProof2 !== "" && formData.primaryBankProof2 !== "undefined" &&
              formData.primaryBankProof2 !== undefined
              : formData.primaryBankAccountName2 !== null && formData.primaryBankAccountName2 !== "" &&
              formData.primaryBankAccountName2 !== "undefined" && formData.primaryBankAccountName2 !== undefined &&
              formData.primaryBankAccountNumber2 !== null && formData.primaryBankAccountNumber2 !== "" && formData.primaryBankAccountNumber2 !== undefined && formData.primaryBankAccountNumber2 !== "" &&
              formData.bankSwiftCode2 !== null && formData.bankSwiftCode2 !== "undefined" &&
              formData.bankSwiftCode2 !== "" && formData.bankSwiftCode2 !== undefined &&
              formData.primaryBankProof2 !== null && formData.primaryBankProof2 !== "" && formData.primaryBankProof2 !== "undefined" &&
              formData.primaryBankProof2 !== undefined)

          )
        ) :
        (formData.isNZBank === 'true' || formData.isNZBank === null || typeof formData.isNZBank === 'undefined'
          ? formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" &&
          formData.primaryBankAccountName !== "undefined" && formData.primaryBankAccountName !== undefined &&
          formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" && formData.primaryBankAccountNumber !== undefined && formData.primaryBankAccountNumber !== ""
          && formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
          formData.primaryBankProof !== undefined
          : formData.primaryBankAccountName !== null &&
          formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" && formData.primaryBankAccountNumber !== undefined && formData.primaryBankAccountNumber !== "" &&
          formData.bankSwiftCode !== null && formData.bankSwiftCode !== "undefined" && formData.bankSwiftCode !== "" && formData.bankSwiftCode !== undefined &&
          formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
          formData.primaryBankProof !== undefined)

    ) {

      eightTick = true;
      setJointBank(true);
      $('#seven').removeClass("fillColor");
      formData.eightTick = true
      formData.seventhTick = true
    }
    else {
      formData.eightTick = false
    }
  };

  // alert(formData.elevenTick)

  // for Wholesale Certification

  // const [wholeSale, setWholeSale] = useState(
  //   );
  let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const wholeSale = (
    formData.usualLegalRules === true &&
      formData.notCompleteInformation === true &&
      // formData.materialExtent10yrs === true &&
      formData.fewerLegalProtection === true &&
      formData.notSuitableInvestment === true &&
      formData.seekFinancialAdvice === true ? true :

      // formData.downsignatureA === ('url').URL ? true :
      formData.signatureA !== null &&
        formData.signatureA !== "" &&
        formData.signatureA !== "undefined" &&
        formData.signatureA !== undefined ? true :
        // formData.downsignatureA === true ? true :
        
        formData.downsignatureA !== null &&
        formData.downsignatureA !== "" &&
        formData.downsignatureA !== "undefined" &&
        formData.downsignatureA !== undefined ? true :

        formData.CertificationValue === true &&
          formData.fullLegalNamesign !== null &&
          formData.fullLegalNamesign !== "" &&
          formData.fullLegalNamesign !== "undefined" &&
          formData.userEmail1 !== null &&
          formData.userEmail1 !== "" &&
          formData.userEmail1 !== "undefined" &&
          // empty !==false && empty!=="undefined"&& empty!==undefined && empty !==null&&
          // (formData.userEmail1.match(emailExpression)) !== null &&
          // (formData.userEmail1.match(emailExpression)) !== "undefined" &&
          formData.rwt12 !== "Select" &&
          formData.rwt12 !== null &&
          formData.rwt12 !== "" ? true : false
  )


  const Certification = () => {
    // if (formData.isPrimary == 0) {
    //   nineTick = true;
    //   $('#nine').removeClass("fillColor");
    //   formData.nineTick = true;
    // }
    // else {
    if (formData.CertificationValue == true || wholeSale == true) {
      nineTick = true;
      $('#nine').removeClass("fillColor");
      formData.nineTick = true;
    }
    else {
      nineTick = false;
      formData.nineTick = false;
    }
    // }
console.log('formData.nineTick',formData.nineTick)
  };
  // for Terms and Condition
  const [termcondi, setTermcondi] = useState(false);

  const TermsAndCondition = () => {
    if (
      formData.termsss1 !== false &&
      formData.termsss1 !== false &&
      formData.termsss1 !== undefined &&
      formData.termsss1 !== null &&
      formData.termsss2 !== false &&
      formData.termsss2 !== false &&
      formData.termsss2 !== undefined &&
      formData.termsss2 !== null
      // formData.termsss3 !== false &&
      // formData.termsss3 !== false &&
      // formData.termsss3 !== undefined &&
      // formData.termsss3 !== null
    ) {
      elevenTick = true;
      setTermcondi(true);
      $('#eleven').removeClass('fillColor');
      formData.elevenTick = true;
    } else {
      formData.elevenTick = false;
    }
  };

  const finalTick = () => {
    if ((firstTick && thirdTick && fifthTick && seventhTick && nineTick && elevenTick && secondTick2)) {
      formData.tickCount = 7;
    } else if ((firstTick && thirdTick && fifthTick && seventhTick && nineTick && elevenTick && secondTick3)) {
      formData.tickCount = 7;
    }
    else if (
      firstTick &&
      secondTick &&
      // secondTick2 &&
      fourthTick &&
      sixthTick &&
      eightTick &&
      nineTick &&
      elevenTick
    ) {
      formData.tickCount = 8;
    }
    else if ((firstTick && thirdTick && fifthTick && seventhTick && nineTick && elevenTick)) {
      formData.tickCount = 7;
    }
    else {
      formData.tickCount = 0;
    }
  };



  const captureComplete = async () => {
    const Bio = await axios
      .get(`${Biometricverify}/get/idScan/${formData.Capture}`)
      .then((Bio) => {
        formData.biometricComplete =
          Bio !== 'undefined' &&
            Bio !== undefined &&
            Bio.data.idscan !== 'undefined' &&
            Bio.data.idscan !== undefined &&
            Bio.data.idscan.completed !== 'undefined'
            ? Bio.data.idscan.completed
            : '';
        if (formData.biometricComplete === true) {
          IndividualIdentityVerify();
          formData.fifthTick = true;
          formData.repeatOff = true;
        }
      });
    console.log(Bio);
  };

  const captureCompletejoint = async () => {
    const Bio = await axios
      .get(`${Biometricverify}/get/idScan/${formData.Capture2}`)
      .then((Bio) => {
        formData.biometricComplete1 =
          Bio !== 'undefined' &&
            Bio !== undefined &&
            Bio.data.idscan !== 'undefined' &&
            Bio.data.idscan !== undefined &&
            Bio.data.idscan.completed !== 'undefined'
            ? Bio.data.idscan.completed
            : '';
        if (formData.biometricComplete1 === true) {
          JointIdentityVerify();
          // setJointIdentity(true);
          formData.sixthTick = true;
          formData.repeatOff2 = true;
        }
      });
    console.log(Bio);
  };
  useEffect(() => {
    if (formData.Capture2 !== null && formData.Capture2 !== "" && formData.Capture2 !== "undefined") {
      formData.repeatOff2 = false
    }
    if (formData.Capture !== null && formData.Capture !== "" && formData.Capture !== "undefined") {
      formData.repeatOff = false
    }

    sss();
    companyentitydetails();
    trustydetails();
    if (formData.investmentType === "Joint") {

      jointPersonalinformation();
      JointIdentityVerify();
      jointBankdetails();
      jointentitydetailsss();

    }

    else {

      IndividualPersonal();
      IndividualIdentityVerify();
      IndividualBank();
    }
    Certification();
    TermsAndCondition();
    finalTick();
  }, []);



  useEffect(() => {
    if (formData.repeatOff === false) {
      if (formData.biometric === "Biometric" && (formData.biometricComplete !== true)) {

        captureComplete()
      }
    }

    if (formData.repeatOff2 === false) {
      if (formData.investmentType === "Joint" && formData.biometric2 === "Biometric" && (formData.biometricComplete1 !== true)) {

        captureCompletejoint()
      }
    }
  })
  const [loader, setLoader] = useState();

  const application4 = async () => {
    let IndividualExpiryDate = sessionStorage.getItem('documentExpiryDates');
    let JointExpiryDate = sessionStorage.getItem('documentExpiryDates1');
    let IndividualDOB = sessionStorage.getItem('dateOfBirth');
    let jointDOB = sessionStorage.getItem('dateOfBirth2');
    let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
    let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
    let residentialAddress2 = sessionStorage.getItem('residentialAddress2');
    let PostalAddress2 = sessionStorage.getItem('PostalAddress2');
    let city = sessionStorage.getItem('locality');
    let postal_code = sessionStorage.getItem('postal_code');
    let country = sessionStorage.getItem('country');
    let region = sessionStorage.getItem('sublocality_level_1');
    let route = sessionStorage.getItem('route');
    let street_number = sessionStorage.getItem('street_number');

    let city2 = sessionStorage.getItem('locality1');
    let postal_code2 = sessionStorage.getItem('postal_code1');
    let country2 = sessionStorage.getItem('country1');
    let region2 = sessionStorage.getItem('sublocality_level_11');
    let route2 = sessionStorage.getItem('route1');
    let street_number2 = sessionStorage.getItem('street_number1');

    let postdata = {

      companyDetails: {
        companyName: formData.companyName !== null && formData.companyName !== "" && formData.companyName !== 'undefined' ? formData.companyName : null,
        companyNumber: formData.companyNumber !== null && formData.companyNumber !== "" && formData.companyNumber !== 'undefined' ? formData.companyNumber : null,
        companyTradingName: formData.companyTradingName !== null && formData.companyTradingName !== "" && formData.companyTradingName !== 'undefined' ? formData.companyTradingName : null,
        companyRegisterAddress: formData.companyRegisterAddress !== null && formData.companyRegisterAddress !== "" && formData.companyRegisterAddress !== 'undefined' ? formData.companyRegisterAddress : null,
        companyAddressProof: formData.companyAddressProof !== null && formData.companyAddressProof !== "" && formData.companyAddressProof !== 'undefined' ? formData.companyAddressProof : null,
        fullLegalCompanyName: formData.fullLegalCompanyName !== null && formData.fullLegalCompanyName !== "" && formData.fullLegalCompanyName !== 'undefined' ? formData.fullLegalCompanyName : null,
        otherCountryCompanyRegistered: formData.otherCountryCompanyRegistered !== null && formData.otherCountryCompanyRegistered !== "" && formData.otherCountryCompanyRegistered !== 'undefined' ? formData.otherCountryCompanyRegistered : null,
        secFullLegalCompanyName: formData.secFullLegalCompanyName !== null && formData.secFullLegalCompanyName !== "" && formData.secFullLegalCompanyName !== 'undefined' ? formData.secFullLegalCompanyName : null,

        isotherCompanyRegister: formData.isotherCompanyRegister != null &&
          formData.isotherCompanyRegister != 'undefined' &&
          formData.isotherCompanyRegister != ''
          ? formData.isotherCompanyRegister == 'true'
            ? true
            : false
          : false,

        entityCompanyOne:
          formData.entityCompanyOne != null &&
            formData.entityCompanyOne != 'undefined' &&
            formData.entityCompanyOne != ''
            ? formData.entityCompanyOne == 'true'
              ? false
              : true
            : false,
        // entityCompanyOne:formData.entityCompanyOne==true?"true":false,
        isHoldingPersonalAssets: formData.isHoldingPersonalAssets != null &&
          formData.isHoldingPersonalAssets != 'undefined' &&
          formData.isHoldingPersonalAssets != ''
          ? formData.isHoldingPersonalAssets == 'true'
            ? true
            : false
          : false,
        isCompanyIndividualOperating: formData.isCompanyIndividualOperating != null &&
          formData.isCompanyIndividualOperating != 'undefined' &&
          formData.isCompanyIndividualOperating != ''
          ? formData.isCompanyIndividualOperating == 'true'
            ? true
            : false
          : false,
        isOtherCompanyIndividual: formData.isOtherCompanyIndividual != null &&
          formData.isOtherCompanyIndividual != 'undefined' &&
          formData.isOtherCompanyIndividual != ''
          ? formData.isOtherCompanyIndividual == 'true'
            ? true
            : false
          : false,

        individualFullName: formData.individualFullName !== null && formData.individualFullName !== "" && formData.individualFullName !== 'undefined' ? formData.individualFullName : null,
        IndividualRole: formData.IndividualRole !== null && formData.IndividualRole !== "" && formData.IndividualRole !== 'undefined' ? formData.IndividualRole : null,
        IndividualEmail: formData.IndividualEmail !== null && formData.IndividualEmail !== "" && formData.IndividualEmail !== 'undefined' ? formData.IndividualEmail : null,
        secondaryIndividualFullName: formData.secondaryIndividualFullName !== null && formData.secondaryIndividualFullName !== "" && formData.secondaryIndividualFullName !== 'undefined' ? formData.secondaryIndividualFullName : null,
        secondaryIndividualRole: formData.secondaryIndividualRole !== null && formData.secondaryIndividualRole !== "" && formData.secondaryIndividualRole !== 'undefined' ? formData.secondaryIndividualRole : null,
        SecondaryIndividualEmail: formData.SecondaryIndividualEmail !== null && formData.SecondaryIndividualEmail !== "" && formData.SecondaryIndividualEmail !== 'undefined' ? formData.SecondaryIndividualEmail : null,
        companySecCountryOfResidence: formData.companySecCountryOfResidence !== null && formData.companySecCountryOfResidence !== "" && formData.companySecCountryOfResidence !== 'undefined' ? formData.companySecCountryOfResidence : null,
        companyIrdNumber: formData.companyIrdNumber !== null && formData.companyIrdNumber !== "" && formData.companyIrdNumber !== 'undefined' ? formData.companyIrdNumber : null,

        companyTaxType: formData.rwtComp,
        companyRwtValue: formData.rwtComp === 'Resident Withholding Tax' ? formData.rwtValueComp : null,
        companyExamptProof:
          formData.companyExamptProof !== null &&
            formData.companyExamptProof !== '' &&
            formData.companyExamptProof !== 'undefined'
            ? formData.companyExamptProof
            : null,
        companyTaxExemption: formData.checkboxComp === true ? true : false,
        companyCountryOfResidence:
          formData.companyCountryOfResidence != null && formData.companyCountryOfResidence != ''
            ? formData.companyCountryOfResidence
            : null,

        companyTinNumber: formData.companyTinNumber !== null && formData.companyTinNumber !== "" && formData.companyTinNumber !== 'undefined' ? formData.companyTinNumber : null,
        companyTinReason: formData.companyTinReason !== null && formData.companyTinReason !== "" && formData.companyTinReason !== 'undefined' ? formData.companyTinReason : null,

        isCompanyOhterCountryCitizen:
          formData.isCompanyOhterCountryCitizen != null &&
            formData.isCompanyOhterCountryCitizen != 'undefined' &&
            formData.isCompanyOhterCountryCitizen != ''
            ? formData.isCompanyOhterCountryCitizen == 'true'
              ? true
              : false
            : false,

        companyFundReason: formData.companyFundReason !== null && formData.companyFundReason !== "" && formData.companyFundReason !== 'undefined' ? formData.companyFundReason : null,
        companyGenerateIncome: formData.companyGenerateIncome !== null && formData.companyGenerateIncome !== "" && formData.companyGenerateIncome !== 'undefined' ? formData.companyGenerateIncome : null,
        companyFundsDocument: formData.companyFundsDocument !== null && formData.companyFundsDocument !== "" && formData.companyFundsDocument !== 'undefined' ? formData.companyFundsDocument : null,

        companyShareholderDetails: formData.inputList !== null && formData.inputList !== "" && formData.inputList !== undefined ? formData.inputList : [],

        companyDirectorDetails: formData.inputData !== null && formData.inputData !== "" && formData.inputData !== undefined && formData.inputData !== "undefined" ? formData.inputData : ""

      },
      trustDetails: {
        trustName: formData.trustName !== null && formData.trustName !== '' && formData.trustName != undefined ? formData.trustName : null,
        trustcountryOfIncorporation: formData.trustcountryOfIncorporation !== null && formData.trustcountryOfIncorporation !== '' && formData.trustcountryOfIncorporation != undefined ? formData.trustcountryOfIncorporation : null,
        trustAddressProof: formData.trustAddressProof !== null && formData.trustAddressProof !== '' && formData.trustAddressProof != undefined ? formData.trustAddressProof : null,
        trustRegisterAddress: formData.trustRegisterAddress !== null && formData.trustRegisterAddress !== '' && formData.trustRegisterAddress != undefined ? formData.trustRegisterAddress : null,
        trustType: formData.trust_type !== null && formData.trust_type !== '' && formData.trust_type != undefined ? formData.trust_type : null,
        trustTypeOther: formData.trust_type_other !== null && formData.trust_type_other !== '' && formData.trust_type_other != undefined ? formData.trust_type_other : null,
        trustDeedProof: formData.trustDeedProof !== null && formData.trustDeedProof !== '' && formData.trustDeedProof != undefined ? formData.trustDeedProof : null,
        trustIrdNumber: formData.TrustIrdNumber !== null && formData.TrustIrdNumber !== '' && formData.TrustIrdNumber != undefined ? formData.TrustIrdNumber : null,
        trustIrdNumberProof: formData.trustIrdNumberProof !== null && formData.trustIrdNumberProof !== '' && formData.trustIrdNumberProof != undefined ? formData.trustIrdNumberProof : null,

        isTrustCitizen: formData.isTrustOhterCountryCitizen != null &&
          formData.isTrustOhterCountryCitizen != 'undefined' &&
          formData.isTrustOhterCountryCitizen != '' ?
          formData.isTrustOhterCountryCitizen == 'true' ? true : false : false,

        trustTaxType: formData.rwtTrust,
        trustRwtValue: formData.rwtTrust === 'Resident Withholding Tax' ? formData.rwtValueTrust : null,
        trustExamptProof: formData.trustExamptProof !== null && formData.trustExamptProof !== '' && formData.trustExamptProof != undefined ? formData.trustExamptProof : null,
        trustTaxExemption: formData.checkboxTrust === true ? true : false,

        trustCountryOfResidence: formData.trustCountryOfResidence,
        trustTinNumber: formData.trustTinNumber != null && formData.trustTinNumber != "" && formData.trustTinNumber != "undefined" ? formData.trustTinNumber : null,
        trustTinReason: formData.trustTinReason != null && formData.trustTinReason != "" && formData.trustTinReason != "undefined" ? formData.trustTinReason : null,

        trustFundReason: formData.trustFundReason !== null && formData.trustFundReason !== '' && formData.trustFundReason != undefined ? formData.trustFundReason : null,
        trustsettlorFundsGensettlorFundsGen: formData.trustFundReason !== null && formData.trustFundReason !== '' && formData.trustFundReason != undefined ? formData.trustsettlorFund : null,
        trustSourceOfIncome: formData.trustGenerateIncome !== null && formData.trustGenerateIncome !== '' && formData.trustGenerateIncome != undefined ? formData.trustGenerateIncome : null,
        trustSourceOfFundsProof: formData.trustSourceOfFundsProof !== null && formData.trustSourceOfFundsProof !== '' && formData.trustSourceOfFundsProof != undefined ? formData.trustSourceOfFundsProof : null,
        trustIndividualDetails: formData.inputDataList !== null && formData.inputDataList !== "" && formData.inputDataList !== undefined ? formData.inputDataList : null,

        trustCompanyName: formData.optionsd !== null && formData.optionsd !== '' && formData.optionsd != undefined ? formData.optionsd : null,
        trustDirectorDetails: [
          {
            "fullLegalName": "sanjai daji",
            "isCompanyDirectorYou": 0,
            "userEmail": "abcsdcsd@gmIK.co"
          }
        ]
      },

      step: '',
      applicationStage: formData.applicationStage,
      investmentType:
        formData.investmentType != null && formData.investmentType != 'Select'
          ? formData.investmentType
          : null,
      // occupation: formData.occupation != null ? formData.occupation : null,
      fundCode: formData.fundCode != null ? formData.fundCode : null,
      totalInvestment: formData.totalInvestment != null ? formData.totalInvestment : null,
      fullLegalName:
        formData.fullName != null && formData.fullName != '' && formData.fullName != 'undefined'
          ? formData.fullName
          : (formData.firstNameindi != null &&
            formData.firstNameindi != '' &&
            formData.firstNameindi != 'undefined'
            ? formData.firstNameindi
            : formData.firstName != null &&
              formData.firstName != '' &&
              formData.firstName != 'undefined'
              ? formData.firstName
              : '') +
          ' ' +
          (formData.middleNameindi != null &&
            formData.middleNameindi != '' &&
            formData.middleNameindi != 'undefined'
            ? formData.middleNameindi
            : formData.middleName != null &&
              formData.middleName != '' &&
              formData.middleName != 'undefined'
              ? formData.middleName
              : '') +
          '' +
          (formData.lastNameindi != null &&
            formData.lastNameindi != '' &&
            formData.lastNameindi != 'undefined'
            ? ' ' + formData.lastNameindi
            : formData.lastName != null &&
              formData.lastName != '' &&
              formData.lastName != 'undefined'
              ? ' ' + formData.lastName
              : ''),
      preferredName: formData.preferredName != null ? formData.preferredName : null,
      dateOfBirth: IndividualDOB !== null && IndividualDOB !== '' ? IndividualDOB : null,

      userResidentialAddress: formData.manuallyAddress == 'false' ?
        (formData.street_number +
          (formData.region != null && formData.region != undefined && formData.region != '' ? ', ' + formData.region : '') +
          (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
          (formData.postalCode != null && formData.postalCode != ''
            ? ', ' + formData.postalCode
            : '') +
          (formData.country != null && formData.country != '' ? ',' + formData.country : '')) :
        formData.isPrimaryResdAddress,

      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,

      isUserAddressSame:
        formData.isUserAddressSame === 'true' ||
          (formData.isUserAddressSame === '' && formData.isUserAddressSame === 'undefined') ||
          formData.isUserAddressSame === undefined
          ? 1
          : 0,

      // userPostalAddress: PostalAddress1 != null && PostalAddress1 != '' ? PostalAddress1 : null,
      userPostalAddress: formData.isPrimaryPostalAdress != null && formData.isPrimaryPostalAdress != '' ? formData.isPrimaryPostalAdress : null,

      priContactType:
        formData.priContactType === 'Mobile' ||
          formData.priContactType === '' ||
          formData.priContactType === 'undefined' ||
          formData.priContactType === undefined ||
          formData.priContactType === null
          ? 'Mobile'
          : formData.priContactType,
      priContactCountryCode:
        formData.priContactCountryCode === undefined ||
          formData.priContactCountryCode === 'undefined' ||
          formData.priContactCountryCode === null
          ? '64'
          : formData.priContactCountryCode,
      priContactNumber: formData.codeVerify === 'true' ? formData.priContactNumber : null,
      isPrimaryBank: true,
      isPriContactNumberVerified: formData.codeVerify === 'true' ? 1 : 0,
      isAlternateContact: formData.isAlternateContact,
      secContactType:
        formData.isAlternateContact == false
          ? null
          : formData.secContactType !== 'undefined' &&
            formData.secContactType !== null &&
            formData.secContactType !== ''
            ? formData.secContactType
            : 'Home',
      secContactCountryCode:
        formData.isAlternateContact == false
          ? null
          : formData.secContactCountryCode === undefined ||
            formData.secContactCountryCode === null ||
            formData.secContactCountryCode === 'undefined'
            ? '64'
            : formData.secContactCountryCode,
      secContactNumber: formData.isAlternateContact == false ? null : formData.secContactNumber,
      // occupation: formData.occupation != null ? formData.occupation : null,
      otherOccupation: formData.otherOccupation != null ? formData.otherOccupation : null,
      placeOfBirth: formData.placeOfBirth != null ? formData.placeOfBirth : null,
      countryOfBirth: formData.countryOfBirth != null ? formData.countryOfBirth : null,
      residingCountry: formData.residingCountry != null ? formData.residingCountry : null,
      userIrdNumber: formData.userIrdNumber != null ? formData.userIrdNumber : null,
      nominatedUserType: formData.rwt12 != null ? formData.rwt12 : null,
      nominatedUserName: formData.fullLegalNamesign != null ? formData.fullLegalNamesign : null,
      nominatedUserEmail: formData.userEmail1 != null ? formData.userEmail1 : null,

      userIrdNumberProof: formData.userIrdNumberProof !== null &&
        formData.userIrdNumberProof !== undefined &&
        formData.userIrdNumberProof !== '' ? formData.userIrdNumberProof : null,
      taxType: formData.rwt,
      rwtValue: formData.rwt === 'Resident Withholding Tax' ? formData.rwtValue : null,
      examptProof:
        formData.examptProof !== null &&
          formData.examptProof !== '' &&
          formData.examptProof !== 'undefined'
          ? formData.examptProof
          : null,
      taxExemption: formData.checkboxx === true ? true : false,
      addressDetails: {
        suburb:
          formData.region != null && formData.region != '' && formData.region != 'undefined'
            ? formData.region
            : region,
        addressLine1:
          (formData.street_number != null &&
            formData.street_number != 'undefined' &&
            formData.street_number != ''
            ? formData.street_number
            : street_number != null && street_number != 'undefined'
              ? street_number
              : '') +
          ' ' +
          (route == null ? '' : route),
        postalCode:
          formData.postalCode != null &&
            formData.postalCode != 'undefined' &&
            formData.postalCode != ''
            ? formData.postalCode
            : postal_code != null && postal_code != 'undefined' && postal_code != ''
              ? postal_code
              : '',
        city:
          formData.city != null && formData.city != 'undefined' && formData.city != ''
            ? formData.city
            : city,
        countryCode:
          formData.countryyCode != null &&
            formData.countryyCode != 'undefined' &&
            formData.countryyCode != ''
            ? formData.countryyCode
            : 'NZ',
        country:
          formData.country != null && formData.country != 'undefined' && formData.country != ''
            ? formData.country
            : country,
        // isPrimary: true,
        // isActive: true
      },
      isOtherCitizen: formData.isPostalAdd11 === 'false' ? 1 : 0,

      tinDetails: [
        {
          tinId:
            formData.tinId === '' ||
              formData.tinId === null ||
              formData.tinId === 'undefined' ||
              formData.tinId === undefined
              ? null
              : formData.tinId,
          countryOfResidence:
            formData.tinResidential != null && formData.tinResidential != ''
              ? formData.tinResidential
              : null,
          tinNumber:
            formData.tinNumber != null && formData.tinNumber != '' ? formData.tinNumber : null,
          tinreason:
            formData.tinreason != null && formData.tinreason != '' ? formData.tinreason : null
        }
      ],
      captureReference: formData.Capture != null ? formData.Capture : null,
      identificationType: formData.biometric != null ? formData.biometric : null,
      documentIdType:
        formData.biometric == 'Biometric'
          ? ''
          : formData.idType === '1' ||
            formData.idType === '' ||
            formData.idType === 'undefined' ||
            formData.idType === undefined ||
            formData.idType === null
            ? 'NZ Driver Licence'
            : formData.idType === '2'
              ? 'NZ Passport'
              : formData.idType === '3'
                ? 'other'
                : null,
      firstName:
        formData.firstName != null && formData.firstName != '' && formData.firstName != 'undefined'
          ? formData.firstName
          : formData.firstNameindi != '' &&
            formData.firstNameindi != null &&
            formData.firstNameindi != 'undefined'
            ? formData.firstNameindi
            : null,
      middleName:
        formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName
            : '',
      lastName:
        formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined'
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined'
            ? formData.lastName
            : '',
      documentProof:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentProof != null
            ? formData.documentProof
            : null,
      documentNumber:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentNumber != null
            ? formData.documentNumber
            : null,
      documentExpiryDate:
        formData.biometric == 'Biometric'
          ? null
          : IndividualExpiryDate != null && IndividualExpiryDate != ''
            ? IndividualExpiryDate
            : null,
      documentVersionNumber:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentVersionNumber != null
            ? formData.documentVersionNumber
            : null,
      documentCountryOfIssue:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentCountryOfIssue != null
            ? formData.documentCountryOfIssue
            : null,
      otherDocumentName:
        formData.biometric == 'Biometric'
          ? null
          : formData.otherDocumentName != null
            ? formData.otherDocumentName
            : null,
      primaryBankAccountName:
        formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
      primaryBankName:
        formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
      primaryBankAccountNumber:
        formData.primaryBankAccountNumber != null ? formData.primaryBankAccountNumber : null,
      primaryBankProof: formData.primaryBankProof != null ? formData.primaryBankProof : null,
      bankSwiftCode:
        formData.isNZBank === 'undefined' || formData.isNZBank === 'true'
          ? null
          : formData.bankSwiftCode != null
            ? formData.bankSwiftCode
            : null,
      entityName: `${formData.fullName}`,
      isPrimaryBank:
        formData.isNZBank12 === undefined ||
          formData.isNZBank12 === null ||
          formData.isNZBank12 === 'true'
          ? true
          : false,
      isNZBank: formData.isNZBank === 'undefined' || formData.isNZBank === 'true' ? true : false,
      certificateDetails: certificateData
    };

    let jointData = {
      applicationStage: formData.applicationStage,
      investmentType:
        formData.investmentType != null && formData.investmentType != 'Select'
          ? formData.investmentType
          : null,
      // occupation: formData.occupation != null ? formData.occupation : null,
      fundCode: formData.fundCode != null ? formData.fundCode : null,
      totalInvestment: formData.totalInvestment != null ? formData.totalInvestment : null,
      primaryShareOfIncome:
        formData.shareholdingPercentage !== null &&
          formData.shareholdingPercentage !== '' &&
          formData.shareholdingPercentage !== 'undefined' &&
          formData.shareholdingPercentage !== undefined
          ? formData.shareholdingPercentage
          : 50,
      fullLegalName:
        (formData.firstNameindi != null &&
          formData.firstNameindi != '' &&
          formData.firstNameindi != 'undefined'
          ? formData.firstNameindi
          : formData.firstName != null &&
            formData.firstName != '' &&
            formData.firstName != 'undefined'
            ? formData.firstName
            : '') +
        ' ' +
        (formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName + ' '
            : '') +
        '' +
        (formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined'
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined'
            ? formData.lastName
            : ''),
      preferredName: formData.preferredName != null ? formData.preferredName : null,
      dateOfBirth: IndividualDOB != null && IndividualDOB != '' ? IndividualDOB : null,
      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,

      userResidentialAddress: formData.manuallyAddress == 'false' ?
        (formData.street_number +
          (formData.region != null && formData.region != undefined && formData.region != '' ? ', ' + formData.region : '') +
          (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
          (formData.postalCode != null && formData.postalCode != ''
            ? ', ' + formData.postalCode
            : '') +
          (formData.country != null && formData.country != '' ? ',' + formData.country : '')) :
        formData.isPrimaryResdAddress,
      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,

      isUserAddressSame:
        formData.isUserAddressSame === 'true' ||
          formData.isUserAddressSame === '' ||
          formData.isUserAddressSame === 'undefined' ||
          formData.isUserAddressSame === undefined
          ? 1
          : 0,
      // userPostalAddress: PostalAddress1 != null && PostalAddress1 != '' ? PostalAddress1 : null,
      userPostalAddress: formData.isPrimaryPostalAdress != null && formData.isPrimaryPostalAdress != '' ? formData.isPrimaryPostalAdress : null,

      priContactType:
        formData.priContactType === 'Mobile' ||
          formData.priContactType === '' ||
          formData.priContactType === 'undefined' ||
          formData.priContactType === undefined ||
          formData.priContactType === null
          ? 'Mobile'
          : formData.priContactType,
      priContactCountryCode:
        formData.priContactCountryCode === undefined ||
          formData.priContactCountryCode === 'undefined' ||
          formData.priContactCountryCode === null
          ? '64'
          : formData.priContactCountryCode,
      priContactNumber: formData.codeVerify === 'true' ? formData.priContactNumber : null,
      isPriContactNumberVerified: formData.codeVerify === 'true' ? 1 : 0,
      isAlternateContact: formData.isAlternateContact,
      secContactType:
        formData.isAlternateContact == false
          ? null
          : formData.secContactType !== 'undefined' &&
            formData.secContactType !== null &&
            formData.secContactType !== ''
            ? formData.secContactType
            : 'Home',
      secContactCountryCode:
        formData.isAlternateContact == false
          ? null
          : formData.secContactCountryCode === undefined ||
            formData.secContactCountryCode === null ||
            formData.secContactCountryCode === 'undefined'
            ? '64'
            : formData.secContactCountryCode,
      secContactNumber: formData.isAlternateContact == false ? null : formData.secContactNumber,
      // occupation: formData.occupation != null ? formData.occupation : null,
      otherOccupation: formData.otherOccupation != null ? formData.otherOccupation : null,
      placeOfBirth: formData.placeOfBirth != null ? formData.placeOfBirth : null,
      countryOfBirth: formData.countryOfBirth != null ? formData.countryOfBirth : null,
      residingCountry: formData.residingCountry != null ? formData.residingCountry : null,
      userIrdNumber: formData.userIrdNumber != null ? formData.userIrdNumber : null,
      userIrdNumberProof: formData.userIrdNumberProof !== null &&
        formData.userIrdNumberProof !== undefined &&
        formData.userIrdNumberProof !== '' ? formData.userIrdNumberProof : null,

      // nrwt: formData.rwt === 'Non Resident Withholding Tax' ? true : false,
      // AIL: formData.rwt === 'AIL 2%' ? true : false,
      // rwt: formData.rwt === 'Resident Withholding Tax' ? true : false,
      taxType: formData.rwt,
      rwtValue: formData.rwt === 'Resident Withholding Tax' ? formData.rwtValue : null,
      examptProof:
        formData.examptProof !== null &&
          formData.examptProof !== '' &&
          formData.examptProof !== 'undefined'
          ? formData.examptProof
          : null,
      taxExemption: formData.checkboxx === true ? true : false,
      addressDetails: {
        suburb:
          formData.region != null && formData.region != '' && formData.region != 'undefined'
            ? formData.region
            : region2,
        addressLine1:
          (formData.street_number != null &&
            formData.street_number != 'undefined' &&
            formData.street_number != ''
            ? formData.street_number
            : street_number2 != null && street_number2 != 'undefined'
              ? street_number2
              : '') +
          ' ' +
          (route2 == null ? '' : route2),
        postalCode:
          formData.postalCode != null &&
            formData.postalCode != 'undefined' &&
            formData.postalCode != ''
            ? formData.postalCode
            : postal_code2 != null && postal_code2 != 'undefined' && postal_code2 != ''
              ? postal_code2
              : '',
        city:
          formData.city != null && formData.city != 'undefined' && formData.city != ''
            ? formData.city
            : city2,
        countryCode:
          formData.countryyCode != null &&
            formData.countryyCode != 'undefined' &&
            formData.countryyCode != ''
            ? formData.countryyCode
            : 'NZ',
        country:
          formData.country != null && formData.country != 'undefined' && formData.country != ''
            ? formData.country
            : country2,
        // isPrimary: true,
        // isActive: true
      },
      isOtherCitizen: formData.isPostalAdd11 === 'false' ? 1 : 0,
      tinDetails: [
        {
          tinId:
            formData.tinId === '' ||
              formData.tinId === null ||
              formData.tinId === 'undefined' ||
              formData.tinId === undefined
              ? null
              : formData.tinId,
          countryOfResidence:
            formData.tinResidential != null && formData.tinResidential != ''
              ? formData.tinResidential
              : null,
          tinNumber:
            formData.tinNumber != null && formData.tinNumber != '' ? formData.tinNumber : null,
          tinreason:
            formData.tinreason != null && formData.tinreason != '' ? formData.tinreason : null
        }
      ],
      captureReference: formData.Capture != null ? formData.Capture : null,
      identificationType: formData.biometric != null ? formData.biometric : null,
      documentIdType:
        formData.biometric == 'Biometric'
          ? null
          : formData.idType === '1' ||
            formData.idType === '' ||
            formData.idType === 'undefined' ||
            formData.idType === undefined ||
            formData.idType === null
            ? 'NZ Driver Licence'
            : formData.idType === '2'
              ? 'NZ Passport'
              : formData.idType === '3'
                ? 'other'
                : null,
      firstName:
        formData.firstName != null && formData.firstName != '' && formData.firstName != 'undefined'
          ? formData.firstName
          : formData.firstNameindi != '' &&
            formData.firstNameindi != null &&
            formData.firstNameindi != 'undefined'
            ? formData.firstNameindi
            : null,
      middleName:
        formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName
            : '',
      lastName:
        formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined' &&
          formData.lastNameindi != undefined
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined' && formData.lastName != undefined
            ? formData.lastName
            : '',
      documentNumber: formData.biometric == 'Biometric' ? null : formData.documentNumber,
      documentExpiryDate:
        formData.biometric == 'Biometric'
          ? null
          : IndividualExpiryDate != null && IndividualExpiryDate != ''
            ? IndividualExpiryDate
            : null,
      documentVersionNumber:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentVersionNumber != null
            ? formData.documentVersionNumber
            : null,
      documentProof:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentProof != null
            ? formData.documentProof
            : null,
      documentCountryOfIssue:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentCountryOfIssue != null
            ? formData.documentCountryOfIssue
            : null,
      otherDocumentName:
        formData.biometric == 'Biometric'
          ? null
          : formData.otherDocumentName != null
            ? formData.otherDocumentName
            : null,
      primaryBankAccountName:
        formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
      primaryBankName:
        formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
      primaryBankAccountNumber:
        formData.primaryBankAccountNumber != null ? formData.primaryBankAccountNumber : null,
      primaryBankProof: formData.primaryBankProof != null ? formData.primaryBankProof : null,
      bankSwiftCode:
        formData.isNZBank === 'undefined' || formData.isNZBank === 'true'
          ? null
          : formData.bankSwiftCode != null
            ? formData.bankSwiftCode
            : null,
      entityName: `${formData.fullName} & ${formData.secondholder}`,
      isNZBank: formData.isNZBank === 'undefined' || formData.isNZBank === 'true' ? true : false,
      isPrimaryBank:
        formData.isNZBank12 === undefined ||
          formData.isNZBank12 === null ||
          formData.isNZBank12 === 'true'
          ? true
          : false,
      certificateDetails: certificateData,

      // secondary

      secondaryDetails: {
        applicationStage: formData.applicationStage,
        investmentType:
          formData.investmentType != null && formData.investmentType != 'Select'
            ? formData.investmentType
            : '',
        // occupation: formData.occupation != null ? formData.occupation : null,
        fundCode: formData.fundCode != null ? formData.fundCode : null,
        totalInvestment: formData.totalInvestment != null ? formData.totalInvestment : null,
        SecondaryShareOfIncome:
          formData.shareholdingPercentage != undefined ? 100 - formData.shareholdingPercentage : 50,
        fullLegalName:
          formData.secondholder != null &&
            formData.secondholder != '' &&
            formData.secondholder != 'undefined'
            ? formData.secondholder
            : '',
        preferredName: formData.preferredName2 != null ? formData.preferredName2 : null,
        dateOfBirth: jointDOB != null ? jointDOB : null,
        userResidentialAddress:
          formData.manuallyAddress2 == 'false'
            ? (formData.street_number1 +
              (formData.region1 != '' && formData.region1 != null && formData.region1 != undefined ? ', ' + formData.region1 : '') +
              (formData.city1 != '' ? ', ' + formData.city1 : '') +
              (formData.postalCode1 != null ? ', ' + formData.postalCode1 : '') +
              (formData.country1 != '' ? ', ' + formData.country1 : ''))
            : formData.isSecondryResdAddress,
        isUserAddressSame:
          formData.isUserAddressSame2 === 'true' ||
            formData.isUserAddressSame2 === '' ||
            formData.isUserAddressSame2 === 'undefined' ||
            formData.isUserAddressSame2 === undefined
            ? 1
            : 0,
        userPostalAddress: formData.isSecondryPostalAdre != null && formData.isSecondryPostalAdre != '' ? formData.isSecondryPostalAdre : null,
        isGoogleAddress:
          formData.manuallyAddress2 != null &&
            formData.manuallyAddress2 != 'undefined' &&
            formData.manuallyAddress2 != ''
            ? formData.manuallyAddress2 == 'true' || formData.manuallyAddress2 == true
              ? true
              : formData.manuallyAddress2 == 'false' || formData.manuallyAddress2 == false
                ? false
                : true
            : true,
        priContactType:
          formData.priContactType2 === 'Mobile' ||
            formData.priContactType2 === '' ||
            formData.priContactType2 === 'undefined' ||
            formData.priContactType2 === undefined ||
            formData.priContactType2 === null
            ? 'Mobile'
            : formData.priContactType2,
        priContactCountryCode:
          formData.priContactCountryCode2 === undefined ||
            formData.priContactCountryCode2 === 'undefined' ||
            formData.priContactCountryCode2 === null
            ? '64'
            : formData.priContactCountryCode2,
        priContactNumber: formData.priContactNumber2,
        isAlternateContact: formData.isAlternateContact1,
        secContactType:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactType2 !== 'undefined' &&
              formData.secContactType2 !== '' &&
              formData.secContactType2 !== null
              ? formData.secContactType2
              : 'Home',
        secContactCountryCode:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactCountryCode2 === undefined ||
              formData.secContactCountryCode2 === null ||
              formData.secContactCountryCode2 === 'undefined'
              ? '64'
              : formData.secContactCountryCode2,
        secContactNumber: formData.isAlternateContact1 == false ? null : formData.secContactNumber2,

        // occupation: formData.occupation2 != null ? formData.occupation2 : null,
        otherOccupation: formData.otherOccupation2 != null ? formData.otherOccupation2 : null,
        placeOfBirth: formData.placeOfBirth2 != null ? formData.placeOfBirth2 : null,
        countryOfBirth: formData.countryOfBirth2 != null ? formData.countryOfBirth2 : null,
        residingCountry: formData.residingCountry2 != null ? formData.residingCountry2 : null,
        userIrdNumber: formData.userIrdNumber2 != null ? formData.userIrdNumber2 : null,
        userIrdNumberProof: formData.userIrdNumberProof2 !== null &&
          formData.userIrdNumberProof2 !== undefined &&
          formData.userIrdNumberProof2 !== '' ? formData.userIrdNumberProof2 : null,

        // nrwt: formData.rwt2 === 'Non Resident Withholding Tax' ? true : false,
        // AIL: formData.rwt2 === 'AIL 2%' ? true : false,
        // rwt: formData.rwt2 === 'Resident Withholding Tax' ? true : false,

        taxType: formData.rwt2,
        rwtValue: formData.rwt2 === 'Resident Withholding Tax' ? formData.rwtValue2 : null,
        examptProof:
          formData.examptProof2 !== null &&
            formData.examptProof2 !== '' &&
            formData.examptProof2 !== 'undefined'
            ? formData.examptProof2
            : null,
        taxExemption: formData.checkboxxjoint2 === true ? true : false,
        captureReference: formData.Capture2 != null ? formData.Capture2 : null,

        addressDetails: {
          suburb:
            formData.region3 != null && formData.region3 != '' && formData.region3 != 'undefined'
              ? formData.region3
              : region,
          addressLine1:
            (formData.street_number3 != null &&
              formData.street_number3 != 'undefined' &&
              formData.street_number3 != ''
              ? formData.street_number3
              : street_number != null && street_number != 'undefined'
                ? street_number
                : '') +
            ' ' +
            (route == null ? '' : route),
          postalCode:
            formData.postalCode3 != null &&
              formData.postalCode3 != 'undefined' &&
              formData.postalCode3 != ''
              ? formData.postalCode3
              : postal_code != null && postal_code != 'undefined' && postal_code != ''
                ? postal_code
                : '',
          city:
            formData.city3 != null && formData.city3 != 'undefined' && formData.city3 != ''
              ? formData.city3
              : city,
          countryCode:
            formData.countryyCode3 != null &&
              formData.countryyCode3 != 'undefined' &&
              formData.countryyCode3 != ''
              ? formData.countryyCode3
              : 'NZ',
          country:
            formData.country3 != null && formData.country3 != 'undefined' && formData.country3 != ''
              ? formData.country3
              : country,
          // isPrimary: true,
          // isActive: true
        },
        isOtherCitizen: formData.isPostalAdd12 === 'false' ? 1 : 0,

        tinDetails: [
          {
            tinId:
              formData.tinId2 === '' ||
                formData.tinId2 === null ||
                formData.tinId2 === 'undefined' ||
                formData.tinId2 === undefined
                ? null
                : formData.tinId2,

            countryOfResidence:
              formData.tinResidential2 != null && formData.tinResidential2 != ''
                ? formData.tinResidential2
                : null,
            tinNumber:
              formData.tinNumber2 != null && formData.tinNumber2 != '' ? formData.tinNumber2 : null,
            tinReason:
              formData.tinReason2 != null && formData.tinReason2 != '' ? formData.tinReason2 : null
          }
        ],
        captureReference: formData.Capture2 != null ? formData.Capture2 : null,
        identificationType: formData.biometric2 != null ? formData.biometric2 : null,
        documentIdType:
          formData.biometric2 == 'Biometric'
            ? ''
            : formData.idType2 === '1' ||
              formData.idType2 === '' ||
              formData.idType2 === 'undefined' ||
              formData.idType2 === undefined ||
              formData.idType2 === null
              ? 'NZ Driver Licence'
              : formData.idType2 === '2'
                ? 'NZ Passport'
                : formData.idType2 === '3'
                  ? 'other'
                  : null,
        firstName:
          formData.firstName1 != null &&
            formData.firstName1 != '' &&
            formData.firstName1 != 'undefined'
            ? formData.firstName1
            : null,
        middleName:
          formData.middleName1 != null &&
            formData.middleName1 != '' &&
            formData.middleName1 != 'undefined'
            ? formData.middleName1
            : null,
        lastName:
          formData.lastName1 != null &&
            formData.lastName1 != '' &&
            formData.lastName1 != 'undefined'
            ? formData.lastName1
            : null,
        documentNumber: formData.biometric2 == 'Biometric' ? null : formData.documentNumber2,
        documentExpiryDate:
          formData.biometric2 == 'Biometric'
            ? null
            : JointExpiryDate != null && JointExpiryDate != ''
              ? JointExpiryDate
              : null,
        documentVersionNumber:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentVersionNumber2 != null
              ? formData.documentVersionNumber2
              : null,
        documentCountryOfIssue:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentCountryOfIssue2 != null
              ? formData.documentCountryOfIssue2
              : null,
        otherDocumentName:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.otherDocumentName2 != null
              ? formData.otherDocumentName2
              : null,
        documentProof:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentProof2 != null
              ? formData.documentProof2
              : null,
        isNZBank:
          formData.isNZBank2 === 'true'
            ? true
            : formData.isNZBank2 === undefined
              ? null
              : formData.isNZBank12 === 'false' && formData.isNZBank12 === 'false'
                ? false
                : true,
        // isNZBank: ,
        isPrimaryBank:
          formData.isNZBank12 === undefined ||
            formData.isNZBank12 === null ||
            formData.isNZBank12 === 'true'
            ? true
            : false,
        primaryBankAccountName:
          formData.primaryBankAccountName2 != null ? formData.primaryBankAccountName2 : null,
        primaryBankName:
          formData.primaryBankAccountName2 != null ? formData.primaryBankAccountName2 : null,
        primaryBankAccountNumber:
          formData.primaryBankAccountNumber2 != null ? formData.primaryBankAccountNumber2 : null,
        primaryBankProof: formData.primaryBankProof2 != null ? formData.primaryBankProof2 : null,
        bankSwiftCode:
          formData.isNZBank2 === 'undefined' || formData.isNZBank2 === 'true'
            ? ''
            : formData.bankSwiftCode2 != null
              ? formData.bankSwiftCode2
              : null,
        entityName: `${formData.fullName} & ${formData.secondholder}`,
        userEmail:
          formData.userEmail === formData.email1
            ? formData.userCode
            : formData.email1 != null && formData.email1 != '' && formData.email1 != 'undefined'
              ? formData.email1
              : formData.userCode,
        certificateDetails: certificateDataSecondary
      }
    };

    formData.applicationStage = 'In Progress';

    if (formData.investmentType === 'Joint') {
      setLoader(true);
      await axios
        .put(`${multisavedata}/onboard/joint`, jointData, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          if (res.status === 200) {
            if (formData.bioVerify == true) {
              sessionStorage.clear();
              localStorage.clear();
              logout();

              setLoader(false);
              formData.bioVerify = '';
            } else {
              sessionStorage.clear();
              localStorage.clear();
              logout();

              setLoader(false);
            }
          } else if (res.status === 202) {
            Swal.fire({
              html: `${res.data.message}`
            });
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
          Swal.fire({
            // icon: 'error',
            // title: 'Oops...',
            html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
            allowOutsideClick: false
          });
        });
    } else if (formData.investmentType === 'Individual') {
      delete postdata.companyDetails;
      delete postdata.trustDetails;
      setLoader(true);
      await axios
        .put(`${multisavedata}/onboard/individual`, postdata, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          if (res.status === 200) {
            if (formData.bioVerify == true) {
              sessionStorage.clear();
              localStorage.clear();
              logout();

              setLoader(false);
              formData.bioVerify = '';
            } else {
              sessionStorage.clear();
              localStorage.clear();
              logout();

              setLoader(false);
            }
          } else if (res.status === 202) {
            Swal.fire({
              html: `${res.data.message}`
            });
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
          Swal.fire({
            // icon: 'error',
            // title: 'Oops...',
            html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
            allowOutsideClick: false
          });
        });
    } else if (formData.investmentType === 'Company') {
      delete postdata.trustDetails;
      setLoader(true);
      await axios
        .put(`${multisavedata}/onboard/company`, postdata, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          if (res.status === 200) {
            if (formData.bioVerify == true) {
              sessionStorage.clear();
              localStorage.clear();
              logout();

              setLoader(false);
              formData.bioVerify = '';
            } else {
              sessionStorage.clear();
              localStorage.clear();
              logout();

              setLoader(false);
            }
          } else if (res.status === 202) {
            Swal.fire({
              html: `${res.data.message}`
            });
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
          Swal.fire({
            // icon: 'error',
            // title: 'Oops...',
            html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
            allowOutsideClick: false
          });
        });
    } else if (formData.investmentType === 'Trust') {

      if (queryValue == null || queryValue == "null" || queryValue == "undefined" || queryValue == undefined || queryValue == "") {
        delete postdata.companyDetails;
        setLoader(true);
        await axios
          .put(`${multisavedata}/onboard/trust`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
                formData.bioVerify = '';
              } else {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      }
    }

    else {
      Swal.fire({
        // icon: 'error',
        // title: 'Oops...',
        text: 'Please select the Investment type'
      });
    }
  };

  useEffect(() => {
    if (formData.submissionStatus == 1) {
      Swal.fire({
        text: 'Your application has already been submitted.',
        allowOutsideClick: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        logout();
        /* Read more about isConfirmed, isDenied below */
        // if (result.isConfirmed) {
        //   Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
        //   Swal.fire('Changes are not saved', '', 'info')
        // }
      });
    }
  }, [])

  let ss = false;
  return (
    <div className={`maisdn ${isActive ? '' : 'is_active'}`}>
      <button onClick={handleToggle}>
        <i class="fa fa-bars" aria-hidden="true"></i>
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div className="left_Menus">
        <div className="left_Menus_Top">
          <div>
            <div class="nav_logo circle-box">
              <ConfigProvider colors={['#D8D8D8', '#D8D8D8', '#D8D8D8']}>
                <Avatar
                  className="avatarname"
                  name={(formData.firstNameindi !== null && formData.firstNameindi !== "" && formData.firstNameindi !== undefined && formData.firstNameindi !== 'undefined' ? formData.firstNameindi : formData.firstName !== null && formData.firstName !== "" && formData.firstName !== "undefined" && formData.firstName !== undefined ? formData.firstName : "") + ' ' + (formData.lastNameindi !== null && formData.lastNameindi !== "" && formData.lastNameindi !== undefined ? formData.lastNameindi : formData.lastName !== null && formData.lastName !== "" && formData.lastName !== "undefined" && formData.lastName !== undefined ? formData.lastName : "")}
                />
              </ConfigProvider>{' '}
            </div>

            <p>
              {(formData.fullName !== "" &&
                formData.fullName !== null &&
                formData.fullName !== undefined &&
                formData.fullName !== 'undefined' ? formData.fullName :
                (formData.firstNameindi !== null &&
                  formData.firstNameindi !== "" &&
                  formData.firstNameindi !== "undefined" &&
                  formData.firstNameindi !== undefined ? formData.firstNameindi :
                  formData.firstName !== null &&
                    formData.firstName !== "" &&
                    formData.firstName !== "undefined"
                    && formData.firstName !== undefined ? formData.firstName : "") + " " + (formData.middleNameindi !== null && formData.middleNameindi !== "" && formData.middleNameindi !== "undefined" && formData.middleNameindi !== undefined ? formData.middleNameindi :
                      formData.middleName !== null && formData.middleName !== "" && formData.middleName !== "undefined" && formData.middleName !== undefined ? formData.middleName + " " : "") + "" + (formData.lastNameindi !== null && formData.lastNameindi !== "" && formData.lastNameindi !== "undefined" && formData.lastNameindi !== undefined ? " " + formData.lastNameindi :
                        formData.lastName !== null && formData.lastName !== "" && formData.lastName !== "undefined" && formData.lastName !== undefined ? " " + formData.lastName : ""))}</p>
            {formData.investmentType == 'Trust' ? <p class="sub-name">{formData.trustName}</p> : ''}

          </div>
          <p className='menuCompanyName text-center'>{formData.investmentType === 'Company' ? formData.companyName : null}</p>

          {/* // ************* */}


          {/* { checkData[0] == "Individual"?
 <>
 <button className={formData.class === 'Second' ? 'active' : null} disabled={formData.unclick} onClick={HandleSecond}>
            <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="first" className='left_menu11'>Investment details</span>
            {right === false ? '' : <i class="fas fa-check"></i>}{' '}
          </button>
          
 </>
          
          : ""}
          {checkData[1] != "Id" ? 
          ""
              : <button onClick={handleFive} disabled={formData.unclick} className={formData.class === 'Five' ? 'active' : null}>
            <i class="fa fa-angle-right" aria-hidden="true"> </i> <span id="fifth" className='left_menu11'> Identity Verification</span>
            {formData.investmentType === 'Joint' ? (
              jointIdentity === false ? null : (
                <i class="fas fa-check"></i>
              )
            ) : identity === false ? null : (
              <i class="fas fa-check"></i>
            )}
          </button>
          } */}

          {/* //******************* */}




          {queryValue != null && queryValue != undefined && queryValue != "" ?
            <>
              {/* <button className={formData.class === 'Second' ? 'active' : null} disabled={formData.unclick} onClick={HandleSecond}>
                <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="first" className='left_menu11'>Investment details</span>
                {right === false ? '' : <i class="fas fa-check"></i>}{' '}
              </button> */}

              

              <button  className={formData.class === 'Five' ? 'active' : null} disabled={formData.unclick} onClick={handleFive}>
                <i class="fa fa-angle-right" aria-hidden="true"> </i> <span id="fifth" className='left_menu11'> Identity Verification</span>
                {formData.investmentType === 'Joint' ? (
                  jointIdentity === false ? null : (
                    <i class="fas fa-check"></i>
                  )
                ) : identity === false ? null : (
                  <i class="fas fa-check"></i>
                )}
              </button>
              <button disabled={formData.unclick} className={formData.class === 'Nine' ? 'active' : null} onClick={HandleNine}>
                <i class="fa fa-angle-right" aria-hidden="true"> </i><span id="nine" className='left_menu11'> Wholesale Certification</span>
                {wholeSale === true ? <i class="fas fa-check"></i> : null}{' '}
                {ss = true}
              </button>



            </>

            :
            <>

              <button className={formData.class === 'Second' ? 'active' : null} disabled={formData.unclick} onClick={HandleSecond}>
                <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="first" className='left_menu11'>Investment details</span>
                {right === false ? '' : <i class="fas fa-check"></i>}{' '}
              </button>

              {formData.investmentType === 'Joint' ? (
                <button
                  className={formData.class === 'Jointhird' ? 'active' : null}
                  onClick={HandleJointThird} disabled={formData.unclick}
                >
                  <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="second" className='left_menu11'>Entity Details </span>
                  {jointthirdentity === false ? '' : <i class="fas fa-check"></i>}
                </button>
              ) : ""}

              {formData.investmentType === 'Company' ? (
                <button
                  className={formData.class === 'Jointhird' ? 'active' : null}
                  onClick={HandleEntityOne} disabled={formData.unclick}
                >
                  <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="second2" className='left_menu11'>Entity Details </span>
                  {companyentity === false ? '' : <i class="fas fa-check"></i>}

                </button>
              ) : ""}

              {formData.investmentType === 'Trust' ? (
                <button
                  className={formData.class === 'Jointhird' ? 'active' : null}
                  onClick={HandleTrustEntityOne} disabled={formData.unclick}
                >
                  <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="second3" className='left_menu11'>Trust Details </span>
                  {trustdetails === false ? '' : <i class="fas fa-check"></i>}

                </button>
              ) : ""}

              <button className={formData.class === 'Third' ? 'active' : null} disabled={formData.unclick} onClick={HandleThird}>
                <i class="fa fa-angle-right" aria-hidden="true"></i><span id="third" className='left_menu11'> Personal information</span>
                {formData.investmentType === 'Joint' ? (
                  jointpersonal === false ? null : (
                    <i class="fas fa-check"></i>
                  )
                ) : personal === false ? null : (
                  <i class="fas fa-check"></i>
                )}
              </button>

              <button onClick={handleFive} disabled={formData.unclick} className={formData.class === 'Five' ? 'active' : null}>
                <i class="fa fa-angle-right" aria-hidden="true"> </i> <span id="fifth" className='left_menu11'> Identity Verification</span>
                {formData.investmentType === 'Joint' ? (
                  jointIdentity === false ? null : (
                    <i class="fas fa-check"></i>
                  )
                ) : identity === false ? null : (
                  <i class="fas fa-check"></i>
                )}
              </button>
              <button onClick={HandleEight} disabled={formData.unclick} className={formData.class === 'Eight' ? 'active' : null}>
                <i class="fa fa-angle-right" aria-hidden="true"> </i><span id="seven" className='left_menu11'> Bank Account Details</span>
                {formData.investmentType === 'Joint' ? (
                  jointBank === false ? null : (
                    <i class="fas fa-check"></i>
                  )
                ) : bank === false ? null : (
                  <i class="fas fa-check"></i>
                )}
              </button>
              <button disabled={formData.unclick} className={formData.class === 'Nine' ? 'active' : null} onClick={HandleNine}>
                <i class="fa fa-angle-right" aria-hidden="true"> </i><span id="nine" className='left_menu11'> Wholesale Certification</span>
                {wholeSale === true ? <i class="fas fa-check"></i> : null}{' '}
              </button>
              <button
                onClick={Handletwentyone} disabled={formData.unclick}
                className={formData.class === 'Twentyone' ? 'active' : null}
              >
                <i class="fa fa-angle-right" aria-hidden="true"> </i><span id="eleven" className='left_menu11'> Terms & Conditions</span>
                {termcondi === false ? null : <i class="fas fa-check "></i>}
              </button>
              {ss = true}
            </>
          }


          {/* //***********  main */}
          {/* <>
        
        <button className={formData.class === 'Second' ? 'active' : null} disabled={formData.unclick} onClick={HandleSecond}>
           <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="first" className='left_menu11'>Investment details</span>
           {right === false ? '' : <i class="fas fa-check"></i>}{' '}
         </button>

         {formData.investmentType === 'Joint' ? (
           <button
             className={formData.class === 'Jointhird' ? 'active' : null}
             onClick={HandleJointThird} disabled={formData.unclick}
           >
             <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="second" className='left_menu11'>Entity Details </span>
             {jointthirdentity === false ? '' : <i class="fas fa-check"></i>}
           </button>
         ) : ""}

         {formData.investmentType === 'Company' ? (
           <button
             className={formData.class === 'Jointhird' ? 'active' : null}
             onClick={HandleEntityOne} disabled={formData.unclick}
           >
             <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="second2" className='left_menu11'>Entity Details </span>
             {companyentity === false ? '' : <i class="fas fa-check"></i>}

           </button>
         ) : ""}

         {formData.investmentType === 'Trust' ? (
           <button
             className={formData.class === 'Jointhird' ? 'active' : null}
             onClick={HandleTrustEntityOne} disabled={formData.unclick}
           >
             <i class="fa fa-angle-right" aria-hidden="true">  </i><span id="second3" className='left_menu11'>Trust Details </span>
             {trustdetails === false ? '' : <i class="fas fa-check"></i>}

           </button>
         ) : ""}

         <button className={formData.class === 'Third' ? 'active' : null} disabled={formData.unclick} onClick={HandleThird}>
           <i class="fa fa-angle-right" aria-hidden="true"></i><span id="third" className='left_menu11'> Personal information</span>
           {formData.investmentType === 'Joint' ? (
             jointpersonal === false ? null : (
               <i class="fas fa-check"></i>
             )
           ) : personal === false ? null : (
             <i class="fas fa-check"></i>
           )}
         </button>

         <button onClick={handleFive} disabled={formData.unclick} className={formData.class === 'Five' ? 'active' : null}>
           <i class="fa fa-angle-right" aria-hidden="true"> </i> <span id="fifth" className='left_menu11'> Identity Verification</span>
           {formData.investmentType === 'Joint' ? (
             jointIdentity === false ? null : (
               <i class="fas fa-check"></i>
             )
           ) : identity === false ? null : (
             <i class="fas fa-check"></i>
           )}
         </button>
         <button onClick={HandleEight} disabled={formData.unclick} className={formData.class === 'Eight' ? 'active' : null}>
           <i class="fa fa-angle-right" aria-hidden="true"> </i><span id="seven" className='left_menu11'> Bank Account Details</span>
           {formData.investmentType === 'Joint' ? (
             jointBank === false ? null : (
               <i class="fas fa-check"></i>
             )
           ) : bank === false ? null : (
             <i class="fas fa-check"></i>
           )}
         </button>
         <button disabled={formData.unclick} className={formData.class === 'Nine' ? 'active' : null} onClick={HandleNine}>
           <i class="fa fa-angle-right" aria-hidden="true"> </i><span id="nine" className='left_menu11'> Wholesale Certification</span>
           {wholeSale === true ? <i class="fas fa-check"></i> : null}{' '}
         </button>
         <button
           onClick={Handletwentyone} disabled={formData.unclick}
           className={formData.class === 'Twentyone' ? 'active' : null}
         >
           <i class="fa fa-angle-right" aria-hidden="true"> </i><span id="eleven" className='left_menu11'> Terms & Conditions</span>
           {termcondi === false ? null : <i class="fas fa-check "></i>}
         </button>
         <button disabled={formData.unclick}
           onClick={Handletwentyone11}
           className={formData.class === 'Twentyone11' ? 'active' : null}
         >
           <i class="fa fa-angle-right" aria-hidden="true"></i><span className='left_menu11'>  Review & Submit</span>
         </button>
       </> */}
          {ss == true ? <button disabled={formData.unclick}
            onClick={Handletwentyone11}
            className={formData.class === 'Twentyone11' ? 'active' : null}
          >
            <i class="fa fa-angle-right" aria-hidden="true"></i><span className='left_menu11'>  Review & Submit</span>
          </button> : ""}
        </div>
        {formData.hidinglogout === false ? null :
          <div className="logout__bTN">
            {
              loader == true ?
                <Spin indicator={antIcon} className="spinnner cust_loader" /> :

                <button onClick={llogggout}>SAVE & LOGOUT</button>

            }
          </div>
        }
      </div>
    </div>
  );
};
export default MenuSidebar;
