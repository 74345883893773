import React, { useState, useEffect } from 'react';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import ReactFlagsSelect from 'react-flags-select';
import { getCode } from 'country-list';
import Rwt from '../Rwt';
import Nrwt from '../Nrwt';
import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from "../../auth/Configuration"
import axios from 'axios';
import UploadImage from '../Individual/UploadImage';

const TrustSecond = ({ func, setForm, formData, navigation, infodetls, infodetlss }) => {

  const {
    isTrustOhterCountryCitizen,
    TrustIrdNumber,
    trustFundReason,
    trustsettlorFund,
    rwtValueTrust,
    checkboxTrust,
    rwtTrust,
    countrtyCode,
    trustGenerateIncome,
    trustCountryOfResidence,
    companyFundsDocument,
    isNZBank
  } = formData

  const [selected2, setSelected2] = useState(

    formData.trustCountryOfResidence !== null &&
      formData.trustCountryOfResidence !== '' &&
      formData.trustCountryOfResidence !== 'undefined' &&
      formData.trustCountryOfResidence !== undefined
      ? formData.trustCountryOfResidence
      : ''
  );

  if (isTrustOhterCountryCitizen === 'true') {
    formData.trustCountryOfResidence = selected2;
  } else {
    formData.trustCountryOfResidence = null;
  }

  infodetls = () => {
    swal({
      text:
        "Please attach documentary evidence as to how this income is generated. Examples include bank statements, letter from Solicitor/Accountant/Donor confirming details of the gift, copy of the will which must include the value of the estate, letter from solicitor. Please note the document must be CERTIFIED AS A TRUE COPY of the original document."
      //"You can save your application data by clicking on the button Save Data. You can then logout, and resume your application at a later stage by clicking on the link we have previously sent in an email to you asking you to verify your email address.",
    });
  };

  infodetlss = () => {
    swal({
      text: 'You can save your application data by clicking on the button Save Data. You can then logout, and resume your application at a later stage by clicking on the link we have previously sent in an email to you asking you to verify your email address.'
    });
  };

  function Onprevious(e) {
    // formData.class = "Second"
    formData.class = 'Jointhird';
    go("TrustFirst")
  }

  function handleClick() {
    if (formData.TrustIrdNumber == "" || formData.TrustIrdNumber == null || formData.TrustIrdNumber == undefined) {
      $('.userIrd').html('Please enter Trust IRD number ')

    } else if (formData.trustAddressProof == "" || formData.trustAddressProof == null || formData.trustAddressProof == undefined) {
      $('.trustAddressProof').html('Please attach Trust IRD number proof ')

    } else if (formData.rwtTrust == "" || formData.rwtTrust == "Select" || formData.rwtTrust == null || formData.rwtTrust == undefined) {
      $('.funderror').html('Please select tax type ')

    } else if ((formData.rwtValueTrust == 'Select' || formData.rwtValueTrust == undefined || formData.rwtValueTrust == '' ||
      formData.rwtValueTrust == null) && formData.rwtTrust == 'Resident Withholding Tax') {
      $('.rwtValueTrust').html('Please select tax type value ')

    } else if ((formData.trustCountryOfResidence == undefined || formData.trustCountryOfResidence == '' || formData.trustCountryOfResidence == null) && formData.isTrustOhterCountryCitizen === 'true') {
      $('.trustCountryOfResidence').html('Please enter  country of tax residence')

    } else if ((formData.trustTinNumber == undefined || formData.trustTinNumber == '' || formData.trustTinNumber == null) && formData.isTrustOhterCountryCitizen === 'true') {
      $('.trustTinNumber').html('Please enter TIN number')

    } else if (formData.trustSourceOfFundsProof == "" || formData.trustSourceOfFundsProof == null || formData.trustSourceOfFundsProof == undefined) {
      $('.trustSourceOfFundsProof').html('Please attach trust source of funds proof ')

    } else {
      formData.class = 'Jointhird'
      go('TrustSecond2')
    }


  }

  function removeError() {
    $('.error').html('');
  }

  const saveandexit = () => {
    func();
  };
  const { go } = navigation;
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  let { document } = documentFile64;
  const datetime = new Date();
  const [errror, setErrror] = useState();

  console.log("formData.isTrustOhterCountryCitizen", formData.isTrustOhterCountryCitizen)

  let labeltxt = 'Please attach documentary evidence as to how this income is generated';
  const columnName = 'companyFundsDocument';
  const fileName = `Company_Funds_Document`;

  let labeltxtird = 'Please attach  proof of the IRD number'
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          <h2>Step 2: Trust Details</h2>
        </div>
        <div className="form entity_four">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff">
            <p className="save next"
              //disabled={formData.isPrimary == 1 ? false : true}
              onClick={saveandexit}>SAVE DATA </p>
            <img src={info} onClick={infodetlss} className="info" />
          </div>
          <Row className="">
            <Col md={6}>
              <label>Trust IRD number</label>
              <InputMask
                disabled={formData.isPrimary == 1 ? false : true}
                autoComplete="off"
                mask="999-999-999"
                maskChar={null}
                className="full ird_number capitaluserEmail"
                // type="text"
                name="TrustIrdNumber"

                value={TrustIrdNumber}

                maskPlaceholder={null}
                // onKeyPress={removeError}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
                placeholder="xxx-xxx-xxx"
              />
              <span className="error userIrd"></span>
            </Col>
            <Col md={12}>
            <UploadImage
                  buttonName='CLICK TO ATTACH'
                  formData={formData}
                  columnName={'trustIrdNumberProof'}
                  fileName={'trust_Ird_Number_Proof'}
                  labeltxt={labeltxtird}
                  valuedefine={"trustIrdNumberProof"}
                />
                <span className="error trustIrdNumberProof"></span>
              {/* {formData.isPrimary == 1 ? <>
                <UploadImage
                  buttonName='CLICK TO ATTACH'
                  formData={formData}
                  columnName={'trustIrdNumberProof'}
                  fileName={'trust_Ird_Number_Proof'}
                  labeltxt={labeltxtird}
                  valuedefine={"trustIrdNumberProof"}
                />
                <span className="error trustIrdNumberProof"></span>
              </> : <button>CLICK TO ATTACH</button>} */}

            </Col>
          </Row>
          <Row className="">
            <Col md={6}>
              <label>Please confirm your tax type</label>
              <Rwt
                disabled={formData.isPrimary == 1 ? false : true}
                autoComplete="off"
                type="text"
                className={
                  rwtTrust === 'Select' ||
                    rwtTrust === undefined ||
                    rwtTrust === 'undefined' ||
                    rwtTrust === '' ||
                    rwtTrust === null
                    ? 'colorgrey capitaluserEmail'
                    : 'colorblack capitaluserEmail'
                }
                onClick={removeError}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
                name="rwtTrust"
                value={rwtTrust}
              />
              <span className="error funderror"></span>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {formData.rwtTrust === 'Resident Withholding Tax' ? (
                <>
                  <Nrwt
                    disabled={formData.isPrimary == 1 ? false : true}
                    type="text"
                    label="Select your Resident Withholding Tax rate"
                    className={
                      rwtValueTrust === 'Select' ||
                        rwtValueTrust === undefined ||
                        rwtValueTrust === '' ||
                        rwtValueTrust === null
                        ? 'colorgrey'
                        : 'colorblack'
                    }
                    onClick={removeError}
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    name="rwtValueTrust"
                    value={rwtValueTrust}
                  />
                  <span className='error rwtValueTrust'></span></>
              ) : rwtTrust !== 'Resident Withholding Tax' && rwtTrust !== 'AIL 2%' && rwtTrust === 'Non Resident Withholding Tax' ? (
                <div className="checkbox-my nrdt_chack">
                  {' '}
                  <div className="checkmystyle">
                    {' '}
                    <>
                      <input
                        disabled={formData.isPrimary == 1 ? false : true}
                        type="checkbox"
                        id="TickHere"
                        defaultChecked={checkboxTrust === true ? true : false}
                        name="checkboxTrust"
                        onChange={(e) => {
                          setForm(e);
                          // checkboxchange(e);
                        }}
                      />
                      <span class="checkmark"></span>{' '}
                      <label for="TickHere">Tick here if you hold a valid Resident Withholding Tax exemption.</label>
                    </>
                  </div>{' '}
                </div>
              ) : (
                ''
              )}
            </Col>

            {rwtValueTrust === 'Exempt' && rwtTrust == 'Resident Withholding Tax' ?

              <Col xs={12}>

                <UploadImage
                  formData={formData}
                  columnName={"trustExamptProof"}
                  fileName={"trustExamptProof"}
                  labeltxt={labeltxt}
                  buttonName='CLICK TO ATTACH'
                  valuedefine={"trustExamptProof"}
                />
              </Col>

              : ""
            }
          </Row>

          <Row>
            <Col lf={12}>
              <label>
                Is the Trust a U.S. citizen, Or U.S. resident, Or a tax resident in any other country?
              </label>
            </Col>
            <Col lg={12}>
              <div className="button_group space-bottom">
                <div class="switch-field nospace">
                  <div className="check_YN_Bank swtich11">
                    <input
                      disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id="radio-one"
                      className="yesno"
                      name="isTrustOhterCountryCitizen"
                      value="false"
                      onChange={(e) => {
                        setForm(e);
                        // removeError();
                      }}
                    />
                    <label
                      for="radio-one"
                      className={`toogles postalAddressButton ${typeof isTrustOhterCountryCitizen === 'undefined' || isTrustOhterCountryCitizen === 'false'
                        ? 'actives'
                        : null
                        }`}
                      id="show"
                    >
                      No
                    </label>
                    <input
                      disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id="radio-two"
                      name="isTrustOhterCountryCitizen"
                      value="true"
                      onChange={(e) => {
                        setForm(e);
                        // removeError();
                      }}
                    />
                    <label
                      for="radio-two"
                      className={`toogles postalAddressButtons
                        ${isTrustOhterCountryCitizen === 'true' ? 'actives' : null
                        }`
                      }
                      id="hide"
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <>
              <div className="fullspace pandingfinsh">
                {' '}
                {isTrustOhterCountryCitizen === 'true' ? (
                  <div className="col-md-12">
                    <Row className="FlagsSelectcss">
                      <Col md={12}>
                        <div onClick={removeError}>
                          <label>Country of tax residence</label>
                        </div>
                      </Col>
                      <Col md={6} className='trustTax_Cntry'>
                        <ReactFlagsSelect
                          disabled={formData.isPrimary == 1 ? false : true}
                          autoComplete="off"
                          style={{ with: "100% !importantd" }}
                          name="trustCountryOfResidence"
                          selected={selected2}
                          value={trustCountryOfResidence}
                          placeholder="Select"
                          onClick={removeError}
                          onChange={(e) => {
                            setForm(e);
                            removeError(e);
                          }}
                          onSelect={(code) => {
                            setSelected2(code)
                            removeError(code);
                          }}

                          countries={countrtyCode}

                          defaultCountry={
                            formData.trustCountryOfResidence && formData.trustCountryOfResidence !== null
                              ? getCode(formData.trustCountryOfResidence.toUpperCase())
                              : null
                          }
                        />

                        <span className="error error-2 trustCountryOfResidence"></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div onClick={removeError}>
                          <label>Tax Identification Number (TIN)</label>
                        </div>
                      </Col>
                      <Col md={6}>
                        <input
                          disabled={formData.isPrimary == 1 ? false : true}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          pattern='/^[ A-Za-z0-9_@./#&+-]*$/'
                          maxlength={11}
                          autoComplete="off"
                          className='capitaluserEmail'
                          placeholder="Enter tax identification or N/A"
                          name="trustTinNumber"
                          value={formData.trustTinNumber}
                          onChange={(e) => {
                            setForm(e);
                            removeError();
                          }}
                        />
                        <span className="error trustTinNumber "></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div onClick={removeError}>
                          <label>Reason if TIN is not available</label>
                        </div>
                      </Col>
                      <Col md={6}>
                        <input
                          disabled={formData.isPrimary == 1 ? false : true}
                          autoComplete="off"
                          className='capitaluserEmail'
                          placeholder="Enter reason"
                          name="trustTinReason"
                          value={formData.trustTinReason}
                          onChange={(e) => {
                            setForm(e);
                            removeError();
                          }}
                        ></input>
                        <span className="error residingCountry"></span>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          </Row>
          <Row>
            <Col md={12}>
              <label>Reason for investment with MyFarm</label>
            </Col>

            <Col md={12}>
              <textarea
                disabled={formData.isPrimary == 1 ? false : true}
                className="entitytextarea"
                type="text"
                name='trustFundReason'
                rows="2"
                cols="80"
                value={trustFundReason}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                placeholder="Please type in your reason for investment"
              />
            </Col>

            <Col md={12}>
              <label>How did the settlor generate the funds they used to establish the trust?</label>
            </Col>

            <Col md={12}>
              <textarea
                disabled={formData.isPrimary == 1 ? false : true}
                className="entitytextarea"
                type="text"
                name='trustsettlorFund'
                rows="2"
                cols="80"
                value={trustsettlorFund}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                placeholder="Please explain the Source of Fund or Source of Wealth"
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <label>How does the Trust continue to generate an income ?</label>
            </Col>
            <Col md={12}>
              <textarea
                disabled={formData.isPrimary == 1 ? false : true}
                className="entitytextarea"
                type="text"
                name='trustGenerateIncome'
                rows="2"
                cols="80"
                value={trustGenerateIncome}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                placeholder="Please describe how the trust continues to generate an income"
              />
            </Col>
          </Row>
          <UploadImage
                formData={formData}
                buttonName='CLICK TO ATTACH'
                columnName={"trustSourceOfFundsProof"}
                fileName={"trustSourceOfFundsProof"}
                labeltxt={labeltxt}
                // inform={infodetls}
                // showIcon={true}
                valuedefine={"trustSourceOfFundsProof"}
                disabled={formData.isPrimary == 1 ? false : true}

              />
              <span className="error trustSourceOfFundsProof"></span>
          {/* {formData.isPrimary == 1 ?
            <>
              <UploadImage
                formData={formData}
                buttonName='CLICK TO ATTACH'
                columnName={"trustSourceOfFundsProof"}
                fileName={"trustSourceOfFundsProof"}
                labeltxt={labeltxt}
                // inform={infodetls}
                // showIcon={true}
                valuedefine={"trustSourceOfFundsProof"}
                disabled={formData.isPrimary == 1 ? false : true}

              />
              <span className="error trustSourceOfFundsProof"></span>

            </> :
            <button>CLICK TO ATTACH</button>
          } */}



          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={Onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {
              imageLoader === true ? (
                <button type="submit" className="next flagsave">
                  <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                  <img src={rightarrow} className="rightarrow" alt="" />{' '}
                </button>
              ) : (
                <button type="submit" className="next flagsave" onClick={handleClick}>
                  Continue
                  <img src={rightarrow} className="rightarrow" alt="" />{' '}
                </button>
              )}

          </div>
        </div>
      </div>
    </>
  );
};
export default TrustSecond;
