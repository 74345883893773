/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import PhoneInput from 'react-phone-input-2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import $ from 'jquery';
import InputMask from 'react-input-mask';
import info from '../info.png';
import swal from 'sweetalert';
import ReactFlagsSelect from 'react-flags-select';
import { getCode } from 'country-list';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import Googleaddress11 from './Googleaddress11';
import Googleaddress12 from './Googleaddress12';
import { date } from 'yup';
// import ContactType from '../ContactType';
import ContactType1 from '../ContactType1';
import MenuSidebar from '../Individual/MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';
import SearchAddress3 from '../Individual/SearchAddress3';
import SearchAddress4 from '../Individual/SearchAddress4';

const Five = ({ func, setForm, formData, navigation, saveAndExist, google, test }) => {
  const {
    preferredName2,
    isUserAddressSame2,
    residentialGoogleAddress1,
    postal1,
    fullName,
    secondholder,
    priContactType2,
    priContactCountryCode2,
    priContactNumber2,
    manuallyAddress2,
    street_number3,
    city3,
    country3,
    postalCode3,
    region3,

  } = formData;
  let countrtyCode = [
    'NZ',
    'AF',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BR',
    'IO',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CO',
    'KM',
    'CG',
    'CD',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'PF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'HT',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RO',
    'RU',
    'RW',
    'KN',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SZ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'US',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VI',
    'YE',
    'ZM',
    'ZW'
  ];
  const { previous, next } = navigation;
  var hasPostal = false;
  if (formData.postalad == 'false') {
    hasPostal = true;
  }
  // formData.secondholder = secondholder.trim();
  // formData.fullName = fullName.trim();

  const [namefull1, setNamefull1] = useState(secondholder ? secondholder.trim().split(' ') : '');
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];

  formData.firstNameSec = namefull1[0];

  if (namefull1.length > 1) {
    formData.lastNameSec = namefull1[namefull1.length - 1];
  }

  if (namefull1.length > 2) {
    formData.middleNameSec = namefull1
      .slice(1, namefull1.length - 1)
      .join()
      .replace(/,/g, ' ');
  }

  const [state, setState] = useState({ shown: hasPostal, hide: hasPostal });

  let token = sessionStorage.getItem('token');

  let setValues = '';
  // let dates = "";

  useEffect(() => {
    // alert(formData.userResidentialAddress2)
    var sdc = localStorage.getItem('date1');

    // if (typeof sdc !== 'object' && sdc !== "" && sdc !== null && sdc !== "null") {
    //   $(".ui.icon.input.datemy>input").val(sdc);
    // }

    let dataStr = sessionStorage.getItem('allData');
    let dataObj = JSON.parse(dataStr);

    //
    $('.toogles').click(function () {
      $('.toogles').removeClass('active');
      $(this).addClass('active');
    });
    $('.toogles3').click(function () {
      $('.toogles3').removeClass('active');
      $(this).addClass('active');
    });
    $('.step-change').click(function () {
      $('.step-change').removeClass('active');
      $(this).addClass('active');
    });
    $('document').ready(function () {
      $("input[type='tel']").attr('readonly', true);
    });

    $('.flagsave').click(function () {
      let contactCountryCode = $('.form-control').val();
      localStorage.setItem('phoneCode', contactCountryCode);
    });

    $('.step-change').click(function () {
      setValues = $(this).html();
    });
  }, []);

  function setDateformateToValid(date1) {
    if (
      date1 !== null &&
      date1 !== 'undefined' &&
      typeof date1 !== 'undefined' &&
      date1.includes('/')
    ) {
      let dat = date1.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }

  function curd(selectedDate) {
    var setDate = new Date(setDateformateToValid(selectedDate));
    var d = new Date();
    var pastYear = d.getFullYear() - 18;
    d.setFullYear(pastYear);
    if (d < setDate) {
      return true;
    } else {
      return false;
    }
  }


  function previousss() {
    if (formData.isMyFarm === true || formData.isMyFarm === 'true') {
      formData.class = 'Third'
      go("Jointfour")
    } else {
      go("Jointfive")
      // previous
    }
  }
  function setDateformateToValid(date1) {
    if (
      date1 !== null &&
      date1 !== 'undefined' &&
      typeof date !== 'undefined' &&
      date1.includes('/')
    ) {
      let dat = date1.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }
  const saveandexit = () => {
    func();
  };
  useEffect(() => {
    if (formData.isUserAddressSame2 == "true" ||  formData.isUserAddressSame2 == true){
      formData.isSecondryPostalAdre = "";
    }
   });
  function handleClick12(e) {
    formData.country3 = selected1234;
    formData.secondholder = secondholder.trim();
    formData.fullName = fullName.trim();
    formData.lastName = '';
    formData.middleName = '';
    formData.lastName1 = '';
    formData.middleName1 = '';
    var d = new Date();
    var pastYear = d.getFullYear() - 18;

    const date1 = document.querySelector('#date1').value;

    formData.dateOfBirth2 = date1;

    const dateValue1 = document.querySelector('#date1').value;
    formData.dateOfBirth2 = dateValue1;

    $('.error').html('');
    var dateOfBirthval = $('.ui.icon.input>input').val();
    var btnVal = $('.postalAddressButton').hasClass('active');

    var residentialAddr1 = $('#resAddr1').val();
    //  if( residentialAddr && residentialAddr != null && residentialAddr != undefined && residentialAddr != "undefined"){
    // formData.userResidentialAddress2 = residentialAddr1;
    // }
    let residetialGoglemap = $('#resAddr1').data('val');
    //  if( residetialGoglemap && residetialGoglemap != null && residetialGoglemap != undefined && residetialGoglemap != "undefined"){
    // formData.userResidentialAddress2GoogleAddress1 = residetialGoglemap;
    // }


    if (formData.isSecondryResdAddress === ",,,,NZ") {
      formData.isSecondryResdAddress = ""
    }
    var residentialAddrs1 = $('#resAddrs1').val();
    // if( residentialAddrs && residentialAddrs != null && residentialAddrs != undefined && residentialAddrs != "undefined"){
    // formData.userPostalAddress2 = residentialAddrs1;

    if ((formData.street_number3 == null || formData.street_number3 == "" || formData.street_number3 == undefined || formData.street_number3 == "undefined") && manuallyAddress2 === "false") {
      $('.street_numbererror').html('Please enter your street number ')
    }
    if ((formData.city3 == "" || formData.city3 == null || formData.city3 == "undefined") && manuallyAddress2 === "false") {
      $('.cityerror').html('Please enter your city')

    }
    if ((formData.postalCode3 == "" || formData.postalCode3 == null || formData.postalCode3 == "undefined") && manuallyAddress2 === "false") {
      $('.postalCodeerror').html('Please enter your postal code')

    }
    if (formData.secondholder === '' || formData.secondholder == undefined || formData.secondholder === null) {
      $('.fullNameerror').html('Please enter your full legal name ')
    }
   

    if (
      curd(formData.dateOfBirth2) ||
      formData.dateOfBirth2.split('/')[1] > 12 ||
      formData.dateOfBirth2.split('/')[2] < 1900
    ) {
      $('.doberror').html('Please enter valid date of birth');
    } else if (
      formData.dateOfBirth2.split('/')[0] > 31 ||
      formData.dateOfBirth2.split('/')[0] == 0 ||
      formData.dateOfBirth2.split('/')[1] == 0 ||
      formData.dateOfBirth2.split('/')[1] > 12 ||
      formData.dateOfBirth2.split('/')[2] < 1900
    ) {
      $('.titleerror12').html('Please enter valid date of birth');
    } if ( formData.isSecondryResdAddress === "" || formData.isSecondryResdAddress === null || formData.isSecondryResdAddress === "undefined" || formData.isSecondryResdAddress === undefined) {
      $('.residential').html('Please enter your  address ')
    }
    if ((formData.isSecondryPostalAdre === '' || formData.isSecondryPostalAdre === undefined || formData.isSecondryPostalAdre === null) && isUserAddressSame2 === "false") {
      $('.postalError').html('Please enter your postal address ')
    }
    if ((formData.priContactNumber2 === '' || formData.priContactNumber2 === undefined || formData.priContactNumber2 === null)) {
      $('.enterPhone').html('Please enter your phone number')
    }

    // if ((formData.secContactNumber2 === '' || formData.secContactNumber2 === undefined || formData.secContactNumber2 === null) && addnumber === true) {
    //   $('.addNumber').html('Please enter your additional number ')
    // }

    if (formData.secondholder === '' || formData.secondholder == undefined || formData.secondholder === null) {
      $('.fullNameerror').html('Please enter your full legal name ')
    }
    else if (
      curd(formData.dateOfBirth2) ||
      formData.dateOfBirth2.split('/')[1] > 12 ||
      formData.dateOfBirth2.split('/')[2] < 1900
    ) {
      $('.doberror').html('Please enter valid date of birth');
    } else if (
      formData.dateOfBirth2.split('/')[0] > 31 ||
      formData.dateOfBirth2.split('/')[0] == 0 ||
      formData.dateOfBirth2.split('/')[1] == 0 ||
      formData.dateOfBirth2.split('/')[1] > 12 ||
      formData.dateOfBirth2.split('/')[2] < 1900
    ) {
      $('.titleerror12').html('Please enter valid date of birth');
    }
     else if 
     ( formData.isSecondryResdAddress === "" || formData.isSecondryResdAddress === null || formData.isSecondryResdAddress === "undefined"|| formData.isSecondryResdAddress === undefined) {
      $('.residential').html('Please enter your address')

    } else if ((formData.street_number3 == null || formData.street_number3 == "" || formData.street_number3 == undefined || formData.street_number3 == "undefined") && manuallyAddress2 === "false") {
      $('.street_numbererror').html('Please enter your street number ')
    }
    else if ((formData.city3 == "" || formData.city3 == null || formData.city3 == "undefined") && manuallyAddress2 === "false") {
      $('.cityerror').html('Please enter your city')

    }
    else if ((formData.postalCode3 == "" || formData.postalCode3 == null || formData.postalCode3 == "undefined") && manuallyAddress2 === "false") {
      $('.postalCodeerror').html('Please enter your postal code')

    } else if ((formData.isSecondryPostalAdre === '' || formData.isSecondryPostalAdre === undefined || formData.isSecondryPostalAdre === null) && isUserAddressSame2 === "false") {
      $('.postalError').html('Please enter your postal address ')
    }
    else if ((formData.priContactNumber2 === '' || formData.priContactNumber2 === undefined || formData.priContactNumber2 === null)) {
      $('.enterPhone').html('Please enter your phone number ')
    }


    // else if ((formData.secContactNumber2 === '' || formData.secContactNumber2 === undefined || formData.secContactNumber2 === null) && addnumber === true) {
    //   $('.addNumber').html('Please enter your additional number ')
    // }


    //  else if (formData.intendToInvest3 == "" || formData.intendToInvest3 == null || formData.intendToInvest3 == undefined || formData.intendToInvest3 == "undefined" || formData.intendToInvest3 == "Select") {
    //   $('.intend').html('Please select your intend to invest')
    // }
    // else if ((formData.otherIntendToInvest == "" || formData.otherIntendToInvest3 == null || formData.otherIntendToInvest3 == "undefined") && formData.intendToInvest3 == "Other") {
    //   $('.Other').html('Please enter your intend to invest')
    // }
    // else if (formData.totalIintend3 == "" || formData.totalIintend3 == null || formData.totalIintend3 == "undefined" || formData.totalIintend3 == "Select") {
    //   $('.total').html('Please select your total intend')
    // }
    // else if (formData.chooseToInvest3 == "" || formData.chooseToInvest3 == null || formData.chooseToInvest3 == "undefined" || formData.chooseToInvest3 == "Select") {
    //   $('.chooseToInvest').html('Please select your invest')
    // }
    // else if ((formData.otherChooseToInvest3 == "" || formData.otherChooseToInvest3 == null || formData.otherChooseToInvest3 == "undefined") && formData.chooseToInvest3 == "Other") {
    //   $('.Other2').html('Please select your other choose invest')
    // } else if (formData.occupation3 == "" || formData.occupation3 == null || formData.occupation3 == "undefined") {
    //   $('.occupations').html('Please enter your occupations')
    // }
    // else if (formData.sourceOfFund3 == "" || formData.sourceOfFund3 == null || formData.sourceOfFund3 == "undefined") {
    //   $('.sourceOfFund').html('Please enter your source of fund')
    // }
    else if (btnVal) {
      formData.postalad = 'false';
      if (formData.isMyFarm === true || formData.isMyFarm === 'true') {
        formData.class = 'Twentyone'
        go("Twentyone1")

      } else {
        next();
      }
    } else {
      formData.postalad = 'true';
      if (formData.isMyFarm === true || formData.isMyFarm === 'true') {
        formData.class = 'Twentyone'
        go("Twentyone1")

      } else {
        next();
      }
    }

  }

  let dateOfBirth2 = sessionStorage.getItem('dateOfBirth2');
  let residentialAddress2 = sessionStorage.getItem('residentialAddress2');
  let PostalAddress2 = sessionStorage.getItem('PostalAddress2');
  useEffect(() => {
    let dateOfBirth2 = sessionStorage.getItem('dateOfBirth2');
    $('.ui.icon.input>input').val(dateOfBirth2);
  });
  function birthdates() {
    dateOfBirth2 = $('.ui.icon.input>input').val();
    sessionStorage.setItem('dateOfBirth2', dateOfBirth2);
  }
  function removeError() {
    formData.check = true;

    $('.error').html('');
    $('.doberror').html('');
  }
  $('input').on('paste', function (e) {
    formData.check = true;
    $('.doberror').html('');
    $('.error').html('');
  });
  const [value, setValue] = useState();

  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, date1) => setNewDate(date1.value);
  const { go } = navigation;

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '3';

    go('submit');
  };
 
  const [phonecode, setPhonecode] = useState();
  useEffect(() => {
    formData.isUserAddressSame2 = isUserAddressSame2;
    formData.beneDataMapList = [];
  }, []);

  function removeError() {
    formData.check = true;
    $('.error').html('');
  }

  // const [addnumber, setAddnumber] = useState(formData.isAlternateContact1);
  // const alternumber = () => {
  //   setAddnumber(!addnumber);
  //   if (addnumber === false) {
  //     formData.isAlternateContact1 = true;
  //     // formData.secContactType = '';
  //     // formData.secContactNumber = '';
  //   } else {
  //     formData.isAlternateContact1 = false;
  //     formData.secContactType2 = '';
  //     formData.secContactNumber2 = '';
  //   }
  // };

  const handleChange = (e) => {
    e.preventDefault();
  };

  function setPass1(e) {
    if (
      priContactType2 === 'Mobile' ||
      priContactType2 === undefined ||
      priContactType2 === '' ||
      priContactType2 === null
    ) {
      if (e.length !== 9) {
        $('.eerrors').html('Enter your phone number including area code');
      } else {
        removeError();
      }
    } else {
      if (e.length !== 9) {
        $('.eerrors').html('Enter your phone number including area code');
      } else {
        removeError();
      }
    }
  }

  function setPass(e) {
    if (
      priContactType2 === 'Mobile' ||
      priContactType2 === undefined ||
      priContactType2 === '' ||
      priContactType2 === null
    ) {
      if (e.length !== 9) {
        $('.eerror').html('Please enter your phone number including 021, 022, 027,  etc');
      } else {
        removeError();
      }
    } else {
      if (e.length !== 9) {
        $('.eerror').html('Please enter your phone number including 021, 022, 027,  etc');
      } if (priContactType2 === 'Home' ||
        priContactType2 === 'Work' ||
        priContactType2 === 'Other') {
        $('.eerror').html('Please enter your phone number including the area code, eg 07, 09, 021, 022, etc');
      } else {
        removeError();
      }
    }
  }

  const [tesf, setTesf] = useState();
  const [conLoader, setConLoader] = useState(true)


  useEffect(() => {

    const timer = setTimeout(
      () => {
        setTesf(true);
        setConLoader(false);
      },
      1500
    );
  }, []);

  useEffect(() => {
    // if (isUserAddressSame2 == true || isUserAddressSame2 == 'true') {
    //   sessionStorage.setItem('PostalAddress2', '');
    //   formData.PostalAddress2 = '';
    // }
    // alert("jhgjh")
  });

  const chenging = () => {
    sessionStorage.removeItem('street_number3');
    sessionStorage.removeItem('locality1');
    sessionStorage.removeItem('administrative_area_level_11');
    sessionStorage.removeItem('sublocality_level_11');
    sessionStorage.removeItem('postal_code1');
    sessionStorage.removeItem('premise1');
    sessionStorage.removeItem('route1');
    sessionStorage.removeItem('country3');
    sessionStorage.removeItem('residentialAddress2');
  };
  const chenging2 = () => {
    formData.street_number3 = '';
    formData.city3 = '';
    formData.country3 = '';
    formData.postalCode3 = '';
    formData.region3 = '';
    formData.isSecondryResdAddress = '';
  };

  const residentialSave1 = (e) => {
    formData.street_number3 = e.target.value;
    formData.isSecondryResdAddress = e.target.value;
  };
  // const residentialSave2 = (e) => {
  //   formData.route3 = e.target.value
  //   formData.residentialAddress2 = formData.street_number3 + " " + e.target.value
  // }
  const residentialSave7 = (e) => {
    formData.region3 = e.target.value;
    formData.isSecondryResdAddress = formData.street_number3 + ',' + e.target.value;
  };

  const residentialSave3 = (e) => {
    formData.city3 = e.target.value;
    formData.isSecondryResdAddress =
      formData.street_number3 + ',' + formData.region3 + ',' + e.target.value;
  };
  const residentialSave6 = (e) => {
    formData.postalCode3 = e.target.value;
    formData.isSecondryResdAddress =
      formData.street_number3 +
      ',' +
      formData.region3 +
      ',' +
      formData.city3 +
      ',' +
      e.target.value;
  };
  const residentialSave4 = (e) => {
    formData.country3 = e.target.value;
    formData.country = selected1234;
    formData.isSecondryResdAddress =
      formData.street_number3 +
      ',' +
      formData.region3 +
      ',' +
      formData.city3 +
      ',' +
      formData.postalCode3 +
      ',' +
      e.target.value;
  };

  useEffect(() => {
    if (formData.manuallyAddress2 == 'false') {
      sessionStorage.removeItem('street_number3');
      sessionStorage.removeItem('locality1');
      sessionStorage.removeItem('administrative_area_level_11');
      sessionStorage.removeItem('sublocality_level_11');
      sessionStorage.removeItem('postal_code1');
      sessionStorage.removeItem('premise1');
      sessionStorage.removeItem('route1');
      sessionStorage.removeItem('country3');
      sessionStorage.removeItem('residentialAddress2');
      // sessionStorage.removeItem('street_number');
      // sessionStorage.removeItem('locality');
      // sessionStorage.removeItem('administrative_area_level_1');
      // sessionStorage.removeItem('sublocality_level_1');
      // sessionStorage.removeItem('postal_code');
      // sessionStorage.removeItem('premise');
      // sessionStorage.removeItem('route');
      // sessionStorage.removeItem('country');
      // sessionStorage.removeItem('residentialAddress2');
    } else if (formData.manuallyAddress2 == 'true') {
      formData.street_number3 = '';
      formData.city3 = '';
      formData.country3 = 'NZ';
      formData.postalCode3 = '';
      formData.region3 = '';
      // formData.userResidentialAddress2 = '';
    }
  }, []);

  const [selected1234, setSelected1234] = useState(
    formData.country3 !== null &&
      formData.country3 !== '' &&
      formData.country3 !== 'undefined' &&
      formData.country3 !== undefined
      ? formData.country3
      : 'NZ'
  );
  formData.country3 = selected1234;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    //menusidebar validation start
    if (formData.check === false) {
      if (formData.isSecondryPostalAdre === null || formData.isSecondryPostalAdre === "" || formData.isSecondryPostalAdre === "undefined" || formData.isSecondryPostalAdre === undefined) {
        $('.postalError').html('Please enter your postal address ')
      }
      if ( formData.isSecondryResdAddress === "" || formData.isSecondryResdAddress === null || formData.isSecondryResdAddress === "undefined" || formData.isSecondryResdAddress === undefined) {
        $('.residential').html('Please enter your address ')
      }
      if (formData.street_number3 == null || formData.street_number3 == "" || formData.street_number3 == "undefined") {
        $('.street_number3').html('Please enter your street number')
      }
      if (formData.city3 == null || formData.city3 == "" || formData.city3 == "undefined") {
        $('.city3').html('Please enter your street number')
      }
      if (formData.postalCode3 == null || formData.postalCode3 == "" || formData.postalCode3 == "undefined") {
        $('.postalCode3').html('Please enter your street number')
      }
      if (
        typeof formData.dateOfBirth2 == 'undefined' ||
        formData.dateOfBirth2 === '' ||
        formData.dateOfBirth2 === null
      ) {
        $('.doberror').html('Please enter your date of birth');
      } else if (
        curd(formData.dateOfBirth2) ||
        formData.dateOfBirth2.split('/')[1] > 12 ||
        formData.dateOfBirth2.split('/')[2] < 1900
      ) {
        $('.doberror').html('Please enter valid date of birth');
      } else if (
        formData.dateOfBirth2.split('/')[0] > 31 ||
        formData.dateOfBirth2.split('/')[0] == 0 ||
        formData.dateOfBirth2.split('/')[1] == 0 ||
        formData.dateOfBirth2.split('/')[1] > 12 ||
        formData.dateOfBirth2.split('/')[2] < 1900
      ) {
        $('.titleerror12').html('Please enter valid date of birth');
      }
      if (formData.secondholder === '' || formData.secondholder == undefined || formData.secondholder === null) {
        $('.fullNameerror').html('Please enter your full legal name ')
      }
      if (formData.codeVerify === "false") {
        $('.verificationerror').html('Please enter unique verification code ')
      }
      if (formData.priContactType2 === null || formData.priContactType2 === "") {
        $('.mobileerror').html('Please enter number ')
      }
    }

  })


  function removeErrorOnlyOne(valueClass) {
    $(`.${valueClass}`).html('');
  }

  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2>
            Step 3: Personal Information - {' '}
            {(formData.firstNameSec != null ? formData.firstNameSec : '') +
              ' ' +
              (formData.lastNameSec != null ? formData.lastNameSec : '')}{' '}
          </h2>{' '}
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit  se_invff joint-holder">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>

          <Row>
            <Col md={12}>
              <label>Full legal name</label>
              <ItemForm
                autoComplete="off"
                type="text"
                name="secondholder"
                onKeyPress={(event) =>
                  (event.charCode >= 65 && event.charCode <= 90) ||
                  (event.charCode >= 97 && event.charCode <= 122)
                }
                className="quantity"
                onPaste={handleChange}
                id="secondholder"
                onChange={(e) => {

                  setForm(e);
                  removeErrorOnlyOne("fullNameerror")
                }}
                value={secondholder}
                placeholder="Enter full legal name"
              />
              <span className="error fullNameerror"></span>
            </Col>
            <Col md={12}>
              {' '}
              <label>Preferred name (if applicable)</label>
              <ItemForm
                autoComplete="off"
                name="preferredName2"
                className="quantity"
                onPaste={handleChange}
                // onKeyPress={removeError}
                value={preferredName2}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                placeholder="Enter preferred name"
              />
              {/* <span className="error fullNameerror"></span> */}
            </Col>
          </Row>

          <Row>
            <Col className="date-w">
              <label>Date of birth</label>
              <SemanticDatepicker
                autoComplete="off"
                className="datemy"
                id="date1"
                clearIcon="close"
                onClick={() => removeErrorOnlyOne("errodate")}
                onChange={(e) => {
                  removeErrorOnlyOne("errodate")
                  birthdates();
                }}
                maxDate={new Date(moment().subtract(18, 'years'))}
                name="date1"
                format="DD/MM/YYYY"
                allowOnlyNumbers
                value={
                  formData.dateOfBirth2
                    ? new Date(setDateformateToValid(formData.dateOfBirth2))
                    : null
                }
              />
              <span className="error errodate   doberror" />
              <span className="error errodate titleerror12" />
              <span className="error errodate titleerror13" />
              <span className="error errodate titleerror14" />
              <div class="address_data">
                <Row>
                  <Col md={9}>
                    {manuallyAddress2 === 'true' ||
                      manuallyAddress2 == 'undefined' ||
                      manuallyAddress2 == undefined ||
                      manuallyAddress2 == null ? (
                      <>
                        <label>Residential address</label>
                      
                         <SearchAddress3
                        formData={formData}
                        className="address_data hj"

                        placeholder="Enter home  address"
                        name="maniAdds"
                        value2={formData.isSecondryResdAddress}
                          />
                        <span className="error residential"></span>

                      </>
                    ) : manuallyAddress2 === 'false' ? (
                      <Row>
                        <Col >
                          <label>Street number & name</label>
                          <input
                            className="hj"
                            type="text"
                            name="street_number3"
                            placeholder="Enter street number and name"
                            onChange={(e) => {
                              residentialSave1(e);
                              setForm(e);
                              removeErrorOnlyOne("street_numbererror")
                            }}
                            value={street_number3}
                          />
                          <span className='error street_numbererror'></span>
                          {/* <label>Route</label>
                            <input
                              type="text"
                              name="route3"
                              placeholder="Enter route"
                              onChange={(e) => {

                                residentialSave2(e);
                                setForm(e);
                                removeError();
                              }}
                              value={route3}
                            /> */}
                          <label>Suburb</label>
                          <input
                            className="hj"
                            type="text"
                            name="region3"
                            placeholder="Enter suburb"
                            onChange={(e) => {
                              residentialSave7(e);
                              setForm(e);


                            }}
                            value={region3}
                          />
                          <label>City</label>
                          <input
                            className="hj"
                            type="text"
                            name="city3"
                            placeholder="Enter city"
                            onChange={(e) => {
                              residentialSave3(e);
                              setForm(e);
                              removeErrorOnlyOne("cityerror")

                            }}
                            value={city3}
                          />
                          <span className='error cityerror'></span>

                          <label>Postal code</label>
                          <input
                            maxLength={4}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }}
                            className="numbers hj"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="postalCode3"
                            placeholder="Enter postal code"
                            onChange={(e) => {
                              residentialSave6(e);
                              setForm(e);

                              removeErrorOnlyOne("postalCodeerror")

                            }}
                            value={postalCode3}
                          />
                          <span className='error postalCodeerror'></span>
                          <label>Country</label>
                          <ReactFlagsSelect
                            autoComplete="off"
                            name="country3"
                            selected={selected1234}
                            className="FlagsSelectcss dffk"
                            value={country3}
                            placeholder="Select"
                            onChange={(e) => {
                              residentialSave4(e);
                              setForm(e);

                            }}
                            onSelect={(code) => setSelected1234(code)}
                            countries={countrtyCode}
                            defaultCountry={
                              country3 && country3 !== null ? getCode(country3.toUpperCase()) : null
                            }
                          />

                          {/* <label>Country</label>
                            <input
                             className='hj'
                              type="text"
                              name="country3"
                              placeholder="Enter country"
                              onChange={(e) => {

                                residentialSave4(e);
                                setForm(e);
                                removeError();
                              }}
                              value={country3}
                            /> */}
                          {/* <label>Country Code</label>
                            <input
                              type="text"
                              name="countryyCode3"
                              placeholder="Enter country code"
                              onChange={(e) => {

                                residentialSave5(e);
                                setForm(e);
                                removeError();
                              }}
                              value={countryyCode3}
                            /> */}
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <div className="button_group space-bottom margin_left">
                      <div class="switch-field nospace">
                        <div className="check_YN_Bank swtich10 ">
                          <input
                            type="radio"
                            id="radio-one3"
                            className="yesno"
                            name="manuallyAddress2"
                            value="true"
                            onChange={(e) => {
                              setForm(e);
                              chenging2(e);
                              removeError();
                            }}
                            onClick={(e) => chenging2(e)}
                          />
                          <label
                            for="radio-one3"
                            className={`toogles3 postalAddressButton ${typeof manuallyAddress2 === 'undefined' || manuallyAddress2 === 'true'
                              ? 'actives'
                              : null
                              }`}
                            id="show"
                          >
                            Auto Lookup
                          </label>
                          <input
                            type="radio"
                            id="radio-two3"
                            name="manuallyAddress2"
                            value="false"
                            onChange={(e) => {
                              setForm(e);
                              chenging(e);
                              removeError();
                            }}
                            onClick={(e) => chenging(e)}
                          />
                          <label
                            for="radio-two3"
                            className={`toogles3 postalAddressButtons ${manuallyAddress2 === 'false' ? 'actives' : null
                              }`}
                            id="hide"
                          >
                            Manually Type
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>
       
          <Row className=" check-mb">
            <Col>
              <label className='mb-1.5'>Is your postal address the same as your home address?</label>
            </Col>
            <Col xs={12}>
              <div className="button_group space-bottom">
                <div class="switch-field nospace">
                  <div className="check_YN_Bank swtich11">

                    <input
                      autoComplete="off"
                      type="radio"
                      id="radio-two"
                      name="isUserAddressSame2"
                      value="false"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />


                    <label
                      for="radio-two"
                      className={`toogles postalAddressButtons ${isUserAddressSame2 === 'false' ? 'actives' : null
                        }`}
                      id="show"
                    >

                      No
                    </label>

                    <input
                      type="radio"
                      id="radio-one"
                      className="yesno"
                      name="isUserAddressSame2"
                      value="true"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />

                    <label
                      for="radio-one"
                      className={`toogles  postalAddressButton ${isUserAddressSame2 === 'undefined' ||
                        isUserAddressSame2 === undefined ||
                        isUserAddressSame2 === 'true' ||
                        isUserAddressSame2 === 'true'
                        ? 'actives'
                        : null
                        }`}
                      id="hide"
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col xs={4}>
              <div className="button_group space-bottom">
                <div class="switch-field nospace">
                  <div className="check_YN_Bank swtich11">
                    <input
                      type="radio"
                      id="radio-one"
                      className="yesno"
                      name="isUserAddressSame2"
                      value="true"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                  
                    <input
                      autoComplete="off"
                      type="radio"
                      id="radio-two"
                      name="isUserAddressSame2"
                      value="false"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                      <label
                      for="radio-one"
                      className={`toogles  postalAddressButton ${isUserAddressSame2 === 'undefined' ||
                        isUserAddressSame2 === undefined ||
                        isUserAddressSame2 === 'false'
                        ? 'actives'
                        : null
                        }`}
                      id="show"
                    >
                      {isUserAddressSame2}
                    </label>
                    <label
                      for="radio-two"
                      className={`toogles postalAddressButtons ${isUserAddressSame2 === 'true' ? 'actives' : null
                        }`}
                      id="hide"
                    >
                      YES
                    </label>
                  </div>
                </div>
              </div>
            </Col> */}

            {/* <Col xs={4}>
              <div className="button_group space-bottom">
                <div class="switch-field nospace">
                  <div className="check_YN_Bank swtich11">
                    <input
                      type="radio"
                      id="radio-one"
                      className="yesno"
                      name="isUserAddressSame2"
                      value="true"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                    <label
                      for="radio-one"
                      className={`toogles  postalAddressButton ${isUserAddressSame2 === 'undefined' ||
                        isUserAddressSame2 === undefined ||
                        isUserAddressSame2 === 'true' ||
                        isUserAddressSame2 === 'true'
                        ? 'actives'
                        : null
                        }`}
                      id="hide"
                    >
                      YES
                    </label>
                    <input
                      autoComplete="off"
                      type="radio"
                      id="radio-two"
                      name="isUserAddressSame2"
                      value="false"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                    <label
                      for="radio-two"
                      className={`toogles postalAddressButtons ${isUserAddressSame2 === 'false' ? 'actives' : null
                        }`}
                      id="show"
                    >
                      NO
                    </label>
                  </div>
                </div>
              </div>
            </Col> */}
            <div className="col-md-12">
              {isUserAddressSame2 === 'false' && tesf === true ? (
                <div>
                   <SearchAddress4
                        formData={formData}
                        placeholder="Enter postal address"
                        name="maniAdds"
                        value2={formData.isSecondryPostalAdre}
                          />


                  <span className="error postalError"></span>
                </div>
              ) : (
                ''
              )}
            </div>
          </Row>
          <ItemForm
            autoComplete="off"
            className="none"
            name="postal1"
            value={postal1}
            onChange={postal1}
            placeholder="postal"
          />

          <Row className="fullinput fullinputNumber">
            <Col md={12}>
              <label>Contact number</label>
            </Col>
            <Col md={2} className="d-flex dropdown-mobile">
              <ContactType1
                autoComplete="off"
                type="text"
                className={
                  priContactType2 === 'Mobile' ||
                    priContactType2 === undefined ||
                    priContactType2 === '' ||
                    priContactType2 === null
                    ? 'colorblack'
                    : 'colorblack'
                }
                // onClick={removeError}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                  setPass1(e);
                }}
                name="priContactType2"
                value={formData.priContactType2}
              />
            </Col>

            <Col md={5} className="d-flex counrtyselext5-phone">
              <div className="numberSet">
                <PhoneInput
                  className="counrtyselext5 digitsOnlynone "
                  readonly
                  country={'nz'}
                  preferredCountries={['nz']}
                  name="priContactCountryCode2"
                  value={formData.priContactCountryCode2}
                  // onChange={setValue}
                  // value={FormData.countryCode}
                  onChange={(phone) => {
                    formData.priContactCountryCode2 = phone;
                  }}
                />
              </div>
              <div className="numberSets InputMask-number">
                <InputMask
                  autoComplete="off"
                  mask="9999999999999"
                  maskChar={null}
                  onKeyPress={() => removeErrorOnlyOne("errenumber")}
                  maskPlaceholder={null}
                  className="numbers setinput"
                  name="priContactNumber2"
                  value={priContactNumber2}
                  onChange={(e) => {
                    setForm(e);
                    setPass(e);
                    removeErrorOnlyOne("errenumber")
                  }}
                  onClick={(e) => {
                    setPass(e);
                  }}
                  placeholder="Enter number"
                /><span className="error errenumber enterPhone " />
                <span className="error errenumber eerror mobileerror " />
              </div>
            </Col>
          </Row>

          {/* {addnumber === true ? (
            <Row className="fullinput fullinputNumber select_num_add">
              <Col md={12}>
                <label>Additional number</label>
              </Col>

              <Col md={6} className="d-flex">
                <ContactType
                  autoComplete="off"
                  type="text"
                  className={
                    secContactType2 === 'Select' ||
                      secContactType2 === undefined ||
                      secContactType2 === '' ||
                      secContactType2 === null
                      ? 'colorblack'
                      : 'colorblack'
                  }
                  // onClick={removeError}
                  onChange={(e) => {
                    setForm(e);
                    // removeError();
                  }}
                  name="secContactType2"
                  value={formData.secContactType2}
                />
                <div className="numberSet">
                  <PhoneInput
                    className="counrtyselext5 digitsOnlynone "
                    readonly
                    country={'nz'}
                    preferredCountries={['nz']}
                    name="secContactCountryCode2"
                    value={secContactCountryCode2}
                    // onChange={setValue}
                    // value={FormData.countryCode}
                    onChange={(phone) => {
                      formData.secContactCountryCode2 = phone;
                    }}
                  />
                </div>
                <div className="numberSets">
                  <InputMask
                    autoComplete="off"
                    mask="99999999999"
                    maskChar={null}
                    onKeyPress={ removeErrorOnlyOne("numbererrro2")}
                    maskPlaceholder={null}
                    className="numbers setinput"
                    name="secContactNumber2"
                    value={secContactNumber2}
                    onChange={(e) => {
                      setForm(e);
                      setPass1(e);
                       removeErrorOnlyOne("numbererrro2")
                    }}
                    placeholder="Enter number"
                  /><span className="error numbererrro2 addNumber " /> 
                  <span className="error numbererrro2 eerrors textcolor"></span>
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )} */}

          {/* <button className="border_BTN" onClick={alternumber}>
            {addnumber === true ? 'Remove' : 'Add Number'}
          </button> */}

          {/* <>

            <Row className="">
              <Col xs={12}>
                <label>How often do you intend to invest with MyFarm?</label>
              </Col>
              <Col xs={12}>
                <select name='intendToInvest3'
                  value={intendToInvest3}
                  onClick={() => removeError()}
                  onChange={(e) => { setForm(e) }}>
                  <option value='Select'>Select</option>
                  <option value='Quarterly'>Quarterly</option>
                  <option value='Six Monthly'>Six Monthly</option>
                  <option value='Annually'>Annually</option>
                  <option value='Other'>Other</option>
                </select>
                <spain className="error intend"></spain>
              </Col>
              {formData.intendToInvest3 == 'Other' ? <Col md={12}>
                <label>Invest with MyFarm Other</label>
                <ItemForm
                  autoComplete="off"
                  type="text"
                  onKeyPress={removeError}
                  maxLength={170}
                  className="quantity"
                  name='otherIntendToInvest3'
                  onPaste={handleChange}
                  onChange={(e) => {
                    setForm(e);
                    removeError();

                  }}
                  value={formData.otherIntendToInvest3}
                />
                <spain className="error Other"></spain>
              </Col> : ''}


            </Row>
            <Row className="">
              <Col xs={12}>
                <label>How much in total do you intend to invest with MyFarm?<br />
                  (note: This is across all MyFarm investment syndicates)</label>
              </Col>
              <Col xs={12}>
                <select name='totalIintend3'
                  value={totalIintend3}
                  onClick={() => removeError()}
                  onChange={(e) => { setForm(e) }}>
                  <option value='Select'>Select</option>
                  <option value='Up to $10,000'>Up to $10,000</option>
                  <option value='$10,000 - $50,000'>$10,000 - $50,000</option>
                  <option value='$50,000 - $200,000'>$50,000 - $200,000</option>
                  <option value='More than $200,000'>More than $200,000</option>
                </select>
                <spain className="error total "></spain>
              </Col>
            </Row>
            <Row className="">
              <Col xs={12}>
                <label>Why are you choosing to invest in this opportunity?</label>
              </Col>
              <Col xs={12}>
                <select name='chooseToInvest3'
                  value={formData.chooseToInvest3}
                  onClick={() => removeError()}
                  onChange={(e) => { setForm(e) }} >
                  <option value='Select'>Select</option>
                  <option value='Investment portfolio'>Investment portfolio</option>
                  <option value='Diversifying investments'>Diversifying investments</option>
                  <option value='Investing for a dependant'>Investing for a dependant</option>
                  <option value='Other'>Other</option>
                </select>
                <spain className="error chooseToInvest"></spain>
              </Col>
              {formData.chooseToInvest3 == 'Other' ?
                <Col md={12}>
                  <label>Invest in this opportunity Other</label>
                  <ItemForm
                    autoComplete="off"
                    type="text"
                    name="otherChooseToInvest3"
                    onKeyPress={removeError}
                    maxLength={170}
                    className="quantity"
                    id="Other3"
                    onPaste={handleChange}
                    onChange={(e) => {
                      setForm(e);
                      removeError();

                    }}
                    value={otherChooseToInvest3}
                  />
                  <spain className="error Other2"></spain>
                </Col>
                : ''}
            </Row>
            <Row>
              <Col md={12}>
                <label>What is your occupation?</label>
                <ItemForm
                  autoComplete="off"
                  type="text"
                  name="occupation3"
                  onKeyPress={removeError}
                  maxLength={170}
                  className="quantity"
                  id="your_occupation"
                  onPaste={handleChange}
                  onChange={(e) => {
                    setForm(e);
                    removeError();

                  }}
                  value={occupation3}
                />
                <span className="error occupations"></span>
              </Col>
              <Col md={12}>
                {' '}
                <label>How will you source the funds for your investment?</label>
                <ItemForm
                  autoComplete="off"
                  name="sourceOfFund3"
                  className="quantity"
                  onKeyPress={removeError}
                  value={sourceOfFund3}
                  maxLength={170}
                  onPaste={handleChange}
                  onChange={(e) => {
                    setForm(e);
                    removeError();
                  }}
                />
                <span className='error sourceOfFund'></span>
              </Col>
            </Row>
          </> */}


          <br />

          <div className="fotter-btn">
            <button className="preview" onClick={previousss}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {conLoader == true ?
              <button type="submit" className="next flagsave">
                Please Wait
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
              :
              <button type="submit" className="next flagsave" onClick={handleClick12}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Five;
