/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import ItemForm from '../ItemForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import InputMask from 'react-input-mask';
import info from '../info.png';
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert';
import MenuSidebar from './MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import { infodetls } from './SaveInfo';
import UploadImage from './UploadImage';
import useQuery from '../useQuery';


// const { useState } = React;
const Eight = ({ func, setForm, formData, navigation, saveAndExist, infobanks }) => {
  const { isNZBank, primaryBankAccountName, primaryBankAccountNumber, bankSwiftCode } = formData;
  // const { next } = navigation;

  const saveandexit = () => {
    if (isNZBank === 'true' || isNZBank === 'undefined') {
      formData.bankSwiftCode = '';
      func();
    } else {
      func();
    }
  };
  const [errror, setErrror] = useState();
  console.log(errror);
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  let { document } = documentFile64;
  const datetime = new Date();

  // async function uploadWithJSON(file, e) {
  //   formData.primaryBankProof = '';

  //   setErrror('');
  //   setdocumentFile64(file.name);
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);

  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   // next();
  //   setdocumentFile64(data.img64);
  //   if (
  //     data.img64 !== null &&
  //     data.img64 !== '' &&
  //     data.img64 !== 'undefined' &&
  //     data.img64 !== undefined
  //   ) {
  //     let dataupdate = {
  //       columnName:
  //         isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined'
  //           ? 'primaryBankProof'
  //           : 'secondaryBankProof',
  //       fileName:
  //         isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined'
  //           ? `primary_bank_details`
  //           : `secondary_Bank_details`,
  //       document: data.img64,
  //       userCode: formData.userCode
  //     };

  //     setImageLoader(true);
  //     axios
  //       .post(`${multisavedata}/genimageurl`, dataupdate, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.primaryBankProof = res.data.url;
  //         if (res.status === 200) {
  //           setImageLoader(false);
  //         } else {
  //           setImageLoader(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setImageLoader(false);
  //       });
  //   }
  //   localStorage.setItem('irdNumberFile', data.img64);
  // }
  let
    labeltxt = 'Upload proof of account';
  const columnName = 'primaryBankProof';

  const fileName = `primary_bank_details`;


  useEffect(() => {
    $('.toogles').click(function () {
        $('.toogles').removeClass('active');
        $(this).addClass('active');
    });
    $('.step-change').click(function () {
        $('.step-change').removeClass('active');
        $(this).addClass('active');
    });
    $('document').ready(function () {
        $("input[type='tel']").attr('readonly', true);
    });
    $('.flagsave').click(function () {
        let phoneCode = $('.form-control').val();
        localStorage.setItem('phoneCode', phoneCode);
    });
    $('#SHAREvALUE').keyup(function () {
        let input = $(this).val();
        if (input < 100) {
            return true;
        } else {
            return false;
        }
    });
});
  // useEffect(() => {
  //   $(document).ready(function () {
  //     $('#hide').click(function () {
  //       $('#showmy').hide();
  //       $('#hidemyy').show();
  //     });

  //     $('#show').click(function () {
  //       $('#hidemyy').hide();
  //       $('#showmy').show();
  //     });
  //   });
  //   $('.toogles').click(function () {
  //     $('.toogles').removeClass('actives');
  //     $(this).addClass('actives');
  //   });
  //   function readURL(input, imgControlName) {
  //     if (input.files && input.files[0]) {
  //       var reader = new FileReader();
  //       reader.onload = function (e) {
  //         $(imgControlName).attr('src', e.target.result);
  //       };
  //       reader.readAsDataURL(input.files[0]);
  //       $('#name_tag').text(input.files[0].name);
  //     }
  //   }

  //   $('#imag2').change(function () {
  //     var imgControlName = '#ImgPreview2';
  //     readURL(this, imgControlName);
  //     $('.preview2').addClass('it');
  //     $('.btn-rmv2').addClass('rmv');
  //   });

  //   $('#removeImage2').click(function (e) {
  //     e.preventDefault();
  //     $('#imag2').val('');
  //     $('#ImgPreview2').attr('src', '');
  //     $('.preview2').text('');
  //     $('.btn-rmv2').removeClass('rmv');
  //   });

  //   $(document).ready(function () {
  //     let isNZBank = true;
  //     localStorage.setItem('isNZBank', isNZBank);
  //     $('.yesno').click(function () {
  //       isNZBank = $(this).val();
  //       localStorage.setItem('isNZCitizen', isNZBank);
  //     });
  //   });
  // });
  function handleClick1(e) {
    if (formData.biometric === 'Biometric') {
      formData.class = 'Five';
      go('Five');
    } else {
      formData.class = 'Five';
      go('Six');
    }
  }

  function handleClick(e) {

    if (isNZBank === 'false') {
      if (formData.primaryBankAccountName === '' || formData.primaryBankAccountName === null || formData.primaryBankAccountName === undefined || formData.primaryBankAccountName === 'undefined') {
        $('.primaryBankAccountName').html('Please enter bank account name');
      }
      if (bankSwiftCode == '') {
        $('.bankSwiftCode').html('Please enter bank swift code');
      }
      if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null || primaryBankAccountNumber === 'undefined' || primaryBankAccountNumber === undefined) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
      if (formData.primaryBankProof === "" || formData.primaryBankProof === null || formData.primaryBankProof === undefined || formData.primaryBankProof === null) {
        $('.document').html('Please attach your document proof ');

      }

    } else {
      if (formData.primaryBankAccountName === '' || formData.primaryBankAccountName === null || formData.primaryBankAccountName === undefined || formData.primaryBankAccountName === 'undefined') {
        $('.primaryBankAccountName').html('Please enter bank account name');
      }

      if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null || primaryBankAccountNumber === 'undefined' || primaryBankAccountNumber === undefined) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
      if (formData.primaryBankProof === "" || formData.primaryBankProof === null || formData.primaryBankProof === undefined || formData.primaryBankProof === null) {
        $('.document').html('Please attach your document proof ');

      }
    }

    if (isNZBank === 'false') {
      if (formData.primaryBankAccountName === '' || formData.primaryBankAccountName === null || formData.primaryBankAccountName === undefined || formData.primaryBankAccountName === 'undefined') {
        $('.primaryBankAccountName').html('Please enter bank account name');
      }
      else if (bankSwiftCode == '') {
        $('.bankSwiftCode').html('Please enter bank swift code');
      }
      else if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null || primaryBankAccountNumber === 'undefined' || primaryBankAccountNumber === undefined) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
      else if (formData.primaryBankProof === "" || formData.primaryBankProof === null || formData.primaryBankProof === undefined || formData.primaryBankProof === null) {
        $('.document').html('Please attach your document proof ');

      }
      else {
        // formData.class = 'Twentyone';
        // go('Twentyone1');
        formData.class = 'Nine';
        next();
      }

    } else {
      if (formData.primaryBankAccountName === '' || formData.primaryBankAccountName === null || formData.primaryBankAccountName === undefined || formData.primaryBankAccountName === 'undefined') {
        $('.primaryBankAccountName').html('Please enter bank account name');
      }

      else if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null || primaryBankAccountNumber === 'undefined' || primaryBankAccountNumber === undefined) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
      else if (formData.primaryBankProof === "" || formData.primaryBankProof === null || formData.primaryBankProof === undefined || formData.primaryBankProof === null) {
        $('.document').html('Please attach your document proof ');

      }
      else {
        // formData.class = 'Twentyone';
        // go('Twentyone1');
        formData.class = 'Nine';
        next();
      }

    }

  }

  function removeError() {
    $('.error').html('');
  }
  const { go, next } = navigation;
  saveAndExist = () => {
    formData.status = 'PENDING';
    go('submit');
  };


  infobanks = () => {
    swal({
      text: 'The nominated Bank Account must be in the same name as the investment entity/owner. Maximum file size is 2MB.'
    });
  };
  useEffect(() => {
    if (isNZBank === 'true' || isNZBank === 'undefined') {
      formData.bankSwiftCode = '';
    }
  });

  const handleChange = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (formData.check4 === false) {
      if (formData.primaryBankAccountName === '' || formData.primaryBankAccountName === null || formData.primaryBankAccountName === undefined || formData.primaryBankAccountName === 'undefined') {
        $('.primaryBankAccountName').html('Please enter bank account name');
      }
      // if ( formData.primaryBankProof === ""|| formData.primaryBankProof===null ) {
      //   $('.document').html('Please attach your document proof ');

      // }
      if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null || primaryBankAccountNumber === 'undefined' || primaryBankAccountNumber === undefined) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
      if (bankSwiftCode == '') {
        $('.bankSwiftCode').html('Please enter bank swift code');
      }
      if (
        formData.primaryBankProof === '' ||
        formData.primaryBankProof === null ||
        formData.primaryBankProof === 'undefined' ||
        formData.primaryBankProof === undefined
      ) {
        $('.primaryBankProof').html('Please upload primary bank proof');
      }
    }
  });


  const [image, setImage] = useState();
  const downloadImage = (imageUrl) => {
    let postadv = {
      downloadUrl: imageUrl
    };
    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };

  function removeErrorOnlyOne(valueClass) {
    $(`.${valueClass}`).html('');
  }
  const query = useQuery();

  let queryValue = query.get("update");
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          {
            formData.investmentType == "Company" ?
              <h2>Step 5: Bank Account Details</h2> :
              <h2>Step 4: Bank Account Details</h2>


          }
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          {queryValue ? <p></p> :
          <div
          disabled={formData.isPrimary == 1 ? false : true}>
            <div className="save-exit se_invff">
              <p className="save next" onClick={saveandexit}>
                SAVE DATA{' '}
              </p>
              <img src={info} onClick={infodetls} className="info" alt="" />
            </div>
            </div>
          }

          <Row className="">
            <Col xs={8}>
              <label>Is this a New Zealand bank account?</label>
            </Col>
            <Col xs={12}>
              <div className="button_group space-bottom">
                <div class="switch-field nospace">
                  <div className="check_YN_Bank swtich11" >
                    <input
                      disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id="radio-two"
                      name="isNZBank"
                      value="false"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                    <label
                      for="radio-two"
                      className={`toogles postalAddressButtons ${isNZBank === 'false' ? 'actives' : null
                        }`}
                      id="show"
                    >
                      No
                    </label>
                    <input
                      disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id="radio-one"
                      className="yesno"
                      name="isNZBank"
                      value="true"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                    <label
                      for="radio-one"
                      className={`toogles postalAddressButton ${typeof isNZBank === 'undefined' || isNZBank === 'true' ? 'actives' : null
                        }`}
                      id="hide"
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </div>
            </Col>

            <div className="col-md-12">
              {isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined' ? (
                <div className="row">
                  <Col xs={12}>
                    <ItemForm
                      disabled={formData.isPrimary == 1 ? false : true}
                      label="Bank account name "
                      className="quantity"
                      name="primaryBankAccountName"
                      value={primaryBankAccountName}
                      onPaste={handleChange}
                      maxLength={170}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      type="text"
                      placeholder="Enter account name"
                      onKeyPress={removeError}
                    />
                    <span className="error primaryBankAccountName"></span>
                    <label>Bank account number</label>
                    <InputMask
                      disabled={formData.isPrimary == 1 ? false : true}
                      mask="99-9999-9999999-999"
                      maskChar={null}
                      maskPlaceholder={null}
                      className="numbers"
                      name="primaryBankAccountNumber"
                      type=""
                      value={primaryBankAccountNumber}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      placeholder="xx-xxxx-xxxxxxx-xxx"
                      onKeyPress={removeError}
                    />
                    <span className="error primaryBankAccountNumber"></span>
                  </Col>
                </div>
              ) : (
                <div className="row">
                  <Col xs={12}>
                    <label>Bank account name</label>
                    <ItemForm
                      disabled={formData.isPrimary == 1 ? false : true}
                      className="quantity"
                      name="primaryBankAccountName"
                      type="text"
                      onPaste={handleChange}
                      value={primaryBankAccountName}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      onKeyPress={removeError}
                      placeholder="Enter account name"
                    />
                    <span className="error primaryBankAccountName"></span>
                    <label>Bank swift code</label>
                    <ItemForm
                      disabled={formData.isPrimary == 1 ? false : true}
                      className="digitsOnly"
                      maxLength={16}
                      type=""
                      name="bankSwiftCode"
                      value={bankSwiftCode}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      placeholder="Enter swift code"
                      onKeyPress={removeError}
                    />{' '}
                    <span className="error bankSwiftCode"></span>
                    <label>Account/IBAN Number</label>
                    <ItemForm
                      disabled={formData.isPrimary == 1 ? false : true}
                      maxlength="35"
                      className="numbers"
                      type="text"
                      name="primaryBankAccountNumber"
                      value={primaryBankAccountNumber}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                      placeholder="Enter account number"
                      onKeyPress={removeError}
                    />
                    <span className="error primaryBankAccountNumber"></span>
                  </Col>
                </div>
              )}
            </div>
          </Row>

          {/* <Row className="fullinput">
            <Col xs={12}>
            <div class="yes backbt">
                  <button className="uploadBtn">
                    <span class="btn_upload bacnkbtn">
                    <input
                            type="file"
                            id="imag2"
                            title=""
                            value={document}
                            onChange={(e) => {
                              uploadWithJSON(e.target.files[0]);
                              removeError();
                            }}
                            onClick={(e) => (e.target.value = null)}
                          />
                                          Upload proof of account

                    </span>
                  </button>
                  <img src={info} onClick={infobanks} className="info backi" alt="" />

                
                </div>
              
            
              <label className="bankClick"></label>
              <span className="error file ml-0"></span>
            </Col>

          </Row> */}
          {/* <div className="spceimg">
            {formData.primaryBankProof === '' ||
              formData.primaryBankProof === null ||
              formData.primaryBankProof === 'undefined' ||
              formData.primaryBankProof === undefined ? (
              ''
            ) : (
              <a href="#" className="licedocument-downloade" onClick={() => { downloadImage(formData.primaryBankProof) }}>
                {' '}
                <div className="main-downloade-wrapper">
                  <div className="downloads">
                    <i className="fa fa-download downloadeStyle"></i>
                  </div>
                  <p className="downloadeMsg">View attached file </p>
                </div>
              </a>
            )}
            <span id="name_tag" class="preview2"></span>
            <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
            {imageLoader === true ? (
              <div class="progress">
                <span class="progress-bar" style={{ width: '100%' }}></span>
              </div>
            ) : null}
          </div> */}
          {formData.isPrimary == 1 ?
            <>
              <UploadImage
                disabled={formData.isPrimary == 1 ? false : true}
                formData={formData}
                columnName={columnName}
                fileName={fileName}
                // labeltxt={labeltxt}
                buttonName='Upload proof of account'
                showIcon={true}
                inform={infobanks}
                bankcls={true}
                valuedefine="bank"

              />
              <span className="error document"></span>
            </> : <button>Upload proof of account</button>}
          {/* <UploadImage
            disabled={formData.isPrimary == 1 ? false : true}
            formData={formData}
            columnName={columnName}
            fileName={fileName}
            // labeltxt={labeltxt}
            buttonName='Upload proof of account'
            showIcon={true}
            inform={infobanks}
            bankcls={true}
            valuedefine="bank"

          />
          <span className="error document"></span> */}

          <div className="fotter-btn">
            <button className="preview" onClick={handleClick1}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Eight;
