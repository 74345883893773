import React, { useState, useEffect } from 'react';
import rightarrow from '../rightarrow.png';
import InputMask from 'react-input-mask';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import Entity from '../Entity';
import Fundss from '../Fundss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../info.png';
import MenuSidebar from './MenuSidebar';
import NumberFormat from "react-number-format";
import { infodetls } from './SaveInfo';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { audience, duvalapi, multisavedata } from '../../auth/Configuration';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { ValueSource } from 'react-avatar';
import Zero from './Zero';
import useQuery from '../useQuery';
import Entity2 from '../Entity2';

const Address = ({
  func,
  setForm,
  formData,
  navigation,
  fun2,
  user,
  isAuthenticated,
}) => {
  const { next } = navigation;
  const props = { formData, setForm, navigation };
// const queryValue=sessionStorage.getItem("queryValue");
// alert(queryValue);
// console.log("aaaa",aa)

  const query = useQuery();

  let queryValue = query.get("name");
  let queryValue2 = query.get("update");
  let queryValue3 = query.get("type")
  
  

  // { queryValue == "invest" ?  func() == false: true};

  const saveandexit = () => {
    if (queryValue != null && queryValue != undefined && queryValue != "") {
      $('.investmentTypeerror').html('button hide ');
    }
    else {
      if (
        typeof formData.investmentType === 'undefined' ||
        formData.investmentType === '' ||
        formData.investmentType === 'Select' ||
        formData.investmentType === null
      ) {
        $('.investmentTypeerror').html('Please select the investment type ');
      }

      //   else if( typeof formData.totalInvestment === 'undefined' ||
      // formData.totalInvestment === 0 ||
      // formData.totalInvestment === '' ||
      // formData.totalInvestment === null || formData.totalInvestment.replace(/,/g, '') < 250000 ){
      //   $('.unitserror').html('Miminmum investment should be $250,000');

      // }
      //  else if (
      //   (typeof formData.totalInvestment === 'undefined' ||
      //   formData.totalInvestment === 0 ||
      //   formData.totalInvestment === '' ||
      //   formData.totalInvestment === null ||
      //   formData.totalInvestment.replace(/,/g, '') % 50000 !== 0 

      //   )


      // ) {
      //   $('.unitserror').html('increase by multiples of $50,000');
      // } 
      else {
        func();
      }
    }


  }
  const { investmentType, totalInvestment, fundCode, insdtt } = formData;

  formData.fundCode = fundCode;
  //
  const brandId = ""


  function handleClick(e) {
    // formData.verification=""
    if (
      typeof formData.investmentType === 'undefined' ||
      formData.investmentType == '' ||
      formData.investmentType == 'Select' ||
      formData.investmentType == null
    ) {
      $('.investmentTypeerror').html('Please select the type investment entity ');
    }
    // else if (
    //   formData.fundCode === 'undefined' ||
    //   formData.fundCode === ''||
    //   formData.fundCode === 'Select'||
    //   formData.fundCode === null


    // ) {
    //   $('.funderror').html('Please select investment fund');
    // }else if( typeof formData.totalInvestment === 'undefined' ||
    // formData.totalInvestment === 0 ||
    // formData.totalInvestment === '' ||
    // formData.totalInvestment === null || formData.totalInvestment.replace(/,/g, '') < 250000 ){
    //   $('.unitserror').html('Minimum investment amount $250,000, then increase by multiples of $50,000');

    // }
    //  else if (
    //   (typeof formData.totalInvestment === 'undefined' ||
    //   formData.totalInvestment === 0 ||
    //   formData.totalInvestment === '' ||
    //   formData.totalInvestment === null ||
    //   formData.totalInvestment.replace(/,/g, '') % 50000 !== 0

    //   )


    // ) {
    //   $('.unitserror').html('Minimum investment amount $250,000 then increase by multiples of $50,000');
    // }
    else {
      go("EntityOne")
      formData.class = 'Jointhird';

      if (formData.investmentType === 'Joint') {

        
        if(queryValue ==null || queryValue ==undefined || queryValue ==""){
          formData.class = 'Jointhird';
        next();
        }
        else{
          formData.class = 'Five';
          go('Jointeight')
        }

      } else if (formData.investmentType === 'Company') {
        
        if(queryValue ==null || queryValue ==undefined || queryValue ==""){
          go("EntityOne")
        formData.class = 'Jointhird';
        }
        else{
          formData.class = 'Five';
          go('Six')
        }

      }
      else if (formData.investmentType === 'Trust') {
        
        //  formData.class = 'Jointhird';
        if(queryValue ==null || queryValue ==undefined || queryValue ==""){
          go("TrustFirst")
        }
        else{
          formData.class = 'Five';
          go('Five')
        }
       

      }

      else {
        if(queryValue ==null || queryValue ==undefined || queryValue ==""){
          formData.class = 'Third';
          next();
        }
        else{
          formData.class = 'Five';
          go('Five')
        }
       
      }
    }
  }

  function removeError() {
    $('.error').html('');
  }
  $('.numbers').on('paste', function (e) {
    $('.error').html('');
  });
  const { go } = navigation;
    


  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });
  const addsymbol = "+".sup()
  const sessionClear = (e) => {
    formData.userResidentialAddress = null
    formData.userResidentialAddress2 = null
    formData.countryyCode = null
    sessionStorage.removeItem("street_number1");
    sessionStorage.removeItem("locality1");
    sessionStorage.removeItem("administrative_area_level_11");
    sessionStorage.removeItem("sublocality_level_11");
    sessionStorage.removeItem("postal_code1");
    sessionStorage.removeItem("premise1");
    sessionStorage.removeItem("route1");
    sessionStorage.removeItem("country1");
    sessionStorage.removeItem("countryCode1");
    sessionStorage.removeItem("street_number");
    sessionStorage.removeItem("locality");
    sessionStorage.removeItem("administrative_area_level_1");
    sessionStorage.removeItem("sublocality_level_1");
    sessionStorage.removeItem("postal_code");
    sessionStorage.removeItem("premise");
    sessionStorage.removeItem("route");
    sessionStorage.removeItem("residentialAddress1");
    sessionStorage.removeItem("country");
    sessionStorage.removeItem("countryCode");
    sessionStorage.removeItem("PostalAddress1");
    sessionStorage.removeItem("residentialAddress2");
    sessionStorage.removeItem("PostalAddress2");

  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const savefunc = (value) => {
    fun2(value);
  }

  const [loader, setLoader] = useState();
  const [loader1, setLoader1] = useState();


  const TermsPage = () => {
    formData.termsss1 = false
    formData.termsss2 = false
    formData.termsss3 = false
    formData.futureInvestementOpportunities = false
  }
  const applications = async (value) => {
    setLoader(true)

  var postdata = {
    investmentType: value
  }
  var tes = value.toLowerCase();
  await axios.put(`${multisavedata}/onboard/${tes}`, postdata, {

    headers: { Authorization: `Bearer ${formData.token}` }
  }).then((res) => {
    if(queryValue){
      go('Five')
    }
    setLoader1(true);
    const timedd = setTimeout(() => {
      setLoader1(false);
    }, 3000);
    return () => clearTimeout(timedd);


  })


  formData.insdtt = "ddddddd";
  console.log(formData.insdtt, "dddddddd");

   
setLoader1(true);
const timedd = setTimeout(() => {
  setLoader1(false);
}, 3000);
return () => clearTimeout(timedd);


  }
  useEffect(() => {
    if(queryValue){
      applications(queryValue2)
    }
  },[])
 

  useEffect(() => {
    if (formData.check2 === false) {
      if (
        typeof formData.investmentType === 'undefined' ||
        formData.investmentType == '' ||
        formData.investmentType == 'Select' ||
        formData.investmentType == null
      ) {
        $('.investmentTypeerror').html('Please select the type investment entity ');
      }
      //    if (
      //     formData.fundCode === 'undefined' ||
      //     formData.fundCode === ''||
      //     formData.fundCode === 'Select'||
      //     formData.fundCode === null
      //   ) {
      //     $('.funderror').html('Please select investment fund');
      //   }
      // if (
      //   formData.totalInvestment === 0 ||
      //   formData.totalInvestment === '' ||
      //   formData.totalInvestment < 250000
      // ) {
      //   $('.unitserror').html(
      //     'Minimum investment amount $250,000, then increase by multiples of $50,000'
      //   );
      // }
      //  if (
      //   formData.totalInvestment === 0 ||
      //   formData.totalInvestment === '' ||
      //   formData.totalInvestment.replace(/,/g, '') % 50000 !== 0
      // ) {
      //   $('.unitserror').html(
      //     'Minimum investment amount $250,000, then increase by multiples of $50,000'
      //   );
      // }
    }
  })


  const [abc, setAbc] = useState({})

  // function apiget(){

  //   let token = sessionStorage.getItem("tokii")

  //  let res=  axios.get(`${duvalapi}/user/details/${formData.investmentType}`, {
  //     headers: { Authorization: `Bearer ${token}` }
  //   }).then((res)=>{
  //     setAbc(res)

  //   }).catch(()=>{

  //   })
  // }

  // apiget();
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container select_account_loader">
        <div className="heading__top">
          <h2>Step 1: Investment Details</h2>
        </div>

        {/* <div>

          {
            loader1 == true ?
              <Zero investmentTypde={investmentType} {...props} /> : <></>
          }
        </div> */}


        <div className="form second_form">
          <img src={logo} alt="img" className="imageCover" />
          {(queryValue != null && queryValue != undefined && queryValue != "" ) ? <p></p> :
            <div
              disabled={formData.isPrimary == 1 ? false : true}
              className="save-exit  se_invff">
              <p
                className="save next "
                onClick={saveandexit}>
                SAVE DATA{' '}
              </p>
              <img src={info} onClick={infodetls} className="info" />
            </div>

          }


          <div className="mmyset">

           
          <>
                  <Entity
                    disabled={formData.isPrimary == 1 ? false : true}
                    autoComplete="off"
                    type="text"
                    label="What kind of application are you making?"
                    className={
                      investmentType === 'Select' || investmentType === undefined || investmentType === "undefined" || investmentType === '' || investmentType === null
                        ? 'colorgrey '
                        : 'colorblack '
                    }
                    onClick={() => {

                      removeError()
                    }}
                    onChange={(e) => {
                      TermsPage();
                      setForm(e);

                      removeError();
                      sessionClear(e);
                      // savefunc(e.target.value);
                      applications(e.target.value)
                      // apiget();

                    }}
                    name="investmentType"
                    value={investmentType}
                  />
                </>

            <span className="error investmentTypeerror"></span>
            <div>

              {
                loader1 == true ?
                  <Zero investmentTypde={investmentType} {...props} /> : <></>
              }
            </div>

          </div>

          {/* <Row className="">
            <Col xs={12}>
              <label>Select which Investment fund you are applying for</label>

            </Col>
            <Col xs={12}>
              <Fundss
            //disabled={formData.isPrimary == 1 ? false : true}
                type="text"
                autoComplete="off"
                className={
                  fundCode === 'Select' || fundCode === undefined || fundCode === "undefined" || fundCode === '' || fundCode === null
                    ? 'colorgrey'
                    : 'colorblack fundesss'
                }
                onClick={removeError}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
                name="fundCode"
                value={fundCode}
              />
              <span className="error funderror"></span>
            </Col>

            <br />
            <Col>
              {fundCode === 'Du Val Mortgage Fund Limited Partnership' ? (
               <>
                <p>
                  DU VAL MORTGAGE FUND LIMITED PARTNERSHIP FSP1000594 IS A 10% P.A RETURN<sup>+</sup> INVESTMENT* MANAGED BY DU VAL CAPITAL PARTNERS.
                </p>
                <p> * Investment in the Du Val Mortgage Fund Limited Partnership is only available to wholesale investors as defined in the Financial Markets Conduct Act 2013.</p>
                <p> <sup>+</sup> For further information about the return and the risks please go to <a  className="duvalpartners_Link" href="http://www.duvalpartners.com" target="_blank">www.duvalpartners.com</a> to request a copy of the Information Memorandum.</p></>
              ) : brandId === '2' ? (
                <p></p>
              ) : (
                ''
              )}
            </Col>
          </Row> */}

          {/* <label>Enter the $ amount you wish to apply for (minimum investment $250,000)</label> */}
          {/* <InputMask */}
          {/* <NumberFormat
            //disabled={formData.isPrimary == 1 ? false : true}
            isNumericString={true}
            thousandSeparator={true}
            maxLength={14}
            autocomplete="off"
            className="totalnumber aa"
          
            maskChar={null}
            name="totalInvestment"
            value={totalInvestment}
            onKeyPress={removeError}
            onChange={(e) => {
              setForm(e);
              removeError();
            }}
            placeholder="Minimum investment amount $250,000 then increase by multiples of $50,000"
          /> */}
          <span id="errmsg"></span>
          <span className="error unitserror"></span>


          <div className="fotter-btn second">

            <button type="submit" className="next se_inv" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Address;
