import React from "react";
import info from '../info.png';
import swal from 'sweetalert';
import logo from '../logo.png';
const Jointtwentyten = ({ setForm, formData, navigation, saveAndExist,savedata,infodetls }) => {
  const { } = formData;
  const { previous, next } = navigation;
  const { go } = navigation;
  saveAndExist = () => {
    formData.status ="PENDING"
    formData.step ="22"
    // swal({
    //   text: "Your application saved",
 
    // });
    go("submit")
    
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  // savedata = () => {
  //   formData.status ="Submission"
  //   formData.step ="22"
  //   swal({
  //     text: "Data Saved sucessfully.",
  //     icon: "success",
  //   });
  //   go("submit")
  // }
  console.log();
  return (
    <div className="main_container">
    <div className="form">


      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls}  className="info" />
      </div>
      <h3>Application Submitted</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
       

      </div>
      <p>Thank you for submitting your application, we'll let you know if we need anything further.</p><br />
      {/* <button className="ok" onClick={() => go("submit")} >OK</button> */}
<button className="ok" onClick={() => go("submit")} >OK</button>

      <br />
      {/* <div className="fotter-btn">
        <button className="preview" onClick={previous}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next"onClick={next}  >Continue<img src={rightarrow} className="rightarrow"/> </button>
        </div> */}
    </div>
    </div>
  );
};

export default Jointtwentyten;
