import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ItemForm from '../ItemForm';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import $ from 'jquery';
import { multisavedata } from '../../auth/Configuration';

const SearchAddress = ({ formData, placeholder, label, name, value2, className }) => {

  const [running, setRunning] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState();
  const [addressd, setAddressd] = useState(value2);
  const [showing, setShowing] = useState(true);


  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24
      }}
      spin
    />
  );

  useEffect(() => {

    const delayDebounceFn = setTimeout(() => {
      if (searchTerm != null && searchTerm != '' && searchTerm != 'undefined') {
        setRunning(true);
      }
      const add = {
        search_address: searchTerm
      }
      axios
        .post(
          `${multisavedata}/search/google/address`, add, {
          headers: { Authorization: `Bearer ${formData.token}` }
        }
        )
        .then((res) => {
          setRunning(false);
          setOptions(res.data);

        }).catch(() => {
          setRunning(false);
        })
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const showed = () => {
    if (showing == true) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };


  const shooowed = () => {
    if (showing == false) {
      setShowing(true);
    } else {
      setShowing(true);
    }
  };

  function removeError() {
    $('.error').html('');
  }


  const setAddress = (val) => {
    setAddressd(val)
    // if( valuedefine="add1"){
    formData.isPrimaryResdAddress = val;
    // }

  }
  return (
    <>
      <Row className='google_add'>
        <Col>
          <label>{label}</label>
          <ItemForm
            //disabled={formData.isPrimary == 1 ? false : true}
            type="text"
            name={name}
            className={className}
            onKeyPress={removeError}
            onChange={(e) => {
              setAddress(e.target.value)
              setSearchTerm(e.target.value);
              removeError();

            }}
            value={addressd}
            onClick={() => shooowed()}
            placeholder={placeholder}
          />

          {running == true ? (
            <Spin indicator={antIcon} className="spinnner cust_loader" />
          ) : null}
        </Col>
      </Row>


      <div className='check_btn'>
        {showing == true
          ? options &&
          options.map((data) => {
            return (
              <button
                className="trustSix"
                onClick={(e) => {
                  setAddress(data.address)
                  showed();
                }}
              >
                {data.address}
              </button>
            );
          })
          : ''}
      </div>

    </>
  )
};

export default SearchAddress;