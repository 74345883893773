import React, { useState, useEffect } from 'react';
import ItemForm from '../ItemForm';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import ReactFlagsSelect from 'react-flags-select';
import { getCode } from 'country-list';
import Rwt from '../Rwt';
import Nrwt from '../Nrwt';
import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from "../../auth/Configuration"
import axios from 'axios';
import UploadImage from '../Individual/UploadImage';
import useQuery from '../useQuery';


const EntityFour = ({ func, setForm, formData, navigation, infodetls, infodetlss }) => {

  const {
    isCompanyOhterCountryCitizen,
    companyIrdNumber,
    companyFundReason,
    companyGenerateIncome,
    rwtValueComp,
    checkboxComp,
    rwtComp,
    countrtyCode,
    companyFundsDocument,
    isNZBank
  } = formData
  const query = useQuery();

  let queryValue = query.get("update");
  const [selected2, setSelected2] = useState(
    formData.companyCountryOfResidence !== null &&
      formData.companyCountryOfResidence !== '' &&
      formData.companyCountryOfResidence !== 'undefined' &&
      formData.companyCountryOfResidence !== undefined
      ? formData.companyCountryOfResidence
      : ''
  );

  if (isCompanyOhterCountryCitizen === 'true') {
    formData.companyCountryOfResidence = selected2;
  } else {
    formData.companyCountryOfResidence = null;
  }

  infodetls = () => {
    swal({
      text:
        "Please attach  evidence as to how this income is generated. Examples include bank statements, letter from Solicitor/Accountant/Donor confirming details of the gift."
      //"You can save your application data by clicking on the button Save Data. You can then logout, and resume your application at a later stage by clicking on the link we have previously sent in an email to you asking you to verify your email address.",
    });
  };

  infodetlss = () => {
    swal({
      text: 'You can save your application data by clicking on the button Save Data. You can then logout, and resume your application at a later stage by clicking on the link we have previously sent in an email to you asking you to verify your email address.'
    });
  };

  function Onprevious(e) {
    // formData.class = "Second"
    formData.class = 'Jointhird';
    go("EntityThird1")
  }

  function handleClick(e) {
    if (formData.companyFundsDocument === null || formData.companyFundsDocument == undefined || formData.companyFundsDocument === "") {
      $('.companyFundsDocument').html('Please attach company Funds Document')
    } else {
      formData.class = 'Third'
      go('CompanyThird')
    }

  }

  function removeError() {
    $('.error').html('');
  }

  const saveandexit = () => {
    func();
  };
  const { go } = navigation;
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  let { document } = documentFile64;
  const datetime = new Date();
  const [errror, setErrror] = useState();



  let labeltxt = 'Please attach  evidence as to how this income is generated';
  const columnName = 'companyFundsDocument';
  const fileName = `Company_Funds_Document`;
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          <h2>Step 2: Company Details</h2>
        </div>
        <div className="form entity_four">
          <img src={logo} alt="img" className="imageCover" />
          {queryValue ? <p></p> :
            <div className="save-exit se_invff">
              <p className="save next"
                disabled={formData.isPrimary == 1 ? false : true}
                onClick={saveandexit}>SAVE DATA </p>
              <img src={info} onClick={infodetlss} className="info" />
            </div>
          }
          <Row className="">
            <Col md={6}>
              <label>Company IRD number</label>
              <InputMask
                disabled={formData.isPrimary == 1 ? false : true}
                autoComplete="off"
                mask="999-999-999"
                maskChar={null}
                className="full ird_number capitaluserEmail"
                type="text"
                name="companyIrdNumber"
                value={companyIrdNumber}
                maskPlaceholder={null}
                // onKeyPress={removeError}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                placeholder="xxx-xxx-xxx"
              />
              <span className="error userIrdNumbers"></span>
            </Col>
          </Row>
          <Row className="">
            <Col md={6}>
              <label>Please confirm your tax type</label>


              <Rwt
                disabled={formData.isPrimary == 1 ? false : true}
                autoComplete="off"
                type="text"
                className={
                  rwtComp === 'Select' ||
                    rwtComp === undefined ||
                    rwtComp === 'undefined' ||
                    rwtComp === '' ||
                    rwtComp === null
                    ? 'colorgrey capitaluserEmail'
                    : 'colorblack capitaluserEmail colorblack_2'
                }
                onClick={removeError}
                onChange={(e) => {
                  setForm(e);
                  removeError();
                }}
                name="rwtComp"
                value={formData.rwtComp !== undefined && formData.rwtComp !== "" ? formData.rwtComp : ""}
              />
              <span className="error funderror"></span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {rwtComp === 'Resident Withholding Tax' ? (
                <Nrwt
                  disabled={formData.isPrimary == 1 ? false : true}
                  type="text"
                  label="Select your Resident Withholding Tax rate"
                  className={
                    rwtValueComp === 'Select' ||
                      rwtValueComp === undefined ||
                      rwtValueComp === '' ||
                      rwtValueComp === null
                      ? 'colorgrey colorGrey_2'
                      : 'colorblack colorBlack_2'
                  }
                  onClick={removeError}
                  onChange={(e) => {
                    setForm(e);
                    removeError();
                  }}
                  name="rwtValueComp"
                  value={formData.rwtValueComp}
                />
              ) : rwtComp !== 'Resident Withholding Tax' && rwtComp !== 'AIL 2%' && rwtComp === 'Non Resident Withholding Tax' ? (
                <div className="checkbox-my nrdt_chack">
                  {' '}
                  <div className="checkmystyle">
                    {' '}
                    <>
                      <input
                        disabled={formData.isPrimary == 1 ? false : true}
                        type="checkbox"
                        id="TickHere"
                        defaultChecked={checkboxComp === true ? true : false}
                        name="checkboxComp"
                        onChange={(e) => {
                          setForm(e);
                          // checkboxchange(e);
                        }}
                      />
                      <span class="checkmark"></span>{' '}
                      <label for="TickHere">Tick here if you hold a valid Resident Withholding Tax exemption.</label>
                    </>
                  </div>{' '}
                </div>
              ) : (
                ''
              )}
            </Col>

            {rwtValueComp === 'Exempt' && rwtComp == 'Resident Withholding Tax' ?

              <Col xs={12}>

                <UploadImage
                  formData={formData}
                  columnName={"companyExamptProof"}
                  fileName={"companyExampt_Proof"}
                  labeltxt={labeltxt}
                  buttonName='CLICK TO ATTACH'
                  valuedefine={"companyExamptProof"}
                />
              </Col>

              : ""
            }
          </Row>

          <Row>
            <Col lf={12}>
              <label>
                Is the Company a U.S. citizen, Or U.S. resident, Or a tax resident in any other country?
              </label>
            </Col>
            <Col lg={12}>
              <div className="button_group space-bottom">
                <div class="switch-field nospace">
                  <div className="check_YN_Bank swtich11">
                    <input
                      disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id="radio-one"
                      className="yesno"
                      name="isCompanyOhterCountryCitizen"
                      value="false"
                      onChange={(e) => {
                        setForm(e);
                        // removeError();
                      }}
                    />
                    <label
                      for="radio-one"
                      className={`toogles postalAddressButton ${typeof isCompanyOhterCountryCitizen === 'undefined' || isCompanyOhterCountryCitizen === 'false'
                        ? 'actives'
                        : null
                        }`}
                      id="show"
                    >
                      No
                    </label>
                    <input
                      disabled={formData.isPrimary == 1 ? false : true}
                      type="radio"
                      id="radio-two"
                      name="isCompanyOhterCountryCitizen"
                      value="true"
                      onChange={(e) => {
                        setForm(e);
                        // removeError();
                      }}
                    />
                    <label
                      for="radio-two"
                      className={`toogles postalAddressButtons
                        ${isCompanyOhterCountryCitizen === 'true' ? 'actives' : null
                        }`
                      }
                      id="hide"
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <>
              <div className="fullspace pandingfinsh">
                {' '}
                {isCompanyOhterCountryCitizen === 'true' ? (
                  <div className="col-md-12">
                    <Row className="FlagsSelectcss">
                      <Col md={12}>
                        <div onClick={removeError}>
                          <label>Country of tax residence</label>
                        </div>
                      </Col>
                      <Col md={6} className='tax-residence-list'>
                        <ReactFlagsSelect
                          disabled={formData.isPrimary == 1 ? false : true}
                          autoComplete="off"
                          style={{ with: "100% !importantd" }}
                          name="companyCountryOfResidence"
                          selected={selected2}
                          value={formData.companyCountryOfResidence}
                          placeholder="Select"
                          onChange={(e) => {
                            setForm(e);
                            removeError();
                          }}
                          onSelect={(code) => setSelected2(code)}
                          countries={countrtyCode}
                          defaultCountry={
                            formData.companyCountryOfResidence && formData.companyCountryOfResidence !== null
                              ? getCode(formData.companyCountryOfResidence.toUpperCase())
                              : null
                          }
                        />

                        <span className="error residingCountry"></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div onClick={removeError}>
                          <label>Tax Identification Number (TIN)</label>
                        </div>
                      </Col>
                      <Col md={6}>
                        <input
                          disabled={formData.isPrimary == 1 ? false : true}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          pattern='/^[ A-Za-z0-9_@./#&+-]*$/'
                          maxlength={11}
                          autoComplete="off"
                          className='capitaluserEmail'
                          placeholder="Enter tax identification or N/A"
                          name="companyTinNumber"
                          value={formData.companyTinNumber}
                          onChange={(e) => {
                            setForm(e);
                            removeError();
                          }}
                        />
                        <span className="error tinNumber residingCountry"></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div onClick={removeError}>
                          <label>Reason if TIN is not available</label>
                        </div>
                      </Col>
                      <Col md={6}>
                        <input
                          disabled={formData.isPrimary == 1 ? false : true}
                          autoComplete="off"
                          className='capitaluserEmail'
                          placeholder="Enter reason"
                          name="companyTinReason"
                          value={formData.companyTinReason}
                          onChange={(e) => {
                            setForm(e);
                            removeError();
                          }}
                        ></input>
                        <span className="error residingCountry"></span>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          </Row>
          <Row>
            <Col md={12}>
              <label>Reasons for Investment with MyFarm Investments</label>
            </Col>

            <Col md={12}>
              <textarea
                disabled={formData.isPrimary == 1 ? false : true}
                className="entitytextarea"
                type="text"
                name='companyFundReason'
                rows="2"
                cols="80"
                value={companyFundReason}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                placeholder="Please type  your reason for investment"
              />
            </Col>

            <Col md={12}>
              <label>How does the Company generate an income?</label>
            </Col>

            <Col md={12}>
              <textarea
                disabled={formData.isPrimary == 1 ? false : true}
                className="entitytextarea"
                type="text"
                name='companyGenerateIncome'
                rows="2"
                cols="80"
                value={companyGenerateIncome}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                placeholder="Please explain the Source of Funds or Source of Wealth"
              />
            </Col>
          </Row>

          {/* <Row className="fullinput">
            <Col xs={3}>
              <div class="yes1">
                <button className="click-hover">
                  <span class="btn_upload">
                    <input
                      disabled={formData.isPrimary == 1 ? false : true}
                      type="file"
                      id="imag2"
                      title=""
                      value={document}
                      onChange={(e) => {
                        uploadWithJSON3(e.target.files[0]);
                        removeError();
                      }}
                      onClick={(e) => { (e.target.value = null); removeError(); }}
                    />
                  </span>
                  CLICK TO ATTACH
                </button>
              </div>
            </Col>

            <Col xs={9} className="attach-style-proof">
              <label className='bankClick'> <img src={info} onClick={} className="info myinfo trr" /></label>
              <span className="error file ml-0"></span>
            </Col>
          </Row>
          <div className="spceimg">
            {formData.companyFundsDocument === '' ||
              formData.companyFundsDocument === null ||
              formData.companyFundsDocument === 'undefined' ||
              formData.companyFundsDocument === undefined ? (
              ''
            ) : (
              <a href={formData.companyFundsDocument} className="licedocument-downloade">
                {' '}
                <div className="main-downloade-wrapper">
                  <div className="downloads">
                    <i className="fa fa-download downloadeStyle"></i>
                  </div>
                  <p className="downloadeMsg">View file already provided </p>
                </div>
              </a>
            )}
            <span id="name_tag" class="preview2"></span>
            <input
              //disabled={formData.isPrimary == 1 ? false : true}
              type="button" id="removeImage2" value="x" class="btn-rmv2" />
            {imageLoader === true ? (
              <div class="progress">
                <span class="progress-bar" style={{ width: '100%' }}></span>
              </div>
            ) : null}
          </div> */}
          {/* {formData.isPrimary == 1 ?
            <> */}
              <UploadImage
                formData={formData}
                buttonName='CLICK TO ATTACH'
                columnName={columnName}
                fileName={fileName}
                labeltxt={labeltxt}
                inform={infodetls}
                showIcon={true}
                valuedefine={"companyFundsDocument"}
              // disabled={formData.isPrimary == 1 ? false : true}
              />
              {/* <span className="error companyFundsDocument"></span>
            </> : <button>CLICK TO ATTACH</button>} */}
          {/* <UploadImage
            formData={formData}
            buttonName='CLICK TO ATTACH'
            columnName={columnName}
            fileName={fileName}
            labeltxt={labeltxt}
            inform={infodetls}
            showIcon={true}
            valuedefine={"companyFundsDocument"}
            // disabled={formData.isPrimary == 1 ? false : true}

          />
          <span className="error companyFundsDocument"></span> */}

          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={Onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {
              imageLoader === true ? (
                <button type="submit" className="next flagsave">
                  <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                  <img src={rightarrow} className="rightarrow" alt="" />{' '}
                </button>
              ) : (
                <button type="submit" className="next flagsave" onClick={handleClick}>
                  Continue
                  <img src={rightarrow} className="rightarrow" alt="" />{' '}
                </button>
              )}

          </div>
        </div>
      </div>
    </>
  );
};
export default EntityFour;
