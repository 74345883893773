import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import ItemForm from "../ItemForm";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import logo from '../logo.png';
import info from '../info.png';
import $ from "jquery";
import swal from 'sweetalert';
const Jointtwentysix = ({ setForm, formData, navigation, saveAndExist, infodetls }) => {
  const { nameOfProfessional, emailOfProfessional, nominatedProfessional } = formData;
  const { previous, next } = navigation;


  function handleClick(e) {
    let emailExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let nameExpression = /^[a-zA-Z/\s/g]+$/;
    if (typeof formData.nominatedProfessional == 'undefined' || formData.nominatedProfessional === "") {
      $('.nominatedProfessional').html('Please enter nominated professional');
    }
     else if (typeof formData.nameOfProfessional == 'undefined' || formData.nameOfProfessional === "") {
      $('.nameOfProfessional').html('Please enter name of professional');
    }
    else if (!nameExpression.test(formData.nameOfProfessional)) {
      $('.nameOfProfessional').html('Please enter only characters');
    }
    else if (typeof formData.emailOfProfessional == 'undefined' || formData.emailOfProfessional === "") {
      $('.emailerror').html('Please enter a valid email address, such as example@email.com');
    }
    else if (!emailExpression.test(formData.emailOfProfessional)) {
      $('.emailerror').html('Please enter a valid email address, such as example@email.com');
    }



        
    else {
      const setValues = localStorage.getItem("setValues")
      if (setValues === "No") {
        go("Twenty");
      } else {
        next();
      }
    }
  }

  function removeError() {
    $('.error').html('');
  }
  saveAndExist = () => {
    formData.status = "PENDING"
    formData.step = "18"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  const { go } = navigation;
  return (
    <div className="main_container">
    <div className="form">

      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls} className="info" />
      </div>
      <h3>Certificate C - Confirmation of Certificate</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>
        <div className="grid-item active"></div>
        <div className="grid-item"></div>


      </div>
      <p>In addition to your declaration that you meet the requirements of an Eligible Investor, we are required to have this information confirmed by either a Chartered Accountant, a Lawyer, or a Financial Adviser.</p><br />
      <p>Please select who you would like to confirm your Eligible Investor status, and enter their name and email details below.</p><br />
      <p>Once you have completed and submitted this Application form, we will send an email to your nominated professional below requesting them to complete the Confirmation Certificate. You will also receive a copy of this email.</p><br />
      <label>Please select your nominated Professional:</label>

      <Row>

        <div class="switch-field">
          <input type="radio" id="radio-three" name="nominatedProfessional" value="Accountant" onChange={e=>{setForm(e) ; removeError()}} />
          <label for="radio-three">Accountant</label>
          <input type="radio" id="radio-four" name="nominatedProfessional" onChange={e=>{setForm(e) ; removeError()}} value="Lawyer" />
          <label for="radio-four">Lawyer</label>
          <input type="radio" id="radio-five" name="nominatedProfessional" onChange={e=>{setForm(e) ; removeError()}} value="Financial Adviser" />
          <label for="radio-five">Financial Adviser</label>

          <span className="error nominatedProfessional "></span>
        </div>

      </Row>
      <br />
      <div className="">
        <label>Name of Professional</label>
        <ItemForm
          // label="Name of Professional"
          className="quantity"
          placeholder="Enter first and last name"
          name="nameOfProfessional" onKeyPress={removeError} onChange={e=>{setForm(e) ; removeError()}}
        />
        <span className="error nameOfProfessional"></span>
      </div>
      <div className="">
        <label>Email address of Professional</label>
        <ItemForm
          // label="Email address of Professional"
          id="emails"
          placeholder="Enter email address"
          name="emailOfProfessional" onKeyPress={removeError} onChange={e=>{setForm(e) ; removeError()}}
        />
        <span className="error emailerror"></span>
      </div>
      <br />
      <div className="fotter-btn">
        <button className="preview " onClick={previous}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next set-c" onClick={handleClick} >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
  );
};

export default Jointtwentysix;
