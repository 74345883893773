import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import MenuSidebar from '../Individual/MenuSidebar';
import axios from 'axios';

const TrustThird = ({ setForm, formData, navigation, saveAndExist, infodetls, func, irdinfo, textinfo }) => {
    const {
        checkboxx,
        professinalTrustee

    } = formData;
    const { previous, next } = navigation;
    let validateName = (nameValue) => {
        if (/^[a-zA-Z\s\-]*$/g.test(nameValue)) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        $(document).ready(function () {
            //called when key is pressed in textbox
            $(".validation_name").keypress(function (e) {
                var regex = new RegExp(/^[ A-Za-z _@!$&%*:'./#&+-]*$/);
                var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (regex.test(str)) {
                    return true;
                } else {
                    e.preventDefault();
                    return false;
                }
            });
        });
        $(document).ready(function () {
            $(' select').on('change', function () {
                if (this.value === 'NonResident') {
                    $('#textboxes').show();
                } else {
                    $('#textboxes').hide();
                }
            });
        });
        $('.toogles').click(function () {
            $('.toogles').removeClass('actives');
            $(this).addClass('actives');
        });

        function readURL(input, imgControlName) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $(imgControlName).attr('src', e.target.result);
                };
                reader.readAsDataURL(input.files[0]);
                $('#name_tag').text(input.files[0].name);
            }
        }

        $('#imag2').change(function () {
            var imgControlName = '#ImgPreview2';
            readURL(this, imgControlName);
            $('.preview2').addClass('it');
            $('.btn-rmv2').addClass('rmv');
        });

        $('#removeImage2').click(function (e) {
            e.preventDefault();
            $('#imag2').val('');
            $('#ImgPreview2').attr('src', '');
            $('.preview2').text('');
            $('.btn-rmv2').removeClass('rmv');
        });
    });

    function removeError() {
        $('.error').html('');
    }

    saveAndExist = () => {
        formData.status = 'PENDING';
        formData.step = '4';
        swal({
            text: 'Your application saved'
        });
        go('submit');
    };
    infodetls = () => {
        swal({
            text: 'By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.'
        });
    };
    irdinfo = () => {
        swal({
            text: 'Please provide a copy of an IRD tax notice or statement confirming the Trust Name and IRD Number.'
        });
    };
    textinfo = () => {
        swal({
            content: {
                element: 'div',
                attributes: {
                    innerHTML:
                        "<p>We are required to confirm the Trust's ''source of wealth or	funds'' as per sections 23 of the AML/CFT Act 2009, in particular we are required to:</p><p>i. Identify the individuals who are the settlor(s), and the origin of the settlor's wealth.</p><p>ii. When the Trust performed its first transaction where did the funds come from in	respect to that Transaction.</p><p>iii. Identify the source of any income that the Trust is receiving.</p><p>iv. The confirmation of the source of funds for this specific transaction. Once you have submitted this application form, we will send an email to your nominated professional requesting this information from them, you will also receive a copy of this email.</p>"
                }
            }
        });
    };

    const { go } = navigation;
    const [forword, setForword] = useState(true)
    const getInitialData1 = {
        fullLegalName: "",
        userEmail: "",
        isSettlor: 0,
        isAuthPerson: 0,
        isTrustee: 0,
        isEffectiveControl: 0,
        isTrustIndividual: 1,
    };

    const [inputDataList, setinputDataList] = useState(formData.inputDataList != null && formData.inputDataList != "" && formData.inputDataList != "undefined" ? formData.inputDataList : [getInitialData1 !== "" && getInitialData1 !== undefined ? getInitialData1 : null]);
    const [nextt, setNextt] = useState(false);

    const [active, setActive] = useState('first');

    function handleClick() {
        const total_index = inputDataList.length - 1;

        let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (total_index == 0) {
            if ((inputDataList[0].fullLegalName == '' || inputDataList[0].fullLegalName == null || inputDataList[0].fullLegalName == undefined)) {
                $('.full_legal_name0').html('Please enter valid first and last name');
            } else if (!emailExpression.test(inputDataList[0].userEmail)) {
                $(".user_email0").html("Please enter a valid email address");
            }
            else {
                if (formData.professinalTrustee == "false" || formData.professinalTrustee == null) {
                    formData.tick = "true"
                    formData.trusttfive = 5
                    formData.class = 'Third';
                    go('ThirdTrust');
                } else {
                    formData.tick = "true"
                    formData.trusttfive = 0
                    formData.class = 'Jointhird';
                    go('TrustFour');
                }
            }
        }
        else if (total_index == 1) {
            if ((inputDataList[0].fullLegalName == '' || inputDataList[0].fullLegalName == null || inputDataList[0].fullLegalName == undefined)) {
                $('.full_legal_name0').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[0].userEmail)) {
                $(".user_email0").html("Please enter a valid email address");
            }
            else if ((inputDataList[1].fullLegalName == '' || inputDataList[1].fullLegalName == null || inputDataList[1].fullLegalName == undefined)) {
                $('.full_legal_name1').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[1].userEmail)) {
                $(".user_email1").html("Please enter a valid email address");
            }
            else {
                if (formData.professinalTrustee == "false" || formData.professinalTrustee == null) {
                    formData.tick = "true"
                    formData.trusttfive = 5
                    formData.class = 'Third';
                    go('ThirdTrust');
                } else {
                    formData.tick = "true"
                    formData.trusttfive = 0
                    formData.class = 'Jointhird';
                    go('TrustFour');
                }
            }
        } else if (total_index == 2) {
            if ((inputDataList[0].fullLegalName == '' || inputDataList[0].fullLegalName == null || inputDataList[0].fullLegalName == undefined)) {
                $('.full_legal_name0').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[0].userEmail)) {
                $(".user_email0").html("Please enter a valid email address");
            }
            else if ((inputDataList[1].fullLegalName == '' || inputDataList[1].fullLegalName == null || inputDataList[1].fullLegalName == undefined)) {
                $('.full_legal_name1').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[1].userEmail)) {
                $(".user_email1").html("Please enter a valid email address");
            }
            else if ((inputDataList[2].fullLegalName == '' || inputDataList[2].fullLegalName == null || inputDataList[2].fullLegalName == undefined)) {
                $('.full_legal_name2').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[2].userEmail)) {
                $(".user_email2").html("Please enter a valid email address");
            }
            else {
                if (formData.professinalTrustee == "false" || formData.professinalTrustee == null) {
                    formData.tick = "true"
                    formData.trusttfive = 5
                    formData.class = 'Third';
                    go('ThirdTrust');
                } else {
                    formData.tick = "true"
                    formData.trusttfive = 0
                    formData.class = 'Jointhird';
                    go('TrustFour');
                }
            }
        } else if (total_index == 3) {
            if ((inputDataList[0].fullLegalName == '' || inputDataList[0].fullLegalName == null || inputDataList[0].fullLegalName == undefined)) {
                $('.full_legal_name0').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[0].userEmail)) {
                $(".user_email0").html("Please enter a valid email address");
            }
            else if ((inputDataList[1].fullLegalName == '' || inputDataList[1].fullLegalName == null || inputDataList[1].fullLegalName == undefined)) {
                $('.full_legal_name1').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[1].userEmail)) {
                $(".user_email1").html("Please enter a valid email address");
            }
            else if ((inputDataList[2].fullLegalName == '' || inputDataList[2].fullLegalName == null || inputDataList[2].fullLegalName == undefined)) {
                $('.full_legal_name2').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[2].userEmail)) {
                $(".user_email2").html("Please enter a valid email address");
            }
            else if ((inputDataList[3].fullLegalName == '' || inputDataList[3].fullLegalName == null || inputDataList[3].fullLegalName == undefined)) {
                $('.full_legal_name3').html('Please enter valid first and last name');
            }
            else if (!emailExpression.test(inputDataList[3].userEmail)) {
                $(".user_email3").html("Please enter a valid email address");
            }
            else {
                if (formData.professinalTrustee == "false" || formData.professinalTrustee == null) {
                    formData.tick = "true"
                    formData.trusttfive = 5
                    formData.class = 'Third';
                    go('ThirdTrust');
                } else {
                    formData.tick = "true"
                    formData.trusttfive = 0
                    formData.class = 'Jointhird';
                    go('TrustFour');
                }
            }
        }

        else {
            if (formData.professinalTrustee == "false" || formData.professinalTrustee == null) {
                formData.tick = "true"
                formData.trusttfive = 5
                formData.class = 'Third';
                go('ThirdTrust');
            } else {
                formData.tick = "false"
                formData.trusttfive = 0
                formData.class = 'Jointhird';
                go('TrustFour');
            }
        }
    }


    console.log("formData.professinalTrustee", formData.professinalTrustee)

    const chenging = () => {
        setForword(false)
        setNextt(true);
        setActive('second');

    };

    const chenging2 = () => {
        setForword(true)
        setNextt(false);
        setActive('first');

    };


    const handleAddClick = () => {
        setinputDataList([...inputDataList, getInitialData1]);
    };

    const handleRemoveClick = (index, e) => {

        const newList = [...inputDataList];
        newList.splice(index, 1);
        const result = axios.delete(`https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev/delete/shareholder/${e.target.value}`, {
            headers: {
                Authorization: `Bearer ${formData.token}`

            }
        }).then((res) => {
            if (res.status == 404) {
                // alert("deleted")
            }
        }
        ).catch((error) => {
            // alert("not Deleted")
        })
        setinputDataList(newList);
        formData.inputDataList = newList
        setinputDataList(newList);
        formData.inputDataList = newList

    };

    const saveandexit = () => {
        func()
    }

    console.log("formData", inputDataList)
    formData.inputDataList = inputDataList

    return (
        <>
            <MenuSidebar navigation={navigation} formData={formData} />
            <div className="main_container">
                <div className="heading__top">
                    <h2>Step 2: Trust Details</h2>
                </div>

                <div className="form">
                    <img src={logo} alt="img" className="imageCover" />

                    <p className="please">
                        Please provide the first name, last name, and email address of all Settlors, Trustees, Authorised people, or anyone else with Effective Control of the trust.
                    </p>

                    {inputDataList.map((x, index) => {
                        return (
                            <>
                                <div className={'bdetail' + index + 1 + '' + 'box remove_first_input'}>
                                    <Row className="Trust_row trust_row_sec ">
                                        <Col>
                                            <label>What is your role with the Trust?</label>
                                        </Col>
                                        {/* <Col className="col_trust">

                                            <input type="text" placeholder="Enter first & last name"
                                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                className='validation_name'
                                                onChange={(event) => {
                                                    removeError(event)
                                                    setForm(event);
                                                    const secArray = inputDataList;
                                                    secArray[index].fullLegalName = event.target.value;
                                                    setinputDataList([...secArray]);
                                                }}
                                                name="fullLegalName"
                                                value={inputDataList[index].fullLegalName}

                                            />
                                            <span className={`full_legal_name${index} error`}></span>

                                            <input type="text" placeholder="Enter their email address"
                                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}

                                                onChange={(event) => {
                                                    setForm(event);
                                                    removeError(event)
                                                    const secArray = inputDataList;
                                                    secArray[index].userEmail = event.target.value;
                                                    setinputDataList([...secArray]);
                                                    console.log("inputDataListinputDataList", inputDataList)
                                                }}
                                                name="userEmail"
                                                value={inputDataList[index].userEmail}

                                            />

                                            <span className={`user_email${index} error`}></span>

                                        </Col> */}
                                        <Col className="col_trust s-roles">

                                            <p className="select_all text-center mb-2">( Select all roles that apply )</p>
                                            <div className='trust_five_checkbox d-flex'>
                                                <div className='Trust_check'>
                                                    <p>Settlor</p>
                                                    <input
                                                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                        disabled={formData.isPrimary == 1 ? false : true}

                                                        // type="checkbox" className="checkTrust" 
                                                        type="checkbox"
                                                        id="TickHere"
                                                        className="containers years set-check"
                                                        name="isSettlor"
                                                        value={inputDataList[index].isSettlor}
                                                        checked={inputDataList[index].isSettlor}
                                                        onChange={(event) => {
                                                            setForm(event);
                                                            const secArray = inputDataList;
                                                            secArray[index].isSettlor = event.target.checked;
                                                            setinputDataList([...secArray]);
                                                        }}
                                                    />
                                                    <span className='check_mark'></span>
                                                </div>
                                                <div className='Trust_check'>
                                                    <p>Trustee</p>
                                                    <input
                                                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                        disabled={formData.isPrimary == 1 ? false : true}

                                                        // type="checkbox" className="checkTrust"
                                                        type="checkbox"
                                                        id="TickHere"
                                                        className="containers years set-check"

                                                        name="isTrustee"
                                                        value={inputDataList[index].isTrustee}
                                                        checked={inputDataList[index].isTrustee}
                                                        onChange={(event) => {
                                                            setForm(event);
                                                            const secArray = inputDataList;
                                                            secArray[index].isTrustee = event.target.checked;
                                                            setinputDataList([...secArray]);
                                                        }}

                                                    />
                                                    <span className='check_mark'></span>
                                                </div>
                                                <div className='Trust_check'>
                                                    <p> Auth Person </p>

                                                    <input
                                                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                        disabled={formData.isPrimary == 1 ? false : true}

                                                        // type="checkbox" className="checkTrust" 
                                                        type="checkbox"
                                                        id="TickHere"
                                                        name="isAuthPerson"
                                                        className="containers years set-check"
                                                        value={inputDataList[index].isAuthPerson}
                                                        checked={inputDataList[index].isAuthPerson}
                                                        onChange={(event) => {
                                                            setForm(event);
                                                            const secArray = inputDataList;
                                                            secArray[index].isAuthPerson = event.target.checked;
                                                            setinputDataList([...secArray]);
                                                        }}

                                                    />
                                                    <span className='check_mark'></span>
                                                </div>
                                                <div className='Trust_check'>
                                                    <p>Effective Control</p>

                                                    <input
                                                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                        disabled={formData.isPrimary == 1 ? false : true}

                                                        // type="checkbox" className="checkTrust"
                                                        type="checkbox"
                                                        id="TickHere"
                                                        className="containers years set-check"
                                                        name="isEffectiveControl"
                                                        value={inputDataList[index].isEffectiveControl}
                                                        checked={inputDataList[index].isEffectiveControl}
                                                        onChange={(event) => {
                                                            setForm(event);
                                                            const secArray = inputDataList;
                                                            secArray[index].isEffectiveControl = event.target.checked;
                                                            setinputDataList([...secArray]);
                                                        }}
                                                    />
                                                    <span className='check_mark'></span>
                                                </div>
                                            </div>
                                        </Col>


                                    </Row>


                                    <Col md={6} className="col_trust col_trust_2nd">

                                        <input type="text" placeholder="Enter first & last name"
                                            // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                            disabled={formData.isPrimary == 1 ? false : true}

                                            className='validation_name'
                                            onChange={(event) => {
                                                removeError(event)
                                                setForm(event);
                                                const secArray = inputDataList;
                                                secArray[index].fullLegalName = event.target.value;
                                                setinputDataList([...secArray]);
                                            }}
                                            name="fullLegalName"
                                            value={inputDataList[index].fullLegalName}

                                        />
                                        <span className={`full_legal_name${index} error`}></span>


                                        {index == 0 ?
                                            <>

                                            </>
                                            : <>
                                                <input type="text" placeholder="Enter their email address"
                                                    // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                    disabled={formData.isPrimary == 1 ? false : true}

                                                    onChange={(event) => {
                                                        setForm(event);
                                                        removeError(event)
                                                        const secArray = inputDataList;
                                                        secArray[index].userEmail = event.target.value;
                                                        setinputDataList([...secArray]);
                                                        console.log("inputDataListinputDataList", inputDataList)
                                                    }}
                                                    name="userEmail"
                                                    value={inputDataList[index].userEmail}

                                                />

                                                <span className={`user_email${index} error`}></span>
                                            </>
                                        }

                                        <div className='indu_remove_btn'>

                                            <div className="removebtn2">
                                                {inputDataList.length > 1 && (
                                                    <button className='removebtn'
                                                        onClick={(e) => handleRemoveClick(index, e)}
                                                        name={x.fullLegalName}
                                                        value={x.userEmail}
                                                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                                        disabled={formData.isPrimary == 1 ? false : true}

                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </div>

                                        </div>
                                    </Col>
                                </div>
                            </>
                        );
                    })

                    }

                    <div className="row mb-5 fullinput indiviual_inp">
                        <Col sm={12}>
                            <div className="Trust_ind_btn">
                                {inputDataList.length <= 3 ? (
                                    <button
                                        onClick={handleAddClick}
                                    // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                    disabled={formData.isPrimary == 1 ? false : true}

                                    >+Individual</button>
                                ) : null}
                            </div>
                        </Col>
                    </div>

                    <Row>
                        {/* <p className="please">
                        Please provide the first name, last name, and email address of all Settlors, Trustees, Authorised people, or anyone else with Effective Control of the trust.
                    </p> */}
                        {/* <Col className="col_trust">

                            <input type="text" placeholder="Enter first & last name"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                className='validation_name'
                                onChange={(event) => {
                                    removeError(event)
                                    setForm(event);
                                    const secArray = inputDataList;
                                    secArray.fullLegalName = event.target.value;
                                    setinputDataList([...secArray]);
                                }}
                                name="fullLegalName"
                                value={inputDataList.fullLegalName}

                            />
                            <span className={`full_legal_name error`}></span>

                            <input type="text" placeholder="Enter their email address"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}

                                onChange={(event) => {
                                    setForm(event);
                                    removeError(event)
                                    const secArray = inputDataList;
                                    secArray.userEmail = event.target.value;
                                    setinputDataList([...secArray]);
                                    console.log("inputDataListinputDataList", inputDataList)
                                }}
                                name="userEmail"
                                value={inputDataList.userEmail}

                            />

                            <span className={`user_email error`}></span>

                        </Col> */}
                    </Row>
                    <div className="trust_form">
                        <label className="please">
                            Do you have a corporate trustee or professional trustee ?
                        </label>
                        <div class="switch-field nospace trust__space_field">
                            <div className="check_YN_Bank">
                                <input
                                    // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                    disabled={formData.isPrimary == 1 ? false : true}
                                    type="radio"
                                    id="radio-one"
                                    className="yesno"
                                    name="professinalTrustee"
                                    value="false"
                                    onChange={(e) => {
                                        setForm(e);
                                        removeError();
                                    }}

                                />
                                <label
                                    for="radio-one"
                                    className={`toogles4 postalAddressButton ${formData.professinalTrustee == "false" || formData.professinalTrustee == null
                                        ? "actives"
                                        : null
                                        }`}

                                    id="hide"

                                >
                                    No
                                </label>

                                <input
                                    // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                    disabled={formData.isPrimary == 1 ? false : true}
                                    autoComplete="off"
                                    type="radio"
                                    id="radio-two"
                                    name="professinalTrustee"
                                    value="true"
                                    onChange={(e) => {
                                        setForm(e);
                                        removeError();
                                    }}
                                //  onClick={() => {
                                //   chenging();
                                // }}

                                />

                                <label
                                    for="radio-two"
                                    className={`toogles4 postalAddressButtons ${formData.professinalTrustee === "true" ? "actives" : null
                                        }`}
                                    id="show"

                                >
                                    Yes
                                </label>

                            </div>
                        </div>
                    </div>
                    <div className="fotter-btn">
                        <button className="preview" onClick={previous}>
                            <img src={leftarrow} className="leftarrow" alt="" />
                            Previous
                        </button>
                        <button type="submit" className="next flagsave" onClick={handleClick}>
                            Continue
                            <img src={rightarrow} className="rightarrow" alt="" />{' '}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrustThird;

