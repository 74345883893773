import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AxiosInstance from "../axios"
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import attch from '../attch.png';
import ItemForm from "../ItemForm";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import info from '../info.png';
import logo from '../logo.png';
import Copy from '../Copy.png';
import $ from "jquery";
import swal from 'sweetalert';
import moment from 'moment';
import Jointtwentyseven from './Jointtwentyseven';
const Twenty = ({ setForm, formData, navigation, saveAndExist, infodetls }) => {
  const { paymentReferance, depositAmount, Syndication, units, bankReferance, myValuestwo1, fullName, accountName, accountNumber, bankName } = formData;
  const { previous, next } = navigation;
  const [userapplication, setUserapplication] = useState([]);
  useEffect(() => {
    application();
  }, []);
  const application = async () => {
    const applicationdata = await AxiosInstance.get("/admin/syndicate/" + Syndication);
    setUserapplication(applicationdata.data);
    console.log(applicationdata.data);
  };

  let xVal;
  xVal = units.replace(/,/g, "");

  let multi = xVal * userapplication.valuePerUnit
  // multi = multi.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  let multis = (multi * userapplication.percentageValue) / 100
  multis = multis.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  let remainder = (xVal - multis)
  remainder = remainder.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');


  let due = (100 - userapplication.percentageValue)
  due = due.toFixed().replace(/\d(?=(\d{3})+\.)/g, '$&,');
  // let multis = (userapplication.valuePerUnit * userapplication.numberOfUnits) / 4
  // multis = multis.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const setValues = localStorage.getItem("setValues")
  const setValuesmy = localStorage.getItem("setValuesmy")
  useEffect(() => {
    localStorage.removeItem("step13");
    localStorage.removeItem("step15");

    // $(".past").click(function () {
    //   const setValues = localStorage.getItem("setValues")
    //   const setValuesmy = localStorage.getItem("setValuesmy")


    //   if (setValuesmy === "Yes") {
    //     previous();
    //   }

    //   else {
    //     if (setValues === "Yes") {
    //       go("Ten");
    //     } else if (setValues === "No") {
    //       go("Eleven");


    //     }


    //   }
    // })

  })
  let setval = multis;
  function handleClick(e) {
    if (typeof formData == 'undefined' || formData === "") {
      $('.').html('Please enter units ');
    }
    else {

      localStorage.setItem('multis', setval);
      formData.multis = multis;

      next();
    }
  }
  const { go } = navigation;
  saveAndExist = () => {
    formData.status = "PENDING"
    formData.step = "20"
    // swal({
    //   text: "Your application saved",
    // });
    go("submit")
  }
  infodetls = () => {
    swal({
      text: "By clicking on Save & exit, your application information will be saved. We will send you an email with a link to resume your application when you are ready.",
    });
  }
  $(function () {
    $('.promocopy-outer button').click(function () {
      $(this).prev("input").focus();
      $(this).prev("input").select();

      document.execCommand('copy');
      // $(".copied").text("Copied to clipboard").show().fadeOut(1200);
    });
  });
  var date = userapplication.depositDueDate
  function setDateformate(dateObj) {
    if (dateObj !== null && dateObj !== "undefined" && typeof dateObj !== "undefined" && dateObj.includes('-') ) {
      let dat = dateObj.split('-');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      let momentObj = moment(dat, 'DD-MM-YYYY')
let showDate = moment(momentObj).format('DD MMMM, YYYY')
      return showDate;
   
    } return date;
  }

  function previous1(e) {
    //go("CompanyTwentySeven")
    {
      (myValuestwo1 === "step16")
      ?
      go("Eighteen")
      :
      (setValues === "Yes")
        ?
        go("Ten")
        :
        (formData.entity === "Company")
         ? go('CompanyTwentySeven')
        :
        ( formData.entity ==="Joint" &&  formData.isTwoYearInvest === "true")
          ?
          go("Jointtwentyseven")
           : go("Eleven")
    }
  }
  
  return (
    <div className="main_container">
    <div className="form">
      <img
        src={logo}
        alt="img"
        className="imageCover"
      />
      <div className="save-exit">
        <p className="save" onClick={saveAndExist} >Save & exit </p><img src={info} onClick={infodetls} className="info" />
      </div>
      <h3>Payment information</h3>
      <div className="grid-container">
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item "></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item "></div>


      </div>
      <p>To secure your investment of {units} units in the {userapplication.syndicateName}, please read the below information, and arrange to make the deposit payment.</p><br />
      <p>I/We confirm that I/We am/are, or the entity, which takes up the investment, is/are able to make that payment.</p><br />
      <p>I/We accept that I/We must make full payment totalling ${userapplication.valuePerUnit} per Unit (100% of investment) as follows:</p>

      <ul className="usual">
        <li>a) A deposit payment of NZ ${multis} per Unit ({userapplication.percentageValue}% of Investment) upon application and no later than {setDateformate(date)}. </li>
        {/* <li>b) A payment of NZ $ {remainder} per unit ({due} % due) on or before the close of business on </li> */}
        <li>b) {userapplication.furtherPaymentsBlurb} </li>

      </ul>
      <Row><Col md={12}>
        <label>Deposit Details </label>
      </Col></Row>
      <br />
      <Row>
        <Col>
          <div className="">
            <label>Account name</label>
            <ItemForm
              // label="Account name"
              id="fullName"
              className="full"
              readonly value="Sharp Tudhope Trust Account"
              name="accountName"
            />
          </div>
        </Col>
        <Col>
          <div className="">
            <label>Bank name</label>
            <ItemForm
              // label="Bank name"
              id="emails"
              className="full"
              placeholder="ANZ" readonly value="ANZ"
            // name="bankName"
            // value={bankName} onChange={e=>{setForm(e) ; removeError()}}
            />
          </div>
        </Col>

      </Row>

      <Row>

        <Col md={12}>
          <div className="pw-promolist__tablist__right promocopy-outer">
            <label>Account number</label>
            <input readonly type="text" className="full" value="06-0433-0020939-00" id="promoCopy" />
            <button className="copyall"><span class="tersalin"><img src={Copy} /></span></button>
          </div>

        </Col>
        {/* <Col md={1}>
       <img src={attch} className="copybank"/>
       </Col> */}

      </Row>

      <Row>

        <Col md={12}>
          <div className="">
            <label>Payment reference</label>
            <ItemForm
              // label="Payment reference "
              id="emails"
              className="full"
              placeholder="<Payment reference> <investment entity name>"
              name="paymentReferance"
              value={formData.refernace + " " + fullName} onChange={e => { setForm(e) }} readonly
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="">
            <label>Deposit amount</label>
            <ItemForm
              // label="Deposit amount"
              id="emails"
              className="full"
              // placeholder={multi}
              // // name="depositAmount"
              value={"$" + multis}
              onChange={e => { setForm(e) }} readonly
            />
          </div>

        </Col>
      </Row>
      <div className="fotter-btn">
        <button className="preview past " onClick={previous1}><img src={leftarrow} className="leftarrow" alt=""/>Previous</button>
        <button type="submit" className="next" onClick={handleClick}  >Continue<img src={rightarrow} className="rightarrow"  alt=""/> </button>
      </div>
    </div>
    </div>
  );
};

export default Twenty;
